import { createAction, handleActions } from 'redux-actions';
// - Actions

export const getExchargesRequest = createAction('GET_LIST_EXCHARGES_REQUEST');
export const getExchargesSuccess = createAction('GET_LIST_EXCHARGES_SUCCESS');

export const removeExchargesRequest = createAction('REMOVE_EXCHARGE_REQUEST');

export const failure = createAction('EXCHARGES_ACTION_FAILURE');


// - Initial State
export const initialState = {
  excharges: [],
  pagination: {
    Page: 1,
    Size: 50,
    Total: 0
  },
  loading: false,

  error: null,
};

// - Reducers
const reducer = handleActions({
  [getExchargesRequest]: (state) => {
    return { ...state, loading: true };
  },
  [getExchargesSuccess]: (state, { payload: { excharges } }) => {
    return { ...state, excharges, loading: false };
  },

  [failure]: (state, action) => ({
    ...state,
    error: action.error,
    loading: false,
  }),
}, initialState);

export default reducer;
