import CommissionV from './CommissionV'
import { compose as recompose, lifecycle } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import { getCustomerCommissionRequest } from '../../../reduxs/commission-redux/reducer';
import { selectCommission, selectCommissioning, } from '../../../reduxs/commission-redux/selectors';


function mapDispatchToProps(dispatch) {
    return {
        getCommission: (params) => dispatch(getCustomerCommissionRequest(params)),
    };
}

const mapStateToProps = createStructuredSelector({
    commission: selectCommission(),
    loadingCommission: selectCommissioning(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default recompose(
    withConnect,
    lifecycle({
        componentDidMount() {
            const { customer, getCommission } = this.props;
            if (customer) {
                getCommission({ CustomerCode: customer.AccountNr });
            }
        },
    })
)(CommissionV);
