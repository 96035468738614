import { createAction, handleActions } from 'redux-actions';
import { findIndex, remove, isNil } from 'lodash';

// - Actions
export const getMyNotificationRequest = createAction('GET_MY_NOTIFICATION_REQUEST');
export const getMyNotificationSuccess = createAction('GET_MY_NOTIFICATION_SUCCESS');

export const markAsReadRequest = createAction('MARK_AS_READ_REQUEST');
export const markAsReadSuccess = createAction('MARK_AS_READ_SUCCESS');

export const markAsUnreadRequest = createAction('MARK_AS_UNREAD_REQUEST');
export const markAsUnreadSuccess = createAction('MARK_AS_UNREAD_SUCCESS');

export const markAllAsReadRequest = createAction('MARK_ALL_AS_READ_REQUEST');
export const markAllAsReadSuccess = createAction('MARK_ALL_AS_READ_SUCCESS');

export const deteleNotificationRequest = createAction('DELETE_NOTIFICATION_REQUEST');
export const deteleNotificationSuccess = createAction('DELETE_NOTIFICATION_SUCCESS');

export const collectingNewNotificationsRequest = createAction('COLLECT_NEW_NOTIFICATIONS_REQUEST');
export const collectingNewNotificationsSuccess = createAction('COLLECT_NEW_NOTIFICATIONS_SUCCESS');

export const clearCheckHasNewNotification = createAction('CLEAR_CHECK_HAS_NEW_NOTIFICATIONS');


export const setShow = createAction('SET_SHOW');
export const setAlwayShow = createAction('SET_ALWAY_SHOW');
export const setOnNotification = createAction('SET_ON_NOTIFICATION');
export const toggleShow = createAction('TOGGLE_SHOW');

// - Initial State
export const initialState = {
    total: 0,
    totalNew: 0,
    fetching: false,
    notifications: [],
    flashNotifications: [],
    hasMore: false,
    show: false,
    makingAsRead: false,
    onNotification: null,
    forceOpen: false,
    collectNewNotificationCount: 0,
    collectNewNotificationSuccess: false,
    hasNewNotification: false,
    markingAllAsRead: false
}

// - Reducers
const reducer = handleActions({
    [getMyNotificationRequest]: state => ({
        ...state, fetching: true
    }),
    [getMyNotificationSuccess]: (state, { payload: { notifications, pagination, totalNew, AddToTop, flashNotifications } }) => {
        let _notification = [...state.notifications];
        if (notifications.length) {
            if (AddToTop) {
                _notification = [...notifications, ..._notification];
            } else {
                _notification = _notification.concat(notifications);
            }
        }
        let s = { ...state, fetching: false, notifications: _notification, flashNotifications };
        if (pagination) {
            s = { ...s, total: pagination.Total, hasMore: _notification.length < pagination.Total }
        }
        if (!isNil(totalNew)) {
            s = { ...s, totalNew: totalNew };
        }
        if (totalNew > state.totalNew) {
            s = { ...s, hasNewNotification: true };
        }
        return s
    },
    [setShow]: (state, { payload }) => ({
        ...state, show: payload
    }),
    [toggleShow]: state => {
        return { ...state, show: !state.show };
    },
    [setAlwayShow]: (state, { payload: { show } }) => {
        let s = { ...state, forceOpen: show };
        if (show) {
            s.show = true;
        }
        return s;
    },
    [markAsReadRequest]: (state) => ({ ...state, makingAsRead: true }),
    [markAsReadSuccess]: (state, { payload: { cb, Id, Total, TotalNew } }) => {
        let _notifications = [...state.notifications];
        let _flashNotification = [...state.flashNotifications];

        if (Id) {
            const index = findIndex(_notifications, x => x.NotifId === Id);
            if (index >= 0) {
                _notifications[index].SeenState = 'READED';
            }
            if (cb && typeof cb === 'function') {
                cb(_notifications);
            }
        }

        if (Id) {
            remove(_flashNotification, x => x.NotifId === Id);
        }

        let s = { ...state, notifications: _notifications, flashNotifications: _flashNotification };
        if (!isNil(Total)) {
            s = { ...s, total: Total };
        }

        if (!isNil(TotalNew)) {
            s = { ...s, totalNew: TotalNew };
        }

        return s;
    },
    [deteleNotificationRequest]: (state) => ({ ...state }),
    [deteleNotificationSuccess]: (state, { payload: { Id, cb, Items, Total, TotalNew } }) => {
        let _notifications = [...state.notifications];
        remove(_notifications, x => x.NotifId === Id);

        let _flashNotification = [...state.flashNotifications];
        remove(_flashNotification, x => x.NotifId === Id);

        if (cb && typeof cb === 'function') {
            cb(_notifications);
        }
        if (Items && Items.length) {
            _notifications.concat(Items);
        }
        return { ...state, notifications: _notifications, flashNotifications: _flashNotification, total: Total, totalNew: TotalNew }
    },
    [setOnNotification]: (state, { payload: { NotifId } }) => ({ ...state, onNotification: NotifId }),
    [markAsUnreadRequest]: (state) => ({ ...state }),
    [markAsUnreadSuccess]: (state, { payload: { Id, Total, TotalNew } }) => {
        let _notifications = [...state.notifications];
        if (Id) {
            const index = findIndex(_notifications, x => x.NotifId === Id);
            if (index >= 0) {
                _notifications[index].SeenState = 'NEW';
            }
        }
        let s = { ...state, notifications: _notifications };

        if (!isNil(Total)) {
            s = { ...s, total: Total };
        }

        if (!isNil(TotalNew)) {
            s = { ...s, totalNew: TotalNew };
        }

        return s;
    },
    [markAllAsReadRequest]: (state) => ({ ...state, markingAllAsRead: true }),
    [markAllAsReadSuccess]: (state, { payload: { TotalNew, Total } }) => {
        let s = { ...state, markingAllAsRead: false };

        if (!isNil(Total)) {
            s = { ...s, total: Total };
        }

        let _notifications = [...state.notifications];
        _notifications.forEach(element => {
            element.SeenState = 'READED';
        });
        s = { ...s, notifications: _notifications, flashNotifications: [] };

        if (!isNil(TotalNew)) {
            s = { ...s, totalNew: TotalNew };
        }
        return s;
    },
    [collectingNewNotificationsRequest]: (state) => ({ ...state }),
    [collectingNewNotificationsSuccess]: (state, { payload: { TotalCollections } }) => ({ ...state, collectNewNotificationCount: TotalCollections }),
    [clearCheckHasNewNotification]: (state) => {
        return { ...state, hasNewNotification: false, collectNewNotificationCount: 0 }
    }
}, initialState);

export default reducer;