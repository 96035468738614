
import LoginPage from '../pages/auth/login';
import CallbackPage from '../pages/auth/callback';

import MainPage from '../pages';
import DashboardPage from '../pages/dashboard/DashboardVM';
import AccountsPage from '../pages/accounts/AccountsVM';
import CodesPage from '../pages/accounts/codes/CodesVM';
import RegionPage from '../pages/region/RegionVM';
import PickupRoutePage from '../pages/pickup-route/PickupRouteVM';
import AreasPage from '../pages/areas/AreasVM';
import PlacePage from '../pages/place/PlaceVM';
import PartitionPage from '../pages/partition/PartitionVM';
import MenuPage from '../pages/menu/MenuVM';
import RevenuePage from '../pages/reports/revenue/RevenueVM';
import RolePage from '../pages/role/RoleVM';
import StaffsPage from '../pages/staffs/StaffsVM';
import AutoPage from '../pages/automatic/AutomaticVM';
import PolicyPage from '../pages/policy/PolicyVM';
import StationPage from '../pages/station/StationVM';
import DepartmentPage from '../pages/department/DepartmentVM';
import EmailManagementPage from '../pages/email-management/EmailManagementVM';
import EditorConvertToHTML from '../pages/editor-template/EditorTemplateV';
import CachePage from '../pages/cache/CacheVM';
import WebhookPage from '../pages/webhook/WebhookVM';
import RequestHistoryPage from '../pages/request-history/RequestHistoryVM';
import PartnersPage from '../pages/partners/PartnersVM';
import ErrorLogPage from '../pages/error-log/ErrorLogVM';
import SqlLogPage from '../pages/sql-log/SqlLogVM';
import DatabaseLogPage from '../pages/database-log-changes/DatabaseLogVM';
import TaskQueuePage from '../pages/task-queue/TaskQueueVM';
import NotificationCreator from '../pages/notitication/NotificationCreatorV';
import CustomerInfoSheet from '../pages/information-sheet/CustomerInfoSheetVM';
import EmailSubscriptionManagerPage from '../pages/email-subscription-manager';
import ExchargePage from '../pages/excharge/ExchargeVM';
import ExportFuelPage from '../pages/export-fuel/ExportFuelVM';
import PickupZonePage from '../pages/pickup-zone';

// import AssetDasboardPage from '../pages/assets/dashboard/DashboardVM';
// import AssetsPage from '../pages/assets/AssetsVM';
// import AssetRepairsPage from '../pages/assets/RepairsVM';
// import AssetHandoverPage from '../pages/assets/handover/HandoverVM';
// import AssetRecoveryPage from '../pages/assets/recovery/RecoveryVM';
// import AssetTransferPage from '../pages/assets/transfer/TransferVM';
// import AssetProviderPage from '../pages/assets/request/ProvidedsVM';
// import AssetShoppingPage from '../pages/assets/request/PurchaseVM';
// import AssetReportsPage from '../pages/assets/failure/ReportsVM';
// import AssetSalePage from '../pages/assets/sale/SalesVM';
// import AssetAdjustPage from '../pages/assets/adjustment/AdjustmentVM';
// import CheckCountPage from '../pages/assets/checkcount/CheckCountVM';
// import CheckListPage from '../pages/assets/checklist/CheckListVM';
// import CheckFormPage from '../pages/assets/checklist/CheckFormVM';

// import AssetTypesPage from '../pages/assets/settings/TypesVM';
// import AssetGroupPage from '../pages/assets/settings/GroupsVM';
// import AssetLocationPage from '../pages/assets/settings/LocationsVM';
// import AssetVendorPage from '../pages/assets/settings/VendorVM';

const routes = [
  { exact: true, path: "/login", component: LoginPage },
  { exact: true, path: "/callback", component: CallbackPage },
  {
    exact: false,
    path: "/",
    component: MainPage,
    private: true,
    routes: [
      { exact: true, path: "/", component: DashboardPage },
      { exact: true, path: "/quan-ly-dia-danh", component: RegionPage },
      { exact: true, path: "/dia-danh-phat-hang", component: AreasPage },
      { exact: true, path: "/quan-ly-phan-vung", component: PlacePage },
      { exact: true, path: "/quan-ly-vung-phat", component: PartitionPage },
      { exact: true, path: "/quan-ly-vung-lay-hang", component: PickupRoutePage },
      { exact: true, path: "/quan-ly-menu", component: MenuPage },
      { exact: true, path: "/reports/revenue", component: RevenuePage },
      { exact: true, path: "/roles", component: RolePage },
      { exact: true, path: "/services", component: AutoPage },
      { exact: true, path: "/policy", component: PolicyPage },
      { exact: true, path: "/quan-ly-buu-cuc", component: StationPage },
      { exact: true, path: "/quan-ly-phong-ban", component: DepartmentPage },
      { exact: true, path: "/quan-ly-tai-khoan", component: AccountsPage },
      { exact: true, path: "/quan-ly-ma-cha", component: CodesPage },
      { exact: true, path: "/quan-ly-email", component: EmailManagementPage },
      { exact: true, path: "/utils/editor", component: EditorConvertToHTML },
      { exact: true, path: "/cache", component: CachePage },
      { exact: true, path: "/system-user", component: StaffsPage },
      { exact: true, path: "/webhook-management", component: WebhookPage },
      { exact: true, path: "/request-histories", component: RequestHistoryPage },
      { exact: true, path: "/partners", component: PartnersPage },
      { exact: true, path: "/error-logs", component: ErrorLogPage },
      { exact: true, path: "/sql-command", component: SqlLogPage },
      { exact: true, path: "/database-changelog", component: DatabaseLogPage },
      { exact: true, path: "/task-queue", component: TaskQueuePage },
      { exact: true, path: "/create-notification", component: NotificationCreator },
      { exact: true, path: "/customer-info-sheet", component: CustomerInfoSheet },
      { exact: true, path: "/email-subscription-manager", component: EmailSubscriptionManagerPage },
      { exact: true, path: "/excharges", component: ExchargePage },
      { exact: true, path: "/export-fuel-surcharges", component: ExportFuelPage },
      { exact: true, path: '/pickup-zone-management', component: PickupZonePage }

      // { exact: true, path: "/tai-san", component: AssetDasboardPage },
      // { exact: true, path: "/tai-san/danh-sach-tai-san", component: AssetsPage },
      // { exact: true, path: "/tai-san/dieu-chinh-tai-san", component: AssetAdjustPage },
      // { exact: true, path: "/tai-san/phieu-ban-giao", component: AssetHandoverPage },
      // { exact: true, path: "/tai-san/phieu-thu-hoi", component: AssetRecoveryPage },
      // { exact: true, path: "/tai-san/phieu-thanh-ly", component: AssetSalePage },
      // { exact: true, path: "/tai-san/phieu-dieu-chuyen", component: AssetTransferPage },
      // { exact: true, path: "/tai-san/bao-duong-sua-chua", component: AssetRepairsPage },
      // { exact: true, path: "/tai-san/yeu-cau-cung-cap", component: AssetProviderPage },
      // { exact: true, path: "/tai-san/yeu-cau-mua-sam", component: AssetShoppingPage },
      // { exact: true, path: "/tai-san/bao-mat-bao-hong", component: AssetReportsPage },
      // { exact: true, path: "/tai-san/bien-ban-kiem-ke", component: CheckListPage },
      // { exact: true, path: "/tai-san/lap-bien-ban-kiem-ke", component: CheckFormPage },
      // { exact: true, path: "/tai-san/bien-ban-kiem-dem", component: CheckCountPage },
      // { exact: true, path: "/tai-san/loai-tai-san", component: AssetTypesPage },
      // { exact: true, path: "/tai-san/nha-cung-cap", component: AssetVendorPage },
      // { exact: true, path: "/tai-san/nhom-tai-san", component: AssetGroupPage },
      // { exact: true, path: "/tai-san/vi-tri-tai-san", component: AssetLocationPage },
    ]
  },

];

export default routes
