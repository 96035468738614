import { createAction, handleActions } from 'redux-actions';
// - Actions
export const getRegionsRequest = createAction('GET_REGION_REQUEST');
export const getRegionsSuccess = createAction('GET_REGION_SUCCESS');

export const fetchProvincesRequest = createAction('FETCH_PROVINCE_REQUEST');
export const fetchProvincesSuccess = createAction('FETCH_PROVINCE_SUCCESS');

export const getProvincesRequest = createAction('GET_PROVINCE_REQUEST');
export const getProvincesSuccess = createAction('GET_PROVINCE_SUCCESS');
export const deleteProvincesRequest = createAction('DELETE_PROVINCE_REQUEST');


export const getDistrictsRequest = createAction('GET_DISTRICT_REQUEST');
export const getDistrictsSuccess = createAction('GET_DISTRICT_SUCCESS');
export const deleteDistrictsRequest = createAction('DELETE_DISTRICT_REQUEST');


export const getWardsRequest = createAction('GET_WARD_REQUEST');
export const getWardsSuccess = createAction('GET_WARD_SUCCESS');
export const deleteWardsRequest = createAction('DELETE_WARD_REQUEST');

export const saveLocalRegionRequest = createAction('SAVE_LOCAL_REGION_REQUTEST');
export const saveRegionWardsRequest = createAction('SAVE_REGION_WARDS_REQUTEST');
export const saveRegionStaffRequest = createAction('SAVE_REGION_STAFF_REQUTEST');

export const getPannersRequest = createAction('GET_PANNERS_REQUEST');
export const getPannersSuccess = createAction('GET_PANNERS_SUCCESS');

export const getRegionWardsRequest = createAction('GET_REGION_WARDS_REQUEST');
export const getRegionWardsSuccess = createAction('GET_REGION_WARDS_SUCCESS');

export const fetchRegionStationRequest = createAction('FETCH_REGION_STATION_REQUEST');
export const fetchRegionStationSuccess = createAction('FETCH_REGION_STATION_SUCCESS');
export const toggleRegionStationRequest = createAction('TOGGLE_REGION_STATION_REQUEST');
export const deleteRegionStationRequest = createAction('DELETE_REGION_STATION_REQUEST');

export const fetchRegionTreeRequest = createAction('FETCH_REGION_TREE_REQUEST');
export const fetchRegionTreeSuccess = createAction('FETCH_REGION_TREE_SUCCESS');

export const fetchRegionStaffRequest = createAction('FETCH_REGION_STAFF_REQUEST');
export const fetchRegionStaffSuccess = createAction('FETCH_REGION_STAFF_SUCCESS');
export const deleteRegionStaffRequest = createAction('DELETE_REGION_STAFF_REQUEST');

export const saveWardInfoRequest = createAction('SAVE_WARD_INFO_REQUTEST');
export const deleteWardInfoRequest = createAction('DELETE_WARD_INFO_REQUTEST');

export const downloadLocationListRequest = createAction('DOWNLOAD_LOCATION_LIST_REQUEST');
export const downloadLocationListSuccess = createAction('DOWNLOAD_LOCATION_LIST_SUCCESS');

export const failure = createAction('REGION_ACTION_FAILURE');


// - Initial State
export const initialState = {
  regions: [],
  regioning: false,

  provinceAlls: [],
  provinces: [],
  provinceing: false,

  districts: [],
  districting: false,

  wards: [],
  warding: false,

  regionwards: [],
  regionwardsing: false,

  regionstations: [],
  regionstationsing: false,
  pagination: {
    Page: 1,
    Size: 50,
    Total: 0
  },
  regionstaffs: [],
  regionstaffsing: false,

  trees: [],
  treesing: false,

  panners: [],
  pannersing: false,

  downloading: false,

  error: null,
};

// - Reducers
const reducer = handleActions({
  [getRegionsRequest]: (state) => {
    return { ...state, regions: [], regioning: true };
  },
  [getRegionsSuccess]: (state, {payload: { regions }}) => {
    return { ...state, regions, regioning: false  };
  },

  //Provinces
  [fetchProvincesRequest]: (state) => {
    return { ...state, provinceing: true };
  },
  [fetchProvincesSuccess]: (state, {payload: { provinces }}) => {
    return { ...state, provinceAlls: provinces, provinceing: false  };
  },

  //Provinces
  [getProvincesRequest]: (state) => {
    return { ...state, provinceing: true };
  },
  [getProvincesSuccess]: (state, {payload: { provinces }}) => {
    return { ...state, provinces, provinceing: false  };
  },

  //Districts
  [getDistrictsRequest]: (state) => {
    return { ...state, districting: true };
  },
  [getDistrictsSuccess]: (state, {payload: { districts }}) => {
    return { ...state, districts, districting: false  };
  },

  //Wards
  [getWardsRequest]: (state) => {
    return { ...state, warding: true };
  },
  [getWardsSuccess]: (state, {payload: { wards }}) => {
    return { ...state, wards, warding: false  };
  },

  //Panners
  [getPannersRequest]: (state) => {
    return { ...state, pannersing: true };
  },
  [getPannersSuccess]: (state, {payload: { panners }}) => {
    return { ...state, panners, pannersing: false  };
  },

  //Region Wards
  [getRegionWardsRequest]: (state) => {
    return { ...state, regionwardsing: true };
  },
  [getRegionWardsSuccess]: (state, {payload: { regionwards }}) => {
    return { ...state, regionwards, regionwardsing: false  };
  },

  //Region stations
  [fetchRegionStationRequest]: (state) => {
    return { ...state, regionstationsing: true };
  },
  [fetchRegionStationSuccess]: (state, {payload: { Data, Pagination }}) => {
    return { ...state, regionstations: Data, pagination: Pagination, regionstationsing: false  };
  },

  //Region tree
  [fetchRegionTreeRequest]: (state) => {
    return { ...state, treesing: true };
  },
  [fetchRegionTreeSuccess]: (state, {payload: { trees }}) => {
    return { ...state, trees: trees, treesing: false  };
  },

  //Region staff
  [fetchRegionStaffRequest]: (state) => {
    return { ...state, regionstaffsing: true };
  },
  [fetchRegionStaffSuccess]: (state, {payload: { Data }}) => {
    return { ...state, regionstaffs: Data, regionstaffsing: false  };
  },

  [downloadLocationListRequest]: (state) => {
    return {...state, downloading: true }
  },
  [downloadLocationListSuccess]: (state) => {
      return {...state, downloading: false }
  },

  [failure]: (state, action) => ({
    ...state,
    error: action.error,
    regioning: false,
  }),
}, initialState);

export default reducer;
