import './style.scss';
import React from 'react';
import { Icons } from '../../assets';
import { Layout, Select, Popover, Checkbox, Pagination, Table, Button, Input, Popconfirm } from 'antd';
import { AppstoreAddOutlined, UndoOutlined, AppstoreOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import StationForm from './components/StationForm';
import RestrictPage from './../../components/RestrictComponents/RestrictPage';

const { Content } = Layout;

const StationV = (props) => {
    const { pagination, params, manageStations, manageStation, loading, clients } = props;
    const { fetchManageStations, editManageStation, isMobile, exportExcel } = props;
    const { open, close } = props;
    const size = params.Size && Number(params.Size) > 0 ? Number(params.Size) : 20;
    const offset = params.Offset && Number(params.Offset) > 0 ? Number(params.Offset) : 1;
    const total = pagination && pagination.Total && Number(pagination.Total) > 0 ? Number(pagination.Total) : 0;
    const n_start = total > 0 ? (((offset - 1) * size) + 1) : 0;
    const n_end = total > 0 ? ((offset * size) > total ? total : offset * size) : 0;
    return (
        <RestrictPage>
            <Content className="page-menus">
            <div className="panel-header">
                <div className="header-title">
                    <i className="fa fa-home icon" aria-hidden="true"></i>
                    <span>Hệ thống</span>
                    <span className="icon-next"><i className="fa fa-angle-right"></i></span>
                    <span className="active">Quản lý bưu cục</span>
                </div>
            </div>
            <div className={isMobile ? "col-md-12 pd-0" : "col-md-12"}>
                <div className="panel-body">
                    <div className="row">
                        <div className="col-md-4">
                            <label className="total-filter">
                                <img src={Icons.common.filter} alt="Số bưu cục" />|&ensp;<span>{n_start} - {n_end}</span> trong tổng <span>{total}</span> bưu cục
                            </label>
                        </div>
                        <div className="col-md-8 text-right">
                            <span>
                                <Input.Search defaultValue={params.findKey || ''} placeholder="Tìm kiếm bưu cục..." onSearch={(value) => fetchManageStations({findKey: value})} style={{maxWidth: isMobile ? '210px':'240px'}}/>
                            </span>
                            &emsp;
                            <Button onClick={() => editManageStation(null)}   type="primary" title="Thêm bưu cục" icon={<AppstoreAddOutlined />}>{!isMobile &&  'Thêm bưu cục'}</Button>&ensp;
                            <Button icon={<img src={Icons.common.excel} alt="" />} onClick={exportExcel} title="Xuất excel danh sách bưu cục" type="primary">{!isMobile &&  'Xuất excel'}</Button>&emsp;
                            <Button onClick={() => fetchManageStations()} title="Làm mới" icon={<UndoOutlined />}>{!isMobile &&  'Làm mới'}</Button>&ensp;
                            <Popover placement="bottomRight" content={content(props)} trigger="click">
                                <Button title="Ẩn/Hiển thị cột" icon={<AppstoreOutlined />} />
                            </Popover>
                        </div>
                    </div>
                    <div className="row mr-t-10">
                        <div className="col-md-12">
                            <Table
                                loading={loading}
                                className="table-main"
                                columns={createColums(props)}
                                rowKey="STATION_CODE"
                                size="small"
                                dataSource={manageStations || []}
                                pagination={false}
                                bordered={true}
                                scroll={{x: 800}}
                            />
                        </div>
                    </div>
                    <div className="row mr-t-10">
                        <div className="col-md-6">
                            <span>Hiển thị &ensp;
                                <Select value={size || 20} style={{ width: 120 }} onChange={(size) => fetchManageStations({ Size: size, Offset: 1 })}>
                                    <Select.Option value="20">20</Select.Option>
                                    <Select.Option value="50">50</Select.Option>
                                    <Select.Option value="100">100</Select.Option>
                                    <Select.Option value="200">200</Select.Option>
                                </Select>
                                &nbsp; bưu cục / trang
                            </span>
                        </div>
                        <div className="col-md-6 text-right">
                            <Pagination current={offset || 1} pageSize={size || 20} total={total || 0} showSizeChanger={false} onChange={(offset) => fetchManageStations({ Offset: offset })} />
                        </div>
                    </div>
                </div>
            </div>
            {open && <StationForm open={open} close={close} clients={clients} ClientId={params.ClientId} manageStation={manageStation} fetchManageStations={fetchManageStations} />}
        </Content>
        </RestrictPage>
    );
};

export default StationV

// Tạo ra danh sách cột trên bảng
const createColums = ({ columns = [], editManageStation, deleteManageStation }) => {
    const _columns = [];
    if (columns && columns.length > 0) {
        columns.forEach((col) => {
            if (col.show) {
                if (col.id !== 'IS_ACTIVE') {
                    _columns.push({ title: col.name, dataIndex: col.id, key: col.id, width: col.width, className: col.class })
                }
                else {
                    _columns.push(
                        {
                            title: 'Trạng thái', dataIndex: '', key: 'IsActive', className: "text-center", align: 'center', width: 50, render: (item) => {
                                return <Checkbox checked={item.IS_ACTIVE} />
                            }
                        })
                }
            }
        });
        var functionColumn = {
            title: 'Chức năng', dataIndex: '', key: 'action', className: "text-center", align: 'center', width: 50, render: (manageStation) => {
                return <div>
                    <Button icon={<EditOutlined />} title="Chi tiết bưu cục" size="small" type="primary" onClick={() => editManageStation(manageStation)}></Button>&ensp;
                    <Popconfirm title="Bạn có chắc muốn xóa?" onConfirm={() => deleteManageStation(manageStation)} placement="topRight" okText="Xóa" cancelText="Không">
                        <Button icon={<DeleteOutlined />} title="Xóa bưu cục" size="small" type="danger" />
                    </Popconfirm>
                </div>
            }
        };
        _columns.push(functionColumn);
    }
    return _columns;
}

const content = ({ columns, stateColumn }) => {
    return <div className="row" style={{ maxWidth: '400px' }}>
        {columns && columns.map((col, key) =>
            <div className="col-md-6" key={key} style={{ padding: '5px 15px' }}><Checkbox checked={col.show} onChange={() => stateColumn(col)} >{col.name}</Checkbox></div>)
        }
    </div>
}