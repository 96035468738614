import React from 'react';
import _ from 'lodash';
import { NumberCommas } from '../../../../../helpers/common';

const ViewCommission = (props) => {
  const { price } = props;

  let prices = [];
  let cols = [];
  let rows = {};
  if(price && price.Details) {
    price.Details.forEach(price => {
      price.Prices.forEach((item) => {
        rows[`${price.FromZone}-${item.ToZone}`] = item.RouteName;
        prices.push({...item, ToZone: `${price.FromZone}-${item.ToZone}` })
      })
    })
    cols = Object.keys(rows).map(key => ({id: key, name: rows[key]}));
    cols = _.orderBy(cols, ['name'], 'asc');
  }

  if(prices && prices.length) {
    prices = _.chain(prices)
              .orderBy((item) => item.From, 'asc')
              .groupBy((item) => item.To ? `Từ ${item.From} đến ${item.To}`: `Trên ${item.From}`)
              .map((items, ToZone) => ({ ToZone: ToZone, Prices: _.orderBy(items, ['To'], 'asc') }))
              .value();
  }

  return (
          <div>
            <table className="table table-bordered table-price">
              <thead>
                <tr>
                  <th rowSpan={2} style={{width: '160px'}} className="text-center">Trọng lượng (gram)</th>
                  <th colSpan={cols.length} style={{width: '800px'}} className="text-center">Điển đến</th>
                </tr>
                <tr>
                  {cols.map((item, key) => <th key={key} className="text-center">{item.name}</th>)}
                </tr>
              </thead>
              <tbody>
                {prices.map((price) => {
                  return (
                    <tr>
                      <td className="bold" style={{textAlign: 'left'}}>{price.ToZone}</td>
                      {cols.map((item, key) => {
                        let _price = price.Prices.find(i => i.ToZone === item.id);
                        return (<td key={key} className="text-right">
                                  {_price?.Step ? '' : <label className="w-100 mr-0">{NumberCommas(_price?.PriceOnStep || '-', 0)}</label>}
                                  {_price?.Step ? <small>Mỗi {_price.Step}(gram) + {NumberCommas(_price.PriceOnStep || '-',0)}</small>: ''}
                                </td>)
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
  );
};

export default ViewCommission;
