import React from "react";
import { Form, Checkbox } from "antd";

const FormItem = Form.Item;

const makeField = Component => ({ input, meta, children, hasFeedback, label, text, config, ...rest }) => {
  const hasError = meta.touched && meta.invalid;
  return (
    <FormItem
      {...config}
      label={label}
      validateStatus={hasError ? "error" : "success"}
      hasFeedback={hasFeedback && hasError}
      help={hasError && meta.error}>
        <Component {...input} {...rest} checked={input && input.value ? input.value : false } children={children} />&ensp;{text}
    </FormItem>
  );
};


export default makeField(Checkbox);