import axios from 'axios';
import { apiEndpointHR } from '../config'
import Auth from '../storage/Auth'
import store from "../reduxs/store";

const axiosInstance = axios.create({
  baseURL: `${apiEndpointHR}`
  // timeout: 300000, // 5 phút
});

axiosInstance.interceptors.request.use(
    (config) => {
      const oidc = store.getState().oidc;
      if(oidc && oidc.user && oidc.user.access_token) {
        const token = oidc.user.access_token;
        config.headers.authorization = `Bearer ${token}`;
      }
      // const token = Auth.getToken();
      // config.headers.authorization = `Bearer ${token}`;
      const stationCode = Auth.getStation();
      if (stationCode) {
        config.headers['station-code'] = stationCode;
      }
      return config;
    },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response) {
      const { data } = error.response;
      if (data) {
          // if(data.StatusCode === 500 && showLogout) {
          //   showLogout = false;
          //   Modal.error({
          //     title: 'Lỗi!',
          //     content: 'Máy chủ đang gặp sự cố. Xin vui lòng tải lại',
          //     okText: 'Tải lại',
          //     onOk() {
          //       showLogout = true;
          //       window.location.reload();
          //     },
          //   });
          // }
          return Promise.reject(data)
      }
    }
    return Promise.reject(error)
  })

export default axiosInstance;
