import TermV from './TermV';
import { lifecycle, compose as recompose, withHandlers, withState } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getAccountInfoRequest, getInvoicesRequest, getWarehousesRequest, getBanksRequest } from './../../../reduxs/account-redux/reducer';
import { selectAccount, selectAccounting, selectInvoices, selectWarehouses, selectBanks } from '../../../reduxs/account-redux/selectors';

export function mapDispatchToProps(dispatch) {
  return {
    getUser: (params) => dispatch(getAccountInfoRequest(params)),
    getInvoices: (params) => dispatch(getInvoicesRequest(params)),
    getWarehouses: (params) => dispatch(getWarehousesRequest(params)),
    getBanks: (params) => dispatch(getBanksRequest(params)),
    // getTypes: (params) => dispatch(getAccountTypeRequest(params)),
  };
}

const mapStateToProps = createStructuredSelector({
  user: selectAccount(),
  loading: selectAccounting(),
  invoices: selectInvoices(),
  wareHouses: selectWarehouses(),
  banks: selectBanks(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default recompose(
  withConnect,
  withState('serviceId', 'setServiceId', null),
  withHandlers({
  }),
  lifecycle({
    componentDidMount() {
      const { account, getInvoices, getWarehouses, getUser, getBanks } = this.props;
      getUser({ AccountNr: account.AccountNr==='-1'?account.Id:account.AccountNr });
      getInvoices({ AccountNr: account.AccountNr });
      getWarehouses({ Id: account.Id, AccountNo: account.AccountNr });
      getBanks({ AccountNo: account.AccountNr });
    },
  }),
)(TermV);
