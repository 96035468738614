import DocumentV from './DocumentV'
import { message } from 'antd';
import { compose as recompose, withHandlers, lifecycle, withState } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import { selectCustomerDocs, selectCustomerDocsing, selectTypeCustomerDoc } from '../../../reduxs/customer-doc-redux/selectors';
import { getCustomerDocRequest, uploadCustomerDocRequest, removeCustomerDocRequest, getDocTypeRequest } from '../../../reduxs/customer-doc-redux/reducer';



function mapDispatchToProps(dispatch) {
    return {
        getCustomerDoc: (params) => dispatch(getCustomerDocRequest(params)),
        uploadCustomerDoc: (params) => dispatch(uploadCustomerDocRequest(params)),
        delCustomerDoc: (params) => dispatch(removeCustomerDocRequest(params)),
        getTypeCustomerDoc: (params) => dispatch(getDocTypeRequest(params)),
    };
}

const mapStateToProps = createStructuredSelector({
  documents: selectCustomerDocs(),
  loading: selectCustomerDocsing(),
  categories: selectTypeCustomerDoc(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default recompose(
    withConnect,
    withState('open', 'setOpen', false),
    withState('category', 'setCategory', null),
    withState('uploading', 'setUploading', false),
    withState('note', 'setDoc', null),
    withState('sort', 'setSort', 'Name'),
    withState('view', 'setView', 'list'),
    withState('type', 'setType', ''),
    withHandlers({
        fetchCustomerDoc: ({customer, getCustomerDoc}) => () => {
            const data = {
              AccountNo: customer.AccountNr
            }
            getCustomerDoc(data)
        }
    }),
    withHandlers({
      onUpFile: ({ customer, category, setOpen, setUploading, fetchCustomerDoc, uploadCustomerDoc}) => (e) => {
        if(customer && category) {
          const files = e.target.files;
          if (files && files.length) {
            const body = {
              data: {
                CategoryId: category,
                CustomerId: customer.Id,
                Files: [...files]
              },
              cb: (res) => {
                if(res) {
                  message.success('Tải lên thành công!');
                  setOpen(false, () => fetchCustomerDoc());
                } else {
                  message.error('Lỗi tải tài liệu')
                }
                setUploading(false)
              }
            }
            setUploading(true, () => uploadCustomerDoc(body));
          }
          e.target.value = null;
        } else {
          message.error("Không có file tải lên!")
        }
      },
      removeCustomerDoc: ({ customer, delCustomerDoc, fetchCustomerDoc }) => (item) => {
          if(item && item.Id) {
              delCustomerDoc({
                  data: {
                      DocId: item.Id,
                      CustomerId: customer.Id,
                  },
                  cb: (result) => {
                      if(result) {
                          message.success('Xóa thành công!');
                          fetchCustomerDoc();
                      } else {
                          message.error('Xóa ghi chú thất bại!')
                      }
                  }
              })
          };
      },
    }),
    lifecycle({
        componentDidMount() {
            this.props.fetchCustomerDoc();
            this.props.getTypeCustomerDoc();
        },
    })
)(DocumentV);
