
import './style.scss';
import React from 'react';
import TermVM from './TermVM';
import { Drawer } from 'antd';

const TermView = (props) => {
  const { open, close, account, fetchAccounts } = props;

  return (
    <Drawer
      placement='right'
      width={1200}
      style={{ maxWidth: '100%' }}
      onClose={() => close()}
      visible={open}
      maskClosable={false}
    >
      {open && <TermVM account={account} fetchAccounts={fetchAccounts} />}
    </Drawer>
  )
}
export default TermView
