import React from 'react';
import { connect } from 'react-redux';
import { Modal, Input, message, notification, Checkbox, Radio, Button } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { createStructuredSelector } from 'reselect';
import { Icons } from '../../../assets';
import { lifecycle, compose as recompose, withHandlers, withState} from 'recompose';

import { getRegionsRequest, saveLocalRegionRequest } from '../../../reduxs/region-redux/reducer';
import { selectRegions } from '../../../reduxs/region-redux/selectors';

import { selectProvinces } from '../../../reduxs/utility-redux/selectors';
import { getProvincesRequest } from '../../../reduxs/utility-redux/reducer';

export function mapDispatchToProps(dispatch) {
  return {
      getRegions: (params) => dispatch(getRegionsRequest(params)),
      getProvinces: (params) => dispatch(getProvincesRequest(params)),
      saveLocalRegion: (params) => dispatch(saveLocalRegionRequest(params))
  };
}

const mapStateToProps = createStructuredSelector({
  regions: selectRegions(),
  provinces: selectProvinces(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const ProvinceForm = (props) => {
  const { open, region, regions, loading, query, setQuery, setRegion, provinces, close, onSavePlace, checkedProvinces, setCheckedProvinces, fetchProvinces } = props;

  let _provinces = provinces
  if(query && provinces && provinces.length) {
    _provinces = provinces.filter((item) => (item.Name && item.Name.toUpperCase().indexOf(query.toUpperCase()) !== -1) || (item.Code && item.Code.indexOf(query) !== -1))
  }

  return (
    <Modal
      title='Thêm tỉnh thành'
      visible={open}
      style={{ top: 20 }}
      width={'960px'}
      footer={null}
      maskClosable={false}
      onOk={() => { setCheckedProvinces([]); close(false) }}
      onCancel={() => { setCheckedProvinces([]); close(false) }}
      className="modal-form-privince"
    >
      <div className="row">
        <div className="col-md-5">
          <div className="panel-body">
              <div className="row panel-header">
                  <div className="col-xs-8 pd-0">
                      <span className="title">Khu vực</span>
                  </div>
              </div>
              <hr/>
              <Radio.Group onChange={(e)=> {setRegion(e.target.value); fetchProvinces(e.target.value)}} value={Number(region)} className="list-region">
                  {regions && regions.map((item, key) => {
                      return <Radio key={key} value={item.Id} className="item-region region">{item.Name}<img src={Icons.common.marker}  alt=""/></Radio>
                  })}
              </Radio.Group>
          </div>
        </div>

        <div className="col-md-7">
          <div className="panel-body">
              <div className="row panel-header">
                <div className="col-xs-9 pd-0 search">
                    <Input.Search
                        value={query}
                        placeholder="Tìm kiếm tỉnh thành..."
                        onChange={e => setQuery(e.target.value)}
                    />
                </div>
                <div className="col-xs-3">
                  <Button className="pull-right mr-t-5" type="primary" onClick={() => onSavePlace()} icon={<SaveOutlined />} loading={loading}>Lưu</Button>
                </div>
              </div>
              <hr/>
              <Checkbox.Group value={checkedProvinces} onChange={(values)=> setCheckedProvinces(values)} className="list-region">
                  {_provinces && _provinces.map((item, key) => {
                      return <Checkbox key={key} value={item.Id} className="item-region">
                            {item.Prefix} {item.Name}
                            {   item.RegionId &&
                                <small className="pull-right">Đã được phân vùng</small>
                            }
                        </Checkbox>
                    })}
              </Checkbox.Group>
          </div>
        </div>
      </div>

    </Modal>
  );
};

export default recompose(
  withConnect,
  withState('query', 'setQuery', null),
  withState('loading', 'setLoading', false),
  withState('province', 'setProvince', null),
  withState('checkedProvinces', 'setCheckedProvinces', []),
  withHandlers({
    onSavePlace: ({ region, setRegion, setLoading, setCheckedProvinces, checkedProvinces, saveLocalRegion, close, fetchProvinces }) => () => {
      if(region && checkedProvinces && checkedProvinces.length) {
        const data = {
          RegionId: region,
          ProvinceId: checkedProvinces
        }

        const callback = (type) => {
          if(type === 'error') {
            notification[type]({message: 'Lưu thất bại!'});
          } else {
            notification[type]({message: 'Lưu thành công!'});
          }
          setRegion(null);
          setCheckedProvinces([]);
          fetchProvinces(region);
          close(false);
          setLoading(false);
        }

        const params ={
          data: data,
          cb: callback
        }

        setLoading(true)
        saveLocalRegion(params)
      } else {
        message.warning('Vui lòng chọn khu vực và tỉnh thành');
      }
    }
  }),
  lifecycle({
    componentDidMount() {
      const { getRegions, region, getProvinces } = this.props;
      getProvinces({CountryId: 234});
      getRegions({RegionId: region});
    },
    componentDidUpdate(props) {
      const { selects, setCheckedProvinces } = this.props;
      if(selects && selects.length !== props.selects.length) {
        let _selects = selects.map((item) => item.Id);
        setCheckedProvinces(_selects);
      }
    }
  }),
)(ProvinceForm);
