import './style.scss';
import React from 'react';
import { Layout, Select, Popover, Checkbox, Pagination, Table, Button, Input, Popconfirm } from 'antd';
import { UndoOutlined, EyeOutlined, AppstoreOutlined, DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Icons } from '../../../assets';
import moment from 'moment';
import RestrictPage from '../../../components/RestrictComponents/RestrictPage';
import ViewCode from './components/ViewCode';
import FormCode from './components/FormCode';
const { Content } = Layout;

const StaffsV = (props) => {
    const { params, codes, loading, pagination, code, setCode, view, setView, edit, setEdit, fetchCodes, isMobile } = props;

    const size = params.Size && Number(params.Size) > 0 ? Number(params.Size) : 20;
    const offset = params.Offset && Number(params.Offset) > 0 ? Number(params.Offset) : 1;
    const total = pagination && pagination.Total && Number(pagination.Total) > 0 ? Number(pagination.Total) : 0;
    const n_start = total > 0 ? (((offset - 1) * size) + 1) : 0;
    const n_end = total > 0 ? ((offset * size) > total ? total : offset * size) : 0;
    return (
        <RestrictPage>
            <Content className="page-menus">
                <div className="panel-header">
                    <div className="header-title">
                        <i className="fa fa-home icon" aria-hidden="true"></i>
                        <span>Tài khoản</span>
                        <span className="icon-next"><i className="fa fa-angle-right"></i></span>
                        <span className="active">Quản lý mã cha</span>
                        <Button type="primary" className="pull-right mr-t-5" onClick={() => setCode(null, () => setEdit(true))} icon={<PlusOutlined/>}>Thêm mới</Button>
                    </div>
                </div>
                <div className={isMobile ? "col-md-12 pd-0" : "col-md-12"}>
                    <div className="panel-body">
                        <div className="row">
                            <div className="col-md-4">
                                <label className="total-filter">
                                    <img src={Icons.common.filter} alt="Số bưu cục" />|&ensp;<span>{n_start} - {n_end}</span> trong tổng <span>{total}</span> mã
                                </label>
                            </div>
                            <div className="col-md-8 text-right">
                                <span>
                                    <Input.Search defaultValue={params.Query || ''} placeholder="Tìm kiếm..." onSearch={(value) => fetchCodes({Query: value})} style={{maxWidth: isMobile ? '210px':'280px'}}/>
                                </span>
                                &emsp;
                                <Button onClick={() => fetchCodes()} title="Làm mới" icon={<UndoOutlined />}>{!isMobile &&  'Làm mới'}</Button>&ensp;
                                <Popover placement="bottomRight" content={content(props)} trigger="click">
                                    <Button title="Ẩn/Hiển thị cột" icon={<AppstoreOutlined />} />
                                </Popover>
                            </div>
                        </div>
                        <div className="row mr-t-10">
                            <div className="col-md-12">
                                <Table
                                    loading={loading}
                                    className="table-main"
                                    columns={createColums(props)}
                                    rowKey={(row, key) => key}
                                    size="small"
                                    dataSource={codes || []}
                                    pagination={false}
                                    bordered={true}
                                    scroll={{x: 800}}
                                />
                            </div>
                        </div>
                        <div className="row mr-t-10">
                            <div className="col-md-6">
                                <span>Hiển thị &ensp;
                                    <Select value={size || 20} style={{ width: 120 }} onChange={(size) => fetchCodes({ Size: size, Offset: 1 })}>
                                        <Select.Option value="20">20</Select.Option>
                                        <Select.Option value="50">50</Select.Option>
                                        <Select.Option value="100">100</Select.Option>
                                        <Select.Option value="200">200</Select.Option>
                                    </Select>
                                    &nbsp; mã / trang
                                </span>
                            </div>
                            <div className="col-md-6 text-right">
                                <Pagination current={offset || 1} pageSize={size || 20} total={total || 0} showSizeChanger={false} onChange={(offset) => fetchCodes({ Offset: offset })} />
                            </div>
                        </div>
                    </div>
                    { edit && <FormCode open={edit} close={() => setCode(null, () => setEdit(false))} code={code} resfresh={fetchCodes} /> }
                    { view && code && <ViewCode open={view} close={() => setCode(null, () => setView(false))} code={code}/> }
                </div>
            </Content>
        </RestrictPage>
    );
};

export default StaffsV

const createColums = ({ columns = [], onViewCode, onEditCode, onDeleteCode }) => {
    const _columns = [];
    if (columns && columns.length > 0) {
        columns.forEach((col) => {
            if (col.show) {
                if(col.type === "date") {
                    _columns.push({ title: col.name, key: col.id, width: col.width, className: col.class, render: (item) => item[col.id] ? <span><i className="fa fa-calendar-o"/>&ensp;{moment(item[col.id]).format("DD-MM-YYYY")}</span> : 'N/A' })
                 } else {
                    _columns.push({ title: col.name, dataIndex: col.id, key: col.id, width: col.width, className: col.class })
                }
            }
        });
        var functionColumn = {
            title: 'Chức năng', dataIndex: '', key: 'action', fixed: "right", className: "text-center", align: 'center', width: 70, render: (item) => {
                return <div>
                    <Button icon={<EyeOutlined />} title="Xem chi tiết" size="small" onClick={() => onViewCode(item)}></Button>&ensp;
                    <Button icon={<EditOutlined />} title="Cập nhật thông tin" size="small" onClick={() => onEditCode(item)}></Button>&ensp;
                    <Popconfirm title="Bạn có chắc muốn xóa?" onConfirm={() => onDeleteCode(item.Id)} placement="topRight" okText="Xóa" cancelText="Không" okType="danger">
                        <Button icon={<DeleteOutlined />} title="Xóa bưu cục" size="small" type="danger" />
                    </Popconfirm>
                </div>
            }
        };
        _columns.push(functionColumn);
    }
    return _columns;
}

const content = ({ columns, stateColumn }) => {
    return <div className="row" style={{ maxWidth: '400px' }}>
        {columns && columns.map((col, key) =>
            <div className="col-md-6" key={key} style={{ padding: '5px 15px' }}><Checkbox checked={col.show} onChange={() => stateColumn(col)} >{col.name}</Checkbox></div>)
        }
    </div>
}