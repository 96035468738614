import { lifecycle, compose as recompose, withState, withHandlers, withPropsOnChange, pure } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Modal, message } from 'antd';
import _ from 'lodash';

import { selectSchedules, selectSchedulesing, selectScheduleTypes  } from '../../../reduxs/account-redux/selectors';
import { getSchedulesRequest, typeScheduleRequest , saveScheduleRequest  } from '../../../reduxs/account-redux/reducer';

import ScheduleV from './ScheduleV';

export function mapDispatchToProps(dispatch) {
  return {
    getSchedules: (params) => dispatch(getSchedulesRequest(params)),
    getTypes: (params) => dispatch(typeScheduleRequest(params)),
    saveSchedule: (params) => dispatch(saveScheduleRequest(params))
  };
}

const mapStateToProps = createStructuredSelector({
  schedules: selectSchedules(),
  schedulesing: selectSchedulesing(),
  types: selectScheduleTypes(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default recompose(
  withConnect,
  withState('type', 'setType', ''),
  withState('valueWeeks', 'setValueWeeks', []),
  withState('valueMonths', 'setValueMonths', []),
  withHandlers({
    saveValue: ({ type, account, getSchedules, saveSchedule }) => (value, mode) => {
      if(value) {
        const params = {
          data: {
            Type: 'value',
            Id: account.Id,
            TypeId: type,
            Value: value,
            Mode: mode
          },
          cb: (res) => {
            if(res === 'error') {
              message.error('Lưu thất bại!')
            } else {
              getSchedules({ CustomerId: account.Id });
              message.success('Lưu thành công!')
            }
          }
        }
        saveSchedule(params);
      }
    },
    delValue: ({ account, getSchedules, saveSchedule }) => (Ids) => {
      if(Ids && Ids.length) {
        const params = {
          data: {
            Type: 'del',
            Ids: Ids,
          },
          cb: (res) => {
            if(res === 'error') {
              message.error('Lỗi xóa ngày!')
            } else {
              getSchedules({ CustomerId: account.Id });
              message.success('Bỏ thành công!')
            }
          }
        }
        saveSchedule(params);
      }
    },
  }),
  withHandlers({
    onScheduleType: ({ account, setType, saveSchedule }) => (value) => {
      const params = {
        data: {
          Type: 'type',
          Id: account.Id,
          Value: value,
        },
        cb: (type) => {
          if(type === 'error') {
            message.error('Thay đổi lịch chốt cước thất bại!')
          } else {
            setType(value);
            message.success('Lưu thay đổi thành công!');
          }
        }
      }
      saveSchedule(params);
    },
    onScheduleValue: ({ types, valueWeeks, valueMonths, saveValue, delValue }) => (values, mode) => {
      if(values) {
        if(mode === 'week') {
          let _type = types.find(k => k.TypeId === 'WEEK');
          if(_type && _type.MaxTime < values.length) {
            Modal.warning({title: `Bạn chỉ được phép chọn tối đa ${_type.MaxTime} ngày.`, content: 'Bạn có thể bỏ chọn ngày hiện tại để chọn ngày khác'})
          } else {
            let weeks = [...valueWeeks].map((i) => i.Value);
            if(values.length > valueWeeks.length) {
              let item = _.difference(values, weeks)[0];
              saveValue(item, weeks.length);
            } else {
              let items = _.difference(weeks, values);
              if(items && items.length) {
                let Ids = valueWeeks.filter((i) => items.includes(i.Value)).map(j => j.Id);
                if(Ids && Ids.length) {
                  delValue(Ids);
                }
              }
            }
          }
        }
        if(mode === 'month') {
          let _type = types.find(k => k.TypeId === 'MONTH');
          if(_type && _type.MaxTime <= values.length) {
            Modal.warning({title: `Bạn chỉ được phép chọn tối đa ${_type.MaxTime} ngày.`, content: 'Bạn có thể bỏ chọn ngày hiện tại để chọn ngày khác'});
          } else {
            let months = [...valueMonths].map((i) => i.Value);
            if(values.length > valueMonths.length) {
              let item = _.difference(values, months)[0];
              saveValue(item, months.length);
            } else {
              let items = _.difference(months, values);
              if(items && items.length) {
                let Ids = valueMonths.filter((i) => items.includes(i.Value)).map(j => j.Id);
                if(Ids && Ids.length) {
                  delValue(Ids);
                }
              }
            }
          }

        }
      }
    }
  }),
  withPropsOnChange(['schedules'], ({schedules, setType, setValueMonths, setValueWeeks}) => {
    if(schedules) {
      setValueMonths(schedules.ScheduleDetails?.MONTH || [])
      setValueWeeks(schedules.ScheduleDetails?.WEEK || [])
      setType(schedules.TypeActivated)
    }
  }),
  lifecycle({
    componentDidMount() {
      const { account, types, getSchedules, getTypes } = this.props;
      getSchedules({ CustomerId: account.Id });
      if(!(types && types.length)) {
        getTypes();
      }
    },
  }),
  pure
)(ScheduleV);