import React from 'react';
import { Button, Modal, Form, notification } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { compose as recompose, withHandlers, withProps} from 'recompose';
import { Field, reduxForm } from 'redux-form';
import { required  } from '../../../helpers/validate';
import { FieldInput, FieldSelect } from '../../../components/Fields';


import { DISTRICT_FORM_KEY, DistrictFormAction } from '../../../reduxs/region-redux/action';

const DistrictForm = (props) => {
  const { open, close, district, reset, error, handleSubmit ,submitting} = props;
  const submit = handleSubmit(DistrictFormAction);

  return (
    <Modal
      title={district && district.Id ? 'Cập nhật quận huyện' : 'Thêm quận huyện'}
      visible={open}
      style={{ top: 20 }}
      width={'460px'}
      footer={null}
      maskClosable={false}
      onOk={() => { reset(); close(false) }}
      onCancel={() => { reset(); close(false) }}
      className="modal-form-region"
    >
      <Form layout="vertical" onFinish={submit}>
        <div className="row">
          <div className="col-md-6">
            <Field
              component={FieldSelect}
              label="Prefix"
              name="Prefix"
              placeholder=""
              options={[
                {value: 'Huyện', text: 'Huyện'},
                {value: 'Quận', text: 'Quận'},
                {value: 'Thị Xã', text: 'Thị Xã'}
              ]}
              className="required"
              validate={[required]}
            />
          </div>
          <div className="col-md-6">
            <Field
              component={FieldInput}
              label="Code"
              name="Code"
              placeholder=""
              className="required"
              validate={[required]}
            />
          </div>
          <div className="col-md-12">
            <Field
              component={FieldInput}
              label="Tên quận huyện"
              name="Name"
              placeholder="Nhập tên quận huyện"
              className="required"
              validate={[required]}
            />
          </div>
          {/* <div className="col-md-7">
            <Field
              component={FieldInput}
              label="Vị trí địa lý"
              name="Coordination"
              placeholder=""
            />
          </div> */}
        </div>
        <p>Ghi chú:&ensp;<span className="note-red">Trường không được để trống</span></p>
        <Form.Item className="text-center">
            <Button type="primary" icon={<SaveOutlined />} htmlType="submit" loading ={submitting} >Lưu lại</Button>
            <p className="text-center text-danger">{error}</p>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default recompose(
    withProps(({district, ProvinceId}) => {
        if(district && district.Id) {
          return { initialValues: { ProvinceId, ...district }};
        } else {
          return { initialValues: { ProvinceId, Prefix: 'Huyện' }};
        }
      }
    ),
    withHandlers({
    }),
    reduxForm({
      form: DISTRICT_FORM_KEY,
      enableReinitialize: true,
      onSubmitSuccess: (result, dispatch, props) => {
          notification.success({
              message: 'Lưu thành công!'
          });
          props.initialValues = null;
          props.reset();
          props.close(false);
          props.fetchDistricts();
      }
    })
)(DistrictForm);
