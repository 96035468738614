import { call, put, takeLatest } from 'redux-saga/effects';
import * as PickupService from '../../services/pickup-route-service';

import { PickupFormAction, RegionFormAction, StaffFormAction } from "./action";
import { SubmissionError } from "redux-form";


import {
    fetchPickupRoutesSuccess,
    getPickupRouteSuccess,
    getPickupStaffSuccess,
} from './reducer';

export function* fetchPickupRoutes({ payload = {} }) {
    try {
        const { Result } = yield call(PickupService.fetchPickupRoutes, payload);
        if(Result) {
            yield put(fetchPickupRoutesSuccess({ routes: Result }));
        } else {
            yield put(fetchPickupRoutesSuccess({ routes: [] }));
        }
    } catch (error) {
        yield put(fetchPickupRoutesSuccess({ routes: [] }));
    }
}

export function* getPickupRoute({ payload = {} }) {
    try {
        const { Result } = yield call(PickupService.getPickupRoute, payload);
        if(Result) {
            yield put(getPickupRouteSuccess({ routers: Result }));
        } else {
            yield put(getPickupRouteSuccess({ routers: [] }));
        }
    } catch (error) {
        yield put(getPickupRouteSuccess({ routers: [] }));
    }
}

export function* getPickupStaff({ payload = {} }) {
    try {
        const { Result } = yield call(PickupService.getPickupStaff, payload);
        if(Result) {
            yield put(getPickupStaffSuccess({ pickups: Result }));
        } else {
            yield put(getPickupStaffSuccess({ pickups: [] }));
        }
    } catch (error) {
        yield put(getPickupStaffSuccess({ pickups: [] }));
    }
}

export function* saveRoute({ payload }) {
    try {
      let { Result } = yield call(PickupService.saveRoute, payload);
      if (Result) {
        yield put(PickupFormAction.success("Lưu thành công!"));
      } else {
        const formError = new SubmissionError({
          _error: "Lưu thất bại!"
        });
        yield put(PickupFormAction.failure(formError));
      }
    } catch (error) {
      const formError = new SubmissionError({
        _error: error
      });
      yield put(PickupFormAction.failure(formError));
    }
}

export function* saveRegion({ payload }) {
    try {
      let { Result } = yield call(PickupService.saveRegion, payload);
      if (Result) {
        yield put(RegionFormAction.success("Lưu thành công!"));
      } else {
        const formError = new SubmissionError({
          _error: "Lưu thất bại!"
        });
        yield put(RegionFormAction.failure(formError));
      }
    } catch (error) {
      const formError = new SubmissionError({
        _error: error
      });
      yield put(RegionFormAction.failure(formError));
    }
}

export function* saveStaffPickup({ payload }) {
  try {
    let { Result } = yield call(PickupService.saveStaffPickup, payload);
    if (Result) {
      yield put(StaffFormAction.success("Lưu thành công!"));
    } else {
      const formError = new SubmissionError({
        _error: "Phân tuyến cho nhân viên thất bại!"
      });
      yield put(StaffFormAction.failure(formError));
    }
  } catch (error) {
    const formError = new SubmissionError({
      _error: error.Message || 'Không thể phân tuyến cho nhân viên'
    });
    yield put(StaffFormAction.failure(formError));
  }
}


export function* removeRoute({ payload }) {
    const { data, cb } = payload;
    try {
      const { Result } = yield call(PickupService.deleteRoute, data);
      if(Result) {
        cb(true)
      } else {
        cb(false)
      }
    } catch (error) {
      cb(false)
    }
}

export function* removeRegion({ payload }) {
    const { data, cb } = payload;
    try {
      const { Result } = yield call(PickupService.deleteRegion, data);
      if(Result) {
        cb(true)
      } else {
        cb(false)
      }
    } catch (error) {
      cb(false)
    }
}

export function* removePickup({ payload }) {
  const { data, cb } = payload;
  try {
    const { Result } = yield call(PickupService.deletePickup, data);
    if(Result) {
      cb(true)
    } else {
      cb(false)
    }
  } catch (error) {
    cb(false)
  }
}

export default [
    takeLatest(PickupFormAction.REQUEST, saveRoute),
    takeLatest(RegionFormAction.REQUEST, saveRegion),
    takeLatest(StaffFormAction.REQUEST, saveStaffPickup),
    takeLatest('FETCH_PICKUP_ROUTE_REQUEST', fetchPickupRoutes),
    takeLatest('GET_PICKUP_ROUTE_REQUEST', getPickupRoute),
    takeLatest('GET_PICKUP_STAFF_REQUEST', getPickupStaff),
    takeLatest('REMOVE_PICKUP_ROUTE_REQUEST', removeRoute),
    takeLatest('REMOVE_PICKUP_REGION_REQUEST', removeRegion),
    takeLatest('REMOVE_PICKUP_STAFF_REQUEST', removePickup)
];
