import { call, put, takeLatest } from 'redux-saga/effects';
import * as UserService from '../../services/user-service';
// import { ZoneFormAction } from './action';
// import { SubmissionError } from 'redux-form';

import {
  searchStaffSuccess,
  searchMyStaffSuccess,
  getStaffByCodeSuccess,
  failure,
} from './reducer';


export function* searchStaffs({ payload = {} }) {
  try {
    const { Result } = yield call(UserService.searchStaffs, payload);
    if(Result) {
      yield put(searchStaffSuccess({ staffs: Result }));
    }
  } catch (error) {
    yield put(failure(error));
  }
}

export function* searchMyStaffs({ payload = {} }) {
  try {
    const { Result } = yield call(UserService.searchMyStaffs, payload);
    if(Result) {
      yield put(searchMyStaffSuccess({ staffs: Result }));
    }
  } catch (error) {
    yield put(failure(error));
  }
}

export function* getStaffsCode({ payload = {} }) {
  try {
    const { Result } = yield call(UserService.getStaffsByCode, payload);
    if(Result) {
      yield put(getStaffByCodeSuccess({ staffs: Result }));
    }
  } catch (error) {
    yield put(failure(error));
  }
}

export default [
  takeLatest('SEARCH_STAFF_REQUEST', searchStaffs),
  takeLatest('SEARCH_MY_STAFF_REQUEST', searchMyStaffs),
  takeLatest('GET_STAFF_BY_CODE_REQUEST', getStaffsCode),
];
