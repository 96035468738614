
import './style.scss';
import _ from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import { Layout, Tooltip, Button, Table, Tabs, Menu, Radio, Dropdown, Empty } from 'antd';
import { ReloadOutlined, MenuOutlined, UserOutlined, PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { NumberCommas } from '../../helpers/common';
import PickupRoute from './components/PickupRoute';
import PickupStaffs from './components/PickupStaffs';
import PickupRegion from './components/PickupRegion';

const { Content } = Layout;
const { TabPane } = Tabs;
// const { RangePicker } = DatePicker;

const actionProvince = (route, { close, setRoute, onRemoveRoute}) => {
  return (
      <Menu>
          <Menu.Item onClick={() => setRoute(route, () => close(true))}>
              <span><EditOutlined style={{color: '#0d7ecc'}} />Cập nhật</span>
          </Menu.Item>
          <Menu.Item onClick={() => onRemoveRoute(route)}>
              <span><DeleteOutlined style={{color: 'red'}} />Xóa bỏ</span>
          </Menu.Item>
      </Menu>
  )
}

const PickupRouteV = (props) => {
    const { details, detailsing, pickups, group, setGroup, routes } = props;
    const { route, setRoute, open, close, fetchPickups, fetchRoutes, getPickupRoute, onRemovePickup } = props;
    const { selected, setRegion, view, setView, display, setDisplay } = props;

    let results = _.chain(pickups)
    .groupBy((item) => group ? item.StaffCode :item.RouteId )
    .map(function(items) {
      return {
        item: items[0],
        childrens: items,
      }
    }).value();

    return (
        <Content className="page-pickup-route" id="body">
            <div className="panel-header">
                <div className="header-title">
                    <i className="fa fa-home icon" aria-hidden="true"></i>
                    <Link to="/"><span>Dashboard</span></Link>
                    <span className="icon-next"><i className="fa fa-angle-right"/></span>
                    <span className="active">Phân tuyến Pickup</span>
                </div>
            </div>
            <div className="col-md-12">
                <div className="panel-body">
                <Tabs defaultActiveKey="1" tabBarExtraContent={<Button type='primary' onClick={() => setDisplay(true)} icon={<PlusOutlined/>}>Phân tuyến lấy hàng</Button>}>
                  <TabPane tab="NHÂN VIÊN ĐƯỢC PHÂN TUYẾN" key="1">
                    <div>
                      <div className='row mr-b-10'>
                        <div className='col-md-6'>
                          <label className='bold'><i className="fa fa-list-ol"/>&ensp;DANH SÁCH PHÂN TUYẾN</label>
                        </div>
                        <div className='col-md-6'>
                          <span className='pull-right'>
                              <span>Xem theo:&ensp;</span>
                              <Radio.Group
                                options={[{ label: 'Tuyến', value: false },{ label: 'Pickup', value: true }]}
                                onChange={(e) => setGroup(e.target.value)}
                                value={group}
                                optionType="button"
                                buttonStyle="solid"
                              />&emsp;|&emsp;
                              <Button onClick={() => fetchPickups()} icon={<ReloadOutlined/>}>Làm mới</Button>
                            </span>
                        </div>
                      </div>
                      <table className='table table-bordered'>
                        <thead>
                          <tr>
                            <th className='bold' style={{width: '40px'}}>STT</th>
                            <th className='bold' style={{width: '200px'}}>{ group ? 'NHÂN VIÊN PICKUP' : 'TUYẾN PICKUP'}</th>
                            <th className='bold' style={{width: '400px'}}>{ !group ? 'NHÂN VIÊN PICKUP' : 'TUYẾN PICKUP'}</th>
                            <th className='bold' style={{width: '160px'}}>GIÁ</th>
                            <th className='bold' style={{width: '80px'}}>ACTION</th>
                          </tr>
                        </thead>
                        <tbody>
                        { results && results.length > 0 ?
                          results.map((pickup, key) => (
                            <React.Fragment key={key}>
                              <tr>
                                <td rowSpan={pickup.childrens && pickup.childrens.length ? (pickup.childrens.length) : 1}>{key+1}</td>
                                <td rowSpan={pickup.childrens && pickup.childrens.length ? (pickup.childrens.length) : 1}><span className='bold'>{group ? <span><UserOutlined/>&ensp;{pickup.item?.StaffCode} - {pickup.item?.StaffName}</span> : <p>{pickup.item?.RouteName}<br/><span style={{ fontStyle: 'italic' }}>Giá: <span>{NumberCommas(pickup.item?.CommonPrice)} đ</span></span></p>}</span></td>
                                <td>
                                  <span className='bold'>{!group ? <span><UserOutlined/>&ensp;{pickup.item?.StaffCode} - {pickup.item?.StaffName}</span> : <p>{pickup.item?.RouteName}<br/><span style={{ fontStyle: 'italic' }}>Giá: <span>{NumberCommas(pickup.item?.CommonPrice)} đ</span></span></p>}</span>
                                </td>
                                <td className='text-right bold'>
                                  <span className={pickup.item?.Price ? 'text-sucess' : "text-primary"}>{ pickup.item?.Price ? NumberCommas(pickup.item?.Price) : NumberCommas(pickup.item?.CommonPrice)} đ</span>
                                </td>
                                <td className='text-center'>
                                  <Tooltip title="Bỏ trạng thái hoạt động của pickup">
                                    <Button onClick={() => onRemovePickup(pickup.item)} size='small' icon={<DeleteOutlined/>} danger></Button>
                                  </Tooltip>
                                </td>
                              </tr>
                              { pickup.childrens && pickup.childrens.length > 1 &&
                                pickup.childrens.map((item, key1) => {
                                  if(key1 === 0) return '';
                                  return(
                                    <tr key={key1}>
                                      <td>
                                        <span className='bold'>{!group ? <span><UserOutlined/>&ensp;{item?.StaffCode} - {item?.StaffName}</span> : <p>{item?.RouteName}<br/><span style={{ fontStyle: 'italic' }}>Giá: <span>{NumberCommas(item?.CommonPrice)} đ</span></span></p>}</span>
                                      </td>
                                      <td className='text-right bold'>
                                        <span className={item?.Price ? 'text-sucess' : "text-primary"}>{ item?.Price ? NumberCommas(item?.Price) : NumberCommas(item?.CommonPrice)} đ</span>
                                      </td>
                                      <td className='text-center'>
                                        <Tooltip title="Bỏ trạng thái hoạt động của pickup">
                                          <Button onClick={() => onRemovePickup(item)} size='small' icon={<DeleteOutlined/>} danger></Button>
                                        </Tooltip>
                                      </td>
                                    </tr>
                                  )
                                })
                              }
                            </React.Fragment>
                          ))
                          :
                          <tr><td colSpan={5}><Empty title="Chưa có tuyến lấy hàng" description="Vui lòng chọn tuyến để cài nhân viên lấy hàng"/></td></tr>
                        }
                        </tbody>
                      </table>
                    </div>
                  </TabPane>
                  <TabPane tab="QUẢN LÝ TUYẾN LẤY HÀNG" key="2">
                    <div className='row'>
                      <div className='col-md-4'>
                        <div className='box-pickup-route'>
                          <div className='box-header'>
                            <label className='title bold'><i className="fa fa-list-ol"/>&ensp;Danh sách tuyến lấy hàng</label>
                            <span className='pull-right'>
                              <Button onClick={() => setRoute(null, () => close(true))} type='primary' icon={<PlusOutlined/>}>Thêm mới</Button>&ensp;
                              <Button onClick={() => fetchRoutes()} icon={<ReloadOutlined/>}/>
                            </span>
                          </div>
                          <div className='box-body'>
                            <Radio.Group onChange={(e)=> getPickupRoute(e.target.value)} value={selected?.Id} className="list-region" id="scroll">
                                {routes && routes.map((item, key) => {
                                    return (<Radio key={key} value={item.Id} className="item-region region">
                                                <span>{item.Name}</span>
                                                <label className='pull-right link'>
                                                  <span>Phí lấy hàng: {NumberCommas(item.CommonPrice)} đ</span>
                                                  &ensp;|&ensp;
                                                  <Dropdown overlay={actionProvince(item, props)} placement="bottomRight">
                                                    <MenuOutlined />
                                                  </Dropdown>
                                                </label>
                                            </Radio>)
                                })}
                            </Radio.Group>
                          </div>
                        </div>
                      </div>
                      <div className='col-md-8'>
                        <div className='box-pickup-region'>
                          <div className='box-header'>
                            <label className='title'><i className="fa fa-list-ol"/>&ensp;Danh sách địa danh <span className='bold'>{selected?.Name ? selected?.Name  : ''}</span></label>
                            <span className='pull-right'>
                              <Button disabled={!selected} onClick={() => setRegion(null, () => setView(true))} type='primary' icon={<PlusOutlined/>}>Thêm mới</Button>&ensp;
                              <Button onClick={() => getPickupRoute(selected.Id)} icon={<ReloadOutlined/>}/>
                            </span>
                          </div>
                          <div className='box-body' id="scroll">
                            <Table
                              loading={detailsing}
                              bordered
                              className="table-main"
                              columns={createColums(props)}
                              rowKey='Id'
                              size="small"
                              dataSource={ details || []}
                              pagination={false}
                              scroll={{x: 600}}
                              locale={{emptyText: <Empty description={ selected ? 'Chưa có địa danh' : "Chưa chọn tuyến pickup"}>{ selected && <Button onClick={() => setRegion(null, () => setView(true))} type='primary' icon={<PlusOutlined/>}>Thêm mới</Button>}</Empty>}}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPane>
                </Tabs>
                </div>
            </div>
            {display && <PickupStaffs open={display} close={setDisplay} routes={routes} refresh={fetchPickups}/> }
            {open && <PickupRoute open={open} close={close} route={route} refresh={fetchRoutes}/> }
            {view && selected && <PickupRegion open={view} close={setView} route={selected} refresh={() => getPickupRoute(selected.Id)}/> }
        </Content>
    )
}

export default PickupRouteV

const createColums = ({ onRemoveRegion }) => {
    const _columns = [
      { title: 'Địa danh', key: 'provice', className: '', render: (item) => <span><i className="fa fa-map-marker"/>&ensp;{item.WardName}, {item.DistrictName}, {item.ProvinceName}</span>},
      { title: 'Action', key: 'x', width: '60px', className: 'text-center', render: (item) => {
        return (
                <Tooltip title="Xóa địa danh này">
                  <Button size="small" icon={<DeleteOutlined />} onClick={() => onRemoveRegion(item)} type="primary" danger/>
                </Tooltip>)
      }}
    ];
    return _columns;
}
