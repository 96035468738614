import { lifecycle, compose as recompose, withHandlers, withState, pure } from 'recompose';
import { connect } from 'react-redux';
import { message } from 'antd';
import moment from 'moment';
import _ from 'lodash';
import { fetchEmailRequest, resendEmailRequest } from '../../reduxs/email-redux/reducer';
import EmailManagementV from './EmailManagementV';

export function mapDispatchToProps(dispatch) {
  return {
    fetchEmailRequest: (params) => dispatch(fetchEmailRequest(params)),
    resendEmail: (params) => dispatch(resendEmailRequest(params))
  };
}

const mapStateToProps = (state) => {
    return {
        emails: state.email && state.email.emails,
        fetching: state.email && state.email.fetching,
        pagination: state.email && state.email.pagination,
        resending: state.email && state.email.resending
    }
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default recompose(
  withConnect,
  withState('modalShown', 'setModalShown', false),
  withState('params', 'setParams', { Offset: 1, Size: 20, StartTime: moment().subtract(1, 'days'), EndTime: moment() }),
  withState('selectedEmail', 'setSelectedEmail', null),
  withState('dateRange', 'setDateRange', [moment(), moment()]),
  withState('keyword', 'setKeyword', ''),
  withHandlers({
    fetchEmails: ({params, setParams, fetchEmailRequest }) => (param) => {
      let objParams = { ...params, Offset: 1, ...param };

      let StartTime = moment().subtract(1, 'days').startOf('day').format();
      if (objParams.StartTime && moment(objParams.StartTime).isValid()) {
        StartTime = moment(objParams.StartTime).startOf('day').format();
      }
      let EndTime = moment().endOf('day').format();
      if (objParams.EndTime && moment(objParams.EndTime).isValid()) {
        EndTime = moment(objParams.EndTime).endOf('day').format();
      }
      
      if(objParams.Offset === undefined) objParams.Offset = 1;
      if(objParams.Size === undefined) objParams.Size = 20;
      
      objParams = _.pickBy(objParams, _.identity);
      setParams(objParams);
      fetchEmailRequest({...objParams, StartTime, EndTime });
    },
    showDetails: ({ setModalShown, setSelectedEmail }) => (item) => {
      setSelectedEmail(item, () => {
        setModalShown(true);
      })
    },
    onResendEmail: ({ resendEmail }) => (params) => {
      resendEmail({...params, callback: (type) => {
        if(type === 'ok') {
          message.success('Gửi lại thành công!')
        } else {
          message.error('Gửi lại không thành công!')
        }
      }})
    }
}),
  lifecycle({
    componentDidMount() {
      this.props.fetchEmails();
    }
  }),
  pure,
)(EmailManagementV);