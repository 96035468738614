import React from 'react'
import { Button, Input, Tooltip, Menu, Popconfirm, Dropdown, Select, DatePicker, message, Modal } from 'antd';
import { UserSwitchOutlined, MenuOutlined, UnlockOutlined, CreditCardOutlined, LockOutlined, BarcodeOutlined, UserOutlined, WarningOutlined, FilterOutlined, CheckCircleOutlined, CloseCircleOutlined, FieldNumberOutlined, ExclamationCircleOutlined, ManOutlined } from '@ant-design/icons'
import moment from 'moment';
import { find } from 'lodash';

const acountStatuses = [{ value: 'true', label: 'Đã có' }, { value: 'false', label: 'Chưa có' }]

const statusProfile = [{ value: 'true', label: 'Đầy đủ thông tin' }, { value: 'false', label: 'Chưa đầy đủ thông tin' }]

const sourceCus = [{ value: 'PCSPOST_WEB', label: 'Web pcspost.vn' }, { value: 'FACEBOOK', label: 'Facebook' }, { value: 'GOOGLE', label: 'Google' }, { value: 'QUANG_CAO', label: 'Quảng cáo' }, { value: 'CA_NHAN', label: 'Cá nhân' }, { value: 'KHAC', label: 'Khác' }]

const renderDropdown = ({ value, onChange, onOk, onCancel, onSearch, options, type, placeholder }) => {
    return (_p) => {
        const { confirm } = _p
        return (
            <div style={{ padding: '6px 8px', width: 270 }}>
                <div>
                    {
                        (() => {
                            switch (type) {
                                case 'dropdown-client': {
                                    return (
                                        <Select
                                            style={{ width: '100%', marginBottom: 8 }}
                                            value={value}
                                            options={options || []}
                                            dropdownMatchSelectWidth={false}
                                            showSearch
                                            placeholder={placeholder || 'Gõ để tìm...'}
                                            filterOption={(value, option) => {
                                                return option.label.toLowerCase().indexOf(value.toLowerCase()) >= 0
                                            }}
                                            onSelect={onChange} />
                                    )
                                }

                                case 'text': {
                                    return (
                                        <Input
                                            style={{ width: '100%', marginBottom: 8 }}
                                            type='text'
                                            value={value}
                                            placeholder={placeholder || 'Gõ để tìm...'}
                                            onChange={e => onChange && onChange(e.target.value)} />
                                    )
                                }

                                case 'date': {
                                    return (
                                        <DatePicker.RangePicker
                                            style={{ width: '100%', marginBottom: 8 }}
                                            value={value}
                                            onChange={onChange}
                                        />
                                    )
                                }

                                default: {
                                    return (
                                        <Select
                                            style={{ width: '100%', marginBottom: 8 }}
                                            value={value}
                                            options={options || []}
                                            dropdownMatchSelectWidth={false}
                                            showSearch
                                            filterOption={false}
                                            onSelect={onChange}
                                            placeholder={placeholder || 'Gõ để tìm...'}
                                            onSearch={onSearch} />
                                    )
                                }
                            }
                        })()
                    }
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button onClick={() => onCancel && onCancel()}>Xoá</Button>
                    <Button type='primary' onClick={() => onOk && onOk(confirm)}>Ok</Button>
                </div>
            </div>
        )
    }
}

export const ACC_HELPER = {
    createColumnTable: ({ onOpenZone, onOpenType, onOpenStation, onOpenSche, onOpenCode, onWarningStep, onOpenEmail, onOpenProfile, onOpenDoc,
        types, steps, setShowConfirm, setSelected, createUser, onResetPassword, allowedVerifySteps, onOpenZoneHistory, newPassword, setNewPassword, params, setParams, onSearch,
        isMobile, verifySteps, features, onShowOtherSetting, onCreateAccounNumber, onAddCustomerToNewStation }) => {
        const menu = (account) => (
            <Menu onClick={() => { }}>
                {
                    account.AccountNr === '-1' && (
                        <Menu.Item key="0" icon={<FieldNumberOutlined />} onClick={() => {
                            Modal.confirm({
                                title: 'Xác nhận thao tác?',
                                icon: <ExclamationCircleOutlined />,
                                content: 'Bạn chắc chắn muốn cấp mã cho khách hàng này?',
                                okText: 'Ok',
                                cancelText: 'Đóng',
                                onOk: () => {
                                    onCreateAccounNumber(account)
                                }
                            });
                        }}>
                            Cấp mã khách hàng
                        </Menu.Item>
                    )
                }
                <Menu.Item key="1" icon={<BarcodeOutlined />} onClick={() => onOpenCode(account)}>
                    Cập nhật mã cha
                </Menu.Item>
                <Menu.Item key="5" icon={<BarcodeOutlined />} onClick={() => onOpenStation(account)}>
                    Thay đổi bưu cục
                </Menu.Item>
                <Menu.Item key="2" icon={<UserSwitchOutlined />} onClick={() => onOpenType(account)}>
                    Thay đổi loại khách
                </Menu.Item>
                {
                    console.log("features",features)
                }
                {
                    features && find(features, { Code: 'UPDATE_ZONING_INFO', IsActive: true }) && (
                        <Menu.Item key="3" icon={<i className="fa fa-map-marker" />} onClick={() => onOpenZone(account)}>
                            &ensp;Thay đổi phân vùng
                        </Menu.Item>
                    )
                }
                <Menu.Item key="4" icon={<i className="fa fa-history" />} onClick={() => onOpenZoneHistory(account)}>
                    &ensp;Lịch sử phân vùng
                </Menu.Item>
                <Menu.Item key="6" icon={<i className="fa fa-envelope-o" />} onClick={() => onOpenEmail(account)}>
                    &ensp;QL email chốt cước
                </Menu.Item>
                <Menu.Item key="7" icon={<i className="fa fa-address-book-o" />} onClick={() => onOpenProfile(account)}>
                    &ensp;Xem chi tiết hồ sơ
                </Menu.Item>
                <Menu.Item key="8" icon={<i className="fa fa-file-archive-o" />} onClick={() => onOpenDoc(account)}>
                    &ensp;Danh sách tài liệu
                </Menu.Item>
                <Menu.Item key="89" icon={<i className="fa fa-cog" />} onClick={() => onShowOtherSetting(account)}>
                    &ensp;Cài đặt khác
                </Menu.Item>
            </Menu>
        );
        const columns = [
            {
                title: 'Bưu cục QL',
                dataIndex: '',
                key: 'StationCode',
                width: 110,
                render: ({ StationCode }) => {
                    return <div>
                        <label className="mr-0 bold">{StationCode && StationCode !== '' ? StationCode : <span className="text-danger">Chưa có</span>}</label>
                    </div>
                },
                toggleShow: true
            },
            {
                title: 'Mã tài khoản',
                dataIndex: '',
                key: 'AccountNo',
                width: 110,
                render: ({ AccountNr, ParentCode }) => {
                    return (<div className="text-center">
                        <label className="mr-0 bold">{AccountNr && AccountNr !== '-1' ? AccountNr : <span className="text-danger">Chưa cấp</span>}</label>
                        {ParentCode && <div><small>Mã cha: {ParentCode}</small></div>}
                    </div>)
                },
                toggleShow: true
            },
            {
                title: 'Số ĐT và Email',
                dataIndex: '',
                key: 'PhoneNr',
                width: 220,
                render: ({ PhoneNr, Email }) => {
                    return <div>
                        {PhoneNr && <p className="mr-0 bold"><i className="fa fa-phone-square" />&ensp;{PhoneNr}</p>}
                        {Email && <p className="mr-0 bold"><i className="fa fa-envelope-o" />&ensp;{Email}</p>}
                    </div>
                },
                toggleShow: true
            },
            {
                title: 'Thông tin tài khoản',
                dataIndex: '',
                key: 'CustomerName',
                width: 300,
                render: ({ FullName, ShortName, Addresses }) => {
                    return <div>
                        <p className="mr-0" style={{ color: '#000000' }}>{FullName}</p>
                        {ShortName && <p className="mr-0" style={{ fontSize: '13px' }}>{ShortName}</p>}
                        {Addresses && Addresses.length > 0
                            ? Addresses.map((address, key) => <div className="account-address" key={key}><i className="fa fa-map-marker" />&ensp;{address}</div>)
                            : <div className="account-address text-danger">Chưa có địa chỉ</div>
                        }
                    </div>
                },
                toggleShow: true
            },
            {
                title: 'Loại tài khoản',
                dataIndex: '',
                key: 'AccountTypeId',
                width: 120,
                className: 'text-center',
                render: ({ AccountTypeId }) => {
                    if (AccountTypeId && types && types.length) {
                        let type = types.find(i => i.Id === AccountTypeId);
                        if (type && type.Name) return type.Name
                    }
                    return AccountTypeId || ''
                },
                filterDropdown: renderDropdown({
                    value: params.AccountTypeId,
                    onChange: (value) => {
                        setParams({ ...params, AccountTypeId: value })
                    },
                    onOk: onSearch,
                    onCancel: () => setParams({ ...params, AccountTypeId: null }),
                    options: types.map(x => ({ value: x.Id, label: x.Name })),
                    type: 'dropdown-client'
                }),
                filterIcon: () => {
                    return <FilterOutlined style={params.AccountTypeId ? { color: '#1890ff' } : {}} />
                },
                toggleShow: true
            }, {
                title: 'Nhân viên chăm sóc',
                dataIndex: '',
                key: 'ZoneInfo',
                width: 300,
                className: 'text-center',
                toggleShow: true,
                render: (account) => {
                    return <div className="text-left">
                        <p className="mr-0">{account.ZoneInfo?.SalesStaffCode} - {account.ZoneInfo?.SalesStaffName}&nbsp;<small>(Bán hàng)</small></p>
                        <p className="mr-0">{account.ZoneInfo?.ZoneManagerStaffCode} - {account.ZoneInfo?.ZoneManagerName}&nbsp;<small>(QL vùng)</small></p>
                        <p className="mr-0">{account.ZoneInfo?.CustomerCareStaffCode} - {account.ZoneInfo?.CustomerCareName}&nbsp;<small>(CSKH)</small></p>
                        <p className="mr-0">{account.ZoneInfo?.PuStaffCode} - {account.ZoneInfo?.PuName}&nbsp;<small>(Giao nhận)</small></p>
                        <p className="mr-0">{account.ZoneInfo?.OpStaffCode} - {account.ZoneInfo?.OpName}&nbsp;<small>(Vận hành)</small></p>
                        <p className="mr-0">{account.ZoneInfo?.AccStaffCode} - {account.ZoneInfo?.AccName}&nbsp;<small>(Kế toán)</small></p>
                    </div>
                }
            }, {
                title: 'Vùng',
                dataIndex: '',
                key: 'Zone',
                width: 100,
                className: 'text-center',
                render: (account) => {
                    return account?.ZoneInfo?.ZoneName || '-'
                },
                toggleShow: true
            },
            {
                title: 'Người giới thiệu',
                key: 'ReferrerCode',
                width: 100,
                render: ({ ReferrerCode, Referrer }) => {
                    return Referrer ? Referrer + ` (${ReferrerCode})` : (ReferrerCode ? ReferrerCode : '');
                },
                toggleShow: true
            },
            {
                title: 'Thời gian tạo',
                dataIndex: '',
                key: 'CreatedDate',
                width: 130,
                filterDropdown: renderDropdown({
                    value: params.CreatedDate,
                    onChange: (value) => {
                        setParams({ ...params, CreatedDate: value })
                    },
                    onOk: onSearch,
                    onCancel: () => setParams({ ...params, CreatedDate: [] }),
                    type: 'date'
                }),
                filterIcon: () => {
                    return <FilterOutlined style={params.SalesStaffCode ? { color: '#1890ff' } : {}} />
                },
                render: ({ CreatedDate }) => CreatedDate ? moment(CreatedDate).format('DD/MM/YYYY HH:mm') : '',
                toggleShow: true
            },
            {
                title: 'Người tạo',
                dataIndex: 'CreatedBy',
                key: 'CreatedBy',
                width: 120,
                toggleShow: true
            },
            {
                title: 'Tk đăng nhập?',
                dataIndex: '',
                key: 'HasUserName',
                className: 'text-center',
                width: 120,
                filterDropdown: renderDropdown({
                    value: params.HasUserName,
                    onChange: (value) => {
                        setParams({ ...params, HasUserName: value })
                    },
                    onOk: onSearch,
                    onCancel: () => setParams({ ...params, HasUserName: null }),
                    options: acountStatuses,
                    type: 'dropdown-client'
                }),
                filterIcon: () => {
                    return <FilterOutlined style={params.HasUserName ? { color: '#1890ff' } : {}} />
                },
                render: ({ HasUserName }) => {
                    return HasUserName ? <CheckCircleOutlined style={{ color: 'green' }} /> : <CloseCircleOutlined />
                },
                toggleShow: true
            },
            {
                title: 'Tr.thái hồ sơ',
                key: 'ProfileComplete',
                dataIndex: '',
                width: 120,
                filterDropdown: renderDropdown({
                    value: params.ProfileComplete,
                    onChange: (value) => {
                        setParams({ ...params, ProfileComplete: value })
                    },
                    onOk: onSearch,
                    onCancel: () => setParams({ ...params, ProfileComplete: null }),
                    options: statusProfile,
                    type: 'dropdown-client'
                }),
                filterIcon: () => {
                    return <FilterOutlined style={params.ProfileComplete ? { color: '#1890ff' } : {}} />
                },
                render: ({ ProfileComplete }) => {
                    return ProfileComplete ? 'Đầy đủ' : 'Chưa đầy đủ'
                },
                toggleShow: true
            },
            {
                title: 'Nguồn',
                dataIndex: '',
                key: 'Source',
                width: 120,
                filterDropdown: renderDropdown({
                    value: params.Source,
                    onChange: (value) => {
                        setParams({ ...params, Source: value })
                    },
                    onOk: onSearch,
                    onCancel: () => setParams({ ...params, Source: null }),
                    options: sourceCus,
                    type: 'dropdown-client'
                }),
                filterIcon: () => {
                    return <FilterOutlined style={params.Source ? { color: '#1890ff' } : {}} />
                },
                render: ({ SourceName }) => {
                    return SourceName || 'Khác'
                },
                toggleShow: true
            },
            {
                title: 'Cấp độ xác nhận TK',
                dataIndex: '',
                className: "text-center",
                width: 150,
                key: 'CurrentVerifyStep',
                fixed: isMobile ? null : 'right',
                filterDropdown: renderDropdown({
                    value: params.CurrentVerifyStep,
                    onChange: (value) => {
                        setParams({ ...params, CurrentVerifyStep: value })
                    },
                    onOk: onSearch,
                    onCancel: () => setParams({ ...params, CurrentVerifyStep: null }),
                    options: verifySteps,
                    type: 'dropdown-client'
                }),
                filterIcon: () => {
                    return <FilterOutlined style={params.CurrentVerifyStep ? { color: '#1890ff' } : {}} />
                },
                toggleShow: true,
                render: (account) => {
                    if (steps && steps.length) {
                        let step = account.CurrentVerifyStep;
                        if (step) {
                            step = [...steps].find(i => i.Id === step)?.NextStepId || 1;
                        } else {
                            step = steps[0]?.Id || 1;
                        }

                        let histories = account.VerifyStepHistories;
                        let finish = account.IsFinishAllSteps || false;
                        
                        const disableBtn = (_allowedSteps, btnId) => {
                            console.log('_allowedSteps',_allowedSteps);
                            return _allowedSteps.filter(x => x.IsAllowed).map(x => x.Id).indexOf(btnId) === -1;
                        }

                        return (
                            <div>
                                <div className="group-steps">
                                    {steps && steps.map((item, key) => {
                                        let _key = key;
                                        let tooltop = item.Name;

                                        if (histories && histories.length) {
                                            let _hstep = histories.find((i) => i.StepId === item.Id);
                                            if (_hstep) {
                                                tooltop = (<div>
                                                    <p className="mr-0">Bước xác nhận: {item.Name}</p>
                                                    <p className="mr-0"><i className="fa fa-user-circle-o" />&ensp;{_hstep.StaffCode} - {_hstep.StaffName}</p>
                                                    <span><i className="fa fa-clock-o" />&ensp;{moment(_hstep.Time).format('DD-MM-YYYY HH:mm')}</span>
                                                </div>);
                                            }
                                        }
                                        if (finish) {
                                            return <React.Fragment key={key}>
                                                <div className="steps">
                                                    <Tooltip title={tooltop}>
                                                        <Button disabled={true} type="primary" className='finish' shape="circle" ><i className="fa fa-check" /></Button>
                                                    </Tooltip>
                                                </div>
                                                {(_key + 1) !== (steps.length) &&
                                                    <div className="steps step-line"><span className="line active" /></div>
                                                }
                                            </React.Fragment>
                                        }

                                        console.log('disableBtn',disableBtn(allowedVerifySteps, item.Id));
                                        return <React.Fragment key={key}>
                                            <div className="steps">
                                                <Tooltip title={tooltop}>
                                                    {
                                                        
                                                        account.ZoneInfo
                                                            ?
                                                            <Button type="primary" disabled={disableBtn(allowedVerifySteps, item.Id) || (step > item.Id)} onClick={() => setSelected({ CustomerId: account.Id, StepId: item.Id, hasUserName: account.HasUserName }, () => setShowConfirm(true))} className={step > item.Id ? 'finish' : 'process'} shape="circle" >
                                                                {step > item.Id ? <i className="fa fa-check" /> : _key + 1}
                                                            </Button>
                                                            :
                                                            <Button type="primary" disabled={disableBtn(allowedVerifySteps, item.Id)} onClick={() => onWarningStep(account)} className='process' shape="circle" >{_key + 1}</Button>
                                                    }
                                                </Tooltip>
                                            </div>
                                            {(_key + 1) !== (steps.length) &&
                                                <div className="steps step-line"><span className={step > item.Id ? "line active" : "line"} /></div>
                                            }
                                        </React.Fragment>
                                    })}
                                </div>
                            </div>
                        )
                    }
                    return ''
                }
            },
            {
                title: 'Chức năng', dataIndex: '', key: 'x', className: "text-center", fixed: 'right', width: 100, render: (account) => {
                    
                    return (<div>
                        <div className="text-center mr-b-10 functions">
                            {
                                account.ProfileComplete
                                    ?
                                    <Tooltip
                                        title="Chi tiết hồ sơ khách hàng"
                                        onClick={() => onOpenProfile(account)}
                                    >
                                        <UserOutlined />
                                    </Tooltip>
                                    :
                                    <Tooltip
                                        title={
                                            <div>
                                                <span>Hồ sơ chưa đầy đủ thông tin.</span>
                                                <br />
                                                {account.ProfileNote && <span>Ghi chú: {account.ProfileNote}<br /></span>}
                                                <span> Click xem chi tiết.</span>
                                            </div>
                                        }
                                        onClick={() => onOpenProfile(account)}
                                    >
                                        <WarningOutlined style={{ color: 'orange' }} />
                                    </Tooltip>
                            }
                            &emsp;
                            <Tooltip title="Lịch trình chốt cước. Click thay đổi." placement="topLeft" onClick={() => onOpenSche(account)}><CreditCardOutlined /></Tooltip>
                        </div>
                        <div className="text-center functions">
                            <Tooltip title={account.HasUserName ? "Đã có tài khoản đăng nhập. Click để đặt mật khẩu về mặc định" : "Chưa có tài khoản đăng nhập. Click để tạo."}>
                                {
                                    account.HasUserName
                                        ?
                                        account.StationCode==='70402'?
                                        <Popconfirm
                                            title={<p>Bạn chắc chắn muốn đặt lại mật khẩu cho tài khoản này?<br />Tên đăng nhập là mã khách hàng, mật khẩu đăng nhập là <Input style={{ width: 160 }} defaultValue={newPassword} onChange={(e) => setNewPassword(e.target.value)} placeholder="Mặc định: 12345678" /></p>}
                                            onConfirm={() => onResetPassword(account.AccountNr)}
                                            okText="Đặt lại mật khẩu"
                                            cancelText="Hủy"
                                            placement="bottomLeft">
                                            <UnlockOutlined style={{ color: 'green' }} />
                                        </Popconfirm>
                                        :
                                        <Popconfirm
                                            title={<p>Bạn chắc chắn muốn đặt lại mật khẩu cho tài khoản này?<br />Tên đăng nhập là mã khách hàng, mật khẩu đăng nhập là <Input style={{ width: 160 }} defaultValue={newPassword} onChange={(e) => setNewPassword(e.target.value)} placeholder="Mặc định: pcs@132" /></p>}
                                            onConfirm={() => onResetPassword(account.AccountNr)}
                                            okText="Đặt lại mật khẩu"
                                            cancelText="Hủy"
                                            placement="bottomLeft">
                                            <UnlockOutlined style={{ color: 'green' }} />
                                        </Popconfirm>
                                        :
                                        account.StationCode==='70402'?
                                        <Popconfirm
                                            title={<p>Bạn chắc chắn muốn tạo tài khoản đăng nhập Post cho tài khoản này?<br />Tên đăng nhập là mã khách hàng, mật khẩu đăng nhập mặc định là <span style={{ color: '#ccc' }}>12345678</span></p>}
                                            onConfirm={() => {
                                                if (account.AccountNr !== '-1') {
                                                    createUser({ Id: account.Id })
                                                } else {
                                                    message.error('Khách hàng này chưa được cấp mã nên không thể tạo tài khoản')
                                                }
                                            }}
                                            okText="Tạo"
                                            cancelText="Hủy"
                                            placement="bottomLeft"
                                        >
                                            <LockOutlined style={{ color: 'red' }} />
                                        </Popconfirm>
                                        :
                                        <Popconfirm
                                            title={<p>Bạn chắc chắn muốn tạo tài khoản đăng nhập Post cho tài khoản này?<br />Tên đăng nhập là mã khách hàng, mật khẩu đăng nhập mặc định là <span style={{ color: '#ccc' }}>pcs@132</span></p>}
                                            onConfirm={() => {
                                                if (account.AccountNr !== '-1') {
                                                    createUser({ Id: account.Id })
                                                } else {
                                                    message.error('Khách hàng này chưa được cấp mã nên không thể tạo tài khoản')
                                                }
                                            }}
                                            okText="Tạo"
                                            cancelText="Hủy"
                                            placement="bottomLeft"
                                        >
                                            <LockOutlined style={{ color: 'red' }} />
                                        </Popconfirm>
                                }
                            </Tooltip>&emsp;
                            <Dropdown overlay={menu(account)}><MenuOutlined /></Dropdown>
                        </div>
                        <div className="text-center mr-t-10 functions">
                            <Tooltip title="Chuyển tính cước hàng xuất" placement="topLeft" onClick={() => onAddCustomerToNewStation(account.AccountNr)}><ManOutlined /></Tooltip>
                        </div>
                    </div>)
                }
            }
        ]

        return columns;
    }
}