import { createSelector } from 'reselect';
import { initialState } from './reducer';

const customerDocState = (state) => state.document || initialState;

const selectCustomerDocs = () => createSelector(
  customerDocState, ({ documents }) => documents
);

const selectCustomerDocsing = () => createSelector(
  customerDocState, ({ documentsing }) => documentsing
);

const selectTypeCustomerDoc = () => createSelector(
  customerDocState, ({ types }) => types
);

export {
  customerDocState,
  selectCustomerDocs,
  selectCustomerDocsing,
  selectTypeCustomerDoc,
};
