import { createAction, handleActions } from 'redux-actions';
// - Actions

export const fetchMyFeatureRequest = createAction('FETCH_MY_FEATURE_REQUEST');
export const fetchMyFeatureSuccess = createAction('FETCH_MY_FEATURE_SUCCESS');


// - Initial State
export const initialState = {
    features: [],
    fetching: false,
    error: null
};

// - Reducers
const reducer = handleActions({
    [fetchMyFeatureRequest]: (state) => {
        return { ...state, fetching: true };
    },
    [fetchMyFeatureSuccess]: (state, {payload: { features }}) => {
        return { ...state, fetching: false, features: features };
    }
}, initialState);

export default reducer;