import React from 'react';
import moment from 'moment';
import { Button, Form, Modal, notification } from 'antd';
import { SaveOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { compose as recompose, lifecycle, withHandlers, withProps } from 'recompose';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { required, minLength6 } from '../../../helpers/validate';
import { FieldCheckBox, FieldDatePicker, FieldInput, FieldSelect } from '../../../components/Fields';
import { getTypeServicesRequest } from '../../../reduxs/common-redux/reducer';
import { selectTypeServices } from '../../../reduxs/common-redux/selectors';
import { FORM_COMMISSION_FORM, CommissionFormAction } from '../../../reduxs/staff-redux/action';

const { confirm } = Modal;

const disabledDate = (current) => {
  return current && current > moment();
}
const disabledExprid = (start) => (current) => {
  return current && current > moment() && current > start;
}

const FormPass = (props) => {
  const { open, close, staff, services, handleSubmit, error, valid, pristine, submitting } = props;
  const onSubmit = handleSubmit(CommissionFormAction);

  console.log('-----------', services);

  function showConfirm() {
    confirm({
      title: 'Bạn có muốn thay đổi mật khấu?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Thay đổi',
      cancelText: 'Hủy bỏ',
      onOk() {
        onSubmit();
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  return (
    <Modal
      title={<span>Thông tin:&ensp;<span className="bold">{staff.StaffCode} - {staff.FullName}</span></span>}
      visible={open}
      style={{ top: 20 }}
      width={'760px'}
      footer={null}
      maskClosable={false}
      onOk={() => { close(false) }}
      onCancel={() => { close(false) }}
      className="modal-form"
    >
      <Form layout="vertical" onFinish={() => valid ? showConfirm() : onSubmit()}>
        {services && services.map((service, key) => {
          return (
            <>
              <div className="row">
                <div className="col-md-6">
                  <Field
                    component={FieldDatePicker}
                    label="Ngày áp dụng"
                    name="AppliedDate"
                    placeholder="Chọn ngày áp dụng"
                    className="w-100 required"
                    validate={[required, minLength6]}
                  />
                </div>
                <div className="col-md-6">
                  <Field
                    component={FieldDatePicker}
                    label="Ngày hết hiệu lực"
                    name="ExpiredDate"
                    placeholder="Chọn ngày hết hiệu lực"
                    className="w-100 required"
                    disabledDate={disabledDate}
                    validate={[required]}
                  />
                </div>
              </div>
              <div className="row mr-t-15">
                <div className="col-md-6">
                  <input name="UserName" hidden />
                  <Field
                    component={FieldInput}
                    label="Chiết khấu"
                    name="Commission"
                    placeholder="Nhập số chiết khấu"
                    className="w-100 required"
                    disabledDate={disabledExprid(service.ExpiredDate)}
                    validate={[required]}
                  />
                </div>
                <div className="col-md-6">
                  <Field
                    component={FieldSelect}
                    label="Loại chiết khấu"
                    options={[]}
                    name="CommissionType"
                    placeholder="Chọn loại chiết khấu"
                    className="w-100 required"
                    validate={[required]}
                  />
                </div>
              </div>
              <div className="row mr-t-15">
                <div className="col-md-6">
                  <Field
                    component={FieldCheckBox}
                    text="Tạm thời"
                    name="IsTemporary"
                  />
                </div>
              </div>
            </>
          )
        })}
        <div className="col-md-6">
          <p className="text-right">Ghi chú:&ensp;<span className="note-red">Trường không được để trống</span></p>
        </div>
        
        <Form.Item className="text-center">
          <Button type="primary" icon={<SaveOutlined />} disabled={pristine} loading={submitting} htmlType="submit" style={{ marginLeft: 8 }}>Đặt mật khấu mới</Button>
          <p className="text-danger">{error}</p>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export function mapDispatchToProps(dispatch) {
  return {
    getServices: (params) => dispatch(getTypeServicesRequest(params)),
  };
}

const mapStateToProps = createStructuredSelector({
  services: selectTypeServices(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const datetime = moment();
export default recompose(
  withConnect,
  withProps(
    ({ staff }) => {
      if (staff) {
        return { initialValues: { Id: staff.Id, AppliedDate: datetime } };
      }
    }
  ),
  withHandlers({
  }),
  reduxForm({
    form: FORM_COMMISSION_FORM,
    enableReinitialize: true,
    onSubmitSuccess: (result, dispatch, props) => {
      notification.success({
        message: 'Lưu thành công!'
      });
      props.initialValues = null;
      props.reset();
      props.close(false);
    }
  }),
  lifecycle({
    componentDidMount() {
      const { services, getServices } = this.props;
      if(!(services && services.length)) {
        getServices({Type: 'DOMESTIC', CustomerId: 50843 });
      }
    }
})
)(FormPass);
