import React from "react";
import { Form, Radio, } from "antd";

const FormItem = Form.Item;
const RadioGroup = Radio.Group;

const makeField = Component => ({ input, meta, children, hasFeedback, config, label, options, type, className, ...rest }) => {
  const hasError = meta.touched && meta.invalid;
  return (
    <FormItem
      {...config}
      label={label}
      validateStatus={hasError ? "error" : "success"}
      hasFeedback={hasFeedback && hasError}
      help={hasError && meta.error}
      className= {className}
    >
      <RadioGroup {...input} {...rest} children={children} className= {className}>
        {
          type === "button"
          ? options && options.map((option, key) =>  <Radio.Button key={key} value={option.value}>{option.text}</Radio.Button>)
          : options && options.map((option, key) => <Radio key={key} value={option.value}>{option.text}</Radio>)
        }
      </RadioGroup>
    </FormItem>
  );
};


export default makeField(RadioGroup);