import { lifecycle, compose as recompose, withState, withHandlers, pure } from 'recompose';
import { connect } from 'react-redux';
import { notification, Modal } from 'antd';
import { createStructuredSelector } from 'reselect';
import _ from 'lodash';
import moment from 'moment';
import {
  getRegionsRequest,
  getProvincesRequest,
  getProvincesSuccess,
  getDistrictsRequest,
  getDistrictsSuccess,
  getRegionWardsRequest,
  getRegionWardsSuccess,
  fetchRegionStaffRequest,
  fetchRegionStationRequest,
  fetchRegionTreeRequest,
  saveRegionWardsRequest,
  saveRegionStaffRequest,
  toggleRegionStationRequest,
  deleteRegionStationRequest,
  deleteRegionStaffRequest
} from '../../reduxs/region-redux/reducer';
import {
  selectRegions,
  selectProvinces,
  selectDistricts,
  selectRegionWards,
  selectRegionWardsing,
  selectRegionStations,
  selectRegionStationsing,
  selectPagination,
  selectRegionStaffs,
  selectTrees,
  selectTreesing,
} from '../../reduxs/region-redux/selectors';
import { selectStations, selectStation } from '../../reduxs/station-redux/selectors';
import { staffStationRequest } from '../../reduxs/common-redux/reducer';
import { selectStaffs, selectStaffsing } from '../../reduxs/common-redux/selectors';

import PartitionV from './PartitionV';
import Auth from '../../storage/Auth';

export function mapDispatchToProps(dispatch) {
  return {
    getRegions: (params) => dispatch(getRegionsRequest(params)),
    getProvinces: (params) => dispatch(getProvincesRequest(params)),
    setProvinces: (params) => dispatch(getProvincesSuccess(params)),
    getDistricts: (params) => dispatch(getDistrictsRequest(params)),
    setDistricts: (params) => dispatch(getDistrictsSuccess(params)),
    getWards: (params) => dispatch(getRegionWardsRequest(params)),
    setWards: (params) => dispatch(getRegionWardsSuccess(params)),
    fetchRegionStaions: (params) => dispatch(fetchRegionStationRequest(params)),
    fetchRegionTrees: (params) => dispatch(fetchRegionTreeRequest(params)),
    fetchRegionStaffs: (params) => dispatch(fetchRegionStaffRequest(params)),
    saveRegionWards: (params) => dispatch(saveRegionWardsRequest(params)),
    saveRegionStaff: (params) => dispatch(saveRegionStaffRequest(params)),
    toggleRegionWards: (params) => dispatch(toggleRegionStationRequest(params)),
    deleteRegionWards: (params) => dispatch(deleteRegionStationRequest(params)),
    deleteRegionStaff: (params) => dispatch(deleteRegionStaffRequest(params)),
    getStaffs: (params) => dispatch(staffStationRequest(params))
  
  };
}

const mapStateToProps = createStructuredSelector({
  regions: selectRegions(),
  provinces: selectProvinces(),
  districts: selectDistricts(),
  wards: selectRegionWards(),
  wardsing: selectRegionWardsing(),
  stations: selectStations(),
  station: selectStation(),
  partitions: selectRegionStations(),
  loading: selectRegionStationsing(),
  pagination: selectPagination(),
  staffs: selectStaffs(),
  staffsing: selectStaffsing(),
  regionstaffs: selectRegionStaffs(),
  trees: selectTrees(),
  treesing: selectTreesing()
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default recompose(
  withConnect,
  withState('monthFilter', 'setMonthFilter', moment()),
  withState('station', 'setStation', () => Auth.getStation()),
  withState('params', 'setParams', { Offset: 1, Size: 20 }),
  withState('region', 'setRegion', null),
  withState('province', 'setProvince', null),
  withState('district', 'setDistrict', null),
  withState('ward', 'setWard', null),
  withState('openRegion', 'setOpenRegion', false),
  withState('openProvince', 'setOpenProvince', false),
  withState('openPlace', 'setOpenPlace', false),
  withState('checkedWards', 'setCheckedWards', []),
  withState('provinceOptions', 'setProvinceOptions', {}),
  withState('districtOptions', 'setDistrictOptions', {}),
  withState('wardOptions', 'setWardOptions', {}),
  withState('searchDistrict', 'setSearchDistrict', null),
  withState('tab', 'setTab', '1'),
  withState('selectKeys', 'setSelectKeys', []),
  withState('selectRows', 'setSelectRows', []),
  withState('selectStaffs', 'setSelectStaffs', []),
  withState('staff', 'setStaff', null),
  withState('keySearch', 'setKeySearch', null),

  withState('checkedKeys', 'setCheckedKeys', []),
  withHandlers({
    onCheck: ({setCheckedKeys}) => (checkedKeys) => {
      setCheckedKeys(checkedKeys);
    },
    onRefresh: ({setStaff, setSelectStaffs, setSelectRows, setSelectKeys, setCheckedKeys}) => () => {
      setSelectKeys([])
      setSelectRows([])
      setSelectStaffs([])
      setCheckedKeys([])
      setStaff();
    }
  }),


  withHandlers({
    fetchPartitions: ({ fetchRegionStaions, station, setParams, onRefresh, params}) => (values) => {
      let _params = { ...params, Offset: 1, StationCode: station, ...values }
      _params = _.pickBy(_params, _.identity);
      setParams(_params);
      onRefresh();
      fetchRegionStaions({..._params });
    },
    fetchTrees: ({ fetchRegionTrees, station }) => (values) => {
      fetchRegionTrees({StationCode: station, ...values, IsGetAll: true});
    },
    fetchRegionStaff: ({ fetchRegionStaffs, station}) => (staffCode) => {
      fetchRegionStaffs({Offset: 1, IsGetAll: true, StationCode: station, StaffCode: staffCode });
    },
    onSelectChange: ({setSelectKeys, setSelectRows}) => (keys, rows) => {
      setSelectKeys(keys);
      setSelectRows(rows);
    }
  }),
  
  withHandlers({
    fetchRegions: ({ getRegions, setRegion, setProvinces, setDistricts, setWards }) => () => {
      getRegions();
      setRegion(null);
      setProvinces({provinces: []});
      setDistricts({districts: []});
      setWards({wards: []});
    },
    fetchProvinces: ({ setRegion, setProvince, setDistrict, getProvinces, setDistricts, setWards }) => (id) => {
      setRegion(id);
      setProvince(null);
      setDistrict(null);
      if (id) {
        getProvinces({ RegionId: id, StationCode: Auth.getStation() });
      }
      setDistricts({districts: []});
      setWards({wards: []});
    },
    fetchDistricts: ({ tab, setProvince, setDistrict, getDistricts, setWards, region, fetchPartitions }) => (id) => {
      setProvince(id);
      setDistrict(null);
      getDistricts({ProvinceId: id, City: id, RegionId: region, StationCode: Auth.getStation() });
      setWards({wards: []});
      if(tab === '1') {
        fetchPartitions({ ProvinceId: id, DistrictId: '', WardId: '' })
      }
    },
    fetchWards: ({ tab, region, province, setDistrict, getWards, setSelectRows, setSelectKeys, fetchPartitions }) => (id) => {
      setDistrict(id);
      setSelectRows([]);
      setSelectKeys([]);
      if(tab === '1') {
        fetchPartitions({ DistrictId: id, WardId: '' })
      }
      getWards({RegionId: region, ProvinceId: province, DistrictId: id, StationCode: Auth.getStation() });
    },
    changWards: ({ tab, setWard, fetchPartitions }) => (id) => {
      setWard(id);
      if(tab === '1') {
        fetchPartitions({ WardId: id })
      }
    },
    onChangeTab: ({ wards, station, setSelectKeys, setSelectRows, setProvince, setDistrict, setTab, setWards, fetchPartitions}) => (tab) => {
      setSelectRows([]);
      setSelectKeys([]);
      setTab(tab)
      if(tab === '1') {
        fetchPartitions({ProvinceId: '', DistrictId: '', WardId: ''});
        setProvince(null)
        setDistrict(null);
      }
      if(tab === '3') {
        setProvince(null)
        setDistrict(null);
        setWards({wards: []});
      }
      if(tab === '3' && station && wards && wards.length) {
        let selected = [];
        let selectekey = [];
        wards.forEach(item => {
          if(item.StationCode && item.StationCode.length && item.StationCode.includes(station)) {
            selected.push(item);
            selectekey.push(item.RegionCode);
          }
        });
        setSelectRows(selected);
        setSelectKeys(selectekey);
      }
    },
    onSelectStaffChange: ({setSelectStaffs, partitions, setStaff, setCheckedKeys}) => (keys, rows) => {
      if(rows) {
        setSelectStaffs(keys);
        setStaff(rows[0]);
        if(partitions && partitions.length) {
          let selectKeys = partitions.filter((item) => {
            if(item.DeliveryStaffs && item.DeliveryStaffs.length) {
             return  item.DeliveryStaffs.map(i => i.StaffCode).includes(rows[0].StaffCode)
            }
            return false;
          }).map(j => j.Id);
          setCheckedKeys(selectKeys)
        }
      }
    }
  }),
  withHandlers({
    onSavePartition: ({ station, region, province, district, saveRegionWards, selectRows, getWards }) => () => {
      const confirm = () => {
        Modal.confirm({
          title: 'Đã được phân vùng phát?',
          content: 'Xã phường đã được phân vùng phát cho bưu cục khác, Tiếp tục phân vùng phát',
          okText: 'Tiếp tục',
          cancelText: 'Thôi',
          centered: true,
          onOk() {
            const _params ={
              data: {
                StationCode: station,
                RegionCode: selectRows.map((item) => item.RegionCode),
                ForceCreate: true,
              },
              cb: callback
            }
      
            saveRegionWards(_params)
          },
          onCancel() {
            console.log('Cancel');
          },
        });
      }

      const callback = (type, data) => {
        if(type === 'error') {
          if(data && data.Description) {
            confirm();
          } else {
            notification[type]({message: 'Lưu thất bại!'});
          }
        } else {
          notification[type]({message: 'Lưu thành công!'});
          getWards({RegionId: region, ProvinceId: province, DistrictId: district });
        }
      }

      const params ={
        data: {
          StationCode: station,
          RegionCode: selectRows.map((item) => item.RegionCode),
          ForceCreate: false,
        },
        cb: callback
      }

      saveRegionWards(params)
    },

    togglePartition: ({ station, fetchPartitions, toggleRegionWards }) => (keys, IsActive) => {
      if(keys && keys.length) {
        const callback = (type, data) => {
          if(type === 'error') {
            notification[type]({message: 'Cập nhật thất bại!'});
          } else {
            notification[type]({message: 'Câp nhập thành công!'});
          }
          fetchPartitions();
        }
        const params ={
          data: {
            StationCode: station,
            RegionCode: keys,
            IsActive: IsActive,
          },
          cb: callback
        }
  
        let title = IsActive ? 'Bạn muốn kích hoạt vùng phát đã chọn?' : 'Bạn muốn vô hiệu vùng phát đã chọn?'
        Modal.confirm({
          title: title,
          okText: IsActive ? 'Kích hoạt' : 'Vô hiệu',
          cancelText: 'Thôi',
          centered: true,
          onOk() {
            toggleRegionWards(params)
          },
          onCancel() {
            console.log('Cancel');
          },
        });
      }
    },

    deletePartition: ({ station, fetchPartitions, deleteRegionWards, selectRows }) => () => {
      const callback = (type, data) => {
        if(type === 'error') {
          notification[type]({message: 'Cập nhật thất bại!'});
        } else {
          notification[type]({message: 'Câp nhập thành công!'});
        }
        fetchPartitions();
      }
      const params ={
        data: {
          StationCode: station,
          RegionCode: selectRows.map((item) => item.RegionCode)
        },
        cb: callback
      }
      Modal.confirm({
        title: 'Bạn có chắc muốn xóa vùng phát đã chọn?',
        content: 'Vùng phát khi đã xóa sẽ không thể khôi phục được',
        okText: 'Xóa',
        cancelText: 'Hủy',
        okType: 'danger',
        centered: true,
        onOk() {
          deleteRegionWards(params)
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    },

    onPartitionStaff: ({checkedKeys, fetchPartitions, setSelectStaffs, staff, saveRegionStaff}) => () =>{
      if(staff && checkedKeys.length > 0) {
        let partitions = checkedKeys.filter((item) => !item.includes('district'));
        const confirm = () => {
          Modal.confirm({
            title: 'Vùng phát đã được phân?',
            content: 'Vùng phát đã được phân cho nhân viên khác, Tiếp tục phân vùng',
            okText: 'Tiếp tục',
            cancelText: 'Thôi',
            centered: true,
            onOk() {
              const _params ={
                data: {
                  StaffCode: staff.StaffCode,
                  DeliveryZoneStationId: partitions,
                  ForceCreate: true,
                },
                cb: callback
              }
        
              setSelectStaffs([]);
              saveRegionStaff(_params);
            },
            onCancel() {
              console.log('Cancel');
            },
          });
        }
  
        const callback = (type, data) => {
          if(type === 'error') {
            if(data && data.Description) {
              confirm();
            } else {
              notification[type]({message: 'Phân vùng phát thất bại!'});
            }
          } else {
            notification[type]({message: 'Lưu thành công!'});
            fetchPartitions();
          }
        }
  
        const params ={
          data: {
            StaffCode: staff.StaffCode,
            DeliveryZoneStationId: partitions,
            ForceCreate: false,
          },
          cb: callback
        }
        saveRegionStaff(params)
      }
    },
    deletePartitionStaff: ({ fetchPartitions, deleteRegionStaff, setSelectStaffs }) => (staff, partitions) => {
      if(staff && staff.StaffCode && partitions && partitions.length) {
        const callback = (type) => {
          if(type === 'error') {
            notification[type]({message: 'Xóa thất bại!'});
          } else {
            notification[type]({message: 'Xóa thành công!'});
          }
          setSelectStaffs([]);
          fetchPartitions();
        }
        const params ={
          data: {
            StaffCode: staff.StaffCode,
            DeliveryZoneStationId: partitions
          },
          cb: callback
        }
        deleteRegionStaff(params);
      }
    },
  }),
  withHandlers({
    onSelectWard: ({setCheckedWards}) => (items) => {
      setCheckedWards(items)
    },
    onProvinceOptions: ({provinceOptions, setProvinceOptions}) => (id, options) => {
      const data = {...provinceOptions};
      data[id] = options[0] || [];
      setProvinceOptions(data);
    },
    onDistrictOptions: ({districtOptions, setDistrictOptions}) => (id, options) => {
      const data = {...districtOptions};
      data[id] =  options[0] || [];
      setDistrictOptions(data);
    },
    onOptionsWards: ({wardOptions, setWardOptions}) => (id, options) => {
      const data = {...wardOptions};
      data[id] = options[0] || [];
      setWardOptions(data);
    },
  }),
  lifecycle({
    componentDidMount() {
      const { station, fetchRegions, fetchPartitions, getStaffs, fetchTrees } = this.props;
      fetchRegions();
      if(station) {
        fetchPartitions({StationCode: station });
        getStaffs({ station: station });
        fetchTrees();
      }
    },
    componentDidUpdate(props) {
      const { wards, station, setSelectRows, setSelectKeys, getStaffs } = this.props;
      if(station && wards && wards.length && (wards !== props.wards || station !== props.station)) {
        let selected = [];
        let selectekey = [];
        wards.forEach(item => {
          if(item.StationCode && item.StationCode.length && item.StationCode.includes(station)) {
            selected.push(item);
            selectekey.push(item.RegionCode);
          }
        });
        setSelectRows(selected);
        setSelectKeys(selectekey);
      }

      if(station && station !== props.station) {
        getStaffs({ station: station })
      }
    }
  }),
  pure,
)(PartitionV);
