import { createAction, handleActions } from 'redux-actions';
// - Actions
export const getStaticsticRequest = createAction('GET_STATICSTIC_REQUEST');
export const getStaticsticSuccess = createAction('GET_STATICSTIC_SUCCESS');

export const getSheetsRequest = createAction('GET_SHEETS_REQUEST');
export const getSheetsSuccess = createAction('GET_SHEETS_SUCCESS');


export const getPickupsRequest = createAction('GET_PICKUPS_REQUEST');
export const getPickupsSuccess = createAction('GET_PICKUPS_SUCCESS');

export const failure = createAction('DASHBOARD_ACTION_FAILURE');

// - Initial State
export const initialState = {
  statistics: [],
  statisting: false,

  sheets: [],
  sheetsing: false,

  pickups: [],
  pickupsing: false,

  loading: false,
  error: null,
};

// - Reducers
const reducer = handleActions({
  [getStaticsticRequest]: (state) => ({
    ...state,
    statisting: true,
    statistics: [],
  }),
  [getStaticsticSuccess]: (state, { payload: { statistics } }) => ({
    ...state,
    statistics: statistics,
    statisting: false,
  }),

  
  [getSheetsRequest]: (state) => ({
    ...state,
    sheetsing: true,
    sheets: [],
  }),
  [getSheetsSuccess]: (state, { payload: { sheets } }) => ({
    ...state,
    sheets: sheets,
    sheetsing: false,
  }),

  [getPickupsRequest]: (state) => ({
    ...state,
    pickupsing: true,
    pickups: [],
  }),
  [getPickupsSuccess]: (state, { payload: { pickups } }) => ({
    ...state,
    pickups: pickups,
    pickupsing: false,
  }),

  [failure]: (state, action) => ({
    ...state,
    loading: false,
    statisting: false,
    error: action.error,
  }),
}, initialState);

export default reducer;
