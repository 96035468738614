import { APP_CONFIG } from '../config'
import monent from 'moment';
const { BIRTHDAY: {MAX_BIRTHDAY, MIN_BIRTHDAY} } = APP_CONFIG

// Check type number
export const checkTypeNumber = (value) => {
  let error
  const regex = new RegExp(/^[0-9]+$/)
  const check = regex.test(value)

  if (value) {
    if (check) {
      error = undefined
    } else {
      error = 'Không phải định dạng số'
    }
  } else {
    error = undefined
  }

  return error
}

// Check phone number
export const checkTypePhoneNumber = (value) => {
  let error
  const regex = new RegExp(/^[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{4,6}$/im)
  const check = regex.test(value)

  if (value) {
    if (check) {
      error = undefined
    } else {
      error = 'Không phải số điện thoại'
    }
  } else {
    error = undefined
  }

  return error
}

// Check email
export const checkEmail = (value) => {
  let error
  const regex = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
  const check = regex.test(value)

  if (value) {
    if (check) {
      error = undefined
    } else {
      error = 'Không phải địa chỉ email'
    }
  } else {
    error = undefined
  }
  return error
}

// Check lat, lng
export const checkPosition = (value) => {
  let error
  const regex = new RegExp(/^-?\d{1,3}[.]\d+$/, 'gim')
  const check = regex.test(value)

  if (value) {
    if (check) {
      error = undefined
    } else {
      error = 'form.error.notPosition'
    }
  } else {
    error = undefined
  }

  return error
}

// Check password
export const minLength = (min) => (value) => {
  let error
  if (value && value.length < min) {
    error = `Độ dài tối thiểu ${min} ký tự`
  } else {
    error = undefined
  }
  return error
}
export const maxLength = (max) => (value) => {
  let error
  if (value && value.length >= max) {
    error = `Độ dài tối đa ${max} ký tự`
  } else {
    error = undefined
  }
  return error
}

export const minLength6 = minLength(6)

// Check empty
export const required = (value) => {
  let error
  if (value) {
    error = undefined
  } else {
    error = 'Trường này không được để trống'
  }
  return error
}

// Check empty
export const requiredSelect = (value) => {
  let error
  if (value && value.length > 0) {
    error = undefined
  } else {
    error = 'Trường này không được để trống'
  }
  return error
}

export const matchPassword = (value, allValues) => {
  let error
  if (value !== allValues.password) {
    error = 'Mật khẩu không khớp nhau'
  } else {
    error = undefined
  }
  return error
}

export const checkBirthday = (value) => {
  let error
  if (value) {
    if (monent(value, 'DD-MM-YYYY').isValid()) {
      let date = new Date(monent(value, 'DD-MM-YYYY'))
      if (date >= MAX_BIRTHDAY) {
        error = 'Chưa đủ tuổi cho phép'
      } else if (MIN_BIRTHDAY >= date) {
        error = 'Tuổi quá mức cho phép'
      } else {
        error = undefined
      }
    } else {
      error = 'Không phải ngày'
    }
  }
  return error
}
