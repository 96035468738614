import React from 'react';
import { connect } from 'react-redux';
import { Modal, notification, Form, Table, Button } from 'antd';
import { SaveOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { lifecycle, compose as recompose, withHandlers, withState, withProps } from 'recompose';
import { selectProvinces, selectDistricts, selectWards } from '../../../reduxs/utility-redux/selectors';
import { getProvincesRequest, getDistrictsRequest, getWardsRequest } from '../../../reduxs/utility-redux/reducer';
import { FieldSelect } from '../../../components/Fields';
import { required  } from '../../../helpers/validate';
import { PICKUP_REGION_FORM_KEY, RegionFormAction } from '../../../reduxs/pickup-route-redux/action';


const columns = ({ onAddItems, onChangeProvince, onChangeWards, districts, wards, details }, _provinces) => {
  let _districts = [];
  const existedWards = (details || []).map(x => x.WardId);
  if(districts && districts.length) {
      _districts = districts.map((i) => {
          return {value: i.Id, text: i.Name, search: i.Name}
      })
  }

  let _wards = []
  if(wards && wards.length) {
      _wards = wards.map((i) => {
          return {value: i.Id, text: i.Name, search: i.Name, disabled: existedWards.indexOf(i.Id) !== -1}
      })
  }

  return [
    {
      title: 'STT',
      dataIndex: '',
      key: 'key',
      className:"text-center",
      width: 40,
      render: (item) => item+1
    },
    {
      title: 'Tỉnh thành',
      dataIndex: '',
      key: 'provice',
      width: 200,
      render: (item) => <Field 
        component={FieldSelect} 
        showSearch={true}
        options={_provinces} 
        optionFilterProp="children"
        filterOption={(input, option) => {
            if (option.search) {
                return option.search.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
        }}
        name={`Details[${item}].ProvinceId`} 
        onChange={(value) => onChangeProvince(value, item)} 
        validate={[required]} 
        className="w-100"/>
    },
    {
      title: 'Quận huyện',
      dataIndex: '',
      key: 'districts',
      width: 200,
      render: (item) => <Field 
        component={FieldSelect}
        showSearch={true}
        options={_districts} 
        optionFilterProp="children"
        filterOption={(input, option) => {
            if (option.search) {
                return option.search.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
        }}
        name={`Details[${item}].DistrictId`}
        onChange={(value) => onChangeWards(value, item)}
        validate={[required]}
        className="w-100"/>
    },
    {
      title: 'Xã phường',
      dataIndex: '',
      key: 'ward',
      width: 200,
      render: (item) => <Field 
        component={FieldSelect} 
        showSearch={true}
        options={_wards} 
        optionFilterProp="children"
        filterOption={(input, option) => {
            if (option.search) {
                return option.search.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
        }}
        name={`Details[${item}].WardId`} 
        validate={[required]} 
        className="w-100"/>
    },
    {
      title: '',
      dataIndex: '',
      key: 'action',
      width: 40,
      render: (item) => {
        if(item > 0) {
          return <Button size="small" type='danger' onClick={() => onAddItems(true, item)} icon={<DeleteOutlined/>}/>
        }
        return ''
      }
    },
  ];
}

const ProvinceForm = (props) => {
  const { open, details, onAddItems, provinces, route, close, setCheckedProvinces, handleSubmit, error, submitting, pristine } = props;

  const submit = handleSubmit(RegionFormAction);

  let _provinces = []
  if(provinces && provinces.length) {
      _provinces = provinces.map((i) => {
          return {value: i.Id, text: i.Name, search: i.Name}
      })
  }

  var packages = [];
  if(details && details.length) {
    for (var i = 0; i < details.length; i++) {
      packages.push(i);
    }
  }

  return (
    <Modal
      title='Thêm địa danh vào tuyến pickup'
      visible={open}
      style={{ top: 20 }}
      width={'860px'}
      footer={null}
      maskClosable={false}
      onOk={() => { setCheckedProvinces([]); close(false) }}
      onCancel={() => { setCheckedProvinces([]); close(false) }}
      className="modal-form-privince"
    >
      <div className="row">
        <div className="col-md-12">
          <div className="panel-body">
            <Form layout="horizontal" onFinish={submit}>
              <div className="row">
                <div className="row">
                  <div className="col-xs-8">
                    <label><i className="fa fa-truck"/>&ensp;TUYẾN: <span className='bold'>{route.Name}</span></label>
                  </div>
                  <div className="col-xs-4">
                    <Button loading={submitting} disabled={pristine} className="pull-right" type="primary" onClick={() => submit()} icon={<SaveOutlined />}>Lưu lại</Button>
                  </div>
                  <p className="col-md-12 text-right text-danger">{error}</p>
                </div>
              </div>
              <div className="row">
                <Table
                  bordered
                  columns={columns(props, _provinces)}
                  dataSource={packages}
                  rowKey={(item) => item}
                  pagination={false}
                  size="small"
                  className="table-package"
                  footer={() => <div className="w-100 text-center"><Button size="small" type='primary' onClick={() =>onAddItems()} icon={<PlusOutlined/>}>Thêm</Button></div>}
                />
              </div>
            </Form>
          </div>
        </div>
      </div>

    </Modal>
  );
};

export function mapDispatchToProps(dispatch) {
  return {
      getProvinces: (params) => dispatch(getProvincesRequest(params)),
      getDistricts: (params) => dispatch(getDistrictsRequest(params)),
      getWards: (params) => dispatch(getWardsRequest(params)),
  };
}
const selector = formValueSelector(PICKUP_REGION_FORM_KEY);

const mapStateToProps = createStructuredSelector({
  provinces: selectProvinces(),
  districts: selectDistricts(),
  wards: selectWards(),
  details: (state) => selector(state, 'Details'),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default recompose(
  withConnect,
  withProps(({route}) => {
    return { initialValues: { RouteId: route.Id, Details: [{ ProvinceId: '', DistrictId: '', WardId: ''}] }};
  }),
  withState('values', 'setValues', {}),
  withState('loading', 'setLoading', false),
  withState('province', 'setProvince', null),
  withState('checkedProvinces', 'setCheckedProvinces', []),
  reduxForm({
    form: PICKUP_REGION_FORM_KEY,
    enableReinitialize: true,
    onSubmitSuccess: (result, dispatch, props) => {
        notification.success({
            message: 'Lưu thành công!'
        });
        props.initialValues = null;
        props.reset();
        props.close(false);
        props.refresh();
    }
  }),
  withHandlers({
    onChangeProvince: ({ details, change, setProvince, setDistricts, getDistricts }) => (id, index) => {
      let items = [...details];
      if(items[index]) {
        items[index] = {...items[index], DistrictId: '', WardId: ''};
        change('Details', items)
      }
      setProvince(id, () => getDistricts({ CityId: id }));
    },
    onChangeWards: ({ details, change, province, setWards, getWards }) => (id, index) => {
      let items = [...details];
      if(items[index]) {
        items[index] = {...items[index], WardId: ''};
        change('Details', items)
      }
      getWards({CityId: province, DistrictId: id})
    },
    onAddItems: ({details, change}) => (del, index) => {
      let items = [...details];
      if(del) {
        items.splice(index, 1);
        change('Details', items)
      } else {
        let item = items[items.length - 1]
        change('Details', [...items, { ProvinceId: item?.ProvinceId, DistrictId: item?.DistrictId, WardId: ''}])
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.getProvinces({CountryId: 234});
    },
  }),
)(ProvinceForm);
