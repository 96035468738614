
import './style.scss';
import React from 'react';
import { Layout, Table, Button, Radio, Select, Input } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import AutoForm from './components/AutoForm';
import RestrictPage from './../../components/RestrictComponents/RestrictPage';

const { Content } = Layout;
const { Option } = Select;

const AutomaticV = (props) => {
    const { autos, params, auto, loading, fetchAutos } = props;
    const { open, close } = props;

    let _autos = [];
    if(autos && autos.length) {
        _autos = autos.map((item, key) => {
            item.key = key;
            return item;
        })
    }

    return (
        <RestrictPage>
            <Content className="page-autos">
                    <div className="panel-header">
                        <div className="header-title">
                            <i className="fa fa-home icon" aria-hidden="true"></i>
                            <span>Hệ thống</span>
                            <span className="icon-next"><i className="fa fa-angle-right"/></span>
                            <span className="active">Dịch vụ tự động</span>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="panel-body">
                            <div className="row mr-b-10">
                                <div className="col-md-3">
                                    <label className="bold w-100">Môi trường:</label>
                                    <Select value={params.EnvironmentName || ''} onChange={(value) => fetchAutos({ EnvironmentName: value })} style={{width: '350px'}}>
                                        <Option value=''>Tất cả</Option>
                                        <Option value='Production'>Production</Option>
                                        <Option value='Op'>OP</Option>
                                        <Option value='Crm'>CRM</Option>
                                        <Option value='Staging'>Staging</Option>
                                        <Option value='Development'>Development</Option>
                                    </Select>
                                </div>
                                <div className="col-md-4">
                                    <label className="bold w-100">Trạng thái:</label>
                                    <Radio.Group onChange={(e) => fetchAutos({IsActive: e.target.value})} value={params.IsActive || ''}>
                                        <Radio value="">Tất cả</Radio>
                                        <Radio value={'true'}>Đã kích hoạt</Radio>
                                        <Radio value={'false'}>Đã tắt</Radio>
                                    </Radio.Group>
                                </div>
                                <div className="col-md-3">
                                    <label className="bold w-100">Tìm kiếm tên:</label>
                                    <Input.Search defaultValue={params.ServiceName || ''} onSearch={(value) => fetchAutos({ServiceName: value})} />
                                </div>
                            </div>
                            <hr/>
                            <div className="import-table">
                                <div className="row">
                                    <div className="col-md-12">
                                        <Table
                                            loading={loading}
                                            bordered
                                            className="table-main table-role"
                                            columns={reactColums(props)}
                                            rowKey='key'
                                            dataSource={ _autos || []}
                                            pagination={false}
                                            size="small"
                                            scroll={{x: 1600}}
                                            locale={{emptyText: 'Chưa có dịch vụ chạy tự động'}}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {open && <AutoForm open={open} close={close} auto={auto} fetchAutos={fetchAutos}/>}
                </Content>
        </RestrictPage>
    )
}
export default AutomaticV

const reactColums = ({onEditAuto}) => {
    const colums = [
        { title: 'Tên dịch vụ', dataIndex: 'ServiceName', key: 'ServiceName', width: 150 },
        { title: 'Môi trường', dataIndex: 'EnvironmentName', key: 'EnvironmentName', width: 120 },
        { title: 'Công thức thời gian', dataIndex: 'Expression', key: 'Expression'},
        { title: 'Mô tả công thức', dataIndex: 'CronExpressionDescription', key: 'CronExpressionDescription'},
        { title: 'Tần suất kiểm tra dịch vụ (ms)', dataIndex: 'Delay', key: 'Delay'},
        { title: 'Lần chạy cuối', dataIndex: '', key: 'LastRun', className: "text-center", width: 150,  render: ({LastRun}) => {
            return LastRun ? moment(LastRun).format('DD-MM-YYYY HH:mm:ss') : '';
        } },
        { title: 'Thời gian thực thi', key: 'ExecutionTime', className: 'text-right', render: ({ ExecutionTime }) => {
            if (ExecutionTime != null) {
                if (ExecutionTime < 1000) {
                    return `${ExecutionTime} ms`
                } else {
                    return `${(ExecutionTime / 1000).toFixed(2)} s`
                }
            }
            return '';
        } },
        { title: 'Lần chạy kế tiếp', dataIndex: '', key: 'NextRun', className: "text-center", width: 150,  render: ({NextRun, IsActive}) => {
            let subNext = null;
            if (NextRun && IsActive) {
                const minutes = moment(NextRun).diff(moment(), 'minutes');
                if (minutes >= -1 && minutes < 60) {
                    let className = 'next-run-minutes'
                    if (minutes < 5) {
                        className += ' danger'
                    }
                    let seconds = 0
                    if (minutes <= 0) {
                        seconds = moment(NextRun).diff(moment(), 'seconds');
                    }
                    
                    subNext = <><br/><span className={className}>{minutes <= 0 && seconds <= 0 ? 'Đang chạy' : `${(minutes === 0 ? seconds + ' giây' : minutes + ' phút' )} nữa` }</span></>
                }
            }
            const next = NextRun ? <>{moment(NextRun).format('DD-MM-YYYY HH:mm:ss')}{subNext}</> : 'N/A';
            return next;
        } },
        { title: 'Mô tả', dataIndex: 'Description', key: 'Description' },
        { title: 'Trạng thái', dataIndex: '', key: 'IsActive', className: "text-center", width: 150,  render: (item) => {
            return item.IsActive ? <span style={{
                padding: '2px 4px',
                border: '1px solid #00b300',
                color: '#00b300',
                borderRadius: '2px',
                minWidth: '100px',
                display: 'inline-block'
            }}>Đã kích hoạt</span> : <span style={{
                padding: '2px 4px',
                border: '1px solid rgb(197, 0, 0)',
                color: 'rgb(197, 0, 0)',
                borderRadius: '2px',
                minWidth: '100px',
                display: 'inline-block'
            }}>Đã tắt</span>
        } },
        { title: 'Số lần thực thi', dataIndex: 'CountRun', key: 'CountRun', className: 'text-right', render: (value) => <NumberFormat value={value} displayType={'text'} thousandSeparator={true} />},
        { title: '', dataIndex: '', key: 'x', className: "text-center", fixed: 'right', width: 110,  render: (auto) => {
            return <Button icon={<EditOutlined/>} size="small" type="primary" onClick={() => onEditAuto(auto)}>Cập nhật</Button>;
        } },
    ]
    return colums;
}

