import { createSelector } from 'reselect';
import { initialState } from './reducer';
const stateDashboard = (state) => state.dashboard || initialState;

const selectStatistics = () => createSelector(
  stateDashboard,
  ({ statistics }) => statistics
);
const selectStatisting = () => createSelector(
  stateDashboard,
  ({ statisting }) => statisting
);

const selectSheets = () => createSelector(
  stateDashboard,
  ({ sheets }) => sheets
);
const selectSheetsing = () => createSelector(
  stateDashboard,
  ({ sheetsing }) => sheetsing
);

const selectPickups = () => createSelector(
  stateDashboard,
  ({ pickups }) => pickups
);
const selectPickupsing = () => createSelector(
  stateDashboard,
  ({ pickupsing }) => pickupsing
);

export {
  stateDashboard,
  selectStatistics,
  selectStatisting,
  selectSheets,
  selectSheetsing,
  selectPickups,
  selectPickupsing
};
