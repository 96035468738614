import { lifecycle, compose as recompose, withHandlers, withState, pure } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { notification, message } from 'antd';
import _ from 'lodash';
import { areaListRequest, deleteAreaRequest, excelAreaRequest } from '../../reduxs/area-redux/reducer';
import { selectAreas, selectLoading, selectPagination } from '../../reduxs/area-redux/selectors';

import { selectTypeServices } from '../../reduxs/common-redux/selectors';
import { getTypeServicesRequest } from '../../reduxs/common-redux/reducer';

import { selectProvinces } from '../../reduxs/utility-redux/selectors';
import { getProvincesRequest } from '../../reduxs/utility-redux/reducer';
import AreasV from './AreasV';

// Danh sách cột trong bảng quản lý tồn kho
const columns = [
  { name: 'Id', id: 'Id', type: 'string', show: false, width: 120, class:'text-left' },
  { name: 'Dịch vụ', id: 'TosCode', type: 'string', show: true, width: 120, class:'text-left' },
  { name: 'Tỉnh thành gửi', id: 'FromProvinceId', type: 'string', show: true, width: 120, class:'text-left' },
  { name: 'Tỉnh thành nhận', id: 'ToProvinceName', type: 'string', show: true, width: 120, class:'text-left' },
  { name: 'Nơi nhận', id: 'ToWardId', type: 'string', show: true, width: 120, class:'text-left' },
  { name: 'Nơi nhận', id: 'ToWardName', type: 'string', show: true, width: 120, class:'text-left' },
  { name: 'Loại', id: 'Type', type: 'int', show: false, width: 120, class:'text-left' },
  { name: 'Ngày tạo', id: 'CreatedDate', type: 'datetime', show: false, width: 120, class:'text-left' },
];

export function mapDispatchToProps(dispatch) {
  return {
    searchArea: (params) => dispatch(areaListRequest(params)),
    deleteArea: (params) => dispatch(deleteAreaRequest(params)),
    excelArea: (params) => dispatch(excelAreaRequest(params)),
    getServices: (params) => dispatch(getTypeServicesRequest(params)),
    getProvinces: (params) => dispatch(getProvincesRequest(params)),
  };
}

const mapStateToProps = createStructuredSelector({
  areas: selectAreas(),
  loading: selectLoading(),
  pagination: selectPagination(),
  services: selectTypeServices(),
  provinces: selectProvinces()
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default recompose(
  withConnect,
  withState('open', 'close', false),
  withState('area', 'setArea', null),
  withState('params', 'setParams', { Offset: 1, Size: 20 }),
  withState('columns', 'setColumns', columns),
  withState('rowKeys', 'setRowKeys', []),
  withState('rowRows', 'setSelectRows', []),
  withHandlers({
    fetchAreas: ({ params, setParams, searchArea, setRowKeys, setSelectRows }) => (param) => {
      let _params = {  ...params, Offset: 1, ...param };
      
      if(_params.Offset === undefined) _params.Offset = 1;
      if(_params.Size === undefined) _params.Size = 20;
      
      _params = _.pickBy(_params, _.identity);
      setParams(_params);

      if (_params.Offset === undefined) _params.Offset = 1;
      if (_params.Size === undefined) _params.Size = 20;

      _params = _.pickBy(_params, _.identity);
      setParams(_params);
      searchArea({ ..._params });
      setRowKeys([]);
      setSelectRows([]);
    },
    exportExcelArea: ({params, excelArea}) => () => {
      const _params = {
        data: {...params},
        cb: (type) => {
          if(type === 'success') {
            message.success('Xuất file excel thành công!')
          } else {
            message.error('Xuất file excel bị lỗi!')
          }
        }
      }
      excelArea(_params);
    },
    editArea: ({close, setArea}) => (area) => {
      setArea(area);
      close(true)
    },
    stateColumn: ({ columns, setColumns }) => (data) => {
      const cols = columns.map((col) => {
        if (col.id === data.id) col.show = !data.show
        return col;
      });
      setColumns(cols);
    },
  }),
  withHandlers({
    deleteArea: ({ params, fetchAreas, deleteArea}) => (area) => {
      if(area && area.Id) {
        const data = {
          Id: [area.Id]
        }
        const callback = (type) => {
          if(type === 'error') {
            notification[type]({message: 'Xóa thất bại!'});
          } else {
            notification[type]({message: 'Xóa thành công!'});
          }
          fetchAreas({Offset: params.Offset || 1});
        }
        const _params ={
          data: data,
          cb: callback
        }
        deleteArea(_params)
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      const { services, getServices, getProvinces, fetchAreas } = this.props;
      if(!(services && services.length)) {
        getServices({Type: 'DOMESTIC'});
      }
      getProvinces({CountryId: 234});
      fetchAreas();
    }
  }),
  pure,
)(AreasV);