import { createAction, handleActions } from 'redux-actions';
// - Actions
export const departmentListRequest = createAction('DEPARTMENT_LIST_REQUEST');
export const departmentListSuccess = createAction('DEPARTMENT_LIST_SUCCESS');
export const exportExcelDepartmentRequest = createAction('EXPORT_DEPARTMENT_EXCEL_REQUEST');
export const deleteDepartmentRequest = createAction('DELETE_DEPARTMENT_REQUEST');
export const failure = createAction('DEPARTMENT_ACTION_FAILURE');

// - Initial State
export const initialState = {
  departments: [],
  pagination: {
    Page: 1,
    Size: 50,
    Total: 0
  },
  loading: false,
  error: null,
};

// - Reducers
const reducer = handleActions({
  [departmentListRequest]: (state) => ({
    ...state,
    loading: true,
  }),
  [departmentListSuccess]: (state, { payload: { departments, pagination } }) => ({
    ...state,
    departments: departments,
    pagination: pagination,
    loading: false,
  }),

  [failure]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error,
  }),
}, initialState);

export default reducer;
