import './style.scss';
import React from 'react';
import moment from 'moment';
import { Layout, Table, Select, Pagination, Input, Button, Card, Collapse, Tabs, message, Tooltip } from 'antd';
import RestrictPage from './../../components/RestrictComponents/RestrictPage';
import { Icons } from '../../assets';
import 'pretty-print-json/dist/pretty-print-json.css'
import prettyPrintJson from 'pretty-print-json';
import { CaretRightOutlined, FilterOutlined, CopyOutlined } from '@ant-design/icons';
import { MilisecondToClearText } from './../../helpers/common';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const { Content } = Layout;
const { Panel } = Collapse;
const { TabPane } = Tabs;
const { Option } = Select;

const RequestHistoryV = (props) => {
    const { isMobile, fetching, histories, fetchingGroup, historyGroup } = props;
    const { pagination, params, onFetchData, onChangeParams, onFetchDataGroup } = props;

    const size = params.Size && Number(params.Size) > 0 ? Number(params.Size) : 25;
    const offset = params.Offset && Number(params.Offset) > 0 ? Number(params.Offset) : 1;
    const total = pagination && pagination.Total && Number(pagination.Total) > 0 ? Number(pagination.Total) : 0;
    const n_start = total > 0 ? (((offset - 1) * size) + 1) : 0;
    const n_end = total > 0 ? ((offset * size) > total ? total : offset * size) : 0;
    return (
        <RestrictPage>
            <Content className="page-request-history">
                <div className="panel-header">
                    <div className="header-title">
                        <i className="fa fa-home icon" aria-hidden="true"></i>
                        <span>Hệ thống</span>
                        <span className="icon-next"><i className="fa fa-angle-right"></i></span>
                        <span className="active">Request histories</span>
                    </div>
                </div>
                <div className={isMobile ? "col-md-12 pd-0" : "col-md-12"}>
                    <div className="panel-body">
                        <Tabs defaultActiveKey="1">
                            <TabPane tab={<span className="bold">Lịch sử</span>} key="1">
                                <div className="row">
                                    <div className="col-md-12">
                                        <Collapse className="filter-container" ghost expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}>
                                            <Panel header={<span className="bold">FILTER</span>} key="1">
                                                <div className="row">
                                                    <div className="col-lg-3 col-sm-6 col-xs-12" style={{ marginBottom: 15 }}>
                                                        <label className="bold">Method</label>
                                                        <Select defaultValue={params.Method || ''} onChange={(value) => onChangeParams({ Method: value })} style={{ width: '100%' }}>
                                                            <Option value="">All</Option>
                                                            <Option value="GET">GET</Option>
                                                            <Option value="POST">POST</Option>
                                                            <Option value="PUT">PUT</Option>
                                                            <Option value="DELETE">DELETE</Option>
                                                        </Select>
                                                    </div>
                                                    <div className="col-lg-3 col-sm-6 col-xs-12" style={{ marginBottom: 15 }}>
                                                        <label className="bold">Environment</label>
                                                        <Select defaultValue={params.Environment || ''} onChange={(value) => onChangeParams({ Environment: value })} style={{ width: '100%' }}>
                                                            <Option value="">All</Option>
                                                            <Option value="Production">Production</Option>
                                                            <Option value="Op">Op</Option>
                                                            <Option value="Crm">Crm</Option>
                                                            <Option value="Development">Development</Option>
                                                        </Select>
                                                    </div>
                                                    <div className="col-lg-3 col-sm-6 col-xs-12" style={{ marginBottom: 15 }}>
                                                        <label className="bold">Trace Identifier</label>
                                                        <Input defaultValue={params.TraceIdentifier || ''} placeholder="Trace Identifier" onChange={(e) => onChangeParams({ TraceIdentifier: e.target.value })} style={{ width: '100%' }} />
                                                    </div>
                                                    <div className="col-lg-3 col-sm-6 col-xs-12" style={{ marginBottom: 15 }}>
                                                        <label className="bold">Request path</label>
                                                        <Input defaultValue={params.RequestPath || ''} placeholder="Request path" onChange={(e) => onChangeParams({ RequestPath: e.target.value })} style={{ width: '100%' }} />
                                                    </div>
                                                    <div className="col-lg-3 col-sm-6 col-xs-12" style={{ marginBottom: 15 }}>
                                                        <label className="bold">Execute Time</label>
                                                        <Input type="number" defaultValue={params.TimeGreaterThan || ''} placeholder="Time greater than (ms)" onChange={(e) => onChangeParams({ TimeGreaterThan: e.target.value })} style={{ width: '100%' }} />
                                                    </div>
                                                    <div className="col-lg-3 col-sm-6 col-xs-12" style={{ marginBottom: 15 }}>
                                                        <label className="bold">Start Time</label>
                                                        <Input type="datetime-local" defaultValue={params.StartTime || ''} placeholder="Start time" onChange={(e) => onChangeParams({ StartTime: e.target.value })} style={{ width: '100%' }} />
                                                    </div>
                                                </div>
                                                <div className="row" style={{ marginBottom: 15 }}>
                                                    <div className="col-lg-12">
                                                        <Button loading={fetching} icon={<FilterOutlined />} onClick={() => onFetchData({})} >Filter</Button>
                                                    </div>
                                                </div>
                                            </Panel>
                                        </Collapse>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <label className="total-filter">
                                            <img src={Icons.common.filter} alt="Số request" />|&ensp;<span>{n_start} - {n_end}</span> trong <span>{total}</span>
                                        </label>
                                    </div>
                                    <div className="col-md-6 text-right">
                                        <Pagination current={offset || 1} pageSize={size || 20} total={total || 0} showSizeChanger={false} onChange={(offset) => onFetchData({ Offset: offset })} />
                                    </div>
                                </div>
                                <div className="row mr-t-10">
                                    <div className="col-md-12">
                                        <Table
                                            loading={fetching}
                                            className="table-request-histories"
                                            columns={createColumns(props)}
                                            rowKey="Id"
                                            size="small"
                                            dataSource={histories || []}
                                            pagination={false}
                                            bordered={true}
                                            scroll={{ y: 'calc(100vh - 200px)' }}
                                        />
                                    </div>
                                </div>

                                <div className="row mr-t-10">
                                    <div className="col-md-6">
                                        <span>Hiển thị &ensp;
                                            <Select value={size || 20} style={{ width: 120 }} onChange={(size) => onFetchData({ Size: size, Offset: 1 })}>
                                                <Select.Option value="25">25</Select.Option>
                                                <Select.Option value="50">50</Select.Option>
                                                <Select.Option value="100">100</Select.Option>
                                                <Select.Option value="200">200</Select.Option>
                                            </Select>
                                            &nbsp; bản ghi / trang
                                        </span>
                                    </div>
                                    <div className="col-md-6 text-right">
                                        <Pagination current={offset || 1} pageSize={size || 20} total={total || 0} showSizeChanger={false} onChange={(offset) => onFetchData({ Offset: offset })} />
                                    </div>
                                </div>
                            </TabPane>
                            <TabPane tab={<span className="bold">Thống kê</span>} key="2">
                                <div className="row">
                                    <div className="col-lg-3 col-sm-6 col-xs-12" style={{ marginBottom: 15 }}>
                                        <label className="bold">Start Time</label> <Input type="datetime-local" defaultValue={params.StartTimeGroup || ''} placeholder="Start time" onChange={(e) => onChangeParams({ StartTimeGroup: e.target.value })} style={{ width: '100%' }} />
                                    </div>
                                    <div className="col-lg-3 col-sm-6 col-xs-12" style={{ marginBottom: 15 }}>
                                        <label className="bold">End Time</label> <Input type="datetime-local" defaultValue={params.EndTimeGroup || ''} placeholder="End time" onChange={(e) => onChangeParams({ EndTimeGroup: e.target.value })} style={{ width: '100%' }} />
                                    </div>
                                    <div className="col-lg-3 col-sm-6 col-xs-12" style={{ marginBottom: 15 }}>
                                        <label className="bold">Execute Time</label><Input type="number" defaultValue={params.ExecuteTime || ''} placeholder="Time greater than or equal (ms)" onChange={(e) => onChangeParams({ ExecuteTime: e.target.value })} style={{ width: '100%' }} />
                                    </div>
                                    <div className="col-lg-3 col-sm-6 col-xs-12" style={{ marginBottom: 15 }}>
                                        <label className="bold">Request Path</label> <Input type="text" defaultValue={params.RequestPathGroup} placeholder="Request Path" onChange={(e) => onChangeParams({ RequestPathGroup: e.target.value })} style={{ width: '100%' }} />
                                    </div>
                                </div>

                                <div className="row" style={{ marginBottom: 15 }}>
                                    <div className="col-lg-12">
                                        <Button loading={fetchingGroup} icon={<FilterOutlined />} onClick={() => onFetchDataGroup({})} >Filter</Button>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <Table
                                            loading={fetchingGroup}
                                            className="table-request-history-group"
                                            columns={createGroupColumns(props)}
                                            rowKey="Id"
                                            size="small"
                                            dataSource={historyGroup || []}
                                            pagination={false}
                                            bordered={true}
                                            scroll={{ y: 'calc(100vh - 200px)' }}
                                        />
                                    </div>
                                </div>
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
            </Content>
        </RestrictPage>
    );
};

export default RequestHistoryV

const createColumns = (props) => {
    const colums = [
        {
            title: 'Client, Environment and User',
            dataIndex: null,
            key: 'Id',
            width: 240,
            render: ({ ClientId, Environment, UserCode, RemoteIpAddress, BrowserAndOs, TraceIdentifier }) => {
                return (
                    <div>
                        <p><span className="bold">Client Id:</span> {ClientId}</p>
                        <p><span className="bold">Environment:</span> {Environment}</p>
                        <p><span className="bold">User code:</span> {UserCode}</p>
                        {RemoteIpAddress && <p><span className="bold">IP address:</span> {RemoteIpAddress}</p>}
                        {BrowserAndOs && <p><span className="bold">{'Browser & OS:'}</span> {BrowserAndOs}</p>}
                        {TraceIdentifier && <p><span className="bold">{'Trace Identifier:'}</span> {TraceIdentifier}</p>}
                    </div>
                )
            }
        },
        {
            title: 'Request & Response', dataIndex: null, key: 'ClientId', width: 'calc(100% - 240px)', render: ({ ExecuteTime, Method, Payload, QueryString, RequestPath, RequestTime, StatusCode, Headers, ContentType }) => {
                let _payload = null
                if (Payload !== '') {
                    _payload = Payload;
                }

                let _headers = null;
                if (Headers && Headers.trim() !== '') {
                    _headers = Headers;
                }

                const isErrorCode =  [400, 401, 404, 415, 500, 501].indexOf(StatusCode) !== -1;

                let _styleStatusCode = {
                    fontFamily: 'Roboto-Medium',
                    borderRadius: '3px',
                    padding: '2px 5px',
                    color: isErrorCode ? 'red' : 'green',
                    border: isErrorCode ? '1px solid #ff8d8d' : '1px solid rgb(0, 216, 0)',
                    backgroundColor: isErrorCode ? '#ffdede' : '#aeffae'
                };

                return (<div>
                    <div style={{ marginBottom: 15 }}>
                        <Card size="small" title={
                            <div className="history-request-wapper"><span className="history-request"><span className={`webhook-method ${Method.toLowerCase()}`}>{Method}</span><input type="text" readOnly className="endpoint" value={RequestPath + (QueryString || '')} /></span></div>
                        }>
                            <>
                                { _headers &&
                                    <div>
                                        <label>Request headers</label>
                                        <pre className="history-request-data"><output dangerouslySetInnerHTML={{ __html: beautiPayload(_headers) }} /></pre>
                                    </div>
                                }
                                <div>
                                    <label><span>Request body </span>
                                        { _payload &&
                                            <CopyToClipboard 
                                                text={_payload}
                                                onCopy={() => message.success('Đã sao chép')}>
                                                    <Tooltip title="Sao chép Request body">
                                                        <CopyOutlined className="handle-copy" />
                                                    </Tooltip>
                                            </CopyToClipboard>
                                        }
                                    </label>
                                    <pre className="history-request-data"><output dangerouslySetInnerHTML={{ __html: beautiPayload(_payload) }} /></pre>
                                </div>
                            </>
                        </Card>
                    </div>
                    <div>
                        <Card size="small" title={<label className="bold">Response </label>}>
                            <p><span className="bold">Requested On: </span>{moment(RequestTime).format('YYYY-MM-DD HH:mm:ss')}</p>
                            <p><span className="bold">Executed Time: </span>{ExecuteTime < 1000 ? <span>{ExecuteTime}<span className="time-unit">ms</span></span> : <span>{(ExecuteTime / 1000).toFixed(2)}<span className="time-unit">s</span></span>}</p>
                            <p><span className="bold">Status Code: </span><span style={_styleStatusCode}>{StatusCode}</span></p>
                        </Card>
                    </div>
                </div>)
            },

        }
    ]
    return colums;
}

const beautiPayload = (_str) => {
    try {
        return prettyPrintJson.toHtml(JSON.parse(_str));
    } catch (error) {
        return _str;
    }
}

const createGroupColumns = (props) => {
    const colums = [
        {
            title: 'Request Path',
            dataIndex: null,
            key: 'RequestPath',
            render: ({ RequestPath }) => {
                return RequestPath
            }
        },
        {
            title: 'Count', dataIndex: null, key: 'Count', width: 120, className: 'text-right', render: ({ Count }) => {
                return Count
            },
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.Count - b.Count
        },
        {
            title: 'Max Execute Time', dataIndex: null, key: 'MaxExecuteTime', width: 180, className: 'text-right', render: ({ MaxExecuteTime }) => {
                return MilisecondToClearText(MaxExecuteTime);
            },
            sorter: (a, b) => a.MaxExecuteTime - b.MaxExecuteTime
        },
        {
            title: 'Min Execute Time', dataIndex: null, key: 'MinExecuteTime', width: 180, className: 'text-right', render: ({ MinExecuteTime }) => {
                return MilisecondToClearText(MinExecuteTime)
            },
            sorter: (a, b) => a.MinExecuteTime - b.MinExecuteTime
        },
        {
            title: 'Average Execute Time', dataIndex: null, key: 'AverageExecuteTime', width: 180, className: 'text-right', render: ({ AverageExecuteTime }) => {
                return MilisecondToClearText(AverageExecuteTime)
            },
            sorter: (a, b) => a.AverageExecuteTime - b.AverageExecuteTime
        }
    ]
    return colums;
}