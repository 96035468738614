import { createSelector } from 'reselect';
import { initialState } from './reducer';

const reportState = (state) => state.report || initialState;

const selectRevenues = () => createSelector(
  reportState,
  ({ revenues }) => revenues
);
const selectRevenuesing = () => createSelector(
  reportState,
  ({ revenuesing }) => revenuesing
);

export {
  reportState,
  selectRevenues,
  selectRevenuesing,
};
