import React, { useRef, useEffect } from "react";

/**
 * Hook that alerts clicks outside of the passed ref
 */
const useClickOutside = (ref, callback) => {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                callback && callback(ref.current, event.target)
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
        // eslint-disable-next-line 
    }, [ref]);
}

/**
 * Component that alerts if you click outside of it
 */
export default (props) => {
    const { onClickOutside } = props;
    const wrapperRef = useRef(null);
    
    useClickOutside(wrapperRef, (ref, currentTarget) => {
        onClickOutside && onClickOutside(ref, currentTarget)
    });

    return <div ref={wrapperRef}>{props.children}</div>;
}