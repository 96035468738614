import React from 'react';
import { Button } from 'antd';
import { CloudDownloadOutlined } from '@ant-design/icons';
import './style.scss';
import { apiEndpoint } from './../../../config';

const FileComponentV = (props) => {
    const { email, downloadAllAttachments, getIcon } = props;

    return (
        <React.Fragment>
                    <div>
                        <div className="row">
                            <div className="col-md-4">
                                <span className="bold"><i className="fa fa-paperclip"/>&ensp;Tệp tin đính kèm</span>
                                &emsp;
                                <Button size="small" onClick={() => downloadAllAttachments(email.Id)} icon={<CloudDownloadOutlined />}>Tải xuống tất cả</Button>
                            </div>
                        </div>
                        <div className="row">
                            {email && email.AttachmentFiles && email.AttachmentFiles.length && email.AttachmentFiles.map((attachment, index) => {
                                return (
                                    <div className="col-md-1">
                                        <div className="file-group">
                                            <div className="box-file">
                                                {
                                                    attachment.ContentType.startsWith('image')
                                                    ? <img alt="" src={`${apiEndpoint}/api/email-management/download-attachment?EmailId=${email.Id}&FileName=${attachment.FileName}`}/>
                                                    : <img alt="" src={getIcon(attachment)}/>
                                                }
                                                <div className="file-info">
                                                    <label className="bold">{attachment.FileName}</label>
                                                    <span className="size">{attachment.Size}</span>
                                                    <div className="file-action">
                                                        <a href={`${apiEndpoint}/api/email-management/download-attachment?EmailId=${email.Id}&FileName=${attachment.FileName}`}>
                                                            <Button type="primary" icon={<CloudDownloadOutlined />}/>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                )
                            })
                            }
                        </div>
                    </div>
        </React.Fragment>
    )
}

export default FileComponentV