import { createSelector } from 'reselect';
import { initialState } from './reducer';
const stateManageStation = (state) => state.manageStation || initialState;

const selectManageStation = () => createSelector(
  stateManageStation,
  ({ manageStations }) => manageStations
);
const selectPagination = () => createSelector(
  stateManageStation,
  ({ pagination }) => pagination
);
const selectLoading = () => createSelector(
  stateManageStation,
  ({ loading }) => loading
);

// Danh sách tất cả bưu cục
const selectSationAll= () => createSelector(
  stateManageStation,
  ({ stationAll }) => stationAll
);

// Danh sách loại phòng ban
const selectSationType = () => createSelector(
  stateManageStation,
  ({ stationTypes }) => stationTypes
);

// Danh sách nhân viên
const selectEmployee = () => createSelector(
  stateManageStation,
  ({ employees }) => employees
);

// Danh sách nhân viên trong bưu cục
const selectEmployeeInStation = () => createSelector(
  stateManageStation,
  ({ employeesInStation }) => employeesInStation
);

export {
  stateManageStation,
  selectManageStation,
  selectPagination,
  selectLoading,
  selectSationType,
  selectEmployee,
  selectEmployeeInStation,
  selectSationAll
};
