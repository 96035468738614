import { createAction, handleActions } from 'redux-actions';
// - Actions

export const assetSalesRequest = createAction('ASSET_SALE_LIST_REQUEST');
export const assetSalesSuccess = createAction('ASSET_SALE_LIST_SUCCESS');
export const deleteSaleRequest = createAction('ASSET_DEL_SALE_REQUEST');

export const getAssetSaleRequest = createAction('ASSET_SALE_REQUEST');
export const getAssetSaleSuccess = createAction('ASSET_SALE_SUCCESS');

export const saleConfirmRequest = createAction('ASSET_SALE_CONFIRM_REQUEST');
export const salePrintRequest = createAction('ASSET_SALE_PRINT_REQUEST');

export const failure = createAction('ASSET_ACTION_FAILURE');

// - Initial State
export const initialState = {
  sales: [],
  salesing: false,
  pagination: {
    Page: 1,
    Size: 50,
    Total: 0
  },

  sale: null,
  saleing: false,

  error: null,
};

// - Reducers
const reducer = handleActions({
  
  [assetSalesRequest]: (state) => ({
    ...state, salesing: true,
  }),
  [assetSalesSuccess]: (state, { payload: { sales, pagination } }) => ({
    ...state, sales, pagination, salesing: false,
  }),

  [getAssetSaleRequest]: (state) => ({
    ...state, saleing: true,
  }),
  [getAssetSaleSuccess]: (state, { payload: { sale } }) => ({
    ...state, sale, saleing: false,
  }),

  [failure]: (state, action) => ({
    ...state,
    error: action.error,
    salesing: false,
  }),
}, initialState);

export default reducer;
