import { createAction, handleActions } from 'redux-actions';

// - Actions
export const getAllPickupZoneRequest = createAction('GET_ALL_PICKUP_ZONE_REQUEST');
export const updatePickupZoneRequest = createAction('UPDATE_PICKUP_ZONE_REQUEST');

// - Initial State
export const initialState = {}

const reducer = handleActions({

}, initialState);

export default reducer;
