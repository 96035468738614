import { createAction, handleActions } from 'redux-actions';
// - Actions

export const assetHandoversRequest = createAction('ASSET_HANDOVER_LIST_REQUEST');
export const assetHandoversSuccess = createAction('ASSET_HANDOVER_LIST_SUCCESS');
export const deleteHandoverRequest = createAction('ASSET_DEL_HANDOVER_REQUEST');

export const getAssetHandoverRequest = createAction('ASSET_HANDOVER_REQUEST');
export const getAssetHandoverSuccess = createAction('ASSET_HANDOVER_SUCCESS');

export const handoverConfirmRequest = createAction('ASSET_HANDOVER_CONFIRM_REQUEST');
export const handoverPrintRequest = createAction('ASSET_HANDOVER_PRINT_REQUEST');

export const failure = createAction('ASSET_ACTION_FAILURE');

// - Initial State
export const initialState = {
  handovers: [],
  handoversing: false,
  pagination: {
    Page: 1,
    Size: 50,
    Total: 0
  },

  handover: null,
  handovering: false,

  error: null,
};

// - Reducers
const reducer = handleActions({
  
  [assetHandoversRequest]: (state) => ({
    ...state, handoversing: true,
  }),
  [assetHandoversSuccess]: (state, { payload: { handovers, pagination } }) => ({
    ...state, handovers, pagination, handoversing: false,
  }),

  [getAssetHandoverRequest]: (state) => ({
    ...state, handovering: true,
  }),
  [getAssetHandoverSuccess]: (state, { payload: { handover } }) => ({
    ...state, handover, handovering: false,
  }),

  [failure]: (state, action) => ({
    ...state,
    error: action.error,
    handoversing: false,
  }),
}, initialState);

export default reducer;
