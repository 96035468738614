import { createSelector } from 'reselect';
import { initialState } from './reducer';

const getStationState = (state) => state.station || initialState;

const selectStations = () => createSelector(
  getStationState,
  ({ stations }) => stations
);

const selectStation = () => createSelector(
  getStationState,
  ({ station }) => station
);

const selectSearching = () => createSelector(
  getStationState,
  ({ searching }) => searching
);

const selectLoading = () => createSelector(
  getStationState,
  ({ loading }) => loading
);

const selectOpenStation = () => createSelector(
  getStationState,
  ({ openStation }) => openStation
);


export {
  getStationState,
  selectStations,
  selectStation,
  selectSearching,
  selectLoading,
  selectOpenStation
};
