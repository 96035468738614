import React from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { lifecycle, compose as recompose, withHandlers, withState } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Modal, message, notification } from 'antd';
import { getCustomerInfoSheetRequest, deleteCustomerInfoSheetRequest, InfoSheetDownloadRequest } from '../../reduxs/customer-info-sheet-redux/reducer';

import CustomerInfoSheetV from './CustomerInfoSheetV';

export function mapDispatchToProps(dispatch) {
  return {
    getCustomerInfoSheetList: (params = {}) => dispatch(getCustomerInfoSheetRequest(params)),
    deleteCustomerIS: (params) => dispatch(deleteCustomerInfoSheetRequest(params)),
    downloadInfoSheet: (params) => dispatch(InfoSheetDownloadRequest(params)),
  };
}

const mapStateToProps = createStructuredSelector({
  fetching: store => store.customerInfoSheet && store.customerInfoSheet.fetchingData,
  dataSource: store => store.customerInfoSheet && store.customerInfoSheet.dataSource,
  pagination: store => store.customerInfoSheet && store.customerInfoSheet.pagination
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default recompose(
  withConnect,
  withState('params', 'setParams', { Offset: 1, Size: 25 }),
  withState('open', 'setOpen', false),
  withState('sheet', 'setSheet', null),
  withState('activeTab', 'setActiveTab', '1'),
  withState('isDownloading', 'setIsDownloading', false),
  withHandlers({
    onFetchData: ({ getCustomerInfoSheetList, setParams, params }) => (_prams = {}) => {
      const _p = { ...params, ..._prams };
      setParams(_p, () => {
        getCustomerInfoSheetList({ params: _p })
      })
    },
    onConfirmDelete: ({ deleteCustomerIS, getCustomerInfoSheetList }) => (id) => {
      Modal.confirm({
        title: 'Xác nhận xoá',
        icon: <ExclamationCircleOutlined />,
        content: 'Dữ liệu không thể khôi phục sau khi xoá, bạn vẫn muốn xoá?',
        okText: 'Xoá',
        cancelText: 'Huỷ',
        onOk: () => {
          deleteCustomerIS({
            params: { Id: id },
            cb: (isSuccess) => {
              if (isSuccess) {
                message.success('Xoá thành công');
                getCustomerInfoSheetList();
              } else {
                message.error('Xoá không thành công');
              }
            }
          })
        }
      });
    },
    onInfomationDownload: ({ downloadInfoSheet, setIsDownloading }) => (_params) => {
      setIsDownloading(true);
      const data = {
          params: { ..._params },
          cb: (_type) => {
              setIsDownloading(false);
              notification[_type || 'success']({
                  message: _type === 'error' ? 'Tải xuống thất bại!' : 'Tải xuống thành công!',
              });
          }
      }
      downloadInfoSheet(data);
  },
  }),
  lifecycle({
    componentDidMount() {
      this.props.getCustomerInfoSheetList();
    }
  })
)(CustomerInfoSheetV);
