import React from "react";
import { Form, Input} from "antd";

const FormItem = Form.Item;
const { TextArea } = Input;


const makeField = Component => ({ input, meta, children, hasFeedback, label, config, className , ...rest }) => {
  const hasError = meta && meta.touched && meta.invalid;
  return (
    <FormItem
      {...config}
      label={label}
      validateStatus={hasError ? "error" : "success"}
      hasFeedback={hasFeedback && hasError}
      help={hasError && meta.error}
      className={className || ''}
    >
      <Component {...input} {...rest} children={children} />
    </FormItem>
  );
};

export default makeField(TextArea);