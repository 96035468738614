import { createAction, handleActions } from 'redux-actions';
// - Actions

export const fetchPickupRoutesRequest = createAction('FETCH_PICKUP_ROUTE_REQUEST');
export const fetchPickupRoutesSuccess = createAction('FETCH_PICKUP_ROUTE_SUCCESS');

export const getPickupRouteRequest = createAction('GET_PICKUP_ROUTE_REQUEST');
export const getPickupRouteSuccess = createAction('GET_PICKUP_ROUTE_SUCCESS');

export const getPickupStaffRequest = createAction('GET_PICKUP_STAFF_REQUEST');
export const getPickupStaffSuccess = createAction('GET_PICKUP_STAFF_SUCCESS');

export const removeRouteRequest = createAction('REMOVE_PICKUP_ROUTE_REQUEST');
export const removeRegionRequest = createAction('REMOVE_PICKUP_REGION_REQUEST');
export const removePickupRequest = createAction('REMOVE_PICKUP_STAFF_REQUEST');


// - Initial State
export const initialState = {
    routes: [],
    routesing: false,

    details: [],
    detailsing: false,

    pickups: null,
    pickupsing:false,

    error: null
};

// - Reducers
const reducer = handleActions({
    [fetchPickupRoutesRequest]: (state) => {
        return { ...state, routesing: true };
    },
    [fetchPickupRoutesSuccess]: (state, { payload: { routes } }) => {
        return { ...state, routesing: false, routes: routes };
    },

    [getPickupRouteRequest]: (state) => {
        return { ...state, detailsing: true };
    },
    [getPickupRouteSuccess]: (state, { payload: { routers } }) => {
        return { ...state, detailsing: false, details: routers };
    },

    [getPickupStaffRequest]: (state) => {
        return { ...state, pickupsing: true };
    },
    [getPickupStaffSuccess]: (state, { payload: { pickups } }) => {
        return { ...state, pickupsing: false, pickups: pickups };
    }
}, initialState);

export default reducer;