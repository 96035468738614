import React from 'react';
import { Link } from 'react-router-dom';
import { Menu, Tooltip } from 'antd';
import { unflatten } from './../../helpers/common';
const { SubMenu } = Menu;

export const RenderMenu = (menu) => {

    let tree = unflatten(menu);

    return tree.map(m => {
        let childrents = menu.filter(x => x.ParentId === m.Id);
        if (childrents.length > 0) {
            return (
                <SubMenu
                    key={m.Id}
                    title={
                        <div className="group-icon">
                            <div className="icon-menu"><img src={m.MenuIcon} alt=""  /></div>
                            <span className="bold">{m.Name}</span>
                        </div>
                    }
                >
                    {
                        childrents.map(m1 => {
                            return (
                                <Menu.Item key={m1.Id} title={m1.Name}>
                                    <Link to={m1.Route}>
                                        <span><i className={menu.IconClass ? `${m1.IconClass} icon` : "fa fa-cube icon"} /></span>
                                        <span className="item-title">&ensp;{m1.Name}</span>
                                    </Link>
                                </Menu.Item>
                            )
                        })
                    }
                </SubMenu>
            )
        } else {
            return (
                <Menu.Item key={m.Id} className="item-menu">
                    <Tooltip title={m.Name} placement="right">
                        <Link to={m.Route}>
                            <div className="group-icon">
                                <div className="icon-menu"><img src={m.MenuIcon} alt=""  /></div>
                                <span className="bold">{m.Name}</span>
                            </div>
                        </Link>
                    </Tooltip>
                </Menu.Item>
            )
        }
    })
}