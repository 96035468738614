import './style.scss';
import React, { useCallback, useState } from 'react';
import moment from 'moment';
import { Layout, Table, Select, Pagination, Input, Button, Collapse, Switch, Space, Tooltip, message } from 'antd';
import RestrictPage from './../../components/RestrictComponents/RestrictPage';
import { Icons } from '../../assets';
import 'pretty-print-json/dist/pretty-print-json.css'
import prettyPrintJson from 'pretty-print-json';
import { CaretRightOutlined, FilterOutlined, CaretDownOutlined, CopyOutlined } from '@ant-design/icons';
import MDEditor from '@uiw/react-md-editor'
import sqlFormatter from 'sql-formatter-plus'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { numberCommas } from '../../helpers';

const { Content } = Layout;
const { Panel } = Collapse;
const { Option } = Select;

const ErrorLogV = (props) => {
    const [useSqlFormater, setUseSqlFormater] = useState(false)

    const renderSqlCommand = useCallback((item) => {
        if (!useSqlFormater) {
            return (
                <div key={item.Id}>
                    <div style={{ marginBottom: 6 }}>Database: <span className='bold'>{item.Database}</span></div>
                    <div data-color-mode="light" className='sqlcommand-content'>
                        {item.CommandText}
                        <span className='copy-handle'>
                            <CopyToClipboard
                                text={item.CommandText}
                                onCopy={() => message.success('Đã sao chép')}>
                                <Tooltip title="Sao chép">
                                    <CopyOutlined className="handle-copy" />
                                </Tooltip>
                            </CopyToClipboard>
                        </span>
                    </div>
                </div>
            )
        }
        const format = sqlFormatter.format(item.CommandText, { language: 'sql' })
        const md = `\`\`\`sql  \n${format}  \n\`\`\``
        return (
            <div key={item.Id}>
                <div style={{ marginBottom: 6 }}>Database: <span className='bold'>{item.Database}</span></div>
                <div data-color-mode="light"><MDEditor.Markdown source={md} /></div>
            </div>
        )
        // eslint-disable-next-line
    }, [useSqlFormater])

    const createColumns = () => {
        const colums = [
            {
                title: 'Environment', dataIndex: null, key: 'Environment', width: 120, render: ({ Environment }) => {
                    return Environment
                }
            },
            {
                title: 'Time', dataIndex: null, key: 'StartTime', width: 160, render: ({ StartTime }) => {
                    return moment(StartTime).format('YYYY-MM-DD HH:mm:ss')
                }
            },
            {
                title: 'Command Text', dataIndex: null, key: 'Id', render: (item) => {
                    return renderSqlCommand(item)
                },
            },
            {
                title: 'Duration (ms)', dataIndex: null, key: 'Id', className: 'text-right', render: ({ ExecuteDuration }) => {
                    return `${ExecuteDuration}`
                },
                width: 100
            },
            {
                title: 'Result', dataIndex: null, key: 'Id', render: ({ Success }) => {
                    return Success ? <span className='result result-success'>Success</span> : <span className='result result-failed'>Failed</span>
                },
                width: 80
            }
        ]
        return colums;
    }

    const createChildTableColumns = () => {
        const colums = [
            {
                title: 'Properties', dataIndex: null, key: 'Id', className: 'vertical-align-top mw-480', render: ({ ActionName, Method, RoutePath, RoutePattern, UserCode, ClientId, TraceIdentifier, Origin }) => {
                    return <pre><output dangerouslySetInnerHTML={{
                        __html: beautiPayload({
                            ActionName,
                            Method,
                            RoutePath,
                            RoutePattern,
                            UserCode,
                            ClientId,
                            TraceIdentifier,
                            Origin
                        })
                    }} /></pre>
                }
            }
        ]
        return colums;
    }

    const { isMobile, fetching, logs } = props;
    const { pagination, params, onFetchData, onChangeParams } = props;

    const size = params.Size && Number(params.Size) > 0 ? Number(params.Size) : 50;
    const offset = params.Offset && Number(params.Offset) > 0 ? Number(params.Offset) : 1;
    const total = pagination && pagination.Total && Number(pagination.Total) > 0 ? Number(pagination.Total) : 0;
    const n_start = total > 0 ? (((offset - 1) * size) + 1) : 0;
    const n_end = total > 0 ? ((offset * size) > total ? total : offset * size) : 0;
    return (
        <RestrictPage>
            <Content className="page-error-logs">
                <div className="panel-header">
                    <div className="header-title">
                        <i className="fa fa-home icon" aria-hidden="true"></i>
                        <span>Hệ thống</span>
                        <span className="icon-next"><i className="fa fa-angle-right"></i></span>
                        <span className="active">SQL Command Logs</span>
                    </div>
                </div>
                <div className={isMobile ? "col-md-12 pd-0" : "col-md-12"}>
                    <div className="panel-body">
                        <div className="row">
                            <div className="col-md-12">
                                <Collapse className="filter-container" ghost expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}>
                                    <Panel header={<span className="bold">FILTER</span>} key="1">
                                        <div className="row">
                                            <div className="col-lg-3 col-sm-6 col-xs-12" style={{ marginBottom: 15 }}>
                                                <label className="bold">Environment</label>
                                                <Select defaultValue={params.Environment || ''} onChange={(value) => onChangeParams({ Environment: value })} style={{ width: '100%' }}>
                                                    <Option value="">All</Option>
                                                    <Option value="Production">API:Production</Option>
                                                    <Option value="Op">API:Op</Option>
                                                    <Option value="Crm">API:Crm</Option>
                                                    <Option value="GenCode">Code generation service</Option>
                                                    <Option value="Identity">Identity</Option>
                                                </Select>
                                            </div>
                                            <div className="col-lg-3 col-sm-6 col-xs-12" style={{ marginBottom: 15 }}>
                                                <label className="bold">Text in message</label>
                                                <Input defaultValue={params.Query || ''} placeholder="Request path" onChange={(e) => onChangeParams({ Query: e.target.value })} style={{ width: '100%' }} />
                                            </div>
                                            <div className="col-lg-3 col-sm-6 col-xs-12" style={{ marginBottom: 15 }}>
                                                <label className="bold">Start Time</label>
                                                <Input type="datetime-local" defaultValue={params.StartTime || ''} placeholder="Start time" onChange={(e) => onChangeParams({ StartTime: e.target.value })} style={{ width: '100%' }} />
                                            </div>
                                            <div className="col-lg-3 col-sm-6 col-xs-12" style={{ marginBottom: 15 }}>
                                                <label className="bold">End Time</label>
                                                <Input type="datetime-local" defaultValue={params.EndTime || ''} placeholder="Start time" onChange={(e) => onChangeParams({ EndTime: e.target.value })} style={{ width: '100%' }} />
                                            </div>
                                            <div className="col-lg-3 col-sm-6 col-xs-12" style={{ marginBottom: 15 }}>
                                                <label className="bold">Duration</label>
                                                <Input type="number" defaultValue={params.Duration || ''} placeholder="Time greater than or equal (ms)" onChange={(e) => onChangeParams({ Duration: e.target.value })} style={{ width: '100%' }} />
                                            </div>
                                        </div>
                                        <div className="row" style={{ marginBottom: 15 }}>
                                            <div className="col-lg-12">
                                                <Button loading={fetching} icon={<FilterOutlined />} onClick={() => onFetchData({})} >Filter</Button>
                                            </div>
                                        </div>
                                    </Panel>
                                </Collapse>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md-4">
                                <label className="total-filter">
                                    <img src={Icons.common.filter} alt="Số request" />|&ensp;<span>{n_start} - {n_end}</span> trong <span>{numberCommas(total || 0)}</span>
                                </label>
                            </div>
                            <div className="col-sm-12 col-md-8 text-right">
                                <div>
                                    <Space>
                                        <Space>
                                            <span>SQL beautify</span>
                                            <Switch checked={useSqlFormater} onChange={checked => setUseSqlFormater(checked)} />
                                        </Space>
                                        <Pagination current={offset || 1} pageSize={size || 20} total={total || 0} showSizeChanger={false} onChange={(offset) => onFetchData({ Offset: offset })} />
                                    </Space>
                                </div>
                            </div>
                        </div>
                        <div className="row mr-t-10">
                            <div className="col-md-12">
                                <Table
                                    loading={fetching}
                                    className="table-request-histories"
                                    columns={createColumns(props)}
                                    rowKey="Id"
                                    size="small"
                                    dataSource={logs || []}
                                    pagination={false}
                                    bordered={true}
                                    expandable={{
                                        expandRowByClick: true,
                                        expandedRowRender: (record) => {
                                            return (
                                                <div className="table-view-children">
                                                    <Table
                                                        key="tab1"
                                                        className="table-chidren small"
                                                        columns={createChildTableColumns()}
                                                        rowKey='Id'
                                                        size="small"
                                                        dataSource={[{ ...record }]}
                                                        pagination={false}
                                                        bordered={true}
                                                    />
                                                </div>
                                            )
                                        },
                                        expandIcon: ({ expanded, onExpand, record }) =>
                                            expanded ? (
                                                <CaretDownOutlined onClick={e => onExpand(record, e)} />
                                            ) : (
                                                <CaretRightOutlined onClick={e => onExpand(record, e)} />
                                            )
                                    }}
                                    scroll={{ y: 'calc(100vh - 200px)', x: 1080 }}
                                />
                            </div>
                        </div>

                        <div className="row mr-t-10">
                            <div className="col-md-6">
                                <span>Hiển thị &ensp;
                                    <Select value={size || 20} style={{ width: 120 }} onChange={(size) => onFetchData({ Size: size, Offset: 1 })}>
                                        <Select.Option value="50">50</Select.Option>
                                        <Select.Option value="100">100</Select.Option>
                                        <Select.Option value="150">150</Select.Option>
                                        <Select.Option value="200">200</Select.Option>
                                    </Select>
                                    &nbsp; bản ghi / trang
                                </span>
                            </div>
                            <div className="col-md-6 text-right">
                                <Pagination current={offset || 1} pageSize={size || 20} total={total || 0} showSizeChanger={false} onChange={(offset) => onFetchData({ Offset: offset })} />
                            </div>
                        </div>
                    </div>
                </div>
            </Content>
        </RestrictPage>
    );
};

export default ErrorLogV

const beautiPayload = (_obj) => {
    try {
        return prettyPrintJson.toHtml(_obj);
    } catch (error) {
        return _obj;
    }
}