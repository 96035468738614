import { lifecycle, compose as recompose, withState, withHandlers } from 'recompose';
import { message } from 'antd';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getMyPricesRequest, getDetailPriceRequest } from '../../../../reduxs/service-redux/reducer';
import { getTypeServicesRequest } from '../../../../reduxs/common-redux/reducer';
import { selectTypeServices } from '../../../../reduxs/common-redux/selectors';
// import { selectUser } from '../../../../reduxs/user-redux/selectors';
import PriceListV from './PriceListV';

export function mapDispatchToProps(dispatch) {
    return {
      getDetailPrice: (params) => dispatch(getDetailPriceRequest(params)),
      fetchPrices: (params) => dispatch(getMyPricesRequest(params)),
      getServices: (params) => dispatch(getTypeServicesRequest(params)),
    };
}

const mapStateToProps = createStructuredSelector({
    services: selectTypeServices(),
    // user: selectUser(),
    prices: (state) => state.service.prices,
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default recompose(
    withConnect,
    withState('customer', 'setCustomer', null),
    withState('open', 'setOpen', false),
    withState('view', 'setView', false),
    withState('price', 'setPrice', null),
    withState('service', 'setService', ''),
    withState('serviceCode', 'setServiceCode', ''),
    withState('openCommission', 'setOpenCommission', false),
    withState('params', 'setParams', { Offset: 1, Size: 20 }),
    withHandlers({
      onViewPrice: ({ setOpen, user, setService, fetchPrices }) => (service) => {
        if(user && service && service.Code) {
          fetchPrices({ ServiceCode: service.Code, AccountNo: user.AccountNr });
          setService(service, () => setOpen(true));
        }
      },
      onView: ({ setServiceCode, setPrice, setView, getDetailPrice }) => (price) => {
        if(price && price.Id && price.ServiceCode) {
          getDetailPrice({
            data: { Id: price.Id },
            cb: (res, result) => {
              if(res) {
                setServiceCode(price.ServiceCode, () => setPrice(result, () => setView(true)));
              } else {
                message.error('Lấy thông tin bảng giá thất bại!')
              }
            }
          })
        } else {
          message.error('Thiếu thông tin bảng giá')
        }
      },
    }),
    // withPropsOnChange(['user'], ({user, getServices}) => {
    // }),
    lifecycle({
      componentDidMount() {
        const { user, getServices } = this.props;
        if(user && user.Id) {
          getServices({Type: 'DOMESTIC', CustomerId: user.Id || ''});
        }
      }
    })
)(PriceListV);
