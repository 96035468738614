import { createSelector } from 'reselect';
import { initialState } from './reducer';

const staffState = (state) => state.staff || initialState;

const selectStaffs = () => createSelector(
  staffState,
  ({ staffs }) => staffs
);
const selectStaffsing = () => createSelector(
  staffState,
  ({ loading }) => loading
);
const selectPagination = () => createSelector(
  staffState,
  ({ pagination }) => pagination
);

const selectDefaultAccount = () => createSelector(
  staffState,
  ({ defaultAccount }) => defaultAccount
);

const selectCommissions = () => createSelector(
  staffState,
  ({ commissions }) => commissions
);
const selectCommissionsing = () => createSelector(
  staffState,
  ({ commissionsing }) => commissionsing
);
const selectCommissionPagin = () => createSelector(
  staffState,
  ({ commissionPagination }) => commissionPagination
);


const selectCommission = () => createSelector(
  staffState,
  ({ commission }) => commission
);
const selectCommissioning = () => createSelector(
  staffState,
  ({ commissioning }) => commissioning
);

export {
  staffState,
  selectStaffs,
  selectStaffsing,
  selectPagination,
  selectDefaultAccount,
  selectCommissions,
  selectCommissionsing,
  selectCommissionPagin,
  selectCommission,
  selectCommissioning
}
