
import axios from '../utils/axiosInstance';

export const countTaskInQueue = async () => {
    try {
        const { data } = await axios.get('/api/count-task-queue', { baseURL: 'https://apiv2.pcs.vn' });
        return data;
    } catch (error) {
        throw error;
    }
};

export const countTaskInQueueOp = async () => {
    try {
        const { data } = await axios.get('/api/count-task-queue', { baseURL: 'https://api-op.pcs.vn' });
        return data;
    } catch (error) {
        throw error;
    }
};

export const countTaskInQueueCrm = async () => {
    try {
        const { data } = await axios.get('/api/count-task-queue', { baseURL: 'https://api-crm.pcs.vn' });
        return data;
    } catch (error) {
        throw error;
    }
};

export const getTaskQueueDetails = async () => {
    try {
        const { data } = await axios.get('/api/count-task-queue/detail', { baseURL: 'https://apiv2.pcs.vn' });
        return data;
    } catch (error) {
        throw error;
    }
};

export const getTaskQueueDetailsOp = async () => {
    try {
        const { data } = await axios.get('/api/count-task-queue/detail', { baseURL: 'https://api-op.pcs.vn' });
        return data;
    } catch (error) {
        throw error;
    }
};

export const getTaskQueueDetailsCrm = async () => {
    try {
        const { data } = await axios.get('/api/count-task-queue/detail', { baseURL: 'https://api-crm.pcs.vn' });
        return data;
    } catch (error) {
        throw error;
    }
};