
import axios from '../utils/axiosPartner';

export const fetchPartners = async (params) => {
  try {
    const { data } = await axios.get('/admin/collaborator/get-collaborators', { params });
    return data;
  } catch (error) {
    throw error;
  }
};

export const confirmBillPartner = async (body) => {
  try {
    const { data } = await axios.post('/admin/collaborator/censor-collaborator', body);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getCommitments = async (params ={}) => {
  try {
    const { data } = await axios.get('/collaborator/get-commitments', { params });
    return data;
  } catch (error) {
    throw error;
  }
};
