
import React from 'react';
import {  Modal, Select, Button, notification } from 'antd';
import { compose as recompose, withHandlers, lifecycle, withState } from 'recompose';
import { SaveOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { changeAccountCodeRequest } from '../../../reduxs/account-redux/reducer';
import { getCodesRequest } from '../../../reduxs/account-redux/reducer';
import { selectCodes, selectCodesing } from '../../../reduxs/account-redux/selectors';

const CodeForm = (props) => {
    const { open, setValue, value, codes, submitting, onHide, onAccountCode } = props;
    let _codes = [];
    if(codes && codes.length > 0) {
        _codes = codes.map((item) => {
            return {
                value: item.Id,
                label: item.Name,
            }
        })
    }
    return (
        <Modal
            title="Cập nhật mã cha"
            visible={open}
            style={{ top: 20 }}
            width={'460px'}
            footer={null}
            maskClosable={false}
            onOk={() => onHide()}
            onCancel={() => onHide()}
            className="modal-form-zone"
            >
                <div className="box-form-type">
                    <Select
                        value={value}
                        options={_codes}
                        onChange={(value) => setValue(value)}
                        className="w-100"
                        showSearch
                        filterOption={(input, option) =>
                            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    />
                    <div className="w-100 mr-t-15 text-center">
                        <Button type="primary" onClick={() =>onAccountCode()} loading={submitting} disabled={!value} icon={<SaveOutlined />}>Cập nhật</Button>
                    </div>
                </div>
        </Modal>
    )
}

function mapDispatchToProps(dispatch) {
    return {
        changeAccountCode: (params) => dispatch(changeAccountCodeRequest(params)),
        getCodes: (params) => dispatch(getCodesRequest(params))
    };
}
const mapStateToProps = createStructuredSelector({
    codes: selectCodes(),
    loading: selectCodesing(),
  });
  
const withConnect = connect(mapStateToProps, mapDispatchToProps);
  
export default recompose(
    withConnect,
    withState('value', 'setValue', ''),
    withState('submitting', 'setSubmitting', false),
    withHandlers({
        fetchCodes: ({ getCodes }) => (param) => {
            let _params = { Size: 50, Offset: 1, ...param };
            getCodes({ ..._params});
        },
        onAccountCode: ({ value, account, setValue, close, setSubmitting, fetchAccounts, changeAccountCode }) => () => {
            if(value && account && account.Id) {
                setSubmitting(true);
                changeAccountCode({
                    data: {
                        ParentCode: value,
                        CustomerId: account.Id,
                    },
                    cb: (type, message) => {
                        setSubmitting(false);
                        if(type === 'error'){
                            notification.error({message: message || 'Cập nhật mã cha thất bại!'});
                        } else {
                            notification.success({message: 'Cập nhật mã cha thành công!'});
                            fetchAccounts();
                            setValue(null, () => close(false))
                        }
                    }
                })
            }
        },
        onHide: ({setValue, close}) => () => {
            setValue(null, () => close(false))
        }
    }),
    lifecycle({
        componentDidMount() {
            this.props.fetchCodes();
            // const {account, setValue} = this.props;
            // if(account && account.AccountTypeId) setValue(account.AccountTypeId)
        },
    }),
)(CodeForm);
