import React from 'react';
import { lifecycle, compose as recompose, withState, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { message, notification, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';
import {
  getRegionsRequest,
  getProvincesRequest,
  getProvincesSuccess,
  deleteProvincesRequest,
  getDistrictsRequest,
  getDistrictsSuccess,
  deleteDistrictsRequest,
  getWardsRequest,
  getWardsSuccess,
  deleteWardsRequest,
  saveLocalRegionRequest,
  getPannersRequest,
  saveWardInfoRequest,
  deleteWardInfoRequest,
  downloadLocationListRequest
} from '../../reduxs/region-redux/reducer';
import { selectRegions, selectProvinces, selectDistricts, selectWards, selectPanners } from '../../reduxs/region-redux/selectors';

import Auth from '../../storage/Auth';

import RegionV from './RegionV';

const { confirm } = Modal;

export function mapDispatchToProps(dispatch) {
  return {
    getRegions: (params) => dispatch(getRegionsRequest(params)),
    getProvinces: (params) => dispatch(getProvincesRequest(params)),
    setProvinces: (params) => dispatch(getProvincesSuccess(params)),
    deleteProvinces: (params) => dispatch(deleteProvincesRequest(params)),
    getDistricts: (params) => dispatch(getDistrictsRequest(params)),
    setDistricts: (params) => dispatch(getDistrictsSuccess(params)),
    deleteDistricts: (params) => dispatch(deleteDistrictsRequest(params)),
    getWards: (params) => dispatch(getWardsRequest(params)),
    setWards: (params) => dispatch(getWardsSuccess(params)),
    deleteWards: (params) => dispatch(deleteWardsRequest(params)),
    getPanners: (params) => dispatch(getPannersRequest(params)),
    saveLocalRegion: (params) => dispatch(saveLocalRegionRequest(params)),
    saveWardInfo: (params) => dispatch(saveWardInfoRequest(params)),
    deleteWardInfo: (params) => dispatch(deleteWardInfoRequest(params)),
    downloadLocationList: (params) => dispatch(downloadLocationListRequest(params)),
  };
}

const mapStateToProps = createStructuredSelector({
  regions: selectRegions(),
  provinces: selectProvinces(),
  districts: selectDistricts(),
  wards: selectWards(),
  panners: selectPanners(),
  downloading: (store) => store.region && store.region.downloading,
  currentStation: store => store.station && store.station.station
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default recompose(
  withConnect,
  withState('monthFilter', 'setMonthFilter', moment()),
  withState('region', 'setRegion', null),
  withState('province', 'setProvince', null),
  withState('district', 'setDistrict', null),
  withState('openRegion', 'setOpenRegion', false),
  withState('openProvince', 'setOpenProvince', false),
  withState('openPlace', 'setOpenPlace', false),
  withState('checkedWards', 'setCheckedWards', []),
  withState('provinceOptions', 'setProvinceOptions', {}),
  withState('districtOptions', 'setDistrictOptions', {}),
  withState('wardOptions', 'setWardOptions', {}),
  withState('searchProvince', 'setSearchProvince', null),
  withState('searchDistrict', 'setSearchDistrict', null),
  withState('searchWard', 'setSearchWard', null),

  withState('editProvince', 'setEditProvince', null),
  withState('showProvince', 'setShowProvince', false),

  withState('editDistrict', 'setEditDistrict', null),
  withState('showDistrict', 'setShowDistrict', false),

  withState('editWards', 'setEditWards', null),
  withState('showWards', 'setShowWards', false),

  withHandlers({
    onProvince: ({ setEditProvince, setShowProvince }) => (item) => {
      setEditProvince(item || null);
      setShowProvince(true);
    },
    onDistrict: ({ setEditDistrict, setShowDistrict }) => (item) => {
      setEditDistrict(item || null);
      setShowDistrict(true);
    },
    onWards: ({ setEditWards, setShowWards }) => (item) => {
      setEditWards(item || null);
      setShowWards(true);
    },

  }),

  withHandlers({
    fetchRegions: ({ getRegions, setRegion, setProvinces, setDistricts, setWards }) => () => {
      getRegions();
      setRegion(null);
      setProvinces({ provinces: [] });
      setDistricts({ districts: [] });
      setWards({ wards: [] });
    },
    fetchProvinces: ({ setRegion, setProvince, setDistrict, getProvinces, setDistricts, setWards }) => (id) => {
      setRegion(id);
      setProvince(null);
      setDistrict(null);
      if (id) {
        getProvinces({ RegionId: id, StationCode: Auth.getStation() });
      }
      setDistricts({ districts: [] });
      setWards({ wards: [] });
    },
    fetchDistricts: ({ setProvince, setDistrict, getDistricts, setWards, region }) => (id) => {
      setProvince(id);
      setDistrict(null);
      getDistricts({ ProvinceId: id, City: id, RegionId: region, StationCode: Auth.getStation() });
      setWards({ wards: [] });
    },
    fetchWards: ({ setDistrict, getWards, region }) => (id) => {
      setDistrict(id);
      getWards({ DistrictId: id, RegionId: region, StationCode: Auth.getStation() });
    },
    downloadLocation: ({ downloadLocationList }) => () => {
      const params = {
        cb: (type) => {
          if (type === 'success') {
            message.success('Tải xuống thành công!')
          } else {
            message.error('Tải xuống không thành công!')
          }
        }
      }
      downloadLocationList(params);
    },
  }),
  withHandlers({
    onSelectWard: ({ setCheckedWards }) => (items) => {
      setCheckedWards(items)
    },
    onProvinceOptions: ({ provinceOptions, setProvinceOptions }) => (id, option) => {
      const data = { ...provinceOptions };
      data[id] = option;
      setProvinceOptions(data);
    },
    onDistrictOptions: ({ districtOptions, setDistrictOptions }) => (id, option) => {
      const data = { ...districtOptions };
      data[id] = option;
      setDistrictOptions(data);
    },
    onOptionsWards: ({ wardOptions, setWardOptions }) => (id, option) => {
      const data = { ...wardOptions };
      data[id] = option;
      setWardOptions(data);
    },

    onSavePlace: ({ region, province, district, checkedWards, provinceOptions, districtOptions, wardOptions, saveLocalRegion }) => () => {
      if (checkedWards && checkedWards.length) {
        const wards = checkedWards.map((ward) => {
          return {
            Id: ward,
            ...wardOptions[ward]
          }
        });

        const data = {
          RegionId: region,
          Province: [
            {
              Id: province,
              ...provinceOptions[province]
            }
          ],
          District: [
            {
              ParentId: province,
              Childs: [
                {
                  Id: district,
                  ...districtOptions[district]
                }
              ]
            }
          ],
          Ward: [
            {
              ParentId: district,
              Childs: wards
            }
          ]
        }

        const callback = (type) => {
          if (type === 'error') {
            notification[type]({ message: 'Lưu thất bại!' });
          } else {
            notification[type]({ message: 'Lưu thành công!' });
          }
        }

        const params = {
          data: data,
          cb: callback
        }

        saveLocalRegion(params)
      } else {
        message.warning('Xã phường chưa được chọn');
      }
    }
  }),
  withHandlers({
    onSaveWardInfo: ({ region, province, district, onOptionsWards, saveWardInfo }) => (id, option) => {
      let StationCode = Auth.getStation();
      if (region && province && district && StationCode && id) {
        const callback = (type) => {
          notification[type]({ message: type === 'error' ? 'Cập nhật thất bại!' : 'Cập nhật thành công!' });
          if (type === 'success') {
            onOptionsWards(id, option)
          }
          // fetchWards(district)
        }

        const params = {
          data: {
            StationCode: StationCode,
            RegionId: region,
            ProvinceId: province,
            DistrictId: district,
            WardId: id,
            ...option
          },
          cb: callback
        }
        saveWardInfo(params)
      }
    },
    toggleWardInfo: ({ region, province, district, checkedWards, setCheckedWards, saveWardInfo, deleteWardInfo }) => (id) => {
      let StationCode = Auth.getStation();
      if (region && province && district && StationCode && id) {
        if (checkedWards.includes(id)) {
          let ids = checkedWards.filter((i) => i !== id)
          confirm({
            title: 'Bạn muốn xóa xã phường khỏi phân vùng?',
            icon: <ExclamationCircleOutlined />,
            content: 'Thông tin xóa sẽ không thể khôi phục được',
            okText: 'Xóa',
            okType: 'danger',
            cancelText: 'Thôi',
            onOk() {
              const callback = (type) => {
                notification[type]({ message: type === 'error' ? 'Xóa thất bại!' : 'Xóa thành công!' });
                if (type === 'success') {
                  setCheckedWards(ids);
                }
              }

              const params = {
                data: {
                  StationCode: StationCode,
                  RegionId: region,
                  ProvinceId: province,
                  DistrictId: district,
                  WardId: id,
                },
                cb: callback
              }
              deleteWardInfo(params)
            },
            onCancel() {
              console.log('Cancel');
            },
          });
        } else {
          const callback = (type) => {
            notification[type]({ message: type === 'error' ? 'Cập nhật thất bại!' : 'Cập nhật thành công!' });
            if (type === 'success') {
              setCheckedWards([...checkedWards, id]);
            }
          }

          const params = {
            data: {
              StationCode: StationCode,
              RegionId: region,
              ProvinceId: province,
              DistrictId: district,
              WardId: id,
              IsInnerCity: false,
              IsOutlyingArea: false,
              IsIndustrialArea: false,
              Island: false,
              PartnerCode: 'PCSPOST',
              PickupUnavailable: false,
              DeliveryUnavailable: false,
            },
            cb: callback
          }
          saveWardInfo(params)
        }
      }
    }
  }),
  //Hanld delete
  withHandlers({
    deleteProvince: ({ region, fetchProvinces, deleteProvinces }) => (item) => {
      confirm({
        title: 'Bạn muốn xóa tỉnh thành này?',
        icon: <ExclamationCircleOutlined />,
        content: 'Thông tin xóa sẽ không thể khôi phục được',
        okText: 'Xóa',
        okType: 'danger',
        cancelText: 'Thôi',
        onOk() {
          const callback = (type) => {
            notification[type]({ message: type === 'error' ? 'Xóa thất bại!' : 'Xóa thành công!' });
            fetchProvinces(region)
          }

          const params = {
            data: { Id: [item.Id] },
            cb: callback
          }

          deleteProvinces(params)
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    },
    deleteDistrict: ({ province, fetchDistricts, deleteDistricts }) => (item) => {
      confirm({
        title: 'Bạn muốn xóa quận huyện này?',
        icon: <ExclamationCircleOutlined />,
        content: 'Thông tin xóa sẽ không thể khôi phục được',
        okText: 'Xóa',
        okType: 'danger',
        cancelText: 'Thôi',
        onOk() {
          const callback = (type) => {
            notification[type]({ message: type === 'error' ? 'Xóa thất bại!' : 'Xóa thành công!' });
            fetchDistricts(province)
          }

          const params = {
            data: { Id: [item.Id] },
            cb: callback
          }

          deleteDistricts(params)
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    },
    deleteWards: ({ district, fetchWards, deleteWards }) => (item) => {
      confirm({
        title: 'Bạn muốn xóa xã phường này?',
        icon: <ExclamationCircleOutlined />,
        content: 'Thông tin xóa sẽ không thể khôi phục được',
        okText: 'Xóa',
        okType: 'danger',
        cancelText: 'Thôi',
        onOk() {
          const callback = (type) => {
            notification[type]({ message: type === 'error' ? 'Xóa thất bại!' : 'Xóa thành công!' });
            fetchWards(district)
          }

          const params = {
            data: { Id: [item.Id] },
            cb: callback
          }

          deleteWards(params)
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    }
  }),
  lifecycle({
    componentDidMount() {
      const { fetchRegions, panners, getPanners } = this.props;
      fetchRegions();
      if (!(panners && panners.length)) {
        getPanners({ CountryCode: 'VN' })
      }
    },
    componentDidUpdate(props) {
      const { region, regions, fetchProvinces, wards, setCheckedWards, setWardOptions } = this.props;
      if (!region && regions && regions.length && regions !== props.regions) {
        fetchProvinces(regions[0].Id);
      }

      if (region && wards && wards.length && wards !== props.wards) {
        let _wards = wards.filter((item) => item.RegionId === Number(region));
        const options = {};
        let valueWards = _wards.map((item) => {
          options[item.Id] = {
            IsInnerCity: item.IsInnerCity,
            IsOutlyingArea: item.IsOutlyingArea,
            IsIndustrialArea: item.IsIndustrialArea,
            Island: item.Island,
            PartnerCode: item.PartnerCode,
            PickupUnavailable: item.PickupUnavailable || false,
            DeliveryUnavailable: item.DeliveryUnavailable || false,
          }
          return item.Id
        });
        setWardOptions(options);
        setCheckedWards(valueWards);
      }
    }
  }),
)(RegionV);