
import axios from '../utils/axiosInstance';
import { APP_CONFIG } from '../config';

export const createNotification = async (params = {}) => {
  try {
    const { data } = await axios.post(`${APP_CONFIG.NOTIF_API_HOST}/api/notification`, params);
    return data;
  } catch (error) {
    throw error;
  }
};
