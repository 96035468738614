import { all } from "redux-saga/effects";
import authSaga from "./auth-redux/saga";
import userSaga from './user-redux/saga';
import stationSaga from "./station-redux/saga";
import reportSaga from "./report-redux/saga";
import accountSaga from "./account-redux/saga";
import customerDocSaga from './customer-doc-redux/saga';
import utilitySaga from './utility-redux/saga';
import commonSaga from './common-redux/saga';
import regionSaga from "./region-redux/saga";
import areaSaga from "./area-redux/saga";
import menuSaga from "./menu-redux/saga";
import roleSaga from "./role-redux/saga";
import autoSaga from "./auto-redux/saga";
import policySaga from "./policy-redux/saga";
import manageStationSaga from "./manageStation-redux/saga";
import staffSaga from "./staff-redux/saga";
import departmentSaga from "./department-redux/saga";
import emailSaga from './email-redux/saga';
import userMenuSaga from './user-menu-redux/saga';
import cacheSaga from './cache-redux/saga';
import serviceSaga from './service-redux/saga';
import pickupRouteSaga from './pickup-route-redux/saga';
import featureSaga from './feature-redux/saga';
import webhookSaga from './webhook-redux/saga';
import partnerSaga from './partner-redux/saga';
import assetSaga from './asset-redux/saga';
import assetCommonSaga from './asset-common-redux/saga';
import assetSettingSaga from './asset-setting-redux/saga';
import assetHandoverSaga from './asset-handover-redux/saga';
import assetTransferSaga from './asset-transfer-redux/saga';
import assetSaleSaga from './asset-sale-redux/saga';
import assetAdjustSaga from './asset-adjustment-redux/saga';
import requestHistorySaga from './request-history-redux/saga';
import errorLogSaga from './error-log-redux/saga';
import dbChangeLogSaga from './db-change-redux/saga';
import taskQueueSaga from './task-queue-redux/saga';
import notiSaga from './notification-redux/saga';
import customerInfSheetSaga from './customer-info-sheet-redux/saga';
import commissionSaga from './commission-redux/saga';
import emailSubscriptionSaga from './email-subscription-redux/saga';
import notiTemplateSaga from './notification-template-redux/saga';
import exchargeSaga from './excharge-redux/saga';
import exportFuelSaga from './export-fuel-redux/saga';
import zonePuSaga from './zone-pickup-redux/saga';

export default function* rootSaga(getState) {
  yield all([
    ...authSaga,
    ...userSaga,
    ...stationSaga,
    ...reportSaga,
    ...accountSaga,
    ...customerDocSaga,
    ...utilitySaga,
    ...commonSaga,
    ...menuSaga,
    ...regionSaga,
    ...areaSaga,
    ...roleSaga,
    ...autoSaga,
    ...policySaga,
    ...manageStationSaga,
    ...departmentSaga,
    ...staffSaga,
    ...emailSaga,
    ...userMenuSaga,
    ...cacheSaga,
    ...serviceSaga,
    ...pickupRouteSaga,
    ...featureSaga,
    ...webhookSaga,
    ...partnerSaga,
    ...assetSaga,
    ...assetCommonSaga,
    ...assetSettingSaga,
    ...assetHandoverSaga,
    ...assetTransferSaga,
    ...assetSaleSaga,
    ...assetAdjustSaga,
    ...requestHistorySaga,
    ...errorLogSaga,
    ...dbChangeLogSaga,
    ...taskQueueSaga,
    ...notiSaga,
    ...customerInfSheetSaga,
    ...commissionSaga,
    ...emailSubscriptionSaga,
    ...notiTemplateSaga,
    ...exchargeSaga,
    ...exportFuelSaga,
    ...zonePuSaga
  ]);
}
