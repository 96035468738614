import React from 'react'
import { lifecycle, compose as recompose, withState, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Avatar, Modal, Popover, Badge } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { icons } from '../../assets/Images'
import userManager from "../../utils/userManager";
import { APP_CONFIG, getAvatar } from './../../config'
import './style.scss'

import { selectStation } from '../../reduxs/station-redux/selectors';
import { openStationRequest } from '../../reduxs/station-redux/reducer';
import { toggleShow } from '../../notif-msg-modules/src/modules'


const Hearder = (props) => {
    const { user, changeStation, station, toggle, setToggle, open, close, onToggleShowNotification, totalNew } = props;

    const username = user && user.profile ? (user.profile.fullName || user.profile.username) : '';

    const staffCode = user && user.profile && user.profile.staffCode;

    const _profile = (
        <div className="admin-link-setup">
            <label className="bold">{username}</label>
            <a href={`${APP_CONFIG.URL_LOGIN}/account/basic`} target="_blank" rel="noopener noreferrer"><i className="fa fa-user-circle-o" />&ensp;Thông tin tài khoản</a>
            <a href={`${APP_CONFIG.URL_LOGIN}/account/security`} target="_blank" rel="noopener noreferrer"><i className="fa fa-unlock-alt" />&ensp;Thay đổi mật khẩu</a>
            <Link to="" onClick={event => {
                event.preventDefault();
                const id_token = user.id_token;
                userManager.removeUser().then((result) => {
                    userManager.signoutRedirect({ 'id_token_hint': id_token });
                });
            }}><i className="fa fa-sign-out" />&ensp;Đăng xuất</Link>
        </div>
    );

    const _station = (
        <div className="admin-link-setup">
            <a href="/" onClick={(e) => { e.preventDefault(); close(true) }}><i className="fa fa-building-o" />&ensp;Thông tin đơn vị</a>
            <a href="/" onClick={(e) => { e.preventDefault(); changeStation(true) }}><i className="fa fa-exchange" />&ensp;Thay đổi đơn vị</a>
        </div>
    );

    return (
        <section className="container header-main">
            <div className="logo hidden-lg hidden-md">
                <Link to="/">
                    <img src={icons.common.logo} alt="" />
                </Link>
            </div>
            <label className="title-username bold hidden-xs"><img src={icons.common.hello} alt="" />Xin chào bạn, {username}</label>
            <div className="header-setting">
                <Popover placement="bottomRight" content={_station} trigger="click">
                    <Badge>
                        <small className="hidden-xs">Đơn vị: <span className="bold hover uppercase">{station ? (station.ADD3 ? station.ADD3 : station.NAME) : ''}</span></small>
                        <i className="hidden-md hidden-lg fa fa-building-o" />
                    </Badge>
                </Popover>
                {/* <Popover placement="bottomRight" content={_setup} trigger="click">
                    <Badge count={5}><i className="fa fa-envelope" /></Badge>
                </Popover> */}
                <Badge onClick={() => onToggleShowNotification()} count={totalNew}><i className="fa fa-bell" /></Badge>
                &emsp;
                <Popover placement="bottomRight" content={_profile} trigger="click">
                    <Avatar icon={<img src={getAvatar(staffCode)} alt="user avatar" />} size={30} /><span className="hidden-xs">&ensp;<i className="fa fa-sort-desc" /></span>
                </Popover>
                <span onClick={() => setToggle(!toggle)} className="ant-badge hidden-md hidden-lg" style={{ width: '25px' }}>{toggle ? <CloseOutlined /> : <i className="fa fa-bars" />}</span>
            </div>
            <Modal
                title={station && station.ADD3 ? station.ADD3 : (station && station.NAME)}
                centered
                visible={open}
                width={'560px'}
                footer={null}
                className="modal-form"
                onOk={() => close(false)}
                onCancel={() => close(false)}
            >
                <br />
                <div className="row group-info-task">
                    <div className="col-md-12">
                        <div className="form-group station-info">
                            <i className="fa fa-building" />
                            <label className="form-label control-label ng-binding short">
                                Tên đầy đủ:&ensp;<span className="bold">{station && station.FULL_NAME}</span>
                            </label>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group station-info">
                            <i className="fa fa-phone-square" />
                            <label className="form-label control-label ng-binding short">
                                Số ĐT:&ensp;<span className="bold">{station && station.TEL}</span>
                            </label>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group station-info">
                            <i className="fa fa-fax" />
                            <label className="form-label control-label ng-binding short">
                                Số FAX:&ensp;<span className="bold">{station && station.FAX}</span>
                            </label>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group station-info">
                            <i className="fa fa-bandcamp" />
                            <label className="form-label control-label ng-binding short">
                                Mã bưu cục:&ensp;<span className="bold">{station && station.STATION_CODE}</span>
                            </label>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group station-info">
                            <i className="fa fa-cube" />
                            <label className="form-label control-label ng-binding short">
                                Mã vùng:&ensp;<span className="bold">{station && station.ZONE_CODE}</span>
                            </label>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="form-group station-info">
                            <i className="fa fa-map-marker" />
                            <label className="form-label control-label ng-binding short">
                                Địa chỉ bưu cực:&ensp;<span className="bold">{station && station.ADD1}</span>
                            </label>
                        </div>
                    </div>

                </div>
            </Modal>
        </section>
    )
}

export function mapDispatchToProps(dispatch) {
    return {
        changeStation: (data) => dispatch(openStationRequest(data)),
        toggleShowNotif: () => dispatch(toggleShow())
    };
}

const mapStateToProps = createStructuredSelector({
    user: (state) => state.oidc && state.oidc.user,
    station: selectStation(),
    totalNew: store => store.notification && store.notification.totalNew
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default recompose(
    withConnect,
    withState('open', 'close', false),
    withHandlers({
        onToggleShowNotification: ({ toggleShowNotif }) => () => toggleShowNotif()
    }),
    lifecycle({
        componentDidMount() {
        },
        componentDidUpdate(props) {

        }
    }),
)(Hearder)
