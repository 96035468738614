import React from "react";
import { Form, TimePicker  } from "antd";
import moment from 'moment';

const FormItem = Form.Item;

const makeField = Component => ({ input, meta, children, hasFeedback, label, ...rest }) => {
  const hasError = meta.touched && meta.invalid;
  console.log(input);
  let time = null;
  if(input.value) {
    time = moment(input.value, 'HH:mm');
    time = time.isValid() ? time : null;
  }
  const onChange = (time, timeString) => {
    input.onChange(timeString);
  }

  return (
    <FormItem
      label={label}
      validateStatus={hasError ? "error" : "success"}
      hasFeedback={hasFeedback && hasError}
      help={hasError && meta.error}
    >
      <Component value={time}  {...rest} onChange={onChange}  format="HH:mm" />
    </FormItem>
  );
};

export default makeField(TimePicker);