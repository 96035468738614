import { call, put, takeLatest } from 'redux-saga/effects';
import * as RoleService from '../../services/role-service';

import { RoleFormAction, UserFormAction } from "./action";
import { SubmissionError } from "redux-form";

import {
  fetchRolesSuccess,
  fetchRoleUsersSuccess,
  failure
} from './reducer';

export function* fetchRoles({ payload = {} }) {
  try {
    const { Result } = yield call(RoleService.getRoles, payload);
    if(Result) {
      yield put(fetchRolesSuccess({ roles: Result }));
    } else {
      yield put(fetchRolesSuccess({ roles: [] }));
    }
  } catch (error) {
    yield put(fetchRolesSuccess({ roles: [] }));
    yield put(failure(error));
  }
}

export function* fetchRoleUsers({ payload = {} }) {
  try {
    const { Result } = yield call(RoleService.getRoleUsers, payload);
    if(Result) {
      const { Data, Pagination } = Result;
      yield put(fetchRoleUsersSuccess({ roleusers: Data, pagination: Pagination }));
    } else {
      yield put(fetchRoleUsersSuccess({ roleusers: [] }));
    }
  } catch (error) {
    yield put(fetchRoleUsersSuccess({ roleusers: [] }));
    yield put(failure(error));
  }
}

export function* saveRoles({ payload }) {
  try {
    let result = null;
    if(payload.Id) {
      result= yield call(RoleService.updateRole, payload);
    } else {
      result= yield call(RoleService.addRole, payload);
    }
    if (result) {
      yield put(RoleFormAction.success("Lưu thành công!"));
    } else {
      const { message } = "Lưu thất bại!";
      const formError = new SubmissionError({
        _error: message
      });
      yield put(RoleFormAction.failure(formError));
    }
  } catch (error) {
    const formError = new SubmissionError({
      _error: error
    });
    yield put(RoleFormAction.failure(formError));
  }
}

export function* saveRoleUser({ payload }) {
  try {
    let { Result } = yield call(RoleService.addRoleUser, {RoleUser: [payload]});
    if (Result) {
      yield put(UserFormAction.success("Lưu thành công!"));
    } else {
      const message = "Lưu thất bại!";
      const formError = new SubmissionError({
        _error: message
      });
      yield put(UserFormAction.failure(formError));
    }
  } catch (error) {
    const message = "Lưu thất bại!";
    const formError = new SubmissionError({
      _error: message
    });
    yield put(UserFormAction.failure(formError));
  }
}


export function* toggleActiveRole({ payload }) {
  const { data, cb } = payload;
  try {
    const { Result } = yield call(RoleService.activeRole, data);
    if(Result) {
      cb('success')
    } else {
      cb('error')
    }
  } catch (error) {
    cb('error')
  }
}

export default [
  takeLatest('FETCH_ROLES_REQUEST', fetchRoles),
  takeLatest('FETCH_ROLE_USERS_REQUEST', fetchRoleUsers),
  takeLatest('TOGGLE_ROLES_REQUEST', toggleActiveRole),
  takeLatest(RoleFormAction.REQUEST, saveRoles),
  takeLatest(UserFormAction.REQUEST, saveRoleUser),
  
];
