import { call, put, takeLatest } from 'redux-saga/effects';
import * as expFuelSurchargeService from '../../services/export-fuel-service';
import moment from 'moment';
import { ExportFuelFormAction } from "./action";
import { SubmissionError } from "redux-form";

import {
  getExportFuelSurchargesSuccess,
} from './reducer';

export function* getListExportFuelSurcharges({ payload = {} }) {
  try {
    const { Result } = yield call(expFuelSurchargeService.getListExportFuelSurcharges, payload);
    if (Result) {
      const { Data, Pagination } = Result
      yield put(getExportFuelSurchargesSuccess({ surcharges: Data, pagination: Pagination }));
    } else {
      yield put(getExportFuelSurchargesSuccess({ surcharges: [], pagination: { Page: 1, Size: 50, Total: 0 } }));
    }
  } catch (error) {
    yield put(getExportFuelSurchargesSuccess({ surcharges: [], pagination: { Page: 1, Size: 50, Total: 0 } }));
  }
}

export function* saveExportFuelSurcharge({ payload }) {
  try {
    if (payload.APPLIED_DATE && moment(payload.APPLIED_DATE).isValid()) {
      payload.APPLIED_DATE = moment(payload.APPLIED_DATE).format('DD-MM-YYYY');
    } else {
      payload.APPLIED_DATE = moment().format('DD-MM-YYYY');
    }
    let result = yield call(expFuelSurchargeService.saveExportFuelSurcharge, payload);
    if (result) {
      yield put(ExportFuelFormAction.success("Lưu thành công!"));
    } else {
      const { message } = "Lưu thất bại!";
      const formError = new SubmissionError({
        _error: message
      });
      yield put(ExportFuelFormAction.failure(formError));
    }
  } catch (error) {
    const formError = new SubmissionError({
      _error: error
    });
    yield put(ExportFuelFormAction.failure(formError));
  }
}

export function* removeExportFuelSurcharge({ payload }) {
  const { data, cb } = payload;
  try {
    const { Result } = yield call(expFuelSurchargeService.removeExportFuelSurcharge, data);
    if (Result) {
      cb('success')
    } else {
      cb('error')
    }
  } catch (error) {
    cb('error')
  }
}

export default [
  takeLatest('GET_LIST_EXPORT_FUEL_SURCHARGES_REQUEST', getListExportFuelSurcharges),
  takeLatest(ExportFuelFormAction.REQUEST, saveExportFuelSurcharge),
  takeLatest('REMOVE_EXPORT_FUEL_SURCHARGES_REQUEST', removeExportFuelSurcharge),
];
