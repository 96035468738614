import '../style.scss';
import React from 'react';
import { Empty } from 'antd';

const Invoices = (props) => {
  const { invoices } = props;
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="box-group">
          <div className="box-header">
            <label className="nav-title bold">Thông tin lập hóa đơn</label>
          </div>
          <div className="box-body">
            {
              invoices && invoices.length
              ?
              invoices.map((invoice, key) => {
                return (
                  <>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-4">
                            <div className='item'>
                              <label className='item-title'>Người đại diện:</label> <span className='item-content'>{invoice.BuyerName}</span>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className='item'>
                              <label className='item-title'>Công ty:</label> <span className='item-content'>{invoice.CompanyName}</span>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className='item'>
                              <label className='item-title'>Email:</label> <span className='item-content'>{invoice.Email}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-4">
                            <div className='item'>
                              <label className='item-title'>Ngân hàng:</label> <span className='item-content'>{invoice.BankWith}</span>
                            </div>
                          </div>
                          <div className="col-md-8">
                            <div className='item'>
                              <label className='item-title'>Địa chỉ:</label> <span className='item-content'>{invoice.Address}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-4">
                            <div className='item'>
                              <label className='item-title'>Số tài khoản:</label> <span className='item-content'>{invoice.BankId}</span>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className='item'>
                              <label className='item-title'>Mã thuế:</label> <span className='item-content'>{invoice.TaxCode}</span>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className='item'>
                              <label className='item-title'>P.Thức T.Toán:</label> <span className='item-content'>{invoice.MethodOfPayment}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-12">
                            <div className='item'>
                              <label className='item-title'>Ghi chú:</label> <span className='item-content'>{invoice.Remark}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {key + 1 < invoices.length ?
                      <hr />
                      :
                      ''}
                  </>
                )
              })
              :
              <Empty description="Chưa có thông tin lập hóa đơn"/>
            }
          </div>
        </div>
      </div>
    </div>
  )
}
export default Invoices
