import { lifecycle, compose as recompose, withHandlers, withState, pure } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import _ from 'lodash';
import { message, notification } from 'antd';
import { manageStationListRequest, exportExcelRequest, deleteManageStationRequest } from '../../reduxs/manageStation-redux/reducer';
import { selectManageStation, selectLoading, selectPagination } from '../../reduxs/manageStation-redux/selectors';
import { selectStations } from '../../reduxs/common-redux/selectors';

import StationV from './StationV';

// Danh sách cột trong bảng quản lý bưu cục
const columns = [
  { name: 'Mã bưu cục', id: 'STATION_CODE', type: 'string', show: true, width: 120 },
  { name: 'Zone code', id: 'ZONE_CODE', type: 'string', show: false, width: 120 },
  { name: 'Tên bưu cục', id: 'NAME', type: 'string', show: true, width: 200 },
  { name: 'Địa chỉ', id: 'ADD1', type: 'string', show: true, width: 250 },
  { name: 'Loại bưu cục', id: 'StationTypeName', type: 'string', show: true, width: 120 },
  { name: 'Mã số thuế', id: 'TAX_CODE', type: 'string', show: false, width: 120 }
];

export function mapDispatchToProps(dispatch) {
  return {
    searchManageStation: (params) => dispatch(manageStationListRequest(params)),
    exportExcel: (params) => dispatch(exportExcelRequest(params)),
    deleteManageStation: (params) => dispatch(deleteManageStationRequest(params)),
  };
}

const mapStateToProps = createStructuredSelector({
  manageStations: selectManageStation(),
  loading: selectLoading(),
  pagination: selectPagination(),
  stations: selectStations()
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default recompose(
  withConnect,
  withState('open', 'close', false),
  withState('manageStation', 'setManageStation', null),
  withState('params', 'setParams', { Offset: 1, Size: 20 }),
  withState('columns', 'setColumns', columns),
  withState('rowKeys', 'setRowKeys', []),
  withState('rowRows', 'setSelectRows', []),
  withHandlers({
    fetchManageStations: ({ params,  setParams, searchManageStation }) => (param) => {
      let _params = { ...params, Offset: 1, ...param };

      if (_params.Offset === undefined) _params.Offset = 1;
      if (_params.Size === undefined) _params.Size = 20;

      _params = _.pickBy(_params, _.identity);
      setParams(_params);
      searchManageStation({ ..._params});
    },
    exportExcel: ({exportExcel}) => () => {
      const params = {
        data: {},
        cb: (type) => {
          if(type === 'success') {
            message.success('Xuất file excel thành công!')
          } else {
            message.error('Xuất file excel bị lỗi!')
          }
        }
      }
      exportExcel(params);
    },
    editManageStation: ({close, setManageStation}) => (manageStation, editStation) => {
      setManageStation(manageStation);
      close(true)
    },
    stateColumn: ({ columns, setColumns }) => (data) => {
      const cols = columns.map((col) => {
        if (col.id === data.id) col.show = !data.show
        return col;
      });
      setColumns(cols);
    }
  }),
  withHandlers({
    deleteManageStation: ({ fetchManageStations, deleteManageStation}) => (manageStation) => {
      if(manageStation && manageStation.STATION_CODE) {
        const data = {
          STATION_CODE: manageStation.STATION_CODE
        }
        const callback = (type) => {
          if(type === 'error') {
            notification[type]({message: 'Xóa thất bại!'});
          } else {
            notification[type]({message: 'Xóa thành công!'});
          }
          fetchManageStations();
        }
        const _params ={
          data: data,
          cb: callback
        }
        deleteManageStation(_params)
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.fetchManageStations();
    }
  }),
  pure,
)(StationV);