import { compose as recompose, withHandlers } from 'recompose'
import { Images } from '../../assets'
import React from 'react'

const adaptFileEventToValue = delegate =>
  e => delegate(e.target.files[0])

class FileInput extends React.PureComponent {
  render () {
    const {
      renderAvatar,
      input: {
        value: omitValue,
        onChange,
        onBlur,
        ...inputProps
      },
      meta: omitMeta,
      ...props
    } = this.props
    return (
      <div>
        <div className='logo-shop'>
          <img alt={`logo-shop-management`} src={renderAvatar(omitValue)} />
        </div>
        <label className='btn btn-upload'>
          <input
            type='file'
            {...inputProps}
            {...props}
            style={{
              fontSize: 9,
              padding: 0,
              height: 'auto',
              width: 70,
              border: 'none',
              marginTop: 10,
              display: 'none'
            }}
            onBlur={adaptFileEventToValue(onBlur)}
            onChange={adaptFileEventToValue(onChange)}
          />
        </label>

      </div>
    )
  }
}

export default recompose(
  withHandlers({
    renderAvatar: (props) => (image) => {
      let src
      if (typeof image === 'string') {
        if (image && image !== '') {
          src = `${image}`
        } else {
          src = Images.common.noAvatar
        }
      } else {
        if (image && window.URL) {
          src = URL.createObjectURL(image)
        }
      }

      return src
    }
  })
)(FileInput)
