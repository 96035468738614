import { createAction, handleActions } from 'redux-actions';
// - Actions

export const fetchCustomersCommissionRequest = createAction('FETCH_CUSTOMERS_COMMISSION_REQUEST');
export const fetchCustomersCommissionSuccess = createAction('FETCH_CUSTOMERS_COMMISSION_SUCCESS');

export const getCustomerCommissionRequest = createAction('GET_CUSTOMER_COMMISSION_REQUEST');
export const getCustomerCommissionSuccess = createAction('GET_CUSTOMER_COMMISSION_SUCCESS');

export const getCustomerPricesRequest = createAction('GET_CUSTOMER_PRICES_REQUEST');
export const getCustomerPricesSuccess = createAction('GET_CUSTOMER_PRICES_SUCCESS');

export const getWaitsPricesRequest = createAction('GET_WAITS_PRICES_REQUEST');
export const getWaitsPricesSuccess = createAction('GET_WAUTS_PRICES_SUCCESS');

export const getHistoryPricesRequest = createAction('GET_HISTORY_PRICES_REQUEST');
export const getHistoryPricesSuccess = createAction('GET_HISTORY_PRICES_SUCCESS');

export const getMyPricesRequest = createAction('GET_MY_COM_PRICES_REQUEST');
export const getMyPricesSuccess = createAction('GET_MY_COM_PRICES_SUCCESS');
export const getPricesCallbackRequest = createAction('GET_PRICES_CALLBACK_REQUEST');

export const activeCommissionRequest = createAction('ACTIVE_COMMISION_REQUEST');
export const deleteCommissionRequest = createAction('DELETE_COMMISION_REQUEST');
export const approveCommissionRequest = createAction('APPROVE_COMMISION_REQUEST');

export const getStaffCommissionRequest = createAction('GET_STAFF_COMMISSION_REQUEST');
export const getStaffCommissionSuccess = createAction('GET_STAFF_COMMISSION_SUCCESS');

export const fetchApproveCommissionRequest = createAction('FETCH_APPROVE_COMMISSION_REQUEST');
export const fetchApproveCommissionSuccess = createAction('FETCH_APPROVE_COMMISSION_SUCCESS');

export const getTemplePricesRequest = createAction('GET_TEMPLE_PICES_REQUEST');
export const getTemplePricesSuccess = createAction('GET_TEMPLE_PICES_SUCCESS');

export const getValueModesRequest = createAction('GET_VALUE_MODE_REQUEST');
export const getValueModesSuccess = createAction('GET_VALUE_MODE_SUCCESS');

export const confirmPriceRequest = createAction('CONFIRM_PRICE_UPLOAD_REQUEST');
export const downloadPriceRequest = createAction('DOWNLOAD_PRICE_TEMPLATE_REQUEST');
export const priviewPriceRequest = createAction('PREVIEW_UPLOAD_PRICE_REQUEST');
export const getDetailPriceRequest = createAction('GET_DETAIL_PRICE_REQUEST');
export const changeAssignRequest = createAction('CHANGE_ASSIGN_PRICE_REQUEST');

export const failure = createAction('CUSTOMER_ACTION_FAILURE');


// - Initial State
export const initialState = {
  pagination: { Page: 1, Size: 50, Total: 0 },
  commissions: [],
  commissionsing: false,

  approvePagination: { Page: 1, Size: 50, Total: 0 },
  approves: [],
  approvesing: false,

  commission: null,
  commissioning: false,

  waits: [],
  waitsing: false,

  valueModes: null,
  valueModesing: false,

  histories: [],
  historiesing: false,

  prices: [],
  pricesing: false,

  myPrices: [],
  myPricesing: false,

  myCommission: null,
  myCommissioning: false,

  temples: [],
  templesing: false,

  defaultAccount: null
};

// - Reducers
const reducer = handleActions({
  [fetchCustomersCommissionRequest]: (state) => {
    return { ...state, commissionsing: true };
  },
  [fetchCustomersCommissionSuccess]: (state, { payload: { commissions, pagination } }) => {
    return { ...state, commissions, pagination, commissionsing: false };
  },

  [getCustomerCommissionRequest]: (state) => {
    return { ...state, commissioning: true };
  },
  [getCustomerCommissionSuccess]: (state, { payload: { commission } }) => {
    return { ...state, commission, commissioning: false };
  },

  [getCustomerPricesRequest]: (state) => {
    return { ...state, pricesing: true };
  },
  [getCustomerPricesSuccess]: (state, { payload: { prices } }) => {
    return { ...state, prices, pricesing: false };
  },

  [getWaitsPricesRequest]: (state) => {
    return { ...state, waitsing: true };
  },
  [getWaitsPricesSuccess]: (state, { payload: { prices } }) => {
    return { ...state, waits: prices, waitsing: false };
  },

  [getHistoryPricesRequest]: (state) => {
    return { ...state, historiesing: true };
  },
  [getHistoryPricesSuccess]: (state, { payload: { prices } }) => {
    return { ...state, histories: prices, historiesing: false };
  },

  [getMyPricesRequest]: (state) => {
    return { ...state, myPrices: [], myPricesing: true };
  },
  [getMyPricesSuccess]: (state, { payload: { prices } }) => {
    return { ...state, myPrices: prices, myPricesing: false };
  },

  [getStaffCommissionRequest]: (state) => {
    return { ...state, myCommission: null, myCommissioning: true };
  },
  [getStaffCommissionSuccess]: (state, { payload: { commission } }) => {
    return { ...state, myCommission: commission, myCommissioning: false };
  },

  [getTemplePricesRequest]: (state) => {
    return { ...state, templesing: true };
  },
  [getTemplePricesSuccess]: (state, { payload: { temples } }) => {
    return { ...state, temples: temples, templesing: false };
  },

  [getValueModesRequest]: (state) => {
    return { ...state, valueModesing: true };
  },
  [getValueModesSuccess]: (state, { payload: { modes } }) => {
    return { ...state, valueModes: modes, valueModesing: false };
  },

  [fetchApproveCommissionRequest]: (state) => {
    return { ...state, approvesing: true };
  },
  [fetchApproveCommissionSuccess]: (state, { payload: { approves, pagination } }) => {
    return { ...state, approves, approvePagination: pagination, approvesing: false };
  },

  [failure]: (state, action) => ({
    ...state,
    error: action.error,
    loading: false,
  }),
}, initialState);

export default reducer;
