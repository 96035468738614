import React, { Component } from 'react';
import { Switch } from 'react-router-dom'
import routes from './routers';
import RouteSubRoutes from './RouteSubRoutes';
import ScrollTop from './ScrollTop';
import CacheBuster from '../components/CacheBuster';
import CheckNewVersion from '../components/CheckNewVersion';
import ImageGallery from '../components/ImageGallery';

class App extends Component {
  render() {
    return (
      <CacheBuster>
        {
          (props) => {
            const { loading } = props;
            return !loading && (
              <div>
                <CheckNewVersion {...props} />
                <Switch>
                    {routes.map((route, i) => <RouteSubRoutes key={i} {...route} />)}
                </Switch>
                <ScrollTop/>
                <ImageGallery/>
              </div>)
          }
        }
      </CacheBuster>
    )
  }
}

export default App
