import { createAction, handleActions } from 'redux-actions';
// - Actions

export const getCustomerDocRequest = createAction('GET_CUSTOMER_DOC_REQUEST');
export const getCustomerDocSuccess = createAction('GET_CUSTOMER_DOC_SUCCESS');
export const uploadCustomerDocRequest = createAction('UPLOAD_CUSTOMER_DOC_REQUEST');
export const removeCustomerDocRequest = createAction('REMOVE_CUSTOMER_DOC_REQUEST');

export const getDocTypeRequest = createAction('GET_CUSTOMER_DOC_TYPE_REQUEST');
export const getDocTypeSuccess = createAction('GET_CUSTOMER_DOC_TYPE_SUCCESS');



// - Initial State
export const initialState = {
  documents: [],
  documentsing: false,

  types: [],
};

// - Reducers
const reducer = handleActions({
  [getCustomerDocRequest]: (state) => {
    return { ...state, documentsing: true };
  },
  [getCustomerDocSuccess]: (state, { payload: { documents } }) => {
    return { ...state, documents: documents, documentsing: false };
  },

  [getDocTypeSuccess]: (state, { payload: { types } }) => {
    return { ...state, types: types };
  }
}, initialState);

export default reducer;
