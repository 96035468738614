import React from 'react';
import moment from 'moment';
import { Modal, Table, Button, Tooltip } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { compose as recompose, withHandlers, withState } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { NumberCommas } from '../../../../../helpers/common';

const ViewPrices = (props) => {
  const { open, close, loading, prices, service } = props;
  let _prices= [];
  if(prices && prices.length) {
    _prices = prices.map(i => i.PriceList)
  }
  return (
    <Modal
      title={<span>Chi tiết bảng giá dịch vụ:&ensp;<span className="bold">{service?.Code} - {service?.Name}</span></span>}
      visible={open}
      style={{ top: 20 }}
      width={'1260px'}
      footer={null}
      maskClosable={false}
      onOk={() =>  close(false) }
      onCancel={() => close(false) }
      className="view-price-service"
    >
      <div className="row">
        <div className="col-md-12">
          <Table
            bordered
            loading={loading}
            className="table-complain"
            columns={reactColums(props)}
            rowKey='Id'
            size="small"
            dataSource={_prices}
            pagination={false}
            scroll={{x: true}}
          />
        </div>
      </div>
    </Modal>
  );
};

const reactColums = ({ onView, service }) => {
  const colums = [
      { title: 'STT', key: 'STT', width: 60, className: "text-center", render: (i,j,k) => k+1 },
      { title: 'Tên dịch vụ', dataIndex: 'Name', key: 'Name' },
      { title: 'VAT', key: 'VAT', render: ({ VAT }) => NumberCommas(VAT, 0) },
      { title: 'Ngày áp dụng', key: 'AppliedDate', render: ({ AppliedDate }) => AppliedDate ? moment(AppliedDate).format('DD-MM-YYYY HH:mm'): ''},
      { title: 'Ngày hết hạn', key: 'ExpriedDate', render: ({ ExpriedDate }) => ExpriedDate ? moment(ExpriedDate).format('DD-MM-YYYY HH:mm'): ''},
      { title: 'Trạng thái', key: 'StatusName', render: (rowData) => {
        if (rowData) {
          const { StatusName, Status } = rowData;
          return StatusName && <span className={Status === 2 ? "text-danger": "text-success"}>{StatusName}</span>
        }
        return ''
      }},
      { title: 'Action', dataIndex: '', fixed: 'right', width: 60, key: 'x', className: "text-center", render: (item) => {
          return (
              <div className="text-center">
                <Tooltip title="Xem chi tiết bảng giá">
                  <Button size="small" icon={<EyeOutlined />} onClick={() => onView({Id: item.PriceListId, ServiceCode: service.Code})}/>
                </Tooltip>
              </div>
          )
      }
    }
  ]
  return colums;
}

export function mapDispatchToProps(dispatch) {
  return {

  };
}

const mapStateToProps = createStructuredSelector({
  prices: (state) => state.service.prices,
  loading: (state) => state.service.pricesing,
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default recompose(
  withConnect,
  withState('show', 'setShow', false),
  withState('record', 'setRecord', null),
  withHandlers({
  }),
)(ViewPrices);
