import { createAction, handleActions } from 'redux-actions';
// - Actions
export const getAccountsRequest = createAction('GET_ACCOUNTS_REQUEST');
export const getAccountsSuccess = createAction('GET_ACCOUNTS_SUCCESS');

export const getCustomersRequest = createAction('GET_CUSTOMERS_REQUEST');
export const getCustomersSuccess = createAction('GET_CUSTOMERS_SUCCESS');

export const getCodesRequest = createAction('GET_CODES_REQUEST');
export const getCodesSuccess = createAction('GET_CODES_SUCCESS');
export const getCodeDetailRequest = createAction('GET_CODE_DETAIL_REQUEST');
export const getCodeDetailSuccess = createAction('GET_CODE_DETAIL_SUCCESS');

export const getEmailRequest = createAction('GET_EMAIL_REQUEST');
export const getEmailSuccess = createAction('GET_EMAIL_SUCCESS');
export const saveEmailRequest = createAction('SAVE_EMAIL_REQUEST');
export const delEmailRequest = createAction('DEL_EMAIL_REQUEST');


export const getStepsRequest = createAction('GET_STEPS_REQUEST');
export const getStepsSuccess = createAction('GET_STEPS_SUCCESS');

export const getAccountTypeRequest = createAction('GET_ACCOUNT_TYPE_REQUEST');
export const getAccountTypeSuccess = createAction('GET_ACCOUNT_TYPE_SUCCESS');

export const confirmAccountRequest = createAction('CONFIRM_ACCOUNT_REQUEST');
export const stepAccountRequest = createAction('STEP_ACCOUNT_REQUEST');

export const changeAccounTypeRequest = createAction('CHANGE_ACCOUNT_TYPE_REQUEST');
export const getZoneByAccounRequest = createAction('GET_ZONE_ACCOUNT_REQUEST');
export const changeValueModeRequest = createAction('CHANGE_VALUE_MODE_REQUEST');

export const getZonesRequest = createAction('GET_ZONES_REQUEST');
export const getZonesSuccess = createAction('GET_ZONES_SUCCESS');

export const getAccountInfoRequest = createAction("GET_USER_ACCOUNT_REQUEST");
export const getAccountInfoSuccess = createAction("GET_USER_ACCOUNT_SUCCESS");

export const getInvoicesRequest = createAction('GET_INVOICES_REQUEST');
export const getInvoicesSuccess = createAction('GET_INVOICES_SUCCESS');

export const getWarehousesRequest = createAction('GET_WAREHOUSES_REQUEST');
export const getWarehousesSuccess = createAction('GET_WAREHOUSES_SUCCESS');

export const getBanksRequest = createAction('GET_BANKS_REQUEST');
export const getBanksSuccess = createAction('GET_BANKS_SUCCESS');

export const createUserByIdRequest = createAction('CREATE_USER_BY_ID_REQUEST');
export const createUserByIdSuccess = createAction('CREATE_USER_BY_ID_SUCCESS');

export const resetPasswordToDefaultRequest = createAction('RESET_PASSWORD_TO_DEFAULT_REQUEST');
export const resetPasswordToDefaultSuccess = createAction('RESET_PASSWORD_TO_DEFAULT_SUCCESS');

export const getSchedulesRequest = createAction('GET_SCHEDULES_REQUEST');
export const getSchedulesSuccess = createAction('GET_SCHEDULES_SUCCESS');
export const typeScheduleRequest = createAction('TYPE_SCHEDULE_REQUEST');
export const typeScheduleSuccess = createAction('TYPE_SCHEDULE_SUCCESS');
export const saveScheduleRequest = createAction('SAVE_SCHEDULE_REQUEST');

export const getAllowedVerifyStepsRequest = createAction('GET_ALLOWED_VERIFY_STEPS_REQUEST');
export const getAllowedVerifyStepsSuccess = createAction('GET_ALLOWED_VERIFY_STEPS_SUCCESS');

export const changeAccountCodeRequest = createAction('CHANGE_ACCOUNT_CODE_REQUEST');
export const removeParentCodeRequest = createAction('REMOVE_PARENT_CODE_REQUEST');
export const removeAccountCodeRequest = createAction('REMOVE_ACCOUNT_CODE_REQUEST');

export const downloadAccountWaitConfirmRequest = createAction('DOWNLOAD_ACCOUNT_WAIT_TO_CONFIRM_REQUEST');
export const downloadAccountWaitConfirmSuccess = createAction('DOWNLOAD_ACCOUNT_WAIT_TO_CONFIRM_SUCCESS');

export const getZoningHistoryRequest = createAction('GET_ZONING_HISTORY_REQUEST');
export const getZoningHistorySuccess = createAction('GET_ZONING_HISTORY_SUCCESS');
export const changeAccountStationRequest = createAction('CHANGE_ACCOUNT_STATION_REQUEST');
export const changeProfileStatusRequest = createAction('CHANGE_PROFILE_STATUS_REQUEST');

export const updateCustomerByPropRequest = createAction('UPDATE_CUSTOMER_BY_PROP_REQUEST')
export const createAccountNumberRequest = createAction('CREATE_ACCOUNT_NUMBER_REQUEST')
export const addCustomerToNewStationRequest = createAction('ADD_CUSTOMER_TO_NEW_STATION_REQUEST')

export const failure = createAction('REPORT_ACTION_FAILURE');

// - Initial State
export const initialState = {
  accounts: [],
  accountsing: false,
  customers: [],
  customersing: false,
  pagination: {
    Page: 1,
    Size: 50,
    Total: 0
  },
  steps: [],
  stepsing: false,

  types: [],
  typesing: false,

  zones: [],
  zoning: false,

  user: null,
  usersing: false,

  invoices: [],
  invoicesing: false,

  warehouses: [],
  warehousesing: false,

  banks: [],
  banksing: false,

  emails: [],
  emailsing: false,

  codes: [],
  codesing: false,

  codedetails: [],
  codedetailsing: false,

  error: null,

  creatingUser: false,
  createUserSuccessed: false,

  schedules: null,
  schedulesing: false,
  scheduletypes: [],

  allowedVerifySteps: [],
  fetchingAllowedVerifyStep: false,

  zoningHistories: [],
  fetchingZoningHistory: false,

  downloadingAWC: false,
  resetPwding: false
};

// - Reducers
const reducer = handleActions({
  [getAccountsRequest]: (state) => {
    return { ...state, accountsing: true };
  },
  [getAccountsSuccess]: (state, { payload: { accounts, pagination } }) => {
    return { ...state, accounts, pagination, accountsing: false };
  },

  [getCustomersRequest]: (state) => {
    return { ...state, customersing: true };
  },
  [getCustomersSuccess]: (state, { payload: { customers, pagination } }) => {
    return { ...state, customers, pagination, customersing: false };
  },

  [getCodesRequest]: (state) => {
    return { ...state, codesing: true };
  },
  [getCodesSuccess]: (state, { payload: { codes, pagination } }) => {
    return { ...state, codes, pagination, codesing: false };
  },

  [getEmailRequest]: (state) => {
    return { ...state, emails: [], emailsing: true };
  },
  [getEmailSuccess]: (state, { payload: { emails } }) => {
    return { ...state, emails, emailsing: false };
  },

  [getCodeDetailRequest]: (state) => {
    return { ...state, codedetailsing: true };
  },
  [getCodeDetailSuccess]: (state, { payload: { codedetails, pagination } }) => {
    return { ...state, codedetails, pagination, codedetailsing: false };
  },

  [getStepsRequest]: (state) => {
    return { ...state, stepsing: true };
  },
  [getStepsSuccess]: (state, { payload: { steps } }) => {
    return { ...state, steps, stepsing: false };
  },

  [getAccountTypeRequest]: (state) => {
    return { ...state, typesing: true };
  },
  [getAccountTypeSuccess]: (state, { payload: { types } }) => {
    return { ...state, types, typesing: false };
  },

  [getAllowedVerifyStepsRequest]: (state) => {
    return { ...state, fetchingAllowedVerifyStep: true };
  },
  [getAllowedVerifyStepsSuccess]: (state, { payload: { steps } }) => {
    return { ...state, allowedVerifySteps: steps, fetchingAllowedVerifyStep: false };
  },

  [getZonesRequest]: (state) => {
    return { ...state, zoning: true };
  },
  [getZonesSuccess]: (state, { payload: { zones } }) => {
    return { ...state, zones: zones, zoning: false };
  },

  [getAccountInfoRequest]: (state) => {
    return { ...state, usering: true };
  },
  [getAccountInfoSuccess]: (state, { payload: { user } }) => {
    return { ...state, user: user, usering: false };
  },

  [getInvoicesRequest]: (state) => {
    return { ...state, invoicesing: true };
  },
  [getInvoicesSuccess]: (state, { payload: { invoices } }) => {
    return { ...state, invoices: invoices, invoicesing: false };
  },

  [getWarehousesRequest]: (state) => {
    return { ...state, warehousesing: true };
  },
  [getWarehousesSuccess]: (state, { payload: { warehouses } }) => {
    return { ...state, warehouses: warehouses, warehousesing: false };
  },

  [getBanksRequest]: (state) => {
    return { ...state, banksing: true };
  },
  [getBanksSuccess]: (state, { payload: { banks } }) => {
    return { ...state, banks: banks, banksing: false };
  },

  [createUserByIdRequest]: (state) => {
    return { ...state, creatingUser: true, createUserSuccessed: false };
  },
  [createUserByIdSuccess]: (state, { payload: { success } }) => {
    return { ...state, creatingUser: false, createUserSuccessed: success };
  },

  [getSchedulesRequest]: (state) => {
    return { ...state, schedulesing: true };
  },
  [getSchedulesSuccess]: (state, { payload: { schedules } }) => {
    return { ...state, schedules: schedules || null, schedulesing: false };
  },
  [getSchedulesRequest]: (state) => {
    return { ...state, schedulesing: true };
  },
  [typeScheduleSuccess]: (state, { payload: { scheduletypes } }) => {
    return { ...state, scheduletypes: scheduletypes || [], schedulesing: false };
  },

  [downloadAccountWaitConfirmRequest]: (state) => {
    return { ...state, downloadingAWC: true }
  },
  [downloadAccountWaitConfirmSuccess]: (state) => {
    return { ...state, downloadingAWC: false }
  },
  [getZoningHistoryRequest]: (state) => ({ ...state, fetchingZoningHistory: true }),
  [getZoningHistorySuccess]: (state, { payload: { histories } }) => (
    { ...state, zoningHistories: histories, fetchingZoningHistory: false }
  ),
  [resetPasswordToDefaultRequest]: (state) => ({ ...state, resetPwding: true }),
  [resetPasswordToDefaultSuccess]: (state) => ({ ...state, resetPwding: false }),

  [failure]: (state, action) => ({
    ...state,
    error: action.error,
    accountsing: false,
  }),
}, initialState);

export default reducer;
