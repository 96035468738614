
import axios from '../utils/axiosInstance';

export const getMyMenu = async () => {
    try {
        const { data } = await axios.get('/api/menu/mine');
        return data;
    } catch (error) {
        throw error;
    }
};