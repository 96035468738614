
import axios from '../utils/axiosInstance';

export const getPolicies = async (params) => {
  try {
    const { data } = await axios.get(`/policy/update-policy`, {
      params: params
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const addPolicy = async (policy) => {
  try {
    const { data } = await axios.post(`/policy`, policy);
    return data;
  } catch (error) {
    throw error;
  }
};

export const deletePolicy = async (policy) => {
  try {
    const { data } = await axios.delete(`/policy`, { params: policy });
    return data;
  } catch (error) {
    throw error;
  }
};




