import localStorage from 'localStorage'

class Auth {
  /**
   * Authenticate a user. Save a token string in Local Storage
   *
   * @param {string} token
   */
  static authenticateUser (token) {
    localStorage.setItem('token', token)
  }

  /**
   * Check if a user is authenticated - check if a token is saved in Local Storage
   *
   * @returns {boolean}
   */
  static isUserAuthenticated () {
    return (localStorage.getItem('token') != null)
  }

  /**
   * Deauthenticate a user. Remove a token from Local Storage.
   *
   */
  static deauthenticateUser () {
    localStorage.removeItem('token')
    localStorage.removeItem('userInfo')
    localStorage.removeItem('store')
  }

  /**
   * Get a token value.
   *
   * @returns {string}
   */

  static getToken () {
    return localStorage.getItem('token')
  }

  /**
   * Set userinfo. Save userinfo in Local Storage
   *
   * @param {string} user
   */
  static setUserInfo (user) {
    localStorage.setItem('userInfo', user)
  }

  /**
   * Get userinfo.
   *
   * @returns {string}
   */

  static getUserInfo () {
    return localStorage.getItem('userInfo')
  }

  /**
   *Set station.
   *
   * @returns {string}
   */

  static setStation (station) {
    localStorage.setItem('station', station);
  }

  /**
   * Get station.
   *
   * @returns {string}
   */

  static getStation () {
    return localStorage.getItem('station');
  }
}

export default Auth
