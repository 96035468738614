
import React from 'react';
import { Empty, Modal, Spin, Timeline } from 'antd';
import { compose as recompose, withHandlers, lifecycle, pure } from 'recompose';
import { ClockCircleOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';

import { getZoningHistoryRequest } from '../../../reduxs/account-redux/reducer';

const ZoneHistory = (props) => {
    const { onClose, fetching, histories } = props;
    return (
        <Modal
            title="Lịch sử phân vùng khách hàng"
            visible={true}
            style={{ top: 20 }}
            width={'640px'}
            footer={null}
            maskClosable={false}
            onOk={onClose}
            onCancel={onClose}
            className="modal-zone-history"
        >
            {fetching && <div style={{ textAlign: 'center' }}>
                <Spin />
            </div>}
            {!fetching && histories.length > 0 && (
                <Timeline mode="alternate">
                    {
                        histories.map((data, i) =>
                        (<Timeline.Item 
                            key={i} 
                            label={
                            <span>
                                Bắt đầu: { moment(data.StartDate).format('DD/MM/YYYY') }<br/>
                                Kết thúc: {moment(data.EndDate).format('DD/MM/YYYY')}<br/>
                                Ngày tạo: {moment(data.CreatedDate).format('DD/MM/YYYY')}<br/>
                                Mã NV tạo: {data.CreatedBy}<br/>
                                Cập nhật cuối: {moment(data.UpdatedDate).format('DD/MM/YYYY')}<br/>
                                Mã NV cập nhật: {data.UpdatedBy}
                            </span>} 
                            dot={<ClockCircleOutlined style={{ fontSize: '16px' }} />}
                            color={data.IsInEffect ? 'green' : 'red'}>
                        Bán hàng: <span>{data.SalesStaffCode} - {data.SalesStaffName}</span><br />
                        CSKH: <span>{data.CustomerCareStaffCode} - {data.CustomerCareStaffName}</span><br />
                        Quản lý vùng: <span>{data.ZoneManagerStaffCode} - {data.ZoneManagerStaffName}</span><br />
                        Vận hành: <span>{data.OpStaffCode} - {data.OpName}</span><br />
                        Giao nhận: <span>{data.PuStaffCode} - {data.PuName}</span><br />
                        Kế toán: <span>{data.AccStaffCode} - {data.AccName}</span><br />
                        </Timeline.Item>))
                    }
                </Timeline>
            )}
            {!fetching && histories.length === 0 && (
                <Empty />
            )
            }
        </Modal>
    )
}

function mapDispatchToProps(dispatch) {
    return {
        getZoningHistory: (params = {}) => dispatch(getZoningHistoryRequest(params))
    };
}
const mapStateToProps = createStructuredSelector({
    fetching: store => store.account && store.account.fetchingZoningHistory,
    histories: store => store.account && store.account.zoningHistories
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default recompose(
    withConnect,
    withHandlers({

    }),
    lifecycle({
        componentDidMount() {
            const { account: { Id }, getZoningHistory } = this.props;
            getZoningHistory({ CustomerId: Id });
        }
    }),
    pure
)(ZoneHistory);
