import './style.scss';
import React from 'react';
import moment from 'moment';
import { Layout, Table, Select, Pagination, Input, Button, Collapse } from 'antd';
import RestrictPage from './../../components/RestrictComponents/RestrictPage';
import { Icons } from '../../assets';
import 'pretty-print-json/dist/pretty-print-json.css'
import prettyPrintJson from 'pretty-print-json';
import { CaretRightOutlined, FilterOutlined, CaretDownOutlined } from '@ant-design/icons';

const { Content } = Layout;
const { Panel } = Collapse;
const { Option } = Select;

const SqlLogV = (props) => {
    const { isMobile, fetching, logs } = props;
    const { pagination, params, onFetchData, onChangeParams } = props;

    const size = params.Size && Number(params.Size) > 0 ? Number(params.Size) : 50;
    const offset = params.Offset && Number(params.Offset) > 0 ? Number(params.Offset) : 1;
    const total = pagination && pagination.Total && Number(pagination.Total) > 0 ? Number(pagination.Total) : 0;
    const n_start = total > 0 ? (((offset - 1) * size) + 1) : 0;
    const n_end = total > 0 ? ((offset * size) > total ? total : offset * size) : 0;
    return (
        <RestrictPage>
            <Content className="page-error-logs">
                <div className="panel-header">
                    <div className="header-title">
                        <i className="fa fa-home icon" aria-hidden="true"></i>
                        <span>Hệ thống</span>
                        <span className="icon-next"><i className="fa fa-angle-right"></i></span>
                        <span className="active">Logs</span>
                    </div>
                </div>
                <div className={isMobile ? "col-md-12 pd-0" : "col-md-12"}>
                    <div className="panel-body">
                        <div className="row">
                            <div className="col-md-12">
                                <Collapse className="filter-container" ghost expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}>
                                    <Panel header={<span className="bold">FILTER</span>} key="1">
                                        <div className="row">
                                            <div className="col-lg-3 col-sm-6 col-xs-12" style={{ marginBottom: 15 }}>
                                                <label className="bold">Level</label>
                                                <Select defaultValue={params.Level || ''} onChange={(value) => onChangeParams({ Level: value })} style={{ width: '100%' }}>
                                                    <Option value="">All</Option>
                                                    <Option value="Verbose">Verbose</Option>
                                                    <Option value="Debug">Debug</Option>
                                                    <Option value="Information">Information</Option>
                                                    <Option value="Warning">Warning</Option>
                                                    <Option value="Error">Error</Option>
                                                    <Option value="Fatal">Fatal</Option>
                                                </Select>
                                            </div>
                                            <div className="col-lg-3 col-sm-6 col-xs-12" style={{ marginBottom: 15 }}>
                                                <label className="bold">Environment</label>
                                                <Select defaultValue={params.Environment || ''} onChange={(value) => onChangeParams({ Environment: value })} style={{ width: '100%' }}>
                                                    <Option value="">All</Option>
                                                    <Option value="Production">API:Production</Option>
                                                    <Option value="Op">API:Op</Option>
                                                    <Option value="Crm">API:Crm</Option>
                                                    <Option value="Development">API:Development</Option>
                                                    <Option value="GenCode">Code generation service</Option>
                                                    <Option value="Identity">Identity</Option>
                                                </Select>
                                            </div>
                                            <div className="col-lg-3 col-sm-6 col-xs-12" style={{ marginBottom: 15 }}>
                                                <label className="bold">Request path</label>
                                                <Input defaultValue={params.RequestPath || ''} placeholder="Request path" onChange={(e) => onChangeParams({ RequestPath: e.target.value })} style={{ width: '100%' }} />
                                            </div>
                                            <div className="col-lg-3 col-sm-6 col-xs-12" style={{ marginBottom: 15 }}>
                                                <label className="bold">Text in message</label>
                                                <Input defaultValue={params.Query || ''} placeholder="Request path" onChange={(e) => onChangeParams({ Query: e.target.value })} style={{ width: '100%' }} />
                                            </div>
                                            <div className="col-lg-3 col-sm-6 col-xs-12" style={{ marginBottom: 15 }}>
                                                <label className="bold">Start Time</label>
                                                <Input type="datetime-local" defaultValue={params.StartTime || ''} placeholder="Start time" onChange={(e) => onChangeParams({ StartTime: e.target.value })} style={{ width: '100%' }} />
                                            </div>
                                            <div className="col-lg-3 col-sm-6 col-xs-12" style={{ marginBottom: 15 }}>
                                                <label className="bold">End Time</label>
                                                <Input type="datetime-local" defaultValue={params.EndTime || ''} placeholder="Start time" onChange={(e) => onChangeParams({ EndTime: e.target.value })} style={{ width: '100%' }} />
                                            </div>
                                        </div>
                                        <div className="row" style={{ marginBottom: 15 }}>
                                            <div className="col-lg-12">
                                                <Button loading={fetching} icon={<FilterOutlined />} onClick={() => onFetchData({})} >Filter</Button>
                                            </div>
                                        </div>
                                    </Panel>
                                </Collapse>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <label className="total-filter">
                                    <img src={Icons.common.filter} alt="Số request" />|&ensp;<span>{n_start} - {n_end}</span> trong <span>{total}</span>
                                </label>
                            </div>
                            <div className="col-md-6 text-right">
                                <Pagination current={offset || 1} pageSize={size || 20} total={total || 0} showSizeChanger={false} onChange={(offset) => onFetchData({ Offset: offset })} />
                            </div>
                        </div>
                        <div className="row mr-t-10">
                            <div className="col-md-12">
                                <Table
                                    loading={fetching}
                                    className="table-request-histories"
                                    columns={createColumns(props)}
                                    rowKey="Id"
                                    size="small"
                                    dataSource={logs || []}
                                    pagination={false}
                                    bordered={true}
                                    expandable={{
                                        expandRowByClick: true,
                                        expandedRowRender: (record) => {
                                            return (
                                                <div className="table-view-children">
                                                    <Table
                                                        key="tab1"
                                                        className="table-chidren small"
                                                        columns={createChildTableColumns()}
                                                        rowKey='Id'
                                                        size="small"
                                                        dataSource={[{ MessageTemplate: record.MessageTemplate, Properties: record.Properties, Exception: record.Exception }]}
                                                        pagination={false}
                                                        bordered={true}
                                                    />
                                                </div>
                                            )
                                        },
                                        expandIcon: ({ expanded, onExpand, record }) =>
                                            expanded ? (
                                                <CaretDownOutlined onClick={e => onExpand(record, e)} />
                                            ) : (
                                                <CaretRightOutlined onClick={e => onExpand(record, e)} />
                                            )
                                    }}
                                    scroll={{ y: 'calc(100vh - 200px)' }}
                                />
                            </div>
                        </div>

                        <div className="row mr-t-10">
                            <div className="col-md-6">
                                <span>Hiển thị &ensp;
                                    <Select value={size || 20} style={{ width: 120 }} onChange={(size) => onFetchData({ Size: size, Offset: 1 })}>
                                        <Select.Option value="50">50</Select.Option>
                                        <Select.Option value="100">100</Select.Option>
                                        <Select.Option value="150">150</Select.Option>
                                        <Select.Option value="200">200</Select.Option>
                                    </Select>
                                    &nbsp; bản ghi / trang
                                </span>
                            </div>
                            <div className="col-md-6 text-right">
                                <Pagination current={offset || 1} pageSize={size || 20} total={total || 0} showSizeChanger={false} onChange={(offset) => onFetchData({ Offset: offset })} />
                            </div>
                        </div>
                    </div>
                </div>
            </Content>
        </RestrictPage>
    );
};

export default SqlLogV

const createColumns = (props) => {
    const colums = [
        {
            title: 'Level',
            dataIndex: null,
            key: 'Id',
            width: 140,
            render: ({ Level }) => {
                return (
                    <div><span className={`tag tag-${Level.toLowerCase()}`}>{Level}</span></div>
                )
            }
        },
        {
            title: 'Time', dataIndex: null, key: 'UtcTimeStamp', width: 160, render: ({ UtcTimeStamp }) => {
                return moment(UtcTimeStamp).format('YYYY-MM-DD HH:mm:ss')
            }
        },
        {
            title: 'Message', dataIndex: null, key: 'Id', render: ({ RenderedMessage }) => {
                return <output dangerouslySetInnerHTML={{ __html: RenderedMessage.replace(/\\"/g, '"').replace(/\n/g, '<br/>') }} />
            },

        }
    ]
    return colums;
}

const createChildTableColumns = () => {
    const colums = [
        {
            title: 'Properties', dataIndex: null, key: 'Id', className: 'vertical-align-top mw-480', render: ({ Properties }) => {
                return <pre><output dangerouslySetInnerHTML={{ __html: beautiPayload(Properties) }} /></pre>
            }
        },
        {
            title: 'Exception', dataIndex: null, key: 'Id', className: 'vertical-align-top', render: ({ Exception }) => {
                return <pre><output dangerouslySetInnerHTML={{ __html: beautiPayload(Exception) }} /></pre>
            }
        }
    ]
    return colums;
}

const beautiPayload = (_obj) => {
    try {
        return prettyPrintJson.toHtml(_obj);
    } catch (error) {
        return _obj;
    }
}