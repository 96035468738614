import { createFormAction } from "redux-form-saga";
export const MENU_FORM_KEY = "MENU_FORM_KEY";
export const MenuFormAction = createFormAction(MENU_FORM_KEY);

export const GROUP_FORM_KEY = "GROUP_FORM_KEY";
export const GroupFormAction = createFormAction(GROUP_FORM_KEY);

export const GROUP_MENU_FORM_KEY = "GROUP_MENU_FORM_KEY";
export const GroupMenuFormAction = createFormAction(GROUP_MENU_FORM_KEY);

export const USER_MENU_FORM_KEY = "USER_MENU_FORM_KEY";
export const UserMenuFormAction = createFormAction(USER_MENU_FORM_KEY);
