
// import './index.css';
// import './App.less';
import * as serviceWorker from './serviceWorker';
import React from 'react'
import ReactDOM from 'react-dom'
import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux'
import App from './app'
import './styles/styles.less'
import './styles/styles.scss'

import store from './reduxs/store';
import history from './utils/history';
import { OidcProvider } from 'redux-oidc';
import userManager from './utils/userManager';

import { ConfigProvider } from 'antd';
import viVN from 'antd/es/locale/vi_VN';
import moment from 'moment';
import 'moment/locale/vi';
import { PcsProvider } from './notif-msg-modules/src/modules';

moment.locale('vi-VN');

ReactDOM.render(
  <Provider store={store}>
    <OidcProvider store={store} userManager={userManager}>
      <PcsProvider store={store} top={50} env={(env => {
        switch (env) {
          case 'production': {
            return 'prod'
          }
          default: {
            return 'uat'
          }
        }
      })(process.env.REACT_APP_ENV)}>
        <ConnectedRouter history={history}>
          <ConfigProvider locale={viVN}>
            <App />
          </ConfigProvider>
        </ConnectedRouter>
      </PcsProvider>
    </OidcProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
