import './style.scss';
import React from 'react';
import { Tooltip, Button, Table } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { Icons } from '../../../../assets';
import ViewService from './prices/ViewService';
import ViewPrices from './prices/ViewPrices';

const PriceListV = (props) => {
    const { services, loading, service, serviceCode, open, setOpen, price, view, setView, onView } = props;

    let _services = [];
    if(services && services.length) {
      _services = services.map((i, key) => ({
        STT: key+1,
        Code: i.Code,
        Name: i.Description,
      }))
    }


    return (
      <div className="w-100">
        <div className="col-md-12">
          <div className="row">
              <div className="col-md-6 pd-0">
                  <label className="total-filter bold">
                      <img src={Icons.common.filter} alt="Dịch vụ" />&ensp;|&ensp;Tổng <span>{services && services.length}</span> dịch vụ
                  </label>
              </div>
          </div>
          <div className="row mr-t-10">
            <Table
              loading={loading}
              className="table-main"
              columns={reactColums(props)}
              rowKey='Code'
              size="small"
              dataSource={ _services || []}
              pagination={false}
              bordered
              scroll={{x: true}}
            />
          </div>
        </div>
        {view && service && <ViewPrices open={view} close={setView} price={price} service={serviceCode}/>}
        <ViewService open={open} close={setOpen} service={service} onView={onView}/>
      </div>
      );
};

export default PriceListV

const reactColums = ({onViewPrice}) => {
  const colums = [
      { title: 'STT', dataIndex: 'STT', key: 'STT', width: 60, className: "text-center" },
      { title: 'Mã dịch vụ', dataIndex: 'Code', key: 'Code', width: 180, },
      { title: 'Tên dịch vụ', dataIndex: 'Name', key: 'Name', },
      { title: 'Trạng thái', key: 'Label', render: ({Label}) => <small className="text-success">Đang áp dụng</small> },
      { title: 'Action', dataIndex: '', fixed: 'right', width: 90, key: 'x', className: "text-center", render: (item) => {
          return (
              <div className="text-center">
                  <Tooltip title="Xem chi tiêt các bảng giá">
                    <Button size="small" icon={<EyeOutlined />} onClick={() => onViewPrice(item)}>Chi tiêt</Button>
                  </Tooltip>
              </div>
          )
      }
    }
  ]
  return colums;
}
