import { createAction, handleActions } from 'redux-actions';
// - Actions

export const assetAdjustmentsRequest = createAction('ASSET_ADJUSTMENT_LIST_REQUEST');
export const assetAdjustmentsSuccess = createAction('ASSET_ADJUSTMENT_LIST_SUCCESS');
export const deleteAdjustmentRequest = createAction('ASSET_DEL_ADJUSTMENT_REQUEST');

export const getAssetAdjustmentRequest = createAction('ASSET_ADJUSTMENT_REQUEST');
export const getAssetAdjustmentSuccess = createAction('ASSET_ADJUSTMENT_SUCCESS');

export const adjustmentConfirmRequest = createAction('ASSET_ADJUSTMENT_CONFIRM_REQUEST');
export const adjustmentPrintRequest = createAction('ASSET_ADJUSTMENT_PRINT_REQUEST');

export const failure = createAction('ASSET_ACTION_FAILURE');

// - Initial State
export const initialState = {
  adjustments: [],
  adjustmentsing: false,
  pagination: {
    Page: 1,
    Size: 50,
    Total: 0
  },

  adjustment: null,
  adjustmenting: false,

  error: null,
};

// - Reducers
const reducer = handleActions({
  
  [assetAdjustmentsRequest]: (state) => ({
    ...state, adjustmentsing: true,
  }),
  [assetAdjustmentsSuccess]: (state, { payload: { adjustments, pagination } }) => ({
    ...state, adjustments, pagination, adjustmentsing: false,
  }),

  [getAssetAdjustmentRequest]: (state) => ({
    ...state, adjustmenting: true,
  }),
  [getAssetAdjustmentSuccess]: (state, { payload: { adjustment } }) => ({
    ...state, adjustment, adjustmenting: false,
  }),

  [failure]: (state, action) => ({
    ...state,
    error: action.error,
    adjustmentsing: false,
  }),
}, initialState);

export default reducer;
