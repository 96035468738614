import { createSelector } from 'reselect';
import { initialState } from './reducer';
const statePartner = (state) => state.partner || initialState;

const selectPartners = () => createSelector(
  statePartner,
  ({ partners }) => partners
);
const selectPagination = () => createSelector(
  statePartner,
  ({ pagination }) => pagination
);
const selectLoading = () => createSelector(
  statePartner,
  ({ loading }) => loading
);

const selectCommitments = () => createSelector(
  statePartner,
  ({ commitments }) => commitments
);

export {
  statePartner,
  selectPartners,
  selectPagination,
  selectLoading,
  selectCommitments,
};
