import React from "react";
import { Form, Select, Avatar } from "antd";

const FormItem = Form.Item;
const { Option } = Select;


const makeField = () => ({ input, meta, children, hasFeedback, options, mode, label, size, className, valueDefault, setFocus, placeholder, ...rest }) => {
  const hasError = meta && meta.touched && meta.invalid;
  if(mode && !input.value) input.value = [];
  if(!mode && !input.value) input.value = valueDefault;
  return (
    <FormItem
      label={label}
      validateStatus={hasError ? "error" : "success"}
      hasFeedback={hasFeedback && hasError}
      help={hasError && meta.error}
      className={className || ''}
    >
      <Select {...rest} mode={mode ? mode : ''} value={input.value} onChange={input.onChange} onFocus={input.onFocus} children={children} size={size ? size : 'default'} placeholder={placeholder || ''} className={className || ''} ref={input => setFocus ? setFocus(input) : null}>
        { options && options.map((option, key) => {
          return(
            <Option key={`${key}.${option.value}`} value={option.value || ''} search={option.search || option.value} disabled={option.disabled || false}>
              { option.icon && <span><Avatar icon="user" size={25} />&ensp;</span> }
              { option.code && <><span className="alert-danger">{option.code}</span> - </>}
              { option.text }
            </Option>)
          })
        }
      </Select>
    </FormItem>
  );
};


export default makeField();