import { createAction, handleActions } from 'redux-actions';

// - Actions
export const getWebhookHistoriesRequest = createAction('GET_WEBHOOK_HISTORIES_REQUEST');
export const getWebhookHistoriesSuccess = createAction('GET_WEBHOOK_HISTORIES_SUCCESS');

export const failure = createAction('COMMON_WEBHOOK_ACTION_FAILURE');

// - Initial State
export const initialState = {
    fetching: false,
    pagination: {
        Page: 1,
        Size: 50,
        Total: 0
    },
    histories: [],

    error: null
};

// - Reducers
const reducer = handleActions({
    [getWebhookHistoriesRequest]: (state) => {
      return { ...state, fetching: true };
    },
    [getWebhookHistoriesSuccess]: (state,  { payload: { histories, pagination } }) => {
      return { ...state, histories: histories, pagination: pagination, fetching: false };
    },
    [failure]: (state, action) => ({
        ...state,
        error: action.error,
        fetching: false
    }),
}, initialState);
  
export default reducer;
  