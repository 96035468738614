import React, { useState } from 'react'
import { Modal, Button, Input, message, Tooltip } from 'antd'
import { useDispatch } from 'react-redux'
import { addEmailSubscriptionRequest } from '../../../../reduxs/email-subscription-redux/reducer'
import { isNil } from 'lodash'
import { checkEmail } from '../../../../helpers/validate'
import './style.scss'

export default ({ type, onCancel, onSuccess, visible }) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState(null)

    const onClick = () => {
        setLoading(true)
        dispatch(addEmailSubscriptionRequest({
            params: {
                TypeId: type.Id,
                Email: email
            },
            cb: (isOk) => {
                setLoading(false)
                if (!isOk) {
                    message.error('Đã xảy ra lỗi')
                } else {
                    setEmail('')
                    onSuccess && onSuccess()
                }
            }
        }))
    }

    return (
        <>
            <Modal visible={visible} title="Thêm email" onCancel={onCancel} footer={null}>
                <div>
                    <label className='bold'>Loại: {type?.Name}</label>
                </div>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div>
                            <label className='bold'>Email</label>
                        </div>
                        <div>
                            <Input value={email} onChange={e => setEmail(e.target.value)} />
                        </div>
                    </div>
                </div>

                <div className='row' style={{ marginTop: 15 }}>
                    <div className='col-lg-12'>
                        <Button onClick={onCancel}>Huỷ</Button>
                        <Tooltip title={email && !isNil(checkEmail(email)) ? 'Email không hợp lệ' : null}>
                            <Button loading={loading} disabled={!email || !type || !isNil(checkEmail(email))} type='primary' style={{ marginLeft: 4 }} onClick={onClick}>Thêm</Button>
                        </Tooltip>
                    </div>
                </div>
            </Modal>
        </>
    )
}