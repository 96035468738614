import './style.scss'
import React from 'react';
import { Link } from 'react-router-dom';
import { Layout, Spin, Select, Popover, Checkbox, Pagination, Table, Button, Tooltip, Popconfirm } from 'antd';
import { AppstoreAddOutlined, DeleteOutlined, EditOutlined, ReloadOutlined, SettingOutlined } from '@ant-design/icons';
import { Icons } from '../../assets'
import ExportFuelForm from './components/ExportFuelForm';
import { NumberCommas } from '../../helpers/common';

const { Content } = Layout;

const ExportFuelV = (props) => {
    const { pagination, stations, customers, search, customersing, params, surcharges, loading, open, fuel } = props;
    const { searchCustomer, fetchFuelSurcharges, setOpen, setFuel } = props;
    const size = params.Size && Number(params.Size) > 0 ? Number(params.Size) : 20;
    const offset = params.Offset && Number(params.Offset) > 0 ? Number(params.Offset) : 1;
    const total = pagination && pagination.Total && Number(pagination.Total) > 0 ? Number(pagination.Total) : 1;
    const n_start = total > 0 ? (offset - 1) * size + 1 : 0;
    const n_end = total > 0 ? (offset * size > total ? total : offset * size) : 0;

    let _customers = [];
    if (customers && customers.length) {
        _customers = customers.map((item) => {
            return {
                id: item.Value.AccountNr,
                value: item.Value.AccountNr,
                label: `${item.Value?.AccountNr} - ${item.Value?.ShortName || item.Value?.FullName}`
            }
        })
    }


    let _stations = [];
    if (stations && stations.length) {
        _stations = stations.map((item) => {
            return {
                value: item.STATION_CODE,
                label: `${item.STATION_CODE} - ${item.NAME}`
            }
        })
    }
    _stations.unshift({ value: 'ALL', label: 'Tất cả các bưu cục' })

    if (surcharges && surcharges.length) {
        surcharges.map(((zone, key) => {
            zone['STT'] = key + 1;
            return zone;
        }))
    }

    return (
        <Content className="page-menus">
            <div className="panel-header">
                <div className="header-title">
                    <i className="fa fa-home icon" aria-hidden="true"></i>
                    <Link to="/"><span>Dashboard</span></Link>
                    <span className="icon-next"><i className="fa fa-angle-right"></i></span>
                    <span>Hệ thống</span>
                    <span className="icon-next"><i className="fa fa-angle-right"></i></span>
                    <span className="active">Phụ phí xăng dầu</span>
                </div>
            </div>
            <div className="col-md-12">
                <div className="panel-body">
                    <div className="row mr-b-10">
                        <div className="col-md-3 line-input">
                            <label className="label-input bold">Bưu cục bán</label>
                            <Select
                                value={params.StationCodeSell || 'ALL'}
                                onChange={(value) => fetchFuelSurcharges({ StationCodeSell: value })}
                                dropdownMatchSelectWidth={200}
                                options={_stations}
                                className="w-100"
                            />
                        </div>
                        <div className="col-md-3 line-input">
                            <label className="label-input bold">Bưu cục mua</label>
                            <Select
                                value={params.StationCodeBuy || 'ALL'}
                                onChange={(value) => fetchFuelSurcharges({ StationCodeBuy: value })}
                                dropdownMatchSelectWidth={200}
                                options={_stations}
                                className="w-100"
                            />
                        </div>
                        <div className="col-md-3">
                            <label className="bold">Khách hàng</label>
                            <Select
                                allowClear
                                showSearch
                                filterOption={false}
                                value={params.CustomerCode || undefined}
                                loading={customersing}
                                options={_customers}
                                onChange={(value) => fetchFuelSurcharges({ ...params, CustomerCode: value || '' })}
                                onSearch={(value) => searchCustomer(value)}
                                notFoundContent={customersing ? <Spin size="small" /> : (search ? 'Không tìm thấy khách hàng' : 'Vui lòng nhập 2 ký tự trở lên')}
                                placeholder="Tìm kiếm khách hàng"
                                className="w-100"
                            />
                        </div>
                    </div>
                    <div className="row mr-b-10">
                        <div className="col-md-6">
                            <label className="total-filter">
                                <img src={Icons.common.filter} alt="Quản lý vùng" />|&ensp;<span>{n_start} - {n_end}</span> trong tổng <span>{total}</span> bản ghi
                            </label>
                        </div>
                        <div className="col-md-6 text-right">
                            <Button
                                type="primary"
                                onClick={() => setFuel(null, () => setOpen(true))}
                                icon={<AppstoreAddOutlined />}
                            >
                                Thêm mới phụ phí
                            </Button>
                            &ensp;
                            {/* <Button type={'primary'} onClick={exportExcel} loading={exporting} icon={<img src={Icons.common.excel} alt="xuất" />}>&ensp;Xuất Excel</Button>&ensp; */}
                            <Button onClick={() => fetchFuelSurcharges({ Offset: 1, Size: 100 })} icon={<ReloadOutlined />}>Làm mới</Button>&ensp;
                            <Popover placement="bottomRight" content={content(props)} trigger="click">
                                <Button icon={<SettingOutlined />} />
                            </Popover>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <Table
                                loading={loading}
                                className="table-main"
                                columns={createColums(props)}
                                rowKey="STT"
                                size="small"
                                dataSource={surcharges || []}
                                pagination={false}
                                bordered={true}
                            />
                        </div>
                    </div>
                    <div className="row mr-t-10">
                        <div className="col-md-6">
                            <span>Hiển thị &ensp;
                                <Select value={size || 20} style={{ width: 120 }} onChange={(size) => fetchFuelSurcharges({ Size: size, Offset: 1 })}>
                                    <Select.Option value="20">20</Select.Option>
                                    <Select.Option value="50">50</Select.Option>
                                    <Select.Option value="100">100</Select.Option>
                                    <Select.Option value="200">200</Select.Option>
                                </Select>
                                &nbsp; bản ghi / trang
                            </span>
                        </div>
                        <div className="col-md-6 text-right">
                            <Pagination current={offset || 1} pageSize={size || 20} total={total || 0} showSizeChanger={false} onChange={(offset) => fetchFuelSurcharges({ Offset: offset })} />
                        </div>
                    </div>
                </div>
            </div>
            {open && (
                <ExportFuelForm
                    open={open}
                    close={setOpen}
                    fuel={fuel}
                    loading={loading}
                    params={params}
                    fetchFuelSurcharges={fetchFuelSurcharges}
                />
            )}
        </Content>
    );
};

export default ExportFuelV

// Tạo ra danh sách cột trên bảng
const createColums = ({ columns = [], setFuel, setOpen, deleting, onDelete }) => {
    const _columns = [];
    if (columns && columns.length > 0) {
        columns.forEach((col) => {
            if (col.show) {
                if (col.type === "stt") {
                    _columns.push({ title: col.name, key: col.id, width: col.width, className: col.class, render: (item, rocrod, key) => key + 1 })
                } else if (col.type === "province") {
                    _columns.push({ title: col.name, key: col.id, width: col.width, className: col.class, render: (item) => item.PrefixProvince + ' ' + item.ProvinceName })
                }
                else if (col.type === 'charges') {
                    _columns.push({
                        title: col.name, key: col.id, width: col.width, className: col.class, render: (item) => {
                            return (
                                <span>{item[col.id] ? NumberCommas(item[col.id]) : '0'}</span>
                            )
                        }
                    })
                }
                else {
                    _columns.push({ title: col.name, dataIndex: col.id, key: col.id, width: col.width, className: col.class })
                }
            }
        });
    }
    _columns.push({
        title: "",
        key: "ACTION",
        width: 120,
        className: "text-center",
        render: (item) => {
            return (
                <>
                    <Tooltip placement="topRight" title={"Cập nhật tỷ giá"}>
                        <Button
                            size="small"
                            type="primary"
                            onClick={() => setFuel(item, () => setOpen(true))}
                            icon={<EditOutlined />}
                        >
                        </Button>
                    </Tooltip>
                    &ensp;
                    <Tooltip placement="right" title={"Xóa tỷ giá"}>
                        <Popconfirm title="Bạn có chắc muốn xóa?" onConfirm={() => onDelete(item)} placement="topRight" okText="Xóa" cancelText="Không" okType="danger">
                            <Button icon={<DeleteOutlined />} title="Xóa tỷ giá" loading={item.ID === deleting} size="small" type="danger" />
                        </Popconfirm>
                    </Tooltip>
                </>
            );
        },
    });
    return _columns;
}

const content = ({ columns, stateColumn }) => {
    return <div className="row" style={{ maxWidth: '400px' }}>
        {columns && columns.map((col, key) =>
            <div className="col-md-6" key={key} style={{ padding: '5px 15px' }}><Checkbox checked={col.show} onChange={() => stateColumn(col)} ><span className="bold">{col.name}</span></Checkbox></div>)
        }
    </div>
}