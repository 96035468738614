import { createAction, handleActions } from 'redux-actions';

export const fetchSubscriptionTypes = createAction('FETCH_SUBSCRIPTION_TYPE_REQUEST');
export const addSubscriptionTypes = createAction('ADD_SUBSCRIPTION_TYPE_REQUEST');
export const updateSubscriptionTypes = createAction('UPDATE_SUBSCRIPTION_TYPE_REQUEST');
export const deleteSubscriptionTypes = createAction('DELETE_SUBSCRIPTION_TYPE_REQUEST');

export const getEmailSubscriptionByTypeRequest = createAction('GET_EMAIL_SUBSCRIPTION_BY_TYPE_REQUEST');
export const toggleActiveEmailSubscriptionRequest = createAction('TOGGLE_ACTIVE_EMAIL_SUBSCRIPTION_REQUEST');
export const removeEmailSubscriptionRequest = createAction('REMOVE_EMAIL_SUBSCRIPTION_REQUEST');
export const addEmailSubscriptionRequest = createAction('ADD_EMAIL_SUBSCRIPTION_REQUEST');

export const getUserSubscriptionByTypeRequest = createAction('GET_USER_SUBSCRIPTION_BY_TYPE_REQUEST');
export const toggleActiveUserSubscriptionRequest = createAction('TOGGLE_ACTIVE_USER_SUBSCRIPTION_REQUEST');
export const removeUserSubscriptionRequest = createAction('REMOVE_USER_SUBSCRIPTION_REQUEST');
export const addUserSubscriptionRequest = createAction('ADD_USER_SUBSCRIPTION_REQUEST');

export const initialState = {
    error: null
};

// - Reducers
const reducer = handleActions({
}, initialState);

export default reducer;