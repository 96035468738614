import "./style.scss";
import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import {
    Layout,
    Button,
    Table,
    Popover,
    Checkbox,
    DatePicker,
    Select,
    Pagination,
    Tooltip,
    Popconfirm,
} from "antd";
import {
    ReloadOutlined,
    SettingOutlined,
    EditOutlined,
    AppstoreAddOutlined,
    DeleteOutlined,
} from "@ant-design/icons";
import { Icons } from "../../assets";
import ExchargeForm from "./components/ExchargeForm";

const { Content } = Layout;

const ExchargeV = (props) => {
    const {
        exporting,
        pagination,
        params,
        exchargesing,
    } = props;
    const {
        fetchExcharges,
        setOpen,
        excharges,
        setExcharge,
        open, excharge, loading } = props;

    const size =
        params.Size && Number(params.Size) > 0 ? Number(params.Size) : 20;
    const offset =
        params.Offset && Number(params.Offset) > 0 ? Number(params.Offset) : 1;
    const total =
        pagination && pagination.Total && Number(pagination.Total) > 0
            ? Number(pagination.Total)
            : 1;
    const n_start = total > 0 ? (offset - 1) * size + 1 : 0;
    const n_end = total > 0 ? (offset * size > total ? total : offset * size) : 0;

    return (
        <Content className="page-errors" id="body">
            <div className="panel-header">
                <div className="header-title">
                    <i className="fa fa-home icon" aria-hidden="true"></i>
                    <Link to="/">
                        <span>Dashboard</span>
                    </Link>
                    <span className="icon-next">
                        <i className="fa fa-angle-right" />
                    </span>
                    <span>Tiện ích</span>
                    <span className="icon-next">
                        <i className="fa fa-angle-right" />
                    </span>
                    <span className="active">Quản lý tỷ giá</span>
                </div>
            </div>
            <div className="panel-body" style={{ padding: "0px 15px 15px" }}>
                <div className="row mr-b-10 mr-t-15">
                    <div className="col-md-2 col-xs-6">
                        <label className="bold">Thời gian tạo tỷ giá</label>
                        <DatePicker
                            className="w-100"
                            format="MM-YYYY"
                            value={
                                moment(params.TimeRequest) || moment()
                            }
                            picker="month"
                            onChange={(val) =>
                                fetchExcharges({
                                    TimeRequest: val,
                                })
                            }
                        />
                    </div>
                    {/* <div className="col-md-3 col-xs-6">
                        <label className="bold">Tìm kiếm</label>
                        <Input.Search
                            value={params.Query}
                            onChange={(e) => setParams({ ...params, Query: e.target.value })}
                            onSearch={(value) =>
                                fetchExcharges({ ...params, Offset: 1, Query: value })
                            }
                            placeholder="Tìm kiếm theo: Mã tiền tệ, Tên tiền tệ, ..."
                            className="w-100"
                        />
                    </div> */}
                </div>
                <div className="col-md-12 pd-0">
                    <div className="col-md-12">
                        <div className="row mr-b-10">
                            <div className="col-md-3">
                                <label className="total-filter">
                                    <img src={Icons.common.filter} alt="Lỗi" />
                                    |&ensp;
                                    <span>
                                        {n_start} - {n_end}
                                    </span>{" "}
                                    trong tổng <span>{total}</span> loại tỷ giá
                                </label>
                            </div>
                            <div className="col-md-9 text-right">
                                <Button
                                    size="small"
                                    type="primary"
                                    onClick={() => setExcharge(null, () => setOpen(true))}
                                    icon={<AppstoreAddOutlined />}
                                >
                                    Thêm mới tỷ giá
                                </Button>
                                &ensp;
                                <Button
                                    size="small"
                                    loading={exporting}
                                    type={"button"}
                                    onClick={() =>
                                        fetchExcharges({
                                            Offset: 1,
                                            Size: 20,
                                        })
                                    }
                                    icon={<ReloadOutlined />}
                                >
                                    Làm mới
                                </Button>
                                &ensp;
                                <Popover
                                    placement="bottomRight"
                                    content={content(props)}
                                    trigger="click"
                                >
                                    <Button size="small" icon={<SettingOutlined />} />
                                </Popover>
                            </div>
                        </div>
                        <div className="row">
                            <Table
                                loading={exchargesing}
                                className="table-main tbl-declaration"
                                columns={createColums(props)}
                                rowKey="Id"
                                size="small"
                                dataSource={excharges || []}
                                pagination={false}
                                bordered={true}
                                scroll={{ x: 1500, y: "calc(100vh - 145px)" }}
                            />
                            <div className={params.CustomerId ? "hidden" : "row mr-t-15 "}>
                                <div className="col-md-6">
                                    <span>
                                        Hiển thị &ensp;
                                        <Select
                                            value={size || 20}
                                            style={{ width: 120 }}
                                            onChange={(size) =>
                                                fetchExcharges({ Size: size, Offset: 1 })
                                            }
                                        >
                                            <Select.Option value="20">20</Select.Option>
                                            <Select.Option value="50">50</Select.Option>
                                            <Select.Option value="100">100</Select.Option>
                                            <Select.Option value="200">200</Select.Option>
                                        </Select>
                                        &nbsp; loại tỷ giá / trang
                                    </span>
                                </div>
                                <div className="col-md-6 text-right">
                                    <Pagination
                                        current={offset || 1}
                                        pageSize={size || 20}
                                        total={total || 0}
                                        onChange={(offset) => fetchExcharges({ Offset: offset })}
                                        showSizeChanger={false}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {open && (
                <ExchargeForm
                    open={open}
                    close={setOpen}
                    excharge={excharge}
                    loading={loading}
                    params={params}
                    fetchExcharges={fetchExcharges}
                />
            )}
        </Content>
    );
};

export default ExchargeV;

const createColums = (props) => {
    const { columns, setOpen, setExcharge, onDeleteExcharge, deleting } = props;
    const _columns = [];
    columns.forEach((col) => {
        if (col.show) {
            if (col.type === "stt") {
                _columns.push({
                    title: () => {
                        return <div className="text-center">{col.name}</div>;
                    },
                    key: col.id,
                    width: col.width,
                    className: col.class,
                    render: (item, record, key) => key + 1,
                    fixed: "left"
                });
            }
            else if (col.type === "currency") {
                _columns.push({
                    title: col.name,
                    key: col.id,
                    className: col.class,
                    children: [
                        {
                            title: () => {
                                return <div className="text-center">Từ loại</div>;
                            },
                            className: `text-center`,
                            width: 300,
                            render: (item) =>
                                item['FromCurency']
                                    ? item['FromCurency']
                                    : '- - -',
                        },
                        {
                            title: () => {
                                return <div className="text-center">Đến loại</div>;
                            },
                            width: 300,
                            className: `text-center`,
                            render: (item) =>
                                item['ToCurency']
                                    ? item['ToCurency']
                                    : '- - -',
                        },
                        {
                            title: () => {
                                return <div className="text-center">Tỷ giá</div>;
                            },
                            width: 300,
                            className: `text-right`,
                            render: (item) =>
                                item['Rate']
                                    ? item['Rate']
                                    : '- - -',
                        },
                    ],
                });
            } else {
                _columns.push({
                    title: col.name,
                    dataIndex: col.id,
                    key: col.id,
                    width: col.width,
                    className: col.class,
                });
            }
        }
    });
    _columns.push({
        title: "",
        key: "ACTION",
        width: 120,
        className: "text-center",
        render: (item) => {
            return (
                <>
                    <Tooltip placement="topRight" title={"Cập nhật tỷ giá"}>
                        <Button
                            size="small"
                            type="primary"
                            onClick={() => setExcharge(item, () => setOpen(true))}
                            icon={<EditOutlined />}
                        >
                        </Button>
                    </Tooltip>
                    &ensp;
                    <Tooltip placement="right" title={"Xóa tỷ giá"}>
                        <Popconfirm title="Bạn có chắc muốn xóa?" onConfirm={() => onDeleteExcharge(item)} placement="topRight" okText="Xóa" cancelText="Không" okType="danger">
                            <Button icon={<DeleteOutlined />} title="Xóa tỷ giá" loading={item === deleting} size="small" type="danger" />
                        </Popconfirm>
                    </Tooltip>
                </>
            );
        },
    });
    return _columns;
};

const content = ({ columns, stateColumn }) => {
    return (
        <div className="row" style={{ maxWidth: "400px" }}>
            {columns &&
                columns.map((col, key) => (
                    <div className="col-md-6" key={key} style={{ padding: "5px 15px" }}>
                        <Checkbox checked={col.show} onChange={() => stateColumn(col)}>
                            <span className="bold">{col.name}</span>
                        </Checkbox>
                    </div>
                ))}
        </div>
    );
};
