import { lifecycle, compose as recompose, withState, withHandlers} from 'recompose';
import { connect } from 'react-redux';
import { notification } from 'antd';
import { createStructuredSelector } from 'reselect';
import _ from 'lodash';
import { fetchMenusRequest, fetchClientsRequest, fetchGroupsRequest, fetchUserMenusRequest, removerMenusRequest, removeUserMenusRequest } from '../../reduxs/menu-redux/reducer';
import { selectMenus, selectMenusing, selectClients, selectGroups, selectUserMenus } from '../../reduxs/menu-redux/selectors';
import { staffAllRequest } from '../../reduxs/common-redux/reducer';
import { selectStaffAlls, selectStationAllsing } from '../../reduxs/common-redux/selectors';

import MenuV from './MenuV';

export function mapDispatchToProps(dispatch) {
  return {
    getMenus: (params) => dispatch(fetchMenusRequest(params)),
    getGroups: (params) => dispatch(fetchGroupsRequest(params)),
    getStaffs: (params) => dispatch(staffAllRequest(params)),
    getClients: (params) => dispatch(fetchClientsRequest(params)),
    getUserMenus: (params) => dispatch(fetchUserMenusRequest(params)),
    removeMenus: (params) => dispatch(removerMenusRequest(params)),
    removeUserMenus: (params) => dispatch(removeUserMenusRequest(params)),
  };
}

const mapStateToProps = createStructuredSelector({
  menus: selectMenus(),
  loading: selectMenusing(),
  clients: selectClients(),
  groups: selectGroups(),
  staffs: selectStaffAlls(),
  staffsing: selectStationAllsing(),
  usermenus: selectUserMenus(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default recompose(
  withConnect,
  withState('open', 'close', false),
  withState('openMenu', 'closeMenu', false),
  withState('openGroup', 'closeGroup', false),
  withState('group', 'setGroup', null),
  withState('show', 'hidden', false),
  withState('openUserMenu', 'closeUserMenu', false),
  withState('menu', 'setMenu', null),
  withState('usermenu', 'setUsermenu', null),
  withState('tabs', 'setTabs', '1'),
  withState('staffCode', 'setStaffCode', null),
  withState('params', 'setParams', { Offset: 1, Size: 20 }),
  withState('selectedKeys', 'setSelectedKeys', []),
  withState('autoExpandParent', 'setAutoExpandParent', true),
  withState('checkedKeys', 'setCheckedKeys', []),
  withHandlers({
    fetchUserMenus: ({staffCode, getUserMenus}) => (params) => {
      if(staffCode && params && params.ClientId) {
        getUserMenus({UserCode: staffCode, ClientId: params.ClientId});
      }
    },
  }),
  withHandlers({
    fetchMenus: ({getMenus, getGroups, fetchUserMenus, setParams, params}) => (values) => {
      let _params = { ...params, ...values }
      _params = _.pickBy(_params, _.identity);
      setParams(_params);
      getMenus(_params);
      getGroups(_params);
      fetchUserMenus(_params)
    },
    onCheck: ({ setCheckedKeys, menus }) => (checkedKeys) => {
      console.log('onCheck', checkedKeys);
      let res = [...checkedKeys];

      if (res.length) {
        // Lấy parentId nếu có
      }

      setCheckedKeys(checkedKeys);
    }
  }),
  withHandlers({
    refreshMenu: ({fetchMenus}) => () => {
      fetchMenus();
    },
    onOpenGroup: ({closeGroup, setGroup}) => (value) => {
      if(value) {
        setGroup(value)
      } else {
        setGroup()
      }
      closeGroup(true);
    },
    onOpenUserMenu: ({closeUserMenu, setUsermenu}) => (value) => {
      if(value) {
        setUsermenu(value)
      } else {
        setUsermenu()
      }
      closeUserMenu(true);
    },
    onHidden: ({hidden, setGroup}) => (value) => {
      if(value) {
        setGroup(value);
        hidden(true);
      }
    },
    editMenu: ({setMenu, closeMenu}) => (item) => {
      setMenu((item || null), () => closeMenu(true))
    },
    removeMenus: ({ params, fetchMenus, removeMenus}) => (menu) => {
      if(menu && menu.Id) {
        const data = {
          Id: [menu.Id]
        }
        const callback = (type) => {
          if(type === 'error') {
            notification[type]({message: 'Xóa thất bại!'});
          } else {
            notification[type]({message: 'Xóa thành công!'});
          }
          fetchMenus(params);
        }
        const _params ={
          data: data,
          cb: callback
        }
        removeMenus(_params);
      }
    },
    removeUserMenus: ({staffCode, params, fetchUserMenus, removeUserMenus}) => (menu) => {
      if(menu && menu.Id && staffCode) {
        const data = {
          ToUserCode: [staffCode],
          MenuId: [menu.Id]
        }
        const callback = (type) => {
          if(type === 'error') {
            notification[type]({message: 'Xóa thất bại!'});
          } else {
            notification[type]({message: 'Xóa thành công!'});
          }
          fetchUserMenus(params);
        }

        const _params ={
          data: data,
          cb: callback
        }
        removeUserMenus(_params)
      }
    }
  }),
  lifecycle({
    componentDidMount() {
      this.props.getClients();
    },
    componentDidUpdate(props) {
      const { clients, fetchMenus, params } = this.props;
      if(!params.ClientId && clients && clients.length > 0) {
        fetchMenus({ ClientId: clients[0].Id })
      }
    }
  }),
)(MenuV);