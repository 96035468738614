import './style.scss';
import React from 'react';
import moment from 'moment';
import { Layout, Table, Select, Pagination, Input, Button, Collapse, Card, Radio } from 'antd';
import RestrictPage from './../../components/RestrictComponents/RestrictPage';
import { Icons } from '../../assets';
import 'pretty-print-json/dist/pretty-print-json.css'
import prettyPrintJson from 'pretty-print-json';
import { CaretRightOutlined, FilterOutlined, CaretDownOutlined, KeyOutlined } from '@ant-design/icons';

const { Content } = Layout;
const { Panel } = Collapse;
const { Option } = Select;

const DatabaseLogV = (props) => {
    const { isMobile, fetching, logs, primaryColumns, tables, columnKey } = props;
    const { pagination, params, onFetchData, onChangeParams, filterMode, setFilterMode } = props;

    const size = params.Size && Number(params.Size) > 0 ? Number(params.Size) : 50;
    const offset = params.Offset && Number(params.Offset) > 0 ? Number(params.Offset) : 1;
    const total = pagination && pagination.Total && Number(pagination.Total) > 0 ? Number(pagination.Total) : 0;
    const n_start = total > 0 ? (((offset - 1) * size) + 1) : 0;
    const n_end = total > 0 ? ((offset * size) > total ? total : offset * size) : 0;
    return (
        <RestrictPage>
            <Content className="page-database-changelog">
                <div className="panel-header">
                    <div className="header-title">
                        <i className="fa fa-home icon" aria-hidden="true"></i>
                        <span>Hệ thống</span>
                        <span className="icon-next"><i className="fa fa-angle-right"></i></span>
                        <span className="active">Database changelog</span>
                    </div>
                </div>
                <div className={isMobile ? "col-md-12 pd-0" : "col-md-12"}>
                    <div className="panel-body">
                        <div className="row">
                            <div className="col-md-12">
                                <Collapse className="filter-container" ghost expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}>
                                    <Panel header={<span className="bold">FILTER</span>} key="1">
                                        <Card size="small" title={<Radio checked={filterMode === 'DB'} onChange={() => setFilterMode('DB')}>Filter by database, table,...</Radio>} style={{marginBottom: 10, backgroundColor: filterMode === 'DB' ? '#fdfdfd' : null }}>
                                            <div className="row">
                                                <div className="col-lg-3 col-sm-6 col-xs-12" style={{ marginBottom: 15 }}>
                                                    <label className="bold">Database</label>
                                                    <Select disabled={filterMode !== 'DB'} allowClear mode="multiple" defaultValue={params.Database || []} value={params.Database || []} onChange={(value) => onChangeParams({ Database: value })} style={{ width: '100%' }}>
                                                        {
                                                            primaryColumns.map(x => {
                                                                return <Option key={x.Database} value={x.Database}>{x.Database}</Option>
                                                            })
                                                        }
                                                    </Select>
                                                </div>

                                                <div className="col-lg-3 col-sm-6 col-xs-12" style={{ marginBottom: 15 }}>
                                                    <label className="bold">Table name</label>
                                                    <Select disabled={filterMode !== 'DB'} allowClear mode="multiple" defaultValue={params.TableName || []} value={params.TableName || []} onChange={(value) => onChangeParams({ TableName: value })} style={{ width: '100%' }}>
                                                        {
                                                            tables.map(x => {
                                                                return <Option key={x.FullPath} value={x.FullPath}>{x.FullPath}</Option>
                                                            })
                                                        }
                                                    </Select>
                                                </div>

                                                <div className="col-lg-3 col-sm-6 col-xs-12" style={{ marginBottom: 15 }}>
                                                    <label className="bold">Column key name</label>
                                                    <Select disabled={filterMode !== 'DB'} allowClear dropdownMatchSelectWidth={false} mode="multiple" defaultValue={params.Column || []} value={params.Column || []} onChange={(value) => onChangeParams({ Column: value })} style={{ width: '100%' }}>
                                                        {
                                                            columnKey.map(x => {
                                                                return <Option key={x.FullPath} value={x.FullPath}>{x.FullPath}{x.Type === 'PRIMARY KEY' && <span>&nbsp;<KeyOutlined style={{color: '#0d7ecc'}} /></span>}</Option>
                                                            })
                                                        }
                                                    </Select>
                                                </div>

                                                <div className="col-lg-3 col-sm-6 col-xs-12" style={{ marginBottom: 15 }}>
                                                    <label className="bold">KeyValue</label>
                                                    <Input disabled={filterMode !== 'DB'} defaultValue={params.KeyValue || ''} onChange={(e) => onChangeParams({ KeyValue: e.target.value })} style={{ width: '100%' }} />
                                                </div>

                                            </div>
                                        </Card>

                                        <Card size="small" title={<Radio checked={filterMode === 'HAWB'} onChange={() => setFilterMode('HAWB')}>Filter by Hawb number</Radio>} style={{marginBottom: 10, backgroundColor: filterMode === 'HAWB' ? '#fdfdfd' : null }}>
                                            <div className="row">
                                                <div className="col-lg-3 col-sm-6 col-xs-12">
                                                    <label className="bold">Hawb Number</label>
                                                    <Input disabled={filterMode !== 'HAWB'} defaultValue={params.HawbNo || ''} onChange={(e) => onChangeParams({ HawbNo: e.target.value })} style={{ width: '100%' }} />
                                                </div>
                                            </div>
                                        </Card>

                                        <div className="row" style={{ marginBottom: 15 }}>
                                            <div className="col-lg-12">
                                                <Button loading={fetching} icon={<FilterOutlined />} onClick={() => onFetchData({})} >Filter</Button>
                                            </div>
                                        </div>
                                    </Panel>
                                </Collapse>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <label className="total-filter">
                                    <img src={Icons.common.filter} alt="Số request" />|&ensp;<span>{n_start} - {n_end}</span> trong <span>{total}</span>
                                </label>
                            </div>
                            <div className="col-md-6 text-right">
                                <Pagination current={offset || 1} pageSize={size || 20} total={total || 0} showSizeChanger={false} onChange={(offset) => onFetchData({ Offset: offset })} />
                            </div>
                        </div>
                        <div className="row mr-t-10">
                            <div className="col-md-12">
                                <Table
                                    loading={fetching}
                                    className="table-request-histories"
                                    columns={createColumns(props)}
                                    rowKey="Id"
                                    size="small"
                                    dataSource={logs || []}
                                    pagination={false}
                                    bordered={true}
                                    expandable={{
                                        expandRowByClick: true,
                                        expandedRowRender: (record) => {
                                          return(
                                            <div className="table-view-children">
                                              <Table
                                                className="table-chidren small"
                                                columns={createChildTableColumns()}
                                                rowKey='Id'
                                                size="small"
                                                dataSource={[{ OldValues: record.OldValues, NewValues: record.NewValues }]}
                                                pagination={false}
                                                bordered={true}
                                              />
                                            </div>
                                          )
                                        },
                                        expandIcon: ({ expanded, onExpand, record }) =>
                                        expanded ? (
                                            <CaretDownOutlined onClick={e => onExpand(record, e)} />
                                          ) : (
                                            <CaretRightOutlined onClick={e => onExpand(record, e)} />
                                          )
                                    }}
                                    scroll={{ y: 'calc(100vh - 200px)' }}
                                />
                            </div>
                        </div>

                        <div className="row mr-t-10">
                            <div className="col-md-6">
                                <span>Hiển thị &ensp;
                                    <Select value={size || 20} style={{ width: 120 }} onChange={(size) => onFetchData({ Size: size, Offset: 1 })}>
                                        <Select.Option value="50">50</Select.Option>
                                        <Select.Option value="100">100</Select.Option>
                                        <Select.Option value="150">150</Select.Option>
                                        <Select.Option value="200">200</Select.Option>
                                    </Select>
                                    &nbsp; bản ghi / trang
                                </span>
                            </div>
                            <div className="col-md-6 text-right">
                                <Pagination current={offset || 1} pageSize={size || 20} total={total || 0} showSizeChanger={false} onChange={(offset) => onFetchData({ Offset: offset })} />
                            </div>
                        </div>
                    </div>
                </div>
            </Content>
        </RestrictPage>
    );
};

export default DatabaseLogV

const createColumns = (props) => {
    const colums = [
        {
            title: 'Datatable',
            dataIndex: null,
            key: 'Id',
            width: 100,
            render: ({ Database }) => {
                return (
                    <div><span>{Database}</span></div>
                )
            }
        },
        {
            title: 'TableName', dataIndex: null, key: 'TableName', render: ({ TableName }) => {
                return <span>{TableName}</span>
            }
        },
        {
            title: 'Keys', dataIndex: null, key: 'Id', className: 'vertical-align-top', render: ({ KeyValues }) => {
                return <pre><output dangerouslySetInnerHTML={{ __html: beautiPayload(KeyValues) }} /></pre>
            }
        },
        {
            title: 'Time', dataIndex: null, key: 'DateTime', width: 160, render: ({ DateTime }) => {
                return moment(DateTime).format('YYYY-MM-DD HH:mm:ss')
            }
        },
        {
            title: 'Action', dataIndex: null, key: 'Action', width: 120, className: 'text-center', render: ({ Action }) => {
                return <span className={`action-change action-change-${Action.toLowerCase()}`}>{Action}</span>
            }
        },
        {
            title: 'Author', dataIndex: null, key: 'Author', render: ({ Author }) => {
                return <pre><output dangerouslySetInnerHTML={{ __html: beautiPayload(Author) }} /></pre>
            }
        }
    ]
    return colums;
}

const createChildTableColumns = () => {
    const colums = [
        {
            title: 'Old values', dataIndex: null, key: 'Id', className: 'vertical-align-top fw-50', render: ({ OldValues }) => {
                return <pre><output dangerouslySetInnerHTML={{ __html: beautiPayload(OldValues) }} /></pre>
            }
        },
        {
            title: 'New values', dataIndex: null, key: 'Id', className: 'vertical-align-top', render: ({ NewValues }) => {
                return <pre><output dangerouslySetInnerHTML={{ __html: beautiPayload(NewValues) }} /></pre>
            }
        }
    ]
    return colums;
}

const beautiPayload = (_obj) => {
    try {
        return prettyPrintJson.toHtml(_obj);
    } catch (error) {
        return _obj;
    }
}