import React from 'react';
import { connect } from 'react-redux';
import { compose as recompose, withHandlers, lifecycle } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { Modal, List } from 'antd';

import './styles.scss';
import FlashNotificationItem from './flashNotificationItem';


const FlashNotification = (props) => {
    const { flashNotifications } = props;
    return (
        <Modal
            closable={false}
            footer={null}
            className="pcs-falsh-notification"
            visible={flashNotifications && flashNotifications.length > 0}>
            <List
                dataSource={flashNotifications}
                renderItem={item => (
                    <List.Item key={item.id}>
                        <FlashNotificationItem notification={item} />
                    </List.Item>
                )}
            >
            </List>
        </Modal>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {

    };
}

const mapStateToProps = createStructuredSelector({
    flashNotifications: store => store.notification && store.notification.flashNotifications
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default recompose(
    withConnect,
    withHandlers({

    }),
    lifecycle({
        componentDidMount() {

        }
    })
)(FlashNotification)