import "../style.scss";
import moment from 'moment';
import React from "react";
import { Button, Form, notification, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import {
    compose as recompose,
    lifecycle,
    pure,
    withHandlers,
    withProps,
    withState,
} from "recompose";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Field, reduxForm } from "redux-form";
import Modal from "antd/lib/modal/Modal";
import {
    FieldDatePicker,
    FieldInput,
    FieldSelect,
} from "../../../components/Fields";
import { required } from '../../../helpers/validate';

import { ExportFuelFormAction, EXPORT_FUEL_FORM_KEY } from "../../../reduxs/export-fuel-redux/action";
import { selectStaffs, selectStaffsing, selectStationAlls, selectTypeServices } from "../../../reduxs/common-redux/selectors";
import { getTypeServicesRequest, staffAllRequest } from "../../../reduxs/common-redux/reducer";
import Auth from "../../../storage/Auth";
import { searchCustomerRequest } from "../../../reduxs/utility-redux/reducer";
import { selectCustomers, selectCustomersing } from "../../../reduxs/utility-redux/selectors";

const STATION_CODE = Auth.getStation();

const ExportFuelForm = (props) => {
    const {
        open,
        close,
        loading,
        submitting,
        handleSubmit,
        fuel,
        services,
        stations,
        customersing,
        searchCustomer,
        customers,
        search, } = props;

    let _services = [];
    if (services && services.length) {
        _services = services.map((item) => ({ value: item.Code, text: `${item.Code} - ${item.Description}`, search: `${item.Code} - ${item.Description}` }));
    }

    let _stations = [];
    if (stations && stations.length) {
        _stations = stations.map((item) => {
            return {
                value: item.STATION_CODE,
                text: `${item.STATION_CODE} - ${item.NAME}`
            }
        })
    }
    _stations.unshift({ value: 'ALL', text: 'Tất cả các bưu cục' })

    let _customers = [];
    if (customers && customers.length) {
        _customers = customers.map((item) => {
            return {
                id: item.Value.AccountNr,
                value: item.Value.AccountNr,
                text: `${item.Value?.AccountNr} - ${item.Value?.ShortName || item.Value?.FullName}`
            }
        })
    }

    const submit = handleSubmit(ExportFuelFormAction);

    return (
        <Modal
            title={fuel ? "Cập nhật phụ phí xăng dầu " : "Thêm mới phụ phí xăng dầu"}
            closable={true}
            visible={open}
            maskClosable={false}
            width={"650px"}
            footer={null}
            className="fuel-modal"
            onOk={() => {
                close(false);
            }}
            onCancel={() => {
                close(false);
            }}
        >
            <Spin
                spinning={loading || false}
                indicator={<LoadingOutlined spin />}
                tip="Xin chờ..."
            >
                <Form layout="vertical" onFinish={submit}>
                    <div>
                        <div className="panel-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <Field
                                        component={FieldDatePicker}
                                        className="w-100 required"
                                        formater="DD-MM-YYYY"
                                        label="Ngày áp dụng"
                                        name="APPLIED_DATE"
                                        placeholder="Ngày áp dụng"
                                        validate={[required]}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        label="Loại dịch vụ"
                                        component={FieldSelect}
                                        name="TOS"
                                        placeholder="Chọn loại dịch vụ gửi hàng"
                                        options={_services}
                                        showSearch={true}
                                        filterOption={(input, option) => {
                                            if (option.search) {
                                                return (option.search.toLowerCase().indexOf(input.toLowerCase()) >= 0);
                                            }
                                        }}
                                        className="required w-100"
                                        validate={[required]}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        component={FieldSelect}
                                        label="Bưu cục bán"
                                        name="STATION_CODE_SELL"
                                        placeholder="Chọn loại dịch vụ gửi hàng"
                                        options={_stations}
                                        showSearch={true}
                                        filterOption={(input, option) => {
                                            if (option.search) {
                                                return (option.search.toLowerCase().indexOf(input.toLowerCase()) >= 0);
                                            }
                                        }}
                                        className="required w-100"
                                        validate={[required]}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        component={FieldSelect}
                                        label="Bưu cục mua"
                                        name="STATION_CODE"
                                        placeholder="Chọn loại dịch vụ gửi hàng"
                                        options={_stations}
                                        showSearch={true}
                                        filterOption={(input, option) => {
                                            if (option.search) {
                                                return (option.search.toLowerCase().indexOf(input.toLowerCase()) >= 0);
                                            }
                                        }}
                                        className="required w-100"
                                        validate={[required]}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        component={FieldInput}
                                        className="w-100 required"
                                        label="Phụ phí xăng dầu (%)"
                                        name="COST_SURCHARGES"
                                        placeholder="Mã tiền tệ"
                                        type={"number"}
                                        mode='comma'
                                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                        parser={value => `${value}`.replace(/(,*)/g, '')}
                                        validate={[required]}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        component={FieldSelect}
                                        className="w-100"
                                        label="Khách hàng"
                                        name="ACCOUNT_NO"
                                        filterOption={false}
                                        loading={customersing}
                                        options={_customers}
                                        onSearch={(value) => searchCustomer(value)}
                                        showSearch={true}
                                        notFoundContent={customersing ? <Spin size="small" /> : (search ? 'Không tìm thấy khách hàng' : 'Vui lòng nhập 2 ký tự trở lên')}
                                        placeholder="Tìm kiếm khách hàng"
                                    />
                                </div>
                                <div className="col-md-12 text-right">
                                    <Button
                                        loading={submitting}
                                        type="primary"
                                        htmlType="submit"
                                        icon={<i className="fa fa-floppy-o" />}
                                    >
                                        &ensp;Lưu lại
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            </Spin>
        </Modal >
    );
};

var searchtime = null;

export function mapDispatchToProps(dispatch) {
    return {
        getStaffs: (params) => dispatch(staffAllRequest(params)),
        getServices: (params) => dispatch(getTypeServicesRequest(params)),
        getCustomers: (params) => dispatch(searchCustomerRequest(params)),
    };
}

const mapStateToProps = createStructuredSelector({
    staffs: selectStaffs(),
    isCustomerSearching: selectStaffsing(),
    services: selectTypeServices(),
    stations: selectStationAlls(),
    customers: selectCustomers(),
    customersing: selectCustomersing(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default recompose(
    withConnect,
    withState('search', 'setSearchStaff', false),
    withState('search', 'setLabelCustomer', false),
    withProps(({ fuel }) => {
        if (fuel) {
            return {
                initialValues:
                    {
                        ...fuel,
                        APPLIED_DATE: moment(fuel.APPLIED_DATE).format('DD-MM-YYYY')
                    } || null,
            };
        } else {
            return {
                initialValues: {
                    APPLIED_DATE: moment().format('DD-MM-YYYY'),
                    TOS: "CPQ",
                    STATION_CODE_SELL: "ALL",
                    STATION_CODE: "ALL",
                    COST_SURCHARGES: 20
                },
            };
        }
    }),
    reduxForm({
        form: EXPORT_FUEL_FORM_KEY,
        enableReinitialize: true,
        onSubmitSuccess: (result, dispatch, props) => {
            notification.success({
                message: "Cập nhật dữ liệu thành công!",
            });
            props.initialValues = null;
            props.reset();
            props.fetchFuelSurcharges();
            props.close(false);
        },
    }),
    withHandlers({
        searchCustomer: ({ setLabelCustomer, getCustomers }) => (value) => {
            if (value && value.length > 1) {
                clearTimeout(searchtime)
                searchtime = setTimeout(() => {
                    getCustomers({ Query: value, Limit: 20, IsActive: true, StationCode: STATION_CODE })
                }, 700);
                setLabelCustomer(true);
            } else {
                setLabelCustomer(false);
            }
        },
    }),
    lifecycle({
        componentDidMount() {
            const { services, getServices } = this.props;
            if (!(services && services.length)) {
                getServices({ Type: 'POST_EXP' });
            }
        },
    }),
    pure
)(ExportFuelForm);
