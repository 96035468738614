
import React from 'react';
import {  Modal, Alert, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { compose as recompose, withHandlers, lifecycle, pure, withState } from 'recompose';
import { connect } from 'react-redux';
import FormZone from './ZoneForm';

import { getZoneByAccounRequest } from '../../../reduxs/account-redux/reducer';

const ZoneForm = (props) => {
    const { open, zone, account, message, onHide, fetchAccounts, loading } = props;
    return (
        <Modal
            title={<label>{account.AccountNr === '-1' ? '(Chưa cấp mã)' : account.AccountNr} - {account.ShortName || account.FullName}</label>}
            visible={open}
            style={{ top: 20 }}
            width={'760px'}
            footer={null}
            maskClosable={false}
            onOk={() => onHide()}
            onCancel={() => onHide()}
            className="modal-form-zone"
            >
                <div className="box-form-zone">
                    { !loading && !message && <FormZone close={onHide} zone={zone} account={account} fetchAccounts={fetchAccounts}/> }
                    { !loading && message &&  <Alert message="Chưa cấp tài khoản" description={message} type="warning" showIcon /> }
                    { loading && <div className="spin-loading">
                        <Spin indicator={<LoadingOutlined style={{ fontSize: 35 }} title="Đang tải" spin />} />
                    </div>}
                </div>
        </Modal>
    )
}

function mapDispatchToProps(dispatch) {
    return {
        getZoneAccount: (params) => dispatch(getZoneByAccounRequest(params))
    };
}
  
const withConnect = connect(null, mapDispatchToProps);
  
export default recompose(
    withConnect,
    withState('zone', 'setZone', null),
    withState('loading', 'setLoading', true),
    withState('message', 'setMessage', ''),
    withHandlers({
        getZone: ({ account, setZone, setLoading, getZoneAccount, setMessage }) => () => {
            setMessage(null);
            if(account && account.Id && account.Id) {
                getZoneAccount({
                    data: account.Id,
                    cb: (type, result) => {
                        if(type === 'error') {
                            setMessage(result, setZone(null, () => setLoading(false)));
                        } else {
                            setMessage('', setZone(result, () => setLoading(false)));
                        }
                    }
                })
            } else {
                setMessage('Vui lòng cấp tài khoản cho khách hàng trước khi phân vùng', setZone(null, () => setLoading(false)));
            }
        },
        onHide: ({setZone, close}) => () => {
            setZone(null, () => close(false))
        }
    }),
    lifecycle({
        componentDidMount() {
            this.props.getZone();
        },
    }),
    pure
)(ZoneForm);
