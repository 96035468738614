
import '../style.scss';
import React from 'react';
import moment from 'moment';
import { Button, Image, Input, message, Modal, Switch, Tooltip } from 'antd';
import { getAvatar } from '../../../../config';
import { EditOutlined, SaveOutlined } from '@ant-design/icons';
import { compose as recompose, withHandlers, lifecycle, withState, withPropsOnChange } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { changeProfileStatusRequest } from '../../../../reduxs/account-redux/reducer';
const { TextArea } = Input;
const Info = (props) => {
  const { user, groupId, setOpen } = props;
  return (
    <div className="row">
      <div className="col-md-12 box-profile">
        <div className="pannels">
          <div className="panel">
            <div className="profile-avatar">
              <div className="background-avatar"></div>
              <div className="avatar-wrapper">
                <div className="avatar">
                  <img className='image-upload' src={getAvatar(user && user.AccountNr) + `?type=MEDIUM&avatar=${Math.random()}`} alt="" />
                </div>
              </div>
            </div>
            <div className="profile-body">
              <h1 className="title-name bold">{user?.FullName} ({user?.ShortName})</h1>
              <div>

                {user.GroupId === 'PERSONAL'
                  ?
                  <label className="label-personal">
                    <span><i class="fa fa-user-circle-o" />&ensp;Cá nhân</span>
                  </label>
                  :
                  <label className="label-company">
                    <span><i class="fa fa-building-o" />&ensp;Doanh nghiệp</span>
                  </label>
                }
                &ensp;

                {/* {!complete
                  ?
                  <Tooltip placement="right" title={
                    <p>Ghi chú: abc xyz</p>
                  }>
                    <label className="label-uncomplete">
                      <span><i className="fa fa-exclamation-circle" />&ensp;Chưa hoàn thành hồ sơ</span>
                    </label>
                  </Tooltip>

                  :
                  <Tooltip placement="right" title="Hồ sơ đầy đủ thông tin">
                    <label className="label-complete">
                      <span><i className="fa fa-check-circle" />&ensp;Đã hoàn thành hồ sơ</span>
                    </label>
                  </Tooltip>
                } */}
                {user.ProfileComplete
                  ?
                  <Tooltip placement="top" title={
                    user.ProfileNote ? <span>Ghi chú: {user.ProfileNote}</span> : <span className="text-center">Không có ghi chú</span>
                  }>
                    <label className="label-complete">
                      <span><i className="fa fa-check-circle" />&ensp;Hồ sơ đầy đủ thông tin</span>
                    </label>
                  </Tooltip>
                  :
                  <Tooltip placement="top" title={
                    user.ProfileNote ? <span>Ghi chú: {user.ProfileNote}</span> : <span className="text-center">Không có ghi chú</span>
                  }>
                    <label className="label-uncomplete">
                      <span><i className="fa fa-exclamation-circle" />&ensp;Hồ sơ chưa đủ thông tin</span>
                    </label>
                  </Tooltip>
                }
                &ensp;
                <Button onClick={() => setOpen(true)} size={"small"}><EditOutlined /></Button>
                <span className="pull-right"></span>
              </div>
              <div className="box-panel">
                <div className="row">
                  {user.groupId === 'PERSONAL' &&
                    <>
                      <div className="col-md-4 item-info">
                        <label className="icon-item"><i className="fa fa-map-marker" /></label>
                        <span>{user?.Gender ? 'Nam' : 'Nữ'}</span>
                      </div>
                      <div className="col-md-4 item-info">
                        <label className="icon-item"><i className="fa fa-map-marker" /></label>
                        <span>{user?.DOB ? moment(user?.DOB).format('DD-MM-YYYY') : ''}</span>
                      </div>
                    </>
                  }
                  {/* <div className="col-md-4 item-info">
                    <label className="icon-item"><i className="fa fa-map-marker"/></label>
                    <span>{user?.Addresses.length > 0 ? user?.Addresses[0].Street : ''}</span>
                  </div> */}
                  {user.Phones && user.Phones.map((item, key) => {
                    return (
                      <div className="col-md-4 item-info" key={key}>
                        <label className="icon-item"><i className="fa fa-phone" /></label>
                        <span>{item.PhoneNr}</span>
                      </div>
                    )
                  })}
                  {user.Emails && user.Emails.map((item, key) => {
                    return (
                      <p className="col-md-4 item-info" key={key}>
                        <label className="icon-item"><i className="fa fa-envelope-o" /></label>
                        <span>{item.Email}</span>
                      </p>
                    )
                  })}
                  {user.Addresses && user.Addresses.map((item, key) => {
                    if (!item.Street) return ''
                    return (
                      <div className="col-md-12 item-info" key={key}>
                        <label className="icon-item"><i className="fa fa-map-marker" /></label>
                        <span>{item.Street} {item.Ward ? (', ' + item.Ward) : ''} {item.District ? (', ' + item.District) : ''} {item.City ? (', ' + item.City) : ''}</span>
                      </div>
                    )
                  })}
                </div>
              </div>
              {groupId === 'PERSONAL'
                ?
                <div className="row">
                  <div className="col-md-6">
                    <div className='item'>
                      <label className='item-title w-100'>Ảnh CMND (mặt trước)</label>
                      <Image
                        src={user?.PassportImageA}
                        className="item-img"
                        width="100px"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className='item'>
                      <label className='item-title  w-100'>Ảnh CMND (mặt sau)</label>
                      <Image
                        src={user?.PassportImageB}
                        className="item-img"
                        width="100px"
                      />
                    </div>
                  </div>
                </div>
                :
                <div className="row">
                  <div className="col-md-6">
                    <div className='item'>
                      <div className="w-100">

                        {user?.PassportImageA
                          ? <label className="label-registration"><span><i class="fa fa-check" />&ensp;Đã có phiếu đăng ký <a href={`${user?.PassportImageA}`} target="_blank" rel="noopener noreferrer">Xem chi tiết</a></span></label>
                          : <label className="label-unregistration"><span><i className="fa fa-exclamation-triangle" />&ensp;Chưa có phiếu đăng ký sử dụng dịch vụ</span></label>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      <ConfirmForm close={() => setOpen(false)} otherProps={{ ...props }} />
    </div >
  )
}

const ConfirmForm = props => {
  const { otherProps, close } = props;
  const { open, submitting, pristine, setParam, param, onChangeStatus, resetForm } = otherProps;
  return (
    <Modal
      title='Cập nhật trạng thái hồ sơ'
      visible={open}
      style={{ top: 50 }}
      width={'450px'}
      footer={null}
      maskClosable={false}
      onOk={() => { resetForm(); close(false); }}
      onCancel={() => { resetForm(); close(false) }}
      className="modal-update-status"
    >
      <div className="row">
        <div className="col-md-12 mr-b-10">
          <label>Trạng thái thông tin hồ sơ: &ensp;</label>
          <Switch onChange={value => setParam({ ...param, ProfileComplete: value })} checkedChildren="Đầy đủ" unCheckedChildren="Còn thiếu" defaultChecked={param.ProfileComplete || false} />
        </div>
        <div className="col-md-12 mr-b-15">
          <TextArea maxLength={500} onChange={e => setParam({ ...param, ProfileNote: e.target.value })} rows={4} value={param.ProfileNote || ''} placeholder="Nhập vào ghi chú tài khoản" />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 text-right">
          <Button onClick={() => onChangeStatus()} loading={submitting} disabled={pristine} type="primary" icon={<SaveOutlined />} htmlType="button">Lưu</Button>
        </div>
      </div>
    </Modal>
  )
}


function mapDispatchToProps(dispatch) {
  return {
    changeProfileStatus: (params) => dispatch(changeProfileStatusRequest(params)),
    getStatusProfile: (params) => dispatch(changeProfileStatusRequest(params)),
  };
}
const mapStateToProps = createStructuredSelector({
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default recompose(
  withConnect,
  withState('open', 'setOpen', false),
  withState('param', 'setParam', {}),
  withState('submitting', 'setSubmitting', false),
  withHandlers({
    resetForm: ({ setParam, user }) => () => {
      setParam({ AccountNo: user.AccountNr, ProfileComplete: user.ProfileComplete, ProfileNote: user.ProfileNote })
    }
  }),
  withHandlers({
    onChangeStatus: ({ param, setSubmitting, changeProfileStatus, setOpen, resetForm, fetchUser, user, fetchAccounts }) => () => {
      const _params = {
        data: { ...param, AccountNo: user.AccountNr },
        cb: (type) => {
          if (type === 'success') {
            message.success('Cập nhật trạng thái thành công!')
            resetForm();
            setOpen(false);
          } else {
            message.error('Có lỗi trong quá trình cập nhật trạng thái!')
          }
          setSubmitting(false);
          fetchUser();
          fetchAccounts();
        }
      }
      setSubmitting(true)
      changeProfileStatus(_params);
    }
  }),
  withPropsOnChange(['user'], ({ setParam, user }) => {
    if (user) {
      setParam({ AccountNo: user.AccountNr, ProfileComplete: user.ProfileComplete, ProfileNote: user.ProfileNote })
    }
  }),
  lifecycle({
    componentDidMount() {
    },
  }),
)(Info);
