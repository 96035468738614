import { createFormAction } from "redux-form-saga";
export const REGION_FORM_KEY = "REGION_FORM_KEY";
export const RegionFormAction = createFormAction(REGION_FORM_KEY);

export const PROVINCE_FORM_KEY = "PROVINCE_FORM_KEY";
export const ProvinceFormAction = createFormAction(PROVINCE_FORM_KEY);

export const DISTRICT_FORM_KEY = "DISTRICT_FORM_KEY";
export const DistrictFormAction = createFormAction(DISTRICT_FORM_KEY);

export const WARD_FORM_KEY = "WARD_FORM_KEY";
export const WardFormAction = createFormAction(WARD_FORM_KEY);
