
import axios from '../utils/axiosInstance';
import FileSaver from 'file-saver';

export const getAccounts = async (params) => {
  try {
    const { data } = await axios.get(`/api/customer-wait-confirm`, {
      params: params
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getCustomers = async (params) => {
  try {
    const { data } = await axios.get(`/crm/customer/by-station`, {
      params: params
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getCodes = async (params) => {
  try {
    const { data } = await axios.get(`/api/customer/parent-code`, {
      params: params
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getEmails = async (params) => {
  try {
    const { data } = await axios.get(`/crm/customer/email-payment-by`, {
      params: params
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getCodeDetais = async (params) => {
  try {
    const { data } = await axios.get(`/api/customer/parent-code/detail`, {
      params: params
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getSteps = async (params) => {
  try {
    const { data } = await axios.get(`/api/crm/verify/steps`, {
      params: params
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const confirmAccounts = async (body) => {
  try {
    const { data } = await axios.post(`/api/customer/confirm`, body);
    return data;
  } catch (error) {
    throw error;
  }
};

export const stepAccounts = async (body) => {
  try {
    const { data } = await axios.post(`/api/crm/verify`, body);
    return data;
  } catch (error) {
    throw error;
  }
};


export const getAccountType = async (params) => {
  try {
    const { data } = await axios.get(`/crm/customer/account-type`, {
      params: params
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getAllowedVerifySteps = async (params) => {
  try {
    const { data } = await axios.get(`/api/customer/allowed-verify-steps`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const changeAccountType = async (body) => {
  try {
    const { data } = await axios.put(`/crm/customer/update-account-type`, body);
    return data;
  } catch (error) {
    throw error;
  }
};

export const changeAccountCode = async (body) => {
  try {
    const { data } = await axios.put(`/api/customer/change-parent-code`, body);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getZones = async (params = {}) => {
  try {
    const { data } = await axios.get('/zone', { params });
    return data;
  } catch (error) {
    throw error;
  }
};


export const getAccountInfo = async ({ AccountNr }) => {
  try {
    const { data } = await axios.get(`/user/info/${AccountNr}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getInvoices = async (params = {}) => {
  try {
    const { data } = await axios.get('/crm/customer/vat-invoice-info', { params });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getWarehouseUser = async (params) => {
  try {
    const { data } = await axios.get("/api/warehouse/by-customer", { params });
    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchBanks = async (params) => {
  try {
    const { data } = await axios.get(`/ops-web-use-bank/list-web-use-bank-by`, {
      params: params
    });
    return data;
  } catch (error) {
    throw error;
  }
};
export const getBanks = async (params) => {
  try {
    const { data } = await axios.get("/ops-web-use-bank/list-bank-by-user", { params });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getZoneByAccount = async (AccountNr) => {
  try {
    const { data } = await axios.get(`/zone/customer/${AccountNr}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const addZone = async (zone) => {
  try {
    const { data } = await axios.post(`/zone/customer`, zone);
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateZone = async ({ zone, ZoningInfoId }) => {
  try {
    const { data } = await axios.put(`/zone/${ZoningInfoId}/customer`, zone);
    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteZone = async (id) => {
  try {
    const { data } = await axios.delete(`/zone/customer/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const createUserByCustomerId = async (body) => {
  try {
    const { data } = await axios.post(`/api/crm/create-user-by-id`, body);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getSchedulesByCode = async ({ CustomerId }) => {
  try {
    const { data } = await axios.get(`/api/money-schedule/${CustomerId}`);
    return data;
  } catch (error) {
    throw error;
  }
};
export const getScheduleTypes = async () => {
  try {
    const { data } = await axios.get('/api/money-schedule/type');
    return data;
  } catch (error) {
    throw error;
  }
};

export const saveSchedule = async (body) => {
  try {
    if (body.Type === 'type') {
      const { data } = await axios.put(`/api/money-schedule/change-type`, { CustomerId: body.Id, TypeId: body.Value });
      return data;
    }
    if (body.Type === 'value') {
      const { data } = await axios.post(`/api/money-schedule`, { CustomerId: body.Id, TypeId: body.TypeId, Value: body.Value });
      if (body.Mode === 0) {
        await axios.put(`/api/money-schedule/change-type`, { CustomerId: body.Id, TypeId: body.TypeId });
      }
      return data;
    }
    if (body.Type === 'del') {
      let str = '';
      if (body.Ids && body.Ids.length) {
        body.Ids.forEach(id => {
          if (str) str += `&Id=${id}`
          else str += `Id=${id}`;
        });
      }
      const { data } = await axios.delete(`/api/money-schedule?${str}`);
      return data;
    }
  } catch (error) {
    throw error;
  }
};

export const saveCode = async (body) => {
  try {
    if (body.Id) {
      const { data } = await axios.put(`/api/customer/parent-code`, body);
      return data;
    } else {
      const { data } = await axios.post(`/api/customer/parent-code`, body);
      return data;
    }
  } catch (error) {
    throw error;
  }
};

export const saveValueMode = async (body) => {
  try {
    if (body.Reset) {
      const { data } = await axios.put(`/api/volmode/reset`, body);
      return data;
    } else {
      const { data } = await axios.put(`/api/volmode`, body);
      return data;
    }
  } catch (error) {
    throw error;
  }
}

export const removeParentCode = async (code) => {
  try {
    const { data } = await axios.delete(`/api/customer/parent-code`, { params: code });
    return data;
  } catch (error) {
    throw error;
  }
};

export const removeAccountCode = async (code) => {
  try {
    const { data } = await axios.delete(`/api/customer/remove-parent-code`, { params: code });
    return data;
  } catch (error) {
    throw error;
  }
};

export const downloadAccounts = async (params) => {
  try {
    const response = await axios.get(`/api/customer-wait-confirm/download`, {
      params: params,
      responseType: 'blob'
    });

    const { data } = response;
    const namefile = response.headers['pcs-filename-suggestion'];
    const fileType = response.headers['content-type'];
    var blob = new Blob([data], { type: fileType });
    FileSaver.saveAs(blob, namefile);
    return true;
  } catch (error) {
    throw error;
  }
};

export const resetPasswordToDefault = async (_params) => {
  try {
    const { data } = await axios.put(`/api/crm/reset-password`, _params);
    return data;
  } catch (error) {
    throw error;
  }
};
export const changestationAccounts = async (body) => {
  try {
    const { data } = await axios.put(`/crm/customer/change-station-customer`, body);
    return data;
  } catch (error) {
    throw error;
  }
};

export const saveEmail = async (body) => {
  try {
    if (body.ID) {
      const { data } = await axios.put(`/crm/customer/change-email-payment-customer`, body);
      return data;
    } else {
      const { data } = await axios.post(`/crm/customer/add-email-payment-customer`, body);
      return data;
    }
  } catch (error) {
    throw error;
  }
};

export const deleteEmail = async (body) => {
  try {
    const { data } = await axios.delete(`/crm/customer/delete-email-payment-customer`, { data: body });
    return data;
  } catch (error) {
    throw error;
  }
};

export const changeProfileStatus = async (_params) => {
  try {
    const { data } = await axios.put(`/crm/customer/change-profile-status`, _params);
    return data;
  } catch (error) {
    throw error;
  }
};
