import axios from '../utils/axiosInstance';

// Danh sách chức năng được phép
export const fetchPickupRoutes = async (params) => {
    try {
        const { data } = await axios.get('/api/courier/pickup-manager/route', { params});
        return data;
    } catch (error) {
        throw error;
    }
};

export const getPickupRoute = async (params) => {
    try {
        const { data } = await axios.get('/api/courier/pickup-manager/route/detail/by-id', {params });
        return data;
    } catch (error) {
        throw error;
    }
};

export const getPickupStaff = async (params) => {
    try {
        const { data } = await axios.get('/api/courier/pickup-manager/route/allocate-staff', {params});
        return data;
    } catch (error) {
        throw error;
    }
};

export const saveRoute = async (route) => {
    try {
        if(route.Id) {
            route.CommonPrice = route.Price;
            const { data } = await axios.put(`/api/courier/pickup-manager/route`, route);
            return data;
        } else {
            const { data } = await axios.post(`/api/courier/pickup-manager/route`, route);
            return data;
        }
    } catch (error) {
      throw error;
    }
};

export const saveRegion = async (route) => {
    try {
        const { data } = await axios.post(`/api/courier/pickup-manager/route/detail`, route);
        return data;
    } catch (error) {
      throw error;
    }
};

export const saveStaffPickup = async (route) => {
    try {
        let staffs = route.childrens.map(i => i.StaffCode);
        let price = route.childrens.find(i => i.IsPrice);
        let body = {
            Routes: price ? [{RouteId: route.RouteId, Price: price.Price || ''}] : [{RouteId: route.RouteId}],
            StaffCodes: staffs
        }
        const { data } = await axios.post(`/api/courier/pickup-manager/route/allocate-staff`, body);
        return data;
    } catch (error) {
      throw error;
    }
};


export const deleteRoute = async (body) => {
    try {
      const { data } = await axios.delete(`/api/courier/pickup-manager/route`, { params: body});
      return data;
    } catch (error) {
      throw error;
    }
};

export const deleteRegion = async (body) => {
    try {
      const { data } = await axios.delete(`/api/courier/pickup-manager/route/detail`, { params: body});
      return data;
    } catch (error) {
      throw error;
    }
};

export const deletePickup = async (body) => {
    try {
      const { data } = await axios.put(`/api/courier/pickup-manager/route/allocate-staff`, body);
      return data;
    } catch (error) {
      throw error;
    }
};