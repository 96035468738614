import '../style.scss';
import React from 'react';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';
import { connect } from 'react-redux';
import { Button, Form, Modal, notification } from 'antd';
import { SaveOutlined, CloseSquareOutlined, LockOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { compose as recompose, withHandlers, withProps, lifecycle, withState } from 'recompose';
import { Field, reduxForm } from 'redux-form';
import { required } from '../../../helpers/validate';
import { FieldInput, FieldSelect, FieldDatePicker, FieldTextArea } from '../../../components/Fields';
import { FORM_STAFF_KEY, StaffFormAction } from '../../../reduxs/staff-redux/action';
import { selectStations } from '../../../reduxs/station-redux/selectors';
import { getDepartmentsRequest, getMyStationRequest } from '../../../reduxs/common-redux/reducer';
import { selectDepartments } from '../../../reduxs/common-redux/selectors';
import _ from 'lodash';

const FormStaff = (props) => {
    const { open, close, reset, handleSubmit, submitting, error, stations, departments, staff, onlyView } = props;
    const { onChangeDepartment, onChangeStation, onChangeGender } = props;
    const submit = handleSubmit(StaffFormAction);
    let _stations = [];
    if (stations && stations.length) {
        _stations = stations.map((item, key) => {
            return {
                key: key,
                value: item.STATION_CODE,
                search: item.STATION_CODE + ' ' + item.NAME,
                text: item.STATION_CODE + ' - ' + item.NAME
            }
        });
    }

    let _departments = [];
    if (departments && departments.length) {
        _departments = departments.map((item, key) => {
            return {
                key: key,
                value: item.Code,
                search: item.Code + ' ' + item.Name,
                text: item.Code + ' - ' + item.Name
            }
        });
    }

    const gender = [{ Code: '1', Name: 'Nam' }, { Code: '2', Name: 'Nữ' }, { Code: '3', Name: 'Khác' }];
    let _gender = [];
    if (gender && gender.length) {
        _gender = gender.map((item, key) => {
            return {
                key: key,
                value: item.Code,
                text: item.Name,
            }
        });
    }
    const formTitle = onlyView ? 'Thông tin chi tiết nhân viên' : (staff ? 'Cập nhật thông tin nhân viên' : 'Thêm mới nhân viên');
    return (
        <Modal
            title={formTitle}
            visible={open}
            style={{ top: 20 }}
            width={'720px'}
            footer={null}
            maskClosable={false}
            onOk={() => { reset(); close(false) }}
            onCancel={() => { reset(); close(false); }}
            className="modal-form"
        >
            <Form layout="vertical" onFinish={submit}>
                <div className="row">
                    <div className="col-md-12 mr-b-5 mr-t-5">
                        {
                            onlyView ?
                                staff?.IsAccountActive ?
                                    <div class="alert alert-success" role="alert">
                                        <CheckCircleOutlined /> Tài khoản đang hoạt động
                                    </div>
                                    :
                                    <div class="alert alert-danger" role="alert">
                                        <LockOutlined /> Tài khoản bị khóa
                                    </div>
                                :
                                <></>
                        }

                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        {
                            onlyView ?
                                <div className="row">
                                    <div className="col-md-6">
                                        <Field
                                            component={FieldInput}
                                            label="Mã nhân viên:"
                                            name="StaffCode"
                                            placeholder="Mã nhân viên"
                                            disabled={onlyView}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <Field
                                            component={FieldInput}
                                            label="Tên đăng nhập:"
                                            name="UserName"
                                            placeholder="Tên đăng nhập"
                                            disabled={onlyView}
                                        />
                                    </div>
                                </div>
                                :
                                <></>
                        }
                        {!staff
                            ?
                            <div className="row">
                                <div className="col-md-6">
                                    <Field
                                        component={FieldInput}
                                        label="Mã nhân viên:"
                                        name="StaffCode"
                                        placeholder="Tự sinh mã nếu bỏ trống"
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        component={FieldInput}
                                        label="Mật khẩu:"
                                        name="Password"
                                        placeholder="Mặc định: 12345678"
                                    />
                                </div>
                            </div>
                            :
                            <></>
                        }
                        <div className="row">
                            <div className="col-md-12 mr-b-5">
                                <Field
                                    label="Bưu cục"
                                    component={FieldSelect}
                                    mode="multiple"
                                    className="required w-100"
                                    name="Stations"
                                    style={{ 'max-height': '60px', 'overflow-y': 'scroll' }}
                                    validate={[required]}
                                    options={_stations}
                                    placeholder="Chọn bưu cục"
                                    onChange={(value) => onChangeStation(value)}
                                    disabled={onlyView}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 mr-b-5">
                                <Field
                                    label="Phòng ban"
                                    component={FieldSelect}
                                    mode="multiple"
                                    className="required w-100"
                                    name="Departments"
                                    style={{ 'max-height': '60px', 'overflow-y': 'scroll' }}
                                    validate={[required]}
                                    options={_departments}
                                    placeholder="Chọn phòng ban"
                                    onChange={(value) => onChangeDepartment(value)}
                                    disabled={onlyView}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 mr-b-5">
                                <Field
                                    component={FieldInput}
                                    label="Họ và tên:"
                                    name="FullName"
                                    placeholder="Nhập vào họ và tên"
                                    className="required"
                                    validate={[required]}
                                    disabled={onlyView}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 mr-b-5">
                                <Field
                                    component={FieldDatePicker}
                                    label="Ngày sinh:"
                                    name="Birthday"
                                    placeholder="Chọn ngày sinh"
                                    className="w-100"
                                    disabled={onlyView}
                                />
                            </div>
                            <div className="col-md-6 mr-b-5">
                                <Field
                                    component={FieldSelect}
                                    label="Giới tính:"
                                    name="Sex"
                                    placeholder="Chọn giới tính"
                                    options={_gender}
                                    className="w-100"
                                    onChange={(value) => onChangeGender(value)}
                                    disabled={onlyView}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 mr-b-5">
                                <Field
                                    component={FieldInput}
                                    label="Email"
                                    name="Email"
                                    placeholder="Nhập vào email"
                                    className="required"
                                    validate={[required]}
                                    disabled={onlyView}
                                />
                            </div>
                            <div className="col-md-6 mr-b-5">
                                <Field
                                    component={FieldInput}
                                    label="SĐT liên hệ"
                                    name="Tel"
                                    placeholder="Nhập vào số điện thoại liên hệ"
                                    className="required"
                                    validate={[required]}
                                    disabled={onlyView}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 mr-b-5">
                                <Field
                                    component={FieldInput}
                                    label="Địa chỉ"
                                    name="Address"
                                    placeholder="Nhập vào địa chỉ"
                                    className="w-100"
                                    disabled={onlyView}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 mr-b-5">
                                <Field
                                    component={FieldInput}
                                    label="Số điện thoại di động"
                                    name="Mobile"
                                    placeholder="Nhập vào số điện thoại di động"
                                    disabled={onlyView}
                                />
                            </div>
                            <div className="col-md-6 mr-b-5">
                                <Field
                                    component={FieldInput}
                                    label="Số FAX"
                                    name="Fax"
                                    placeholder="Nhập vào số FAX"
                                    disabled={onlyView}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 mr-b-5">
                                <Field
                                    component={FieldTextArea}
                                    label="Ghi chú"
                                    name="Note"
                                    rows="5"
                                    placeholder="Nhập vào ghi chú"
                                    disabled={onlyView}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <label>Ghi chú:&ensp;<span className="note-red">Trường không được để trống</span></label>
                                <div className="col-md-12 text-center">
                                    <p className="text-red">{error}</p>
                                    <Button type="secondary" icon={<CloseSquareOutlined />} onClick={() => close(false)} disabled={submitting} loading={submitting} htmlType="button" style={{ marginLeft: 8 }}>Hủy bỏ</Button>
                                    {!onlyView ? <> &emsp;<Button type="primary" icon={<SaveOutlined />} loading={submitting} disabled={submitting} htmlType="submit" style={{ marginLeft: 8 }}>Lưu lại</Button></> : <></>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </Modal>
    );
};

export function mapDispatchToProps(dispatch) {
    return {
        getDepartments: (params) => dispatch(getDepartmentsRequest(params)),
        getStations: (params) => dispatch(getMyStationRequest(params)),
    };
}
const mapStateToProps = createStructuredSelector({
    stations: selectStations(),
    departments: selectDepartments(),
    station: (state) => state.station.station
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default recompose(
    withConnect,
    withState('isDisableDefaultCode', 'setIsDisableDefaultCode', true),
    withState('isDisableDefaultPassword', 'setIsDisableDefaultPassword', true),
    withProps(({ staff, station }) => {
        var initialValues = {};
        initialValues = {
            isCodeDefault: true,
            isPasswordDefault: true
        }
        if (staff) {
            let _stations = [];
            let _departments = [];
            if (staff.Stations && staff.Stations.length) {
                _stations = staff.Stations.map((item) => item.StationCode)
            };
            if (staff.Departments && staff.Departments.length) {
                _departments = staff.Departments.map((item) => item.DepartmentCode)
            };
            initialValues = {
                ...initialValues,
                UserId: staff.UserId,
                StaffCode: staff.StaffCode,
                Address: staff.Address,
                Birthday: staff.Birthday ? moment(staff.Birthday, "YYYY/MM/DD") : null,
                FullName: staff.FullName,
                Tel: staff.Tel,
                Mobile: staff.Mobile,
                Fax: staff.Fax,
                Sex: staff.Sex,
                Email: staff.Email,
                Note: staff.Note,
                Stations: _stations,
                UserName: staff.UserName,
                Departments: _departments,
            }
        }
        else {
            const currentStation = [{ StationCode: station.STATION_CODE, StationName: station.STATION_CODE + ' - ' + station.NAME }];
            let _stations = [];
            _stations = currentStation.map((item) => item.StationCode)
            initialValues = {
                ...initialValues,
                Stations: _stations
            }
        }
        return { initialValues };
    }),
    reduxForm({
        form: FORM_STAFF_KEY,
        enableReinitialize: true,
        onSubmitSuccess: (result, dispatch, props) => {
            notification.success({
                message: 'Cập nhật dữ liệu thành công!'
            });
            props.initialValues = null;
            props.reset();
            props.close(false);
            props.fetchStaffs();
        }
    }),
    withHandlers({
        stateColumn: ({ columns, setColumns }) => (data) => {
            const cols = columns.map((col) => {
                if (col.id === data.id) col.show = !data.show
                return col;
            });
            setColumns(cols);
        },
    }),
    withHandlers({
        onChangeDepartment: ({ change }) => (value) => {
            change('Departments', value);
        },
        onChangeStation: ({ change }) => (value) => {
            change('Stations', value);
        },
        onChangeGender: ({ change }) => (value) => {
            change('Sex', value);
        },
        onChangeIsCodeDefault: (props) => () => {
            const { isDisableDefaultCode, setIsDisableDefaultCode } = props;
            setIsDisableDefaultCode(!isDisableDefaultCode);
        },
        onChangeIsPasswordDefault: (props) => () => {
            const { isDisableDefaultPassword, setIsDisableDefaultPassword } = props;
            setIsDisableDefaultPassword(!isDisableDefaultPassword);
        },
    }),
    lifecycle({
        componentDidMount() {
            const { stations, getStations, departments, getDepartments, getNewDefaultAccount, setNewGenCode, isGenCode, staff } = this.props;
            if (!(stations && stations.length)) {
                getStations();
            }
            if (!(departments && departments.length)) {
                getDepartments();
            }
            if (isGenCode && _.isEmpty(staff)) {
                getNewDefaultAccount();
                setNewGenCode(false);
            }
        },
    })
)(FormStaff);
