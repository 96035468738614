import './style.scss'
import React from "react";
import moment from 'moment';
import { Table, Collapse, Empty, Spin } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import { numberCommas } from '../../../helpers';

const { Panel } = Collapse;

const createColums = () => {
    return [
        { title: 'Dịch vụ', key: 'ServiceCode', width: 100, className: '', render: (item) => item.ServiceCode },
        { title: 'Chiết khấu', key: 'Discount', width: 80, className: 'text-right', render: (item) => item.Discount > 999 ? numberCommas(item.Discount) : item.Discount },
        { title: 'Hoa hồng', key: 'Commission', width: 80, className: 'text-right', render: (item) => item.Commission > 999 ? numberCommas(item.Commission) : item.Commission },
        { title: 'Đơn vị CK', key: 'CommissionType', width: 80, className: '', render: (item) => item.CommissionType === 'PERCENT' ? '%' : 'VNĐ' },
        { title: 'TG áp dụng', key: 'AppliedDate', width: 100, className: '', render: (item) => item.AppliedDate ? moment(item.AppliedDate).format('DD-MM-YYYY') : '' },
        { title: 'TG hết hạn', key: 'ExpiredDate', width: 100, className: '', render: (item) => item.ExpiredDate ? moment(item.ExpiredDate).format('DD-MM-YYYY') : '' },
        { title: 'Người tạo', key: 'CreatedBy', width: 140, className: '', render: (item) => item.CreatedBy },
        { title: 'Thời gian tạo', key: 'CreatedDate', width: 120, className: '', render: (item) => item.CreatedDate ? moment(item.CreatedDate).format('DD-MM-YYYY HH:mm') : '' },
        { title: 'Trạng thái', key: 'IsApproved', width: 80, className: '', render: (item) => item.IsApproved ? <span className="text-success">Đã duyệt</span> : <span className="text-danger">Chờ duyệt</span> },
    ]
}

const CommissionV = props => {
    const { commission, loadingCommission } = props;
    let acitveKey = [];
    if (commission) {
        if (commission.Activated && commission.Activated.length) acitveKey.push('1');
        if (commission.WaitingForApproval && commission.WaitingForApproval.length) acitveKey.push('2');
        if (commission.Deactivated && commission.Deactivated.length) acitveKey.push('3');
        if (commission.Expired && commission.Expired.length) acitveKey.push('4');
        if (commission.Deleted && commission.Deleted.length) acitveKey.push('5');
    }

    return <div className="row commission-table">
        {commission
            ?
            <Collapse
                bordered={false}
                defaultActiveKey={1}
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                className="site-collapse-custom-collapse"
            >
                <Panel header="ĐANG KÍCH HOẠT" key="1" className="site-collapse-custom-panel">
                    <Table
                        bordered
                        loading={loadingCommission}
                        size="small"
                        className="table-main"
                        columns={createColums(props, true, true)}
                        rowKey={(row, key) => key}
                        dataSource={commission?.Activated || []}
                        pagination={false}
                        scroll={{ x: 800 }}
                    />
                </Panel>
            </Collapse>
            : (loadingCommission ? <Spin spinning={loadingCommission} /> : <Empty description="Chưa có dữ liệu" />)
        }
    </div>
}

export default CommissionV;