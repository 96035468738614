
import './style.scss';
import React from 'react';
import { Layout, Table, Button, Popconfirm } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import PolicyForm from './components/PolicyForm';
import RestrictPage from './../../components/RestrictComponents/RestrictPage';


const { Content} = Layout;

const PolicyV = (props) => {
    const { policies, policy, clients, loading, fetchPolicy } = props;
    const { open, close } = props;

    return (
        <RestrictPage>
            <Content className="page-policys">
                <div className="panel-header">
                    <div className="header-title">
                        <i className="fa fa-home icon" aria-hidden="true"></i>
                        <span>Hệ thống</span>
                        <span className="icon-next"><i className="fa fa-angle-right"/></span>
                        <span className="active">Chính sách (Policies)</span>
                        <span  className="pull-right">
                            <Button icon={<PlusOutlined />} size="small" type="primary" onClick={() => close(true)}>Thêm mới</Button>
                        </span>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="panel-body">
                        <div className="import-table">
                            <div className="row">
                                <div className="col-md-12">
                                    <Table
                                        loading={loading}
                                        bordered
                                        className="table-main table-role"
                                        columns={reactColums(props)}
                                        rowKey='Host'
                                        dataSource={ policies || []}
                                        pagination={false}
                                        size="small"
                                        scroll={{x: true}}
                                        locale={{emptyText: 'Không có dữ liệu'}}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {open && <PolicyForm open={open} close={close} policy={policy} clients={clients} fetchPolicy={fetchPolicy}/>}
            </Content>
        </RestrictPage>
    )
}
export default PolicyV

const reactColums = ({deletePolicy}) => {
    const colums = [
        { title: 'Tên miền gốc', dataIndex: 'Origin', key: 'Origin' },
        { title: 'Mã hệ thống', dataIndex: 'SystemId', key: 'SystemId' },
        { title: 'Tên hệ thống', dataIndex: 'SystemName', key: 'SystemName' },
        { title: 'Tên miền được phép', dataIndex: 'Host', key: 'Host', width: 350 },
        { title: 'Tên chính sách', dataIndex: 'PolicyName', key: 'PolicyName'},
        { title: '', dataIndex: '', key: 'x', className: "text-center", width: 50,  render: (policy) => {
            return  <Popconfirm title="Bạn có chắc muốn xóa?" onConfirm={()=>deletePolicy(policy)} placement="topRight" okText="Xóa" cancelText="Không">
                        <Button icon={<DeleteOutlined />} size="small" type="danger"/>
                    </Popconfirm>;
        } },
    ]
    return colums;
}

