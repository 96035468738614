import { createSelector } from 'reselect';
import { initialState } from './reducer';

const policyState = (state) => state.policy || initialState;

const selectPolicies = () => createSelector(
  policyState,
  ({ policies }) => policies
);
const selectPoliciesing = () => createSelector(
  policyState,
  ({ loading }) => loading
);
const selectPagination = () => createSelector(
  policyState,
  ({ pagination }) => pagination
);

export {
  policyState,
  selectPolicies,
  selectPoliciesing,
  selectPagination,
}
