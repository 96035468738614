import React from 'react';
import { Button, Modal, Form, notification } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { compose as recompose, withHandlers, withProps} from 'recompose';
import { Field, reduxForm } from 'redux-form';
import { required  } from '../../../helpers/validate';
import { FieldInput, FieldSelect } from '../../../components/Fields';


import { POLICY_FORM_KEY, PolicyFormAction } from '../../../reduxs/policy-redux/action';

const AutoForm = (props) => {
  const { open, close, reset, clients, handleSubmit, submitting, pristine } = props;
  const submit = handleSubmit(PolicyFormAction);

  let _clients = [];
  if(clients && clients.length) {
    _clients = clients.map((item) => {
      return {
        value: item.Id,
        text: `${item.Name} - ${item.Url}`
      }
    })
  }

  return (
    <Modal
      title='Thêm tên miền cho phép'
      visible={open}
      style={{ top: 50 }}
      width={'560px'}
      footer={null}
      maskClosable={false}
      onOk={() => { reset(); close(false) }}
      onCancel={() => { reset(); close(false) }}
      className="modal-form"
    >
      <Form layout="vertical" onFinish={submit}>
        <div className="row">
          <div className="col-md-12">
            <Field
              component={FieldSelect}
              label="Hệ thống"
              name="SystemId"
              placeholder=""
              options={_clients}
              className="required"
              validate={[required]}
            />
          </div>
          <div className="col-md-6">
            <Field
              component={FieldInput}
              label="Tên miền được cho phép"
              name="AllowedHost"
              placeholder=""
              className="required"
              validate={[required]}
            />
          </div>
          <div className="col-md-6">
            <Field
              component={FieldInput}
              label="Tên chính sách"
              name="PolicyName"
              placeholder=""
              className="required"
              validate={[required]}
            />
          </div>
        </div>
        <br/>
        <Form.Item className="text-center">
            <Button loading={submitting} disabled={pristine} type="primary" icon={<SaveOutlined />} htmlType="submit">Lưu</Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default recompose(
    withProps(({auto}) => {
        if(auto) {
          return { initialValues: { ...auto }};
        } else {
          return { initialValues: null};
        }
      }
    ),
    withHandlers({
    }),
    reduxForm({
      form: POLICY_FORM_KEY,
      enableReinitialize: true,
      onSubmitSuccess: (result, dispatch, props) => {
          notification.success({
              message: 'Lưu thành công!'
          });
          props.initialValues = null;
          props.reset();
          props.close(false);
          props.fetchPolicy();
      }
    })
)(AutoForm);
