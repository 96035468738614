import axios from '../utils/axiosInstance';

export const findLocation = async (params) => {
  try {
    const { data } = await axios.get('/common/local/find', {
      params: {
        Key: params.Key, Limit: params.Limit || 50
      }
    });
    return data || [];
  } catch (error) {
    throw error;
  }
};

export const getCountry = async (params) => {
  try {
    const { data } = await axios.get('/common/local/country');
    return data || [];
  } catch (error) {
    throw error;
  }
};

export const getProvinces = async (params) => {
  try {
    const { data } = await axios.get(`/common/local/country/${params.CountryId}/city`);
    return data || [];
  } catch (error) {
    throw error;
  }
};

export const getDistricts = async ({ CountryId, CityId }) => {
  try {
    const { data } = await axios.get(`/common/local/country/${CountryId}/city/${CityId}/district`);
    return data || [];
  } catch (error) {
    throw error;
  }
};

export const getWards = async ({ CountryId, CityId, DistrictId }) => {
  try {
    const { data } = await axios.get(`/common/local/country/${CountryId}/city/${CityId}/district/${DistrictId}/ward`);
    return data || [];
  } catch (error) {
    throw error;
  }
};

export const searchCustomers = async (params) => {
  try {
    const { data } = await axios.get("/fin-autocomplete/list-customer", {
      params,
    });
    return data || [];
  } catch (error) {
    throw error;
  }
};
