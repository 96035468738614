
//import axios from '../utils/axiosInstance';
import API from '../utils/axiosApiHR';
import { printFrame } from '../helpers/common';
import FileSaver from 'file-saver';

export const fetchAssets = async (params) => {
  try {
    const { data } = await API.get('/assets', { params });
    return data;
  } catch (error) {
    throw error;
  }
};

export const excelAssets = async (params) => {
  try {
    const response = await API.get('/assets/export-excel', { params,  responseType: 'blob' });
    const { data } = response;
    const namefile = response.headers['pcs-filename-suggestion'];
    const fileType = response.headers['content-type'];
    var blob = new Blob([data], {type: fileType});
    FileSaver.saveAs(blob, namefile);
    return true;
  } catch (error) {
    throw error;
  }
}

export const fetchUserAssets = async (params) => {
  try {
    const { data } = await API.get('/assets/staff', { params });
    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchAssetTypes = async (params) => {
  try {
    const { data } = await API.get('/assets/type', { params });
    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchStatusHistory = async (params) => {
  try {
    const { data } = await API.get('/assets/status/history', { params });
    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchAssignHistory = async (params) => {
  try {
    const { data } = await API.get('/assets/assigned/history', { params });
    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchRepairHistory = async (params) => {
  try {
    const { data } = await API.get('/assets/repairs/history', { params });
    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchReports = async (params) => {
  try {
    const { data } = await API.get('/assets/reports', { params });
    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchRequests = async (params) => {
  try {
    const { data } = await API.get('/assets/requests', { params });
    return data;
  } catch (error) {
    throw error;
  }
};


export const getRepairLast = async (params) => {
  try {
    const { data } = await API.get('/assets/repairs/last', { params });
    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchAssetStaff = async (params) => {
  try {
    const { data } = await API.get('/assets/assigned/last', { params });
    return data;
  } catch (error) {
    throw error;
  }
};


export const saveAsset = async (body) => {
  try {
    if(body.ID) {
      const { data } = await API.put('/assets', body);
      return data;
    } else {
      const { data } = await API.post('/assets', body);
      return data;
    }
  } catch (error) {
    throw error;
  }
};

export const saveAssetType = async (body) => {
  try {
    if(body && body.ID) {
      const { data } = await API.put(`/assets/type/${body.ID}`, body);
      return data;
    } else {
      const { data } = await API.post('/assets/type', body);
      return data;
    }
  } catch (error) {
    throw error;
  }
};
export const deleteAssetType = async ({Id}) => {
  try {
    const { data } = await API.delete(`/assets/type/${Id}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const assignAsset = async (body) => {
  try {
    const { data } = await API.post('/assets/assigned', body);
    return data;
  } catch (error) {
    throw error;
  }
};


export const statusAsset = async (body) => {
  try {
    const { data } = await API.post('/assets/status', body);
    return data;
  } catch (error) {
    throw error;
  }
};

export const repairAsset = async (body) => {
  try {
    const { data } = await API.post('/assets/repair', body);
    return data;
  } catch (error) {
    throw error;
  }
};

export const reportAsset = async (body) => {
  try {
    const { data } = await API.post('/assets/report', body);
    return data;
  } catch (error) {
    throw error;
  }
};

export const reportConfirm = async (body) => {
  try {
    const { data } = await API.post('/assets/report/confirm', body);
    return data;
  } catch (error) {
    throw error;
  }
};

export const requestAsset = async (body) => {
  try {
    const { data } = await API.post('/assets/request', body);
    return data;
  } catch (error) {
    throw error;
  }
};

export const requestConfirm = async (body) => {
  try {
    const { data } = await API.post('/assets/request/confirm', body);
    return data;
  } catch (error) {
    throw error;
  }
};

export const printLabel = async ({ AssetsCode }) => {
  try {
    let str = ''
    if(AssetsCode && AssetsCode.length) {
      AssetsCode.forEach(code => {
        if(str) {
          str += `&AssetsCode=${code}`
        } else {
          str = `AssetsCode=${code}`
        }
      });
    }
    const { data } = await API.get(`/assets/qrcode?${str}`);
    if(data && data.Result.Content) {
      printFrame(data.Result.Content)
      return true;
    }
    throw new Error('Error');
  } catch (error) {
    throw error;
  }
}

export const saveAssetExcel = async (params) => {
  try {
    const body = new FormData()
    body.append('PuStaffCode', params.StaffCode);
    body.append('File', params.File);
    const { data } = await API.post(`/asset/excel`, body);
    return data;
  } catch (error) {
    throw error;
  }
}

export const downloadTemplate = async (params) => {
  try {
    const response = await API.get('/assets/download-template', { params,  responseType: 'blob' });
    const { data } = response;
    const namefile = response.headers['pcs-filename-suggestion'];
    const fileType = response.headers['content-type'];
    var blob = new Blob([data], {type: fileType});
    FileSaver.saveAs(blob, namefile);
    return true;
  } catch (error) {
    throw error;
  }
}