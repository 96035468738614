import React from 'react';
import { Button, Modal, Form, notification } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { compose as recompose, withHandlers, withProps} from 'recompose';
import { Field, reduxForm } from 'redux-form';
import { required  } from '../../../helpers/validate';
import { FieldInput, FieldSelect } from '../../../components/Fields';


import { WARD_FORM_KEY, WardFormAction } from '../../../reduxs/region-redux/action';

const DistrictForm = (props) => {
  const { open, close, wards, reset, error, handleSubmit,submitting } = props;
  const submit = handleSubmit(WardFormAction);

  return (
    <Modal
      title={wards && wards.Id ? 'Cập nhật xã phường' : 'Thêm xã phường'}
      visible={open}
      style={{ top: 20 }}
      width={'560px'}
      footer={null}
      maskClosable={false}
      onOk={() => { reset(); close(false) }}
      onCancel={() => { reset(); close(false) }}
      className="modal-form-region"
    >
      <Form layout="vertical" onFinish={submit}>
        <div className="row">
          <div className="col-md-7">
            <Field
              component={FieldInput}
              disabled={wards && wards.Id }
              label="ID"
              name="Id"
              placeholder=""
              className="required"
              validate={[required]}
            />
          </div>
          <div className="col-md-5">
            <Field
              component={FieldSelect}
              label="Prefix"
              name="Prefix"
              placeholder=""
              options={[
                {value: 'Phường', text: 'Phường'},
                {value: 'Thị Trấn', text: 'Thị Trấn'},
                {value: 'Xã', text: 'Xã'},
                {value: 'KCN', text: 'KCN'},
                {value: 'KKT', text: 'KKT'},
                {value: 'CCN', text: 'CCN'}
              ]}
              className="required"
              validate={[required]}
            />
          </div>
          <div className="col-md-7">
            <Field
              component={FieldInput}
              label="Tên đơn vị"
              name="Name"
              placeholder=""
              className="required"
              validate={[required]}
            />
          </div>
          <div className="col-md-5">
            <Field
              component={FieldInput}
              label="Kiểu vùng"
              name="ZoneType"
              placeholder=""
            />
          </div>
          {/* <div className="col-md-12">
            <Field
              component={FieldInput}
              label="Vị trí địa lý"
              name="Coordinate"
              placeholder=""
            />
          </div> */}
        </div>
        <p>Ghi chú:&ensp;<span className="note-red">Trường không được để trống</span></p>
        <Form.Item className="text-center">
            <Button type="primary" icon={<SaveOutlined />} htmlType="submit" loading={submitting}>Lưu lại</Button>
            <p className="text-center text-danger">{error}</p>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default recompose(
    withProps(({wards, DistrictId}) => {
        if(wards && wards.Id) {
          return { initialValues: { DistrictId, edit: true, Id: wards.Id, Prefix: wards.Prefix, Name: wards.Name,  ZoneType: wards.ZoneType, Coordinate: wards.Coordinate,  }};
        } else {
          return { initialValues: { DistrictId, Prefix: 'Phường' }};
        }
      }
    ),
    withHandlers({
    }),
    reduxForm({
      form: WARD_FORM_KEY,
      enableReinitialize: true,
      onSubmitSuccess: (result, dispatch, props) => {
          notification.success({
              message: 'Lưu thành công!'
          });
          props.initialValues = null;
          props.reset();
          props.close(false);
          props.fetchWards();
      }
    })
)(DistrictForm);
