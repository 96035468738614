import React from 'react';
import { connect } from 'react-redux';
import { compose as recompose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { withRouter, Redirect } from 'react-router-dom';
import { find } from 'lodash';
import { getMyMenuRequest } from './../../reduxs/user-menu-redux/reducer';

class RestrictPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        if (!this.props.fetchedServer) {
            this.props.fetchMyMenu();
        }
    }

    componentWillReceiveProps(nextProps) {

    }
    
    render() {
        const { loading, myMenu } = this.props;
        const { pathname } = this.props.location;
        const menu = find(myMenu, { Route: pathname })
        let allowed = !loading && menu;
        return !loading ? (allowed ? this.props.children : <Redirect to="/"/> ) : <div/>
    }
}


export function mapDispatchToProps(dispatch) {
    return {
        fetchMyMenu: () => dispatch(getMyMenuRequest())
    };
}

const mapStateToProps = createStructuredSelector({
    myMenu: (store) => store.userMenu && store.userMenu.myMenu,
    loading: (store) => store.userMenu && store.userMenu.fetching,
    fetchedServer: (store) => store.userMenu && store.userMenu.fetchedServer
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default recompose(
    withConnect,
    withRouter
)(RestrictPage);