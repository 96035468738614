import { call, put, takeLatest } from 'redux-saga/effects';
import * as CustomerDocService from '../../services/customer-doc-service';
import { CustomerDocFormAction } from './action';
import { SubmissionError } from 'redux-form';

import {
  getCustomerDocSuccess,
  getDocTypeSuccess,
} from './reducer';


export function* saveCustomerDoc({ payload }) {
  try {
    const { Result } = yield call(payload.Id ? CustomerDocService.editCustomerDoc :  CustomerDocService.addCustomerDoc, payload);
    if (Result) {
      yield put(CustomerDocFormAction.success('Lưu thành công'))
    } else {
      const message = 'Lưu thất bại!'
      const formError = new SubmissionError({
        _error: message
      })
      yield put(CustomerDocFormAction.failure(formError))
    }
  } catch (error) {
    const formError = new SubmissionError({
      _error:  error.message || 'Lưu thất bại'
    })

    yield put(CustomerDocFormAction.failure(formError))
  }
}

export function* getCustomerDocByCustomer({ payload = {} }) {
  try {
    const { Result } = yield call(CustomerDocService.getCustomerDocs, payload);
    if(Result) {
      yield put(getCustomerDocSuccess({ documents: Result }));
    } else {
      yield put(getCustomerDocSuccess({ documents: [] }));
    }
  } catch (error) {
    yield put(getCustomerDocSuccess({ documents: [] }));
  }
}


export function* uploadCustomerDoc({ payload }) {
  const { data, cb } = payload;
  try {
    const { Result } = yield call(CustomerDocService.uploadCustomerDoc, data);
    if (Result) {
      if(cb) cb(true);
    } else {
      if(cb) cb(false);
    }
  } catch (error) {
    if(cb) cb(false);
  }
}

export function* deleteCustomerDoc({ payload }) {
  const { data, cb } = payload;
  try {
    const result = yield call(CustomerDocService.deleteCustomerDoc, data);
    if (result) {
      if(cb) cb(true);
    } else {
      if(cb) cb(false);
    }
  } catch (error) {
    if(cb) cb(false);
  }
}

export function* getCustomerDocTypes({ payload = {} }) {
  try {
    const { Result } = yield call(CustomerDocService.getCustomerDocType, payload);
    if(Result) {
      yield put(getDocTypeSuccess({ types: Result }));
    }
  } catch (error) {
  }
}



export default [
  takeLatest(CustomerDocFormAction.REQUEST, saveCustomerDoc),
  takeLatest('GET_CUSTOMER_DOC_REQUEST', getCustomerDocByCustomer),
  takeLatest('UPLOAD_CUSTOMER_DOC_REQUEST', uploadCustomerDoc),
  takeLatest('REMOVE_CUSTOMER_DOC_REQUEST', deleteCustomerDoc),
  takeLatest('GET_CUSTOMER_DOC_TYPE_REQUEST', getCustomerDocTypes)
];
