import { createAction, handleActions } from 'redux-actions';

export const fetchLogsRequest = createAction('FETCH_LOGS_REQUEST');
export const fetchLogsSuccess = createAction('FETCH_LOGS_SUCCESS');

export const sqlCommandLogsRequest = createAction('FETCH_SQL_COMMAND_LOGS_REQUEST');
export const sqlCommandLogsSuccess = createAction('FETCH_SQL_COMMAND_LOGS_SUCCESS');

export const initialState = {
    logs: [],
    fetching: false,
    sqlCmdFetching: false,
    pagination: {
        Page: 1,
        Size: 50,
        Total: 0
    },
    commandLogs: [],
    cmdPagination: {
        Page: 1,
        Size: 50,
        Total: 0
    },
    error: null
};

// - Reducers
const reducer = handleActions({
    [fetchLogsRequest]: (state) => {
        return { ...state, fetching: true };
    },
    [fetchLogsSuccess]: (state, {payload: { logs, pagination }}) => {
        const data = { logs: logs, pagination: pagination }
        return { ...state, ...data, fetching: false  };
    },
    [sqlCommandLogsRequest]: state => ({ ...state, sqlCmdFetching: true }),
    [sqlCommandLogsSuccess]: (state, { payload }) => ({ ...state, sqlCmdFetching: false, commandLogs: payload.data, cmdPagination: { ...state.cmdPagination, ...payload.pagination } })
}, initialState);

export default reducer;