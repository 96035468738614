import { createAction, handleActions } from 'redux-actions';
// Actions
// Bưu cục
export const manageStationListRequest = createAction('MANAGESTATION_LIST_REQUEST');
export const manageStationListSuccess = createAction('MANAGESTATION_LIST_SUCCESS');
export const deleteManageStationRequest = createAction('DELETE_MANAGESTATION_REQUEST');
export const failure = createAction('MANAGESTATION_ACTION_FAILURE');
// Xuất excel
export const exportExcelRequest = createAction('EXPORT_EXCEL_REQUEST');
// Loại bưu cục
export const stationTypeListSuccess = createAction('STATIONTYPE_LIST_SUCCESS');
export const stationTypeListRequest = createAction('STATIONTYPE_LIST_REQUEST');
// Danh sách tất cả bưu cục
export const stationAllRequest = createAction('STATION_ALL_REQUEST');
export const stationAllSuccess = createAction('STATION_ALL_SUCCESS');
// Danh sách nhân viên
export const employeesListSuccess = createAction('EMPLOYEES_LIST_SUCCESS');
export const employeesListRequest = createAction('EMPLOYEES_LIST_REQUEST');
// Danh sách nhân viên trong bưu cục
export const employeesInStationListRequest = createAction('EMPLOYEESINSTATION_LIST_REQUEST');
export const employeesInStationListSuccess = createAction('EMPLOYEESINSTATION_LIST_SUCCESS');

// - Initial State
export const initialState = {
  manageStations: [],
  pagination: {
    Page: 1,
    Size: 50,
    Total: 0
  },
  loading: false,
  error: null,
};

// - Reducers
const reducer = handleActions({
  [manageStationListRequest]: (state) => ({
    ...state,
    loading: true,
  }),
  [manageStationListSuccess]: (state, { payload: { manageStations, pagination } }) => ({
    ...state,
    manageStations: manageStations,
    pagination: pagination,
    loading: false,
  }),

  // Loại bưu cục
  [stationTypeListRequest]: (state) => ({
    ...state,
    loading: true,
  }),
  [stationTypeListSuccess]: (state, { payload: { stationTypes } }) => ({
    ...state,
    stationTypes: stationTypes,
    loading: false,
  }),

  // Danh sách tất cả bưu cục
  [stationAllRequest]: (state) => ({
    ...state,
    loading: true,
  }),
  [stationAllSuccess]: (state, { payload: { stationAll } }) => ({
    ...state,
    stationAll: stationAll,
    loading: false,
  }),

  // Nhân viên
  [employeesListRequest]: (state) => ({
    ...state,
    loading: true,
  }),
  [employeesListSuccess]: (state, { payload: { employees } }) => ({
    ...state,
    employees: employees,
    loading: false,
  }),

  // Nhân viên trong bưu cục
  [employeesInStationListRequest]: (state) => ({
    ...state,
    loading: true,
  }),
  [employeesInStationListSuccess]: (state, { payload: { employeesInStation } }) => ({
    ...state,
    employeesInStation: employeesInStation,
    loading: false,
  }),

  [failure]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error,
  }),
}, initialState);

export default reducer;
