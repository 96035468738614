import React from 'react';
import { Button, Modal, Form, notification, Tabs, Table } from 'antd';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Icons } from '../../../assets';
import { SaveOutlined, MenuUnfoldOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { lifecycle, compose as recompose, withHandlers, withProps, withState } from 'recompose';
import { Field, reduxForm } from 'redux-form';
import { required } from '../../../helpers/validate';
import { FieldInput, FieldTextArea, FieldSelect } from '../../../components/Fields';
import { MANAGESTATION_FORM_KEY, StationManagementFormAction } from '../../../reduxs/manageStation-redux/action';
import { getProvincesRequest, getDistrictsRequest, getWardsRequest } from '../../../reduxs/utility-redux/reducer';
import { selectProvinces, selectDistricts, selectWards } from '../../../reduxs/utility-redux/selectors';
import { selectSationType, selectEmployee, selectManageStation, selectEmployeeInStation, selectSationAll } from '../../../reduxs/manageStation-redux/selectors';
import { stationTypeListRequest, manageStationListRequest, employeesListRequest, employeesInStationListRequest, stationAllRequest } from '../../../reduxs/manageStation-redux/reducer';
import { departmentListRequest } from '../../../reduxs/department-redux/reducer';
import { selectDepartment } from '../../../reduxs/department-redux/selectors';

import StaffForm from './StaffStationForm';


const ManageStationForm = (props) => {
  const { open, close, reset, StationCode, tabs, setTabs, handleSubmit, submitting, pristine, provinces, districts, wards, stationTypes, manageStation, stationAll, employeesInStations } = props;
  const { fetchDistricts, fetchWards, fetchEmployees } = props;
  const { TabPane } = Tabs;
  const submit = handleSubmit(StationManagementFormAction);

  // Danh sách bưu cục
  let _stations = [];
  if (stationAll && stationAll.length) {
    _stations = stationAll.map((stationAll, key) => {
      return {
        value: stationAll.STATION_CODE,
        text: stationAll.STATION_CODE + " - " + stationAll.NAME
      }
    });
  }


  // Danh sách loại bưu cục
  let _stationTypes = [];
  if (stationTypes && stationTypes.length) {
    _stationTypes = stationTypes.map((stationTypes, key) => {
      return {
        value: stationTypes.Id,
        text: stationTypes.Name
      }
    });
  }

  // Danh sách Tỉnh/Thành phố
  let _provinces = [];
  if (provinces && provinces.length) {
    _provinces = provinces.map((item, key) => {
      return {
        value: item.Id,
        text: item.Name
      }
    });
  }

  // Danh sách Quận/Huyện
  let _districts = [];
  if (districts && districts.length) {
    _districts = districts.map((item, key) => {
      return {
        value: item.Id,
        text: item.Name
      }
    });
  }

  // Danh sách Xã/Phường
  let _wards = [];
  if (wards && wards.length) {
    _wards = wards.map((item, key) => {
      return {
        value: item.Id,
        text: item.Name
      }
    });
  }

  return (
    <Modal
      title={manageStation ? 'Cập nhật bưu cục' : 'Thêm bưu cục'}
      visible={open}
      style={{ top: 50 }}
      width={'1000px'}
      footer={null}
      maskClosable={false}
      onOk={() => { reset(); close(false) }}
      onCancel={() => { reset(); close(false) }}
      className="modal-form-manage-station"
    >
      <Tabs activeKey={tabs || '1'} onChange={(key) => setTabs(key)} size="small">
        <TabPane tab={<><MenuUnfoldOutlined />Thông tin bưu cục</>} key="1">
          <Form layout="vertical" onFinish={submit}>
            <div className="box-group">
              <div className="box-title">
                <h3 className="title-group">
                  <img src={Icons.common.postType} alt="Loại bưu cục" />
                  <label className="bold">Loại bưu cục</label>
                </h3>
              </div>
              <div className="box-body">
                <div className="row">
                  <div className="col-md-6">
                    <Field
                      component={FieldSelect}
                      label="Bưu cục cha"
                      name="ParentStationCode"
                      placeholder="Bưu cục cha"
                      showSearch
                      filterOption={(input, option) =>
                        option.children[1].toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      options={_stations}
                    />
                  </div>
                  <div className="col-md-6">
                    <Field
                      component={FieldSelect}
                      label="Loại bưu cục"
                      name="StationTypeId"
                      placeholder="Loại bưu cục"
                      options={_stationTypes}
                      className="required"
                      validate={[required]}
                      showSearch
                      filterOption={(input, option) =>
                        option.children[1].toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="box-group">
              <div className="box-title">
                <h3 className="title-group">
                  <img src={Icons.common.postInformation} alt="Thông tin bưu cục" />
                  <label className="bold">Thông tin bưu cục</label>
                </h3>
              </div>
              <div className="box-body">
                <div className="row">
                  <div className="col-md-6">
                    <Field
                      component={FieldInput}
                      label="Mã bưu cục"
                      name="StationCode"
                      placeholder="Mã bưu cục"
                      className="required"
                      validate={[required]}
                    />
                  </div>
                  <div className="col-md-6">
                    <Field
                      component={FieldInput}
                      label="Tên bưu cục"
                      name="FullName"
                      placeholder="Tên bưu cục"
                      className="required"
                      validate={[required]}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <Field
                      component={FieldTextArea}
                      label="Mô tả"
                      name="Remark"
                      placeholder="Mô tả"
                      rows="2"
                      className="required"
                      validate={[required]}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="box-group">
              <div className="box-title">
                <h3 className="title-group">
                  <img src={Icons.common.postAddress} alt="Địa chỉ" />
                  <label className="bold">Địa chỉ</label>
                </h3>
              </div>
              <div className="box-body">
                <div className="row">
                  <div className="col-md-4">
                    <Field
                      component={FieldSelect}
                      label="Tỉnh/Thành phố"
                      name="ProvinceId"
                      placeholder="Tỉnh/Thành phố"
                      options={_provinces}
                      className="required"
                      onChange={(value) => fetchDistricts(value)}
                      validate={[required]}
                      showSearch
                      filterOption={(input, option) =>
                        option.children[1].toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    />
                  </div>
                  <div className="col-md-4">
                    <Field
                      component={FieldSelect}
                      label="Quận/Huyện"
                      name="DistrictId"
                      options={_districts}
                      placeholder="Quận huyện"
                      className="required"
                      onChange={(value) => fetchWards(value)}
                      validate={[required]}
                      showSearch
                      filterOption={(input, option) =>
                        option.children[1].toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    />
                  </div>
                  <div className="col-md-4">
                    <Field
                      component={FieldSelect}
                      label="Xã/Phường"
                      name="WardId"
                      placeholder="Xã/Phường"
                      options={_wards}
                      className="required"
                      validate={[required]}
                      showSearch
                      filterOption={(input, option) =>
                        option.children[1].toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <Field
                      component={FieldTextArea}
                      label="Địa chỉ"
                      name="Address"
                      rows="2"
                      placeholder="Địa chỉ"
                      className="required"
                      validate={[required]}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="box-group">
              <div className="box-title">
                <h3 className="title-group">
                  <img src={Icons.common.postContact} alt="Liên hệ" />
                  <label className="bold">Liên hệ</label>
                </h3>
              </div>
              <div className="box-body">
                <div className="row">
                  <div className="col-md-6">
                    <Field
                      component={FieldInput}
                      label="Số điện thoại"
                      name="PhoneNumber"
                      placeholder="Số điện thoại"
                      className="required"
                      validate={[required]}
                    />
                  </div>
                  <div className="col-md-6">
                    <Field
                      component={FieldInput}
                      label="Email"
                      name="Email"
                      placeholder="Email"
                      className="required"
                      validate={[required]}
                    />
                  </div>
                </div>
              </div>
            </div>
            <Form.Item className="text-right">
              <Button type="default" onClick={() => { reset(); close(false) }}>Hủy</Button>&ensp;&ensp;
              <Button loading={submitting} disabled={pristine} type="primary" icon={<SaveOutlined />} htmlType="submit">Lưu</Button>
            </Form.Item>
          </Form>
        </TabPane>
        <TabPane tab={<><UsergroupAddOutlined />Nhân sự</>} key="2">
          <div className="box-group">
            {(manageStation || StationCode) && <StaffForm station={manageStation} StationCode={StationCode} fetchEmployees={fetchEmployees} />}
          </div>
          <div className="box-group">
            <div className="box-body">
              <div className="row">
                <div className="col-md-12">
                  <Table
                    className="table-main"
                    columns={columEmployeeList(props)}
                    rowKey="Id"
                    size="small"
                    dataSource={employeesInStations}
                    bordered={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </TabPane>
      </Tabs>
    </Modal>
  );
};

export function mapDispatchToProps(dispatch) {
  return {
    // Danh sách tất cả phòng ban
    getStationAll: (params) => dispatch(stationAllRequest(params)),
    // Nhân viên
    getEmployees: (params) => dispatch(employeesListRequest(params)),
    // Phòng ban
    getDepartments: (params) => dispatch(departmentListRequest(params)),
    // Tỉnh/Thành phố
    getProvinces: (params) => dispatch(getProvincesRequest(params)),
    // Quận/Huyện
    getDistricts: (params) => dispatch(getDistrictsRequest(params)),
    // Xã/Phường
    getWards: (params) => dispatch(getWardsRequest(params)),
    // Loại bưu cục
    getStationTypes: (params) => dispatch(stationTypeListRequest(params)),
    // Bưu cục
    getStations: (params) => dispatch(manageStationListRequest(params)),
    // Danh sách nhân viên trong bưu cục
    searchEmployeesInStation: (params) => dispatch(employeesInStationListRequest(params))
  };
}

const mapStateToProps = createStructuredSelector({
  // Nhân viên
  stationAll: selectSationAll(),
  // Nhân viên
  employees: selectEmployee(),
  // Phòng ban
  departments: selectDepartment(),
  // Tỉnh/Thành phố
  provinces: selectProvinces(),
  // Quận/Huyện
  districts: selectDistricts(),
  // Xã/Phường
  wards: selectWards(),
  // Loại bưu cục
  stationTypes: selectSationType(),
  // Bưu cục
  stations: selectManageStation(),
  // Danh sách nhân viên trong bưu cục
  employeesInStations: selectEmployeeInStation()
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default recompose(
  withConnect,
  withProps(({ manageStation }) => {
    if (manageStation) {
      // Map data
      const d = {
        UpdateStation: true,
        StationCode: manageStation.STATION_CODE,
        ShortName: manageStation.NAME,
        FullName: manageStation.NAME,
        PhoneNumber: manageStation.TEL,
        Fax: manageStation.FAX,
        TaxCode: manageStation.TAX_CODE,
        Email: manageStation.EMAIL,
        ProvinceId: manageStation.PROVINCE_ID,
        DistrictId: manageStation.DISTRICT_ID,
        WardId: manageStation.WARD_ID,
        Address: manageStation.ADD1,
        ParentStationCode: manageStation.PARENT_STATION_CODE,
        Coordinate: manageStation.COORDINATE,
        Remark: manageStation.REMARK,
        StationTypeId: manageStation.STATION_TYPE_ID
      };
      return { initialValues: { ...d } };
    } else {
      return { initialValues: null };
    }
  }
  ),
  withState('employeesInStation', 'setEmployeesInStation', null),
  withState('StationCode', 'setStationCode', null),
  withState('department', 'setDepartment', null),
  withState('employee', 'setEmployee', null),
  withState('province', 'setProvince', null),
  withState('stationType', 'setStationType', null),
  withState('district', 'setDistrict', null),
  withState('ward', 'setWard', null),
  withState('tabs', 'setTabs', '1'),
  withHandlers({
    fetchDistricts: ({ setProvince, getDistricts }) => (id) => {
      setProvince(id);
      getDistricts({ CountryId: 234, CityId: id });
    },
    fetchEmployees: ({ manageStation, searchEmployeesInStation, StationCode }) => (id) => {
      if (manageStation && manageStation.STATION_CODE) {
        searchEmployeesInStation({ station: manageStation.STATION_CODE });
      } else if (StationCode) {
        searchEmployeesInStation({ station: StationCode });
      } else {
        searchEmployeesInStation({ station: null });
      }
    },
    fetchWards: ({ province, setDistrict, getWards }) => (id, provinceId) => {
      setDistrict(id);
      console.log("------------------> id tinh thanh", provinceId);
      console.log("------------------> id quan huyen", id);
      getWards({ CountryId: 234, CityId: province || provinceId, DistrictId: id });
    }
  }),
  reduxForm({
    form: MANAGESTATION_FORM_KEY,
    enableReinitialize: true,
    onSubmitSuccess: (result, dispatch, props) => {
      notification.success({
        message: 'Lưu thành công!'
      });
      if ((!result.UpdateStation)) {
        props.setTabs('2');
        props.setStationCode(result.StationCode);
      }
      else {
        props.close(false);
      }
      props.fetchManageStations();
    }
  }),
  lifecycle({
    componentDidMount() {
      const { manageStation, fetchDistricts, fetchWards } = this.props;
      this.props.getProvinces({ CountryId: 234 });
      this.props.getStationTypes();
      this.props.getStationAll();
      this.props.getEmployees();
      this.props.getDepartments({ Size: 100, Offset: 1 });
      this.props.fetchEmployees();
      if (manageStation && manageStation.STATION_CODE) {
        if (manageStation && manageStation.STATION_CODE) {
          fetchDistricts(manageStation.PROVINCE_ID);
          fetchWards(manageStation.DISTRICT_ID, manageStation.PROVINCE_ID);
        }
      }
    },
  }),
)(ManageStationForm);

// Bảng danh sách nhân viên trong bưu cục
const columEmployeeList = () => {
  const colums = [
    {
      title: 'STT', dataIndex: '', key: 'Id', width: 60, className: "text-center", render: (i, j, k) => {
        return k + 1;
      }
    },
    { title: 'Mã nhân viên', dataIndex: 'StaffCode', key: 'StaffCode' },
    { title: 'Tên nhân viên', dataIndex: 'StaffName', key: 'StaffName' },
    { title: 'Phòng ban', dataIndex: 'DepartmentName', key: 'DepartmentName' },
    {
      title: 'Chức vụ', dataIndex: '', render: (item) => {
        if (item.IsManager) {
          return "Trưởng phòng";
        }
        else {
          return "Nhân viên";
        }
      }
    },
    { title: 'Số điện thoại', dataIndex: 'StaffTelMobile', key: 'StaffTelMobile' },
    { title: 'Email', dataIndex: 'StaffEmail', key: 'StaffEmail' }
  ]
  return colums;
}