import { createAction, handleActions } from 'redux-actions';
// - Actions
export const getStationRequest = createAction('STATION_LIST_REQUEST');
export const getStationSuccess = createAction('STATION_LIST_SUCCESS');

export const openStationRequest = createAction('OPEN_STATION_REQUEST');
export const openStationSuccess = createAction('OPEN_STATION_SUCCESS');

export const setStationSuccess = createAction('SET_STATION_SUCCESS');

export const failure = createAction('STATION_ACTION_FAILURE');


// - Initial State
export const initialState = {
  stations: [],
  searching: false,

  openStation: false,

  station: null,

  active: false,

  error: null,
};

// - Reducers
const reducer = handleActions({
  [getStationRequest]: (state) => {
    return {
      ...state,
      stations: [],
      searching: true,
    };
  },

  [getStationSuccess]: (state, { payload: { stations } }) => {
    return {
      ...state,
      stations: stations,
      searching: false,
    };
  },


  [openStationRequest]: (state, { payload }) => {
    return {
      ...state,
      openStation: !!payload
    };
  },

  [setStationSuccess]: (state, { payload: { station } }) => {
    return {
      ...state,
      station: station,
    };
  },
  
  [failure]: (state, action) => ({
    ...state,
    error: action.error,
    searching: false,
  }),
}, initialState);

export default reducer;
