
import axios from '../utils/axiosInstance';
import FileSaver from 'file-saver';

export const autoCompleteCustomer = async (params = {}) => {
  try {
    const { data } = await axios.get('/crm/autocomplete/customer-by-station', { params });
    return data;
  } catch (error) {
    throw error;
  }
};


export const getServiceType = async (params = {}) => {
  try {
    if (params.IsAll) {
      const { data } = await axios.get('/common/service-type-all', { params: params });
      return data;
    } else {
      const { data } = await axios.get('/common/service-type', { params: params });
      return data;
    }
  } catch (error) {
    throw error;
  }
};

export const getExtraServiceType = async (params = {}) => {
  try {
    const { data } = await axios.get('/common/extra-service-type');
    return data;
  } catch (error) {
    throw error;
  }
};

export const getCurrency = async (params = {}) => {
  try {
    const { data } = await axios.get('/common/currency', { params });
    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchAllStations = async (params = {}) => {
  try {
    const { data } = await axios.get('/ops-station/list-station-ops');
    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchMyStations = async (params = {}) => {
  try {
    const { data } = await axios.get('/crm/station/my-station');
    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchMyStaffs = async (params = {}) => {
  try {
    const { data } = await axios.get('/ops-staff/list-staff-by', { params });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getStationDepartment = async (params = {}) => {
  try {
    const { data } = await axios.get("/api/staff/staff-with-department", {
      params,
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchStaffAlls = async (params = {}) => {
  try {
    const { data } = await axios.get('/admin/staff-for-autocomplete', { params });
    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchCalculater = async (params = {}) => {
  try {
    const { data } = await axios.get('/fin-freight-calculation/calculator', { params });
    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchCalculaterExtra = async (params = {}) => {
  try {
    let str = `FromZoneCode=${params.ZoneCodeProvinceReceived}&ToZoneCode=${params.ZoneCodeProvinceSend}&COD=${params.COD}`
    if (params.services && params.services.length) {
      params.services.forEach(id => {
        str += `&ExtraService=${id}`
      });
      const { data } = await axios.get(`/bill/calc-price-extra-service?${str}`);
      return data;
    } else {
      return { Result: 0 }
    }

  } catch (error) {
    throw error;
  }
};

export const fetchBatch = async () => {
  try {
    const { data } = await axios.get('/api/util/gen-code?CodeType=QUICK_CHECK_IN');
    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchBillCheckin = async (params) => {
  try {
    const { data } = await axios.get('/api/op/check-in/check-bill', { params });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getValueModes = async (params) => {
  try {
    const { data } = await axios.get(`/api/volmode`, {
      params: params
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const downloadRevenueComparison = async (params) => {
  try {
    const response = await axios.get('/api/revenue/compare-data/download', { params, responseType: 'blob' });
    const { data } = response;
    const namefile = response.headers['pcs-filename-suggestion'];
    const fileType = response.headers['content-type'];
    var blob = new Blob([data], { type: fileType });
    FileSaver.saveAs(blob, namefile);
    return true;
  } catch (error) {
    throw error;
  }
};

export const getDepartments = async (params = {}) => {
  try {
    const { data } = await axios.get('/department/list-department', { params });
    return data;
  } catch (error) {
    throw error;
  }
};

export const findUsers = async (params = {}) => {
  try {
    const { data } = await axios.get('/api/admin/search-user', { params });
    return data;
  } catch (error) {
    throw error;
  }
};

export const findStaffByStation = async (params = {}) => {
  try {
    const { data } = await axios.get('/api/staff/find', { params });
    return data;
  } catch (error) {
    throw error;
  }
};