import axios from '../utils/axiosInstance';

export const getErrorLogs = async (params = {}) => {
    try {
        const {
            data
        } = await axios.get('/api/admin/system-error', { params });
        return data;
    } catch (error) {
        throw error;
    }
};

export const getSqlCommandLogs = async (params = {}) => {
    try {
        const {
            data
        } = await axios.get('/api/sql-history', { params });
        return data;
    } catch (error) {
        throw error;
    }
};