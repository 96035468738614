
import axios from '../utils/axiosInstance';
import FileSaver from 'file-saver';

export const fetchCustomersCommission = async (params) => {
  try {
    const { data } = await axios.get(`/api/customer-commission`, {
      params: params
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getCustomerCommission = async (params) => {
  try {
    const { data } = await axios.get(`/api/customer-commission/details`, {
      params: params
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getCustomerPrices = async (params) => {
  try {
    const { data } = await axios.get(`/api/price-list/by-service-code`, {
      params: params
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getWaitPrices = async (params) => {
  try {
    const { data } = await axios.get(`/api/price-list/my-pending-approval`, {
      params: params
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getHistoryPrices = async (params) => {
  try {
    const { data } = await axios.get(`/api/price-list/my-upload`, {
      params: params
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getMyPrices = async (params) => {
  try {
    const { data } = await axios.get(`/api/price-list/available-for-discount-configuration`, {
      params: params
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getPricesCallback = async (params) => {
  try {
    const { data } = await axios.get(`/api/price-list/available-for-discount-configuration`, {
      params: params
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchApproveCommission = async (params) => {
  try {
    const { data } = await axios.get(`/api/customer-commission/waiting-for-approval-by-me`, {
      params: params
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getStaffCommission = async (params) => {
  try {
    const { data } = await axios.get(`/api/staff-commission/details`, {
      params: params
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getTemplePices = async (params) => {
  try {
    const { data } = await axios.get(`/api/price-list/avalable-template`, {
      params: params
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getValueModes = async (params) => {
  try {
    const { data } = await axios.get(`/api/volmode`, {
      params: params
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const saveCommission = async body => {
  try {
    const { data } = await axios.post('/api/customer-commission/details', body);
    return data;
  } catch (error) {
    throw error;
  }
};

export const activeCommission = async body => {
  try {
    const { data } = await axios.put('/api/customer-commission/details/deactive', body);
    return data;
  } catch (error) {
    throw error;
  }
};

export const approveCommission = async body => {
  try {
    const { data } = await axios.put('/api/customer-commission/approve', body);
    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteCommission = async (body) => {
  try {
    const { data } = await axios.delete(`/api/customer-commission/details?Id=${body.Id}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const confirmPriceUpload = async body => {
  try {
    const { data } = await axios.post('/api/price-list/approve', body);
    return data;
  } catch (error) {
    throw error;
  }
};

export const changeAssignPice = async body => {
  try {
    const { data } = await axios.post('/api/price-list/assign-approval', body);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getDetailPrice = async params => {
  try {
    const { data } = await axios.get('/api/price-list/by-id', {params})
    return data;
  } catch (error) {
    throw error;
  }
};

export const downloadTemplate = async (params) => {
  try {
    const response = await axios.get('/api/price-list/download-template', { params,  responseType: 'blob' });
    const { data } = response;
    const namefile = response.headers['pcs-filename-suggestion'];
    const fileType = response.headers['content-type'];
    var blob = new Blob([data], {type: fileType});
    FileSaver.saveAs(blob, namefile);
    return true;
  } catch (error) {
    throw error;
  }
}

export const uploadPrice = async (params) => {
  try {
    const body = new FormData()
    body.append('File', params.File)
    body.append('ServiceCode', params.ServiceCode)
    body.append('AppliedDate', params.AppliedDate)
    body.append('ExpiredDate', params.ExpiredDate || '')
    body.append('WeightUnit', params.WeightUnit)
    body.append('IsPublic', !!params.IsPublic)
    body.append('IsOrigin', !!params.IsOrigin)
    body.append('VAT', params.VAT || '')
    body.append('VolModeId', params.VolModeId || '')
    body.append('ApprovalStaff', params.ApprovalStaff || '')
    body.append('PriceListName', params.PriceListName || '')
    if(params.DiscountInfo) {
      body.append('DiscountInfo.AccountNo', params.DiscountInfo.AccountNo || '')
      body.append('DiscountInfo.Discount', params.DiscountInfo.Discount || '')
      body.append('DiscountInfo.Commission', params.DiscountInfo.Commission || '')
      body.append('DiscountInfo.CommissionType', params.DiscountInfo.CommissionType || '')

    }
    const { data } = await axios.post('/api/price-list/upload', body);
    return data;
  } catch (error) {
    throw error;
  }
};

export const uploadPriceDirectCustomer = async (params) => {
  try {
    const body = new FormData()
    body.append('File', params.File)
    body.append('ServiceCode', params.ServiceCode)
    body.append('AppliedDate', params.AppliedDate)
    body.append('ExpiredDate', params.ExpiredDate || '')
    body.append('WeightUnit', params.WeightUnit)
    body.append('IsPublic', !!params.IsPublic)
    body.append('IsOrigin', !!params.IsOrigin)
    body.append('VAT', params.VAT || '')
    body.append('VolModeId', params.VolModeId || '')
    body.append('ApprovalStaff', params.ApprovalStaff || '')
    body.append('AccountNo', params.AccountNo || '')
    body.append('PriceListName', params.PriceListName || '')

    const { data } = await axios.post('/api/price-list/upload-direct-customer', body);
    return data;
  } catch (error) {
    throw error;
  }
};

export const previewPrice = async (params) => {
  try {
    const body = new FormData()
    body.append('File', params.File)
    body.append('ServiceCode', params.ServiceCode)
    body.append('AppliedDate', params.AppliedDate)
    body.append('ExpiredDate', params.ExpiredDate || '')
    body.append('WeightUnit', params.WeightUnit)
    body.append('IsPublic', !!params.IsPublic)
    body.append('IsOrigin', !!params.IsOrigin)
    body.append('VAT', params.VAT || '')
    body.append('ApprovalStaff', params.ApprovalStaff || '')
    if(params.DiscountInfo) {
      body.append('DiscountInfo.AccountNo', params.DiscountInfo.AccountNo || '')
      body.append('DiscountInfo.Discount', params.DiscountInfo.Discount || '')
      body.append('DiscountInfo.Commission', params.DiscountInfo.Commission || '')
      body.append('DiscountInfo.CommissionType', params.DiscountInfo.CommissionType || '')

    }
    const { data } = await axios.post('/api/price-list/upload/preview', body);
    return data;
  } catch (error) {
    throw error;
  }
};
