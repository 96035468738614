import React, { useState, useEffect } from 'react'
import { Modal, Checkbox, Button, message } from 'antd'
import { SaveOutlined } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import { updateCustomerByPropRequest } from '../../../reduxs/account-redux/reducer'
import './style.scss'

export default ({ visible, onCancel, onOk, info, onSuccess }) => {
    const dispatch = useDispatch()

    const [params, setParams] = useState({})
    const [updating, setUpdating] = useState(false)

    useEffect(() => {
        setParams({ ...params, IsDeliveryStaffAsPuStaff: info?.IsDeliveryStaffAsPuStaff, IsWeightReal: info?.IsWeightReal })
        // eslint-disable-next-line
    }, [info])

    const onUpdate = () => {
        setUpdating(true)
        dispatch(updateCustomerByPropRequest({
            params: {
                Props: params,
                Id: info.Id
            },
            cb: (isOk) => {
                setUpdating(false)
                if (!isOk) {
                    message.error('Đã xảy ra lỗi')
                } else {
                    message.success('Cập nhật thành công')
                    onSuccess && onSuccess()
                }
            }
        }))
    }

    return (
        <>
            <Modal
                title='Cài đặt khác'
                footer={null}
                visible={visible}
                onCancel={onCancel}
                onOk={onOk}
            >
                <div className='row'>
                    <div className='col-lg-12'>
                        <div style={{ marginBottom: 10 }}>
                            <Checkbox checked={params.IsDeliveryStaffAsPuStaff} onChange={e => setParams({ ...params, IsDeliveryStaffAsPuStaff: e.target.checked })} >Lưu nhân viên phát cuối cùng làm nhân viên lấy hàng lần kế tiếp</Checkbox>
                        </div>
                        <div style={{ marginBottom: 10 }}>
                            <Checkbox checked={params.IsWeightReal} onChange={e => setParams({ ...params, IsWeightReal: e.target.checked })} >Chỉ tính trọng lượng thực</Checkbox>
                        </div>
                    </div>
                </div>

                <hr />
                <div className='row'>
                    <div className='col-lg-12 text-right'>
                        <Button loading={updating} onClick={onUpdate} icon={<SaveOutlined />} type='primary'>Lưu</Button>
                    </div>
                </div>
            </Modal>
        </>
    )
}