import { call, put, takeLatest } from 'redux-saga/effects';
import * as RequestHistoryService from '../../services/request-history-service';

import {
  getRequestHistoriesSuccess,
  getRequestHistoryGroupSuccess,
  failure
} from './reducer';


export function* getRequestHistories({ payload }) {
  try {
    const { Result } = yield call(RequestHistoryService.fetchRequestHistories, payload);
    if(Result) {
        const { Data, Pagination } = Result;
        yield put(getRequestHistoriesSuccess({ histories: Data, pagination: { Total: Pagination.Total, Page: Pagination.Offset, Size: Pagination.Size } }));
    }
  } catch (error) {
    yield put(failure(error));
  }
}

export function* getRequestHistoryGroupByPath({ payload }) {
  try {
    const { Result } = yield call(RequestHistoryService.fetchRequestHistoryGroupByPath, payload);
    if(Result) {
        yield put(getRequestHistoryGroupSuccess({ histories: Result }));
    }
  } catch (error) {
    yield put(failure(error));
  }
}


export default [
    takeLatest('GET_REQUEST_HISTORIES_REQUEST', getRequestHistories),
    takeLatest('GET_REQUEST_HISTORY_GROUP_REQUEST', getRequestHistoryGroupByPath)
];
  