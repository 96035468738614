import { createSelector } from 'reselect';
import { initialState } from './reducer';

const exportFuelState = (state) => state.exportFuel || initialState;

const selectSurcharges = () => createSelector(
  exportFuelState,
  ({ surcharges }) => surcharges
);
const selectLoading = () => createSelector(
  exportFuelState,
  ({ loading }) => loading
);
const selectPagination = () => createSelector(
  exportFuelState,
  ({ pagination }) => pagination
);

export {
  exportFuelState,
  selectSurcharges,
  selectLoading,
  selectPagination,
}
