import { call, put, takeLatest } from 'redux-saga/effects';
import * as CommissionService from '../../services/commision-service';
import { CommissionFormAction, UploadFormAction } from "./action";
import { SubmissionError } from "redux-form";
import moment from 'moment';

import {
  fetchCustomersCommissionSuccess,
  getCustomerCommissionSuccess,
  getWaitsPricesSuccess,
  getValueModesSuccess,
  getCustomerPricesSuccess,
  getHistoryPricesSuccess,
  getMyPricesSuccess,
  getStaffCommissionSuccess,
  getTemplePricesSuccess,
  fetchApproveCommissionSuccess
} from './reducer';

//Commission
export function* fetchCustomersCommission({ payload = {} }) {
  try {
    const { Result } = yield call(CommissionService.fetchCustomersCommission, payload);
    if (Result) {
      const { Data, Pagination } = Result;
      yield put(fetchCustomersCommissionSuccess({ commissions: Data, pagination: Pagination }));
    } else {
      yield put(fetchCustomersCommissionSuccess({ commissions: [], pagination: { Total: 0, Size: 0, Offset: 0 } }));
    }
  } catch (error) {
    yield put(fetchCustomersCommissionSuccess({ commissions: [], pagination: { Total: 0, Size: 0, Offset: 0 } }));
  }
}

export function* getCustomerCommission({ payload = {} }) {
  try {
    const { Result } = yield call(CommissionService.getCustomerCommission, payload);
    if (Result) {
      yield put(getCustomerCommissionSuccess({ commission: Result }));
    } else {
      yield put(getCustomerCommissionSuccess({ commission: null }));
    }
  } catch (error) {
    yield put(getCustomerCommissionSuccess({ commission: null }));
  }
}

export function* getCustomerPrices({ payload = {} }) {
  try {
    const { Result } = yield call(CommissionService.getCustomerPrices, payload);
    if (Result) {
      yield put(getCustomerPricesSuccess({ prices: Result }));
    } else {
      yield put(getCustomerPricesSuccess({ prices: null }));
    }
  } catch (error) {
    yield put(getCustomerPricesSuccess({ prices: null }));
  }
}

export function* getWaitPrices({ payload = {} }) {
  try {
    const { Result } = yield call(CommissionService.getWaitPrices, payload);
    if (Result) {
      yield put(getWaitsPricesSuccess({ prices: Result }));
    } else {
      yield put(getWaitsPricesSuccess({ prices: null }));
    }
  } catch (error) {
    yield put(getWaitsPricesSuccess({ prices: null }));
  }
}

export function* getHistoryPrices({ payload = {} }) {
  try {
    const { Result } = yield call(CommissionService.getHistoryPrices, payload);
    if (Result) {
      yield put(getHistoryPricesSuccess({ prices: Result }));
    } else {
      yield put(getHistoryPricesSuccess({ prices: null }));
    }
  } catch (error) {
    yield put(getHistoryPricesSuccess({ prices: null }));
  }
}

export function* getMyPrices({ payload = {} }) {
  try {
    const { Result } = yield call(CommissionService.getMyPrices, payload);
    if (Result) {
      yield put(getMyPricesSuccess({ prices: Result }));
    } else {
      yield put(getMyPricesSuccess({ prices: null }));
    }
  } catch (error) {
    yield put(getMyPricesSuccess({ prices: null }));
  }
}

export function* getStaffCommission({ payload = {} }) {
  try {
    const { Result } = yield call(CommissionService.getStaffCommission, payload);
    if (Result) {
      yield put(getStaffCommissionSuccess({ commission: Result }));
    } else {
      yield put(getStaffCommissionSuccess({ commission: null }));
    }
  } catch (error) {
    yield put(getStaffCommissionSuccess({ commission: null }));
  }
}

export function* getTemplePices({ payload = {} }) {
  try {
    const { Result } = yield call(CommissionService.getTemplePices, payload);
    if (Result) {
      yield put(getTemplePricesSuccess({ temples: Result }));
    } else {
      yield put(getTemplePricesSuccess({ temples: [] }));
    }
  } catch (error) {
    yield put(getTemplePricesSuccess({ temples: [] }));
  }
}

export function* getValueModes({ payload = {} }) {
  try {
    const { Result } = yield call(CommissionService.getValueModes, payload);
    if (Result) {
      yield put(getValueModesSuccess({ modes: Result }));
    } else {
      yield put(getValueModesSuccess({ modes: null }));
    }
  } catch (error) {
    yield put(getValueModesSuccess({ modes: null }));
  }
}

export function* fetchApproveCommission({ payload = {} }) {
  try {
    const { Result } = yield call(CommissionService.fetchApproveCommission, payload);
    if (Result) {
      // const { Data, Pagination } = Result;
      yield put(fetchApproveCommissionSuccess({ approves: Result, pagination: { Total: 0, Size: 0, Offset: 0 } }));
    } else {
      yield put(fetchApproveCommissionSuccess({ approves: [], pagination: { Total: 0, Size: 0, Offset: 0 } }));
    }
  } catch (error) {
    yield put(fetchApproveCommissionSuccess({ approves: [], pagination: { Total: 0, Size: 0, Offset: 0 } }));
  }
}

export function* saveComminssion({ payload }) {
  try {
    if(payload && payload.Details.length) {
      let AppliedDate = moment().format('YYYY-MM-DD');
      let ExpiredDate = null;
      if(payload.AppliedDate && moment(payload.AppliedDate, 'DD-MM-YYYY').isValid()) {
        AppliedDate = moment(payload.AppliedDate, 'DD-MM-YYYY').format('YYYY-MM-DD').toString();
      }
      if(payload.ExpiredDate && moment(payload.ExpiredDate,'DD-MM-YYYY').isValid()) {
        ExpiredDate = moment(payload.ExpiredDate, 'DD-MM-YYYY').format('YYYY-MM-DD').toString();
      }
      let details = [...payload.Details].map((item) => {
        item['AppliedDate'] = AppliedDate;
        item['ExpiredDate'] = ExpiredDate;
        return item;
      })
      const { Result } = yield call(CommissionService.saveCommission, { AccountNo: payload.AccountNo, Details: details });
      if (Result) {
        yield put(CommissionFormAction.success("Lưu thành công"));
      } else {
        throw new Error('Lưu lại thất bại!')
      }
    } else {
      throw new Error('Lưu chiết khấu thất bại!')
    }
  } catch (error) {
    let message = "Lưu lại thất bại!"
    if(error && error.Description && typeof error.Description === 'string') {
      message = error.Description
    }
    const formError = new SubmissionError({
      _error: message
    });
    yield put(CommissionFormAction.failure(formError));
  }
}

export function* getPriceCallback({ payload }) {
  const { data, cb } = payload;
  try {
    const { Result } = yield call(CommissionService.getPricesCallback, data);
    if (Result) {
      cb(true, Result)
    } else {
      cb(false)
    }
  } catch (error) {
    cb(false)
  }
}

export function* activeCommission({ payload }) {
  const { data, cb } = payload;
  try {
    const { Result } = yield call(CommissionService.activeCommission, data);
    if (Result) {
      cb(true)
    } else {
      cb(false)
    }
  } catch (error) {
    cb(false)
  }
}

export function* approveCommission({ payload }) {
  const { data, cb } = payload;
  try {
    const { Result } = yield call(CommissionService.approveCommission, data);
    if (Result) {
      cb(true)
    } else {
      cb(false)
    }
  } catch (error) {
    cb(false)
  }
}

export function* deleteCommission({ payload }) {
  const { data, cb } = payload;
  try {
    const { Result } = yield call(CommissionService.deleteCommission, data);
    if (Result) {
      cb(true)
    } else {
      cb(false)
    }
  } catch (error) {
    cb(false)
  }
}

export function* confirmUploadPice({ payload }) {
  const { data, cb } = payload;
  try {
    const { Result } = yield call(CommissionService.confirmPriceUpload, data);
    if (Result) {
      cb(true)
    } else {
      cb(false)
    }
  } catch (error) {
    cb(false)
  }
}

export function* getDetailPice({ payload }) {
  const { data, cb } = payload;
  try {
    const { Result } = yield call(CommissionService.getDetailPrice, data);
    if (Result) {
      cb(true, Result)
    } else {
      cb(false)
    }
  } catch (error) {
    cb(false)
  }
}

export function* changeAssignPice({ payload }) {
  const { data, cb } = payload;
  try {
    const { Result } = yield call(CommissionService.changeAssignPice, data);
    if (Result) {
      cb(true, Result)
    } else {
      cb(false)
    }
  } catch (error) {
    cb(false)
  }
}

export function* downloadTemplatePice({ payload }) {
  const { data, cb } = payload;
  try {
    const result = yield call(CommissionService.downloadTemplate, data);
    if (result) {
      cb(true)
    } else {
      cb(false)
    }
  } catch (error) {
    cb(false)
  }
}

export function* uploadPice({ payload }) {
  try {
    let params = { ...payload };
    const { Mode } = params;
    if (Mode === 'DIRECT_CUSTOMER') {
      params.AccountNo = params.DiscountInfo.AccountNo;
      delete params.DiscountInfo;
      delete params.Mode;
    }

    let AppliedDate = moment().format('YYYY-MM-DD');
    let ExpiredDate = null;
    if(params.AppliedDate && moment(params.AppliedDate, 'DD-MM-YYYY').isValid()) {
      AppliedDate = moment(params.AppliedDate, 'DD-MM-YYYY').format('YYYY-MM-DD').toString();
    }
    if(params.ExpiredDate && moment(params.ExpiredDate,'DD-MM-YYYY').isValid()) {
      ExpiredDate = moment(params.ExpiredDate, 'DD-MM-YYYY').format('YYYY-MM-DD').toString();
    }
    params['AppliedDate'] = AppliedDate;
    params['ExpiredDate'] = ExpiredDate;

    const { Result } = yield call(Mode === 'DIRECT_CUSTOMER' ? CommissionService.uploadPriceDirectCustomer : CommissionService.uploadPrice, params);
    if (Result) {
      yield put(UploadFormAction.success("Lưu thành công"));
    } else {
      throw new Error('Lưu lại thất bại!')
    }
  } catch (error) {
    let message = "Lưu lại thất bại!"
    if(error && error.Description && typeof error.Description === 'string') {
      message = error.Description
    }
    const formError = new SubmissionError({
      _error: message
    });
    yield put(UploadFormAction.failure(formError));
  }
}

export function* previewPice({ payload }) {
  const { data, cb } = payload;
  try {
    let AppliedDate = moment().format('YYYY-MM-DD');
    let ExpiredDate = null;
    if(data.AppliedDate && moment(data.AppliedDate, 'DD-MM-YYYY').isValid()) {
      AppliedDate = moment(data.AppliedDate, 'DD-MM-YYYY').format('YYYY-MM-DD').toString();
    }
    if(data.ExpiredDate && moment(data.ExpiredDate,'DD-MM-YYYY').isValid()) {
      ExpiredDate = moment(data.ExpiredDate, 'DD-MM-YYYY').format('YYYY-MM-DD').toString();
    }
    data['AppliedDate'] = AppliedDate;
    data['ExpiredDate'] = ExpiredDate;

    const { Result } = yield call(CommissionService.previewPrice, data);
    if (Result) {
      cb(true, Result)
    } else {
      cb(false)
    }
  } catch (error) {
    let message = "Lỗi xem trước!"
    if(error && error.Description && typeof error.Description === 'string') {
      message = error.Description
    }
    cb(false, message)
  }
}

export default [
  takeLatest(CommissionFormAction.REQUEST, saveComminssion),
  takeLatest(UploadFormAction.REQUEST, uploadPice),
  takeLatest('FETCH_CUSTOMERS_COMMISSION_REQUEST', fetchCustomersCommission),
  takeLatest('GET_CUSTOMER_COMMISSION_REQUEST', getCustomerCommission),
  takeLatest('GET_CUSTOMER_PRICES_REQUEST', getCustomerPrices),
  takeLatest('GET_WAITS_PRICES_REQUEST', getWaitPrices),
  takeLatest('GET_HISTORY_PRICES_REQUEST', getHistoryPrices),
  takeLatest('GET_MY_COM_PRICES_REQUEST', getMyPrices),
  takeLatest('GET_PRICES_CALLBACK_REQUEST', getPriceCallback),
  takeLatest('GET_STAFF_COMMISSION_REQUEST', getStaffCommission),
  takeLatest('GET_TEMPLE_PICES_REQUEST', getTemplePices),
  takeLatest('GET_VALUE_MODE_REQUEST', getValueModes),
  takeLatest('ACTIVE_COMMISION_REQUEST', activeCommission),
  takeLatest('APPROVE_COMMISION_REQUEST', approveCommission),
  takeLatest('DELETE_COMMISION_REQUEST', deleteCommission),
  takeLatest('FETCH_APPROVE_COMMISSION_REQUEST', fetchApproveCommission),
  takeLatest('GET_DETAIL_PRICE_REQUEST', getDetailPice),
  takeLatest('CHANGE_ASSIGN_PRICE_REQUEST', changeAssignPice),
  takeLatest('CONFIRM_PRICE_UPLOAD_REQUEST', confirmUploadPice),
  takeLatest('DOWNLOAD_PRICE_TEMPLATE_REQUEST', downloadTemplatePice),
  takeLatest('PREVIEW_UPLOAD_PRICE_REQUEST', previewPice),
];
