import './style.scss';
import React from 'react';
import moment from 'moment';
import 'pretty-print-json/dist/pretty-print-json.css'
import prettyPrintJson from 'pretty-print-json';
import { Layout, Table, Select, Pagination, Input } from 'antd';
import RestrictPage from './../../components/RestrictComponents/RestrictPage';
import { Icons } from '../../assets';

const { Content } = Layout;

const WebhookV = (props) => {
    const { isMobile, fetching, histories } = props;
    const { pagination, params, onFetchData } = props;

    const size = params.Size && Number(params.Size) > 0 ? Number(params.Size) : 25;
    const offset = params.Offset && Number(params.Offset) > 0 ? Number(params.Offset) : 1;
    const total = pagination && pagination.Total && Number(pagination.Total) > 0 ? Number(pagination.Total) : 0;
    const n_start = total > 0 ? (((offset - 1) * size) + 1) : 0;
    const n_end = total > 0 ? ((offset * size) > total ? total : offset * size) : 0;
    return (
        <RestrictPage>
            <Content className="page-menus">
                <div className="panel-header">
                    <div className="header-title">
                        <i className="fa fa-home icon" aria-hidden="true"></i>
                        <span>Hệ thống</span>
                        <span className="icon-next"><i className="fa fa-angle-right"></i></span>
                        <span className="active">Quản lý webhook</span>
                    </div>
                </div>
                <div className={isMobile ? "col-md-12 pd-0" : "col-md-12"}>
                    <div className="panel-body">
                        <div className="row">
                            <div className="col-md-4">
                                <label className="total-filter">
                                    <img src={Icons.common.filter} alt="Số bưu cục" />|&ensp;<span>{n_start} - {n_end}</span> trong <span>{total}</span>
                                </label>
                            </div>

                            <div className="col-md-8 text-right">
                                <span>
                                    <Input.Search loading={fetching} defaultValue={params.findKey || ''} placeholder="Tìm kiếm theo mã KH..." onSearch={(value) => onFetchData({ClientCode: value})} style={{maxWidth: isMobile ? '210px' : '240px'}}/>
                                </span>
                            </div>
                        </div>
                        <div className="row mr-t-10">
                            <div className="col-md-12">
                                <Table
                                    key="tab1"
                                    loading={fetching}
                                    className="table-histories"
                                    columns={createColumns(props)}
                                    rowKey="Id"
                                    size="small"
                                    dataSource={histories || []}
                                    pagination={false}
                                    bordered={true}
                                />
                            </div>
                        </div>

                        <div className="row mr-t-10">
                            <div className="col-md-6">
                                <span>Hiển thị &ensp;
                                    <Select value={size || 20} style={{ width: 120 }} onChange={(size) => onFetchData({ Size: size, Offset: 1 })}>
                                        <Select.Option value="25">25</Select.Option>
                                        <Select.Option value="50">50</Select.Option>
                                        <Select.Option value="100">100</Select.Option>
                                        <Select.Option value="200">200</Select.Option>
                                    </Select>
                                    &nbsp; bưu cục / trang
                                </span>
                            </div>
                            <div className="col-md-6 text-right">
                                <Pagination current={offset || 1} pageSize={size || 20} total={total || 0} showSizeChanger={false} onChange={(offset) => onFetchData({ Offset: offset })} />
                            </div>
                        </div>
                    </div>
                </div>
            </Content>
        </RestrictPage>
    );
};

export default WebhookV

const createColumns = (props) => {
    const colums = [
        { title: 'Mã KH', dataIndex: 'ClientCode', key: 'ClientCode' },
        { title: 'Kiểu webhook', key: 'Type', render: ({ Type }) => `${Type.Name}` },
        { title: 'Lịch sử', key: 'TimeHistories', render: ({ Url, Data, TimeHistories, MessageHistories, Type : { Type } }) => {
            return (<div>
                <p className="history-request"><span className={`webhook-method ${Type && Type.toLowerCase()}` }>{Type}</span><span className="endpoint">{Url}</span></p>
                <div>
                    <label>Payload</label>
                    <pre className="history-request-data"><output dangerouslySetInnerHTML={{ __html: Data ? prettyPrintJson.toHtml(JSON.parse(Data)) : prettyPrintJson.toHtml(null)}} /></pre>
                </div>
                <div>
                    <label>Response</label>
                    {
                        TimeHistories.map((history, index) => {
                            return (
                                <div key={index}>
                                    <p><span className="history-order">{`Lần ${(index + 1)} tại `}</span><span className="history-time">{moment(history).format('DD-MM-YYYY HH:mm:ss')}</span></p>
                                    <pre><output dangerouslySetInnerHTML={{ __html: MessageHistories[index] ? prettyPrintJson.toHtml(MessageHistories[index]) : prettyPrintJson.toHtml(null)}} /></pre>
                                </div>
                            )
                        })
                    }
                </div>
            </div>)
        }},
        { title: 'Kết quả cuối cùng', key: 'IsSuccess', render: ({ IsSuccess }) => IsSuccess ? <span className="success">Thành công</span> : <span className="unsuccess">Thất bại</span> },
    ]
    return colums;
}