import moment from 'moment';
import * as _ from 'lodash';
moment.locale('en')

const getWeekNumber = (date) => {
  let d = new Date(+date)
  d.setHours(0, 0, 0, 0)
  d.setDate(d.getDate() + 4 - (d.getDay() + 1 || 7))
  let yearStart = new Date(d.getFullYear(), 0, 1)
  let weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7)
  return weekNo
}

export const TitleWeek = (date) => {
  if (date && moment(date).isValid()) {
    date = moment(date)
    const weekNo = getWeekNumber(date._i)
    const beginningOfWeek = moment().week(weekNo).startOf('week').format('DD/MM/YYYY')
    const endOfWeek = moment().week(weekNo).startOf('week').add(6, 'days').format('DD/MM/YYYY')
    let title = `${beginningOfWeek} - ${endOfWeek}`
    return title
  }
  return ''
}

export const TitleDay = (date) => {
  if (date && moment(date).isValid()) {
    return moment(date).format('DD/MM/YYYY')
  }
  return ''
}

export const StartEndTime = (date, type = 'day') => {
  if (date && moment(date).isValid()) {
    date = moment(date)
    const startOfWeek = moment(date).startOf(type).utc().format();
    const endOfWeek = moment(date).endOf(type).utc().format();
    return { StartTime: startOfWeek, EndTime: endOfWeek }
  }
  return null
}

export const GroupPlanWeek = (date) => {
  const weebs = [];
  if (date && moment(date).isValid()) {
    date = moment(date);
    let dateStart = moment(date).startOf('month').utc().format();
    let dateEnd = moment(date).endOf('month').utc().format();

    let _date = dateStart;
    let i = 0;
    while (i < 6) {

      let startOfWeek = moment(_date).startOf('week').utc().format();
      let endOfWeek = moment(_date).endOf('week').utc().format();

      if (startOfWeek <= dateEnd) {
        weebs.push({
          StartTime: moment(startOfWeek).isAfter(dateStart) ? startOfWeek : dateStart,
          EndTime: moment(endOfWeek).isBefore(dateEnd) ? endOfWeek : dateEnd,
        })
      }
      _date = moment(_date).add(1, 'weeks');

      i++
    }

  }
  return weebs
}

export const ObjEachDate = ({ From, To }) => {
  const days = {};
  if (From && To && moment(From).isValid() && moment(To).isValid()) {

    let startDay = moment(From).utc().format();
    let endDay = moment(To).add(1, 'day');
    let _date = startDay;
    while (_date <= endDay) {
      const keyday = moment(_date).utc().format('DD-MM-YYYY');
      days[keyday] = { name: keyday };
      _date = moment(_date).add(1, 'day').utc().format();
    }
  }
  return days
}

export const arrayToObject = (_arr = [], objectKey = 'param') => {
  if(!_arr) {
    return {};
  }
  let res = {};
  _arr.forEach((element, index) => {
    res[`${objectKey}[${index}]`] = element;
  });
  return res;
}

export const NumberCommas = (x) => {
  if (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return 0
}

export const scrollMoveTable = (el, ratio = 1) => {
  const element = document.querySelector(el);
  let isDown = false;
  let startX;
  let scrollLeft;
  if (element) {
    element.addEventListener('mousedown', (e) => {
      if (element.classList.contains('disable-drag-to-scroll')) {
        return;
      }
      if (!e.target.closest('.no-srcoll')) {
        isDown = true;
        element.classList.add('active');
        startX = e.pageX - element.offsetLeft;
        scrollLeft = element.scrollLeft;
      }
    });
    element.addEventListener('mouseleave', (e) => {
      isDown = false;
      element.classList.remove('active');
    });
    element.addEventListener('mouseup', (e) => {
      isDown = false;
      element.classList.remove('active');
    });
    element.addEventListener('mousemove', (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - element.offsetLeft;
      const walk = (x - startX) * ratio;
      element.scrollLeft = scrollLeft - walk;
    });
  }
};

export const unflatten = function (array, parent, tree) {
  tree = typeof tree !== 'undefined' ? tree : [];
  parent = typeof parent !== 'undefined' ? parent : { Id: null };

  var children = _.filter(array, function (child) { return child.ParentId === parent.Id; });

  if (!_.isEmpty(children)) {
    if (parent.Id === null) {
      tree = children;
    } else {
      parent['Children'] = children
    }
    _.each(children, function (child) { unflatten(array, child) });
  }

  return tree;
}

export const unflattenToTreeViewDataObject = function (array, parent, tree, options = {}) {
  const defaultOptions = { keyCol: 'Id', titleCol: 'Name', parentCol: 'ParentId' };
  const { keyCol, titleCol, parentCol } = { ...defaultOptions, ...options }
  array = array.map(x => ({ key: x[keyCol], title: x[titleCol], children: [], parentId: x[parentCol] }));
  tree = typeof tree !== 'undefined' ? tree : [];
  parent = typeof parent !== 'undefined' ? parent : { key: null };

  var children = _.filter(array, function (child) { return child.parentId === parent.key; });

  if (!_.isEmpty(children)) {
    if (parent.key === null) {
      tree = children;
    } else {
      parent['children'] = children
    }
    _.each(children, function (child) { unflattenToTreeViewDataObject(array, child, undefined, { keyCol: 'key', titleCol: 'title', parentCol: 'parentId' }) });
  }

  return tree;
}

export const arrayTree = function (array, parent, tree) {
  array = array.map(x => ({ ...x, children: null }));
  tree = typeof tree !== 'undefined' ? tree : [];
  parent = typeof parent !== 'undefined' ? parent : { Id: null };

  var children = _.filter(array, function (child) { return child.ParentId === parent.Id; });

  if (!_.isEmpty(children)) {
    if (parent.Id === null) {
      tree = children;
    } else {
      parent['children'] = children
    }
    _.each(children, function (child) { arrayTree(array, child, undefined) });
  }

  return tree;
}

export const MakeRound = (_nr, numberOfDigitsAfterTheDecimalPoint = 3) => {
  if (!_nr || isNaN(_nr)) return _nr;

  if (_nr % 1 !== 0) {
    return _nr.toFixed(numberOfDigitsAfterTheDecimalPoint)
  }

  return _nr;
}


export const printFrame = (contents) => {
  var today = new Date();
  var dd = today.getDate();
  var mm = today.getMonth() + 1; //January is 0!
  var yyyy = today.getFullYear();
  var hh = today.getHours();
  var _mm = today.getMinutes();
  var ss = today.getSeconds();
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }
  if (hh < 10) {
    hh = '0' + hh;
  }
  if (_mm < 10) {
    mm = '0' + mm;
  }
  if (ss < 10) {
    ss = '0' + ss;
  }
  today = dd + "-" + mm + "-" + yyyy + "-" + hh + _mm + ss;
  const iframe = document.createElement('iframe')
  iframe.setAttribute('title', 'frame1')
  iframe.setAttribute('id', 'frame1')
  iframe.setAttribute('style', 'height: 0px; width: 0px; position: absolute;')
  document.body.appendChild(iframe)
  let pri = iframe.contentWindow
  pri.document.open()
  pri.document.write(contents);
  pri.document.close();
  setTimeout(function () {
    pri.focus()
    pri.print()
  }, 500);
};

export const MilisecondToClearText = function (ms, includeHours = false) {
  const msPerSecond = 1000;
  const msPerMin = msPerSecond * 60;
  const msPerHour = msPerMin * 60;

  const hours = Math.floor(ms / msPerHour);
  const mins = Math.floor((ms - (hours * msPerHour)) / msPerMin);
  const seconds = Math.floor((ms - (hours * msPerHour) - (mins * msPerMin)) / msPerSecond);
  const mili = ms - (hours * msPerHour) - (mins * msPerMin) - (seconds * msPerSecond);
  let res = '';
  if (includeHours) {
    res += `${(hours < 10 ? '0' + hours : hours)}`;
  }
  res += `${(includeHours ? ':' : '')}${(mins < 10 ? '0' + mins : mins)}`;
  res += `:${(seconds < 10 ? '0' + seconds : seconds)}`;
  if (mili === 0) {
    res += '.000';
  } else {
    switch (Math.floor(Math.log10(mili))) {
      case 0: {
        res += `.00${mili.toFixed(0)}`;
        break;
      }

      case 1: {
        res += `.0${mili.toFixed(0)}`;
        break;
      }

      default: {
        res += `.${mili.toFixed(0)}`;
        break;
      }
    }
  }

  return res;
}