import { call, put, takeLatest } from 'redux-saga/effects';

import * as taskQueueService from './../../services/task-queue-service'
import {
    countTaskInQueueSuccess, countTaskInQueueOpSuccess, countTaskInQueueCrmSuccess,
    getTaskInQueueDetailsCrmSuccess, getTaskInQueueDetailsOpSuccess, getTaskInQueueDetailsSuccess
} from './reducer';

export function* countTaskInQueue() {
    try {
        const { Result } = yield call(taskQueueService.countTaskInQueue);

        if (Result !== null && Result !== undefined) {
            yield put(countTaskInQueueSuccess({ taskInQueue: Result }));
        } else {
            yield put(countTaskInQueueSuccess({ taskInQueue: 0 }))
        }
    } catch (error) {
        yield put(countTaskInQueueSuccess({ taskInQueue: -1 }))
    }
}

export function* countTaskInQueueOp() {
    try {
        const { Result } = yield call(taskQueueService.countTaskInQueueOp);

        if (Result !== null && Result !== undefined) {
            yield put(countTaskInQueueOpSuccess({ taskInQueue: Result }));
        } else {
            yield put(countTaskInQueueOpSuccess({ taskInQueue: 0 }))
        }
    } catch (error) {
        yield put(countTaskInQueueOpSuccess({ taskInQueue: -1 }))
    }
}

export function* countTaskInQueueCrm() {
    try {
        const { Result } = yield call(taskQueueService.countTaskInQueueCrm);

        if (Result !== null && Result !== undefined) {
            yield put(countTaskInQueueCrmSuccess({ taskInQueue: Result }));
        } else {
            yield put(countTaskInQueueCrmSuccess({ taskInQueue: 0 }))
        }
    } catch (error) {
        yield put(countTaskInQueueCrmSuccess({ taskInQueue: -1 }))
    }
}

export function* getTaskInQueueDetails({ payload: { cb } = {} }) {
    try {
        const { Result } = yield call(taskQueueService.getTaskQueueDetails);

        if (Result !== null && Result !== undefined) {
            yield put(getTaskInQueueDetailsSuccess(Result));
        } else {
            yield put(getTaskInQueueDetailsSuccess({}))
        }
        cb && cb(Result)
    } catch (error) {
        cb && cb({})
        yield put(getTaskInQueueDetailsSuccess({}))
    }
}

export function* getTaskInQueueDetailsOp({ payload: { cb } = {} }) {
    try {
        const { Result } = yield call(taskQueueService.getTaskQueueDetailsOp);

        if (Result !== null && Result !== undefined) {
            yield put(getTaskInQueueDetailsOpSuccess(Result));
        } else {
            yield put(getTaskInQueueDetailsOpSuccess({}))
        }
        cb && cb(Result)
    } catch (error) {
        cb && cb({})
        yield put(getTaskInQueueDetailsOpSuccess({}))
    }
}

export function* getTaskInQueueDetailsCrm({ payload: { cb } = {} }) {
    try {
        const { Result } = yield call(taskQueueService.getTaskQueueDetailsCrm);

        if (Result !== null && Result !== undefined) {
            yield put(getTaskInQueueDetailsCrmSuccess(Result));
        } else {
            yield put(getTaskInQueueDetailsCrmSuccess([]))
        }
        cb && cb(Result)
    } catch (error) {
        cb && cb([])
        yield put(getTaskInQueueDetailsCrmSuccess([]))
    }
}

export default [
    takeLatest('COUNT_TASK_IN_QUEUE_REQUEST', countTaskInQueue),
    takeLatest('COUNT_TASK_IN_QUEUE_OP_REQUEST', countTaskInQueueOp),
    takeLatest('COUNT_TASK_IN_QUEUE_CRM_REQUEST', countTaskInQueueCrm),
    takeLatest('COUNT_TASK_IN_QUEUE_DETAILS_CRM_REQUEST', getTaskInQueueDetailsCrm),
    takeLatest('COUNT_TASK_IN_QUEUE_DETAILS_OP_REQUEST', getTaskInQueueDetailsOp),
    takeLatest('COUNT_TASK_IN_QUEUE_DETAILS_REQUEST', getTaskInQueueDetails)
];