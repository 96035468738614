import React from 'react';
import { Route } from 'react-router-dom';

const RouteSubRoutes = route => {
  return (
    <Route
      exact={route.exact}
      path={route.path}
      render={props => <route.component {...props} routes={route.routes} isMobile={route.isMobile} />}
    />
  )
};

export default RouteSubRoutes
