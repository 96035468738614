import ExportFuelV from "./ExportFuelV";
import { lifecycle, compose as recompose, withState, pure, withHandlers } from "recompose";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { deleteExportFuelSurchargesRequest, getExportFuelSurchargesRequest } from "../../reduxs/export-fuel-redux/reducer";
import { selectStationAlls } from '../../reduxs/common-redux/selectors';
import { selectSurcharges, selectLoading, selectPagination } from "../../reduxs/export-fuel-redux/selectors";
import { getAllStationRequest } from "../../reduxs/common-redux/reducer";
import { searchCustomerRequest } from "../../reduxs/utility-redux/reducer";
import { selectCustomers, selectCustomersing } from "../../reduxs/utility-redux/selectors";
import Auth from "../../storage/Auth";
import { message } from "antd";

const STATION_CODE = Auth.getStation();

const columns = [
    { name: 'STT', id: 'STT', show: true, width: 80 },
    { name: 'Bưu cục bán', id: 'STATION_CODE_SELL', show: true },
    { name: 'Bưu cục mua', id: 'STATION_CODE', show: true },
    { name: 'Mã KH', id: 'ACCOUNT_NO', show: true },
    { name: 'Dịch vụ', id: 'TOS', show: true, class: 'text-center' },
    { name: 'Tháng', id: 'APPLIED_MONTH', show: true, class: 'text-center' },
    { name: 'Năm', id: 'APPLIED_YEAR', show: true, class: 'text-center' },
    { name: 'Phụ phí xăng dầu (%)', id: 'COST_SURCHARGES', type: 'charges', show: true },
];

export function mapDispatchToProps(dispatch) {
    return {
        getFuelSurcharges: (params) => dispatch(getExportFuelSurchargesRequest(params)),
        getAllStation: (params) => dispatch(getAllStationRequest(params)),
        getCustomers: (params) => dispatch(searchCustomerRequest(params)),
        removeSurcharge: (params) => dispatch(deleteExportFuelSurchargesRequest(params)),
    };
}

const mapStateToProps = createStructuredSelector({
    surcharges: selectSurcharges(),
    loading: selectLoading(),
    pagination: selectPagination(),
    stations: selectStationAlls(),
    customers: selectCustomers(),
    customersing: selectCustomersing(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const getParams = (params, setParams, param = {}) => {
    let objParams = { ...params, ...param };
    setParams(objParams);

    if (objParams.Offset === undefined) objParams.Offset = 0;
    if (objParams.Size === undefined) objParams.Size = 20;

    if (objParams.StationCodeSell === undefined) objParams.StationCodeSell = "ALL";
    if (objParams.StationCodeBuy === undefined) objParams.StationCodeBuy = "ALL";

    const _params = {
        Offset: objParams.Offset,
        Size: objParams.Size,
        CustomerCode: objParams.CustomerCode,
        StationCodeSell: objParams.StationCodeSell,
        StationCodeBuy: objParams.StationCodeBuy,
    }
    return { ..._params }
}

var searchtime = null;

export default recompose(
    withConnect,
    withState('params', 'setParams', { Offset: 1, Size: 20, StationCodeSell: "ALL", StationCodeBuy: "ALL" }),
    withState('rowKeys', 'setRowKeys', []),
    withState('rowRows', 'setSelectRows', []),
    withState('selectKeys', 'setSelectKeys', []),
    withState('selectRows', 'setSelectRows', []),
    withState('columns', 'setColumns', columns),
    withState('open', 'setOpen', false),
    withState('fuel', 'setFuel', null),
    withState('search', 'setLabelCustomer', false),
    withState('deleting', 'setDeleting', null),
    withHandlers({
        stateColumn: ({ columns, setColumns }) => (data) => {
            const cols = columns.map((col) => {
                if (col.id === data.id) col.show = !data.show
                return col;
            });
            setColumns(cols);
        },
        searchCustomer: ({ setLabelCustomer, getCustomers }) => (value) => {
            if (value && value.length > 1) {
                clearTimeout(searchtime)
                searchtime = setTimeout(() => {
                    getCustomers({ Query: value, Limit: 20, IsActive: true, StationCode: STATION_CODE })
                }, 700);
                setLabelCustomer(true);
            } else {
                setLabelCustomer(false);
            }
        },
        fetchFuelSurcharges: ({ params, setParams, getFuelSurcharges }) => (param = {}) => {
            getFuelSurcharges(getParams(params, setParams, param));
        },
    }),
    withHandlers({
        onDelete: ({ fetchFuelSurcharges, removeSurcharge, setDeleting }) => (item) => {
            if (item) {
                setDeleting(item.ID)
                const params = {
                    data: { ID: item.ID, },
                    cb: (type) => {
                        if (type === 'error') {
                            message.error('Xóa thất bại!');
                        } else {
                            message.error('Xóa thành công!');
                        }
                        fetchFuelSurcharges();
                        setDeleting(null)
                    }
                }
                removeSurcharge(params);
            }
        }
    }),
    lifecycle({
        componentDidMount() {
            const { fetchFuelSurcharges, getAllStation } = this.props;
            fetchFuelSurcharges();
            getAllStation();
        },
    }),
    pure
)(ExportFuelV);
