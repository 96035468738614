
import './style.scss';
import React from 'react';
import { Layout, Input, Menu, Dropdown, Checkbox, Button, Radio, Select, Tooltip } from 'antd';
import { MenuOutlined, EditOutlined, DeleteOutlined, PlusOutlined, DownloadOutlined } from '@ant-design/icons';
import RegionForm from './components/RegionForm';
import ProvinceRegion from './components/ProvinceRegion';
import ProvinceForm from './components/ProvinceForm';
import DistrictForm from './components/DistrictForm';
import WardsForm from './components/WardsForm';
import RestrictPage from './../../components/RestrictComponents/RestrictPage';
import RestrictFeature from './../../components/RestrictComponents/RestrictFeature';

const { Content } = Layout;
const { Option } = Select;

const actionProvince = (province, { onProvince, deleteProvince }) => {
    return (
        <Menu>
            <Menu.Item onClick={() => onProvince(province)}>
                <span><EditOutlined style={{ color: '#0d7ecc' }} />Cập nhật</span>
            </Menu.Item>
            <Menu.Item onClick={() => deleteProvince(province)}>
                <span><DeleteOutlined style={{ color: 'red' }} />Xóa bỏ</span>
            </Menu.Item>
        </Menu>
    )
}

const actionDistrict = (district, { onDistrict, deleteDistrict }) => {
    return (
        <Menu>
            <Menu.Item onClick={() => onDistrict(district)}>
                <span><EditOutlined style={{ color: '#0d7ecc' }} />Cập nhật</span>
            </Menu.Item>
            <Menu.Item onClick={() => deleteDistrict(district)}>
                <span><DeleteOutlined style={{ color: 'red' }} />Xóa bỏ</span>
            </Menu.Item>
        </Menu>
    )
}

const actionWards = (wards, { onWards, deleteWards }) => {
    return (
        <Menu>
            <Menu.Item onClick={() => onWards(wards)}>
                <span><EditOutlined style={{ color: '#0d7ecc' }} />&ensp;Cập nhật</span>
            </Menu.Item>
            <Menu.Item onClick={() => deleteWards(wards)}>
                <span><DeleteOutlined style={{ color: 'red' }} />&ensp;Xóa bỏ</span>
            </Menu.Item>
        </Menu>
    )
}

const RegionV = (props) => {
    const { regions, provinces, districts, wards, panners, searchDistrict, setSearchDistrict, searchProvince, setSearchProvince, searchWard, setSearchWard, currentStation } = props;
    const { openRegion, setOpenRegion, openProvince, setOpenProvince, checkedWards, setCheckedWards } = props;
    const { region, setRegion, province, district, fetchRegions, fetchProvinces, fetchDistricts, fetchWards } = props;
    const { downloading, downloadLocation, wardOptions, onSaveWardInfo } = props;

    const { editProvince, showProvince, setShowProvince } = props;
    const { editDistrict, showDistrict, setShowDistrict, onDistrict } = props;
    const { editWards, showWards, setShowWards, onWards } = props;

    let _provinces = provinces;
    if (searchProvince && provinces && provinces.length) {
        _provinces = provinces.filter((item) => (item.Name && item.Name.toUpperCase().indexOf(searchProvince.toUpperCase()) !== -1) || (item.Code && item.Code.indexOf(searchProvince) !== -1))
    }

    let _districts = districts
    if (searchDistrict && districts && districts.length) {
        _districts = districts.filter((item) => (item.Name && item.Name.toUpperCase().indexOf(searchDistrict.toUpperCase()) !== -1) || (item.Code && item.Code.indexOf(searchDistrict) !== -1))
    }

    let _wards = wards;
    if (searchWard && wards && wards.length) {
        _wards = wards.filter((item) => item.Name && item.Name.toUpperCase().indexOf(searchWard.toUpperCase()) !== -1)
    }

    let _panners = panners.map((item, key) => {
        return { key: key, value: item.Code, label: `${item.Code} - ${item.Name}` }
    });

    return (
        <RestrictPage>
            <Content className="page-region">
                <div className="panel-header">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="header-title">
                                <i className="fa fa-home icon" aria-hidden="true"></i>
                                <span>Hệ thống</span>
                                <span className="icon-next"><i className="fa fa-angle-right" /></span>
                                <span className="active">Quản lý địa danh</span>
                            </div>
                        </div>
                        <div className="col-md-8 mr-t-5 text-right">
                            <span className="text-left">
                                <label className="title-country mr-0 bold"><i className="fa fa-map-marker" />Khu vực</label>&emsp;
                                <Select value={region} style={{ width: 300 }} onChange={(value) => fetchProvinces(value)}>
                                    {regions && regions.map((item, key) => {
                                        return (
                                            <Option value={item.Id} key={key}>
                                                <span className="item-region bold">{item.Name}</span>
                                            </Option>
                                        )
                                    })}
                                </Select>&emsp;
                                <Button loading={downloading} onClick={() => downloadLocation()} icon={<DownloadOutlined style={{ fontSize: '16px' }} />} className="ant-btn ant-btn-primary">Tải danh sách</Button>&nbsp;&nbsp;
                                <RestrictFeature key="add-region" feature="UPDATE_REGION_INFO">
                                    {
                                        (_props) => {
                                            return (<Button type="primary" onClick={() => setOpenRegion(true)} disabled={!_props.allowed} icon={<PlusOutlined />}>Thêm khu vực</Button>)
                                        }
                                    }
                                </RestrictFeature>
                                <RegionForm open={openRegion} close={setOpenRegion} fetchRegions={fetchRegions} />
                            </span>

                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-12 box-container">
                            <div className="col-md-4 pd-5">
                                <div className="panel-body">
                                    <div className="row panel-headers">
                                        <div className="col-xs-10 search">
                                            <span className="title bold">Tỉnh Thành</span>
                                            <Input.Search
                                                value={searchProvince}
                                                placeholder="Tìm tỉnh thành"
                                                onChange={e => setSearchProvince(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-xs-2 text-right">
                                            <RestrictFeature key="add-province" feature="UPDATE_REGION_INFO">
                                                {
                                                    (_props) => {
                                                        return (<Button onClick={() => setOpenProvince(true)} disabled={!_props.allowed} icon={<PlusOutlined />} />)
                                                    }
                                                }
                                            </RestrictFeature>
                                            <ProvinceRegion open={openProvince} close={setOpenProvince} fetchProvinces={fetchProvinces} selects={provinces} region={region} setRegion={setRegion} />
                                            {showProvince && editProvince && <ProvinceForm open={showProvince} close={setShowProvince} province={editProvince || ''} fetchProvinces={() => fetchProvinces(region)} />}
                                        </div>
                                    </div>
                                    <hr />
                                    <Radio.Group onChange={(e) => fetchDistricts(e.target.value)} value={Number(province)} className="list-region" id="scroll">
                                        {_provinces && _provinces.map((item, key) => {
                                            // let option = provinceOptions[item.Id] || {};
                                            return <div key={key} className={Number(province) === item.Id ? 'item-region active' : 'item-region'}>
                                                <Radio value={item.Id}>
                                                    {item.Prefix} {item.Name}
                                                    <span className="pull-right">
                                                        <small>Mã BC: {item.ZipPostalCode}</small>&ensp;
                                                        <RestrictFeature key={`edit-province-${key}`} feature="UPDATE_REGION_INFO">
                                                            {
                                                                (_props) => {
                                                                    return (
                                                                        <Dropdown overlay={actionProvince(item, props)} placement="bottomRight">
                                                                            <MenuOutlined />
                                                                        </Dropdown>)
                                                                }
                                                            }
                                                        </RestrictFeature>
                                                    </span>
                                                </Radio>
                                            </div>
                                        })}
                                    </Radio.Group>
                                </div>
                            </div>

                            <div className="col-md-4 pd-5">
                                <div className="panel-body">
                                    <div className="row panel-headers">
                                        <div className="col-xs-10 search">
                                            <span className="title bold">Quận Huyện</span>
                                            <Input.Search
                                                value={searchDistrict}
                                                placeholder="Tìm quận huyện"
                                                onChange={e => setSearchDistrict(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-xs-2 text-right">
                                            <RestrictFeature key="add-district" feature="UPDATE_REGION_INFO">
                                                {
                                                    (_props) => {
                                                        return (<Button disabled={!_props.allowed || !province} onClick={() => onDistrict()} icon={<PlusOutlined />} />)
                                                    }
                                                }
                                            </RestrictFeature>
                                            {showDistrict && province && <DistrictForm open={showDistrict} close={setShowDistrict} district={editDistrict || ''} ProvinceId={province} fetchDistricts={() => fetchDistricts(province)} />}
                                        </div>
                                    </div>
                                    <hr />
                                    <Radio.Group onChange={(e) => fetchWards(e.target.value)} value={Number(district)} className="list-region" id="scroll">
                                        {_districts && _districts.map((item, key) => {
                                            // let option = districtOptions[item.Id] || {};
                                            if (item.ProvinceId === province) {
                                                return <div key={key} className={Number(district) === item.Id ? 'item-region active' : 'item-region'}>
                                                    <Radio value={item.Id}>
                                                        {item.Id} - {item.Prefix} {item.Name}
                                                        <span className="pull-right">
                                                            <small>Mã code: {item.Code}</small>&ensp;
                                                            <RestrictFeature key={`edit-district-${key}`} feature="UPDATE_REGION_INFO">
                                                                {
                                                                    (_props) => {
                                                                        return  (
                                                                            <Dropdown overlay={actionDistrict(item, props)} placement="bottomRight">
                                                                                <MenuOutlined />
                                                                            </Dropdown>
                                                                        )
                                                                    }
                                                                }
                                                            </RestrictFeature>
                                                        </span>
                                                    </Radio>
                                                </div>
                                            }
                                            return null;
                                        })}
                                    </Radio.Group>
                                </div>
                            </div>

                            <div className="col-md-4 pd-5">
                                <div className="panel-body">
                                    <div className="row panel-headers">
                                        <div className="col-xs-10 search">
                                            <span className="title">Xã Phường</span>
                                            <Input.Search
                                                value={searchWard}
                                                placeholder="Tìm xã phường"
                                                onChange={e => setSearchWard(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-xs-2 text-right">
                                            <RestrictFeature key="add-region" feature="UPDATE_REGION_INFO">
                                                {
                                                    (_props) => {
                                                        return (<Button disabled={!_props.allowed || !district} onClick={() => onWards()} icon={<PlusOutlined />} />)
                                                    }
                                                }
                                            </RestrictFeature>
                                            {showWards && district && <WardsForm open={showWards} close={setShowWards} wards={editWards || ''} DistrictId={district} fetchWards={() => fetchWards(district)} />}
                                        </div>
                                    </div>
                                    <hr />
                                    <Checkbox.Group value={checkedWards} onChange={(values) => setCheckedWards(values)} className="list-region" id="scroll">
                                        {/* <Checkbox.Group value={checkedWards} className="list-region" id="scroll"> */}
                                        {_wards && _wards.map((item, key) => {
                                            let option = wardOptions[item.Id] || {};
                                            let values = [];
                                            if (option.IsInnerCity) values.push('IsInnerCity');
                                            if (option.IsOutlyingArea) values.push('IsOutlyingArea');
                                            if (option.IsIndustrialArea) values.push('IsIndustrialArea');
                                            if (option.DeliveryUnavailable) values.push('DeliveryUnavailable');
                                            if (option.PickupUnavailable) values.push('PickupUnavailable');
                                            if (option.Island) values.push('Island');
                                            return (
                                                <div key={key} className={'item-region'}>
                                                    {/* <Checkbox key={key} value={item.Id} className="w-100" onChange={() => toggleWardInfo(item.Id)}> */}
                                                    <RestrictFeature key={`edit-ward-${key}`} feature="UPDATE_REGION_INFO">
                                                        {
                                                            (_props) => {
                                                                return (
                                                                    <div className="w-100">
                                                                        <Checkbox disabled={!_props.allowed} key={key} value={item.Id}>
                                                                            {item.Id} - {item.Prefix} {item.Name}
                                                                        </Checkbox>
                                                                        <label className="pull-right">
                                                                            {
                                                                                _props.allowed && (<span>
                                                                                    {item.RegionId === Number(region) &&
                                                                                        <small>Đã phân vùng</small>
                                                                                    }&ensp;
                                                                                </span>)
                                                                            }
                                                                            <Dropdown overlay={actionWards(item, props)} placement="bottomRight">
                                                                                <MenuOutlined className="link" />
                                                                            </Dropdown>
                                                                        </label>
                                                                    </div>
                                                                )
                                                            }
                                                        }
                                                    </RestrictFeature>
                                                    {checkedWards.includes(item.Id) &&
                                                        <RestrictFeature key={`${key}A`} feature="UPDATE_REGION_INFO">
                                                            {(_props) => {
                                                                return (
                                                                    <Checkbox.Group value={values} className="group-children" disabled={!_props.allowed} >
                                                                        <Checkbox value={`PickupUnavailable`} checked={option.PickupUnavailable || false} onChange={(e) => onSaveWardInfo(item.Id, { ...option, PickupUnavailable: e.target.checked })} className="cb-children">Không lấy hàng</Checkbox>
                                                                        <Checkbox value={`DeliveryUnavailable`} checked={option.DeliveryUnavailable || false} onChange={(e) => onSaveWardInfo(item.Id, { ...option, DeliveryUnavailable: e.target.checked })} className="cb-children">Không phát hàng</Checkbox>
                                                                        <Checkbox value={`IsInnerCity`} checked={option.IsInnerCity || false} onChange={(e) => onSaveWardInfo(item.Id, { ...option, IsInnerCity: e.target.checked, IsOutlyingArea: false, Island: false, IsIndustrialArea: false })} className="cb-children">Nội thành</Checkbox>
                                                                        <Checkbox value={`IsIndustrialArea`} checked={option.IsIndustrialArea || false} onChange={(e) => onSaveWardInfo(item.Id, { ...option, IsIndustrialArea: e.target.checked })} className="cb-children">Khu công nghiệp</Checkbox>
                                                                        <Checkbox value={`IsOutlyingArea`} checked={option.IsOutlyingArea || false} onChange={(e) => onSaveWardInfo(item.Id, { ...option, IsInnerCity: false, Island: false, IsOutlyingArea: e.target.checked, IsIndustrialArea: false })} className="cb-children">Vùng sâu, vùng xa</Checkbox>
                                                                        <Checkbox value={`Island`} checked={option.Island || false} onChange={(e) => onSaveWardInfo(item.Id, { ...option, IsInnerCity: false, IsOutlyingArea: false, IsIndustrialArea: false, Island: e.target.checked })} className="cb-children">Hải đảo / biên giới</Checkbox>
                                                                    </Checkbox.Group>
                                                                )
                                                            }
                                                            }
                                                        </RestrictFeature>
                                                    }
                                                    {checkedWards.includes(item.Id) &&
                                                        <label className="group-select">
                                                            <span><i className="fa fa-building" /> Đơn vị phát</span>&ensp;
                                                            <RestrictFeature key={`${key}B`} feature="UPDATE_REGION_INFO">
                                                                {
                                                                    (_props) => {
                                                                        return (
                                                                            <Tooltip title={`Cài đặt đơn vị phát cho bưu cục ${currentStation?.STATION_CODE} - ${currentStation?.NAME}`}>
                                                                                <Select
                                                                                    disabled={!_props.allowed}
                                                                                    options={_panners}
                                                                                    value={option.PartnerCode || 'PCSPOST'}
                                                                                    showSearch onChange={(value) => onSaveWardInfo(item.Id, { ...option, PartnerCode: value })}
                                                                                    size="small"
                                                                                    dropdownMatchSelectWidth={350}
                                                                                />
                                                                            </Tooltip>
                                                                        )
                                                                    }
                                                                }
                                                            </RestrictFeature>
                                                        </label>
                                                    }
                                                </div>
                                            )
                                        })}
                                    </Checkbox.Group>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </Content>
        </RestrictPage>
    )
}
export default RegionV