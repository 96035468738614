import API from '../utils/axiosApiHR';
import axios from '../utils/axiosInstance';
// import { printFrame } from '../helpers/common';
// import FileSaver from 'file-saver';

export const fetchAssetVendors = async (params) => {
  try {
    const { data } = await API.get('/assets/vendors', { params });
    return data;
  } catch (error) {
    throw error;
  }
};
export const saveAssetVendor = async (body) => {
    try {
      if(body && body.ID) {
        const { data } = await API.put(`/assets/vendor/${body.ID}`, body);
        return data;
      } else {
        const { data } = await API.post('/assets/vendor', body);
        return data;
      }
    } catch (error) {
      throw error;
    }
};
export const deleteAssetVendor = async ({Id}) => {
  try {
    const { data } = await API.delete(`/assets/vendor/${Id}`);
    return data;
  } catch (error) {
    throw error;
  }
};

/**Location */
export const fetchAssetLocations = async (params) => {
  try {
    const { data } = await API.get('/assets/locations', { params });
    return data;
  } catch (error) {
    throw error;
  }
};
export const saveAssetLocation = async (body) => {
    try {
      if(body && body.ID) {
        const { data } = await API.put(`/assets/location/${body.ID}`, body);
        return data;
      } else {
        const { data } = await API.post('/assets/location', body);
        return data;
      }
    } catch (error) {
      throw error;
    }
};
export const deleteAssetLocation = async ({Id}) => {
  try {
    const { data } = await API.delete(`/assets/location/${Id}`);
    return data;
  } catch (error) {
    throw error;
  }
};

/**Group */
export const fetchAssetGroups = async (params) => {
  try {
    const { data } = await API.get('/assets/groups', { params });
    return data;
  } catch (error) {
    throw error;
  }
};
export const saveAssetGroup = async (body) => {
    try {
      if(body && body.ID) {
        const { data } = await API.put(`/assets/group/${body.ID}`, body);
        return data;
      } else {
        const { data } = await API.post('/assets/group', body);
        return data;
      }
    } catch (error) {
      throw error;
    }
};
export const deleteAssetGroup = async ({Id}) => {
  try {
    const { data } = await API.delete(`/assets/group/${Id}`);
    return data;
  } catch (error) {
    throw error;
  }
};

/**Type */
export const fetchAssetTypes = async (params) => {
  try {
    const { data } = await API.get('/assets/types', { params });
    return data;
  } catch (error) {
    throw error;
  }
};
export const saveAssetType = async (body) => {
    try {
      if(body && body.ID) {
        const { data } = await API.put(`/assets/type/${body.ID}`, body);
        return data;
      } else {
        const { data } = await API.post('/assets/type', body);
        return data;
      }
    } catch (error) {
      throw error;
    }
};
export const deleteAssetType = async ({Id}) => {
  try {
    const { data } = await API.delete(`/assets/type/${Id}`);
    return data;
  } catch (error) {
    throw error;
  }
};


export const fetchAssetLeaders = async (params) => {
  try {
    const { data } = await axios.get('/ops-staff/list-staff-by', { params });
    return data;
  } catch (error) {
    throw error;
  }
};