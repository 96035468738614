import React from 'react';
import { Button, Modal, Form, notification, Tooltip, Empty } from 'antd';
import { DeleteOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';
import { compose as recompose, lifecycle, withHandlers, withProps, withPropsOnChange, withState} from 'recompose';
import { Field, formValueSelector, getFormValues, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getStaffDepartmentRequest }  from '../../../reduxs/common-redux/reducer';
import { selectStation } from '../../../reduxs/station-redux/selectors';
import { STAFF_ROUTE_FORM_KEY, StaffFormAction } from '../../../reduxs/pickup-route-redux/action';
import { arrayToObject, NumberCommas } from '../../../helpers/common';
import { required  } from '../../../helpers/validate';
import { FieldCheckBox, FieldNumber, FieldSelect } from '../../../components/Fields';

const PickupRouteForm = (props) => {
  const { open, close, group, staffs, childrens, route, onPushStaff, onDeleteStaff, routes, reset, error, handleSubmit } = props;
  const submit = handleSubmit(StaffFormAction);
  
  let _staffs = [];
  if(staffs && staffs.length) {
    _staffs = staffs.map((item) => {
      return {
        value: item.StaffCode,
        text: `${item.StaffCode} - ${item.StaffName}`,
        search: `${item.StaffCode} - ${item.StaffName}`
      }
    })
  }

  let _routes = [];
  if(routes && routes.length) {
    _routes = routes.map((item) => ({value: item.Id, text: item.Name, search: item.Name}))
  }


  let rowspan = childrens && childrens.length ? (childrens.length) + 1 : 2;

  return (
    <Modal
      title='Phân tuyến lấy hàng'
      visible={open}
      style={{ top: 20 }}
      width={'860px'}
      footer={null}
      maskClosable={false}
      onOk={() => { reset(); close(false) }}
      onCancel={() => { reset(); close(false) }}
      className="modal-pickup-staff"
    >
      <Form layout="vertical" onFinish={submit}>
        <div className='row'>
          <div className='col-md-8'>
            <Field
              showSearch
              component={FieldSelect}
              label={<span><i className="fa fa-map-marker"/>&ensp;Tuyến lấy hàng</span>}
              name="RouteId"
              placeholder="Chọn tuyến lấy hàng cần phân"
              options={_routes}
              className="required"
              validate={[required]}
              filterOption={(input, option) => {
                if(option.search) {
                        return option.search.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                }
              }
            />
          </div>
          <div className='col-md-4 text-right'>
            <label className='ant-col ant-form-item-label w-100 mr-0'>&emsp;</label>
            <Button type="primary" icon={<SaveOutlined />} htmlType="submit">{group ? "Cập nhật" : "Lưu lại"}</Button>
          </div>
        </div>
        <p className='text-red text-right'>&ensp;{error}</p>
        <table className='table table-bordered'>
          <thead>
            <tr>
              <th className='bold'>Tuyến</th>
              <th className='bold'>Nhân viên pickup</th>
              <th className='bold'>Giá cước</th>
              <th className='bold'>Action</th>
            </tr>
          </thead>
          <tbody>
          { route ?
            (
              <React.Fragment>
                <tr>
                  <td rowSpan={rowspan} style={{width: '200px'}}><span className='bold'>{route.Name}</span> ({NumberCommas(route.CommonPrice)} đ)</td>
                  <td>
                    <Field
                      showSearch
                      component={FieldSelect}
                      name={'childrens[0].StaffCode'}
                      placeholder="Chọn nhân viên lấy hàng"
                      options={_staffs}
                      className="required"
                      validate={[required]}
                      filterOption={(input, option) => {
                        if(option.search) {
                                return option.search.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        }
                      }
                    />
                  </td>
                  <td style={{width: '180px'}}>
                    <div className='input-line'>
                      <Field
                        disabled={!(childrens && childrens[0]?.IsPrice)}
                        component={FieldNumber}
                        name={'childrens[0].Price'}
                        mode="comma"
                        placeholder="Nhập giá cước"
                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        parser={value => `${value}`.replace(/(,*)/g, '')}
                        className="required"
                        validate={[required]}
                      />
                      <Tooltip title="Sử dụng giá khác">
                        <Field
                          className="checkbox"
                          component={FieldCheckBox}
                          name={'childrens[0].IsPrice'}
                        />
                      </Tooltip>
                    </div>
                  </td>
                  <td style={{width: '60px'}}></td>
                </tr>
                { childrens && childrens.length > 1 &&
                  childrens.map((staff, key1) => {
                    if(key1 === 0) return '';
                    return(
                      <tr key={key1}>
                        <td>
                          <Field
                            showSearch
                            component={FieldSelect}
                            name={`childrens[${key1}].StaffCode`}
                            placeholder="Chọn nhân viên lấy hàng"
                            options={_staffs}
                            className="required"
                            validate={[required]}
                            filterOption={(input, option) => {
                              if(option.search) {
                                      return option.search.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                  }
                              }
                            }
                          />
                        </td>
                        <td>
                          <div className='input-line'>
                            <Field
                              disabled={!(childrens && childrens[key1]?.IsPrice)}
                              component={FieldNumber}
                              name={`childrens[${key1}].Price`}
                              mode="comma"
                              placeholder="Nhập giá cước"
                              formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              parser={value => `${value}`.replace(/(,*)/g, '')}
                              className="required"
                              validate={[required]}
                            />
                            <Tooltip title="Sử dụng giá khác">
                              <Field
                                className="checkbox"
                                component={FieldCheckBox}
                                name={`childrens[${key1}].IsPrice`}
                              />
                            </Tooltip>
                          </div>
                        </td>
                        <td className='text-center'>
                          <Tooltip title="Sử dụng giá khác">
                            <Button size='small' onClick={() => onDeleteStaff(key1)} icon={<DeleteOutlined/>} danger/>
                          </Tooltip>
                        </td>
                      </tr>
                    )
                  })
                }

                <tr>
                  <td colSpan={4} className='text-right'><Button size='small' onClick={() => onPushStaff(route, rowspan-1)} icon={<PlusOutlined/>}>Thêm PU</Button></td>
                </tr>
              </React.Fragment>
            )
            :
            <tr><td colSpan={5}><Empty title="Chưa có tuyến lấy hàng" description="Vui lòng chọn tuyến để phân nhân viên lấy hàng"/></td></tr>
          }
          </tbody>
        </table>
        <p>Ghi chú:&ensp;<span className="note-red">Trường không được để trống</span></p>
      </Form>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getStaffs: (params) => dispatch(getStaffDepartmentRequest(params))
  };
}
const selector = formValueSelector(STAFF_ROUTE_FORM_KEY);

const mapStateToProps = createStructuredSelector({
  station: selectStation(),
  staffs: ({common}) => common.staffDepartments,
  items: (state) => getFormValues('STAFF_ROUTE_FORM_KEY')(state),
  childrens: (state) => selector(state, 'childrens'),
  routeId: (state) => selector(state, 'RouteId'),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default recompose(
  withConnect,
  withProps(() => {
      return { initialValues: {}};
    }
  ),
  withState('route', 'setRoute', null),
  reduxForm({
    form: STAFF_ROUTE_FORM_KEY,
    enableReinitialize: true,
    onSubmitSuccess: (result, dispatch, props) => {
        notification.success({
          message: 'Lưu thành công!'
        });
        props.initialValues = null;
        props.close(false);
        props.refresh();
    }
  }),
  withHandlers({
    onPushStaff: ({ change }) => (route, key) => {
      if(route) {
        change(`childrens[${key}]`, { StaffCode: '', Price: route.CommonPrice})
      }
    },
    onDeleteStaff: ({ change, childrens }) => (key) => {
      if(childrens && childrens.length) {
        let _childrens = childrens.filter((i, k) => k !== key);
        change('childrens', _childrens)
      }

    }
  }),
  withPropsOnChange(['routeId'], ({ routeId, routes, change, setRoute }) => {
    if(routeId) {
      let route = routes.find(i => i.Id === routeId);
      setRoute(route || null);
      change('childrens[0]', { StaffCode: '', Price: route?.CommonPrice })
    } else {
      setRoute(null)
    }
  }),
  lifecycle({
    componentDidMount() {
      const { station }= this.props;
      if(station) {
        const department = arrayToObject(['PU', 'LX'], 'DepartmentCode')
        this.props.getStaffs({station: station.STATION_CODE, ...department});
      }
    }
  }),
)(PickupRouteForm);
