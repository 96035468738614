
import axios from '../utils/axiosInstance';

export const getNotificationTemplates = async (params = {}) => {
    try {
        const { data } = await axios.get(`/api/notification-template`, { params });
        return data;
    } catch (error) {
        throw error;
    }
};

export const addTemplate = async (params = {}) => {
    try {
        const { data } = await axios.post(`/api/notification-template`, params);
        return data;
    } catch (error) {
        throw error;
    }
};

export const updateTemplate = async (params = {}) => {
    try {
        const { data } = await axios.put(`/api/notification-template`, params);
        return data;
    } catch (error) {
        throw error;
    }
};

export const removeTemplate = async (params = {}) => {
    try {
        const { data } = await axios.delete(`/api/notification-template`, { params });
        return data;
    } catch (error) {
        throw error;
    }
};

