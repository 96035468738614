import { combineReducers } from 'redux'

import { connectRouter } from 'connected-react-router'
import { reducer as formReducer } from 'redux-form'
import { reducer as oidcReducer } from 'redux-oidc';
import { reducer as notificationReducer } from '../notif-msg-modules/src/modules';

import authReducer from './auth-redux/reducer';
import userReducer from './user-redux/reducer';
import subscriptionsReducer from './auth-redux/subscriptions';
import stationReducer from './station-redux/reducer';
import reportReducer from './report-redux/reducer';
import accountReducer from './account-redux/reducer';
import documentReducer from './customer-doc-redux/reducer';
import utilityReducer from './utility-redux/reducer';
import commonReducer from './common-redux/reducer';
import regionReducer from './region-redux/reducer';
import areaReducer from './area-redux/reducer';
import menuReducer from './menu-redux/reducer';
import roleReducer from './role-redux/reducer';
import autoReducer from './auto-redux/reducer';
import policyReducer from './policy-redux/reducer';
import manageStationReducer from './manageStation-redux/reducer';
import departmentReducer from './department-redux/reducer';
import staffReducer from './staff-redux/reducer';
import emailManagement from './email-redux/reducer';
import userMenuReducer from './user-menu-redux/reducer';
import cacheReducer from './cache-redux/reducer';
import serviceReducer from './service-redux/reducer';
import pickupRouteReducer from './pickup-route-redux/reducer';
import featureReducer from './feature-redux/reducer';
import webhookReducer from './webhook-redux/reducer';
import partnerReducer from './partner-redux/reducer';
import requestHistoryReducer from './request-history-redux/reducer';
import assetReducer from './asset-redux/reducer';
import assetSettingReducer from './asset-setting-redux/reducer';
import assetHandoverReducer from './asset-handover-redux/reducer';
import assetTransferReducer from './asset-transfer-redux/reducer';
import assetSaleReducer from './asset-sale-redux/reducer';
import assetAdjustReducer from './asset-adjustment-redux/reducer';
import assetCommonReducer from './asset-common-redux/reducer';
import errorLogsReducer from './error-log-redux/reducer';
import dbChangeLogReducer from './db-change-redux/reducer';
import taskQueueReducer from './task-queue-redux/reducer';
import notiReducer from './notification-redux/reducer';
import customerInfoSheetReducder from './customer-info-sheet-redux/reducer';
import commissionReducer from './commission-redux/reducer';
import emailSubscriptionReducer from './email-subscription-redux/reducer';
import notiTemplate from './notification-template-redux/reducer';
import exchargeReducer from './excharge-redux/reducer';
import exportFuelReducer from './export-fuel-redux/reducer';
import zonepuReducer from './zone-pickup-redux/reducer';

export default (history, injectedReducers) => combineReducers({
  router: connectRouter(history),
  form: formReducer,
  oidc: oidcReducer,
  auth: authReducer,
  user: userReducer,
  account: accountReducer,
  document: documentReducer,
  subscriptions: subscriptionsReducer,
  station: stationReducer,
  report: reportReducer,
  utility: utilityReducer,
  common: commonReducer,
  region: regionReducer,
  area: areaReducer,
  menu: menuReducer,
  role: roleReducer,
  auto: autoReducer,
  policy: policyReducer,
  manageStation: manageStationReducer,
  department: departmentReducer,
  staff: staffReducer,
  email: emailManagement,
  userMenu: userMenuReducer,
  cache: cacheReducer,
  service: serviceReducer,
  pickupRoute: pickupRouteReducer,
  feature: featureReducer,
  webhook: webhookReducer,
  partner: partnerReducer,
  asset: assetReducer,
  assetCommon: assetCommonReducer,
  assetSetting: assetSettingReducer,
  assetHandover: assetHandoverReducer,
  assetTransfer: assetTransferReducer,
  assetSale: assetSaleReducer,
  assetAdjust: assetAdjustReducer,
  requestHistory: requestHistoryReducer,
  notification: notificationReducer,
  errorLogs: errorLogsReducer,
  dbChangeLogs: dbChangeLogReducer,
  taskQueue: taskQueueReducer,
  notif: notiReducer,
  customerInfoSheet: customerInfoSheetReducder,
  commission: commissionReducer,
  emailSubscription: emailSubscriptionReducer,
  notiTemplate: notiTemplate,
  excharge: exchargeReducer,
  exportFuel: exportFuelReducer,
  zonepu: zonepuReducer,
  ...injectedReducers,
});
