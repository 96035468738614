import { createSelector } from 'reselect';
import { initialState } from './reducer';

const regionState = (state) => state.region || initialState;

const selectRegions = () => createSelector(
  regionState,
  ({ regions }) => regions
);
const selectRegioning = () => createSelector(
  regionState,
  ({ regioning }) => regioning
);

const selectAllProvinces = () => createSelector(
  regionState,
  ({ provinceAlls }) => provinceAlls
);
const selectProvinces = () => createSelector(
  regionState,
  ({ provinces }) => provinces
);
const selectProvincesing = () => createSelector(
  regionState,
  ({ provinceing }) => provinceing
);

const selectDistricts = () => createSelector(
  regionState,
  ({ districts }) => districts
);
const selectDistricting = () => createSelector(
  regionState,
  ({ districting }) => districting
);

const selectWards = () => createSelector(
  regionState,
  ({ wards }) => wards
);
const selectWarding = () => createSelector(
  regionState,
  ({ warding }) => warding
);

const selectRegionWards = () => createSelector(
  regionState,
  ({ regionwards }) => regionwards
);
const selectRegionWardsing = () => createSelector(
  regionState,
  ({ regionwardsing }) => regionwardsing
);

const selectRegionStations = () => createSelector(
  regionState,
  ({ regionstations }) => regionstations
);
const selectRegionStationsing = () => createSelector(
  regionState,
  ({ regionstationsing }) => regionstationsing
);
const selectPagination = () => createSelector(
  regionState,
  ({ pagination }) => pagination
);

const selectRegionStaffs = () => createSelector(
  regionState,
  ({ regionstaffs }) => regionstaffs
);
const selectRegionStaffsing = () => createSelector(
  regionState,
  ({ regionstaffsing }) => regionstaffsing
);

const selectPanners = () => createSelector(
  regionState,
  ({ panners }) => panners
);
const selectPannersing = () => createSelector(
  regionState,
  ({ pannersing }) => pannersing
);

const selectTrees = () => createSelector(
  regionState,
  ({ trees }) => trees
);
const selectTreesing = () => createSelector(
  regionState,
  ({ treesing }) => treesing
);

export {
  regionState,
  selectRegions,
  selectRegioning,
  selectAllProvinces,
  selectProvinces,
  selectProvincesing,
  selectDistricts,
  selectDistricting,
  selectWards,
  selectWarding,
  selectPanners,
  selectPannersing,
  selectRegionWards,
  selectRegionWardsing,
  selectRegionStations,
  selectRegionStationsing,
  selectPagination,
  selectRegionStaffs,
  selectRegionStaffsing,
  selectTrees,
  selectTreesing
};
