import { createAction, handleActions, combineActions } from 'redux-actions';

// - Actions
export const registerRequest = createAction('REGISTER_REQUEST');
export const registerSuccess = createAction('REGISTER_SUCCESS');
export const registerFailure = createAction('PREGISTER_FAILURE');

export const loginRequest = createAction('LOGIN_REQUEST');
export const loginSuccess = createAction('LOGIN_SUCCESS');
export const loginFailure = createAction('LOGIN_FAILURE');

export const forgotRequest = createAction('FORGOT_REQUEST');
export const forgotSuccess = createAction('FORGOT_SUCCESS');
export const forgotFailure = createAction('FORGOT_FAILURE');

export const failure = createAction('AUTH_ACTION_FAILURE');


// - Initial State
export const initialState = {
  registerLoading: false,
  registerSuccess: false,
  registerMessage: '',

  loginLoading: false,
  loginSuccess: false,
  loginMessage: '',

  forgotLoading: false,
  forgotSuccess: false,
  forgotMessage: '',

  socialLoading: false,
  socialSuccess: false,
  socialMessage: '',
};

// - Reducers
const reducer = handleActions({
  /** Register */
  [combineActions(registerRequest)]: (state) => ({
    ...state,
    registerLoading: true,
    registerSuccess: false,
    registerMessage: '',
  }),
  [registerSuccess]: (state) => {
    return {
      ...state,
      registerLoading: false,
      registerSuccess: true,
      registerMessage: ''
    };
  },
  [registerFailure]: (state, { payload }) => {
    return {
      ...state,
      registerLoading: false,
      registerMessage: payload.message || '',
    };
  },

  /** Login */
  [loginRequest]: (state) => {
    return {
      ...state,
      loginLoading: true,
      loginSuccess: false,
      loginMessage: '',
    };
  },

  [loginSuccess]: (state) => {
    return {
      ...state,
      loginLoading: false,
      loginSuccess: true,
      loginMessage: '',
    };
  },
  [loginFailure]: (state, { payload }) => {
    return {
      ...state,
      loginLoading: false,
      loginMessage: payload.message || '',
    };
  },

  /** Forgot */
  [forgotRequest]: (state) => {
    return {
      ...state,
      forgotLoading: true,
      forgotSuccess: false,
      forgotMessage: '',
    };
  },

  [forgotSuccess]: (state) => {
    return {
      ...state,
      forgotLoading: false,
      forgotSuccess: true,
      forgotMessage: '',
    };
  },
  [forgotFailure]: (state, { payload }) => {
    return {
      ...state,
      forgotLoading: false,
      forgotMessage: payload.message || '',
    };
  },

  [failure]: (state, action) => ({
    ...state,
    registerLoading: false,
    registerSuccess: false,
    registerMessage: '',

    loginLoading: false,
    loginSuccess: false,
    loginMessage: '',

    forgotLoading: false,
    forgotSuccess: false,
    forgotMessage: '',

    socialLoading: false,
    socialSuccess: false,
    socialMessage: '',
  }),
}, initialState);

export default reducer;
