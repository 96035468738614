/* eslint-disable react/jsx-handler-names */
import React from 'react'
import { Modal, Select } from 'antd'
import { lifecycle, compose as recompose, withHandlers, withState } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Auth from '../../storage/Auth';
import { selectStations, selectStation, selectOpenStation } from '../../reduxs/station-redux/selectors';
import { openStationRequest, setStationSuccess } from '../../reduxs/station-redux/reducer';

const currentStation = Auth.getStation();

const Station = (props) => {
    const { close, open, message, stations, station, value, setValue, changeStation } = props
    return (
        <Modal
            title={'Chọn bưu cục'}
            centered
            closable={false}
            visible={open}
            maskClosable={false}
            width={'420px'}
            footer={null}
            className="modal-station"
            onOk={close}
            onCancel={close}
        >
            <div className="row">
                <div className="col-md-12">
                    <Select defaultValue={station && station.STATION_CODE} style={{ width: '100%' }} className="uppercase" onChange={(value) => setValue(value)}>
                        {
                            stations && stations.map((station, key) => {
                                return (
                                    <Select.Option key={key} value={station.STATION_CODE} className="uppercase">{station.STATION_CODE} - {station.ADD3 ? station.ADD3 : station.NAME}</Select.Option>
                                )
                            })
                        }
                    </Select>
                    <p className="text-danger">{message ? message : ''}</p>
                </div>
                <div className="col-md-12 text-center">
                    <button className="btn btn-sm btn-default" onClick={close}>Hủy bỏ</button>&ensp;
                    <button className="btn btn-sm btn-primary" disabled={!value} onClick={changeStation}>Thay đổi</button>
                </div>
            </div>
        </Modal>
    )
}

export function mapDispatchToProps(dispatch) {
    return {
        checkStation: (data) => dispatch(openStationRequest(data)),
        setStation: (data) => dispatch(setStationSuccess(data))
    };
}

const mapStateToProps = createStructuredSelector({
    stations: selectStations(),
    station: selectStation(),
    open: selectOpenStation()
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default recompose(
    withConnect,
    withState('message', 'setMessage', null),
    withState('value', 'setValue', null),
    withHandlers({
        changeStation: ({ stations = [], checkStation, setStation, setMessage, value }) => () => {
            const station = stations.find((station) => station.STATION_CODE === value);
            if (station) {
                Auth.setStation(value);
                setStation({ station });
                checkStation(false);
                setTimeout(() => window.location.reload(), 100)
            }
            setMessage('');
        },
        close: ({ station, checkStation, setMessage }) => () => {
            if (station) {
                checkStation(false);
                setMessage('');
            } else {
                setMessage('Vui lòng chọn đơn vị');
            }
        }
    }),
    lifecycle({
        componentDidMount() {
            if (!currentStation) {
                this.props.checkStation(true);
            }
        },
        componentDidUpdate(props) {
            if (this.props.stations && this.props.stations !== props.stations) {
                const { stations, station, changeStation, checkStation } = this.props;
                if (stations && stations.length === 1) {
                    changeStation(stations[0].STATION_CODE);
                } else if (currentStation && stations && stations.length > 1) {
                    if (station) {
                        checkStation(false);
                    } else {
                        checkStation(true);
                    }
                }
            }
        }
    }),
)(Station);

// export default Station
