import React from 'react';
import { Modal, Checkbox, Collapse } from 'antd'
import { CaretRightOutlined, UserOutlined, CarOutlined } from '@ant-design/icons';
import moment from 'moment';

const { Panel } = Collapse;

const MenuForm = (props) => {
  const { open, close, partner } = props;

  return (
    <Modal
      visible={open}
      style={{ top: 20 }}
      width={'560px'}
      footer={null}
      maskClosable={false}
      onOk={() => close(false) }
      onCancel={() => close(false) }
      className="modal-view-detail"
    >
      <div className="view-profile">
        <h4 className="bold">HỒ SƠ CỘNG TÁC VIÊN</h4>
        <div className="group-profile">
          <div className="">
            <Collapse
              bordered={false}
              expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
              className="collapse-partner"
            >
              <h4 className="title-group-partner bold"><UserOutlined />&ensp;Thông tin cá nhân</h4>
              <Panel header="Thông tin cơ bản" key="1" className="collapse-panel">
                <div className="group-img">
                  <div className="item-img">
                    <label>
                      <img src={partner?.Avatar} alt=""/>
                      <span className="hint">Ảnh chân dung</span>
                    </label>
                  </div>
                </div>
                <div className="group-item">
                  <span>Họ và Tên:</span>
                  <label>{partner?.FullName}</label>
                </div>
                <div className="group-item">
                  <span>Số ĐT:</span>
                  <label>{partner?.PhoneNumber}</label>
                </div>
                <div className="group-item">
                  <span>Email:</span>
                  <label>{partner?.Email}</label>
                </div>
                <div className="group-item">
                  <span>Giới tính:</span>
                  <label>{partner?.Gender ? 'Nam': 'Nữ'}</label>
                </div>
                <div className="group-item">
                  <span>Ngày sinh:</span>
                  <label>{partner?.DateOfBirth ? moment(partner?.DateOfBirth).format('DD-MM-YYYY'): 'N/A'}</label>
                </div>
                <div className="group-item">
                  <span>Đia điểm:</span>
                  <label>{partner?.ProvinceName} - {partner?.DistrictName}</label>
                </div>
                <div className="group-item">
                  <span>Công việc:</span>
                  <label>{partner?.JobName}</label>
                </div>
              </Panel>
              <Panel header="CMT / Thẻ căn cước / Hộ chiếu" key="2" className="collapse-panel">
                <div className="group-img">
                  <div className="item-img">
                    <label>
                      <img src={partner?.IdFrontImage} alt=""/>
                      <span className="hint">Mặt trước</span>
                    </label>
                    <label>
                      <img src={partner?.IdBackImage} alt=""/>
                      <span className="hint">Mặt sau</span>
                    </label>
                  </div>
                </div>
                <div className="group-item">
                  <span>Số CMT/CC/HC:</span>
                  <label>{partner?.IdNumber}</label>
                </div>
                <div className="group-item">
                  <span>Ngày cấp:</span>
                  <label>{partner?.IdDateOfIssue && moment(partner.IdDateOfIssue).format('DD-MM-YYYY')}</label>
                </div>
                <div className="group-item">
                  <span>Nơi cấp:</span>
                  <label>{partner?.IdProvinceName}</label>
                </div>
              </Panel>
              <Panel header="Giấy phép lái xe" key="3" className="collapse-panel">
                <div className="group-img">
                  <div className="item-img">
                    <label>
                      <img src={partner?.DriversLicenseFrontImage} alt=""/>
                      <span className="hint">Mặt trước</span>
                    </label>
                    <label>
                      <img src={partner?.DriversLicenseBackImage} alt=""/>
                      <span className="hint">Mặt sau</span>
                    </label>
                  </div>
                </div>
                <div className="group-item">
                  <span>Số bằn lái xe:</span>
                  <label>{partner?.DriversLicenseNumber}</label>
                </div>
                <div className="group-item">
                  <span>Loại bằng:</span>
                  <label>{partner?.DriversLicenseClass}</label>
                </div>
              </Panel>
              <Panel header="Liên hệ khẩn cấp" key="4" className="collapse-panel">
                <div className="group-item">
                  <span>Người liên hệ:</span>
                  <label>{partner?.EmergencyContactPerson}</label>
                </div>
                <div className="group-item">
                  <span>Quan hệ:</span>
                  <label>{partner?.Relationship}</label>
                </div>
                <div className="group-item">
                  <span>Số ĐT:</span>
                  <label>{partner?.EmergencyPhoneNumber}</label>
                </div>
                <div className="group-item">
                  <span>Địa chỉ:</span>
                  <label>{partner?.EmergencyAddress}</label>
                </div>
              </Panel>
              <Panel header="Tài khoản ngân hàng" key="5" className="collapse-panel">
                <div className="group-item">
                  <span>Ngân hàng:</span>
                  <label>{partner?.BankName}</label>
                </div>
                <div className="group-item">
                  <span>Tên chủ TK:</span>
                  <label>{partner?.BankAccountName}</label>
                </div>
                <div className="group-item">
                  <span>Số Khoản:</span>
                  <label>{partner?.BankAccountNumber}</label>
                </div>
              </Panel>
              <Panel header="Cam kết với PCSPOST" key="6" className="collapse-panel">
                { partner?.CollaboratorCommitments && partner.CollaboratorCommitments.length > 0 &&
                  partner.CollaboratorCommitments.map((item) => {
                    return <p><Checkbox checked>{item.CommitmentName}</Checkbox></p>
                  })
                }
              </Panel>
            </Collapse>
            <Collapse
              bordered={false}
              expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
              className="collapse-partner"
            >
              <h4 className="title-group-partner bold"><CarOutlined/>&ensp;Thông tin phương tiện</h4>
              <Panel header="Hình ảnh xe" key="1" className="collapse-panel">
                <div className="group-img">
                  <div className="item-img">
                    <label>
                      <img src={partner?.VehicleFrontSideImage} alt=""/>
                      <span className="hint">Trước xe</span>
                    </label>
                    <label>
                      <img src={partner?.VehicleBackSideImage} alt=""/>
                      <span className="hint">Sau xe</span>
                    </label>
                    <label>
                      <img src={partner?.VehicleBodyImage} alt=""/>
                      <span className="hint">Thân xe</span>
                    </label>
                  </div>
                </div>
              </Panel>
              <Panel header="Giấy đăng ký xe" key="2" className="collapse-panel">
                <div className="group-img">
                  <div className="item-img">
                    <label>
                      <img src={partner?.VehicleRegistrationCertificateFrontImage} alt=""/>
                      <span className="hint">Trước xe</span>
                    </label>
                    <label>
                      <img src={partner?.VehicleRegistrationCertificateBackImage} alt=""/>
                      <span className="hint">Sau xe</span>
                    </label>
                  </div>
                </div>
                <div className="group-item">
                  <span>Biển số xe:</span>
                  <label>{partner?.VehicleNumberPlate}</label>
                </div>
                <div className="group-item">
                  <span>Hãng xe:</span>
                  <label>{partner?.VehicleBrand}</label>
                </div>
                <div className="group-item">
                  <span>Năm sản xuất:</span>
                  <label>{partner?.VehicleManufacturingYear}</label>
                </div>
              </Panel>
              <Panel header="Bảo hiển xe" key="3" className="collapse-panel">
                <div className="group-img">
                  <div className="item-img">
                    <label>
                      <img src={partner?.VehicleInsuranceFrontImage} alt=""/>
                      <span className="hint">Mặt trước</span>
                    </label>
                    <label>
                      <img src={partner?.VehicleInsuranceBackImage} alt=""/>
                      <span className="hint">Mặt sau</span>
                    </label>
                  </div>
                </div>
              </Panel>
              <Panel header="Giấy đăng kiểm" key="4" className="collapse-panel">
                <div className="group-img">
                  <div className="item-img">
                    <label>
                      <img src={partner?.CertificateOfTechnicalFrontImage} alt=""/>
                      <span className="hint">Trước xe</span>
                    </label>
                    <label>
                      <img src={partner?.CertificateOfTechnicalBackImage} alt=""/>
                      <span className="hint">Sau xe</span>
                    </label>
                  </div>
                </div>
                <div className="group-item">
                  <span>Số đăng kiểm:</span>
                  <label>{partner?.VehicleNumberPlate}</label>
                </div>
                <div className="group-item">
                  <span>Ngày hết hạn:</span>
                  <label>{partner?.CertificateOfTechnicalExpiredDate && moment(partner.CertificateOfTechnicalExpiredDate).format('DD-MM-YYYY')}</label>
                </div>
              </Panel>
              <Panel header="Tình trạng sở hữu xe" key="5" className="collapse-panel">
                <div className="group-item">
                  <span>Loại sở hữu xe:</span>
                  <label>{partner?.VehicleOwnerType}</label>
                </div>
                <div className="group-item">
                  <span>Chủ phương tiện:</span>
                  <label>{partner?.VehicleOwner}</label>
                </div>
                <div className="group-item">
                  <span>Số điện thoại:</span>
                  <label>{partner?.VehicleOwnerPhone}</label>
                </div>
                <div className="group-item">
                  <span>Địa chỉ Email:</span>
                  <label>{partner?.VehicleOwnerEmail}</label>
                </div>
                <div className="group-item">
                  <span>Tên trên Cavet:</span>
                  <label>{partner?.VehicleRegistrationName}</label>
                </div>
              </Panel>
            </Collapse>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default MenuForm;
