
import './style.scss';
import React from 'react';
import { Icons } from '../../assets';
import { Layout, Table, Select, Pagination, Modal, Checkbox, Input, DatePicker, Button, Space, Popover, Row, Col } from 'antd';
import { SearchOutlined, MoreOutlined } from '@ant-design/icons'
import RestrictPage from '../../components/RestrictComponents/RestrictPage';
import ZoneForm from './components/Zone';
import StationForm from './components/Station';
import TypeForm from './components/Type';
import CodeForm from './components/Code';
import EmailFee from './components/EmailFee';
import Schedule from './schedule/ScheduleVM';
import ZoneHistory from './components/ZoneHistory';
import TermProfile from './profile/DrawerTerm';
import DocumentForm from './document/ViewDoc';
import OtherSetting from './other-setting';
import { ACC_HELPER } from './Helper';
import { remove } from 'lodash';

const { Content } = Layout;

const content = (props) => {
    const { setHiddenColumns, hiddenColumns, allColumns } = props;
    return (
        <Row>
            {
                allColumns.map(x => {
                    return (
                        <Col span={12} key={x.value}>
                            <Checkbox checked={hiddenColumns.indexOf(x.value) === -1} onChange={e => {
                                if (!e.target.checked) {
                                    if (hiddenColumns.indexOf(x.value) === -1) {
                                        setHiddenColumns([...hiddenColumns, x.value])
                                    }
                                } else {
                                    let _tmp = [...hiddenColumns]
                                    remove(_tmp, z => z === x.value)
                                    setHiddenColumns(_tmp)
                                }
                            }}>{x.label}</Checkbox>
                        </Col>
                    )
                })
            }
        </Row>
    )
}


const AccountsV = (props) => {
    const { customers, customersing, params, pagination, rowKeys, onSelectChange, fetchAccounts, setParams } = props;
    const { account, open, isMobile, show, modal, onOpenStation, openStation, onOpenZone, showZoneHistory, setShowZoneHistory, setAccount, openEmail, onOpenEmail, onOpenType, onOpenSche, openCode, onOpenCode, showConfirm, setShowConfirm, setSelected, confirmSteps, createAccIfNotExists, onChangeCreateAcc, selected } = props;
    const { showProfile, setShowProfile, showDoc, setShowDoc, stations, types, staffSource, onSearchStaff, verifySteps, filterMode, setFilterMode, onEnter, hiddenColumns, showOtherSetting, onShowOtherSetting } = props;

    const size = params.Size && Number(params.Size) > 0 ? Number(params.Size) : 20;
    const offset = params.Offset && Number(params.Offset) > 0 ? Number(params.Offset) : 0;
    const total = pagination && pagination.Total && Number(pagination.Total) > 0 ? Number(pagination.Total) : 0;
    const n_start = total > 0 ? (((offset - 1) * size) + 1) : 0;
    const n_end = total > 0 ? ((offset * size) > total ? total : offset * size) : 0;

    return (
        <RestrictPage>
            <Content className="page-account">
                <div className="panel-header">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="header-title">
                                <i className="fa fa-home icon" aria-hidden="true"></i>
                                <span>Tài khoản</span>
                                <span className="icon-next"><i className="fa fa-angle-right"></i></span>
                                <span className="active">Danh sách tài khoản</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={isMobile ? "col-md-12 pd-0" : "col-md-12"}>
                    <div className="panel-body">
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='filter-wrapper'>
                                    {(params?.StationCode =="89304" || params?.StationCode =="50401" || params?.StationCode =="50402" || params?.StationCode =="60401" || params?.StationCode =="60402" || params?.StationCode =="60403") ?
                                    <></>
                                    :
                                    <div className='filter-control-wrapper'>
                                        <div><label className='bold'>Bưu cục quản lý</label></div>
                                        <div>
                                            <Select
                                                allowClear
                                                showSearch
                                                options={stations.map(x => ({ value: x.STATION_CODE, label: `${x.STATION_CODE} - ${x.NAME}` }))}
                                                dropdownMatchSelectWidth={false}
                                                className="w-100"
                                                value={params?.StationCode}
                                                filterOption={(value, option) => {
                                                    return option.label.toLowerCase().indexOf(value.toLowerCase()) >= 0
                                                }}
                                                placeholder='Tìm theo bưu cục'
                                                onChange={value => setParams({ ...params, StationCode: value })}
                                            />
                                        </div>
                                    </div>
                                    }
                                    

                                    <div className='filter-control-wrapper'>
                                        <div><label className='bold'>Đã cấp mã KH?</label></div>
                                        <div>
                                            <Select
                                                allowClear
                                                showSearch
                                                options={[{ value: 'true', label: 'Đã cấp' }, { value: 'false', label: 'Chưa cấp' }]}
                                                dropdownMatchSelectWidth={false}
                                                className="w-100"
                                                value={params?.HasAccountNo}
                                                filterOption={(value, option) => {
                                                    return option.label.toLowerCase().indexOf(value.toLowerCase()) >= 0
                                                }}
                                                onChange={value => {
                                                    setParams({ ...params, HasAccountNo: value })
                                                }}
                                                placeholder='Gõ để tìm kiếm...'
                                            />
                                        </div>
                                    </div>

                                    <div className='filter-control-wrapper'>
                                        <div><label className='bold'>Loại tài khoản</label></div>
                                        <div>
                                            <Select
                                                allowClear
                                                showSearch
                                                options={types.map(x => ({ value: x.Id, label: x.Name }))}
                                                dropdownMatchSelectWidth={false}
                                                className="w-100"
                                                value={params?.AccountTypeId}
                                                filterOption={(value, option) => {
                                                    return option.label.toLowerCase().indexOf(value.toLowerCase()) >= 0
                                                }}
                                                onChange={value => setParams({ ...params, AccountTypeId: value })}
                                                placeholder="Tìm theo loại tài khoản"
                                            />
                                        </div>
                                    </div>

                                    {filterMode === 'advanced' && (
                                        <div className='filter-control-wrapper'>
                                            <div><label className='bold'>Bán hàng</label></div>
                                            <div>
                                                <Select
                                                    allowClear
                                                    showSearch
                                                    options={staffSource.map(x => ({ value: x.StaffCode, label: `${x.StaffCode} - ${x.StaffName}` }))}
                                                    dropdownMatchSelectWidth={false}
                                                    className="w-100"
                                                    value={params?.SalesStaffCode}
                                                    filterOption={false}
                                                    onChange={value => {
                                                        setParams({ ...params, SalesStaffCode: value })
                                                    }}
                                                    placeholder='Gõ để tìm kiếm...'
                                                    onSearch={onSearchStaff}
                                                />
                                            </div>
                                        </div>
                                    )}

                                    {filterMode === 'advanced' && (
                                        <div className='filter-control-wrapper'>
                                            <div><label className='bold'>Quản lý vùng</label></div>
                                            <div>
                                                <Select
                                                    allowClear
                                                    showSearch
                                                    options={staffSource.map(x => ({ value: x.StaffCode, label: `${x.StaffCode} - ${x.StaffName}` }))}
                                                    dropdownMatchSelectWidth={false}
                                                    className="w-100"
                                                    value={params?.ZoneManagerStaffCode}
                                                    filterOption={false}
                                                    onChange={value => {
                                                        setParams({ ...params, ZoneManagerStaffCode: value })
                                                    }}
                                                    placeholder='Gõ để tìm kiếm...'
                                                    onSearch={onSearchStaff}
                                                />
                                            </div>
                                        </div>
                                    )}

                                    {filterMode === 'advanced' && (
                                        <div className='filter-control-wrapper'>
                                            <div><label className='bold'>Chăm sóc KH</label></div>
                                            <div>
                                                <Select
                                                    allowClear
                                                    showSearch
                                                    options={staffSource.map(x => ({ value: x.StaffCode, label: `${x.StaffCode} - ${x.StaffName}` }))}
                                                    dropdownMatchSelectWidth={false}
                                                    className="w-100"
                                                    value={params?.CustomerCareStaffCode}
                                                    filterOption={false}
                                                    onChange={value => {
                                                        setParams({ ...params, CustomerCareStaffCode: value })
                                                    }}
                                                    placeholder='Gõ để tìm kiếm...'
                                                    onSearch={onSearchStaff}
                                                />
                                            </div>
                                        </div>
                                    )}

                                    {filterMode === 'advanced' && (
                                        <div className='filter-control-wrapper'>
                                            <div><label className='bold'>Giao nhận</label></div>
                                            <div>
                                                <Select
                                                    allowClear
                                                    showSearch
                                                    options={staffSource.map(x => ({ value: x.StaffCode, label: `${x.StaffCode} - ${x.StaffName}` }))}
                                                    dropdownMatchSelectWidth={false}
                                                    className="w-100"
                                                    value={params?.PuStaffCode}
                                                    filterOption={false}
                                                    onChange={value => {
                                                        setParams({ ...params, PuStaffCode: value })
                                                    }}
                                                    placeholder='Gõ để tìm kiếm...'
                                                    onSearch={onSearchStaff}
                                                />
                                            </div>
                                        </div>
                                    )}

                                    {filterMode === 'advanced' && (
                                        <div className='filter-control-wrapper'>
                                            <div><label className='bold'>Vận hành</label></div>
                                            <div>
                                                <Select
                                                    allowClear
                                                    showSearch
                                                    options={staffSource.map(x => ({ value: x.StaffCode, label: `${x.StaffCode} - ${x.StaffName}` }))}
                                                    dropdownMatchSelectWidth={false}
                                                    className="w-100"
                                                    value={params?.OpStaffCode}
                                                    filterOption={false}
                                                    onChange={value => {
                                                        setParams({ ...params, OpStaffCode: value })
                                                    }}
                                                    placeholder='Gõ để tìm kiếm...'
                                                    onSearch={onSearchStaff}
                                                />
                                            </div>
                                        </div>
                                    )}

                                    {filterMode === 'advanced' && (
                                        <div className='filter-control-wrapper'>
                                            <div><label className='bold'>Kế toán</label></div>
                                            <div>
                                                <Select
                                                    allowClear
                                                    showSearch
                                                    options={staffSource.map(x => ({ value: x.StaffCode, label: `${x.StaffCode} - ${x.StaffName}` }))}
                                                    dropdownMatchSelectWidth={false}
                                                    className="w-100"
                                                    value={params?.AccStaffCode}
                                                    filterOption={false}
                                                    onChange={value => {
                                                        setParams({ ...params, AccStaffCode: value })
                                                    }}
                                                    placeholder='Gõ để tìm kiếm...'
                                                    onSearch={onSearchStaff}
                                                />
                                            </div>
                                        </div>
                                    )}

                                    {filterMode === 'advanced' && (
                                        <div className='filter-control-wrapper'>
                                            <div><label className='bold'>Thời gian tạo</label></div>
                                            <div>
                                                <DatePicker.RangePicker
                                                    className="w-100"
                                                    style={{ width: '100%', marginBottom: 8 }}
                                                    value={params?.CreatedDate}
                                                    onChange={(value) => {
                                                        setParams({ ...params, CreatedDate: value })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    )}

                                    {filterMode === 'advanced' && (
                                        <div className='filter-control-wrapper'>
                                            <div><label className='bold'>Tài khoản đăng nhập?</label></div>
                                            <div>
                                                <Select
                                                    allowClear
                                                    showSearch
                                                    options={[{ value: 'true', label: 'Đã có' }, { value: 'false', label: 'Chưa có' }]}
                                                    dropdownMatchSelectWidth={false}
                                                    className="w-100"
                                                    value={params?.HasUserName}
                                                    filterOption={(value, option) => {
                                                        return option.label.toLowerCase().indexOf(value.toLowerCase()) >= 0
                                                    }}
                                                    onChange={value => {
                                                        setParams({ ...params, HasUserName: value })
                                                    }}
                                                    placeholder='Gõ để tìm kiếm...'
                                                />
                                            </div>
                                        </div>
                                    )}

                                    {filterMode === 'advanced' && (
                                        <div className='filter-control-wrapper'>
                                            <div><label className='bold'>Trạng thái hồ sơ?</label></div>
                                            <div>
                                                <Select
                                                    allowClear
                                                    showSearch
                                                    options={[{ value: 'true', label: 'Đầy đủ thông tin' }, { value: 'false', label: 'Chưa đầy đủ thông tin' }]}
                                                    dropdownMatchSelectWidth={false}
                                                    className="w-100"
                                                    value={params?.ProfileComplete}
                                                    filterOption={(value, option) => {
                                                        return option.label.toLowerCase().indexOf(value.toLowerCase()) >= 0
                                                    }}
                                                    onChange={value => {
                                                        setParams({ ...params, ProfileComplete: value })
                                                    }}
                                                    placeholder='Gõ để tìm kiếm...'
                                                />
                                            </div>
                                        </div>
                                    )}

                                    {filterMode === 'advanced' && (
                                        <div className='filter-control-wrapper'>
                                            <div><label className='bold'>Nguồn khách hàng</label></div>
                                            <div>
                                                <Select
                                                    allowClear
                                                    showSearch
                                                    options={[{ value: 'PCSPOST_WEB', label: 'Web pcspost.vn' }, { value: 'FACEBOOK', label: 'Facebook' }, { value: 'GOOGLE', label: 'Google' }, { value: 'QUANG_CAO', label: 'Quảng cáo' }, { value: 'CA_NHAN', label: 'Cá nhân' }, { value: 'KHAC', label: 'Khác' }]}
                                                    dropdownMatchSelectWidth={false}
                                                    className="w-100"
                                                    value={params?.Source}
                                                    filterOption={(value, option) => {
                                                        return option.label.toLowerCase().indexOf(value.toLowerCase()) >= 0
                                                    }}
                                                    onChange={value => {
                                                        setParams({ ...params, Source: value })
                                                    }}
                                                    placeholder='Gõ để tìm kiếm...'
                                                />
                                            </div>
                                        </div>
                                    )}

                                    <div className='filter-control-wrapper'>
                                        <div><label className='bold'>Cấp độ khách hàng</label></div>
                                        <div>
                                            <Select
                                                allowClear
                                                showSearch
                                                options={verifySteps}
                                                dropdownMatchSelectWidth={false}
                                                className="w-100"
                                                value={params?.CurrentVerifyStep}
                                                filterOption={(value, option) => {
                                                    return option.label.toLowerCase().indexOf(value.toLowerCase()) >= 0
                                                }}
                                                onChange={value => {
                                                    setParams({ ...params, CurrentVerifyStep: value })
                                                }}
                                                placeholder='Gõ để tìm kiếm...'
                                            />
                                        </div>
                                    </div>

                                    <div className='filter-control-wrapper'>
                                        <div><label className='bold'>Từ khoá</label></div>
                                        <div>
                                            <Input
                                                allowClear
                                                className='w-100'
                                                value={params?.Query}
                                                onChange={e => setParams({ ...params, Query: e.target.value })}
                                                placeholder="Mã, Tên, SĐT, Email"
                                                onKeyDown={onEnter}
                                            />
                                        </div>
                                    </div>

                                    <div className='filter-control-wrapper'>
                                        <div><label>&nbsp;</label></div>
                                        <div>
                                            <Space>
                                                <Button icon={<MoreOutlined />} onClick={() => setFilterMode(filterMode === 'basic' ? 'advanced' : 'basic')}>{filterMode === 'basic' ? 'Mở rộng' : 'Cơ bản'}</Button>
                                                <Button icon={<SearchOutlined />} type='primary' loading={customersing} onClick={() => fetchAccounts()}>Lọc</Button>
                                            </Space>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{ marginBottom: 5 }}>
                            <div className="col-md-6">
                                <label className="total-filter">
                                    <img src={Icons.common.filter} alt="Số tài khoản" />|&ensp;<span>{n_start} - {n_end}</span> trong tổng <span>{total}</span> tài khoản
                                </label>
                            </div>

                            <div className='col-md-6 text-right'>
                                <Popover overlayInnerStyle={{ maxWidth: 420 }} placement="bottomRight" content={content(props)} trigger="click">
                                    <Button size="small" type="primary"><i className="fa fa-th" /></Button>
                                </Popover>
                            </div>
                        </div>
                        <div className="import-table">
                            <div className="row">
                                <div className="col-md-12">
                                    <Table
                                        loading={customersing}
                                        bordered
                                        className="table-main table-role"
                                        columns={ACC_HELPER.createColumnTable(props).filter(x => hiddenColumns.indexOf(x.key) === -1)}
                                        rowKey='Id'
                                        dataSource={customers}
                                        pagination={false}
                                        size="small"
                                        scroll={{ x: 1800, y: 'calc(100vh - 280px)' }}
                                        rowSelection={{ selectedRowKeys: rowKeys, onChange: onSelectChange }}
                                        locale={{ emptyText: 'Không có dữ liệu' }}
                                    />
                                </div>
                            </div>
                            <div className={params.CustomerId ? 'hidden' : "row mr-t-5 "}>
                                <div className="col-md-6">
                                    <span>Hiển thị &ensp;
                                        <Select value={size || 20} style={{ width: 120 }} onChange={(size) => fetchAccounts({ Size: size, Offset: 1 })}>
                                            <Select.Option value="20">20</Select.Option>
                                            <Select.Option value="50">50</Select.Option>
                                            <Select.Option value="100">100</Select.Option>
                                            <Select.Option value="200">200</Select.Option>
                                        </Select>
                                        &nbsp; tài khoản / trang
                                    </span>
                                </div>
                                <div className="col-md-6 text-right">
                                    <Pagination current={offset || 1} pageSize={size || 20} total={total || 0} onChange={(offset) => fetchAccounts({ Offset: offset })} showSizeChanger={false} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {open && account && <ZoneForm open={open} close={onOpenZone} account={account} fetchAccounts={fetchAccounts} />}
                {openStation && account && <StationForm open={openStation} close={onOpenStation} account={account} fetchAccounts={fetchAccounts} />}
                {show && account && <TypeForm open={show} close={onOpenType} account={account} fetchAccounts={fetchAccounts} />}
                {openCode && account && <CodeForm open={openCode} close={onOpenCode} account={account} fetchAccounts={fetchAccounts} />}
                {modal && account && <Schedule open={modal} close={onOpenSche} account={account} fetchAccounts={fetchAccounts} />}
                {openEmail && account && <EmailFee open={openEmail} close={onOpenEmail} account={account} fetchAccounts={fetchAccounts} />}
                {showZoneHistory && account && <ZoneHistory onClose={() => setShowZoneHistory(false, () => setAccount(null))} account={account} />}
                <TermProfile open={showProfile} close={setShowProfile} account={account} fetchAccounts={fetchAccounts} />
                {showDoc && account && <DocumentForm open={showDoc} close={setShowDoc} account={account} />}
                <OtherSetting visible={showOtherSetting} info={account} onCancel={() => onShowOtherSetting(null)} onSuccess={() => {
                    onShowOtherSetting(null)
                    fetchAccounts()
                }} />
                <Modal
                    title="Xác nhận"
                    centered
                    visible={showConfirm}
                    onOk={() => confirmSteps()}
                    onCancel={() => setShowConfirm(false, () => setSelected(null))}
                    okText="Duyệt"
                    cancelText="Hủy"
                >
                    <div className="row">
                        <div className="col-md-12">
                            <p>Bạn chắc chắn muốn duyệt tài khoản này?</p>
                            {
                                selected && !selected.hasUserName &&
                                <div>
                                    <Checkbox checked={createAccIfNotExists} onClick={onChangeCreateAcc}>Tạo tài khoản đăng nhập POST</Checkbox>
                                </div>
                            }
                        </div>
                    </div>
                </Modal>
            </Content>
        </RestrictPage>
    )
}

export default AccountsV
