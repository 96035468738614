import { createAction, handleActions } from 'redux-actions';

export const updateServiceCacheRequest = createAction('UPDATE_SERVICE_CACHE_REQUEST');
export const updateServiceCacheSuccess = createAction('UPDATE_SERVICE_CACHE_SUCCESS');

export const updateServiceCacheOPRequest = createAction('UPDATE_SERVICE_CACHE_OP_REQUEST');
export const updateServiceCacheOPSuccess = createAction('UPDATE_SERVICE_CACHE_OP_SUCCESS');

export const updateServiceCacheCRMRequest = createAction('UPDATE_SERVICE_CACHE_CRM_REQUEST');
export const updateServiceCacheCRMSuccess = createAction('UPDATE_SERVICE_CACHE_CRM_SUCCESS');

export const updateIdentityServiceCacheRequest = createAction('UPDATE_IDENTITY_SERVICE_CACHE_REQUEST');
export const updateIdentityServiceCacheSuccess = createAction('UPDATE_IDENTITY_SERVICE_CACHE_SUCCESS');

export const updateService2CacheRequest = createAction('UPDATE_SERVICE2_CACHE_REQUEST');
export const updateService2CacheSuccess = createAction('UPDATE_SERVICE2_CACHE_SUCCESS');

// - Initial State
export const initialState = {
    fetching: false,
    clearingIdentityServerCache: false,
    clearingOpCache: false,
    clearingCrmCache: false,
    fetching2: false,
};

// - Reducers
const reducer = handleActions({
    [updateServiceCacheRequest]: (state) => {
        return {
            ...state,
            fetching: true
        };
    },
    [updateServiceCacheSuccess]: (state) => {
        return {
            ...state,
            fetching: false,
        };
    },
    [updateService2CacheRequest]: (state) => {
        return {
            ...state,
            fetching2: true
        };
    },
    [updateService2CacheSuccess]: (state) => {
        return {
            ...state,
            fetching2: false,
        };
    },
    [updateIdentityServiceCacheRequest]: (state) => {
        return {
            ...state,
            clearingIdentityServerCache: true
        };
        },
    [updateIdentityServiceCacheSuccess]: (state) => {
        return {
            ...state,
            clearingIdentityServerCache: false,
        };
    },
    [updateServiceCacheOPRequest]: (state) => {
        return {
            ...state,
            clearingOpCache: true
        };
    },
    [updateServiceCacheOPSuccess]: (state) => {
        return {
            ...state,
            clearingOpCache: false,
        };
    },
    [updateServiceCacheCRMRequest]: (state) => {
        return {
            ...state,
            clearingCrmCache: true
        };
        },
    [updateServiceCacheCRMSuccess]: (state) => {
        return {
            ...state,
            clearingCrmCache: false,
        };
    }
}, initialState);

export default reducer;