import { createAction, handleActions } from 'redux-actions';
// - Actions

export const assetVendorsRequest = createAction('ASSET_VENDOR_LIST_REQUEST');
export const assetVendorsSuccess = createAction('ASSET_VENDOR_LIST_SUCCESS');
export const deleteVendorRequest = createAction('ASSET_DEL_VENDOR_REQUEST');

export const assetLocationsRequest = createAction('ASSET_LOCATION_LIST_REQUEST');
export const assetLocationsSuccess = createAction('ASSET_LOCATION_LIST_SUCCESS');
export const deleteLocationRequest = createAction('ASSET_DEL_LOCATION_REQUEST');

export const assetGroupsRequest = createAction('ASSET_GROUP_LIST_REQUEST');
export const assetGroupsSuccess = createAction('ASSET_GROUP_LIST_SUCCESS');
export const deleteGroupRequest = createAction('ASSET_DEL_GROUP_REQUEST');

export const assetTypesRequest = createAction('ASSET_TYPE_LIST_REQUEST');
export const assetTypesSuccess = createAction('ASSET_TYPE_LIST_SUCCESS');
export const deleteTypeRequest = createAction('ASSET_DEL_TYPE_REQUEST');

export const failure = createAction('ASSET_ACTION_FAILURE');

// - Initial State
export const initialState = {
  vendors: [],
  vendorsing: false,

  locations: [],
  locationsing: false,

  groups: [],
  groupsing: false,

  types: [],
  typesing: false,

  pagination: {
    Page: 1,
    Size: 50,
    Total: 0
  },

  error: null,
};

// - Reducers
const reducer = handleActions({
  
  [assetVendorsRequest]: (state) => ({
    ...state, vendorsing: true,
  }),
  [assetVendorsSuccess]: (state, { payload: { vendors, pagination } }) => ({
    ...state, vendors, pagination, vendorsing: false,
  }),

  [assetLocationsRequest]: (state) => ({
    ...state, locationsing: true,
  }),
  [assetLocationsSuccess]: (state, { payload: { locations, pagination } }) => ({
    ...state, locations, pagination, locationsing: false,
  }),

  [assetGroupsRequest]: (state) => ({
    ...state, groupsing: true,
  }),
  [assetGroupsSuccess]: (state, { payload: { groups, pagination } }) => ({
    ...state, groups, pagination, groupsing: false,
  }),

  [assetTypesRequest]: (state) => ({
    ...state, typesing: true,
  }),
  [assetTypesSuccess]: (state, { payload: { types, pagination } }) => ({
    ...state, types, pagination, typesing: false,
  }),

  [failure]: (state, action) => ({
    ...state,
    error: action.error,
    vendorsing: false,
  }),
}, initialState);

export default reducer;
