import { call, put, takeLatest } from 'redux-saga/effects';
import * as NotificationService from '../services/notification-service';

import {
    getMyNotificationSuccess,
    markAsReadSuccess,
    deteleNotificationSuccess,
    markAsUnreadSuccess,
    markAllAsReadSuccess,
    collectingNewNotificationsSuccess
} from './reducer';

export function* getMyNotifications({ payload = {} }) {
    try {
        const { AddToTop } = payload;
        const { Result } = yield call(NotificationService.getMyNotifications, payload);
        yield put(getMyNotificationSuccess({ notifications: Result.Notifications || [], flashNotifications: Result.FlashNotifications || [], pagination: Result.Pagination || {}, totalNew: Result.TotalNewNotifications || 0, AddToTop }));
    } catch (error) {
        yield put(getMyNotificationSuccess({ notifications: [] }));
    }
}

export function* markAsRead({ payload }) {
    try {
        const { Result: { Success, Total, TotalNew } } = yield call(NotificationService.markAsRead, payload);
        if (Success) {
            yield put(markAsReadSuccess(
                {
                    Id: payload,
                    Total: Total,
                    TotalNew: TotalNew
                }));
        }
        yield put(markAsReadSuccess({}));
    } catch (error) {
        yield put(markAsReadSuccess({}));
    }
}

export function* markAsUnread({ payload }) {
    try {
        const { Result: { Success, Total, TotalNew } } = yield call(NotificationService.markAsUnread, payload);
        if (Success) {
            yield put(markAsUnreadSuccess(
                {
                    Id: payload,
                    Total: Total,
                    TotalNew: TotalNew
                }));
        }
        yield put(markAsUnreadSuccess({}));
    } catch (error) {
        yield put(markAsUnreadSuccess({}));
    }
}

export function* deleteNotification({ payload }) {
    try {
        const { Result: { Success, Items, Total, TotalNew } } = yield call(NotificationService.deleteNotification, payload);
        if (Success) {
            yield put(deteleNotificationSuccess({
                Id: payload.Id,
                Items: Items,
                Total,
                TotalNew
            }));
        }
    } catch (error) {
    }
}

export function* markAllAsRead({ payload }) {
    try {
        const { Result: { Success, Total, TotalNew } } = yield call(NotificationService.markAllAsRead, payload);
        if (Success) {
            yield put(markAllAsReadSuccess({ Total, TotalNew }));
        }
    } catch (error) {
    }
}

export function* collectNewNotifications({ payload: { getNotification, isFirst } }) {
    try {
        const { Result: { Success, TotalNew, TotalCollections } } = yield call(NotificationService.collectNewNotifications);
        if (Success) {
            if (getNotification) {
                if (isFirst) {
                    yield call(getMyNotifications, { payload: { PageSize: 25, PageOffset: 0 } })
                } else if (TotalCollections > 0) {
                    yield call(getMyNotifications, { payload: { PageSize: TotalCollections, PageOffset: 0, AddToTop: true } })
                }
            }
        }

        yield put(collectingNewNotificationsSuccess({ TotalNew, Success, TotalCollections }));
    } catch (error) {
    }
}
const saga = [
    takeLatest('GET_MY_NOTIFICATION_REQUEST', getMyNotifications),
    takeLatest('MARK_AS_READ_REQUEST', markAsRead),
    takeLatest('DELETE_NOTIFICATION_REQUEST', deleteNotification),
    takeLatest('MARK_AS_UNREAD_REQUEST', markAsUnread),
    takeLatest('MARK_ALL_AS_READ_REQUEST', markAllAsRead),
    takeLatest('COLLECT_NEW_NOTIFICATIONS_REQUEST', collectNewNotifications)
]

export default saga;