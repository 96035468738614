import React from 'react';
import { connect } from 'react-redux';
import { compose as recompose, withHandlers, withState, lifecycle, withPropsOnChange } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { Avatar, Dropdown, Menu } from 'antd';
import parse from 'html-react-parser';
import { CheckOutlined, CloseOutlined, EyeOutlined } from '@ant-design/icons';
import Moment from 'react-moment';
import { markAsReadRequest, deteleNotificationRequest, setOnNotification, markAsUnreadRequest } from '../../reducer/reducer';

import './styles.scss';
import ViewNotification from './viewNotification';
import { processText } from './../../helpers';

const iconMore = require('../../assets/ellipsis.svg');

const MenuContent = (props) => {
    const { handleMenuClick, data: { SeenState, NotifId } } = props;
    return (
        <Menu className="notification-item-menu-list" onClick={(e) => handleMenuClick(e, NotifId)}>
            {
                SeenState === 'NEW' && <Menu.Item key="MARK_AS_READ" icon={<CheckOutlined />}> Đánh dấu là đã xem</Menu.Item>
            }
            {
                SeenState === 'READED' && <Menu.Item key="MARK_AS_UNREAD" icon={<CheckOutlined />}>Đánh dấu là chưa xem</Menu.Item>
            }
            <Menu.Item key="VIEW" icon={<EyeOutlined />}>Xem nội dung thông báo</Menu.Item>
            <Menu.Item key="DELETE" icon={<CloseOutlined />}>Xóa thông báo này</Menu.Item>
        </Menu>
    )
}

const getIconClass = (_type) => {
    let icon = 'default';
    switch (_type) {
        case 'COMMENT': {
            icon = 'comment';
            break;
        }
        default: {

        }
    }
    return `notification-item_icon--${icon}`
}

const NotificationItem = (props) => {
    const { showDetail, onMarkAsRead, onClickContextMenu, showContextMenu, setShowContextMenu, notification: { Body, NotifId, SeenState, IconType }, onMouseEnter, onHover, onMouseLeave, setHoverOnMenu, hoverOnMenu } = props;
    const { notification, onHandleOk, onHandCancel, myRef, onNotification, onClickNotification } = props;

    const isUnread = SeenState === 'NEW';

    const isHover = onNotification === NotifId && onHover && !hoverOnMenu;

    return (
        <div className={`notification-item-container ${(isHover ? 'notification-item-container---hover' : '')}`} onMouseEnter={() => onMouseEnter(NotifId)} onMouseLeave={() => onMouseLeave()}>
            <div ref={myRef} className="notification-item">
                <div className="notification-item_avatar">
                    <Avatar src={Body.NotifImage.Uri} />
                    <span className={`notification-item_icon ${getIconClass(IconType)}`}></span>
                </div>
                <div className="notification-item_body" style={{paddingRight: (isUnread ? 20 : 0)}} onClick={(e) => {
                    if (e.target.tagName !== 'A') {
                        onClickNotification(notification)
                    }
                }}>
                    <div className={`notification-item_body__container ${(isUnread ? 'unread' : '')}`}>
                        <div>
                            <span className="notification-item_body__content" style={{ WebkitBoxOrient: 'vertical', WebkitLineClamp: '3', display: '-webkit-box' }}>
                                {parse(processText(Body.Text, Body.Ranges))}
                            </span>
                        </div>
                        <div className="notification-item_footer">
                            <span className="notification-item_footer__time" style={{ color: (isUnread ? '#1876f2' : 'inherit'), fontFamily: (isUnread ? 'Roboto-Medium' : 'Roboto-Regular') }}><Moment locale="vi" fromNow>{Body.CreationTime}</Moment></span>
                        </div>
                    </div>
                </div>
                <div className="notification-item_right">
                    <div className="notification-item_right__action">
                        <div>
                            <div className="notification-item_right__action___button">
                                {
                                    isUnread && <span onClick={() => onMarkAsRead(NotifId)} className="notification-item_right__action--mask_as_read"></span>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                {
                    onHover &&
                    (<div className="notification-item_menu">
                        <Dropdown visible={showContextMenu} placement="bottomLeft" style={{ padding: 0 }} overlay={<MenuContent handleMenuClick={onClickContextMenu} data={props.notification} />}>
                            <div onClick={() => setShowContextMenu(!showContextMenu)} className="notification-item_menu__handle" onMouseEnter={() => setHoverOnMenu(true)} onMouseLeave={() => setHoverOnMenu(false)}>
                                <span>
                                    <img src={iconMore} style={{ width: 18 }} alt="more" />
                                </span>
                            </div>
                        </Dropdown>
                    </div>)
                }
            </div>
            {showDetail && <ViewNotification handleOk={onHandleOk} handleCancel={onHandCancel} data={notification} />}
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        markAsRead: (params) => dispatch(markAsReadRequest(params)),
        deleteNotif: (params) => dispatch(deteleNotificationRequest(params)),
        setOnNotif: (params) => dispatch(setOnNotification(params)),
        markAsUnread: (params) => dispatch(markAsUnreadRequest(params)),
    };
}

const mapStateToProps = createStructuredSelector({
    makingAsRead: store => store.notification && store.notification.makingAsRead,
    onNotification: store => store.notification && store.notification.onNotification,
    notifications: store => store.notification && store.notification.notifications
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default recompose(
    withConnect,
    withState('onHover', 'setOnHover', false),
    withState('hoverOnMenu', 'setHoverOnMenu', false),
    withState('showContextMenu', 'setShowContextMenu', false),
    withState('showDetail', 'setShowDetail', false),
    withState('myRef', 'setMyRef', React.createRef()),
    withPropsOnChange(['makingAsRead'], props => {
    }),
    withHandlers({
        onMarkAsRead: ({ markAsRead }) => (_id) => markAsRead(_id),
        onMarkAsUnread: ({ markAsUnread }) => (_id) => markAsUnread(_id),
        onMouseEnter: ({ setOnHover, setOnNotif }) => (_id) => { setOnHover(true); setOnNotif({ NotifId: _id }) },
        onMouseLeave: ({ setOnHover, setShowContextMenu }) => () => { setOnHover(false); setShowContextMenu(false) },
        onDelete: ({ deleteNotif, notifications }) => (_id) => deleteNotif({ Id: _id, PageOffset: notifications.length, PageSize: 25 })
    }),
    withHandlers({
        onClickContextMenu: ({ onMarkAsRead, onDelete, setShowContextMenu, setShowDetail, setOnHover, onMarkAsUnread }) => ({ key }, id) => {
            switch (key) {
                case 'MARK_AS_READ': {
                    onMarkAsRead(id);
                    break;
                }
                case 'MARK_AS_UNREAD': {
                    onMarkAsUnread(id);
                    break;
                }
                case 'VIEW': {
                    onMarkAsRead(id);
                    setOnHover(false);
                    setShowDetail(true);
                    break;
                }
                case 'DELETE': {
                    onDelete(id);
                    break;
                }
                default: {
                    break;
                }
            }
            setShowContextMenu(false);
        },
        onHandleOk: ({ setShowDetail }) => () => { setShowDetail(false) },
        onHandCancel: ({ setShowDetail }) => () => { setShowDetail(false) }
    }),
    withHandlers({
        onClickNotification: ({ onMarkAsRead, setOnHover, setShowDetail }) => (notification) => {
            const { Url, OpenInNewTab, SeenState, NotifId } = notification;
            if (Url) {
                if (SeenState === 'NEW') {
                    onMarkAsRead(NotifId);
                }
                window.open(Url, (OpenInNewTab ? '_blank' : 'parent'));
            } else {
                if (SeenState === 'NEW') {
                    onMarkAsRead(NotifId);
                }
                setOnHover(false);
                setShowDetail(true);
            }
        }
    }),
    lifecycle({
        componentDidMount() {

        }
    })
)(NotificationItem)