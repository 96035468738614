import { lifecycle, compose as recompose, withHandlers, withState } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';
import { message } from 'antd';

import { selectRevenues, selectRevenuesing } from '../../../reduxs/report-redux/selectors';
import { reportRevenueRequest } from '../../../reduxs/report-redux/reducer';
import { exportRevenueComparisonRequest } from '../../../reduxs/common-redux/reducer'

import RevenueV from './RevenueV';

export function mapDispatchToProps(dispatch) {
  return {
    getRevenues: (params) => dispatch(reportRevenueRequest(params)),
    downloadRevenueComparison: (params) => dispatch(exportRevenueComparisonRequest(params))
  };
}

const mapStateToProps = createStructuredSelector({
  user: (state) => state.oidc && state.oidc.user,
  revenues: selectRevenues(),
  loading: selectRevenuesing(),
  downloadingRevenue: (store) => store.common && store.common.downloadingRevenue
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default recompose(
  withConnect,
  withState('params', 'setParams', {
    Owner: "1",
    GroupBy: 'SALES',
    FirstMonth: moment().subtract(1, 'month'),
    SecondMonth: moment(),
  }),
  withHandlers({
    onReportRevenue: ({ params, setParams, getRevenues }) => (param) => {
      const _params = {...params, ...param}
      setParams(_params)
      const data = {
        ..._params,
        FirstMonth: _params.FirstMonth ? _params.FirstMonth.format('MM') : moment().subtract(1, 'month').format('MM'),
        FirstYear: _params.FirstMonth ?_params.FirstMonth.format('YYYY') : moment().subtract(1, 'month').format('YYYY'),
        SecondMonth: _params.SecondMonth ? _params.SecondMonth.format('MM') : moment().format('MM'),
        SecondYear: _params.SecondMonth ?_params.SecondMonth.format('YYYY') : moment().format('YYYY')
      }
      getRevenues(data);
    },
    onClickDownload: ({ params, downloadRevenueComparison }) => () => {
      const _params = {...params}
      const data = {
        ..._params,
        FirstMonth: _params.FirstMonth ? _params.FirstMonth.format('MM') : moment().subtract(1, 'month').format('MM'),
        FirstYear: _params.FirstMonth ?_params.FirstMonth.format('YYYY') : moment().subtract(1, 'month').format('YYYY'),
        SecondMonth: _params.SecondMonth ? _params.SecondMonth.format('MM') : moment().format('MM'),
        SecondYear: _params.SecondMonth ?_params.SecondMonth.format('YYYY') : moment().format('YYYY')
      }
      downloadRevenueComparison({ params: data, cb: (msg) => {
        if (msg === 'success') {
          message.success('Tải xuống thành công');
        } else {
          message.error('Tải xuống thất bại');
        }
      } });
    }
  }),
  lifecycle({
    componentDidMount() {
      this.props.onReportRevenue();
    }
  }),
)(RevenueV);