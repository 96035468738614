import React from 'react';
import { Layout, Button } from 'antd';
import RestrictPage from './../../components/RestrictComponents/RestrictPage';
import './style.scss';
const { Content } = Layout;

const CacheV = (props) => {
    const { updateServiceCache, loading, updateIdentityServiceCache, clearingIdentityServerCache, updateOpCache, updateCrmCache, clearingOpCache, clearingCrmCache, updateApiv2DevCache, clearingCache2 } = props;
    return (
        <RestrictPage>
            <Content className="page-menus">
                <div className="panel-header">
                    <div className="header-title">
                        <i className="fa fa-home icon" aria-hidden="true"></i>
                        <span>Hệ thống</span>
                        <span className="icon-next"><i className="fa fa-angle-right"></i></span>
                        <span className="active">Cache</span>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="panel-body">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="btn-grp-handle">
                                    <Button loading={loading} onClick={() => updateServiceCache()}>Update Cache apiv2.pcs.vn</Button>
                                    <Button loading={clearingOpCache} onClick={() => updateOpCache()}>Update Cache api-op.pcs.vn</Button>
                                    <Button loading={clearingCrmCache} onClick={() => updateCrmCache()}>Update Cache api-crm.pcs.vn</Button>
                                    <Button loading={clearingCache2} onClick={() => updateApiv2DevCache()}>Update Cache apiv2-dev.pcs.vn</Button>
                                    <Button loading={clearingIdentityServerCache} onClick={() => updateIdentityServiceCache()}>Update Cache id.pcs.vn</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Content>
        </RestrictPage>
    );
};

export default CacheV