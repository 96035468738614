import { createAction, handleActions } from 'redux-actions';
// - Actions

export const fetchMenusRequest = createAction('FETCH_MENUS_REQUEST');
export const fetchMenusSuccess = createAction('FETCH_MENUS_SUCCESS');

export const removerMenusRequest = createAction('REMOVE_MENUS_REQUEST');

export const fetchClientsRequest = createAction('FETCH_CLIENTS_REQUEST');
export const fetchClientsSuccess = createAction('FETCH_CLIENTS_SUCCESS');

export const fetchGroupsRequest = createAction('FETCH_GROUPS_REQUEST');
export const fetchGroupsSuccess = createAction('FETCH_GROUPS_SUCCESS');

export const fetchMenuGroupsRequest = createAction('FETCH_MENU_GROUPS_REQUEST');
export const fetchMenuGroupsSuccess = createAction('FETCH_MENU_GROUPS_SUCCESS');

export const fetchUserMenusRequest = createAction('FETCH_USER_MENUS_REQUEST');
export const fetchUserMenusSuccess = createAction('FETCH_USER_MENUS_SUCCESS');
export const removeUserMenusRequest = createAction('REMOVE_USER_MENUS_REQUEST');

export const saveGroupMenusRequest = createAction('SAVE_GROUP_MENUS_REQUEST');

export const failure = createAction('MENU_ACTION_FAILURE');


// - Initial State
export const initialState = {
  menus: [],
  menusing: false,

  clients: [],
  clientsing: false,

  groups: [],
  groupsing: false,

  menugroups: [],
  menugroupsing: false,

  usermenus: [],
  usermenusing: false,

  error: null,
};

// - Reducers
const reducer = handleActions({
  [fetchMenusRequest]: (state) => {
    return { ...state, menusing: true };
  },
  [fetchMenusSuccess]: (state, {payload: { menus }}) => {
    return { ...state, menus, menusing: false  };
  },

  [fetchClientsRequest]: (state) => {
    return { ...state, clientsing: true };
  },
  [fetchClientsSuccess]: (state, {payload: { clients }}) => {
    return { ...state, clients, clientsing: false  };
  },

  [fetchGroupsRequest]: (state) => {
    return { ...state, groupsing: true };
  },
  [fetchGroupsSuccess]: (state, {payload: { groups }}) => {
    return { ...state, groups, groupsing: false  };
  },

  [fetchMenuGroupsRequest]: (state) => {
    return { ...state, menugroups: [], menugroupsing: true };
  },
  [fetchMenuGroupsSuccess]: (state, {payload: { menugroups }}) => {
    return { ...state, menugroups, menugroupsing: false  };
  },
  
  [fetchUserMenusRequest]: (state) => {
    return { ...state, usermenusing: true };
  },
  [fetchUserMenusSuccess]: (state, {payload: { usermenus }}) => {
    return { ...state, usermenus, usermenusing: false  };
  },

  [failure]: (state, action) => ({
    ...state,
    error: action.error,
    usermenusing: false,
  }),
}, initialState);

export default reducer;
