
import axios from '../utils/axiosInstance';

export const getCustomerDocs = async (params = {}) => {
    try {
      const { data } = await axios.get(`/api/file/customer/account/${params.AccountNo}`, {params});
      return data;
    } catch (error) {
      throw error;
    }
};

export const addCustomerDoc = async (note) => {
  try {
    const { data } = await axios.post(`/api/customer/doc`, note);
    return data;
  } catch (error) {
    throw error;
  }
};

export const editCustomerDoc = async (note) => {
  try {
    const { data } = await axios.put(`/api/customer/doc`, note);
    return data;
  } catch (error) {
    throw error;
  }
};

export const uploadCustomerDoc = async (params) => {
  try {
    console.log('-------', params)
    const body = new FormData();
    if(params.Files && params.Files.length) {
      params.Files.forEach(file => {
        body.append('Files', file)
      });
      body.append('CustomerId', params.CustomerId)
      body.append('CategoryId', params.CategoryId)
      const { data } = await axios.post(`/api/file/upload`, body);
      return data;
    } else {
      throw new Error('Không có file tải lên')
    }
  } catch (error) {
    throw error;
  }
};

export const deleteCustomerDoc = async (params) => {
  try {
    const { data } = await axios.delete(`/api/customer/doc`, { params: params });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getCustomerDocType = async (params ={}) => {
  try {
    const { data } = await axios.get('/api/file/category', {params});
    return data;
  } catch (error) {
    throw error;
  }
};
