import { createAction, handleActions } from 'redux-actions';
// - Actions

export const getExportFuelSurchargesRequest = createAction('GET_LIST_EXPORT_FUEL_SURCHARGES_REQUEST');
export const getExportFuelSurchargesSuccess = createAction('GET_LIST_EXPORT_FUEL_SURCHARGES_SUCCESS');

export const deleteExportFuelSurchargesRequest = createAction('REMOVE_EXPORT_FUEL_SURCHARGES_REQUEST');

export const failure = createAction('EXPORT_FUEL_SURCHARGES_ACTION_FAILURE');


// - Initial State
export const initialState = {
  surcharges: [],
  pagination: {
    Page: 1,
    Size: 50,
    Total: 0
  },
  loading: false,

  error: null,
};

// - Reducers
const reducer = handleActions({
  [getExportFuelSurchargesRequest]: (state) => {
    return { ...state, loading: true };
  },
  [getExportFuelSurchargesSuccess]: (state, { payload: { surcharges, pagination } }) => {
    return { ...state, surcharges, pagination, loading: false };
  },

  [failure]: (state, action) => ({
    ...state,
    error: action.error,
    loading: false,
  }),
}, initialState);

export default reducer;
