import { createAction, handleActions } from 'redux-actions';

// - Actions
export const getRequestHistoriesRequest = createAction('GET_REQUEST_HISTORIES_REQUEST');
export const getRequestHistoriesSuccess = createAction('GET_REQUEST_HISTORIES_SUCCESS');

export const getRequestHistoryGroupRequest = createAction('GET_REQUEST_HISTORY_GROUP_REQUEST');
export const getRequestHistoryGroupSuccess = createAction('GET_REQUEST_HISTORY_GROUP_SUCCESS');

export const failure = createAction('COMMON_REQUEST_ACTION_FAILURE');

// - Initial State
export const initialState = {
    fetching: false,
    pagination: {
        Page: 1,
        Size: 50,
        Total: 0
    },
    histories: [],
    historyGroupByPath: [],
    fetchingGroup: false,
    error: null
};

// - Reducers
const reducer = handleActions({
    [getRequestHistoriesRequest]: (state) => {
      return { ...state, fetching: true };
    },
    [getRequestHistoriesSuccess]: (state,  { payload: { histories, pagination } }) => {
      return { ...state, histories: histories, pagination: pagination, fetching: false };
    },
    [getRequestHistoryGroupRequest]: (state) => {
      return { ...state, fetchingGroup: true };
    },
    [getRequestHistoryGroupSuccess]: (state,  { payload: { histories } }) => {
      return { ...state, historyGroupByPath: histories, fetchingGroup: false };
    },
    [failure]: (state, action) => ({
        ...state,
        error: action.error,
        fetching: false
    }),
}, initialState);
  
export default reducer;
  