import { createSelector } from 'reselect';
import { initialState } from './reducer';

const accountState = (state) => state.account || initialState;

const selectAccounts = () => createSelector(accountState,({ accounts }) => accounts);
const selectAccountsing = () => createSelector(accountState,({ accountsing }) => accountsing);
const selectCustomers = () => createSelector(accountState,({ customers }) => customers);
const selectCustomersing = () => createSelector(accountState,({ customersing }) => customersing);
const selectCodes = () => createSelector(accountState,({ codes }) => codes);
const selectCodesing = () => createSelector(accountState,({ codesing }) => codesing);
const selectCodeDetails = () => createSelector(accountState,({ codedetails }) => codedetails);
const selectCodeDetailsing = () => createSelector(accountState,({ codedetailsing }) => codedetailsing);
const selectPagination = () => createSelector(accountState,({ pagination }) => pagination);

const selectSteps = () => createSelector(accountState,({ steps }) => steps);

const selectTypes = () => createSelector(accountState,({ types }) => types);

const selectZones = () => createSelector(accountState,({ zones }) => zones);
const selectZoning = () => createSelector(accountState,({ zoning }) => zoning);

const selectAccount = () => createSelector(accountState,({ user }) => user);
const selectAccounting = () => createSelector(accountState,({ usering }) => usering);

const selectInvoices = () => createSelector(accountState,({ invoices }) => invoices);
const selectInvoicesing = () => createSelector(accountState,({ invoicesing }) => invoicesing);

const selectWarehouses = () => createSelector(accountState, ({ warehouses }) => warehouses);
const selectWarehousesing = () => createSelector(accountState, ({ warehousesing }) => warehousesing);

const selectBanks = () => createSelector(accountState, ({ banks }) => banks);
const selectBanksing = () => createSelector(accountState, ({ banksing }) => banksing);

const selectEmails = () => createSelector(accountState,({ emails }) => emails);
const selectEmailsing = () => createSelector(accountState,({ emailsing }) => emailsing);

const selectSchedules = () => createSelector(accountState, ({ schedules }) => schedules );
const selectSchedulesing = () => createSelector(accountState, ({ schedulesing }) => schedulesing );
const selectScheduleTypes = () => createSelector(accountState, ({ scheduletypes }) => scheduletypes );

export {
  accountState,
  selectAccounts,
  selectAccountsing,
  selectCustomers,
  selectCustomersing,
  selectCodes,
  selectCodesing,
  selectCodeDetails,
  selectCodeDetailsing,
  selectPagination,
  selectSteps,
  selectTypes,
  selectZones,
  selectZoning,
  selectAccount,
  selectAccounting,
  selectInvoices,
  selectInvoicesing,
  selectWarehouses,
  selectWarehousesing,
  selectBanks,
  selectBanksing,
  selectEmails,
  selectEmailsing,
  selectSchedules,
  selectSchedulesing,
  selectScheduleTypes,
};
