import React from 'react';
import moment from 'moment';
import { compose as recompose, lifecycle, withHandlers, withState } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Icons } from '../../assets';
import { Button, message, Modal } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { confirmCustomerInfoSheetRequest } from '../../reduxs/customer-info-sheet-redux/reducer';

const CustomerInfoSheetForm = (props) => {
    const { info, signature, onConfirm } = props;
    return (
            <div className="scrollable-container">
                <div className="paper-page">
                    <table className="w-100">
                        <tbody>
                            <tr>
                                <td style={{width: '25%'}}>
                                    <img src={Icons.common.logo} alt="logo"/>
                                </td>
                                <td style={{width: '45%'}}>
                                    <div className="table-title">PHIẾU THÔNG TIN KHÁCH HÀNG MỚI</div>
                                </td>
                                <td style={{width: '30%'}} className="text-right">
                                    <div>Mã KH&ensp;<label style={{width: '80px'}} className="bold">{info.AccountNo}</label></div>
                                    <div>Đơn vị&ensp;<label style={{width: '80px'}} className="bold">{info.StationCode}</label></div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <br/>
                    <table className="table table-bordered table-sheet">
                        <thead>
                            <tr>
                                <th className="bold" style={{width: '40px'}}>STT</th>
                                <th className="bold" style={{width: '26%'}}>TIÊU CHỈ</th>
                                <th className="bold" colSpan="2">NỘI DUNG</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>Tên Khách hàng:</td>
                                <td colSpan="2">{info.CustomerName}</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>Địa chỉ lấy hàng:</td>
                                <td colSpan="2">{info.WarehouseAddress && info.WarehouseAddress.map((ware) => <span>{ware}</span>)}</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>Số điện thoại:</td>
                                <td colSpan="2">{info.PhoneNumber && info.PhoneNumber.join(', ')}</td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>Người liên hệ gửi hàng:</td>
                                <td colSpan="2">{info.Contact && info.Contact.map((item) => <span>{item.Name} - {item.PhoneNumber}</span>)}</td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>Người liên hệ thanh toán:</td>
                                <td colSpan="2">{info.PaymentContactPerson}</td>
                            </tr>
                            <tr>
                                <td>6</td>
                                <td>Chức vụ & phòng ban:</td>
                                <td colSpan="2">{info.Position}</td>
                            </tr>
                            <tr>
                                <td>7</td>
                                <td>Loại hàng hóa</td>
                                <td colSpan="2">{info.PackageType}</td>
                            </tr>
                            <tr>
                                <td>8</td>
                                <td>Doanh số dự tính:</td>
                                <td colSpan="2">{info.EstimatedRevenue}</td>
                            </tr>
                            <tr>
                                <td>9</td>
                                <td>Loại Khách hàng:</td>
                                <td colSpan="2">{info.CustomerType}</td>
                            </tr>
                            <tr>
                                <td>10</td>
                                <td>Bảng giá nội địa áp dụng:</td>
                                <td colSpan="2">{info.DomesticPriceList}</td>
                            </tr>
                            <tr>
                                <td>11</td>
                                <td>Bảng giá quốc tế áp dụng:</td>
                                <td colSpan="2">{info.ExportPriceList}</td>
                            </tr>
                            <tr>
                                <td>12</td>
                                <td>Chu kỳ thanh toán </td>
                                <td colSpan="2">{info.PaymentCycle}</td>
                            </tr>
                            <tr>
                                <td>13</td>
                                <td>Tháng tính khách mới:</td>
                                <td colSpan="2">{info.StartTime}</td>
                            </tr>
                            <tr>
                                <td rowSpan="3">14</td>
                                <td rowSpan="3">Hoa hồng khách hàng:</td>
                                <td colSpan="2">{info.Commission?.Commission}</td>
                            </tr>
                            <tr><td colSpan="2">Tên người nhận: {info.Commission?.Recipient}</td></tr>
                            <tr>
                                <td colSpan="2">
                                    <span>Số TK Ngân hàng: {info.Commission?.BankAccountNo}</span>
                                    <span>Tại NH: {info.Commission?.BankName}</span>
                                </td>
                            </tr>

                            <tr>
                                <td rowSpan="3">15</td>
                                <td rowSpan="3">Thông tin hóa đơn</td>
                                <td>Tên Công ty:</td>
                                <td>{info.InvoiceInfo?.CustomerName}</td>
                            </tr>
                            <tr><td style={{width: '22%'}}>Địa chỉ:</td><td>{info.InvoiceInfo?.Address}</td></tr>
                            <tr><td>Mã số thuế:</td><td>{info.InvoiceInfo?.TaxCode}</td></tr>

                            <tr>
                                <td rowSpan="6">16</td>
                                <td rowSpan="6">Thông tin Nhân viên</td>
                                <td>Nhân viên Giao dịch:</td>
                                <td>{info.Staffs?.GiaoDich}</td>
                            </tr>
                            <tr><td>Nhân viên CSKH:</td><td>{info.Staffs?.CSKH}</td></tr>
                            <tr><td>Nhân viên DVKH:</td><td>{info.Staffs?.DVKH}</td></tr>
                            <tr><td>Nhân Viên KD:</td><td>{info.Staffs?.KD}</td></tr>
                            <tr><td>Nhân viên kế toán:</td><td>{info.Staffs?.KeToan}</td></tr>
                            <tr><td>TP. Kinh Doanh:</td><td>{info.Staffs?.TPKD}</td></tr>

                            <tr>
                                <td rowSpan="4">17</td>
                                <td rowSpan="4">Yêu cầu đặc biệt:</td>
                                <td>1.	Bảng kê:</td>
                                <td>{info.OtherRequirement?.Catalog}</td>
                            </tr>
                            <tr><td>2.	Hóa đơn:</td><td>{info.OtherRequirement?.Invoice}</td></tr>
                            <tr><td>3.	Báo phát:</td><td>{info.OtherRequirement?.DeliveryNotice}</td></tr>
                            <tr><td>4.	Khác:</td><td>{info.OtherRequirement?.OtherRequire}</td></tr>
                        </tbody>
                    </table>
                    <p className="text-note bold">Tôi xin chịu trách nhiệm với những thông tin ở trên và đồng ý giải quyết các vấn đề thu hồi công nợ mà Tôi đã đề xuất.</p>
                    <p className="text-right"><i>Hà Nội, Ngày&emsp;{moment(info.CreatedDate).format('DD')}&emsp;tháng&emsp;{moment(info.CreatedDate).format('MM')}&emsp;năm {moment(info.CreatedDate).format('YYYY')}</i></p>
                    <table className="w-100">
                        <tbody>
                            <tr>
                                <td style={{width: '25%', verticalAlign: 'initial'}} className="text-center">
                                    <label className="bold">Giám đốc duyệt</label>
                                    {signature.GD
                                    ? (<div className="text-center">
                                        <small className="text-success"><i className="fa fa-check"/> Đã xác nhận</small>
                                        <div className="bold">{signature.GD?.Name}</div>
                                    </div>)
                                    : <Button onClick={() => onConfirm('GD')} size="small" type="primary" icon={<CheckCircleOutlined />}>Xác nhận</Button>
                                    }
                                </td>
                                <td style={{width: '25%', verticalAlign: 'initial'}} className="text-center">
                                    <label className="bold">P. TCKT duyệt</label>
                                    {signature.KT
                                    ? (<div className="text-center">
                                        <small className="text-success"><i className="fa fa-check"/> Đã xác nhận</small>
                                        <div className="bold">{signature.TK?.Name}</div>
                                    </div>)
                                    : <Button onClick={() => onConfirm('KT')} size="small" type="primary" icon={<CheckCircleOutlined />}>Xác nhận</Button>
                                    }
                                </td>
                                <td style={{width: '25%', verticalAlign: 'initial'}} className="text-center">
                                    <label className="bold">TP kinh doanh duyệt</label>
                                    {signature.KD
                                    ? (<div className="text-center">
                                        <small className="text-success"><i className="fa fa-check"/> Đã xác nhận</small>
                                        <div className="bold">{signature.KD?.Name}</div>
                                    </div>)
                                    : <Button onClick={() => onConfirm('KD')} size="small" type="primary" icon={<CheckCircleOutlined />}>Xác nhận</Button>
                                    }
                                    
                                </td>
                                <td style={{width: '25%', verticalAlign: 'initial'}} className="text-center">
                                    <label className="bold">Nhân viên kinh doanh</label>
                                    {signature.NV
                                    ? (<div className="text-center">
                                        <small className="text-success"><i className="fa fa-check"/> Đã xác nhận</small>
                                        <div className="bold">{signature.NV?.Name}</div>
                                    </div>)
                                    : <Button onClick={() => onConfirm('NV')} size="small" type="primary" icon={<CheckCircleOutlined />}>Xác nhận</Button>
                                    }
                                    
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <br/>
                    <br/>
                    <br/>
                </div>
            </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        confirmSheet: (params) => dispatch(confirmCustomerInfoSheetRequest(params))
    };
}

const mapStateToProps = createStructuredSelector({
    user: ({oidc}) => oidc && oidc.user && oidc.user.profile,
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default recompose(
    withConnect,
    withState('info', 'setInfo', {}),
    withState('signature', 'setSignature', { 'GD': null, 'KT': null, 'KD': null, 'NV': null }),
    withHandlers({
        onConfirm: ({ confirmSheet, user, sheet, signature, setSignature, refresh }) => (Type) => {
            if(sheet && sheet.Id) {
                Modal.confirm({
                  title: 'Bạn có chắc đồng ý xác nhận',
                  content: 'Bạn đồng ý xác nhận phiếu thông tin khách mới',
                  okText: 'Có',
                  cancelText: 'Không',
                  onOk: () => {
                    confirmSheet({
                        data: {
                            Id: sheet.Id,
                            Position: Type
                        },
                        cb: (resut) => {
                            if (resut) {
                                message.success('Xác nhận thành công!');
                                const _signature = {...signature}
                                _signature[Type] = {
                                    Code: user.staffCode,
                                    Name: user.fullName,
                                    Time: new Date(),
                                    Type: Type
                                }
                                setSignature(_signature);
                                refresh()
                            } else {
                                message.error('Xác nhận thất bại!');
                            }
                        }
                    })
                  }
                });
            }
          }
    }),
    lifecycle({
        componentDidMount() {
            const { sheet, setInfo, signature, setSignature } = this.props;
            if(sheet) {
                const types = {
                    'Nhân viên giao dịch': 'GiaoDich',
                    'Nhân viên CSKH': 'CSKH',
                    'Nhân viên DVKH': 'DVKH',
                    'Nhân viên KD': 'KD',
                    'Nhân viên kế toán': 'KeToan',
                    'TP. Kinh doanh': 'TPKD'
                }
                let staffInfo = {};
                if (sheet.Staffs && sheet.Staffs.length) {
                    sheet.Staffs.forEach(staff => {
                        staffInfo[types[staff.Type]] = staff.Name
                    })
                }

                if(sheet.Approval && sheet.Approval.length) {
                    const _signature = {...signature}
                    sheet.Approval.forEach((item) => {
                        _signature[item.Type] = item;
                    })
                    setSignature(_signature);
                }

                const data = {
                    ...sheet,
                    'Id': sheet.Id,
                    'CustomerName': sheet.CustomerName,
                    'WarehouseAddress': sheet.WarehouseAddress || [],
                    'PhoneNumber': sheet.PhoneNumber || [],
                    'Contact': sheet.Contact || [],
                    'PaymentContactPerson': sheet.PaymentContactPerson,
                    'Position': sheet.Position,
                    'Department': sheet.Department,
                    'PackageType': sheet.PackageType,
                    'EstimatedRevenue': sheet.EstimatedRevenue,
                    'CustomerType': sheet.CustomerType,
                    'DomesticPriceList': sheet.DomesticPriceList,
                    'ExportPriceList': sheet.ExportPriceList,
                    'PaymentCycle': sheet.PaymentCycle,
                    'StartTime': sheet.StartTime ? moment(sheet.StartTime).format('MM-YYYY') : '',
                    'Commission': sheet.Commission,
                    'InvoiceInfo': sheet.InvoiceInfo,
                    'Staffs': staffInfo,
                    'OtherRequirement': sheet.OtherRequirement
                }
                setInfo(data)
            }
        }
    })
)(CustomerInfoSheetForm);
