import { createSelector } from 'reselect';
import { initialState } from './reducer';

const stateCommon = (state) => state.common || initialState;

const selectCustomers = () => createSelector(
  stateCommon,
  ({ customers }) => customers
);

const selectCustomering = () => createSelector(
  stateCommon,
  ({ customering }) => customering
);

const selectTypeServices = () => createSelector(
  stateCommon,
  ({ typeservices }) => typeservices
);
const selectTypeServing = () => createSelector(
  stateCommon,
  ({ typeservicing }) => typeservicing
);

const selectStationAlls = () => createSelector(
  stateCommon,
  ({ stationAlls }) => stationAlls
);
const selectStationAllsing = () => createSelector(
  stateCommon,
  ({ stationAllsing }) => stationAllsing
);

const selectStations = () => createSelector(
  stateCommon,
  ({ stations }) => stations
);
const selectStationsing = () => createSelector(
  stateCommon,
  ({ stationsing }) => stationsing
);

const selectCurrency = () => createSelector(
  stateCommon,
  ({ currencies }) => currencies
);
const selectCurrencing = () => createSelector(
  stateCommon,
  ({ currencing }) => currencing
);

const selectCalculater = () => createSelector(
  stateCommon,
  ({ calculater }) => calculater
);
const selectCalculating = () => createSelector(
  stateCommon,
  ({ calculating }) => calculating
);

const selectExtraService = () => createSelector(
  stateCommon,
  ({ extraServiceTypes }) => extraServiceTypes
);

const selectCalculaterExtra = () => createSelector(
  stateCommon,
  ({ extra }) => extra
);

const selectBatch = () => createSelector(
  stateCommon,
  ({ batch }) => batch
);

const selectStaffs = () => createSelector(
  stateCommon,
  ({ staffs }) => staffs
);
const selectStaffsing = () => createSelector(
  stateCommon,
  ({ staffsing }) => staffsing
);

const selectStaffAlls = () => createSelector(
  stateCommon, ({ staffAlls }) => staffAlls
);
const selectStaffAllsing = () => createSelector(
  stateCommon, ({ staffAllsing }) => staffAllsing
);

const selectValueModes = () => createSelector(
  stateCommon, ({ valueModes }) => valueModes
);
const selectValueModesing = () => createSelector(
  stateCommon, ({ valueModesing }) => valueModesing
);

const selectDepartments = () => createSelector(
  stateCommon, ({ departments }) => departments
);

export {
  stateCommon,
  selectCustomers,
  selectCustomering,
  selectTypeServices,
  selectTypeServing,
  selectCurrency,
  selectCurrencing,
  selectCalculater,
  selectCalculating,
  selectExtraService,
  selectCalculaterExtra,
  selectBatch,
  selectStations,
  selectStationsing,
  selectStaffs,
  selectStaffsing,
  selectStaffAlls,
  selectStaffAllsing,
  selectStationAlls,
  selectValueModes,
  selectValueModesing,
  selectStationAllsing,
  selectDepartments
};
