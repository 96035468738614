import axios from '../utils/axiosInstance';

export const getChangeLogs = async (params = {}) => {
    try {
        const {
            data
        } = await axios.get('/api/admin/database-changes', { params });
        return data;
    } catch (error) {
        throw error;
    }
};

export const getPrimaryColumns = async () => {
    try {
        const {
            data
        } = await axios.get('/api/admin/database-and-primary-columns');
        return data;
    } catch (error) {
        throw error;
    }
};