
import './style.scss';
import React from 'react';
import { Layout } from 'antd';
import { Icons } from '../../assets';

const { Content } = Layout;

const renderItem = ({ isShowItem, onChangeUrl }, path, label, icon, state = null) => {
  return isShowItem(path) &&
  (
    <div className="col-md-12 col-xs-6 pd-0">
      <div className="group-op">
        <label onClick={() => onChangeUrl({pathname: path, state: state})} className="link-op">
          <div className="img-op">
            <img src={icon} alt="" />
          </div>
          <div className="label-op">
            <span className="bold">{label}</span>
          </div>
        </label>
      </div>
    </div>
  )
}

const DashboardV = (props) => {
  return (
    <Content className="page-dashboard">
        <div className="panel-body">
          <div className="dashboard-menu-item">
            <div className="col-md-2 col-xs-12 col-pu-item">
              <div className="box-op">
                <h4 className="title"><img src={Icons.common.location} alt="" />Khu vực</h4>
                <div className="group-body-dashboard">
                  <div className="col-md-12 box-items">
                    { renderItem(props, '/quan-ly-vung-phat', 'Quản lý vùng phát', Icons.dashboard.icon_region_delivery) }
 
                    { renderItem(props, '/quan-ly-dia-danh', 'Quản lý địa danh', Icons.dashboard.icon_ls_location) }

                    { renderItem(props, '/quan-ly-phan-vung', 'DS vùng miền/khu vực', Icons.dashboard.icon_ls_region) }
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-2 col-xs-12 col-pu-item">
              <div className="box-op">
                <h4 className="title"><img src={Icons.common.account} alt="" />Tài khoản</h4>
                <div className="group-body-dashboard">
                  <div className="col-md-12 box-items">
                    { renderItem(props, '/quan-ly-tai-khoan', 'Tài khoản chờ duyệt', Icons.dashboard.icon_wt_acc) }

                    { renderItem(props, '/quan-ly-tai-khoan', 'Chưa có TK đăng nhập', Icons.dashboard.icon_no_acc, { login: true }) }

                    { renderItem(props, '/quan-ly-tai-khoan', 'Danh sách tài khoản', Icons.dashboard.icon_ls_acc, { tab: 1 }) }
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-2 col-xs-12 col-pu-item">
              <div className="box-op">
                <h4 className="title"><img src={Icons.common.service} alt="" />Hệ thống</h4>
                <div className="group-body-dashboard">
                  <div className="col-md-12 box-items">
                    { renderItem(props, '/roles', 'Vai trò người dùng', Icons.dashboard.icon_sys_role) }
                    
                    { renderItem(props, '/services', 'Cron task', Icons.dashboard.icon_sys_service) }

                    { renderItem(props, '/policy', 'Hệ thống cấp phép', Icons.dashboard.icon_sys_policy) }
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-2 col-xs-12 col-pu-item">
              <div className="box-op">
                <h4 className="title"><img src={Icons.common.cog} alt="" />Bưu cục & phòng ban</h4>
                <div className="group-body-dashboard">
                  <div className="col-md-12 box-items">
                    { renderItem(props, '/quan-ly-buu-cuc', 'Quản lý bưu cục', Icons.dashboard.icon_station) }

                    { renderItem(props, '/quan-ly-phong-ban', 'Quản lý phòng ban', Icons.dashboard.icon_department) }

                    { renderItem(props, '/quan-ly-email', 'Quản lý gửi email', Icons.dashboard.icon_email) }
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-2 col-xs-12 col-pu-item">
              <div className="box-op">
                <h4 className="title"><img src={Icons.common.report} alt="" />Báo cáo</h4>
                <div className="group-body-dashboard">
                  <div className="col-md-12 box-items">
                    { renderItem(props, '/reports/revenue', 'Báo cáo doanh thu', Icons.dashboard.icon_rp_revenue) }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </Content>
  )
}
export default DashboardV
