import { call, put, takeLatest } from 'redux-saga/effects';
import * as StaffService from '../../services/staff-service';
import moment from 'moment';
import { StaffFormAction, PassFormAction, CommissionFormAction } from "./action";
import { SubmissionError } from "redux-form";

import {
  fetchStaffsSuccess,
  fetchStaffsCommissionSuccess,
  getStaffCommissionSuccess
} from './reducer';

export function* fetchStaffs({ payload = {} }) {
  try {
    const { Result } = yield call(StaffService.fetchStaffs, payload);
    if (Result) {
      const { Data, Pagination } = Result;
      yield put(fetchStaffsSuccess({ staffs: Data, pagination: Pagination }));
    } else {
      yield put(fetchStaffsSuccess({ staffs: [], pagination: { Total: 0, Size: 0, Offset: 0 } }));
    }
  } catch (error) {
    yield put(fetchStaffsSuccess({ staffs: [], pagination: { Total: 0, Size: 0, Offset: 0 } }));
  }
}

export function* saveStaff({ payload }) {
  try {
    if (payload.Birthday) {
      var newBirthday = moment(payload.Birthday, 'DD-MM-YYYY').format('YYYY-MM-DD HH:mm:ss')
      payload.Birthday = newBirthday;
    }

    let { Result } = yield call(StaffService.saveStaff, payload);
    if (Result) {
      yield put(StaffFormAction.success("Lưu dữ liệu thành công!"));
    } else {
      const formError = new SubmissionError({
        _error: 'Có lỗi trong quá trình lưu dữ liệu!'
      });
      yield put(StaffFormAction.failure(formError));
    }
  } catch (error) {
    const formError = new SubmissionError({
      _error: error.Description ? error.Description : "Lỗi hệ thống!"
    });
    yield put(StaffFormAction.failure(formError));
  }
}

export function* changePassword({ payload }) {
  try {
    const { Result } = yield call(StaffService.changePassword, payload);
    if (Result) {
      yield put(PassFormAction.success("Đặt mật khẩu thành công"));
    } else {
      const message = "Đặt mật khẩu thất bại!";
      const formError = new SubmissionError({
        _error: message
      });
      yield put(PassFormAction.failure(formError));
    }
  } catch (error) {
    const formError = new SubmissionError({
      _error: 'Đặt mật khẩu thất bại!'
    });
    yield put(PassFormAction.failure(formError));
  }
}

export function* lockStaff({ payload }) {
  const { data, cb } = payload;
  try {
    const { Result } = yield call(StaffService.lockStaff, data);
    if (Result) {
      cb(true)
    } else {
      cb(false)
    }
  } catch (error) {
    cb(false)
  }
}

export function* unLockStaff({ payload }) {
  const { data, cb } = payload;
  try {
    const { Result } = yield call(StaffService.unLockStaff, data);
    if (Result) {
      cb(true)
    } else {
      cb(false)
    }
  } catch (error) {
    cb(false)
  }
}

//Commission
export function* fetchStaffsCommission({ payload = {} }) {
  try {
    const { Result } = yield call(StaffService.fetchStaffsCommission, payload);
    if (Result) {
      const { Data, Pagination } = Result;
      yield put(fetchStaffsCommissionSuccess({ commissions: Data, pagination: Pagination }));
    } else {
      yield put(fetchStaffsCommissionSuccess({ commissions: [], pagination: { Total: 0, Size: 0, Offset: 0 } }));
    }
  } catch (error) {
    yield put(fetchStaffsCommissionSuccess({ commissions: [], pagination: { Total: 0, Size: 0, Offset: 0 } }));
  }
}

export function* getStaffCommission({ payload = {} }) {
  try {
    const { Result } = yield call(StaffService.getStaffCommission, payload);
    if (Result) {
      yield put(getStaffCommissionSuccess({ commission: Result }));
    } else {
      yield put(getStaffCommissionSuccess({ commission: null }));
    }
  } catch (error) {
    yield put(getStaffCommissionSuccess({ commission: null }));
  }
}

export function* saveComminssion({ payload }) {
  try {
    if(payload && payload.Details.length) {
      let AppliedDate = moment().format('YYYY-MM-DD');
      let ExpiredDate = null;
      if(payload.AppliedDate && moment(payload.AppliedDate, 'DD-MM-YYYY').isValid()) {
        AppliedDate = moment(payload.AppliedDate, 'DD-MM-YYYY').format('YYYY-MM-DD').toString();
      }
      if(payload.ExpiredDate && moment(payload.ExpiredDate,'DD-MM-YYYY').isValid()) {
        ExpiredDate = moment(payload.ExpiredDate, 'DD-MM-YYYY').format('YYYY-MM-DD').toString();
      }
      let details = [...payload.Details].map((item) => {
        item['AppliedDate'] = AppliedDate;
        item['ExpiredDate'] = ExpiredDate;
        return item;
      })
      const { Result } = yield call(StaffService.saveCommission, { StaffCode: payload.StaffCode, OverApprover: payload.OverApprover, Details: details });
      if (Result) {
        yield put(CommissionFormAction.success("Lưu thành công"));
      } else {
        throw new Error('Lưu lại thất bại!')
      }
    } else {
      throw new Error('Thông tin lưu thất bại!')
    }
    
  } catch (error) {
    let message = "Lưu lại thất bại!"
    if(error && error.Description && typeof error.Description === 'string') {
      message = error.Description
    }
    const formError = new SubmissionError({
      _error: message
    });
    yield put(CommissionFormAction.failure(formError));
  }
}

export function* getCommissionCallback({ payload }) {
  const { data, cb } = payload;
  try {
    const { Result } = yield call(StaffService.getStaffCommission, data);
    if (Result) {
      cb(Result)
    } else {
      cb(null)
    }
  } catch (error) {
    cb(null)
  }
}

export function* activeCommission({ payload }) {
  const { data, cb } = payload;
  try {
    const { Result } = yield call(StaffService.activeCommission, data);
    if (Result) {
      cb(true)
    } else {
      cb(false)
    }
  } catch (error) {
    cb(false)
  }
}

export function* deleteCommission({ payload }) {
  const { data, cb } = payload;
  try {
    const { Result } = yield call(StaffService.deleteCommission, data);
    if (Result) {
      cb(true)
    } else {
      cb(false)
    }
  } catch (error) {
    cb(false)
  }
}


export default [
  takeLatest('FETCH_STAFFS_REQUEST', fetchStaffs),
  takeLatest('LOCK_STAFF_REQUEST', lockStaff),
  takeLatest('UNLOCK_STAFF_REQUEST', unLockStaff),
  takeLatest(StaffFormAction.REQUEST, saveStaff),
  takeLatest(PassFormAction.REQUEST, changePassword),

  takeLatest('FETCH_STAFFS_COMMISSION_REQUEST', fetchStaffsCommission),
  takeLatest('GET_STAFF_COMMISSION_REQUEST', getStaffCommission),
  takeLatest('GET_STAFF_COMMISSION_CALLBACK', getCommissionCallback),
  takeLatest('ACTIVE_COMMISION_REQUEST', activeCommission),
  takeLatest('DELETE_COMMISION_REQUEST', deleteCommission),
  takeLatest(CommissionFormAction.REQUEST, saveComminssion),
];
