import moment from "moment";
import ExchargeV from "./ExchargeV";
import { lifecycle, compose as recompose, withState, pure, withHandlers } from "recompose";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { getExchargesRequest, removeExchargesRequest } from "../../reduxs/excharge-redux/reducer";
import { selectExcharges, selectPagination, selectLoading } from "../../reduxs/excharge-redux/selectors";
import Auth from "../../storage/Auth";
import { message } from "antd";

const StationCode = Auth.getStation();

const columns = [
    { name: 'STT', id: 'stt', class: 'text-center', type: 'stt', show: true, width: 80 },
    { name: 'Bảng quy đổi tỷ giá', id: 'CurrencyCode', type: 'currency', show: true, width: 150 },
]

export function mapDispatchToProps(dispatch) {
    return {
        getExcharges: (params) => dispatch(getExchargesRequest(params)),
        removeExcharge: (params) => dispatch(removeExchargesRequest(params)),
    };
}

const mapStateToProps = createStructuredSelector({
    excharges: selectExcharges(),
    exchargesing: selectLoading(),
    pagination: selectPagination(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const getParams = (params, setParams, param = {}) => {
    let objParams = { ...params, ...param };
    setParams(objParams);

    // Lấy tỷ giá tháng này
    let YearTime = moment().format('Y');
    let MonthTime = moment().format('M');

    if (objParams.Offset === undefined) objParams.Offset = 1;
    if (objParams.Size === undefined) objParams.Size = 20;
    if (objParams.StationCode === undefined) objParams.StationCode = StationCode;
    if (objParams.TimeRequest) {
        YearTime = moment(objParams.TimeRequest).format('Y');
        MonthTime = moment(objParams.TimeRequest).format('M');
    }

    const _params = {
        Offset: objParams.Offset,
        Size: objParams.Size,
        Query: objParams.Query,
        Year: YearTime,
        Month: MonthTime,
    }
    return { ..._params }
}

export default recompose(
    withConnect,
    withState('params', 'setParams', { Offset: 1, Size: 20, TimeRequest: moment() }),
    withState('rowKeys', 'setRowKeys', []),
    withState('rowRows', 'setSelectRows', []),
    withState('selectKeys', 'setSelectKeys', []),
    withState('selectRows', 'setSelectRows', []),
    withState('columns', 'setColumns', columns),
    withState('open', 'setOpen', false),
    withState('excharge', 'setExcharge', null),
    withState('deleting', 'setDeleting', null),
    withHandlers({
        stateColumn: ({ columns, setColumns }) => (data) => {
            const cols = columns.map((col) => {
                if (col.id === data.id) col.show = !data.show
                return col;
            });
            setColumns(cols);
        },
        fetchExcharges: ({ params, setParams, getExcharges }) => (param = {}) => {
            getExcharges(getParams(params, setParams, param));
        },
    }),
    withHandlers({
        onDeleteExcharge: ({ fetchExcharges, removeExcharge, setDeleting }) => (excharge) => {
            if (excharge) {
                setDeleting(excharge)
                const params = {
                    data: { ...excharge },
                    cb: (type) => {
                        if (type === 'error') {
                            message.error('Xóa thất bại!');
                        } else {
                            message.error('Xóa thành công!');
                        }
                        fetchExcharges();
                        setDeleting(null)
                    }
                }
                removeExcharge(params);
            }
        }
    }),
    lifecycle({
        componentDidMount() {
            const { fetchExcharges } = this.props;
            fetchExcharges();
        },
    }),
    pure
)(ExchargeV);
