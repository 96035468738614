import { createFormAction } from "redux-form-saga";
export const VENDOR_FORM_KEY = 'ASSET_VENDOR_FORM'
export const AssetVendorFormAction = createFormAction(VENDOR_FORM_KEY)

export const TYPE_FORM_KEY = 'ASSET_TYPE_FORM'
export const AssetTypeFormAction = createFormAction(TYPE_FORM_KEY)

export const LOCATION_FORM_KEY = 'ASSET_LOCATION_FORM'
export const AssetLocationFormAction = createFormAction(LOCATION_FORM_KEY)

export const GROUP_FORM_KEY = 'ASSET_GROUP_FORM'
export const AssetGroupFormAction = createFormAction(GROUP_FORM_KEY)

