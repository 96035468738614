import axios from '../utils/axiosInstance';
import FileSaver from 'file-saver';

export const getEmails = async (params = {}) => {
    try {
        const { data } = await axios.get('/api/email-management/email', { params });
        return data;
    } catch (error) {
        throw error;
    }
};

export const reSendEmail = async (params = {}) => {
    try {
        const { data } = await axios.post('/api/email-management/email/resend', { ...params });
        return data;
    } catch (error) {
        throw error;
    }
};

export const downloadAttachment = async (params) => {
    try {
        const response = await axios.get('api/email-management/download-attachment', { params,  responseType: 'blob' });
        const { data } = response;
        const namefile = response.headers['pcs-filename-suggestion'];
        const fileType = response.headers['content-type'];
        var blob = new Blob([data], {type: fileType});
        FileSaver.saveAs(blob, namefile);
        return true;
    } catch (error) {
        throw error;
    }
};

export const downloadAllAttachments = async (params) => {
    try {
        const response = await axios.get('api/email-management/download-attachment/all', { params,  responseType: 'blob' });
        const { data } = response;
        const namefile = response.headers['pcs-filename-suggestion'];
        const fileType = response.headers['content-type'];
        var blob = new Blob([data], {type: fileType});
        FileSaver.saveAs(blob, namefile);
        return true;
    } catch (error) {
        throw error;
    }
};

