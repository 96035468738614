import { call, put, takeLatest } from 'redux-saga/effects';
import * as RegionService from '../../services/region-service';

import { RegionFormAction, ProvinceFormAction, DistrictFormAction, WardFormAction } from "./action";
import { SubmissionError } from "redux-form";
import Auth from '../../storage/Auth';

import {
  getRegionsSuccess,
  fetchProvincesSuccess,
  getProvincesSuccess,
  getDistrictsSuccess,
  getWardsSuccess,
  getPannersSuccess,
  getRegionWardsSuccess,
  fetchRegionStationSuccess,
  fetchRegionTreeSuccess,
  fetchRegionStaffSuccess,
  downloadLocationListSuccess,
  failure
} from './reducer';


export function* getRegionRequest({ payload = {} }) {
  try {
    const { Result } = yield call(RegionService.getRegions, payload);
    if(Result) {
      yield put(getRegionsSuccess({ regions: Result }));
    } else {
      yield put(getRegionsSuccess({ regions: [] }));
    }
  } catch (error) {
    yield put(getRegionsSuccess({ regions: [] }));
    yield put(failure(error));
  }
}

export function* getProvinceAllsRequest(payload) {
  try {
    const { Result } = yield call(RegionService.fetchProvinceAlls, payload);
    if(Result) {
      yield put(fetchProvincesSuccess({ provinces: Result }));
    } else {
      yield put(fetchProvincesSuccess({ provinces: [] }));
    }
  } catch (error) {
    yield put(fetchProvincesSuccess({ provinces: [] }));
    yield put(failure(error));
  }
}

export function* getProvincesRequest({ payload = {} }) {
  try {
    const { Result } = yield call(RegionService.getProvinces, payload);
    if(Result) {
      yield put(getProvincesSuccess({ provinces: Result }));
    } else {
      yield put(getProvincesSuccess({ provinces: [] }));
    }
  } catch (error) {
    yield put(getProvincesSuccess({ provinces: [] }));
    yield put(failure(error));
  }
}

export function* getDistrictsRequest({ payload = {} }) {
  try {
    const { Result } = yield call(RegionService.getDistricts, payload);
    if(Result) {
      yield put(getDistrictsSuccess({ districts: Result }));
    } else {
      yield put(getDistrictsSuccess({ districts: [] }));
    }
  } catch (error) {
    yield put(getDistrictsSuccess({ districts: [] }));
    yield put(failure(error));
  }
}

export function* getWardsRequest({ payload = {} }) {
  try {
    const { Result } = yield call(RegionService.getWards, payload);
    if(Result) {
      yield put(getWardsSuccess({ wards: Result }));
    } else {
      yield put(getWardsSuccess({ wards: [] }));
    }
  } catch (error) {
    yield put(getWardsSuccess({ wards: [] }));
    yield put(failure(error));
  }
}

export function* getPannersRequest({ payload = {} }) {
  try {
    const { Result } = yield call(RegionService.getPanners, payload);
    if(Result) {
      yield put(getPannersSuccess({ panners: Result }));
    } else {
      yield put(getPannersSuccess({ panners: [] }));
    }
  } catch (error) {
    yield put(getPannersSuccess({ panners: [] }));
    yield put(failure(error));
  }
}

export function* getRegionWardsRequest({ payload = {} }) {
  try {
    const { Result } = yield call(RegionService.getRegionWards, payload);
    if(Result) {
      yield put(getRegionWardsSuccess({ regionwards: Result }));
    } else {
      yield put(getRegionWardsSuccess({ regionwards: [] }));
    }
  } catch (error) {
    yield put(getRegionWardsSuccess({ regionwards: [] }));
    yield put(failure(error));
  }
}

export function* getRegionStationRequest({ payload = {} }) {
  const Pagination = { Page: 1, Size: 50, Total: 0 }
  try {
    const { Result } = yield call(RegionService.getRegionStation, payload);
    if(Result) {
      yield put(fetchRegionStationSuccess(Result));
    } else {
      yield put(fetchRegionStationSuccess({ Data: [], Pagination }));
    }
  } catch (error) {
    yield put(fetchRegionStationSuccess({ Data: [], Pagination }));
    yield put(failure(error));
  }
}

export function* getRegionTreeRequest({ payload = {} }) {
  try {
    const { Result } = yield call(RegionService.getRegionTrees, payload);
    if(Result && Result.Data) {
      yield put(fetchRegionTreeSuccess({ trees: Result.Data }));
    } else {
      yield put(fetchRegionTreeSuccess({ trees: [] }));
    }
  } catch (error) {
    yield put(fetchRegionTreeSuccess({ trees: [] }));
    yield put(failure(error));
  }
}

export function* getRegionStaffRequest({ payload = {} }) {
  const Pagination = { Page: 1, Size: 50, Total: 0 }
  try {
    const { Result } = yield call(RegionService.getRegionStaff, payload);
    if(Result) {
      yield put(fetchRegionStaffSuccess(Result));
    } else {
      yield put(fetchRegionStaffSuccess({ Data: [], Pagination }));
    }
  } catch (error) {
    yield put(fetchRegionStaffSuccess({ Data: [], Pagination }));
    yield put(failure(error));
  }
}

export function* saveLocalRegion({ payload }) {
  const { data, cb } = payload;
  try {
    if(!data.StationCode) {
      data.StationCode = Auth.getStation();
    }
    const { Result } = yield call(RegionService.saveLocalRegions, data);
    if(Result) {
      cb('success')
    } else {
      cb('error')
    }
  } catch (error) {
    cb('error')
  }
}

export function* saveRegionWards({ payload }) {
  const { data, cb } = payload;
  try {
    const { Result } = yield call(RegionService.saveRegionWards, data);
    if(Result) {
      cb('success')
    } else {
      cb('error')
    }
  } catch (error) {
    if(error && error.Description === 'DELIVERY_ZONE_IN_OTHER_STATION') {
      cb('error', error)
    } else {
      cb('error')
    }
  }
}

export function* saveRegionStaff({ payload }) {
  const { data, cb } = payload;
  try {
    const { Result } = yield call(RegionService.saveRegionStaff, data);
    if(Result) {
      cb('success')
    } else {
      cb('error')
    }
  } catch (error) {
    if(error && error.Description === 'DELIVERY_ZONE_IN_OTHER_STATION') {
      cb('error', error)
    } else {
      cb('error')
    }
  }
}

export function* saveRegion({ payload }) {
  try {
    let result = null;
    if(payload.Id) {
      result= yield call(RegionService.updateRegion, payload);
    } else {
      result= yield call(RegionService.addRegion, payload);
    }
    if (result) {
      yield put(RegionFormAction.success("Lưu thành công!"));
    } else {
      const { message } = "Lưu thất bại!";
      const formError = new SubmissionError({
        _error: message
      });
      yield put(RegionFormAction.failure(formError));
    }
  } catch (error) {
    const formError = new SubmissionError({
      _error: error
    });
    yield put(RegionFormAction.failure(formError));
  }
}

export function* saveProvince({ payload }) {
  try {
    let result = null;
    if(payload.Id) {
      result= yield call(RegionService.updateProvince, payload);
    } else {
      result= yield call(RegionService.addProvince, payload);
    }
    if (result) {
      yield put(ProvinceFormAction.success("Lưu thành công!"));
    } else {
      const message = "Lưu thất bại!";
      const formError = new SubmissionError({
        _error: message
      });
      yield put(ProvinceFormAction.failure(formError));
    }
  } catch (error) {
    const formError = new SubmissionError({
      _error: error.message || 'Lưu thất bại'
    });
    yield put(ProvinceFormAction.failure(formError));
  }
}

export function* deleteProvince({ payload }) {
  const { data, cb } = payload;
  try {
    const { Result } = yield call(RegionService.deleteProvince, data);
    if(Result) {
      cb('success')
    } else {
      cb('error')
    }
  } catch (error) {
    cb('error')
  }
}

export function* saveDistrict({ payload }) {
  try {
    let result = null;
    if(payload.Id) {
      result= yield call(RegionService.updateDistrict, payload);
    } else {
      result= yield call(RegionService.addDistrict, payload);
    }
    if (result) {
      yield put(DistrictFormAction.success("Lưu thành công!"));
    } else {
      const message = "Lưu thất bại!";
      const formError = new SubmissionError({
        _error: message
      });
      yield put(DistrictFormAction.failure(formError));
    }
  } catch (error) {
    const formError = new SubmissionError({
      _error: error.message || 'Lưu thất bại'
    });
    yield put(DistrictFormAction.failure(formError));
  }
}
export function* deleteDistrict({ payload }) {
  const { data, cb } = payload;
  try {
    const { Result } = yield call(RegionService.deleteDistrict, data);
    if(Result) {
      cb('success')
    } else {
      cb('error')
    }
  } catch (error) {
    cb('error')
  }
}

export function* saveWard({ payload }) {
  try {
    let result = null;
    if(payload.edit) {
      delete payload.edit;
      result= yield call(RegionService.updateWard, payload);
    } else {
      delete payload.edit;
      result= yield call(RegionService.addWard, payload);
    }
    if (result) {
      yield put(WardFormAction.success("Lưu thành công!"));
    } else {
      const message = "Lưu thất bại!";
      const formError = new SubmissionError({
        _error: message
      });
      yield put(WardFormAction.failure(formError));
    }
  } catch (error) {
    const formError = new SubmissionError({
      _error: error.message || 'Lưu thất bại'
    });
    yield put(WardFormAction.failure(formError));
  }
}
export function* deleteWards({ payload }) {
  const { data, cb } = payload;
  try {
    const { Result } = yield call(RegionService.deleteWards, data);
    if(Result) {
      cb('success')
    } else {
      cb('error')
    }
  } catch (error) {
    cb('error')
  }
}

export function* toggleRegionStation({ payload }) {
  const { data, cb } = payload;
  try {
    const { Result } = yield call(RegionService.toggleRegionStation, data);
    if(Result) {
      cb('success')
    } else {
      cb('error')
    }
  } catch (error) {
    cb('error')
  }
}

export function* deleteRegionStation({ payload }) {
  const { data, cb } = payload;
  try {
    const { Result } = yield call(RegionService.deleteRegionStation, data);
    if(Result) {
      cb('success')
    } else {
      cb('error')
    }
  } catch (error) {
    cb('error')
  }
}

export function* deleteRegionStaff({ payload }) {
  const { data, cb } = payload;
  try {
    const { Result } = yield call(RegionService.deleteRegionStaff, data);
    if(Result) {
      cb('success')
    } else {
      cb('error')
    }
  } catch (error) {
    cb('error')
  }
}

export function* saveWardInfo({ payload }) {
  const { data, cb } = payload;
  try {
    const { Result } = yield call(RegionService.saveWardInfo, data);
    if(Result) {
      cb('success')
    } else {
      cb('error')
    }
  } catch (error) {
    cb('error')
  }
}

export function* deleteWardInfo({ payload }) {
  const { data, cb } = payload;
  try {
    const { Result } = yield call(RegionService.deleteWardInfo, data);
    if(Result) {
      cb('success')
    } else {
      cb('error')
    }
  } catch (error) {
    cb('error')
  }
}

export function* downloadLocationList({ payload }) {
  const { cb } = payload;
  try {
  const Result = yield call(RegionService.downloadLocationList);
  yield put(downloadLocationListSuccess());
  if(Result) {
      cb('success')
  } else {
      cb('error')
  }
  } catch (error) {
    yield put(downloadLocationListSuccess());
    cb('error')
  }
}


export default [
  takeLatest('GET_REGION_REQUEST', getRegionRequest),
  takeLatest('FETCH_PROVINCE_REQUEST', getProvinceAllsRequest),
  takeLatest('GET_PROVINCE_REQUEST', getProvincesRequest),
  takeLatest('GET_DISTRICT_REQUEST', getDistrictsRequest),
  takeLatest('GET_WARD_REQUEST', getWardsRequest),
  takeLatest('GET_PANNERS_REQUEST', getPannersRequest),
  takeLatest('GET_REGION_WARDS_REQUEST', getRegionWardsRequest),
  takeLatest('FETCH_REGION_STATION_REQUEST', getRegionStationRequest),
  takeLatest('FETCH_REGION_STAFF_REQUEST', getRegionStaffRequest),
  takeLatest('FETCH_REGION_TREE_REQUEST', getRegionTreeRequest),
  takeLatest('DELETE_PROVINCE_REQUEST', deleteProvince),
  takeLatest('DELETE_DISTRICT_REQUEST', deleteDistrict),
  takeLatest('DELETE_WARD_REQUEST', deleteWards),
  takeLatest('SAVE_LOCAL_REGION_REQUTEST', saveLocalRegion),
  takeLatest('SAVE_REGION_WARDS_REQUTEST', saveRegionWards),
  takeLatest('SAVE_REGION_STAFF_REQUTEST', saveRegionStaff),
  takeLatest('TOGGLE_REGION_STATION_REQUEST', toggleRegionStation),
  takeLatest('DELETE_REGION_STATION_REQUEST', deleteRegionStation),
  takeLatest('DELETE_REGION_STAFF_REQUEST', deleteRegionStaff),
  takeLatest('SAVE_WARD_INFO_REQUTEST', saveWardInfo),
  takeLatest('DELETE_WARD_INFO_REQUTEST', deleteWardInfo),
  takeLatest(RegionFormAction.REQUEST, saveRegion),
  takeLatest(ProvinceFormAction.REQUEST, saveProvince),
  takeLatest(DistrictFormAction.REQUEST, saveDistrict),
  takeLatest(WardFormAction.REQUEST, saveWard),
  takeLatest('DOWNLOAD_LOCATION_LIST_REQUEST', downloadLocationList)
];
