import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose as recompose, withHandlers, withState } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { Layout, Tabs } from 'antd';
import './style.scss';
import { Input, DatePicker, Button, Select, message, Radio, Space, Checkbox, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons'
import { createNotificationRequest } from '../../reduxs/notification-redux/reducer';
import { findUserRequest } from '../../reduxs/common-redux/reducer';
import Editor from '../../components/Editor';
import NotificationTemplate from './components/notification-template';

const { Content } = Layout;
const { TabPane } = Tabs;

const SYSTEM_CLIENTS = [
    { value: 'pcs-post', label: 'pcspost.vn', type: 'CUSTOMER' },
    { value: 'op-mvc-production', label: 'op.pcs.vn', type: 'STAFF' },
    { value: 'pcs_crm_spa', label: 'crm.pcs.vn', type: 'STAFF' },
    { value: 'pcs-system', label: 'sys.pcs.vn', type: 'STAFF' },
    { value: 'pcs-helpdesk-client', label: 'ticket.pcs.vn', type: 'STAFF' }
]

class NotificationCreatorV extends Component {
    render() {
        const { payload, changeParams, creating, createNotif, setActiveKey, activeKey, onSelectTemplate, onSearch, dataUser, searchMode, setSearchMode, searching } = this.props;

        return (
            <Content className="page-menus">
                <div className="panel-header">
                    <div className="header-title">
                        <i className="fa fa-home icon" aria-hidden="true"></i>
                        <span>Hệ thống</span>
                        <span className="icon-next"><i className="fa fa-angle-right"></i></span>
                        <span className="active">Tạo thông báo hệ thống</span>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="panel-body">
                        <Tabs activeKey={activeKey} onChange={key => setActiveKey(key)}>
                            <TabPane tab="Tạo thông báo" key="notification">
                                <div className="row" style={{ marginBottom: 15 }}>
                                    <div className="col-md-6">
                                        <label className="bold">Tiêu đề thông báo <span className='text-danger'>*</span></label>
                                        <div>
                                            <Input value={payload.Title} onChange={(e) => changeParams({ Title: e.target.value })} placeholder="" />
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <label className="bold">Thời gian hết hạn <Tooltip title="Sau khoảng thời gian này người dùng sẽ không nhận được thông báo này nữa"><InfoCircleOutlined /></Tooltip></label>
                                        <div>
                                            <DatePicker value={payload.ExpiredTime} showTime style={{ width: '100%' }} onChange={(value) => changeParams({ ExpiredTime: value })} />
                                        </div>
                                    </div>
                                </div>

                                <div className="row" style={{ marginBottom: 15 }}>
                                    <div className="col-md-6">
                                        <label className="bold">Gửi đến người dùng cụ thể <Tooltip title="Chọn người dùng để gửi thông báo, để trống để gửi thông báo đến tất cả người dùng trong hệ thống được chọn"><InfoCircleOutlined /></Tooltip></label>
                                        <div>

                                            <Input.Group compact>
                                                <Select value={searchMode} style={{ width: 120 }} onChange={v => {
                                                    setSearchMode(v)
                                                    changeParams({ ToSystem: null })
                                                }}>
                                                    <Select.Option value="CUSTOMER">Khách hàng</Select.Option>
                                                    <Select.Option value="STAFF">Nhân viên</Select.Option>
                                                </Select>
                                                <Select
                                                    allowClear
                                                    style={{ width: 'calc(100% - 120px)' }}
                                                    mode="tags"
                                                    value={payload.UserCode}
                                                    onChange={(value) => changeParams({ UserCode: value || [] })}
                                                    onSearch={onSearch}
                                                    options={dataUser}
                                                    loading={searching}
                                                    placeholder="Nhập 3 ký tự trở lên để tìm kiếm"
                                                />
                                            </Input.Group>
                                        </div>
                                    </div>
                                </div>

                                <div className="row" style={{ marginBottom: 15 }}>
                                    <div className="col-md-6">
                                        <div>
                                            <Checkbox checked={payload.AsSystemAccount} onChange={(e) => changeParams({ AsSystemAccount: e.target.checked })}>Gửi như thông báo hệ thống <Tooltip title="Chọn để gửi thông báo như một thông báo của hệ thống PCS. Nếu không chọn thì thông báo sẽ hiển thị người tạo là chính bạn"><InfoCircleOutlined /></Tooltip></Checkbox>
                                        </div>
                                    </div>
                                </div>

                                <div className="row" style={{ marginBottom: 15 }}>
                                    <div className="col-md-6">
                                        <Radio.Group onChange={(e) => changeParams({ SendToAllSystems: e.target.value })} value={payload.SendToAllSystems}>
                                            <Space direction="vertical">
                                                <Radio value={true}>Gửi đến tất cả các hệ thống</Radio>
                                                <Radio value={false}>Chọn hệ thống &nbsp;
                                                    {!payload.SendToAllSystems &&
                                                        <Select style={{ minWidth: 150 }} value={payload.ToSystem} dropdownMatchSelectWidth={false} onChange={(value) => changeParams({ ToSystem: value })} >
                                                            {
                                                                SYSTEM_CLIENTS.filter(x => x.type === searchMode).map(x => {
                                                                    return <Select.Option key={x.value} value={x.value}>{x.label}</Select.Option>
                                                                })
                                                            }
                                                        </Select>
                                                    }
                                                </Radio>
                                            </Space>
                                        </Radio.Group>
                                    </div>
                                </div>

                                <div>
                                    <Editor value={payload.Message} onChange={v => {
                                        changeParams({ Message: v })
                                    }}
                                    />
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <Button disabled={!payload.Title || !payload.Message} type="primary" loading={creating} onClick={() => createNotif()} className="btn btn-primary">Gửi thông báo</Button>
                                    </div>
                                </div>
                            </TabPane>
                            <TabPane tab="Mẫu thông báo" key="template">
                                <NotificationTemplate onSelect={onSelectTemplate} />
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
            </Content>
        );
    }
}

export function mapDispatchToProps(dispatch) {
    return {
        create: (params) => dispatch(createNotificationRequest(params)),
        findUsers: (params) => dispatch(findUserRequest(params)),
    };
}

const mapStateToProps = createStructuredSelector({
    creating: store => store.notif && store.notif.creatingNotification
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default recompose(
    withConnect,
    withState('payload', 'setPayload', {
        Message: null,
        Title: null,
        ExpiredTime: null,
        SendToAllSystems: true,
        UserCode: [],
        ToSystem: null,
        AsSystemAccount: true
    }),
    withState('activeKey', 'setActiveKey', 'notification'),
    withState('dataUser', 'setDataUser', []),
    withState('searchMode', 'setSearchMode', 'CUSTOMER'),
    withState('searching', 'setSearching', false),
    withHandlers({
        changeParams: ({ payload, setPayload }) => (_params = {}) => {
            const _p = { ...payload, ..._params };
            setPayload(_p);
        },
        createNotif: ({ payload, create, setPayload }) => () => {
            let _params = { ...payload };
            if (_params.Title && _params.Title.trim() !== '' && _params.Message && _params.Message.trim() !== '') {
                if (_params.ExpiredTime) {
                    _params.ExpiredTime = _params.ExpiredTime.format('YYYY-MM-DD HH:mm:ss');
                }
                const cb = (type) => {
                    message[type](type === 'success' ? 'Gửi thông báo thành công' : 'Gửi thông báo không thành công');
                    setPayload({
                        Message: null,
                        Title: null,
                        ExpiredTime: null,
                        SendToAllSystems: true,
                        UserCode: [],
                        ToSystem: null,
                        AsSystemAccount: true
                    })
                }
                create({ data: { ..._params, Type: 'flash_notification' }, cb });
            }
        }
    }),
    withHandlers({
        onSelectTemplate: ({ setActiveKey, changeParams }) => (template) => {
            setActiveKey('notification', () => {
                changeParams({ Title: template.Title, Message: template.Message })
            })
        },
        onSearch: ({ setDataUser, findUsers, searchMode, setSearching }) => (q) => {
            if (q.length < 3) {
                return
            }
            setSearching(true)
            findUsers({
                params: {
                    Query: q,
                    Limit: 25,
                    Type: searchMode
                },
                cb: (isOk, result) => {
                    setSearching(false)
                    if (!isOk) {
                        message.error('Đã xảy ra lỗi')
                    } else {
                        setDataUser(result.map(x => ({ value: x.Code, label: `${x.Code} - ${x.Name}` })))
                    }
                }
            })
        }
    })
)(NotificationCreatorV)