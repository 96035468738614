import { lifecycle, compose as recompose, withHandlers, withState } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import TaskQueueV from './TaskQueueV';
import {
  countTaskInQueueRequest, countTaskInQueueOpRequest, countTaskInQueueCrmRequest,
  getTaskInQueueDetailsRequest, getTaskInQueueDetailsOpRequest, getTaskInQueueDetailsCrmRequest
} from './../../reduxs/task-queue-redux/reducer'

export function mapDispatchToProps(dispatch) {
  return {
    countTaskInQueue: () => dispatch(countTaskInQueueRequest()),
    countTaskInQueueOp: () => dispatch(countTaskInQueueOpRequest()),
    countTaskInQueueCrm: () => dispatch(countTaskInQueueCrmRequest()),
    getDetails: (cb) => dispatch(getTaskInQueueDetailsRequest({ cb })),
    getDetailsOp: (cb) => dispatch(getTaskInQueueDetailsOpRequest({ cb })),
    getDetailsCrm: (cb) => dispatch(getTaskInQueueDetailsCrmRequest({ cb }))
  };
}

const mapStateToProps = createStructuredSelector({
  fetching: store => store.taskQueue && store.taskQueue.fetching,
  taskInQueue: store => store.taskQueue && store.taskQueue.taskInQueue,
  fetchingOp: store => store.taskQueue && store.taskQueue.fetchingOp,
  taskInQueueOp: store => store.taskQueue && store.taskQueue.taskInQueueOp,
  fetchingCrm: store => store.taskQueue && store.taskQueue.fetchingCrm,
  taskInQueueCrm: store => store.taskQueue && store.taskQueue.taskInQueueCrm,
  details: store => store.taskQueue && Object.keys(store.taskQueue.taskQueueDetails).map(key => ({ Source: key, Count: store.taskQueue.taskQueueDetails[key] })),
  detailsOp: store => store.taskQueue && Object.keys(store.taskQueue.taskQueueOpDetails).map(key => ({ Source: key, Count: store.taskQueue.taskQueueOpDetails[key] })),
  detailsCrm: store => store.taskQueue && Object.keys(store.taskQueue.taskQueueCrmDetails).map(key => ({ Source: key, Count: store.taskQueue.taskQueueCrmDetails[key] }))
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default recompose(
  withConnect,
  withState('fetchingDetails', 'setFetchingDetails', false),
  withState('fetchingDetailsOp', 'setFetchingDetailsOp', false),
  withState('fetchingDetailsCrm', 'setFetchingDetailsCrm', false),
  withHandlers({
    onGetDetails: ({ getDetails, setFetchingDetails }) => () => {
      setFetchingDetails(true)
      getDetails(() => {
        setFetchingDetails(false)
      })
    },
    onGetDetailsOP: ({ getDetailsOp, setFetchingDetailsOp }) => () => {
      setFetchingDetailsOp(true)
      getDetailsOp(() => {
        setFetchingDetailsOp(false)
      })
    },
    onGetDetailsCRM: ({ getDetailsCrm, setFetchingDetailsCrm }) => () => {
      setFetchingDetailsCrm(true)
      getDetailsCrm(() => {
        setFetchingDetailsCrm(false)
      })
    }
  }),
  lifecycle({
    componentDidMount() {
      this.props.countTaskInQueue();
      this.props.countTaskInQueueOp();
      this.props.countTaskInQueueCrm();

      this.props.onGetDetails()
      this.props.onGetDetailsOP()
      this.props.onGetDetailsCRM()
    },
    componentDidUpdate(props) {

    }
  }),
)(TaskQueueV);