import React from 'react';
import { Button, Modal, Form, notification } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { compose as recompose, withHandlers, withProps} from 'recompose';
import { Field, reduxForm } from 'redux-form';
import { required  } from '../../../helpers/validate';
import { FieldInput, FieldCheckBox } from '../../../components/Fields';


import { REGION_FORM_KEY, RegionFormAction } from '../../../reduxs/region-redux/action';

const RegionForm = (props) => {
  const { open, close, reset, handleSubmit,submitting } = props;
  const submit = handleSubmit(RegionFormAction);

  return (
    <Modal
      title='Thêm khu vực'
      visible={open}
      style={{ top: 20 }}
      width={'460px'}
      footer={null}
      maskClosable={false}
      onOk={() => { reset(); close(false) }}
      onCancel={() => { reset(); close(false) }}
      className="modal-form-region"
    >
      <Form layout="horizontal" onFinish={submit}>
        <div className="row">
          <div className="col-md-12">
            <Field
              component={FieldInput}
              label="Khu vực"
              name="Name"
              placeholder="Nhập tên khu vực"
              className="required"
              validate={[required]}
            />
          </div>
          <div className="col-md-12 text-right">
            <Field
              component={FieldCheckBox}
              text="Hoạt động"
              name="isActive"
            />
          </div>
        </div>
        <Form.Item className="text-center">
            <Button type="primary" icon={<SaveOutlined />} htmlType="submit" style={{ marginLeft: 8 }} loading={submitting}>Lưu</Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default recompose(
    withProps(
      ({ region }) => {
        if(region) {
          return { initialValues: { ...region }};
        }
      }
    ),
    withHandlers({
    }),
    reduxForm({
      form: REGION_FORM_KEY,
      enableReinitialize: true,
      onSubmitSuccess: (result, dispatch, props) => {
          notification.success({
              message: 'Gửi thành công!'
          });
          props.initialValues = null;
          props.reset();
          props.close(false);
          props.fetchRegions();
      }
    })
)(RegionForm);
