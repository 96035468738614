import { createAction, handleActions } from 'redux-actions';
// - Actions

export const fetchAssetsRequest = createAction('FETCH_ASSET_CHOOSE_REQUEST');
export const fetchAssetsSuccess = createAction('FETCH_ASSET_CHOOSE_SUCCESS');

export const fetchGroupsRequest = createAction('FETCH_ASSET_GROUP_REQUEST');
export const fetchGroupsSuccess = createAction('FETCH_ASSET_GROUP_SUCCESS');

export const fetchTypesRequest = createAction('FETCH_ASSET_TYPE_REQUEST');
export const fetchTypesSuccess = createAction('FETCH_ASSET_TYPE_SUCCESS');

export const fetchVendorsRequest = createAction('FETCH_ASSET_VENDOR_REQUEST');
export const fetchVendorsSuccess = createAction('FETCH_ASSET_VENDOR_SUCCESS');

export const fetchLocationsRequest = createAction('FETCH_ASSET_LOCATION_REQUEST');
export const fetchLocationsSuccess = createAction('FETCH_ASSET_LOCATION_SUCCESS');

export const fetchLeadersRequest = createAction('FETCH_ASSET_LEADERS_REQUEST');
export const fetchLeadersSuccess = createAction('FETCH_ASSET_LEADERS_SUCCESS');

export const failure = createAction('ASSET_ACTION_FAILURE');

// - Initial State
export const initialState = {
  assets: [],
  assetsing: false,
  pagination: {
    Offset: 1,
    Size: 50,
    Total: 0
  },

  groups: [],
  groupsing: false,

  types: [],
  tyepsing: false,

  vendors: [],
  vendorsing: false,

  locations: [],
  locationsing: false,

  leaders: [],
  leadersing: false,

  error: null,
};

// - Reducers
const reducer = handleActions({
  [fetchAssetsRequest]: (state) => ({
    ...state, assetsing: true,
  }),
  [fetchAssetsSuccess]: (state, { payload: { assets, pagination } }) => ({
    ...state, assets, pagination, assetsing: false,
  }),
  
  [fetchGroupsRequest]: (state) => ({
    ...state, groupsing: true,
  }),
  [fetchGroupsSuccess]: (state, { payload: { groups } }) => ({
    ...state, groups, groupsing: false,
  }),

  [fetchTypesRequest]: (state) => ({
    ...state, typesing: true,
  }),
  [fetchTypesSuccess]: (state, { payload: { types } }) => ({
    ...state, types, typesing: false,
  }),

  [fetchVendorsRequest]: (state) => ({
    ...state, vendorsing: true,
  }),
  [fetchVendorsSuccess]: (state, { payload: { vendors } }) => ({
    ...state, vendors, vendorsing: false,
  }),

  [fetchLocationsRequest]: (state) => ({
    ...state, locationsing: true,
  }),
  [fetchLocationsSuccess]: (state, { payload: { locations } }) => ({
    ...state, locations, locationsing: false,
  }),

  [fetchLeadersRequest]: (state) => ({
    ...state, leadersing: true,
  }),
  [fetchLeadersSuccess]: (state, { payload: { leaders } }) => ({
    ...state, leaders, leadersing: false,
  }),

  [failure]: (state, action) => ({
    ...state,
    error: action.error,
    groupsing: false,
  }),
}, initialState);

export default reducer;
