import { lifecycle, compose as recompose, withHandlers, withState, pure } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { notification, message } from 'antd';
import queryString from 'query-string';
import _ from 'lodash';
import { departmentListRequest, deleteDepartmentRequest, exportExcelDepartmentRequest } from '../../reduxs/department-redux/reducer';
import { selectDepartment, selectLoading, selectPagination } from '../../reduxs/department-redux/selectors';
import { selectStations } from '../../reduxs/common-redux/selectors';
import DepartmentV from './DepartmentV';

var timeout = null;

// Danh sách cột trong bảng quản lý tồn kho
const columns = [
  { name: 'Id phòng ban', id: 'Id', type: 'string', show: false, width: 120, class:'text-left' },
  { name: 'Tên phòng ban', id: 'Name', type: 'string', show: true, width: 120, class:'text-left' },
  { name: 'Mã phòng ban', id: 'Code', type: 'string', show: true, width: 120, class:'text-left' },
  { name: 'Mô tả', id: 'Description', type: 'string', show: true, width: 120, class:'text-left' },
  { name: 'Ghi chú', id: 'Remark', type: 'string', show: true, width: 120, class:'text-left' },
  { name: 'Trạng thái', id: 'IsActive', type: 'bool', show: true, width: 50, class:'text-center' },
  { name: 'Thứ tự', id: 'Order', type: 'int', show: false, width: 120, class:'text-left' },
  { name: 'Ngày tạo', id: 'CreatedDate', type: 'datetime', show: false, width: 120, class:'text-left' },
  { name: 'Người tạo', id: 'CreatedBy', type: 'string', show: false, width: 120, class:'text-left' },
  { name: 'Ngày cập nhật', id: 'UpdatedDate', type: 'datetime', show: false, width: 120, class:'text-left' },
  { name: 'Cập nhật bởi', id: 'UpdatedBy', type: 'string', show: false, width: 120, class:'text-left' },
];

export function mapDispatchToProps(dispatch) {
  return {
    searchDepartment: (params) => dispatch(departmentListRequest(params)),
    deleteDepartment: (params) => dispatch(deleteDepartmentRequest(params)),
    exportExcelDepartment: (params) => dispatch(exportExcelDepartmentRequest(params))
  };
}

const mapStateToProps = createStructuredSelector({
  departments: selectDepartment(),
  loading: selectLoading(),
  pagination: selectPagination(),
  stations: selectStations()
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default recompose(
  withConnect,
  withState('open', 'close', false),
  withState('department', 'setDepartment', null),
  withState('params', 'setParams', { Offset: 1, Size: 20 }),
  withState('columns', 'setColumns', columns),
  withState('rowKeys', 'setRowKeys', []),
  withState('rowRows', 'setSelectRows', []),
  withHandlers({
    fetchDepartments: ({ location, match, history, setParams, searchDepartment, setRowKeys, setSelectRows }) => (param) => {
      const search = location.search
      let params = queryString.parse(search);
      params = {  ...params, Offset: 1, ...param };

      if (params.Offset === undefined) params.Offset = 1;
      if (params.Size === undefined) params.Size = 20;

      params = _.pickBy(params, _.identity);
      setParams(params);
      const strparams = queryString.stringify(params);
      const url = match.url + '?' + strparams;
      history.push(url)

      clearTimeout(timeout);
      timeout = setTimeout(() => {
        searchDepartment({ ...params});
      }, 700);
      setRowKeys([]);
      setSelectRows([]);
    },
    exportExcelDepartment: ({exportExcelDepartment}) => () => {
      const params = {
        data: {},
        cb: (type) => {
          if(type === 'success') {
            message.success('Xuất file excel thành công!')
          } else {
            message.error('Xuất file excel bị lỗi!')
          }
        }
      }
      exportExcelDepartment(params);
    },
    editDepartment: ({close, setDepartment}) => (department) => {
      setDepartment(department);
      close(true)
    },
    stateColumn: ({ columns, setColumns }) => (data) => {
      const cols = columns.map((col) => {
        if (col.id === data.id) col.show = !data.show
        return col;
      });
      setColumns(cols);
    },
  }),
  withHandlers({
  deleteDepartment: ({ fetchDepartments, deleteDepartment}) => (department) => {
    if(department && department.Id) {
      const data = {
        Id: department.Id
      }
      const callback = (type) => {
        if(type === 'error') {
          notification[type]({message: 'Xóa thất bại!'});
        } else {
          notification[type]({message: 'Xóa thành công!'});
        }
        fetchDepartments();
      }
      const _params ={
        data: data,
        cb: callback
      }
      deleteDepartment(_params)
    }
  },
}),
  lifecycle({
    componentDidMount() {
      this.props.fetchDepartments();
    }
  }),
  pure,
)(DepartmentV);