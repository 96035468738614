import { createAction, handleActions } from 'redux-actions';
// - Actions

export const fetchStaffsRequest = createAction('FETCH_STAFFS_REQUEST');
export const fetchStaffsSuccess = createAction('FETCH_STAFFS_SUCCESS');

export const fetchStaffsCommissionRequest = createAction('FETCH_STAFFS_COMMISSION_REQUEST');
export const fetchStaffsCommissionSuccess = createAction('FETCH_STAFFS_COMMISSION_SUCCESS');
export const getStaffCommissionRequest = createAction('GET_STAFF_COMMISSION_REQUEST');
export const getStaffCommissionSuccess = createAction('GET_STAFF_COMMISSION_SUCCESS');
export const getStaffCommissionCallback = createAction('GET_STAFF_COMMISSION_CALLBACK');

export const lockStaffRequest = createAction('LOCK_STAFF_REQUEST');
export const unlockStaffRequest = createAction('UNLOCK_STAFF_REQUEST');
export const activeCommissionRequest = createAction('ACTIVE_COMMISION_REQUEST');
export const deleteCommissionRequest = createAction('DELETE_COMMISION_REQUEST');



export const failure = createAction('STAFF_ACTION_FAILURE');


// - Initial State
export const initialState = {
  staffs: [],
  pagination: { Page: 1, Size: 50, Total: 0 },
  loading: false,
  error: null,

  commissions: [],
  commissionsing: false,
  commissionPagination: { Page: 1, Size: 50, Total: 0 },
  commission: null,
  commissioning: false,

  defaultAccount: null
};

// - Reducers
const reducer = handleActions({
  [fetchStaffsRequest]: (state) => {
    return { ...state, loading: true };
  },
  [fetchStaffsSuccess]: (state, { payload: { staffs, pagination } }) => {
    return { ...state, staffs, pagination, loading: false };
  },

  [fetchStaffsCommissionRequest]: (state) => {
    return { ...state, commissionsing: true };
  },
  [fetchStaffsCommissionSuccess]: (state, { payload: { commissions, pagination } }) => {
    return { ...state, commissions, commissionPagination: pagination, commissionsing: false };
  },

  [getStaffCommissionRequest]: (state) => {
    return { ...state, commission: null, commissioning: true };
  },
  [getStaffCommissionSuccess]: (state, { payload: { commission } }) => {
    return { ...state, commission, commissioning: false };
  },

  [failure]: (state, action) => ({
    ...state,
    error: action.error,
    loading: false,
  }),
}, initialState);

export default reducer;
