import { createAction, handleActions } from 'redux-actions';

export const fetchDatabaseChangeLogRequest = createAction('FETCH_DATABASE_CHANGELOG_REQUEST');
export const fetchDatabaseChangeLogSuccess = createAction('FETCH_DATABASE_CHANGELOG_SUCCESS');

export const fetchPrimaryColumnsRequest = createAction('FETCH_PRIMARY_COLUMNS_REQUEST');
export const fetchPrimaryColumnsSuccess = createAction('FETCH_PRIMARY_COLUMNS_SUCCESS');

export const initialState = {
    logs: [],
    fetching: false,
    pagination: {
        Page: 1,
        Size: 50,
        Total: 0
    },
    fetchingPrimaryColumns: false,
    primaryColumns: [],
    error: null
};

// - Reducers
const reducer = handleActions({
    [fetchDatabaseChangeLogRequest]: (state) => {
        return { ...state, fetching: true };
    },
    [fetchDatabaseChangeLogSuccess]: (state, {payload: { logs, pagination }}) => {
        const data = { logs: logs, pagination: pagination }
        return { ...state, ...data, fetching: false  };
    },
    [fetchPrimaryColumnsRequest]: (state) => {
        return { ...state, fetchingPrimaryColumns: true };
    },
    [fetchPrimaryColumnsSuccess]: (state, {payload: { columns }}) => {
        return { ...state, fetchingPrimaryColumns: false, primaryColumns: columns };
    }
}, initialState);

export default reducer;