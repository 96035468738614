import _ from 'lodash';
import { lifecycle, compose as recompose, withHandlers, withState } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { fetchPickupRoutesRequest, getPickupRouteRequest, getPickupStaffRequest, removeRegionRequest, removeRouteRequest, removePickupRequest } from '../../reduxs/pickup-route-redux/reducer';
import { notification, Modal } from 'antd';
import PickupRouteV from './PickupRouteV';

const { confirm } = Modal;
const columns = [
  { name: 'Họ và Tên', id: 'FullName', show: true, width: 150},
  { name: 'Số ĐT', id: 'PhoneNumber', show: true, width: 130 },
  { name: 'Email', id: 'Email', show: true, width: 130 },
  { name: 'Giới tính', id: 'Gender', show: true, width: 60, type: 'gender', class: 'text-center' },
  { name: 'Ngày sinh', id: 'DateOfBirth', show: true, width: 100, type: 'date'},
  { name: 'Địa điểm', id: 'ProvinceId', show: true , width: 120, type: "address" },
  { name: 'CMT/CC/HC', id: 'IdNumber', show: true, width: 80, class: 'text-center' },
  { name: 'Phương tiện', id: 'VehicleTypeName', show: true, width: 80, class: 'text-center' },
  { name: 'Bằng lái xe', id: 'DriversLicenseNumber', show: true, width: 100, class: 'text-center' },
  { name: 'Loại bằng', id: 'DriversLicenseClass', show: true, width: 80, class: 'text-center' },
  { name: 'Biển số xe', id: 'VehicleNumberPlate', show: true, width: 100, class: 'text-right', type: 'price'},
  { name: 'Dòng xe', id: 'VehicleBrand', show: true, width: 100, class: 'text-right', type: 'price'},
]


export function mapDispatchToProps(dispatch) {
  return {
    searchRoutes: (params) => dispatch(fetchPickupRoutesRequest(params)),
    getPickupRoute: (params) => dispatch(getPickupRouteRequest(params)),
    searchPickups: (params) => dispatch(getPickupStaffRequest(params)),
    removeRoute: (params) => dispatch(removeRouteRequest(params)),
    removeRegion: (params) => dispatch(removeRegionRequest(params)),
    removePickup: (params) => dispatch(removePickupRequest(params))
  };
}

const mapStateToProps = createStructuredSelector({
  routes: ({pickupRoute }) => pickupRoute.routes,
  details: ({pickupRoute }) => pickupRoute.details,
  detailsing: ({pickupRoute }) => pickupRoute.detailsing,
  pickups: ({pickupRoute }) => pickupRoute.pickups,
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default recompose(
  withConnect,
  withState('open', 'close', false),
  withState('route', 'setRoute', null),
  withState('selected', 'setSelected', null),
  withState('view', 'setView', false),
  withState('region', 'setRegion', null),
  withState('display', 'setDisplay', false),
  withState('exporting', 'setExporting', false),
  withState('confirm', 'setConfirm', false),
  withState('params', 'setParams', {  Offset: 1, Size: 10, tabs: 1, ProfileStatusId: '' }),
  withState('columns', 'setColumns', columns),
  withState('rowKeys', 'setRowKeys', []),
  withState('rowRows', 'setSelectRows', []),
  withState('view', 'setView', false),
  withState('printing', 'setPrinting', false),
  withState('group', 'setGroup', false),
  withHandlers({
    fetchRoutes: ({ params, setParams, searchRoutes }) => (param) => {
      let objParams = { ...params, Offset: 1, ...param };
      objParams = _.pickBy(objParams, _.identity);
      setParams(objParams);
      searchRoutes({ ...objParams });
    },

    fetchPickups: ({searchPickups}) => () => {
      searchPickups()
    },

    getPickupRoute: ({ routes, setSelected, getPickupRoute }) => (value) => {
      let route = routes.find(item => item.Id === value);
      if(route) {
        setSelected(route, () => getPickupRoute({ Id: value }))
      }
    },

    onSelectChange: ({setRowKeys, setSelectRows}) => (keys, rows) => {
      setRowKeys(keys);
      setSelectRows(rows);
    },
    
    stateColumn: ({ columns, setColumns }) => (data) => {
      const cols = columns.map((col) => {
        if(col.id === data.id) col.show = !data.show
        return col;
      });
      setColumns(cols);
    }
  }),
  withHandlers({
    onRemoveRoute: ({ fetchRoutes, removeRoute }) => (menu) => {
      if(menu && menu.Id) {
        const data = {
          Id: menu.Id
        }
        const callback = (type) => {
          if(type) {
            notification.success({message: 'Xóa thành công!'});
            fetchRoutes();
          } else {
            notification.error({message: 'Xóa thất bại!'});
          }
        }
        const _params ={
          data: data,
          cb: callback
        }
        confirm({
          title: 'Bạn có chắc muốn xóa tuyến này?',
          okText: 'Có',
          okType: 'danger',
          cancelText: 'Không',
          onOk() {
            console.log('OK');
            removeRoute(_params);
          },
          onCancel() {
            console.log('Cancel');
          },
        });
      }
    },
    onRemoveRegion: ({ getPickupRoute, removeRegion }) => (item) => {
      if(item && item.Id) {
        const data = {
          RouteId: item.RouteId,
          'DetailIds[0]': item.Id
        }
        const callback = (type) => {
          if(type) {
            notification.success({message: 'Xóa thành công!'});
            getPickupRoute(item.RouteId);
          } else {
            notification.error({message: 'Xóa thất bại!'});
          }
        }
        const _params ={
          data: data,
          cb: callback
        }
        confirm({
          title: 'Bạn có chắc muốn xóa địa danh này?',
          okText: 'Có',
          okType: 'danger',
          cancelText: 'Không',
          onOk() {
            console.log('OK');
            removeRegion(_params);
          },
          onCancel() {
            console.log('Cancel');
          },
        });
      }
    },
    onRemovePickup: ({ fetchPickups, removePickup }) => (item) => {
      if(item && item.RouteId) {
        const data = {
          RouteId: item.RouteId,
          StaffCode: item.StaffCode,
        }
        const callback = (type) => {
          if(type) {
            notification.success({message: 'Xóa thành công!'});
            fetchPickups();
          } else {
            notification.error({message: 'Xóa thất bại!'});
          }
        }
        const _params ={
          data: data,
          cb: callback
        }
        confirm({
          title: 'Bạn có chắc muốn bỏ pickup?',
          okText: 'Có',
          okType: 'danger',
          cancelText: 'Không',
          onOk() {
            console.log('OK');
            removePickup(_params);
          },
          onCancel() {
            console.log('Cancel');
          },
        });
      }
    },
    onViewBill: ({close, setView, setPartner}) => (partner) => {
      if(partner) {
        setView(true, setPartner(partner, () => close(true)))
      } else {
        setPartner(null, () => close(false))
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.fetchRoutes();
      this.props.searchPickups();
    }
  }),
)(PickupRouteV);