import { call, put, takeLatest } from 'redux-saga/effects';
import * as stationService from '../../services/station-service';
import Auth from '../../storage/Auth';

import {
  getStationSuccess,
  setStationSuccess,
  openStationRequest,
  failure,
} from './reducer';

const currentStation = Auth.getStation();

export function* getStation({ payload = {} }) {
  try {
    const { Result } = yield call(stationService.getStations, payload);
    if(Result && Result.length) {
      const station = Result.find((station) => station.STATION_CODE === currentStation);
      if(station) {
        yield put(setStationSuccess({ station: station }));
      } else {
        yield put(openStationRequest(true))
      }
      yield put(getStationSuccess({ stations: Result }));
    }
  } catch (error) {
    yield put(failure(error));
  }
}

export default [
  takeLatest('STATION_LIST_REQUEST', getStation)
];
