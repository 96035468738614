import { createAction, handleActions } from 'redux-actions';
// - Actions

export const fetchAutosRequest = createAction('FETCH_AUTOS_REQUEST');
export const fetchAutosSuccess = createAction('FETCH_AUTOS_SUCCESS');


export const failure = createAction('AUTO_ACTION_FAILURE');


// - Initial State
export const initialState = {
  autos: [],
  pagination: {
    Page: 1,
    Size: 50,
    Total: 0
  },
  autosing: false,

  error: null,
};

// - Reducers
const reducer = handleActions({
  [fetchAutosRequest]: (state) => {
    return { ...state, autosing: true };
  },
  [fetchAutosSuccess]: (state, {payload: { autos }}) => {
    return { ...state, autos, autosing: false  };
  },

  [failure]: (state, action) => ({
    ...state,
    error: action.error,
    autosing: false,
  }),
}, initialState);

export default reducer;
