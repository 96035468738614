import { call, put, takeLatest } from 'redux-saga/effects';
import * as NotiService from '../../services/notification-service';

import {
  createNotificationSuccess,
  failure
} from './reducer';

export function* createNotification({ payload }) {
  const { data, cb } = payload;
  try {
    const { Result } = yield call(NotiService.createNotification, data);
    if(Result) {
      cb('success')
    } else {
      cb('error');
    }
    yield put(createNotificationSuccess())
  } catch (error) {
    cb('error');
    yield put(failure(error));
    yield put(createNotificationSuccess())
  }
}

export default [
  takeLatest('CREATE_NOTIFICATION_REQUEST', createNotification)
];
