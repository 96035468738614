import { call, put, takeLatest } from 'redux-saga/effects';
import * as departmentService from '../../services/department-service';

import { DEPARTMENTFormAction } from "./action";
import { SubmissionError } from "redux-form";

import {
    departmentListSuccess,
    failure,
} from './reducer';

export function* fetchDepartments({ payload }) {
    try {
        payload.iDisplayStart = (payload.Offset - 1) * payload.Size;
        payload.iDisplayLength = payload.Size;
        const { Result } = yield call(departmentService.getListDepartments, payload);
        if(Result.data) {
          const { data, iTotalRecords } = Result;
            yield put(departmentListSuccess({ departments: data, pagination: {Total: iTotalRecords, Size: payload.Size, Offset: payload.Offset } }));
        } else {
            yield put(failure('Tìm kiếm thất bại'));
        }
    } catch (error) {
        yield put(failure(error));
    }
}

export function* saveDepartment({ payload }) {
    try {
      let result = null;
      if(payload.Id) {
        result= yield call(departmentService.updateDepartment, payload);
      } else {
        result= yield call(departmentService.addDepartment, payload);
      }
      if (result) {
        yield put(DEPARTMENTFormAction.success("Lưu thành công!"));
      } else {
        const { message } = "Lưu thất bại!";
        const formError = new SubmissionError({
          _error: message
        });
        yield put(DEPARTMENTFormAction.failure(formError));
      }
    } catch (error) {
      const formError = new SubmissionError({
        _error: error
      });
      yield put(DEPARTMENTFormAction.failure(formError));
    }
  }

  
export function* deleteDepartment({ payload }) {
  const { data, cb } = payload;
  try {
    const { Result } = yield call(departmentService.deleteDepartment, data);
    if(Result) {
      cb('success')
    } else {
      cb('error')
    }
  } catch (error) {
    cb('error')
  }
}

  // Xuất dữ liệu exel bưu cục
  export function* exportExcelDepartment({ payload }) {
    const { data, cb } = payload;
    try {
      const Result = yield call(departmentService.exportExcelDepartment, data);
      if(Result) {
        cb('success')
      } else {
        cb('error')
      }
    } catch (error) {
      cb('error')
    }
  }

export default [
    takeLatest('DEPARTMENT_LIST_REQUEST', fetchDepartments),
    takeLatest('DELETE_DEPARTMENT_REQUEST', deleteDepartment),
    takeLatest(DEPARTMENTFormAction.REQUEST, saveDepartment),
    takeLatest('EXPORT_DEPARTMENT_EXCEL_REQUEST', exportExcelDepartment)
];
