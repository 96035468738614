import React from 'react';
import { Button, Form, Modal, notification } from 'antd';
import { SaveOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { compose as recompose, withHandlers, withProps } from 'recompose';
import { Field, reduxForm } from 'redux-form';
import { required, matchPassword, minLength6 } from '../../../helpers/validate';
import { FieldInput, FieldCheckBox } from '../../../components/Fields';


import { FORM_CHANGE_KEY, PassFormAction } from '../../../reduxs/staff-redux/action';

const { confirm } = Modal;

const FormPass = (props) => {
  const { open, close, staff, handleSubmit, error, valid, pristine, submitting } = props;
  const onSubmit = handleSubmit(PassFormAction);

  function showConfirm() {
    confirm({
      title: 'Bạn có muốn thay đổi mật khẩu?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Thay đổi',
      cancelText: 'Hủy bỏ',
      onOk() {
        onSubmit();
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  return (
    <Modal
      title={<span>Đặt mật khẩu mới:&ensp;<span className="bold">{staff.StaffCode} - {staff.FullName}</span></span>}
      visible={open}
      style={{ top: 20 }}
      width={'460px'}
      footer={null}
      maskClosable={false}
      onOk={() => { close(false) }}
      onCancel={() => { close(false) }}
      className="modal-form"
    >
      <Form layout="vertical" onFinish={() => valid ? showConfirm() : onSubmit()}>
        <div className="row">
          <div className="col-md-12">
            <Field
              component={FieldInput}
              label="Mật khẩu mới"
              name="password"
              placeholder="Nhập mật khẩu mới"
              type="password"
              className="required"
              validate={[required, minLength6]}
            />
          </div>

          <div className="col-md-12">
            <Field
              component={FieldInput}
              label="Nhập lại mật khẩu"
              name="NewPassword"
              placeholder="Nhập lại mật khẩu"
              type="password"
              className="required"
              validate={[required, matchPassword]}
            />
          </div>

          <div className="col-md-12">
            <Field
              component={FieldCheckBox}
              label=""
              text="Đổi mật khẩu sau khi đăng nhập?"
              name="ChangePasswordRequired"
              placeholder="Nhập lại mật khẩu"
            />
          </div>
        </div>
        <p>Ghi chú:&ensp;<span className="note-red">Trường không được để trống</span></p>
        <Form.Item className="text-center">
          <Button type="primary" icon={<SaveOutlined />} disabled={pristine} loading={submitting} htmlType="submit" style={{ marginLeft: 8 }}>Đặt mật khẩu mới</Button>
          <p className="text-danger">{error}</p>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default recompose(
  withProps(
    ({ staff }) => {
      if (staff) {
        return { initialValues: staff };
      }
    }
  ),
  withHandlers({
  }),
  reduxForm({
    form: FORM_CHANGE_KEY,
    enableReinitialize: true,
    onSubmitSuccess: (result, dispatch, props) => {
      notification.success({
        message: 'Thay đổi mật khẩu thành công!'
      });
      props.initialValues = null;
      props.reset();
      props.close(false);
    }
  })
)(FormPass);
