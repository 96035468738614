import { call, put, takeLatest } from 'redux-saga/effects';
import * as FeatureService from '../../services/feature-service';


import {
    fetchMyFeatureSuccess,
} from './reducer';

export function* fetchMyFeatures({ payload = {} }) {
    try {
        const { Result } = yield call(FeatureService.getMyFeatures, payload);
        if(Result) {
            yield put(fetchMyFeatureSuccess({ features: Result }));
        } else {
            yield put(fetchMyFeatureSuccess({ features: [] }));
        }
    } catch (error) {
        yield put(fetchMyFeatureSuccess({ features: [] }));
    }
}

export default [
    takeLatest('FETCH_MY_FEATURE_REQUEST', fetchMyFeatures)
];
