import { createAction, handleActions } from 'redux-actions';
// - Actions

export const fetchRolesRequest = createAction('FETCH_ROLES_REQUEST');
export const fetchRolesSuccess = createAction('FETCH_ROLES_SUCCESS');

export const fetchRoleUsersRequest = createAction('FETCH_ROLE_USERS_REQUEST');
export const fetchRoleUsersSuccess = createAction('FETCH_ROLE_USERSS_SUCCESS');

export const toggleActiveRolesRequest = createAction('TOGGLE_ROLES_REQUEST');


export const failure = createAction('ROLE_ACTION_FAILURE');


// - Initial State
export const initialState = {
  roles: [],
  rolesing: false,

  roleusers: [],
  pagination: {
    Page: 1,
    Size: 50,
    Total: 0
  },
  roleusersing: false,

  error: null,
};

// - Reducers
const reducer = handleActions({
  [fetchRolesRequest]: (state) => {
    return { ...state, rolesing: true };
  },
  [fetchRolesSuccess]: (state, {payload: { roles }}) => {
    return { ...state, roles, rolesing: false  };
  },

  [fetchRoleUsersRequest]: (state) => {
    return { ...state, roleusersing: true };
  },
  [fetchRoleUsersSuccess]: (state, { payload: { roleusers, pagination }}) => {
    return { ...state, roleusers, pagination, roleusersing: false  };
  },

  [failure]: (state, action) => ({
    ...state,
    error: action.error,
    rolesing: false,
    roleusersing: false,
  }),
}, initialState);

export default reducer;
