import React from 'react';
import { Modal, Select, Pagination, Table, Button, Popconfirm, notification } from 'antd';
import { UndoOutlined, DeleteOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { lifecycle, compose as recompose, withHandlers, withState } from 'recompose';
import { Icons } from '../../../../assets';
import moment from 'moment';
import _ from 'lodash';
import { getCodeDetailRequest, removeAccountCodeRequest } from '../../../../reduxs/account-redux/reducer';
import { selectCodeDetails, selectCodeDetailsing, selectPagination } from '../../../../reduxs/account-redux/selectors';

const createColums = ({ columns = [], removeCode }) => {
  const _columns = [];
  if (columns && columns.length > 0) {
      columns.forEach((col) => {
          if (col.show) {
              if(col.type === "date") {
                  _columns.push({ title: col.name, key: col.id, width: col.width, className: col.class, render: (item) => item[col.id] ? <span><i className="fa fa-calendar-o"/>&ensp;{moment(item[col.id]).format("DD-MM-YYYY")}</span> : 'N/A' })
               } else {
                  _columns.push({ title: col.name, dataIndex: col.id, key: col.id, width: col.width, className: col.class })
              }
          }
      });
      var functionColumn = {
          title: '', dataIndex: '', key: 'action', fixed: "right", className: "text-center", align: 'center', width: 50, render: (item) => {
                  return <div>
                      <Popconfirm title="Bạn có chắc muốn xóa?" onConfirm={() => removeCode(item.Id)} placement="topRight" okText="Xóa" cancelText="Không">
                          <Button icon={<DeleteOutlined />} title="Xóa mã cha" size="small" type="danger" />
                      </Popconfirm>
                  </div>
          }
      };
      _columns.push(functionColumn);
  }
  return _columns;
}

const FormPass = (props) => {
  const { open, close, codes, loading, pagination, params, fetchCodes } = props;

  const size = params.Size && Number(params.Size) > 0 ? Number(params.Size) : 20;
  const offset = params.Offset && Number(params.Offset) > 0 ? Number(params.Offset) : 1;
  const total = pagination && pagination.Total && Number(pagination.Total) > 0 ? Number(pagination.Total) : 0;
  const n_start = total > 0 ? (((offset - 1) * size) + 1) : 0;
  const n_end = total > 0 ? ((offset * size) > total ? total : offset * size) : 0;

  return (
    <Modal
      title={'Chi tiết mã cha'}
      visible={open}
      style={{ top: 20 }}
      width={1160}
      footer={null}
      maskClosable={false}
      onOk={() => { close(false) }}
      onCancel={() => { close(false) }}
      className="modal-form"
    >
      <div className="panel-body pd-0">
          <div className="row">
              <div className="col-md-4">
                  <label className="total-filter">
                      <img src={Icons.common.filter} alt="Số tài khoản" />&ensp;|&ensp;<span>{n_start} - {n_end}</span> trong tổng <span>{total}</span> tài khoản
                  </label>
              </div>
              <div className="col-md-8 text-right">
                  {/* <span>
                      <Input.Search defaultValue={params.Query || ''} placeholder="Tìm kiếm..." onSearch={(value) => fetchCodes({Query: value})} style={{maxWidth: '280px'}}/>
                  </span>
                  &emsp; */}
                  <Button onClick={() => fetchCodes()} title="Làm mới" icon={<UndoOutlined />}>Làm mới</Button>&ensp;
              </div>
          </div>
          <div className="row mr-t-10">
              <div className="col-md-12">
                  <Table
                      loading={loading}
                      className="table-main"
                      columns={createColums(props)}
                      rowKey={(row, key) => key}
                      size="small"
                      dataSource={codes || []}
                      pagination={false}
                      bordered={true}
                      scroll={{x: 800}}
                  />
              </div>
          </div>
          <div className="row mr-t-10">
              <div className="col-md-6">
                  <span>Hiển thị &ensp;
                      <Select value={size || 20} style={{ width: 120 }} onChange={(size) => fetchCodes({ Size: size, Offset: 1 })}>
                          <Select.Option value="20">20</Select.Option>
                          <Select.Option value="50">50</Select.Option>
                          <Select.Option value="100">100</Select.Option>
                          <Select.Option value="200">200</Select.Option>
                      </Select>
                      &nbsp; mã / trang
                  </span>
              </div>
              <div className="col-md-6 text-right">
                  <Pagination current={offset || 1} pageSize={size || 20} total={total || 0} showSizeChanger={false} onChange={(offset) => fetchCodes({ Offset: offset })} />
              </div>
          </div>
      </div>
    </Modal>
  );
};

export function mapDispatchToProps(dispatch) {
  return {
      getCodes: (params) => dispatch(getCodeDetailRequest(params)),
      removeAccountCode: (params) => dispatch(removeAccountCodeRequest(params))
  };
}

const mapStateToProps = createStructuredSelector({
  codes: selectCodeDetails(),
  loading: selectCodeDetailsing(),
  pagination: selectPagination(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const columns = [
  { name: 'ID', id: 'Id', type: 'string', show: true, width: 120 },
  { name: 'Mã Account', id: 'AccountNo', type: 'string', show: true, width: 200 },
  { name: 'Tên đầy đủ', id: 'FullName', type: 'string', show: true, width: 250 },
  { name: 'Tên rút gọn', id: 'ShortName', type: 'string', show: true, width: 280 },
  // { name: 'Thời gian tạo', id: 'CreatedDate', type: 'date', show: true, width: 150 }
];

export default recompose(
    withConnect,
    withState('params', 'setParams', { Offset: 1, Size: 20 }),
    withState('columns', 'setColumns', columns),
    withHandlers({
      fetchCodes: ({ params, code, setParams, getCodes }) => (param) => {
          let _params = { ...params, Offset: 1, ...param };
    
          if (_params.Offset === undefined) _params.Offset = 1;
          if (_params.Size === undefined) _params.Size = 20;
    
          _params = _.pickBy(_params, _.identity);
          setParams(_params);
          getCodes({ ..._params, ParentCode: code.Id});
      },
    }),
    withHandlers({
      removeCode: ({ fetchCodes, removeAccountCode}) => (Id) => {
        if(Id) {
          const data = {
            CustomerId: Id
          }
          const callback = (type) => {
            if(type === 'error') {
              notification[type]({message: 'Xóa thất bại!'});
            } else {
              notification[type]({message: 'Xóa thành công!'});
            }
            fetchCodes();
          }
          const _params ={
            data: data,
            cb: callback
          }
          removeAccountCode(_params);
        }
      },
  }),
  lifecycle({
    componentDidMount() {
        this.props.fetchCodes();
    }
  })
)(FormPass);
