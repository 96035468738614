import React from 'react';
import { Modal, notification, message, Button, Input } from 'antd';
import { LeftOutlined, CheckOutlined } from '@ant-design/icons';
import { compose as recompose, withHandlers, withState} from 'recompose';

const MenuForm = (props) => {
  const { open, close, partner, confirming, submitConfirm, approve, note, setNote } = props;
  let strTitle = approve ? "Xác nhận duyệt hồ sơ" : "Từ chối duyệt hồ sơ";
  let strConfirm = approve ? 'Xác nhận duyệt hồ sơ': 'Từ chối duyệt hồ sơ';
  if(partner && partner.ProfileStatusId === 4) {
      strTitle = approve ? "Duyệt làm cộng tác viên" : "Từ chối duyệt cộng tác viên";
      strConfirm = approve ? 'Duyệt làm cộng tác viên' : 'Hủy hồ sơ công tác viên';
  }

  return (
    <Modal
      visible={open}
      style={{ top: 20 }}
      width={'560px'}
      footer={null}
      maskClosable={false}
      onOk={() => close(false) }
      onCancel={() => close(false) }
      className="modal-view-detail"
    >
      <div className="view-profile">
        <div className="group-profile">
            <div className="body-panel">
              <h3 className="text-center">{strTitle}</h3>
              <br/>
              <div className="row">
                <div className="col-md-12 input-note">
                  <Input.TextArea value={note || ''} rows={2} onChange={(e) => setNote(e.target.value)} placeholder={approve ? "Nội dung xác nhận" : "Nhập lý do từ chối"} />
                </div>
              </div>
              <br/>
              <div className="row">
                <div className="col-xs-6">
                  <Button onClick={() => close(false)} size="large" block><LeftOutlined />Đóng</Button>
                </div>
                <div className="col-xs-6">
                  <Button onClick={() => submitConfirm(approve)} loading={confirming} size="large" type="primary" block danger={!approve} icon={<CheckOutlined />}>{strConfirm}</Button>
                </div>
              </div>
            </div>
        </div>
      </div>
    </Modal>
  );
};

export default recompose(
  withState('note', 'setNote', null),
  withState('confirming', 'setConfirming', false),
  withHandlers({
    submitConfirm: ({ partner, note, close, fetchPartners, setConfirming, confirmPartner }) => (approve) =>{
      let _note = `${note || ''}`.trim();
      if(partner && partner.PhoneNumber) {
        if(approve || _note ) {
          setConfirming(true);
          const params = {
            data: {
              PhoneNumber: partner.PhoneNumber,
              Email: partner.Email,
              ProfileStatusId: approve ? (partner.ProfileStatusId === 4 ? 5 : 4) : 2,
              Notes: _note || ''
            },
            cb: (type) => {
              if (type === 'error') {
                notification.error({message: 'Xác nhận đơn hoàn thất bại!'});
              } else {
                message.success('Xác nhận đơn hoàn thành công!');
                close(false)
              }
              setConfirming(false);
              fetchPartners();
            }
          }
          confirmPartner(params)
        } else {
          message.warn('Vui lòng nhập lý do từ chối')
        }
      }
    },
  }),
)(MenuForm);
