import React, { useState, useEffect } from 'react'
import { Layout, message, Table, Button, Popconfirm, Switch, Empty, Tabs } from 'antd'
import { useDispatch } from 'react-redux';
import { EditOutlined, EyeOutlined, PlusOutlined, DeleteOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons'
import RestrictPage from './../../components/RestrictComponents/RestrictPage';
import {
    fetchSubscriptionTypes, deleteSubscriptionTypes, getEmailSubscriptionByTypeRequest, toggleActiveEmailSubscriptionRequest, removeEmailSubscriptionRequest,
    getUserSubscriptionByTypeRequest, toggleActiveUserSubscriptionRequest, removeUserSubscriptionRequest
} from '../../reduxs/email-subscription-redux/reducer'
import TypeForm from './components/type-form';
import EmailForm from './components/email-form';
import UserForm from './components/user-form';
import moment from 'moment';
import './style.scss'

const { Content } = Layout;
const { TabPane } = Tabs;

export default () => {
    const dispatch = useDispatch();
    const [types, setTypes] = useState([])
    const [typeLoading, setTypeLoading] = useState(false)
    const [selectedType, setSelectedType] = useState(null)
    const [deleting, setDeleting] = useState(false)
    const [emailToggling, setEmailToggling] = useState({})

    const [emailSubscriptions, setEmailSubscriptions] = useState([])
    const [userSubscriptions, setUserSubscriptions] = useState([])
    const [typeView, setTypeView] = useState(null)
    const [detailLoading, setDetailLoading] = useState(false)
    const [showAddEmail, setShowAddEmail] = useState(false)
    const [userToggling, setUserToggling] = useState({})
    const [showAddUser, setShowAddUser] = useState(false)

    const [tabShow, setTabShow] = useState('email')

    const fetchTypes = () => {
        setTypeLoading(true)
        dispatch(fetchSubscriptionTypes({
            params: {
            },
            cb: (isOk, result) => {
                setTypeLoading(false)
                if (!isOk) {
                    message.error('Đã xảy ra lỗi')
                } else {
                    setTypes(result)
                }
            }
        }))
    }

    const onTypeSuccess = () => {
        setSelectedType(null);
        fetchTypes()
    }

    const onRemove = (_id) => {
        setDeleting(true)
        dispatch(deleteSubscriptionTypes({
            params: {
                Id: _id
            },
            cb: (isOk) => {
                setDeleting(false)
                if (!isOk) {
                    message.error('Đã xảy ra lỗi')
                } else {
                    fetchTypes()
                }
            }
        }))
    }

    const getDetails = (_id) => {
        setDetailLoading(true)
        if (tabShow === 'email') {
            dispatch(getEmailSubscriptionByTypeRequest({
                params: {
                    TypeId: _id
                },
                cb: (isOk, result) => {
                    setDetailLoading(false)
                    if (!isOk) {
                        message.error('Đã xảy ra lỗi')
                    } else {
                        setEmailSubscriptions(result)
                    }
                }
            }))
        } else {
            dispatch(getUserSubscriptionByTypeRequest({
                params: {
                    TypeId: _id
                },
                cb: (isOk, result) => {
                    setDetailLoading(false)
                    if (!isOk) {
                        message.error('Đã xảy ra lỗi')
                    } else {
                        setUserSubscriptions(result)
                    }
                }
            }))
        }
    }

    useEffect(() => {
        fetchTypes();
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (typeView) {
            getDetails(typeView.Id)
        } else {
            setEmailSubscriptions([])
        }
        // eslint-disable-next-line
    }, [typeView])

    const onRemoveEmail = (email) => {
        dispatch(removeEmailSubscriptionRequest({
            params: {
                TypeId: email.SubscriptionTypeId,
                Email: email.Email
            },
            cb: (isOk) => {
                if (!isOk) {
                    message.error('Đã xảy ra lỗi')
                } else {
                    getDetails(typeView.Id)
                }
            }
        }))
    }

    const onRemoveUser = (user) => {
        dispatch(removeUserSubscriptionRequest({
            params: {
                TypeId: user.TypeId,
                UserCode: user.UserCode
            },
            cb: (isOk) => {
                if (!isOk) {
                    message.error('Đã xảy ra lỗi')
                } else {
                    getDetails(typeView.Id)
                }
            }
        }))
    }

    const onToggleActive = (email) => {
        setEmailToggling({ ...emailToggling, [email.Email]: true })
        dispatch(toggleActiveEmailSubscriptionRequest({
            params: {
                Email: email.Email,
                IsActive: !email.IsActive,
                TypeId: email.SubscriptionTypeId
            },
            cb: (isOk) => {
                setEmailToggling({ ...emailToggling, [email.Email]: false })
                if (!isOk) {
                    message.error('Đã xảy ra lỗi')
                } else {
                    getDetails(typeView.Id)
                }
            }
        }))
    }

    const onToggleActiveUser = (user) => {
        setUserToggling({...userToggling, [user.UserCode]: true })
        dispatch(toggleActiveUserSubscriptionRequest({
            params: {
                UserCode: user.UserCode,
                IsActive: !user.IsActive,
                TypeId: user.TypeId
            },
            cb: (isOk) => {
                setUserToggling({...userToggling, [user.UserCode]: false })
                if (!isOk) {
                    message.error('Đã xảy ra lỗi')
                } else {
                    getDetails(typeView.Id)
                }
            }
        }))
    }

    const onAddEmailSuccess = () => {
        setShowAddEmail(false);
        getDetails(typeView.Id)
    }

    const onAddUserSuccess = () => {
        setShowAddUser(false);
        getDetails(typeView.Id)
    }

    const onChangTab = (tab) => {
        setTabShow(tab)
    }

    useEffect(() => {
        typeView && getDetails(typeView.Id)
        // eslint-disable-next-line
    }, [tabShow])

    return (
        <>
            <RestrictPage>
                <Content className="page-email-subscription-manager">
                    <div className="panel-header">
                        <div className="header-title">
                            <i className="fa fa-home icon" aria-hidden="true"></i>
                            <span>Hệ thống</span>
                            <span className="icon-next"><i className="fa fa-angle-right"></i></span>
                            <span className="active">Quản lý email nhận thông báo</span>
                        </div>
                    </div>
                    <div className="panel-body">
                        <div className='col-md-6'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div className='tbl-types-header'>
                                        <label className='bold'>Loại email</label>
                                        <Button icon={<PlusOutlined />} type='primary' onClick={() => setSelectedType({})}>Thêm loại</Button>
                                    </div>
                                </div>
                            </div>
                            <Table
                                className='tbl-types'
                                loading={typeLoading}
                                dataSource={types}
                                rowKey={x => x.Id}
                                columns={[
                                    { title: 'Mô tả', render: x => (<>{x.Name}<br /><span style={{ fontSize: 12, color: '#ababab' }}>{x.Code}</span></>) },
                                    { title: 'Client-Id', render: x => x.ClientId },
                                    {
                                        title: 'Thao tác', className: 'text-center', width: 240, render: x => (
                                            <>
                                                <span className='btn-group-hanle-type'>
                                                    <Popconfirm onConfirm={() => onRemove(x.Id)} placement='leftBottom' title="Bạn chắc chắn muốn xoá?" okText="Chắc chắn" cancelText="Huỷ">
                                                        <Button size='small' type='primary' danger loading={deleting} icon={<DeleteOutlined />}>Xoá</Button>
                                                    </Popconfirm>
                                                    <Button size='small' type='primary' icon={<EditOutlined />} onClick={() => setSelectedType(x)}>Sửa</Button>
                                                    <Button size='small' icon={<EyeOutlined />} onClick={() => setTypeView(x)}>Xem</Button>
                                                </span>
                                            </>
                                        )
                                    }
                                ]}
                                pagination={false}
                                size='small'
                                bordered
                            />
                        </div>

                        <div className='col-md-6'>
                            <Tabs defaultActiveKey="email" onChange={onChangTab}>
                                <TabPane tab="Emails" key="email">
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <div className='tbl-types-header'>
                                                <label className='bold'>Email nhận thông báo {typeView && `(${typeView.Name})`}</label>
                                                <Button disabled={!typeView} icon={<PlusOutlined />} type='primary' onClick={() => setShowAddEmail(true)}>Thêm email</Button>
                                            </div>
                                        </div>
                                    </div>
                                    <Table
                                        className='tbl-emails'
                                        loading={detailLoading}
                                        dataSource={emailSubscriptions}
                                        locale={{ emptyText: typeView ? <Empty description="Chưa có email nhận thông báo" /> : 'Chọn loại để xem' }}
                                        rowKey={x => `${x.SubscriptionTypeId}.${x.Email}`}
                                        columns={[
                                            { title: 'Email', render: x => x.Email },
                                            {
                                                title: 'Trạng thái', width: 120, className: 'text-center', render: x => (
                                                    <Switch size='small' loading={emailToggling[x.Email]} checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} checked={x.IsActive} onChange={() => onToggleActive(x)} />
                                                )
                                            },
                                            {
                                                title: 'T.gian', render: x => {
                                                    return (
                                                        <>
                                                            {moment(x.CreatedDate).format('YYYY-MM-DD HH:mm')}
                                                            {x.UpdatedDate && <><br /><span style={{ fontSize: 12, color: '#ababab' }}>{moment(x.UpdatedDate).format('YYYY-MM-DD HH:mm')}</span></>}
                                                        </>
                                                    )
                                                }, width: 140
                                            },
                                            {
                                                title: 'Thao tác', className: 'text-center', width: 140, render: x => (
                                                    <>
                                                        <span className='btn-group-hanle-type'>
                                                            <Popconfirm onConfirm={() => onRemoveEmail(x)} placement='leftBottom' title="Bạn chắc chắn muốn xoá?" okText="Chắc chắn" cancelText="Huỷ">
                                                                <Button size='small' type='primary' danger loading={deleting} icon={<DeleteOutlined />}>Xoá</Button>
                                                            </Popconfirm>
                                                        </span>
                                                    </>
                                                )
                                            }
                                        ]}
                                        pagination={false}
                                        size='small'
                                        bordered
                                        scroll={{ y: 'calc(100vh - 250px)' }}
                                    />
                                </TabPane>
                                <TabPane tab="Users" key="user">
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <div className='tbl-types-header'>
                                                <label className='bold'>Người dùng nhận thông báo {typeView && `(${typeView.Name})`}</label>
                                                <Button disabled={!typeView} icon={<PlusOutlined />} type='primary' onClick={() => setShowAddUser(true)}>Thêm người dùng</Button>
                                            </div>
                                        </div>
                                    </div>
                                    <Table
                                        className='tbl-users'
                                        loading={detailLoading}
                                        dataSource={userSubscriptions}
                                        locale={{ emptyText: typeView ? <Empty description="Chưa có người dùng nhận thông báo" /> : 'Chọn loại để xem' }}
                                        rowKey={x => `${x.TypeId}.${x.UserCode}`}
                                        columns={[
                                            { title: 'Người dùng', render: x => (<>{x.UserName}<br /><span style={{ fontSize: 12, color: '#ababab' }}>{x.UserCode}</span></>) },
                                            { title: 'Email', render: x => x.Email && x.Email.trim() !== '' ? x.Email : <span style={{ color: '#ababab' }}>Chưa cung cấp</span> },
                                            {
                                                title: 'Trạng thái', width: 120, className: 'text-center', render: x => (
                                                    <Switch size='small' loading={userToggling[x.UserCode]} checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} checked={x.IsActive} onChange={() => onToggleActiveUser(x)} />
                                                )
                                            },
                                            {
                                                title: 'T.gian', render: x => {
                                                    return (
                                                        <>
                                                            {moment(x.CreatedDate).format('YYYY-MM-DD HH:mm')}
                                                            {x.UpdatedDate && <><br /><span style={{ fontSize: 12, color: '#ababab' }}>{moment(x.UpdatedDate).format('YYYY-MM-DD HH:mm')}</span></>}
                                                        </>
                                                    )
                                                }, width: 140
                                            },
                                            {
                                                title: 'Thao tác', className: 'text-center', width: 140, render: x => (
                                                    <>
                                                        <span className='btn-group-hanle-type'>
                                                            <Popconfirm onConfirm={() => onRemoveUser(x)} placement='leftBottom' title="Bạn chắc chắn muốn xoá?" okText="Chắc chắn" cancelText="Huỷ">
                                                                <Button size='small' type='primary' danger loading={deleting} icon={<DeleteOutlined />}>Xoá</Button>
                                                            </Popconfirm>
                                                        </span>
                                                    </>
                                                )
                                            }
                                        ]}
                                        pagination={false}
                                        size='small'
                                        bordered
                                        scroll={{ y: 'calc(100vh - 250px)' }}
                                    />
                                </TabPane>
                            </Tabs>
                        </div>
                    </div>

                    {selectedType && <TypeForm type={selectedType} onCancel={() => setSelectedType(null)} onSuccess={onTypeSuccess} />}
                    <EmailForm visible={showAddEmail} type={typeView} onCancel={() => setShowAddEmail(false)} onSuccess={onAddEmailSuccess} />
                    <UserForm visible={showAddUser} type={typeView} onCancel={() => setShowAddUser(false)} onSuccess={onAddUserSuccess} />
                </Content>
            </RestrictPage>
        </>
    )
}