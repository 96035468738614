import { lifecycle, compose as recompose, withHandlers, withState } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pickBy, identity } from 'lodash';
import SqlLogV from './SqlLogV';
import { sqlCommandLogsRequest } from './../../reduxs/error-log-redux/reducer'

export function mapDispatchToProps(dispatch) {
  return {
    getLogs: (params) => dispatch(sqlCommandLogsRequest(params))
  };
}

const mapStateToProps = createStructuredSelector({
  fetching: store => store.errorLogs && store.errorLogs.sqlCmdFetching,
  pagination: store => store.errorLogs && store.errorLogs.cmdPagination,
  logs: store => store.errorLogs && store.errorLogs.commandLogs
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default recompose(
  withConnect,
  withState('params', 'setParams', { Offset: 1, Size: 50 }),
  withHandlers({
    onFetchData: ({ params, setParams, getLogs }) => (param) => {
      let _params = { ...params, ...param };

      if (_params.Offset === undefined) _params.Offset = 1;
      if (_params.Size === undefined) _params.Size = 50;

      _params = pickBy(_params, identity);
      setParams(_params);
      getLogs({ ..._params });
    },
    onChangeParams: ({ setParams, params }) => (_params = {}) => {
      const _prs = { ...params, Offset: 1, ..._params };
      setParams(_prs);
    }
  }),
  lifecycle({
    componentDidMount() {
      this.props.onFetchData();
    },
    componentDidUpdate(props) {

    }
  }),
)(SqlLogV);