import React from 'react';
import { setAccessToken, setEnvironment, setOpenHandleElement } from './actions';
import { connect } from 'react-redux';
import { compose as recompose, lifecycle, withPropsOnChange } from 'recompose';
import NotificationList from './../notification';
import { default as privateStore } from '../notification/private-store/store';

class PcsProvider extends React.Component {
    render() {
        const { width = 380, right = 5, top = 80, isShow, access_token } = this.props;
        return (
            <React.Fragment>
                <div style={{ position: 'fixed', width: width, right: right, top: top, display: (isShow ? 'block' : 'none'), zIndex: 999 }}>
                    {access_token && <NotificationList />}
                </div>
                {React.Children.only(this.props.children)}
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    };
}

const mapStateToProps = (store) => {
    return {
        isShow: store.notification && store.notification.show,
        access_token: store.oidc && store.oidc.user && store.oidc.user.access_token,
        forceOpen: store.notification && store.notification.forceOpen
    }
};


const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default recompose(
    withConnect,
    withPropsOnChange(['access_token', 'env'], props => {
        const { access_token, env } = props;
        if (access_token) {
            privateStore.dispatch(setAccessToken(access_token));
        }
        if (env) {
            privateStore.dispatch(setEnvironment(env));
        }
    }),
    withPropsOnChange(['notifyHandleSelector'], props => {
        const { notifyHandleSelector } = props;
        privateStore.dispatch(setOpenHandleElement(notifyHandleSelector));
    }),
    lifecycle({
        componentDidMount() {
            if (this.props.access_token) {
                privateStore.dispatch(setAccessToken(this.props.access_token));
            }

            if (this.props.notifyHandleSelector) {
                privateStore.dispatch(setOpenHandleElement(this.props.notifyHandleSelector));
            }

            if (this.props.env) {
                privateStore.dispatch(setEnvironment(this.props.env));
            }
        }
    })
)(PcsProvider);