import { createSelector } from 'reselect';
import { initialState } from './reducer';

const commissionState = (state) => state.commission || initialState;

const selectCommissions = () => createSelector(
  commissionState,
  ({ commissions }) => commissions
);
const selectCommissionsing = () => createSelector(
  commissionState,
  ({ commissionsing }) => commissionsing
);
const selectPagination = () => createSelector(
  commissionState,
  ({ pagination }) => pagination
);

const selectDefaultAccount = () => createSelector(
  commissionState,
  ({ defaultAccount }) => defaultAccount
);

const selectCommission = () => createSelector(
  commissionState,
  ({ commission }) => commission
);
const selectCommissioning = () => createSelector(
  commissionState,
  ({ commissioning }) => commissioning
);

const selectMyCommission = () => createSelector(
  commissionState,
  ({ myCommission }) => myCommission
);
const selectMyCommissioning = () => createSelector(
  commissionState,
  ({ myCommissioning }) => myCommissioning
);

const selectApproves = () => createSelector(
  commissionState,
  ({ approves }) => approves
);
const selectApprovesing = () => createSelector(
  commissionState,
  ({ approvesing }) => approvesing
);
const selectApprovePagination = () => createSelector(
  commissionState,
  ({ approvePagination }) => approvePagination
);

const selectTemples = () => createSelector(
  commissionState,
  ({ temples }) => temples
);
const selectTemplesing = () => createSelector(
  commissionState,
  ({ templesing }) => templesing
);

const selectValueModes = () => createSelector(
  commissionState,
  ({ valueModes }) => valueModes
);
const selectValueModesing = () => createSelector(
  commissionState,
  ({ valueModesing }) => valueModesing
);


export {
  commissionState,
  selectCommissions,
  selectCommissionsing,
  selectPagination,
  selectDefaultAccount,
  selectCommission,
  selectCommissioning,
  selectMyCommission,
  selectMyCommissioning,
  selectApproves,
  selectApprovesing,
  selectApprovePagination,
  selectTemples,
  selectTemplesing,
  selectValueModes,
  selectValueModesing
}
