import { call, put, takeLatest } from 'redux-saga/effects';

import * as cacheService from './../../services/cache-service'
import {
    updateServiceCacheSuccess,
    updateIdentityServiceCacheSuccess,
    updateServiceCacheOPSuccess,
    updateServiceCacheCRMSuccess,
    updateService2CacheSuccess
} from './reducer';

export function* updateServiceCache() {
    try {
        const { Result } = yield call(cacheService.updateServiceCache);

        if(Result && Result.length) {
            yield put(updateServiceCacheSuccess());
        } else {
            yield put(updateServiceCacheSuccess())
        }
    } catch (error) {
        yield put(updateServiceCacheSuccess())
    }
}

export function* updateService2Cache() {
    try {
        const { Result } = yield call(cacheService.updateService2Cache);

        if(Result && Result.length) {
            yield put(updateService2CacheSuccess());
        } else {
            yield put(updateService2CacheSuccess())
        }
    } catch (error) {
        yield put(updateService2CacheSuccess())
    }
}

export function* updateIdentityServiceCache() {
    try {
        const { Result } = yield call(cacheService.updateServiceCacheIdentityServer);

        if(Result && Result.length) {
            yield put(updateIdentityServiceCacheSuccess());
        } else {
            yield put(updateIdentityServiceCacheSuccess())
        }
    } catch (error) {
        yield put(updateServiceCacheSuccess())
    }
}

export function* updateOpCacheServer() {
    try {
        const { Result } = yield call(cacheService.updateOpCacheServer);

        if(Result) {
            yield put(updateServiceCacheOPSuccess());
        } else {
            yield put(updateServiceCacheOPSuccess())
        }
    } catch (error) {
        yield put(updateServiceCacheOPSuccess())
    }
}

export function* updateCrmCacheServer() {
    try {
        const { Result } = yield call(cacheService.updateCrmCacheServer);

        if(Result) {
            yield put(updateServiceCacheCRMSuccess());
        } else {
            yield put(updateServiceCacheCRMSuccess())
        }
    } catch (error) {
        yield put(updateServiceCacheCRMSuccess())
    }
}

export default [
    takeLatest('UPDATE_SERVICE_CACHE_REQUEST', updateServiceCache),
    takeLatest('UPDATE_IDENTITY_SERVICE_CACHE_REQUEST', updateIdentityServiceCache),
    takeLatest('UPDATE_SERVICE_CACHE_OP_REQUEST', updateOpCacheServer),
    takeLatest('UPDATE_SERVICE_CACHE_CRM_REQUEST', updateCrmCacheServer),
    takeLatest('UPDATE_SERVICE2_CACHE_REQUEST', updateService2Cache),
];