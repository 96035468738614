import React from "react";
import { Form, InputNumber  } from "antd";

const FormItem = Form.Item;

const makeField = Component => ({ input, meta, mode, children, hasFeedback, label, config, className, ...rest }) => {
  const hasError = meta.touched && meta.invalid;
  if(mode === 'comma' && input.value) {
    const value = `${input.value}`.replace(/(,*)/g, '');
    input.value = !isNaN(Number(value)) ? Number(value) : (input.value || '');
  }
  const changeInput = (value) => {
    value = `${value}`.replace(/(,*)/g, '');
    value = !isNaN(Number(value)) ? Number(value) : (input.value || '');
    input.onChange(value)
  }
  return (
    <FormItem
      {...config}
      label={label}
      validateStatus={hasError ? "error" : "success"}
      hasFeedback={hasFeedback && hasError}
      help={hasError && meta.error}
      className={className}
    >
      {
        mode === 'comma'
        ? <Component value={input.value} name={input.name} {...rest} onChange={changeInput} children={children} />
        : <Component {...input} {...rest} children={children} />
      }
    </FormItem>
  );
};

export default makeField(InputNumber);