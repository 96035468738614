import { call, put, takeLatest } from 'redux-saga/effects';
import { SubmissionError } from 'redux-form';
import * as customerInfoSheetService from '../../services/customer-info-sheet-service';
import { CustomerInformationSheetFormAction } from "./action";
import { getCustomerInfoSheetSuccess, deleteCustomerInfoSheetSuccess, failure } from './reducer';

export function* createCustomerInfoSheet({ payload }) {
    try {
      const { Result } = yield call(customerInfoSheetService.createCustomerInfoSheet, payload);
      if (Result) {
        yield put(CustomerInformationSheetFormAction.success(Result));
      } else {
        const message = "Tạo phiếu thông tin thất bại";
        const formError = new SubmissionError({
          _error: message
        });
        yield put(CustomerInformationSheetFormAction.failure(formError));
      }
    } catch (error) {
      const formError = new SubmissionError({
        _error: (error && error.Message) || 'Tạo phiếu thông tin thất bại'
      });
      yield put(CustomerInformationSheetFormAction.failure(formError));
    }
}

export function* confirmCustomerInfoSheets({ payload: { data, cb } }) {
  try {
    const { Result } = yield call(customerInfoSheetService.confirmCustomerInfoSheet, data);
    cb(true, Result)
  } catch (error) {
    cb(false)
  }
}

export function* getCustomerInfoSheets({ payload: { params } }) {
  try {
    const { Result: { Data, Pagination } } = yield call(customerInfoSheetService.getCustomerInfoSheet, params);
    yield put(getCustomerInfoSheetSuccess({ data: Data, pagination: Pagination }));
  } catch (error) {
    yield put(getCustomerInfoSheetSuccess({ data: [] }))
  }
}

export function* deleteCustomerInfoSheets({ payload: { params, cb } }) {
  try {
    const { Result } = yield call(customerInfoSheetService.deleteCustomerInfoSheet, params);
    if (cb) {
      cb(Result)
    }
    yield put(deleteCustomerInfoSheetSuccess({ success: Result }));
  } catch (error) {
    yield put(deleteCustomerInfoSheetSuccess({ success: false }));
  }
}

export function* InfoSheetDownload({ payload = {} }) {
  const { params, cb } = payload;
  try {
    const result = yield call(customerInfoSheetService.InfoSheetDownload, params);
    if (result) {
      if (cb) cb('success');
    } else {
      if (cb) cb('error');
    }
  } catch (error) {
    if (cb) cb('error');
    yield put(failure(error));
  }
}

export default [
    takeLatest(CustomerInformationSheetFormAction.REQUEST, createCustomerInfoSheet),
    takeLatest('GET_CUSTOMER_INFO_SHEET_REQUEST', getCustomerInfoSheets),
    takeLatest('CONFIRM_CUSTOMER_INFO_SHEET_REQUEST', confirmCustomerInfoSheets),
    takeLatest('DELETE_CUSTOMER_INFO_SHEET_REQUEST', deleteCustomerInfoSheets),
    takeLatest('INFO_SHEET_DOWNLOAD_REQUEST', InfoSheetDownload),
];