import { lifecycle, compose as recompose, withState, withHandlers} from 'recompose';
import { connect } from 'react-redux';
import { notification } from 'antd';
import { createStructuredSelector } from 'reselect';
import _ from 'lodash';
import { fetchClientsRequest } from '../../reduxs/menu-redux/reducer';
import { selectClients } from '../../reduxs/menu-redux/selectors';

import { fetchRolesRequest, fetchRoleUsersRequest, toggleActiveRolesRequest } from '../../reduxs/role-redux/reducer';
import { selectRoles, selectRolesing, selectRoleUsers, selectPagination, selectLoading } from '../../reduxs/role-redux/selectors';

import RoleV from './RoleV';

export function mapDispatchToProps(dispatch) {
  return {
    getRoles: (params) => dispatch(fetchRolesRequest(params)),
    getUserRoles: (params) => dispatch(fetchRoleUsersRequest(params)),
    toggleRoles: (params) => dispatch(toggleActiveRolesRequest(params)),
    getClients: (params) => dispatch(fetchClientsRequest(params)),
  };
}

const mapStateToProps = createStructuredSelector({
  roles: selectRoles(),
  loading: selectRolesing(),
  clients: selectClients(),
  roleusers: selectRoleUsers(),
  pagination: selectPagination(),
  roleusersing: selectLoading(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default recompose(
  withConnect,
  withState('open', 'close', false),
  withState('openRole', 'closeRole', false),
  withState('openUser', 'closeUser', false),
  withState('toggle', 'setToggle', null),
  withState('tabs', 'setTabs', '1'),
  withState('roleId', 'setRoleId', null),
  withState('params', 'setParams', { Offset: 1, Size: 20 }),
  withHandlers({
    fetchRoleUsers: ({ roleId, getUserRoles, setParams, params}) => (values) => {
      let _params = { ...params, Offset: 1, RoleId: roleId, ...values }
      _params = _.pickBy(_params, _.identity);
      setParams(_params);
      getUserRoles(_params)
    }
  }),
  withHandlers({
    fetchRoles: ({ getRoles}) => () => {
      getRoles()
    }
  }),
  withHandlers({
    togleRoles: ({ fetchRoles, setToggle, toggleRoles}) => (role, IsActive) => {
      if(role && role.Id) {
        const data = {
          IsActive: IsActive,
          Id: [role.Id]
        }
        setToggle(role.Id)
        const callback = (type) => {
          if(type === 'error') {
            notification[type]({message: 'Cập nhật thất bại!'});
          } else {
            notification[type]({message: 'Cập nhật thành công!'});
          }
          fetchRoles();
          setToggle(null)
        }

        const _params ={
          data: data,
          cb: callback
        }
        toggleRoles(_params)
      }
    }
  }),
  lifecycle({
    componentDidMount() {
      this.props.getClients();
      this.props.fetchRoles();
    }
  }),
)(RoleV);