import {
    SET_ACCESS_TOKEN,
    SET_ENVIRONMENT,
    SET_HANDLE_OPEN_ELEMENT
} from '../constants';

const initialState = {
    access_token: null,
    env: 'prod',
    handleOpenElmt: null
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_ACCESS_TOKEN:
            return Object.assign({}, { ...state }, { access_token: action.payload });

        case SET_ENVIRONMENT:
            return Object.assign({}, { ...state }, { env: action.payload });

        case SET_HANDLE_OPEN_ELEMENT:
            return Object.assign({}, { ...state }, { handleOpenElmt: action.payload });

        default:
            return state;
    }
}
