import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Modal, Input, Button, message } from 'antd'
import { addSubscriptionTypes, updateSubscriptionTypes } from '../../../../reduxs/email-subscription-redux/reducer'
import './style.scss'

export default ({ type, onCancel, onSuccess }) => {
    const dispatch = useDispatch()
    const [name, setName] = useState(type.Name)
    const [code, setCode] = useState(type.Code)
    const [clientId, setClientId] = useState(type.ClientId)
    const [loading, setLoading] = useState(false)

    const onClick = () => {
        setLoading(true)
        if (type.Id) {
            dispatch(updateSubscriptionTypes({
                params: {
                    Id: type.Id,
                    Name: name,
                    Code: code,
                    ClientId: clientId
                },
                cb: (isOK) => {
                    setLoading(false)
                    if (!isOK) {
                        message.error('Đã xảy ra lỗi')
                    } else {
                        onSuccess && onSuccess()
                    }
                }
            }))
        } else {
            dispatch(addSubscriptionTypes({
                params: {
                    Name: name,
                    Code: code,
                    ClientId: clientId
                },
                cb: (isOK) => {
                    setLoading(false)
                    if (!isOK) {
                        message.error('Đã xảy ra lỗi')
                    } else {
                        onSuccess && onSuccess()
                    }
                }
            }))
        }
    }

    return (
        <>
            <Modal onCancel={onCancel} visible title={type.Id ? 'Cập nhật' : 'Thêm mới'} footer={null} >
                <div className='row'>
                    <div className='col-lg-12'>
                        <div>
                            <label className='bold'>Mô tả <span className='text-danger'>*</span></label>
                        </div>
                        <div>
                            <Input value={name} onChange={e => setName(e.target.value)} />
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-lg-12'>
                        <div>
                            <label className='bold'>Mã loại <span className='text-danger'>*</span></label>
                        </div>
                        <div>
                            <Input value={code} onChange={e => setCode(e.target.value)} />
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-lg-12'>
                        <div>
                            <label className='bold'>Client Id</label>
                        </div>
                        <div>
                            <Input value={clientId} onChange={e => setClientId(e.target.value)} />
                        </div>
                    </div>
                </div>

                <div className='row' style={{ marginTop: 15 }}>
                    <div className='col-lg-12'>
                        <Button onClick={onCancel}>Huỷ</Button>
                        <Button loading={loading} disabled={!code || !name} type='primary' style={{ marginLeft: 4 }} onClick={onClick}>{ type.Id ? 'Cập nhật' : 'Thêm' }</Button>
                    </div>
                </div>
            </Modal>
        </>
    )
}