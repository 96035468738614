import { call, put, takeLatest } from 'redux-saga/effects';
import * as AssetSaleServices from '../../services/asset-sale-service';
import moment from 'moment';
import { AssetSaleFormAction } from "./action";
import { SubmissionError } from "redux-form";

import {
  assetSalesSuccess,
  getAssetSaleSuccess
} from './reducer';


export function* fetchAssetSales({ payload }) {
  try {
    const { Result } = yield call(AssetSaleServices.fetchAssetSales, payload);
    if(Result.Data) {
      const { Data, Pagination } = Result
      yield put(assetSalesSuccess({ sales: Data, pagination: Pagination }));
    } else {
      yield put(assetSalesSuccess({ sales: [], pagination: { Page: 1,Size: 50, Total: 0} }));
    }
  } catch (error) {
    yield put(assetSalesSuccess({ sales: [], pagination: { Page: 1,Size: 50, Total: 0} }));
  }
}

export function* getAssetSale({ payload }) {
  try {
    const { Result } = yield call(AssetSaleServices.getAssetSale, payload);
    if(Result) {
      yield put(getAssetSaleSuccess({ sale: Result }));
    } else {
      yield put(getAssetSaleSuccess({ sale: null }));
    }
  } catch (error) {
    yield put(getAssetSaleSuccess({ sale: null }));
  }
}

export function* deleteAssetSale({ payload: { data, cb } }) {
  try {
    const { Result } = yield call(AssetSaleServices.deleteAssetSale, data);
    if(Result) {
      cb(true)
    } else {
      cb(false)
    }
  } catch (error) {
    cb(false)
  }
}

export function* saveAssetSale({ payload }) {
  try {
    if(payload.CREATED_DATE) payload.CREATED_DATE = moment(payload.CREATED_DATE).format('YYYY-MM-DD')
    if(payload.SALED_DATE) payload.SALED_DATE = moment(payload.SALED_DATE).format('YYYY-MM-DD')

    const { Result } = yield call(AssetSaleServices.saveAssetSale, payload);
    if (Result) {
      yield put(AssetSaleFormAction.success(Result));
    } else {
      const formError = new SubmissionError({
        _error: 'Tạo phiếu bàn giao thất bại!'
      });
      yield put(AssetSaleFormAction.failure(formError));
    }
  } catch (error) {
    let _message = "Tạo phiếu bàn giao thất bại!";
    if(error && typeof error.Description === 'string') {
      _message = error.Description;
    }
    const formError = new SubmissionError({_error: _message});
    yield put(AssetSaleFormAction.failure(formError));
  }
}

export function* confirmAssetSale({ payload: { data, cb } }) {
  try {
    const Result  = yield call(AssetSaleServices.confirmAssetSale, data);
    if(Result) {
      cb(true)
    } else {
      cb(false)
    }
  } catch (error) {
    cb(false)
  }
}

export function* printAssetSale({ payload: { data, cb } }) {
  try {
    const { Result }  = yield call(AssetSaleServices.printAssetSale, data);
    if(Result) {
      cb(true)
    } else {
      cb(false)
    }
  } catch (error) {
    cb(false)
  }
}

export default [
  takeLatest('ASSET_SALE_LIST_REQUEST', fetchAssetSales),
  takeLatest('ASSET_SALE_REQUEST', getAssetSale),
  takeLatest('ASSET_DEL_SALE_REQUEST', deleteAssetSale),
  takeLatest('ASSET_SALE_CONFIRM_REQUEST', confirmAssetSale),
  takeLatest('ASSET_SALE_PRINT_REQUEST', printAssetSale),
  takeLatest(AssetSaleFormAction.REQUEST, saveAssetSale),
];
