import { call, put, takeLatest } from 'redux-saga/effects';
import * as PolicyService from '../../services/policy-service';

import { PolicyFormAction } from "./action";
import { SubmissionError } from "redux-form";

import {
  fetchPoliciesSuccess,
  failure
} from './reducer';

export function* fetchPolicies({ payload = {} }) {
  try {
    const { Result } = yield call(PolicyService.getPolicies, payload);
    if(Result) {
      yield put(fetchPoliciesSuccess({ policies: Result }));
    } else {
      yield put(fetchPoliciesSuccess({ policies: [] }));
    }
  } catch (error) {
    yield put(fetchPoliciesSuccess({ policies: [] }));
    yield put(failure(error));
  }
}

export function* savePolicy({ payload }) {
  try {
    let result = yield call(PolicyService.addPolicy, payload);
    if (result) {
      yield put(PolicyFormAction.success("Lưu thành công!"));
    } else {
      const { message } = "Lưu thất bại!";
      const formError = new SubmissionError({
        _error: message
      });
      yield put(PolicyFormAction.failure(formError));
    }
  } catch (error) {
    const formError = new SubmissionError({
      _error: error
    });
    yield put(PolicyFormAction.failure(formError));
  }
}

export function* deletePolicy({ payload }) {
  const { data, cb } = payload;
  try {
    const { Result } = yield call(PolicyService.deletePolicy, data);
    if(Result) {
      cb('success')
    } else {
      cb('error')
    }
  } catch (error) {
    cb('error')
  }
}

export default [
  takeLatest('FETCH_POLICIES_REQUEST', fetchPolicies),
  takeLatest('DELETE_POLICY_REQUEST', deletePolicy),
  takeLatest(PolicyFormAction.REQUEST, savePolicy),
];
