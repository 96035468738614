import './style.scss';
import React from 'react';
import { Layout, Select, Popover, Checkbox, Pagination, Table, Button, Input, Tabs, Popconfirm } from 'antd';
import { UndoOutlined, LockOutlined, AppstoreOutlined, EditOutlined, PlusOutlined, UnlockOutlined, RollbackOutlined, EyeOutlined, OrderedListOutlined } from '@ant-design/icons';
import { Icons } from '../../assets';
import moment from 'moment';
import RestrictPage from './../../components/RestrictComponents/RestrictPage';
import RestrictFeature from './../../components/RestrictComponents/RestrictFeature';
import FormPassword from './components/FormPassword';
import FormStaff from './components/StaffForm';
import Commission from './CommissionVM';
const { Content } = Layout;
const { TabPane } = Tabs;

const StaffsV = (props) => {
    const { params, staffs, loading, pagination, staff, setStaff, openPass, setOpenPass, fetchStaffs, isMobile, openStaff, setOpenStaff, onInsertStaff, onlyView, setOnlyView } = props;
    const { tabs, setTabs } = props;
    const size = params.Size && Number(params.Size) > 0 ? Number(params.Size) : 20;
    const offset = params.Offset && Number(params.Offset) > 0 ? Number(params.Offset) : 1;
    const total = pagination && pagination.Total && Number(pagination.Total) > 0 ? Number(pagination.Total) : 0;
    const n_start = total > 0 ? (((offset - 1) * size) + 1) : 0;
    const n_end = total > 0 ? ((offset * size) > total ? total : offset * size) : 0;
    return (
        <RestrictPage>
            <Content className="page-menus">
                <div className="panel-header">
                    <div className="header-title">
                        <i className="fa fa-home icon" aria-hidden="true"></i>
                        <span>Hệ thống</span>
                        <span className="icon-next"><i className="fa fa-angle-right"></i></span>
                        <span className="active">Nhân viên</span>
                    </div>
                </div>
                <div className={isMobile ? "col-md-12 pd-0" : "col-md-12"}>
                    <div className="panel-body">
                        <Tabs activeKey={tabs} size="small" onChange={(tab) => setTabs(tab)}>
                            <TabPane tab={<span className="bold"><OrderedListOutlined />Danh sách nhân viên</span>} key="1">
                                <div className="row">
                                    <div className="col-md-4">
                                        <label className="total-filter">
                                            <img src={Icons.common.filter} alt="Số nhân viên" />|&ensp;<span>{n_start} - {n_end}</span> trong tổng <span>{total}</span> nhân viên
                                        </label>
                                    </div>
                                    <div className="col-md-8 text-right">
                                        <RestrictFeature key="add-staff" feature="ADD_NEW_STAFF"> 
                                        {
                                            ({allowed}) => (
                                                <Button type="primary" disabled={!allowed} onClick={() => {
                                                    setOnlyView(false)
                                                    onInsertStaff(true)
                                                }} icon={<PlusOutlined />}>Thêm nhân viên</Button>
                                            )
                                        }
                                        </RestrictFeature>
                                        &emsp;
                                        <span>
                                            <Input.Search defaultValue={params.Query || ''} placeholder="Tìm kiếm nhân viên..." onSearch={(value) => fetchStaffs({ Query: value })} style={{ maxWidth: isMobile ? '210px' : '280px' }} />
                                        </span>
                                        &emsp;
                                        <Button onClick={() => fetchStaffs()} title="Làm mới" icon={<UndoOutlined />}>{!isMobile && 'Làm mới'}</Button>&ensp;
                                        <Popover placement="bottomRight" content={content(props)} trigger="click">
                                            <Button title="Ẩn/Hiển thị cột" icon={<AppstoreOutlined />} />
                                        </Popover>
                                    </div>
                                </div>
                                <div className="row mr-t-10">
                                    <div className="col-md-12">
                                        <Table
                                            bordered
                                            loading={loading}
                                            size="small"
                                            className="table-main"
                                            columns={createColums(props)}
                                            rowKey={(row, key) => key}
                                            dataSource={staffs || []}
                                            pagination={false}
                                            scroll={{ x: 800 }}
                                        />
                                    </div>
                                </div>
                                <div className="row mr-t-10">
                                    <div className="col-md-6">
                                        <span>Hiển thị &ensp;
                                            <Select value={size || 20} style={{ width: 120 }} onChange={(size) => fetchStaffs({ Size: size, Offset: 1 })}>
                                                <Select.Option value="20">20</Select.Option>
                                                <Select.Option value="50">50</Select.Option>
                                                <Select.Option value="100">100</Select.Option>
                                                <Select.Option value="200">200</Select.Option>
                                            </Select>
                                            &nbsp; nhân viên / trang
                                        </span>
                                    </div>
                                    <div className="col-md-6 text-right">
                                        <Pagination current={offset || 1} pageSize={size || 20} total={total || 0} showSizeChanger={false} onChange={(offset) => fetchStaffs({ Offset: offset })} />
                                    </div>
                                </div>
                            </TabPane>
                            <TabPane tab={<span className="bold"><OrderedListOutlined />Cài đặt chiết khấu</span>} key="2">
                                <Commission/>
                            </TabPane>
                        </Tabs>
                    </div>
                    {openPass && staff && <FormPassword open={openPass} fetchStaffs={fetchStaffs} close={() => setStaff(null, () => setOpenPass(false))} staff={staff} />}
                    {openStaff && <FormStaff open={openStaff} fetchStaffs={fetchStaffs} onlyView={onlyView} close={() => setStaff(null, () => setOpenStaff(false))} staff={staff} />}
                </div>
            </Content>
        </RestrictPage>
    );
};

export default StaffsV

const createColums = ({ columns = [], onPassStaff, onUpdateStaff, onLockStaff, onUnlockStaff, onViewStaff, setOnlyView }) => {
    const _columns = [];
    if (columns && columns.length > 0) {
        columns.forEach((col) => {
            if (col.show) {
                if (col.type === "date") {
                    _columns.push({ title: col.name, key: col.id, width: col.width, className: col.class, render: (item) => item[col.id] ? <span><i className="fa fa-calendar-o" />&ensp;{moment(item[col.id]).format("DD-MM-YYYY")}</span> : 'N/A' })
                }
                else if (col.type === "gender") {
                    _columns.push({
                        title: col.name, key: col.id, width: col.width, className: col.class, render: (item) => {
                            if (item[col.id] === "1") {
                                return <span>Nam</span>
                            }
                            else if (item[col.id] === "2") {
                                return <span>Nữ</span>
                            }
                            else if (item[col.id] === "3") {
                                return <span>Khác</span>
                            }
                            else {
                                return <span>N/A</span>
                            }
                        }
                    })
                }
                else if (col.type === "time_exec") {
                    _columns.push({ title: col.name, key: col.id, width: col.width, className: col.class, render: (item) => item[col.id] ? <span style={{ 'fontSize': '12px' }}><i className="fa fa-user" />&ensp;{item[col.by]} <br /><i className="fa fa-calendar-o" />&ensp;{moment(item[col.date]).format("DD-MM-YYYY hh:mm:ss")}</span> : 'N/A' })
                }
                else if (col.type === "status") {
                    _columns.push({
                        title: col.name, key: col.id, width: col.width, className: col.class, render: (item) => {
                            return item[col.id] ? <span style={{
                                textAlign: 'center',
                                color: '#00b300',
                                borderRadius: '2px',
                                minWidth: '100px',
                                display: 'inline-block'
                            }}>Đã kích hoạt</span> : <span style={{
                                textAlign: 'center',
                                color: 'rgb(197, 0, 0)',
                                borderRadius: '2px',
                                minWidth: '100px',
                                display: 'inline-block'
                            }}>Đã khóa</span>
                        }
                    })
                }
                else {
                    _columns.push({ title: col.name, dataIndex: col.id, key: col.id, width: col.width, className: col.class })
                }
            }
        });
        var functionColumn = {
            title: 'Chức năng', dataIndex: '', key: 'action', fixed: "right", className: "text-center", align: 'center', width: 150, render: (item) => {
                if (item && item.UserId) {
                    return <div>
                        <Button icon={<EyeOutlined />} title="Thông tin chi tiết nhân viên" size="small" onClick={() => setOnlyView(true, () => onViewStaff(item))}></Button>&ensp;
                        <Button icon={<EditOutlined />} title="Cập nhật thông tin" disabled={!item.IsAccountActive} size="small" onClick={() => setOnlyView(false,() => onUpdateStaff(item))}></Button>&ensp;
                        <Button icon={<RollbackOutlined />} title="Đặt mật khấu cho nhân viên" disabled={!item.IsAccountActive} size="small" onClick={() => onPassStaff(item)}></Button>&ensp;
                        {
                            item.IsAccountActive === true ?
                            <Popconfirm title="Bạn có chắc muốn khóa nhân viên?" onConfirm={() => onLockStaff(item)} placement="topRight" okText="Khóa" cancelText="Không">
                                <Button icon={<LockOutlined />} title="Mở khóa nhân viên" size="small" type="danger" />
                            </Popconfirm> :
                            <Popconfirm title="Bạn có chắc muốn mở khóa nhân viên?" onConfirm={() => onUnlockStaff(item)} placement="topRight" okText="Mở khóa" cancelText="Không">
                                <Button icon={<UnlockOutlined style={{ fontSize: '18px', color: 'green' }} />} title="Mở khóa nhân viên" size="small" type="success" />
                            </Popconfirm>
                        }
                    </div>
                }
                return <span className="text-danger">Chưa có ID</span>
            }
        };
        _columns.push(functionColumn);
    }
    return _columns;
}

const content = ({ columns, stateColumn }) => {
    return <div className="row" style={{ maxWidth: '400px' }}>
        {columns && columns.map((col, key) =>
            <div className="col-md-6" key={key} style={{ padding: '5px 15px' }}><Checkbox checked={col.show} onChange={() => stateColumn(col)} >{col.name}</Checkbox></div>)
        }
    </div>
}