import { call, put, takeLatest } from 'redux-saga/effects';

import * as menuService from './../../services/user-menu-service'
import {
    getMyMenuSuccess
} from './reducer';

export function* fetchMyMemu({ payload = {} }) {
    try {
        const { Result } = yield call(menuService.getMyMenu);

        if(Result && Result.length) {
            yield put(getMyMenuSuccess({ menu: Result }));
        } else {
            yield put(getMyMenuSuccess([]))
        }
    } catch (error) {
        yield put(getMyMenuSuccess([]))
    }
}

export default [
    takeLatest('FETCH_MY_MENU_REQUEST', fetchMyMemu)
];