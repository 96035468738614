import React, { useState, useEffect } from 'react'
import { Modal, Button, Select, message } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { addUserSubscriptionRequest } from '../../../../reduxs/email-subscription-redux/reducer'
import { staffAllRequest } from '../../../../reduxs/common-redux/reducer'
import './style.scss'

export default ({ type, onCancel, onSuccess, visible }) => {
    const dispatch = useDispatch()
    const staffs = useSelector(store => store.common && store.common.staffAlls)
    const [loading, setLoading] = useState(false)
    const [userCode, setUser] = useState([])

    const onClick = () => {
        setLoading(true)
        dispatch(addUserSubscriptionRequest({
            params: {
                TypeId: type.Id,
                UserCode: userCode
            },
            cb: (isOk) => {
                setLoading(false)
                if (!isOk) {
                    message.error('Đã xảy ra lỗi')
                } else {
                    setUser([])
                    onSuccess && onSuccess()
                }
            }
        }))
    }

    const getStaffs = (_params = { Size: 25 }) => {
        dispatch(staffAllRequest(_params))
    }

    useEffect(() => {
        getStaffs()
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <Modal visible={visible} title="Thêm người dùng nhận thông báo" onCancel={onCancel} footer={null}>
                <div>
                    <label className='bold'>Loại: {type?.Name}</label>
                </div>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div>
                            <label className='bold'>Người dùng</label>
                        </div>
                        <div>
                            <Select
                                style={{ width: '100%' }}
                                mode='multiple'
                                options={(staffs || []).map(x => ({ label: `${x.StaffCode} - ${x.FullName}`, value: x.StaffCode }))}
                                onChange={v => setUser(v)}
                                allowClear
                                showSearch
                                onSearch={(value) => getStaffs({ Limit: 25, Query: value })}
                                placeholder="Chọn một hoặc nhiều"
                            />
                        </div>
                    </div>
                </div>

                <div className='row' style={{ marginTop: 15 }}>
                    <div className='col-lg-12'>
                        <Button onClick={onCancel}>Huỷ</Button>
                        <Button loading={loading} type='primary' style={{ marginLeft: 4 }} onClick={onClick}>Thêm</Button>
                    </div>
                </div>
            </Modal>
        </>
    )
}