import { lifecycle, compose as recompose, withHandlers} from 'recompose';
import { message } from 'antd';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getStaticsticRequest, getSheetsRequest, getPickupsRequest } from '../../reduxs/dashboard-redux/reducer';
import { selectStatistics, selectStatisting, selectSheets, selectSheetsing, selectPickups, selectPickupsing } from '../../reduxs/dashboard-redux/selectors';

import DashboardV from './DashboardV';

export function mapDispatchToProps(dispatch) {
  return {
    getStaticstic: () => dispatch(getStaticsticRequest()),
    getSheets: () => dispatch(getSheetsRequest()),
    getPickups: () => dispatch(getPickupsRequest())
  };
}

const mapStateToProps = createStructuredSelector({
  statistics: selectStatistics(),
  statisting: selectStatisting(),
  sheets: selectSheets(),
  sheetsing: selectSheetsing(),
  pickups: selectPickups(),
  pickupsing: selectPickupsing(),
  menus: (state) => state.userMenu && state.userMenu.myMenu
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default recompose(
  withConnect,
  withHandlers({
    onChangeUrl: ({history, menus}) => ({pathname, state}) => {
      if(menus && menus.length && menus.find(i => i.Route === pathname)) {
        history.push({pathname, state});
      } else {
        message.warn('Bạn không có quyền vào menu này')
      }
    },
    isShowItem: ({ menus }) => (path) => {
      return (menus || []).map(x => x.Route).indexOf(path) !== -1;
    }
  }),

  lifecycle({
    componentDidMount() {
      const { statistics, getStaticstic } = this.props;
      if(!(statistics && statistics.length)) {
        getStaticstic();
      }
      // this.props.getSheets();
    },
    componentDidUpdate(props) {
      
    }
  }),
)(DashboardV);