
import React from 'react';
import {  Modal, Input, Button, Popconfirm, message } from 'antd';
import { compose as recompose, withHandlers, lifecycle, withState } from 'recompose';
import { PlusOutlined, EditOutlined, SaveOutlined, DeleteOutlined, StopOutlined, MailOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { getEmailRequest, saveEmailRequest, delEmailRequest } from '../../../reduxs/account-redux/reducer';
import { selectEmails, selectEmailsing } from '../../../reduxs/account-redux/selectors';

function validateEmail(email) {
    const regex = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
    return regex.test(email);
}

const EmailForm = (props) => {
    const { open, emails, email, setEmail, plus, setPlus, onSaveEmail, onChangeEmail, onDeleteEmail, enable, setEnable, saving, onHide } = props;
    return (
        <Modal
            title="Quản lý email chốt cước"
            visible={open}
            style={{ top: 20 }}
            width={'560px'}
            footer={null}
            maskClosable={false}
            onOk={() => onHide()}
            onCancel={() => onHide()}
            className="modal-form-email"
            >
                <div className="box-form-type">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th className="bold">DANH SÁCH EMAIL CHỐT CƯỚC</th>
                                <th width="80px"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {emails.length > 0 &&
                                emails.map((mail, key) => {
                                    if(enable === key) {
                                        return (
                                            <tr>
                                                <td>
                                                    <Input prefix={<MailOutlined />} value={email || ''} onChange={(e) => setEmail(e.target.value)}/>
                                                </td>
                                                <td style={{paddingTop: '12px'}}>
                                                    <Popconfirm title="Bạn có chắc muốn cập nhập email này？" okText="Có" cancelText="Không" onConfirm={() => onChangeEmail(mail)}>
                                                        <Button loading={saving} disabled={!validateEmail(email)} size="small" type="primary" icon={<SaveOutlined />} />
                                                    </Popconfirm>
                                                    &ensp;
                                                    <Button onClick={() => setEnable(null)} disabled={saving} size="small" icon={<StopOutlined />} />
                                                </td>
                                            </tr>
                                        )
                                    }
                                    return (
                                        <tr>
                                            <td><label style={{ padding: '0px 12px', margin: '0px', lineHeight: '32px'}}><MailOutlined /> {mail.PaymentEmail}</label></td>
                                            <td style={{paddingTop: '12px'}}>
                                                <Button onClick={() => setEmail(mail.PaymentEmail, () => setEnable(key))} size="small" icon={<EditOutlined />} />&ensp;
                                                <Popconfirm title="Bạn có chắc muốn xóa email này？" okText="Có" cancelText="Không" onConfirm={() => onDeleteEmail(mail)}>
                                                    <Button size="small" icon={<DeleteOutlined />} danger/>
                                                </Popconfirm>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            <tr>
                                <td>
                                    <Input prefix={<MailOutlined />} value={plus || undefined} placeholder="Nhập địa chỉ email..." onChange={(e) => setPlus(e.target.value)} />
                                </td>
                                <td style={{paddingTop: '12px'}}>
                                    <Button onClick={() => onSaveEmail()} disabled={!validateEmail(plus)} loading={saving} size="small" type="primary" icon={<PlusOutlined />} />&ensp;
                                    <Button onClick={() => setPlus('')} disabled={saving} size="small" type="primary" icon={<StopOutlined />} danger />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
        </Modal>
    )
}

function mapDispatchToProps(dispatch) {
    return {
        getEmails: (params) => dispatch(getEmailRequest(params)),
        saveEmail: (params) => dispatch(saveEmailRequest(params)),
        deleteEmail: (params) => dispatch(delEmailRequest(params))
    };
}
const mapStateToProps = createStructuredSelector({
    emails: selectEmails(),
    loading: selectEmailsing(),
  });
  
const withConnect = connect(mapStateToProps, mapDispatchToProps);
  
export default recompose(
    withConnect,
    withState('value', 'setValue', ''),
    withState('submitting', 'setSubmitting', false),
    withState('plus', 'setPlus', ''),
    withState('enable', 'setEnable', null),
    withState('email', 'setEmail', null),
    withState('saving', 'setSaving', false),
    withHandlers({
        fetchEmails: ({ account, getEmails }) => (param) => {
            getEmails({ CustomerId: account.Id });
        }
    }),
    withHandlers({
        onSaveEmail: ({ plus, account, saveEmail, setPlus, setSaving, fetchEmails }) => () => {
            if(account && account.Id) {
                if(plus && validateEmail(plus)) {
                    setSaving(true);
                    saveEmail({
                        data: {
                            CustomerId: account.Id,
                            PaymentEmail: plus,
                        },
                        cb: (type, error=null) => {
                            if(type === 'success') {
                                setPlus('', () => fetchEmails());
                                message.success("Lưu email thành công");
                            } else message.error(error || 'Lưu email thất bại');
                            setSaving(false);
                        }
                    })
                } else {
                    message.error('Email không hợp lệ');
                }
            } else {
                message.error('Không tìm thấy TK cập nhập');
            }
        },
        onChangeEmail: ({ email, saveEmail, setEnable, setSaving, fetchEmails }) => (item) => {
            if(email && validateEmail(email)) {
                setSaving(true);
                saveEmail({
                    data: {
                        ID: item.ID,
                        PaymentEmail: email,
                    },
                    cb: (type, error=null) => {
                        if(type === 'success') {
                            setEnable(null, () => fetchEmails());
                            message.success("Cập nhập thành công");
                        } else message.error(error || 'Cập nhập thất bại');
                        setSaving(false);
                    }
                })
            } else {
                message.error('Email không hợp lệ');
            }
        },
        onDeleteEmail: ({ deleteEmail, setSaving, fetchEmails }) => (item) => {
            setSaving(true);
            deleteEmail({
                data: {ID: item.ID},
                cb: (type, error=null) => {
                    if(type === 'success') {
                        fetchEmails();
                        message.success("Xóa email thành công");
                    } else message.error( error || 'Xóa email thất bại');
                    setSaving(false);
                }
            })
        },
        onHide: ({setValue, close}) => () => {
            setValue(null, () => close(false))
        }
    }),
    lifecycle({
        componentDidMount() {
            this.props.fetchEmails();
            // const {account, setValue} = this.props;
            // if(account && account.AccountTypeId) setValue(account.AccountTypeId)
        },
    }),
)(EmailForm);
