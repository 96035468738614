import { createSelector } from 'reselect';
import { initialState } from './reducer';

const menuState = (state) => state.menu || initialState;

const selectMenus = () => createSelector(
  menuState,
  ({ menus }) => menus
);
const selectMenusing = () => createSelector(
  menuState,
  ({ menusing }) => menusing
);

const selectClients = () => createSelector(
  menuState,
  ({ clients }) => clients
);
const selectClientsing = () => createSelector(
  menuState,
  ({ clientsing }) => clientsing
);

const selectGroups = () => createSelector(
  menuState,
  ({ groups }) => groups
);
const selectGroupsing = () => createSelector(
  menuState,
  ({ groupsing }) => groupsing
);

const selectMenuGroups = () => createSelector(
  menuState,
  ({ menugroups }) => menugroups
);
const selectMenuGroupsing = () => createSelector(
  menuState,
  ({ menugroupsing }) => menugroupsing
);

const selectUserMenus = () => createSelector(
  menuState,
  ({ usermenus }) => usermenus
);
const selectUserMenusing = () => createSelector(
  menuState,
  ({ usermenusing }) => usermenusing
);

export {
  menuState,
  selectMenus,
  selectMenusing,
  selectClients,
  selectClientsing,
  selectGroups,
  selectGroupsing,
  selectMenuGroups,
  selectMenuGroupsing,
  selectUserMenus,
  selectUserMenusing
}
