
import axios from '../utils/axiosInstance';
import FileSaver from 'file-saver';

export const getRegions = async (params) => {
  try {
    const { data } = await axios.get(`/local-manager/region`, {
      params: params
    });
    return data;
  } catch (error) {
    throw error;
  }
};
export const addRegion = async (region) => {
  try {
    const { data } = await axios.post(`/local-manager/region`, region);
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateRegion = async (region) => {
  try {
    const { data } = await axios.put(`/local-manager/region`, region);
    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchProvinceAlls = async () => {
  try {
    const { data } = await axios.get(`/local-manager/province`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getProvinces = async (params) => {
  try {
    const { data } = await axios.get(`/local-manager/province?RegionId=${params.RegionId}`, {
      params: params
    });
    return data;
  } catch (error) {
    throw error;
  }
};
export const addProvince = async (province) => {
  try {
    const { data } = await axios.post(`/local-manager/province`, province);
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateProvince = async (province) => {
  try {
    const { data } = await axios.put(`/local-manager/province`, province);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getDistricts = async (params) => {
  try {
    const { data } = await axios.get(`/local-manager/district?ProvinceId=${params.ProvinceId}`, {
      params: params
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const addDistrict = async (district) => {
  try {
    const { data } = await axios.post(`/local-manager/district`, district);
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateDistrict = async (district) => {
  try {
    const { data } = await axios.put(`/local-manager/district`, district);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getWards = async (params) => {
  try {
    const { data } = await axios.get(`/local-manager/ward?DistrictId=${params.DistrictId}`, {
      params: params
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const addWard = async (ward) => {
  try {
    const { data } = await axios.post(`/local-manager/ward`, ward);
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateWard = async (ward) => {
  try {
    const { data } = await axios.put(`/local-manager/ward`, ward);
    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteProvince = async (province) => {
  try {
    const { data } = await axios.delete(`/local-manager/province`, {data: province});
    return data;
  } catch (error) {
    throw error;
  }
};
export const deleteDistrict = async (district) => {
  try {
    const { data } = await axios.delete(`/local-manager/district`, {data: district});
    return data;
  } catch (error) {
    throw error;
  }
};
export const deleteWards = async (ward) => {
  try {
    const { data } = await axios.delete(`/local-manager/ward`, {data: ward});
    return data;
  } catch (error) {
    throw error;
  }
};


export const getPanners = async (params) => {
  try {
    const { data } = await axios.get(`/op/partner/by-type?Type=CONNECT`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getRegionWards = async (params) => {
  try {
    const { data } = await axios.get(`/local-manager/ward-in-region`, {
      params: params
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getRegionStation = async (params) => {
  try {
    const { data } = await axios.get(`/delivery-zoning/by-station`, {
      params: params
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getRegionTrees = async (params) => {
  try {
    const { data } = await axios.get(`/delivery-zoning/by-station`, {
      params: params
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getRegionStaff = async (params) => {
  try {
    const { data } = await axios.get(`/delivery-zoning/by-staff`, {
      params: params
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const saveLocalRegions = async (region) => {
  try {
    const { data } = await axios.post(`/local-manager/add-local-to-region`, region);
    return data;
  } catch (error) {
    throw error;
  }
};

export const saveRegionWards = async (region) => {
  try {
    const { data } = await axios.post(`/delivery-zoning/station`, region);
    return data;
  } catch (error) {
    throw error;
  }
};

export const saveRegionStaff = async (region) => {
  try {
    const { data } = await axios.post(`/delivery-zoning/staff`, region);
    return data;
  } catch (error) {
    throw error;
  }
};

export const toggleRegionStation = async (region) => {
  try {
    const { data } = await axios.put(`/delivery-zoning/station`, region);
    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteRegionStation = async (region) => {
  try {
    const { data } = await axios.delete(`/delivery-zoning/station`, {data: region});
    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteRegionStaff = async (region) => {
  try {
    const { data } = await axios.delete(`/delivery-zoning/staff`, {data: region});
    return data;
  } catch (error) {
    throw error;
  }
};

export const saveWardInfo = async (region) => {
  try {
    const { data } = await axios.put(`/local-manager/ward-info`, region);
    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteWardInfo = async (region) => {
  try {
    const { data } = await axios.delete(`/local-manager/ward-info`, { data: region });
    return data;
  } catch (error) {
    throw error;
  }
};

export const downloadLocationList = async (params) => {
  try {
      const response = await axios.get('/admin/localtion/location-info/download', { params,  responseType: 'blob' });
      const { data } = response;
      const namefile = response.headers['pcs-filename-suggestion'];
      const fileType = response.headers['content-type'];
      var blob = new Blob([data], {type: fileType});
      FileSaver.saveAs(blob, namefile);
      return true;
  } catch (error) {
      throw error;
  }
};
