/* eslint-disable react/jsx-handler-names */
import React, { Component } from 'react'
import { Modal } from 'antd'
import Cropper from 'react-cropper'
import 'cropperjs/dist/cropper.css'

class CropperImage extends Component {
  constructor (props) {
    super(props)
    this.state = {}
    this.cropImage = this.cropImage.bind(this)
  }

  cropImage () {
    if (typeof this.cropper.getCroppedCanvas() === 'undefined') return
    const canvas = this.cropper.getCroppedCanvas();
    this.props.onCrop(canvas);
  }

  render () {
    const { src, close, open, ratio, title, width } = this.props
    return (
      <Modal
        title= { title }
        visible={ open }
        mask={false}
        maskClosable={false}
        width={width ? width : '520px'}
        footer={null}
        onOk={close}
        onCancel={close}
      >
        <Cropper
          aspectRatio={ratio}
          guides={false}
          ref={cropper => { this.cropper = cropper }}
          src={src}
          style={{height: '350px', width: '100%'}}
        />
        <br/>
        <div className="row record-info">
            <div className="col-md-12 text-right">
                <button type="button" onClick={close}className="btn btn-danger"><i className="fa fa-trash" />&ensp;Hủy bỏ</button>
                <button type="button" onClick={this.cropImage} className="btn btn-primary"><i className="fa fa-clone" />&ensp;Save</button>
            </div>
        </div>
      </Modal>
    )
  }
}

export default CropperImage
