import React from 'react';
import { Icons } from '../../assets';
import { Layout, Select, Pagination, Tag, Table, Button, Modal, DatePicker, Input } from 'antd';
import { SendOutlined, ReadOutlined, ClockCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import FileComponentV from './components/FileComponentVM';
import parse from 'html-react-parser';
import { apiEndpoint } from '../../config';
import './style.scss';
import RestrictPage from './../../components/RestrictComponents/RestrictPage';

const { RangePicker } = DatePicker;
const { Content } = Layout;
const disabledDate = (current) => {
    return current && current > moment();
}

const EmailManagementV = (props) => {
    const { pagination, params, fetching, emails, fetchEmails, setParams, setModalShown, modalShown, selectedEmail, onResendEmail, resending } = props;
    const size = params.Size && Number(params.Size) > 0 ? Number(params.Size) : 20;
    const offset = params.Offset && Number(params.Offset) > 0 ? Number(params.Offset) : 0;
    const total = pagination && pagination.Total && Number(pagination.Total) > 0 ? Number(pagination.Total) : 0;
    const n_start = total > 0 ? (((offset - 1) * size) + 1) : 0;
    const n_end = total > 0 ? ((offset * size) > total ? total : offset * size) : 0;

    return (
        <RestrictPage>
            <Content className="page-menus">
                <div className="panel-header">
                    <div className="header-title">
                        <i className="fa fa-home icon" aria-hidden="true"></i>
                        <span>Hệ thống</span>
                        <span className="icon-next"><i className="fa fa-angle-right"></i></span>
                        <span className="active">Quản lý email</span>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="panel-body">
                        <div className="row mr-b-10">
                            <div className="col-md-2 mr-b-10">
                                <RangePicker
                                    className="w-100"
                                    format="DD-MM-YYYY"
                                    disabledDate={disabledDate}
                                    value={[params.StartTime || moment().subtract(1, 'days'), params.EndTime || moment()]}
                                    onCalendarChange={(values) => fetchEmails({ StartTime: values ? values[0] : moment().subtract(1, 'days'), EndTime: values ? values[1] : moment() })}
                                />
                            </div>
                            <div className="col-md-4">
                                <Input.Search
                                    value={params.Query}
                                    onChange={(e) => setParams({ ...params, Query: e.target.value })}
                                    onSearch={(value) => fetchEmails({ Query: value })}
                                    placeholder="Tìm kiếm theo tiêu đề, nội dung, người nhận, tệp tin đính kèm"
                                    className="w-100"
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <label className="total-filter">
                                    <img src={Icons.common.filter} alt="Số phòng ban" />|&ensp;<span>{n_start} - {n_end}</span> trong tổng <span>{total}</span> email
                                </label>
                            </div>
                        </div>
                        <div className="row mr-t-10">
                            <div className="col-md-12">
                                <Table
                                    showHeader={false}
                                    loading={fetching}
                                    className="table-main tabel-email"
                                    columns={createColumns(columns, props)}
                                    rowKey="Id"
                                    size="small"
                                    dataSource={emails || []}
                                    pagination={false}
                                    bordered={false}
                                    scroll={{ x: 1400 }}
                                />
                            </div>
                        </div>
                        <div className="row mr-t-10">
                            <div className="col-md-6">
                                <span>Hiển thị &ensp;
                                    <Select value={size || 20} style={{ width: 120 }} onChange={(size) => fetchEmails({ Size: size, Offset: 1 })}>
                                        <Select.Option value="20">20</Select.Option>
                                        <Select.Option value="50">50</Select.Option>
                                        <Select.Option value="100">100</Select.Option>
                                        <Select.Option value="200">200</Select.Option>
                                    </Select>
                                    &nbsp; email / trang
                                </span>
                            </div>
                            <div className="col-md-6 text-right">
                                <Pagination current={offset || 1} pageSize={size || 20} total={total || 0} onChange={(offset) => fetchEmails({ Offset: offset })} showSizeChanger={false} />
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    title={'Chi tiết nội dung mail'}
                    visible={modalShown}
                    mask={true}
                    maskClosable={false}
                    width={'auto'}
                    style={{ maxWidth: 'calc(100vw - 180px)', top: 50 }}
                    footer={null}
                    className="modal-view-email"
                    onOk={() => { setModalShown(false) }}
                    onCancel={() => setModalShown(false)}
                >
                    {selectedEmail &&
                        (
                            <React.Fragment>
                                <div className="row">
                                    <div className="col-md-8">
                                        <div>Gửi tới: {selectedEmail.To.map((x, k) => <Tag key={k}>{x.Email}</Tag>)}</div>
                                    </div>
                                    <div className="col-md-4 text-right">
                                        <Button size="small" loading={resending} icon={<SendOutlined />} type="primary" onClick={() => {
                                            onResendEmail(
                                                {
                                                    EmailId: selectedEmail.Id,
                                                    EmailAddress: (selectedEmail.To || []).map(x => x.Email)
                                                }
                                            )
                                        }}>Gửi lại</Button>
                                    </div>
                                </div>
                                <br />
                                {selectedEmail.CC.length > 0 && <div>CC: {selectedEmail.CC.map(x => x.Email).join(', ')}</div>}
                                {selectedEmail.BCC.length > 0 && <div>BCC: {selectedEmail.BCC.map(x => x.Email).join(', ')}</div>}
                                <h4>Tiêu đề: {selectedEmail.Subject}</h4>
                                {selectedEmail.Message && selectedEmail.Message.trim() !== '' &&
                                    (<React.Fragment>
                                        <div><label className="bold">Nội dung:</label></div>
                                        <div className="email-content">
                                            {parse(selectedEmail.Message)}
                                        </div></React.Fragment>)}
                                {selectedEmail.AttachmentFiles.length > 0 &&
                                    (<FileComponentV email={selectedEmail} />)
                                }
                            </React.Fragment>
                        )
                    }
                </Modal>
            </Content>
        </RestrictPage>
    );
};

export default EmailManagementV

const columns = [
    { title: 'Tiêu đề', dataIndex: '', key: 'Subject', type: 'string', show: true, class: 'text-left' },
    { title: 'Người nhận', dataIndex: 'To', key: 'To', type: 'array', width: 420, show: true, class: 'text-left' },
    { title: 'Trạng thái', dataIndex: 'Status', key: 'Status', type: 'number', show: true, class: 'text-center', width: 70 },
    { title: 'Thời gian', dataIndex: 'CreatedDate', key: 'CreatedDate', type: 'datetime', show: true, width: 120, class: 'text-left' },
];

const createColumns = (columns = [], props) => {
    const { showDetails } = props;
    const _columns = [];
    if (columns && columns.length > 0) {
        columns.forEach((col) => {
            if (col.show) {
                switch (col.type) {
                    case 'array': {
                        const _col = {
                            ...col, ...{
                                dataIndex: '', render: (item) => {
                                    const email = item && item[col.dataIndex].map(x => <Tag style={{ marginBottom: '2px' }}>{x.Email}</Tag>);
                                    return <><span className="bold">Tới:</span>&ensp;{email}</>;
                                }
                            }
                        };
                        _columns.push(_col);
                        break;
                    }

                    case 'datetime': {
                        const _col = {
                            ...col, ...{
                                dataIndex: '', width: 180, render: (item) => {
                                    return <span><ClockCircleOutlined />&ensp;{moment(item[col.dataIndex]).format('DD/MM/YYYY | HH:mm:ss')}</span>
                                }
                            }
                        };
                        _columns.push(_col);
                        break;
                    }

                    case 'number': {
                        const _col = {
                            ...col, ...{
                                dataIndex: '', render: (item) => {
                                    return item[col.dataIndex] === 1
                                        ? <Tag color="green" style={{ borderRadius: '15px' }}>Đã gửi</Tag>
                                        : <Tag color="volcano" style={{ borderRadius: '15px' }}>Chưa gửi</Tag>
                                }
                            }
                        };
                        _columns.push(_col);
                        break;
                    }

                    case 'string': {
                        const _col = {
                            ...col, ...{
                                dataIndex: '', render: (item) => {
                                    return <div>
                                        <p className="mr-0">{item.Subject}</p>
                                        <div className="group-file">
                                            {item.AttachmentFiles && item.AttachmentFiles.map((file, key) => {
                                                if (key === 3) {
                                                    return <label key={key} className="icon-number" onClick={() => { showDetails(item) }}>+{item.AttachmentFiles.length - 3}</label>
                                                }
                                                if (key < 3) {
                                                    return (
                                                        file.ContentType.startsWith('image') ?
                                                            (<label key={key} className="icon-file">
                                                                <a href={`${apiEndpoint}/api/email-management/download-attachment?EmailId=${item.Id}&FileName=${file.FileName}`}>
                                                                    <img alt="" style={{ height: '20px' }} src={`${apiEndpoint}/api/email-management/download-attachment?EmailId=${item.Id}&FileName=${file.FileName}`} />
                                                                    <span>{file.FileName}</span>
                                                                </a>
                                                            </label>)
                                                            :
                                                            (<label key={key} className="icon-file">
                                                                <a href={`${apiEndpoint}/api/email-management/download-attachment?EmailId=${item.Id}&FileName=${file.FileName}`}>
                                                                    <img alt="" src={getIcon(file)} style={{ height: '20px' }} />
                                                                    <span>{file.FileName}</span>
                                                                </a>
                                                            </label>)
                                                    )
                                                }
                                                return null;
                                            })
                                            }
                                        </div>
                                    </div>
                                }
                            }
                        };
                        _columns.push(_col);
                        break;
                    }

                    default: {
                        _columns.push(col);
                        break;
                    }
                }

            } else {
                _columns.push(col);
            }
        });

        var functionColumn = {
            title: 'Chức năng', dataIndex: '', key: 'action', className: "text-center", align: 'center', width: 60, render: (item) => {
                return (
                    <React.Fragment>
                        <Button icon={<ReadOutlined />} size="small" title="Chi tiết" type="primary" onClick={() => { showDetails(item) }}></Button>&ensp;
                    </React.Fragment>
                )
            }
        };
        _columns.push(functionColumn);
    }
    return _columns;
}

const getIcon = (attachment) => {
    const ext = attachment.FileName.split('.')[attachment.FileName.split('.').length - 1];
    let icon = 'file.svg';
    switch (ext) {
        case 'xls':
        case 'xlsx': {
            icon = 'xls.svg';
            break;
        }
        case 'doc':
        case 'docx': {
            icon = 'doc.svg';
            break;
        }
        case 'pdf': {
            icon = 'pdf.svg';
            break;
        }
        case 'zi': {
            icon = 'zip.svg';
            break;
        }
        default: {
            break;
        }
    }
    return `/images/icons/file-types/${icon}`
}
