import { createAction, handleActions } from 'redux-actions';
// - Actions

export const assetTransfersRequest = createAction('ASSET_TRANSFER_LIST_REQUEST');
export const assetTransfersSuccess = createAction('ASSET_TRANSFER_LIST_SUCCESS');
export const deleteTransferRequest = createAction('ASSET_DEL_TRANSFER_REQUEST');

export const getAssetTransferRequest = createAction('ASSET_TRANSFER_REQUEST');
export const getAssetTransferSuccess = createAction('ASSET_TRANSFER_SUCCESS');

export const transferConfirmRequest = createAction('ASSET_TRANSFER_CONFIRM_REQUEST');
export const transferPrintRequest = createAction('ASSET_TRANSFER_PRINT_REQUEST');

export const failure = createAction('ASSET_ACTION_FAILURE');

// - Initial State
export const initialState = {
  transfers: [],
  transfersing: false,
  pagination: {
    Page: 1,
    Size: 50,
    Total: 0
  },

  transfer: null,
  transfering: false,

  error: null,
};

// - Reducers
const reducer = handleActions({
  
  [assetTransfersRequest]: (state) => ({
    ...state, transfersing: true,
  }),
  [assetTransfersSuccess]: (state, { payload: { transfers, pagination } }) => ({
    ...state, transfers, pagination, transfersing: false,
  }),

  [getAssetTransferRequest]: (state) => ({
    ...state, transfering: true,
  }),
  [getAssetTransferSuccess]: (state, { payload: { transfer } }) => ({
    ...state, transfer, transfering: false,
  }),

  [failure]: (state, action) => ({
    ...state,
    error: action.error,
    transfersing: false,
  }),
}, initialState);

export default reducer;
