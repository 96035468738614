import React, { useState, useEffect } from 'react'
import { message, Table, Button, Modal, Input, Checkbox, Popconfirm, Empty, Card, Tooltip } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { PlusOutlined, EyeOutlined, EditOutlined, DeleteOutlined, SendOutlined } from '@ant-design/icons'
import MDEditor from '@uiw/react-md-editor'
import moment from 'moment'
import Editor from '../../../../components/Editor'
import { getNotificationTemplateRequest, addNotificationTemplateRequest, updateNotificationTemplateRequest, removeNotificationTemplateRequest } from '../../../../reduxs/notification-template-redux/reducer'
import './style.scss'

export default ({ onSelect }) => {
    const dispatch = useDispatch()
    const [templates, setTemplates] = useState([])
    const [visible, setVisible] = useState(false)
    const [selected, setSelected] = useState(null)
    const [title, setTitle] = useState('')
    const [content, setMessage] = useState('')
    const [updating, setUpdating] = useState(false)
    const [removing, setRemoving] = useState({})
    const [isShared, setIsShared] = useState(false)
    const [viewItem, setViewItem] = useState(null)
    const currentUser = useSelector(store => store.oidc && store.oidc.user && store.oidc.user.profile)

    const fetchTemplates = () => {
        dispatch(getNotificationTemplateRequest({
            params: {},
            cb: (isOk, result) => {
                if (!isOk) {
                    message.error('Đã xảy ra lỗi')
                } else {
                    setTemplates(result)
                }
            }
        }))
    }

    useEffect(() => {
        fetchTemplates()
        // eslint-disable-next-line
    }, [])

    const clearForm = () => {
        setTitle('')
        setMessage('')
    }

    useEffect(() => {
        if (selected) {
            setIsShared(selected.IsShared)
            setMessage(selected.Message)
            setTitle(selected.Title)
        }
    }, [selected])

    const onUpdate = () => {
        setUpdating(true)
        if (selected) {
            dispatch(updateNotificationTemplateRequest({
                params: {
                    Id: selected.Id,
                    Title: title,
                    Message: content,
                    IsShared: isShared
                },
                cb: (isOk) => {
                    if (!isOk) {
                        message.error('Đã xảy ra lỗi')
                    } else {
                        setVisible(false)
                        setSelected(null)
                        clearForm()
                        fetchTemplates()
                    }
                }
            }))
        } else {
            dispatch(addNotificationTemplateRequest({
                params: {
                    Title: title,
                    Message: content,
                    IsShared: isShared
                },
                cb: (isOk) => {
                    setUpdating(false)
                    if (!isOk) {
                        message.error('Đã xảy ra lỗi')
                    } else {
                        setVisible(false)
                        clearForm()
                        fetchTemplates()
                    }
                }
            }))
        }
    }

    const onRemove = (t) => {
        setRemoving({ ...removing, [t.Id]: true })
        dispatch(removeNotificationTemplateRequest({
            params: {
                Id: t.Id
            },
            cb: (isOk) => {
                if (!isOk) {
                    setRemoving({ ...removing, [t.Id]: false })
                    message.error('Đã xảy ra lỗi')
                } else {
                    fetchTemplates();
                    if (viewItem && viewItem.Id === t.Id) {
                        setViewItem(null)
                    }
                }
            }
        }))
    }

    return (
        <>
            <div className='row'>
                <div className='col-md-6'>
                    <div className='text-right' style={{ marginBottom: 10 }}>
                        <Button icon={<PlusOutlined />} type='primary' onClick={() => { setSelected(null); setVisible(true) }}>Thêm mẫu</Button>
                    </div>
                    <Table
                        size='small'
                        dataSource={templates}
                        columns={[
                            { title: 'Tiêu đề', render: x => x.Title },
                            {
                                title: 'Người tạo', render: x => <>
                                    {x.CreatedBy.Name}<br />
                                    <span style={{ fontSize: 12, color: '#ababab' }}>{x.CreatedBy.UserCode}</span>
                                </>
                            },
                            {
                                title: 'Thời gian', width: 140, render: x => <>
                                    {moment(x.CreatedDate).format('YYYY-MM-DD HH:mm')}
                                    { x.UpdatedDate && <><br /><span style={{ fontSize: 12, color: '#ababab' }}>{moment(x.UpdatedDate).format('YYYY-MM-DD HH:mm')}</span></> }
                                </>
                            },
                            {
                                title: 'Thao tác', className: 'text-center', width: 140, render: x => {
                                    return (
                                        <span>
                                            <Popconfirm disabled={currentUser.staffCode !== x.CreatedBy.UserCode} okText="Chắc chắn" cancelText="Hủy" title="Bạn chắc chắn muốn xóa?" onConfirm={() => onRemove(x)}>
                                                <Button disabled={currentUser.staffCode !== x.CreatedBy.UserCode} loading={removing[x.Id]} size='small' icon={<DeleteOutlined />} style={{ marginRight: 4, marginBottom: 4 }} type='primary' danger></Button>
                                            </Popconfirm>
                                            <Button disabled={currentUser.staffCode !== x.CreatedBy.UserCode} size='small' icon={<EditOutlined />} onClick={() => { setSelected(x); setVisible(true) }} style={{ marginRight: 4, marginBottom: 4 }} type='primary'></Button>
                                            <Button size='small' icon={<EyeOutlined />} onClick={() => setViewItem(x)} style={{ marginRight: 4, marginBottom: 4 }}></Button>
                                            <Tooltip title="Sử dụng mẫu này">
                                                <Button onClick={() => onSelect && onSelect(x)} icon={<SendOutlined />} size='small'></Button>
                                            </Tooltip>
                                        </span>
                                    )
                                }
                            }
                        ]}
                        rowKey={x => x.Id}
                        bordered
                    />
                </div>

                <div className='col-md-6'>
                    {
                        viewItem ?
                            <>
                                <Card className='notification-template-card' size='small' title={viewItem.Title} style={{ width: '100%' }}>
                                    <MDEditor.Markdown className='notification-template-viewer' source={viewItem.Message} />
                                </Card>
                            </> :
                            <Empty description="Chọn mẫu để xem" />
                    }
                </div>
            </div>

            <Modal maskClosable={false} visible={visible} onCancel={() => { setVisible(false); setSelected(null); clearForm() }} footer={null} title={selected ? 'Cập nhật mẫu' : 'Tạo mẫu mới'}>
                <div className='row'>
                    <div className='col-md-12'>
                        <label className='bold'>Tiêu đề</label>
                        <Input autoFocus style={{ width: '100%' }} value={title} onChange={e => setTitle(e.target.value)} />
                    </div>

                    <div className='col-md-12' style={{ marginTop: 10 }}>
                        <label className='bold'>Nội dung thông báo</label>
                        <Editor codeview value={content} onChange={(v) => {
                            setMessage(v)
                        }} />
                    </div>

                    <div className='col-md-12' style={{ marginTop: 10 }}>
                        <Checkbox checked={isShared} onChange={e => setIsShared(e.target.checked)}>Chia sẻ mẫu này</Checkbox>
                    </div>

                    <div className='col-md-12 text-right' style={{ marginTop: 10 }}>
                        <Button onClick={() => {
                            setVisible(false)
                            clearForm()
                        }}>Hủy</Button>
                        &nbsp;
                        <Button disabled={!title || !content} onClick={onUpdate} loading={updating} type='primary'>{selected ? 'Cập nhật' : 'Thêm mới'}</Button>
                    </div>
                </div>
            </Modal>
        </>
    )
}