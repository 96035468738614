import React from 'react';
import { Button, Modal, Form, notification } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { compose as recompose, withHandlers, withProps} from 'recompose';
import { Field, reduxForm } from 'redux-form';
import { required  } from '../../../helpers/validate';
import { FieldInput, FieldSelect } from '../../../components/Fields';


import { PROVINCE_FORM_KEY, ProvinceFormAction } from '../../../reduxs/region-redux/action';

const ProvinceForm = (props) => {
  const { open, close, province, reset, error, handleSubmit,submitting } = props;
  const submit = handleSubmit(ProvinceFormAction);

  return (
    <Modal
      title={province ? 'Cập nhật tỉnh thành' : 'Thêm tỉnh thành'}
      visible={open}
      style={{ top: 20 }}
      width={'560px'}
      footer={null}
      maskClosable={false}
      onOk={() => { reset(); close(false) }}
      onCancel={() => { reset(); close(false) }}
      className="modal-form-region"
    >
      <Form layout="vertical" onFinish={submit}>
        <div className="row">
          <div className="col-md-5">
            <Field
              component={FieldSelect}
              label="Prefix"
              name="Prefix"
              placeholder=""
              options={[{value: 'Tỉnh', text: 'Tỉnh'}, {value: 'Thành phố', text: 'Thành phố'}]}
              className="required"
              validate={[required]}
            />
          </div>
          <div className="col-md-7">
            <Field
              component={FieldInput}
              label="Tên tỉnh thành"
              name="Name"
              placeholder="Nhập tên tỉnh thành"
              className="required"
              validate={[required]}
            />
          </div>
          <div className="col-md-5">
            <Field
              component={FieldInput}
              label="Code"
              name="Code"
              placeholder=""
              className="required"
              validate={[required]}
            />
          </div>
          <div className="col-md-7">
            <Field
              component={FieldInput}
              rows={3}
              label="Zip Code"
              name="ZipPostalCode"
              placeholder=""
              className="required"
              validate={[required]}
            />
          </div>
        </div>
        <p>Ghi chú:&ensp;<span className="note-red">Trường không được để trống</span></p>
        <Form.Item className="text-center">
            <Button type="primary" icon={<SaveOutlined />} htmlType="submit" loading={submitting}>Lưu lại</Button>
            <p className="text-center text-danger">{error}</p>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default recompose(
    withProps(({province}) => {
        if(province) {
          return { initialValues: { CountryId: 234, ...province }};
        } else {
          return { initialValues: { CountryId: 234, Prefix: 'Tỉnh' }};
        }
      }
    ),
    withHandlers({
    }),
    reduxForm({
      form: PROVINCE_FORM_KEY,
      enableReinitialize: true,
      onSubmitSuccess: (result, dispatch, props) => {
          notification.success({
              message: 'Lưu thành công!'
          });
          props.initialValues = null;
          props.reset();
          props.fetchProvinces();
          props.close(false);
      }
    })
)(ProvinceForm);
