import axios from '../utils/axiosInstance';

// Danh sách chức năng được phép
export const getMyFeatures = async () => {
    try {
        const { data } = await axios.get('/api/feature/mine');
        return data;
    } catch (error) {
        throw error;
    }
};