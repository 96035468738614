
import React, { useState, useEffect } from 'react';
import { Alert, Form, notification, Button } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { FieldTextArea, FieldSelect, FieldCheckBox, FieldDatePicker } from '../../../components/Fields'
import { compose as recompose, withHandlers, lifecycle, withProps, pure } from 'recompose';
import { reduxForm, Field } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import moment from 'moment';
import { required } from '../../../helpers/validate';
import { find } from 'lodash'
import { FORM_KEY, ZoneFormAction } from '../../../reduxs/account-redux/action';
import { selectZones, selectZoning } from '../../../reduxs/account-redux/selectors';
import { getZonesRequest } from '../../../reduxs/account-redux/reducer';
import { findStaffByStation } from '../../../reduxs/common-redux/reducer';

const ZoneForm = (props) => {
    const dispatch = useDispatch()
    const [optionsStaff, setStaffs] = useState([])
    const [searching, setSearching] = useState({})

    const { zones, error, pristine, submitting } = props;
    const { close, handleSubmit, zone } = props;

    const submit = handleSubmit(ZoneFormAction);
    const optionsZone = zones && zones.map((zone) => { return { value: zone.ZoneCode, text: zone.ZoneName, search: zone.ZoneName } })

    let timeoutSearch = null

    const findStaff = (_q, _t) => {
        setSearching({ ...searching, [_t]: true })
        dispatch(findStaffByStation({
            params: {
                Query: _q
            },
            cb: (isOK, staffSource) => {
                setSearching({ ...searching, [_t]: false })
                if (isOK) {
                    setStaffs(staffSource.map(staff => ({ search: `${staff.StaffCode} ${staff.StaffName}`, value: `${staff.StaffCode}`, text: `${staff.StaffCode} - ${staff.StaffName}` })))
                } else {
                    setStaffs([])
                }
            }
        }))
    }

    const onSearch = (q, t) => {
        clearTimeout(timeoutSearch)
        timeoutSearch = setTimeout(() => findStaff(q, t), 800)
    }

    useEffect(() => {
        findStaff()
        // eslint-disable-next-line
    }, [])

    const mapOption = (code, name) => {
        let _staffs = [...optionsStaff]
        if (zone && zone[code] && !find(_staffs, { StaffCode: zone[code] })) {
            _staffs.push({
                search: `${zone[code]} ${zone[name]}`,
                value: `${zone[code]}`,
                text: `${zone[code]} - ${zone[name]}`
            })
        }

        return _staffs
    }

    return (
        <Form onFinish={submit}>
            <div className="row form-plan">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-6">
                            <label className="w-100 bold mr-0">Bán hàng <span className="note-red" /></label>
                            <Field
                                component={FieldSelect}
                                name="SalesStaffCode"
                                options={mapOption('SalesStaffCode', 'SalesStaffName')}
                                placeholder="Chọn nhân viên bán hàng"
                                showSearch={true}
                                loading={searching.SalesStaffCode}
                                filterOption={false}
                                onSearch={value => {
                                    onSearch(value, 'SalesStaffCode')
                                }}
                                validate={[required]}
                                allowClear
                            />
                        </div>
                        <div className="col-md-6">
                            <label className="w-100 bold mr-0">Vùng <span className="note-red" /></label>
                            <Field
                                component={FieldSelect}
                                name="ZoneCode"
                                options={optionsZone}
                                placeholder="Chọn vùng"
                                showSearch={true}
                                optionFilterProp="children"
                                filterOption={(input, option) => {
                                    if (option.search) {
                                        return option.search.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                }}
                                validate={[required]}
                                allowClear
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6">
                            <label className="w-100 bold mr-0">Quản lý vùng <span className="note-red" /></label>
                            <Field
                                component={FieldSelect}
                                name="ZoneManagerStaffCode"
                                options={mapOption('ZoneManagerStaffCode', 'ZoneManagerName')}
                                placeholder="Chọn quản lý vùng"
                                showSearch={true}
                                loading={searching.ZoneManagerStaffCode}
                                filterOption={false}
                                onSearch={value => {
                                    onSearch(value, 'ZoneManagerStaffCode')
                                }}
                                validate={[required]}
                                allowClear
                            />
                        </div>
                        <div className="col-md-6">
                            <label className="w-100 bold mr-0">Chăm sóc khách hàng <span className="note-red" /></label>
                            <Field
                                component={FieldSelect}
                                name="CustomerCareStaffCode"
                                options={mapOption('CustomerCareStaffCode', 'CustomerCareName')}
                                placeholder="Chọn nhân viên chăm sóc"
                                showSearch={true}
                                loading={searching.CustomerCareStaffCode}
                                filterOption={false}
                                onSearch={value => {
                                    onSearch(value, 'CustomerCareStaffCode')
                                }}
                                allowClear
                                validate={[required]}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6">
                            <label className="w-100 bold mr-0">Vận hành</label>
                            <Field
                                component={FieldSelect}
                                name="OpStaffCode"
                                options={mapOption('OpStaffCode', 'OpName')}
                                placeholder="Chọn nhân viên vận hành"
                                showSearch={true}
                                loading={searching.OpStaffCode}
                                filterOption={false}
                                onSearch={value => {
                                    onSearch(value, 'OpStaffCode')
                                }}
                                validate={[]}
                                allowClear
                            />
                        </div>
                        <div className="col-md-6">
                            <label className="w-100 bold mr-0">Giao nhận</label>
                            <Field
                                component={FieldSelect}
                                name="PuStaffCode"
                                options={mapOption('PuStaffCode', 'PuName')}
                                placeholder="Chọn nhân viên giao nhận"
                                showSearch={true}
                                loading={searching.PuStaffCode}
                                filterOption={false}
                                onSearch={value => {
                                    onSearch(value, 'PuStaffCode')
                                }}
                                validate={[]}
                                allowClear
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className='col-md-6'>
                            <label className="w-100 bold mr-0">Kế toán</label>
                            <Field
                                component={FieldSelect}
                                name="AccStaffCode"
                                options={mapOption('AccStaffCode', 'AccName')}
                                placeholder="Chọn nhân viên kế toán"
                                showSearch={true}
                                loading={searching.AccStaffCode}
                                filterOption={false}
                                onSearch={value => {
                                    onSearch(value, 'AccStaffCode')
                                }}
                                validate={[]}
                                allowClear
                            />
                        </div>

                        <div className="col-md-6">
                            <label className="w-100 bold mr-0">Ngày có hiệu lực <span className="note-red" /></label>
                            <Field
                                component={FieldDatePicker}
                                name="EffectiveDate"
                                validate={[required]}
                                className="w-100"
                                placeholder="DD-MM-YYYY"
                            />
                        </div>
                    </div>

                    <div className='row'>
                        <div className="col-md-3">
                            <label className="w-100 bold mr-0">&emsp;</label>
                            <Field
                                component={FieldCheckBox}
                                text="Hoạt động"
                                name="IsActive"
                            />
                        </div>
                        <div className="col-md-3">
                            <label className="w-100 bold mr-0">&emsp;</label>
                            <Field
                                component={FieldCheckBox}
                                text="Hiệu lực"
                                name="IsInEffect"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <label className="w-100 bold mr-0">Ghi chú</label>
                            <Field
                                component={FieldTextArea}
                                name="Remark"
                                placeholder="Nhập nội dung kế hoạch."
                                rows={2}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row record-info">
                <p className="col-md-12">Ghi chú:&ensp;<span className="note-red">Trường không được để trống</span></p>
                <div className="col-md-12 text-right">
                    {error && <Alert message={error} type="error" showIcon className="text-left mr-b-10" />}
                    <Button onClick={() => close(true)}><i className="fa fa-ban" />&ensp;Hủy bỏ</Button>&emsp;
                    <Button type="primary" disabled={pristine} loading={submitting} htmlType="submit" icon={<SaveOutlined />}>Lưu</Button>
                </div>
            </div>
        </Form>
    )
}

function mapDispatchToProps(dispatch) {
    return {
        getZones: () => dispatch(getZonesRequest()),
    };
}

const mapStateToProps = createStructuredSelector({
    zones: selectZones(),
    zoning: selectZoning(),
    user: (state) => state.oidc && state.oidc.user,
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const timedefault = moment();

export default recompose(
    withConnect,
    withProps(
        ({ account, zone, user }) => {
            if (zone) {
                if (zone.EffectiveDate) {
                    zone.EffectiveDate = moment(zone.EffectiveDate);
                }
                return { initialValues: { ...zone, CustomerId: account.Id } };
            }
            return {
                initialValues: {
                    CustomerId: account.Id,
                    IsActive: true,
                    IsInEffect: true,
                    EffectiveDate: timedefault,
                    ZoneManagerStaffCode: user?.profile.staffCode,
                    CustomerCareStaffCode: user?.profile.staffCode
                }
            };
        }
    ),
    reduxForm({
        form: FORM_KEY,
        enableReinitialize: true,
        onSubmitSuccess: (result, dispatch, props) => {
            notification.success({ message: 'Lưu vùng khách hàng thành công!' });
            props.initialValues = null;
            props.reset();
            props.fetchAccounts();
            props.close();
        }
    }),
    withHandlers({
        goBack: ({ history }) => () => {
            history.goBack();
        },
    }),
    lifecycle({
        componentDidMount() {
            const { zones, getZones } = this.props;
            if (!(zones && zones.length)) {
                getZones();
            }
        }
    }),
    pure
)(ZoneForm);
