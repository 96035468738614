
import axios from '../utils/axiosInstance';

export const getStations = async (params) => {
  try {
    const { data } = await axios.get('/crm/station/my-station');
    return data;
  } catch (error) {
    throw error;
  }
};
