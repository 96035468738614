
import './style.scss';
import React from 'react';
import _ from 'lodash';
import { Layout, Table, Tabs, Spin, Button, Popconfirm, Switch, Select, Tooltip, Empty } from 'antd';
import { AppstoreAddOutlined, PlusOutlined, UndoOutlined, MenuUnfoldOutlined, PicLeftOutlined, UserAddOutlined, DeleteOutlined, UserOutlined, EditOutlined } from '@ant-design/icons';
import { arrayTree } from './../../helpers/common';
import MenuForm from './components/MenuForm';
import GroupForm from './components/GroupForm';
import GroupMenuForm from './components/GroupMenuForm';
import UserMenuForm from './components/UserMenuForm';
import RestrictPage from './../../components/RestrictComponents/RestrictPage';

const { Content} = Layout;
const { TabPane } = Tabs;

const menuParent = (menus, item, items) => {
    if(item.ParentId) {
        let menu =  menus.find(i => i.Id === item.ParentId);
        menu.private = true;
        if(menu) {
            items.push(menu)
            menuParent(menus, menu, items)
        }
    }
}


const MenuV = (props) => {
    const { show, hidden, staffCode, setStaffCode, fetchUserMenus, group, tabs, setTabs, menu, menus, groups, usermenus, clients, params, loading, fetchMenus, refreshMenu } = props;
    const { openMenu, isMobile, closeMenu, openGroup, closeGroup, onOpenGroup } = props;
    const { staffs, staffsing, getStaffs, usermenu, openUserMenu, closeUserMenu, editMenu } = props;
    const treeMenus = arrayTree(menus);
    let _usermenus = [];
    if(menus && usermenus && menus.length && usermenus.length) {
        usermenus.forEach(item => {
            menuParent(menus, item, _usermenus);
            _usermenus.push(item);
        });
    }
    _usermenus = _.uniqBy(_usermenus, 'Id');
    const usersData = arrayTree(_usermenus);

    let _staffs = [];
    if (staffs && staffs.length) {
      _staffs = staffs.map((item, key) => {
        return {
          value: item.StaffCode,
          title: `${item.StaffCode} - ${item.FullName}`,
          label: <div className="w-100"><UserOutlined />&ensp;{item.StaffCode} - {item.FullName}</div>
        }
      });
    }

    return (
        <RestrictPage>
            <Content className="page-menus">
                <div className="panel-header">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="header-title">
                                <i className="fa fa-home icon" aria-hidden="true"></i>
                                <span>Hệ thống</span>
                                <span className="icon-next"><i className="fa fa-angle-right"/></span>
                                <span className="active">Quản lý menu</span>
                            </div>
                        </div>
                        <div className="col-md-8 text-right">
                            <span className="text-left">
                                <span className="title-system bold"><i className="fa fa-building-o"/>Hệ thống</span>&ensp;
                                <Select
                                    value={params.ClientId}
                                    onChange={(value) => fetchMenus({ ClientId: value })}
                                    style={{maxWidth: '250px'}} className="w-100"
                                    showSearch={true}
                                    options={clients && clients.map((item) => ({value: item.Id, label: item.Name}))}
                                    filterOption={(input, option) => {
                                            if(option.label) {
                                                return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        }
                                    }
                                />
                                <span className="hidden-xs">&emsp;|&emsp;</span>
                                <div style={{display: 'inline-block'}} className="pull-right">
                                    <Tooltip title={!(menus && menus.length > 0) ? "Hệ thống chưa có menu": ""}>
                                        <Button disabled={!(menus && menus.length > 0)} onClick={()=> closeUserMenu(true)} type="primary" icon={<PlusOutlined />}>Thêm menu cho NV</Button>
                                    </Tooltip>
                                    &ensp;
                                    <Button onClick={refreshMenu} icon={<UndoOutlined />}>Làm mới</Button>
                                </div>
                            </span>

                        </div>
                    </div>
                </div>
                <div className={isMobile ? "col-md-12 pd-0" : "col-md-12"}>
                    <div className="panel-body">
                        <div className="import-table">
                            <div className="row">
                                <div className="col-md-6">
                                    <Tabs activeKey={tabs || '1'} onChange={(key) => setTabs(key)}
                                        tabBarExtraContent={
                                            <div>
                                                <Button onClick={() => editMenu()} disabled={!params.ClientId} size={'small'} icon={<PlusOutlined />}><span className="hidden-xs">Thêm menu</span></Button>&ensp;
                                                <Button onClick={() => onOpenGroup()} disabled={!params.ClientId} size={'small'} icon={<AppstoreAddOutlined />}><span className="hidden-xs">Thêm group</span></Button>
                                            </div>
                                        }
                                    >
                                        <TabPane tab={<><MenuUnfoldOutlined />{isMobile ? 'MENU': 'DANH SÁCH MENU'}</>} key="1">
                                            <Table
                                                loading={loading}
                                                bordered
                                                className="table-main"
                                                columns={reactColums(props)}
                                                rowKey='Id'
                                                size="small"
                                                dataSource={ treeMenus || []}
                                                pagination={false}
                                                scroll={{x: 600}}
                                                locale={{emptyText: <Empty description='Chưa có menu'/>}}
                                            />
                                        </TabPane>
                                        <TabPane tab={<><PicLeftOutlined />{isMobile ? 'GROUPS': 'DANH SÁCH GROUPS'}</>} key="2">
                                            <Table
                                                loading={loading}
                                                bordered
                                                className="table-main"
                                                columns={columsGroup(props)}
                                                rowKey='Id'
                                                size="small"
                                                dataSource={ groups || []}
                                                pagination={false}
                                                scroll={{x: 600}}
                                                locale={{emptyText: <Empty description='Chưa có group'/>}}
                                            />
                                        </TabPane>
                                    </Tabs>
                                </div>
                                <div className="col-md-6">
                                    <Tabs
                                        activeKey={'1'}
                                        tabBarExtraContent={
                                            <Select
                                                options={_staffs}
                                                allowClear
                                                showSearch
                                                onSearch={(value) => getStaffs({Limit: 25, Query: value})}
                                                notFoundContent={staffsing ? <Spin size="small" /> : null}
                                                placeholder="Tìm và chọn nhân viên"
                                                onChange={(value) => setStaffCode(value, () => fetchUserMenus(params))}
                                                className="w-100"
                                                style={{minWidth: '280px'}}
                                            />
                                        }
                                    >
                                        <TabPane tab={<><UserAddOutlined />{isMobile ? 'MENU NV': 'MENU NHÂN VIÊN'}</>} key="1">
                                            <Table
                                                loading={loading}
                                                bordered
                                                className="table-main"
                                                columns={columsUserMenus(props)}
                                                rowKey="Id"
                                                size="small"
                                                dataSource={usersData || []}
                                                pagination={false}
                                                scroll={{x: 600}}
                                                locale={{emptyText: <Empty description={staffCode ? `Mã nhân viên ${staffCode} chưa có menu`: 'Vui lòng nhập mã nhân viên để xem'}/>}}
                                            />
                                        </TabPane>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                { openMenu && <MenuForm open={openMenu} close={closeMenu} menus={menus} menu={menu} clients={clients} ClientId={params.ClientId} fetchMenus={fetchMenus} setTabs={() => setTabs('1')} />}
                { openGroup && <GroupForm open={openGroup} close={closeGroup} group={group} menus={menus} clients={clients} ClientId={params.ClientId} fetchMenus={fetchMenus} setTabs={() => setTabs('2')} />}
                { show && group && <GroupMenuForm open={show} close={hidden} group={group} menus={menus} treeMenus={treeMenus} ClientId={params.ClientId} fetchMenus={fetchMenus} /> }
                { openUserMenu && <UserMenuForm open={openUserMenu} close={closeUserMenu} usermenu={usermenu} menus={menus} groups={[...groups]} ClientId={params.ClientId} fetchMenus={fetchMenus} />}
            </Content>
        </RestrictPage>
    )
}
export default MenuV

const reactColums = ({editMenu, removeMenus}) => {
    const colums = [
        { title: 'Id', dataIndex: 'Id', key: 'Id', width: 100, className: "text-right col-id" },
        { title: 'Tên menu', dataIndex: 'Name', key: 'Name'},
        { title: 'Link Menu', dataIndex: 'Route', key: 'Route', },
        { title: 'Vị trí', dataIndex: 'Order', key: 'Order'},
        { title: 'Trạng thái', dataIndex: '', key: 'IsActive', className: "text-center", width: 120,  render: ({IsActive}) => {
            return <Switch size="small" checked={IsActive} />
        } },
        { title: 'Chú năng', dataIndex: '', key: 'action', className: "text-center", width: 110,  render: (item) => {
            return  <div>
                        <Button size="small" type="primary" onClick={() => editMenu(item)} icon={<EditOutlined />}/>&ensp;
                        <Popconfirm title="Bạn muốn xóa menu?" onConfirm={()=>removeMenus(item)} placement="topRight" okText="Xóa" cancelText="Không">
                            <Button size="small" type="primary" danger icon={<DeleteOutlined />}/>
                        </Popconfirm>
                    </div>
        }},
    ]
    return colums;
}

const columsGroup = ({onOpenGroup, onHidden}) => {
    const colums = [
        { title: 'Id', dataIndex: 'Id', key: 'Id', width: 60, className: "text-center" },
        { title: 'Nhóm menu', dataIndex: 'Name', key: 'Name'},
        { title: 'Trạng thái', dataIndex: '', key: 'IsActive', className: "text-center", width: 150,  render: ({IsActive}) => {
            return <Switch size="small" checked={IsActive} />
        }},
        { title: 'Chú năng', dataIndex: '', key: 'action', className: "text-center", width: 150,  render: (item) => {
            return <div style={{display: 'flex'}}>
                        <Button size="small" onClick={() => onOpenGroup(item)} icon={<EditOutlined/>}/>&ensp;
                        <Button size="small" type="primary" onClick={() => onHidden(item)}>Xem & cập nhập menu</Button>
                    </div>
        }},
    ]
    return colums;
}

const columsUserMenus = ({removeUserMenus}) => {
    const colums = [
        { title: 'Id', dataIndex: 'Id', key: 'Id', width: 100, className: "text-right" },
        { title: 'Tên menu', dataIndex: 'Name', key: 'Name'},
        { title: 'Link Menu', dataIndex: 'Route', key: 'Route', },
        { title: 'Vị trí', dataIndex: 'Order', key: 'Order'},
        { title: 'Chú năng', dataIndex: '', key: 'action', className: "text-center", width: 80,  render: (item) => {
            if(item.private) {
                return null;
            }
            return  <Popconfirm title="Bạn muốn xóa menu?" onConfirm={()=>removeUserMenus(item)} placement="topRight" okText="Xóa" cancelText="Không">
                        <Button size="small" type="primary" danger>Bỏ</Button>
                    </Popconfirm>
        }},
    ]
    return colums;
}
