import { lifecycle, compose as recompose, withHandlers, withState} from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pickBy, identity } from 'lodash';
import RequestHistoryV from './RequestHistoryV';
import { getRequestHistoriesRequest, getRequestHistoryGroupRequest }  from './../../reduxs/request-history-redux/reducer'

export function mapDispatchToProps(dispatch) {
  return {
      getHistories: (params) => dispatch(getRequestHistoriesRequest(params)),
      getHistoryGroupPath: (params) => dispatch(getRequestHistoryGroupRequest(params))
  };
}

const mapStateToProps = createStructuredSelector({
    fetching: store => store.requestHistory && store.requestHistory.fetching,
    histories: store => store.requestHistory && store.requestHistory.histories,
    pagination: store => store.requestHistory && store.requestHistory.pagination,
    fetchingGroup: store => store.requestHistory && store.requestHistory.fetchingGroup,
    historyGroup: store => store.requestHistory && store.requestHistory.historyGroupByPath
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default recompose(
  withConnect,
  withState('params', 'setParams', { Offset: 1, Size: 25 }),
  withState('filter', 'setFilter', {}),
  withHandlers({
      onFetchData: ({ params,  setParams, getHistories, filter }) => (param) => {
        let _params = { ...params, Offset: 1, ...param, ...filter };

        if (_params.Offset === undefined) _params.Offset = 1;
        if (_params.Size === undefined) _params.Size = 25;
  
        _params = pickBy(_params, identity);
        setParams(_params);
        getHistories({ ..._params});
      },
      onChangeParams: ({ setFilter, filter }) => (params = {}) => {
        let _params = {...filter, ...params};
        setFilter(_params);
      },
      onFetchDataGroup: ({ getHistoryGroupPath, filter }) => () => {
        const _payload = {
          StartTime: filter.StartTimeGroup,
          EndTime: filter.EndTimeGroup,
          ExecuteTime: filter.ExecuteTime,
          RequestPath: filter.RequestPathGroup
        }
        getHistoryGroupPath(_payload);
      }
  }),
  lifecycle({
    componentDidMount() {
        this.props.onFetchData();
        this.props.onFetchDataGroup();
    },
    componentDidUpdate(props) {
      
    }
  }),
)(RequestHistoryV);