import { lifecycle, compose as recompose, withState, withHandlers} from 'recompose';
import { connect } from 'react-redux';
import { notification } from 'antd';
import { createStructuredSelector } from 'reselect';

import { fetchPoliciesRequest, deletePolicyRequest } from '../../reduxs/policy-redux/reducer';
import { selectPolicies, selectPoliciesing, selectPagination } from '../../reduxs/policy-redux/selectors';

import { fetchClientsRequest } from '../../reduxs/menu-redux/reducer';
import { selectClients } from '../../reduxs/menu-redux/selectors';


import PolicyV from './PolicyV';

export function mapDispatchToProps(dispatch) {
  return {
    getPolicies: (params) => dispatch(fetchPoliciesRequest(params)),
    deletePolicy: (params) => dispatch(deletePolicyRequest(params)),
    getClients: (params) => dispatch(fetchClientsRequest(params)),
  };
}

const mapStateToProps = createStructuredSelector({
  policies: selectPolicies(),
  loading: selectPoliciesing(),
  pagination: selectPagination(),
  clients: selectClients(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default recompose(
  withConnect,
  withState('open', 'close', false),
  withState('policy', 'setPolicy', null),
  withHandlers({
    fetchPolicy: ({ getPolicies }) => () => {
      getPolicies()
    },
    onEditAuto: ({close, setPolicy}) => (policy) => {
      setPolicy(policy);
      close(true)
    }
  }),
  withHandlers({
    deletePolicy: ({ fetchPolicy, deletePolicy}) => (policy) => {
      if(policy && policy.Id) {
        const data = {
          Id: policy.Id
        }
        const callback = (type) => {
          if(type === 'error') {
            notification[type]({message: 'Xóa thất bại!'});
          } else {
            notification[type]({message: 'Xóa thành công!'});
          }
          fetchPolicy();
        }

        const _params ={
          data: data,
          cb: callback
        }
        deletePolicy(_params)
      }
    }
  }),
  lifecycle({
    componentDidMount() {
      this.props.fetchPolicy();
      this.props.getClients();
    }
  }),
)(PolicyV);