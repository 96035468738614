import React from 'react';
import { Button, Form, Modal, notification } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { compose as recompose, withProps} from 'recompose';
import { Field, reduxForm } from 'redux-form';
import { required } from '../../../../helpers/validate';
import { FieldInput } from '../../../../components/Fields';

import { FORM_CODE_KEY, CodeFormAction } from '../../../../reduxs/account-redux/action';

const FormPass = (props) => {
  const { open, close, code, handleSubmit, error, pristine, submitting } = props;
  const onSubmit = handleSubmit(CodeFormAction);


  return (
    <Modal
      title={code ? 'Cập nhật tên' : 'Tạo mới mã cha'}
      visible={open}
      style={{ top: 20 }}
      width={'460px'}
      footer={null}
      maskClosable={false}
      onOk={() => { close(false) }}
      onCancel={() => { close(false) }}
      className="modal-form"
    >
      <Form layout="vertical" onFinish={() => onSubmit()}>
        <div className="row">
          <div className="col-md-12">
            <Field
              component={FieldInput}
              label="Tên khách hàng"
              name="Name"
              placeholder="Nhập tên khách hàng"
              validate={[required]}
              className="required"
            />
          </div>
        </div>
        <p>Ghi chú:&ensp;<span className="note-red">Trường không được để trống</span></p>
        <Form.Item className="text-center">
            <Button type="primary" icon={<SaveOutlined/>} disabled={pristine} loading={submitting} htmlType="submit" style={{ marginLeft: 8 }}>Lưu lại</Button>
            <p className="text-danger">{error}</p>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default recompose(
    withProps(
      ({ code }) => {
        if(code) {
          return { initialValues: { Id: code.Id, Name: code.Name }};
        }
      }
    ),
    reduxForm({
      form: FORM_CODE_KEY,
      enableReinitialize: true,
      onSubmitSuccess: (result, dispatch, props) => {
          notification.success({
              message: 'Lưu lại thành công!'
          });
          props.initialValues = null;
          props.reset();
          props.resfresh();
          props.close(false);
      }
    })
)(FormPass);
