import { createAction, handleActions } from 'redux-actions';
// - Actions

export const getNotificationTemplateRequest = createAction('GET_NOTIFICATION_TEMPLATES_REQUEST');
export const addNotificationTemplateRequest = createAction('ADD_NOTIFICATION_TEMPLATES_REQUEST');
export const updateNotificationTemplateRequest = createAction('UPDATE_NOTIFICATION_TEMPLATES_REQUEST');
export const removeNotificationTemplateRequest = createAction('REMOVE_NOTIFICATION_TEMPLATES_REQUEST');

// - Initial State
export const initialState = {
};

// - Reducers
const reducer = handleActions({
}, initialState);

export default reducer;
