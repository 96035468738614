import React from 'react';
import { Button, Modal, Form, notification } from 'antd';
import { SaveOutlined, CloudUploadOutlined } from '@ant-design/icons';
import { compose as recompose, withHandlers, withProps, withState} from 'recompose';
import { Field, reduxForm } from 'redux-form';
import { required  } from '../../../helpers/validate';
import { FieldInput, FieldNumber, FieldSelect, FieldTreeSelect } from '../../../components/Fields';
import { MENU_FORM_KEY, MenuFormAction } from '../../../reduxs/menu-redux/action';
import { unflattenToTreeViewDataObject } from '../../../helpers/common';

const MenuForm = (props) => {
  const { open, close, menus, menu, image, clients, onChangeFile, reset, handleSubmit } = props;
  const submit = handleSubmit(MenuFormAction);

  let _menus = [];
  if(menus && menus.length) {
    _menus = unflattenToTreeViewDataObject(menus);
  }


  let _clients = [];
  if(clients && clients.length) {
    _clients = clients.map((item) => {
      return {
        value: item.Id,
        text: item.Name
      }
    })
  }

  return (
    <Modal
      title={menu ? 'Cập nhật menu': "Thêm menu mới"}
      visible={open}
      style={{ top: 20 }}
      width={'760px'}
      footer={null}
      maskClosable={false}
      onOk={() => { reset(); close(false) }}
      onCancel={() => { reset(); close(false) }}
      className="modal-form-menu"
    >
      <Form layout="vertical" onFinish={submit}>
        <div className="row">
          <div className="col-md-6">
            <Field
              component={FieldInput}
              label="Tên menu"
              name="Name"
              placeholder="Nhập tên menu"
              className="required"
              validate={[required]}
            />
          </div>
          <div className="col-md-6">
            <Field
              component={FieldInput}
              label="Route"
              name="Route"
              placeholder="Nhập đường dẫn"
              className="required"
              validate={[required]}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <Field
              component={FieldSelect}
              label="Hệ thống"
              name="ClientId"
              placeholder=""
              options={_clients}
              className="required"
              validate={[required]}
            />
          </div>
          <div className="col-md-6">
            <Field
              component={FieldTreeSelect}
              label="Menu cha"
              name="ParentId"
              placeholder=""
              options={_menus}
              fieldNames={{ label: 'title', value: 'key', children: 'children' }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <Field
              component={FieldInput}
              label="Font Icon"
              name="IconClass"
              placeholder=""
            />
          </div>
          <div className="col-md-2">
            <Field
              component={FieldNumber}
              label="Vị trí"
              min={0}
              name="Order"
            />
          </div>
          <div className="col-md-2">
            <div><label>Image Icon</label></div>
            <input accept=".svg,.jpg,.png" className="hidden" id="fileUpload" onChange={onChangeFile} type="file"/>
            <label className="ant-btn btn btn-upload w-100" htmlFor="fileUpload"><CloudUploadOutlined /> Tải icon</label>
          </div>
          <div className="col-md-2">
            <img src={image} height="45px" className="mr-t-20" alt=""/>
          </div>
        </div>
        <br/>
        <p>Ghi chú:&ensp;<span className="note-red">Trường không được để trống</span></p>
        <Form.Item className="text-center">
            <Button type="primary" icon={<SaveOutlined />} htmlType="submit">Lưu lại</Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default recompose(
    withProps(({menu, ClientId}) => {
        if(menu) {
          return { initialValues: { ...menu, ClientId: ClientId }};
        } else {
          return { initialValues: { ClientId: ClientId }};
        }
      }
    ),
    reduxForm({
      form: MENU_FORM_KEY,
      enableReinitialize: true,
      onSubmitSuccess: (result, dispatch, props) => {
          notification.success({
              message: 'Lưu thành công!'
          });
          props.initialValues = null;
          props.reset();
          props.close(false);
          props.fetchMenus();
          props.setTabs();
      }
    }),
    withState('image', 'setImage', null),
    withHandlers({
      onChangeFile: ({change, setImage}) => (e) => {
        const files = e.target.files;
        // if(!warehouser) {
        //   message.warning('Vui lòng chọn địa gửi hàng trước khi tải lên file excel');
        // }
        // if (warehouser && files && files[0]) {
        //   const body = {
        const file = files[0];
        var reader = new FileReader();
        reader.onloadend = function() {
          if(reader.result && reader.result.includes('data:image')) {
            console.log('RESULT', reader.result)
            setImage(reader.result);
            change('IconImageAsBase64', reader.result)
          }
        }
        reader.readAsDataURL(file);
            // WarehouseId: warehouser,
        //   }
        //   const params = {
        //     data: body,
        //     cb: (status, id) => {
        //         message[status](status === 'error' ? 'Tải danh sách đơn hàng lên không thành công!' : 'Tải danh sách đơn hàng lên thành công!');
        //         if (status !== 'error') {
        //           // fetchCustomers();
        //         }
        //         setFileId(id)
        //       }
        //   }
        //   importOrderFile(params);
        // }
        e.target.value = null;
      }
    }),
)(MenuForm);
