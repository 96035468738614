import React from 'react';
import { connect } from 'react-redux';
import { Modal, notification, Form, Table, Button } from 'antd';
import { SaveOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { lifecycle, compose as recompose, withHandlers, withState, withProps } from 'recompose';

import { districtsAreaRequest, wardsAreaRequest } from '../../../reduxs/area-redux/reducer';

import { FieldSelect } from '../../../components/Fields';
import { required  } from '../../../helpers/validate';

import { AREA_FORM_KEY, AreaFormAction } from '../../../reduxs/area-redux/action';


const columns = ({ onAddItems, onChangeProvince, onChangeWards, districts, wards }, _provinces) => {
  let _districts = []
  if(districts && districts.length) {
      _districts = districts.map((i) => {
          return {value: i.Id, text: i.Name}
      })
  }

  let _wards = []
  if(wards && wards.length) {
      _wards = wards.map((i) => {
          return {value: i.Id, text: i.Name}
      })
  }

  return [
    {
      title: 'STT',
      dataIndex: '',
      key: 'key',
      className:"text-center",
      width: 40,
      render: (item) => item+1
    },
    {
      title: 'Tỉnh thành',
      dataIndex: '',
      key: 'provice',
      width: 200,
      render: (item) => <Field component={FieldSelect} options={_provinces} name={`Items[${item}].ProvinceId`} onChange={(value) => onChangeProvince(value, item)} validate={[required]} className="w-100"/>
    },
    {
      title: 'Quận huyện',
      dataIndex: '',
      key: 'districts',
      width: 200,
      render: (item) => <Field component={FieldSelect} options={_districts}  name={`Items[${item}].DistrictId`} onChange={(value) => onChangeWards(value, item)} validate={[required]} className="w-100"/>
    },
    {
      title: 'Xã phường',
      dataIndex: '',
      key: 'ward',
      width: 200,
      render: (item) => <Field component={FieldSelect} options={_wards} name={`Items[${item}].WardId`} validate={[required]} className="w-100"/>
    },
    {
      title: '',
      dataIndex: '',
      key: 'action',
      width: 40,
      render: (item) => {
        if(item > 0) {
          return <Button size="small" type='danger' onClick={() => onAddItems(true, item)} icon={<DeleteOutlined/>}>Bỏ</Button>
        }
        return ''
      }
    },
  ];
}

const ProvinceForm = (props) => {
  const { open, Items, onAddItems, provinces, services, close, setCheckedProvinces, handleSubmit, error, submitting, pristine } = props;

  const submit = handleSubmit(AreaFormAction);

  let _provinces = []
  if(provinces && provinces.length) {
      _provinces = provinces.map((i) => {
          return {value: i.Id, text: i.Name}
      })
  }

  let _services = [];
  if(services && services.length) {
      _services = services.map((i) => {
          return {value: i.Code, text: i.Description}
      })
  }


  var packages = [];
  if(Items && Items.length) {
    for (var i = 0; i < Items.length; i++) {
      packages.push(i);
    }
  }

  return (
    <Modal
      title='Thêm tỉnh thành'
      visible={open}
      style={{ top: 20 }}
      width={'860px'}
      footer={null}
      maskClosable={false}
      onOk={() => { setCheckedProvinces([]); close(false) }}
      onCancel={() => { setCheckedProvinces([]); close(false) }}
      className="modal-form-privince"
    >
      <div className="row">
        <div className="col-md-12">
          <div className="panel-body">
            <Form layout="horizontal" onFinish={submit}>
              <div className="row">
                <div className="row">
                  <div className="col-xs-5">
                    <label>Dịch vụ</label>
                    <Field
                      component={FieldSelect}
                      options={_services}
                      name='TosCode'
                      validate={[required]}
                      className="w-100 required"
                    />
                  </div>
                  <div className="col-xs-5">
                    <label>Tỉnh thành gửi</label>
                    <Field
                      component={FieldSelect}
                      options={_provinces}
                      name='FromProvinceID'
                      validate={[required]}
                      className="w-100 required"
                    />
                  </div>
                  <div className="col-xs-2">
                    <label className="w-100">&emsp;</label>
                    <Button loading={submitting} disabled={pristine} className="pull-right" type="primary" onClick={() => submit()} icon={<SaveOutlined />}>Lưu lại</Button>
                  </div>
                  <p className="col-md-12 text-right text-danger">{error}</p>
                </div>
              </div>
              <div className="row">
                <label>Địa danh nhận</label>
                <Table
                  bordered
                  columns={columns(props, _provinces)}
                  dataSource={packages}
                  rowKey={(item) => item}
                  pagination={false}
                  size="small"
                  className="table-package"
                  footer={() => <div className="w-100 text-center"><Button size="small" type='primary' onClick={() =>onAddItems()} icon={<PlusOutlined/>}>Thêm</Button></div>}
                />
              </div>
            </Form>
          </div>
        </div>
      </div>

    </Modal>
  );
};

export function mapDispatchToProps(dispatch) {
  return {
      getDistricts: (params) => dispatch(districtsAreaRequest(params)),
      getWards: (params) => dispatch(wardsAreaRequest(params)),
  };
}
const selector = formValueSelector(AREA_FORM_KEY);

const mapStateToProps = createStructuredSelector({
  Items: (state) => selector(state, 'Items'),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default recompose(
  withConnect,
  withProps(() => {
    return { initialValues: { Type: 'DOMESTIC', Items: [{ ProvinceId: '', DistrictId: '', WardId: ''}] }};
  }),
  withState('values', 'setValues', {}),
  withState('loading', 'setLoading', false),
  withState('province', 'setProvince', null),
  withState('districts', 'setDistricts', []),
  withState('wards', 'setWards', []),
  withState('checkedProvinces', 'setCheckedProvinces', []),
  withState('selects', 'setSelects', ({provinces}) => provinces.map((i) => {
    return {
      key: i.Id,
      title: i.Name,
    }
  })),
  reduxForm({
    form: AREA_FORM_KEY,
    enableReinitialize: true,
    onSubmitSuccess: (result, dispatch, props) => {
        notification.success({
            message: 'Lưu thành công!'
        });
        props.initialValues = null;
        props.reset();
        props.close(false);
        props.fetchAreas();
    }
  }),
  withHandlers({
    onChangeProvince: ({ Items, change, setProvince, setDistricts, getDistricts }) => (id, index) => {
      let items = [...Items];
      if(items[index]) {
        items[index] = {...items[index], DistrictId: '', WardId: ''};
        change('Items', items)
      }
      getDistricts({
        data: {CityId: id},
        cb: (results) => {
          setProvince(id);
          setDistricts(results);
        }
      })
    },
    onChangeWards: ({ Items, change, province, setWards, getWards }) => (id, index) => {
      let items = [...Items];
      if(items[index]) {
        items[index] = {...items[index], WardId: ''};
        change('Items', items)
      }
      getWards({
        data: {CityId: province, DistrictId: id},
        cb: (results) => {
          setWards(results);
        }
      })
    },
    onAddItems: ({Items, change}) => (del, index) => {
      let items = [...Items];
      if(del) {
        items.splice(index, 1);
        change('Items', items)
      } else {
        change('Items', [...items, { ProvinceId: items[index]?.ProvinceId, DistrictId: items[index]?.DistrictId, WardId: ''}])
      }
    },
  }),
  lifecycle({
    componentDidMount() {
    },
  }),
)(ProvinceForm);
