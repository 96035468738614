import { createAction, handleActions } from 'redux-actions';
// - Actions
export const partnerListRequest = createAction('PARTNER_LIST_REQUEST');
export const partnerListSuccess = createAction('PARTNER_LIST_SUCCESS');

export const confirmPartnerRequest = createAction('CONFIRM_PARTNER_BILL_REQUEST');

export const getCommitmentsRequest = createAction('GET_COMMITMENTS_REQUEST');
export const getCommitmentsSuccess = createAction('GET_COMMITMENTS_SUCCESS');

export const failure = createAction('PARTNER_ACTION_FAILURE');

// - Initial State
export const initialState = {
  partners: [],
  pagination: {
    Page: 1,
    Size: 50,
    Total: 0
  },
  loading: false,

  commitments: [],
  commitmentsing: false,

  error: null,
};

// - Reducers
const reducer = handleActions({
  [partnerListRequest]: (state) => ({
    ...state,
    loading: true,
  }),

  [partnerListSuccess]: (state, { payload: { partners, pagination } }) => ({
    ...state,
    partners: partners,
    pagination: pagination,
    loading: false,
  }),

  [getCommitmentsRequest]: (state) => {
    return { ...state, commitmentsing: true, };
  },
  [getCommitmentsSuccess]: (state, { payload: { commitments } }) => {
    return { ...state, commitments: commitments, commitmentsing: false};
  },

  [failure]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error,
  }),
}, initialState);

export default reducer;
