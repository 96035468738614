/* @flow */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SketchPicker } from 'react-color';

import { Icons } from '../../../assets';
import './style.scss';

class ColorPic extends Component {
    constructor(props) {
        super(props)
        this.wrapperRef = React.createRef();
        this.btnWrapperRef = React.createRef();
    }

    state = {
        expanded: false
    }
    static propTypes = {
        onExpandEvent: PropTypes.func,
        onChange: PropTypes.func,
        currentState: PropTypes.object,
        show: PropTypes.func,
        hide: PropTypes.func
    };

    stopPropagation = (event) => {
        event.stopPropagation();
    };

    onChange = (color) => {
        const { onChange } = this.props;
        onChange('color', color.hex);
    }

    show = () => {
        this.setState({
            expanded: true
        })
    }

    hide = () => {
        this.setState({
            expanded: false
        })
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClick)
    }

    handleClick = (event) => {
        const { target } = event
        if (this.wrapperRef.current && !this.wrapperRef.current.contains(target) && !this.btnWrapperRef.current.contains(target)) {
            this.hide()
        }
    }
    
    componentWillUnmount() {
        document.removeEventListener('click', this.handleClick)
    }

    renderModal = () => {
        const { color } = this.props.currentState;
        return (
        <div
            ref={this.wrapperRef}
            className="demo-color-modal"
            onClick={this.stopPropagation}
        >
            <SketchPicker color={color} onChange={this.onChange} />
        </div>
        );
    };

    render() {
        return (
            <div
                className="demo-color-wrapper"
                aria-haspopup="true"
                aria-expanded={this.state.expanded}
                aria-label="rdw-color-picker"
            >
                <div
                ref={this.btnWrapperRef}
                onClick={this.show}
                className="demo-icon-wrapper"
                onBlur={this.hide}
                >
                <img
                    className="demo-icon"
                    src={Icons.common.colorPicker}
                    alt="Color picker"
                />
                </div>
                {this.state.expanded ? this.renderModal() : undefined}
            </div>
        );
    }
}

export default ColorPic;
