import React from 'react';
import { Modal, Empty } from 'antd';
import PricePHT from './PricePHT';
import PriceCPN from './PriceCPN';
import PricePTK from './PricePTK';
import PriceCDB from './PriceCDB';
import PriceECOD from './PriceECOD';
import PricePT12H from './PricePT12H';

const ViewPrices = (props) => {
  const { open, close, service, price } = props;
  const viewPrice = () => {
    switch (service) {
      case 'CPN':
        return <PriceCPN price={price}/>
      case 'ECOD':
        return <PriceECOD price={price}/>
      case 'SCOD':
        return <PriceECOD price={price}/>
      case 'PT12H':
        return <PricePT12H price={price}/>
      case 'PHT':
        return <PricePHT price={price}/>
      case 'CDB':
        return <PriceCDB price={price}/>
      case 'PTK':
        return <PricePTK price={price}/>
      default:
        return ''
    }
  }

  return (
    <Modal
      title="Chi tiết bảng giá"
      visible={open}
      style={{ top: 20 }}
      width={'1260px'}
      footer={null}
      maskClosable={false}
      onOk={() =>  close(false) }
      onCancel={() => close(false) }
      className="view-price-service"
    >
      <div className="row">
        <div className="col-md-12">
          {price
            ? viewPrice()
            : <Empty description="Không tìm thấy bảng giá"/>
          }
        </div>
      </div>
    </Modal>
  );
};

export default ViewPrices;
