import React from "react";
import { Form, Input } from "antd";

const FormItem = Form.Item;

const makeField = Component => ({ input, meta, children, hasFeedback, label, config, className, setFocus, ...rest }) => {
  const hasError = meta.touched && meta.invalid;

  const changeInput = (e) => {
    let value = `${e.target.value}`.replace(/\s/g,'').replace('-','').replace('.','');
    input.onChange(value)
  }

  return (
    <FormItem
      {...config}
      label={label}
      validateStatus={hasError ? "error" : "success"}
      hasFeedback={hasFeedback && hasError}
      help={hasError && meta.error}
      className={className || ''}
    >
      <Component value={input.value} name={input.name} {...rest} onChange={changeInput} ref={input => setFocus ? setFocus(input) : null}  />
    </FormItem>
  );
};

export default makeField(Input);