import { call, put, takeLatest } from 'redux-saga/effects';
import * as manageStationService from '../../services/manageStation-service';
import { StationManagementFormAction, StaffStationForm } from "./action";
import { SubmissionError } from "redux-form";

import {
  manageStationListSuccess,
  stationTypeListSuccess,
  employeesListSuccess,
  employeesInStationListSuccess,
  failure,
  stationAllSuccess,
} from './reducer';

// Danh sách bưu cục
export function* fetchManageStations({ payload }) {
  try {
    payload.iDisplayStart = (payload.Offset - 1) * payload.Size;
    payload.iDisplayLength = payload.Size;
    const { Result } = yield call(manageStationService.getListStations, payload);
    if (Result) {
      const { Data, Pagination } = Result;
      yield put(manageStationListSuccess({ manageStations: Data, pagination: Pagination }));
    } else {
      yield put(manageStationListSuccess({ manageStations: [], pagination: { Total: 0, Size: 0, Offset: 0 } }));
    }
  } catch (error) {
    yield put(failure(error));
  }
}

// Cập nhật bưu cục
export function* saveManageStation({ payload }) {
  try {
    let result = null;
    payload.ShortName = payload.FullName;
    if (payload.UpdateStation) {
      result = yield call(manageStationService.updateStation, payload);
    } else {
      result = yield call(manageStationService.addStation, payload);
    }
    if (result) {
      yield put(StationManagementFormAction.success(payload));
    } else {
      const { message } = "Lưu thất bại!";
      const formError = new SubmissionError({
        _error: message
      });
      yield put(StationManagementFormAction.failure(formError));
    }
  } catch (error) {
    const formError = new SubmissionError({
      _error: error
    });
    yield put(StationManagementFormAction.failure(formError));
  }
}

// Xóa bưu cục
export function* deleteManageStation({ payload }) {
  const { data, cb } = payload;
  try {
    const { Result } = yield call(manageStationService.deleteStation, data);
    if (Result) {
      cb('success')
    } else {
      cb('error')
    }
  } catch (error) {
    cb('error')
  }
}

// Xuất dữ liệu exel bưu cục
export function* exportExcel({ payload }) {
  const { data, cb } = payload;
  try {
    const Result = yield call(manageStationService.exportExcel, data);
    if (Result) {
      cb('success')
    } else {
      cb('error')
    }
  } catch (error) {
    cb('error')
  }
}

// Danh sách tất cả bưu cục
export function* fetchAllStations({ payload }) {
  try {
    const { Result } = yield call(manageStationService.getListStationAll, payload);
    if (Result) {
      yield put(stationAllSuccess({ stationAll: Result }));
    } else {
      yield put(failure('Tìm kiếm thất bại'));
    }
  } catch (error) {
    yield put(failure(error));
  }
}

// Danh sách loại bưu cục
export function* fetchStationType({ payload }) {
  try {
    const { Result } = yield call(manageStationService.getListStationType, payload);
    if (Result) {
      yield put(stationTypeListSuccess({ stationTypes: Result }));
    } else {
      yield put(failure('Tìm kiếm thất bại'));
    }
  } catch (error) {
    yield put(failure(error));
  }
}

// Danh sách nhân viên
export function* fetchEmployees({ payload }) {
  try {
    const { Result } = yield call(manageStationService.getListEmployees, payload);
    if (Result) {
      yield put(employeesListSuccess({ employees: Result }));
    } else {
      yield put(failure('Tìm kiếm thất bại'));
    }
  } catch (error) {
    yield put(failure(error));
  }
}

// Cập nhật nhân viên trong bưu cục
export function* saveEmployeeInStation({ payload }) {
  try {
    let { Result } = yield call(manageStationService.addEmployeeInStation, payload);
    if (Result) {
      yield put(StaffStationForm.success("Lưu thành công!"));
    } else {
      const message = "Cập nhập nhân viên cho bưu cục thất bại!";
      const formError = new SubmissionError({
        _error: message
      });
      yield put(StaffStationForm.failure(formError));
    }
  } catch (error) {
    const message = "Cập nhập nhân viên cho bưu cục thất bại!";
    const formError = new SubmissionError({
      _error: message
    });
    yield put(StaffStationForm.failure(formError));
  }
}

// Danh sách nhân viên trong bưu cục
export function* fetchEmployeesInStation({ payload }) {
  try {
    if(payload.station) {
      const { Result } = yield call(manageStationService.getListEmployeesInStation, payload);
      if (Result) {
        yield put(employeesInStationListSuccess({ employeesInStation: Result }));
      } else {
        yield put(employeesInStationListSuccess({ employeesInStation: [] }));
      }
    } else {
      yield put(employeesInStationListSuccess({ employeesInStation: [] }));
    }
  } catch (error) {
    yield put(employeesInStationListSuccess({ employeesInStation: [] }));
  }
}

export default [
  takeLatest('MANAGESTATION_LIST_REQUEST', fetchManageStations),
  takeLatest(StationManagementFormAction.REQUEST, saveManageStation),
  takeLatest(StaffStationForm.REQUEST, saveEmployeeInStation),
  takeLatest('EXPORT_EXCEL_REQUEST', exportExcel),
  takeLatest('DELETE_MANAGESTATION_REQUEST', deleteManageStation),
  takeLatest('STATIONTYPE_LIST_REQUEST', fetchStationType),
  takeLatest('STATION_ALL_REQUEST', fetchAllStations),
  takeLatest('EMPLOYEES_LIST_REQUEST', fetchEmployees),
  takeLatest('EMPLOYEESINSTATION_LIST_REQUEST', fetchEmployeesInStation)
];
