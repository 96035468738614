
import './style.scss';
import React from 'react';
import { Layout, Input, Checkbox, Radio, Select, Button } from 'antd';
import { Icons } from '../../assets';
import { Link } from 'react-router-dom';
import RestrictPage from './../../components/RestrictComponents/RestrictPage';
import { DownloadOutlined } from '@ant-design/icons';

const { Content} = Layout;
const { Option } = Select;

const PlaceV = (props) => {
    const { regions, provinces, districts, wards, searchDistrict, setSearchDistrict, searchProvince, setSearchProvince, searchWard, setSearchWard } = props;
    const { setCheckedWards, downloadLocation, downloading } = props;
    const { region, province, district, fetchProvinces, fetchDistricts, fetchWards } = props;

    let _provinces = provinces;
    if(searchProvince && provinces && provinces.length) {
        _provinces = provinces.filter((item) => ( item.Name && item.Name.toUpperCase().indexOf(searchProvince.toUpperCase()) !== -1) || ( item.Code && item.Code.indexOf(searchProvince) !== -1))
    }

    let _districts = districts
    if(districts && districts.length) {
        _districts = districts.filter((item) => item.RegionId === Number(region));
        if(searchDistrict) {
            _districts = _districts.filter((item) => ( item.Name && item.Name.toUpperCase().indexOf(searchDistrict.toUpperCase()) !== -1) || (item.Code && item.Code.indexOf(searchDistrict) !== -1))
        }
    }

    let valueWards = [];
    let _wards = wards;
    if(wards && wards.length) {
        _wards = wards.filter((item) => item.RegionId === Number(region));
        if(searchWard) {
            _wards = _wards.filter((item) => item.Name && item.Name.toUpperCase().indexOf(searchWard.toUpperCase()) !== -1)
        }
        valueWards = _wards.map((item) => item.Id);
    }

    return (
        <RestrictPage>
            <Content className="page-place">
                <div className="panel-header">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="header-title">
                                <i className="fa fa-home icon" aria-hidden="true"></i>
                                <span>Hệ thống</span>
                                <span className="icon-next"><i className="fa fa-angle-right"/></span>
                                <span className="active">Danh sách vùng miền/khu vực</span>
                            </div>
                        </div>
                        <div className="col-md-8 mr-t-5 text-right">
                            <span className="text-left">
                                <span className="title-country bold"><i className="fa fa-globe"/>Quốc gia</span>&emsp;
                                <Select defaultValue="vn" style={{ width: 250 }}>
                                    <Option value="vn">Việt Nam</Option>
                                </Select>
                                <span className="hidden-xs">&emsp;|&emsp;</span>
                                <div style={{display: 'inline-block'}} className="pull-right">
                                    <Button loading={downloading} onClick={() => downloadLocation()} icon={<DownloadOutlined style={{fontSize: '16px'}} />} className="ant-btn ant-btn-primary">Tải danh sách</Button>&nbsp;&nbsp;
                                    <Link to="/quan-ly-dia-danh" className="ant-btn ant-btn-primary"><i className="fa fa-plus"/>&ensp;Thêm phân vùng mới</Link>
                                </div>
                            </span>

                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-12 box-container">
                            <div className="col-md-3 pd-5">
                                <div className="panel-body">
                                    <div className="row panel-headers">
                                        <div className="col-xs-8">
                                            <span className="title">Khu vực</span>
                                            {/* <Input.Search
                                                defaultValue={ ''}
                                                placeholder="Tìm kiếm khu vực"
                                                onSearch={value => { }}
                                            /> */}
                                        </div>
                                    </div>
                                    <hr/>
                                    <Radio.Group onChange={(e)=> fetchProvinces(e.target.value)} value={Number(region)} className="list-region" id="scroll">
                                        {regions && regions.map((item, key) => {
                                            return <Radio key={key} value={item.Id} className="item-region region">{item.Name}<img src={Icons.common.marker} alt=""/></Radio>
                                        })}
                                    </Radio.Group>
                                </div>
                            </div>
                            <div className="col-md-3 pd-5">
                                <div className="panel-body">
                                    <div className="row panel-headers">
                                        <div className="col-xs-12 search">
                                            <span className="title">Tỉnh Thành</span>
                                            <Input.Search
                                                value={searchProvince}
                                                placeholder="Tìm tỉnh thành"
                                                onChange={e => setSearchProvince(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <hr/>
                                    <Radio.Group onChange={(e)=> fetchDistricts(e.target.value)} value={Number(province)} className="list-region" id="scroll">
                                        {_provinces && _provinces.map((item, key) => {
                                            return  <div key={key} className={Number(province) === item.Id ? 'item-region active' : 'item-region'}>
                                                        <Radio value={item.Id}>
                                                            {item.Prefix} {item.Name}
                                                            <small className="pull-right">Mã BC: {item.ZipPostalCode}</small>
                                                        </Radio>
                                                    </div>
                                        })}
                                    </Radio.Group>
                                </div>
                            </div>

                            <div className="col-md-3 pd-5">
                                <div className="panel-body">
                                    <div className="row panel-headers">
                                        <div className="col-xs-12 search">
                                            <span className="title">Quận Huyện</span>
                                            <Input.Search
                                                value={searchDistrict}
                                                placeholder="Tìm quận huyện"
                                                onChange={e => setSearchDistrict(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <hr/>
                                    <Radio.Group onChange={(e)=> fetchWards(e.target.value)} value={Number(district)} className="list-region" id="scroll">
                                        {_districts && _districts.map((item, key) => {
                                            if(item.ProvinceId === province) {
                                                return  <div  key={key} className={Number(district) === item.Id ? 'item-region active' : 'item-region'}>
                                                            <Radio value={item.Id}>
                                                                {item.Prefix} {item.Name}
                                                                <small className="pull-right">Mã code: {item.Code}</small>
                                                            </Radio>
                                                        </div>
                                            }
                                            return null;
                                        })}
                                    </Radio.Group>
                                </div>
                            </div>

                            <div className="col-md-3 pd-5">
                                <div className="panel-body">
                                    <div className="row panel-headers">
                                        <div className="col-xs-12 search">
                                            <span className="title">Xã Phường</span>
                                            <Input.Search
                                                value={searchWard}
                                                placeholder="Tìm xã phường"
                                                onChange={e => setSearchWard(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <hr/>
                                    <Checkbox.Group value={valueWards} onChange={(values)=> setCheckedWards(values)} className="list-region" id="scroll">
                                        {_wards && _wards.map((item, key) => {
                                            let values= []
                                            if(item.IsInnerCity) values.push('IsInnerCity');
                                            if(item.IsOutlyingArea) values.push('IsOutlyingArea');
                                            if(item.IsIndustrialArea) values.push('IsIndustrialArea');
                                            return <Checkbox key={key} value={item.Id} className="item-region">
                                                {item.Prefix} {item.Name}
                                                <Checkbox.Group value={values} onChange={(values)=> {}} className="group-children">
                                                    <Checkbox value={`IsInnerCity`} className="cb-children">Nội thành</Checkbox>
                                                    <Checkbox value={`IsOutlyingArea`} className="cb-children">Vùng sâu, vùng xa</Checkbox>
                                                    <Checkbox value={`Island`} className="cb-children">Hải đảo / biên giới</Checkbox>
                                                    <Checkbox value={`IsIndustrialArea`} className="cb-children">Khu công nghiệp</Checkbox>
                                                </Checkbox.Group>
                                                {item.PartnerCode && <Radio  checked={true}>Đơn vị phát: {item.PartnerCode}</Radio>}
                                            </Checkbox>
                                        })}
                                    </Checkbox.Group>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Content>
        </RestrictPage>
    )
}
export default PlaceV