
import axios from '../utils/axiosInstance';
import * as http from 'axios';
import store from "../reduxs/store";

export const updateServiceCache = async () => {
    try {
        const { data } = await axios.put('/admin/update-service-cache');
        return data;
    } catch (error) {
        throw error;
    }
};

export const updateService2Cache = async () => {
    try {
        const oidc = store.getState().oidc;
        const token = oidc && oidc.user && oidc.user.access_token;
        let config = {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }
        const { data } = await http.put('https://apiv2-dev.pcs.vn/admin/update-service-cache', null, config);
        return data;
    } catch (error) {
        throw error;
    }
};

export const updateServiceCacheIdentityServer = async () => {
    try {
        const { data } = await http.get('https://id.pcs.vn/api/update-cache');
        return data;
    } catch (error) {
        throw error;
    }
}

export const updateOpCacheServer = async () => {
    try {
        const oidc = store.getState().oidc;
        const token = oidc && oidc.user && oidc.user.access_token;
        let config = {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }
        const { data } = await http.put('https://api-op.pcs.vn/admin/update-service-cache', null, config);
        return data;
    } catch (error) {
        throw error;
    }
}

export const updateCrmCacheServer = async () => {
    try {
        const oidc = store.getState().oidc;
        const token = oidc && oidc.user && oidc.user.access_token;
        let config = {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }
        const { data } = await http.put('https://api-crm.pcs.vn/admin/update-service-cache', null, config);
        return data;
    } catch (error) {
        throw error;
    }
}