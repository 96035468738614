import { createSelector } from 'reselect';
import { initialState } from './reducer';
const stateArea = (state) => state.area || initialState;

const selectAreas = () => createSelector(
    stateArea,
    ({ areas }) => areas
);
const selectPagination = () => createSelector(
    stateArea,
    ({ pagination }) => pagination
);
const selectLoading = () => createSelector(
    stateArea,
    ({ loading }) => loading
);

export {
    stateArea,
    selectAreas,
    selectPagination,
    selectLoading,
};
