import '../style.scss';
import React from 'react';
import moment from 'moment';
import { Empty } from 'antd';

const Contact = (props) => {
  const { user } = props;
  const contacts = user?.Contacts;
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="box-group">
          <div className="box-header">
            <label className="nav-title bold">Thông tin địa chỉ liên hệ</label>
          </div>
          <div className="box-body">
            {
              contacts && contacts.length
              ?
              contacts.map((contact, key) => {
                return (
                  <>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-4">
                            <div className='item'>
                              <label className='item-title'>Tên liên hệ:</label> <span className='item-content'>{contact.FullName}</span>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className='item'>
                              <label className='item-title'>Giới tính:</label> <span className='item-content'>{contact.Gender ? 'Nam' : 'Nữ'}</span>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className='item'>
                              <label className='item-title'>Ngày sinh:</label> <span className='item-content'>{contact.DOB ? moment(contact.DOB).format('DD-MM-YYYY') : ''}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-4">
                            <div className='item'>
                              <label className='item-title'>Điện thoại:</label> <span className='item-content'>{renderPhones(contact.Phones)}</span>
                            </div>
                          </div>
                          <div className="col-md-8">
                            <div className='item'>
                              <label className='item-title'>Email:</label> <span className='item-content'>{renderEmails(contact.Emails)}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-12">
                            <div className='item'>
                              <label className='item-title'>Địa chỉ:</label> <span className='item-content'>{renderAddress(contact.Addresses)}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {key + 1 < contacts.length ?
                      <hr />
                      :
                      ''}
                  </>
                )
              })
              :
              <Empty description="Chưa có thông tin địa chỉ liên hệ"/>
            }
          </div>
        </div>
      </div>
    </div>
  )
}
export default Contact

const renderPhones = (phones) => {
  if (phones && phones.length > 0) {
    return phones.map((phone, key) => <span key={'phone_' + key}>{phone.PhoneNr}</span>)
  } else {
    return (<span>Chưa có số điện thoại</span>)
  }
}

const renderEmails = (emails) => {
  if (emails && emails.length > 0) {
    return emails.map((email, key) => <span key={'email_' + key} className="mr-b-5">{email.Email} {email.IsPrimary ? <span className="pull-right"><i className="fa fa-lock" /></span> : ''}</span>)
  } else {
    return (<span>Chưa có email</span>)
  }
}

const renderAddress = (addresses) => {
  if (addresses && addresses.length > 0) {
    return addresses.map((address, key) => <span key={'addresses_' + key} className="uppercase">
      {address.Street}
      {address.Ward ? ' - ' + address.Ward : ''}
      {address.District ? ' - ' + address.District : ''}
      {address.City ? ' - ' + address.City : ''}
    </span>)
  } else {
    return (<p>Chưa có địa chỉ</p>)
  }
}
