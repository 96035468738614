import { call, put, takeLatest } from 'redux-saga/effects';
import * as ErrorLogService from '../../services/error-log-service';


import {
    fetchLogsSuccess,
    sqlCommandLogsSuccess
} from './reducer';

export function* fetchErrorLogs({ payload = {} }) {
    try {
        const { Result: { Data, Pagination } } = yield call(ErrorLogService.getErrorLogs, payload);
        if (Data && Data.length) {
            yield put(fetchLogsSuccess({ logs: Data, pagination: Pagination }));
        } else {
            yield put(fetchLogsSuccess({ logs: [] }));
        }
    } catch (error) {
        yield put(fetchLogsSuccess({ logs: [] }));
    }
}

export function* fetchSqlCommandLogs({ payload = {} }) {
    try {
        const { Result: { Data, Pagination } } = yield call(ErrorLogService.getSqlCommandLogs, payload);
        yield put(sqlCommandLogsSuccess({ data: Data, pagination: Pagination }));
    } catch (error) {
        yield put(sqlCommandLogsSuccess({ data: [] }));
    }
}

export default [
    takeLatest('FETCH_LOGS_REQUEST', fetchErrorLogs),
    takeLatest('FETCH_SQL_COMMAND_LOGS_REQUEST', fetchSqlCommandLogs)
];
