
import './style.scss';
import React from 'react';
import { Layout, Table, Alert, Tree, Tabs, Tag, Checkbox, Select, Popconfirm, Button, Pagination, Spin, Empty, Dropdown, Menu, Switch, Avatar, Input } from 'antd';
import { SaveFilled, UsergroupAddOutlined, DownOutlined, DeleteOutlined, OrderedListOutlined, PlusOutlined, EditOutlined, CheckCircleOutlined, CloseCircleOutlined, UserOutlined } from '@ant-design/icons';
import { Icons } from '../../assets';
import _ from 'lodash';
import RestrictPage from './../../components/RestrictComponents/RestrictPage';

const { Content} = Layout;
const { TabPane } = Tabs;

const PartitionV = (props) => {
    const { wardsing, loading, treesing, staffsing, staffs, staff, partitions, setStation, regions, trees, stations, provinces, districts, wards } = props;
    const { fetchPartitions, fetchTrees, selectRows, params, pagination, tab, onChangeTab, isMobile, changWards } = props;
    const { station, region, province, district, ward, fetchProvinces, fetchDistricts, fetchWards, onPartitionStaff, onSavePartition } = props;

    const { onCheck, checkedKeys, onSelect, selectedKeys, keySearch, setKeySearch } = props;

    const size = params.Size && Number(params.Size) > 0 ? Number(params.Size) : 20;
    const offset = params.Offset && Number(params.Offset) > 0 ? Number(params.Offset) : 0;
    const total = pagination && pagination.Total && Number(pagination.Total) > 0 ? Number(pagination.Total) : 0;
    const n_start = total > 0 ? (((offset - 1) * size) + 1) : 0;
    const n_end = total > 0 ? ((offset * size) > total ? total : offset * size) : 0;

    let _staffs = [];
    let _staffsTwo = [];
    let _staffsOption = [];
    if(staffs && staffs.length > 0) {
      _staffs = staffs.filter((i) => i.DepartmentCode==='LX' || i.DepartmentCode==='PU');
      _staffsOption = [..._staffs].map((staff, key) => {
        return {
          key: key,
          value: staff.StaffCode,
          label: staff.StaffCode + ' - ' + staff.StaffName
        }
      })
      if(keySearch) {
        let _keyword = keySearch.toLowerCase()
        _staffsTwo = [...staffs].filter((staff) => staff.StaffCode.toLowerCase().indexOf(_keyword) >= 0 || staff.StaffName.toLowerCase().indexOf(_keyword) >= 0)
      } else {
        _staffsTwo = [...staffs]
      }
    }

    let _stations = [];
    if(stations && stations.length) {
        _stations = stations.map((station, key) => {
        return {
          key: key,
          value: station.STATION_CODE,
          label: station.STATION_CODE + ' - ' + station.NAME
        }
      });
    }

    let _regions = [];
    if(regions && regions.length) {
        _regions = regions.map((item, key) => {
        return {
          key: key,
          value: item.Id,
          label: item.Name
        }
      });
    }

    let _provinces = [];
    if(provinces && provinces.length) {
      _provinces = provinces.map((province, key) => {
        return {
          key: key,
          value: province.Id,
          label: province.Name
        }
      });
    }
  
    let _districts = [];
    if(districts && districts.length) {
      _districts = districts.map((item, key) => {
        return {
          key: key,
          value: item.Id,
          label: item.Name
        }
      });
    }

    let _wards = [];
    if(wards && wards.length) {
      _wards = wards.map((item, key) => {
        return {
          key: key,
          value: item.WardId,
          label: item.Name
        }
      });
    }

    const _trees = treeData([...trees], props);

    return (
      <RestrictPage>
        <Content className="page-partition">
            <div className="panel-header">
                <div className="row">
                    <div className="col-md-6">
                      <div className="header-title">
                          <i className="fa fa-home icon" aria-hidden="true"></i>
                          <span>Hệ thống</span>
                          <span className="icon-next"><i className="fa fa-angle-right"/></span>
                          <span className="active">Quản lý vùng phát</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row select-header">
                        <div className="col-xs-12 search pull-right" style={{maxWidth: '450px'}}>
                          <span className="title bold">Bưu cục:</span>
                          <Select
                            value={station || ''}
                            options={_stations}
                            onChange={(value) => { setStation(value); fetchPartitions({StationCode: value}); fetchTrees({StationCode: value})}}
                            showSearch
                            filterOption={(input, option) =>
                              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            className="w-100"
                          />
                        </div>
                      </div>
                    </div>
                </div>
            </div>
            <div className={isMobile ? "col-md-12 pd-0" : "col-md-12"}>
                <div className="row">
                    <div className="col-md-12">
                        <div className="panel-body">
                            <div className="row">
                              <div className="col-md-12">
                                <Tabs activeKey={tab} onChange={(key) => onChangeTab(key)} className="w-100">
                                  <TabPane tab={ <span className="bold"><OrderedListOutlined />Danh sách vùng phát</span>} key="1">
                                    <div className="row mr-b-15">
                                        <div className="col-md-3">
                                          <span className="title bold">Nhân viên</span>
                                          <Select
                                            allowClear
                                            value={params.StaffCode || ''}
                                            options={_staffsOption}
                                            onChange={(value) => fetchPartitions({StaffCode: value})}
                                            showSearch
                                            filterOption={(input, option) =>
                                              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            className="w-100"
                                          />
                                        </div>
                                        <div className="col-md-9">
                                          <div className="row">
                                            <div className="col-md-3">
                                              <span className="title bold">Khu vực</span>
                                              <Select
                                                value={region || ''}
                                                options={_regions}
                                                onChange={(value) => fetchProvinces(value)}
                                                showSearch
                                                filterOption={(input, option) =>
                                                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                className="w-100"
                                              />
                                            </div>
                                            <div className="col-md-3">
                                              <span className="title bold">Tỉnh Thành</span>
                                              <Select
                                                value={province || ''}
                                                options={_provinces}
                                                onChange={(value) => fetchDistricts(value)}
                                                showSearch
                                                filterOption={(input, option) =>
                                                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                className="w-100"
                                              />
                                            </div>
                                          
                                            <div className="col-md-3">
                                              <span className="title bold">Quận Huyện</span>
                                              <Select
                                                value={district || ''}
                                                options={_districts}
                                                onChange={(value) => fetchWards(value)}
                                                showSearch
                                                filterOption={(input, option) =>
                                                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                className="w-100"
                                              />
                                            </div>
                                            <div className="col-md-3">
                                              <span className="title bold">Xã phường</span>
                                              <Select
                                                value={ward || ''}
                                                options={_wards}
                                                onChange={(value) => changWards(value)}
                                                showSearch
                                                filterOption={(input, option) =>
                                                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                className="w-100"
                                              />
                                            </div>
                                            
                                          </div>
                                        </div>
                                    </div>
                                    <div className="row mr-b-10">
                                        <div className="col-md-6">
                                            <label className="total-filter">
                                              <img src={Icons.common.filter} alt="Vùng phát" />|&ensp;<span>{n_start} - {n_end}</span> trong tổng <span>{total}</span> vùng phát
                                            </label>
                                        </div>
                                        <div className="col-md-6 text-right">
                                          <Dropdown overlay={actionMenu(props)} disabled={!(selectRows.length > 0)}><Button type="primary" icon={<EditOutlined />}>Thao tác&ensp;<small>({selectRows.length})</small>&ensp;<DownOutlined /></Button></Dropdown>
                                        </div>
                                    </div>
                                    <Table
                                      loading={loading}
                                      bordered
                                      className="table-wards"
                                      rowKey="Id"
                                      columns={createColPartition(props)}
                                      dataSource={partitions}
                                      size="small"
                                      locale={{emptyText: <Empty description={''}>{station ? 'Bưu cục có vùng phát' : 'Vui lòng chọn bưu cục.'}</Empty>}}
                                      rowSelection={{selectedRowKeys: props.selectKeys, onChange: props.onSelectChange }}
                                      pagination={false}
                                      scroll={{x: 1400}}
                                    />
                                    <div className="row mr-t-10">
                                      <div className="col-md-6">
                                          <span>Hiển thị &ensp;
                                              <Select value={size || 20} style={{ width: 120 }} onChange={(size) => fetchPartitions({ Size: size, Offset: 1 })}>
                                                  <Select.Option value="20">20</Select.Option>
                                                  <Select.Option value="50">50</Select.Option>
                                                  <Select.Option value="100">100</Select.Option>
                                                  <Select.Option value="200">200</Select.Option>
                                              </Select>
                                              &nbsp; vùng phát / trang
                                          </span>
                                      </div>
                                      <div className="col-md-6 text-right">
                                          <Pagination current={offset || 1} pageSize={size || 20} total={total || 0} onChange={(offset) => fetchPartitions({ Offset: offset })} showSizeChanger={false} />
                                      </div>
                                  </div>
                                  </TabPane>
                                  <TabPane tab={ <span className="bold"><UsergroupAddOutlined />Phân vùng phát cho nhân viên</span>} key="2">
                                    <div className="row">
                                      <div className="col-md-7">
                                        {_staffs && _staffs.length > 0 && partitions && partitions.length > 0 &&
                                          (
                                            staff
                                            ? <Alert style={{ padding: '5px 40px'}} banner message={<span>Phân vùng cho nhân viên <span className="bold">{staff.StaffCode} - {staff.StaffName}</span></span>} type="info" showIcon />
                                            : <Alert style={{ padding: '5px 40px'}} banner message="Chọn nhân viên để phân vùng phát" type="warning" showIcon />
                                          )
                                        }
                                      </div>
                                      <div className="col-md-5 text-right">
                                        <Button type="primary" icon={<SaveFilled />} onClick={onPartitionStaff} disabled={!(staff && checkedKeys.length)}>Lưu vùng phát&ensp;<small>({selectRows.length} được chọn)</small></Button>
                                      </div>
                                    </div>
                                    <div className="row  mr-t-10">
                                      <div className="col-md-5 col-lg-4">
                                        <Table
                                          loading={staffsing}
                                          id="scroll"
                                          className="table-staff"
                                          rowKey="StaffCode"
                                          columns={[{
                                              title: (<div className="title-staff">
                                                        <Input.Search placeholder="Tìm kiếm..." onChange={(e) => setKeySearch(e.target.value)} className="w-100 mr-b-10"/>
                                                        <span className="bold">DANH SÁCH NHÂN VIÊN</span><span className="pull-right">Tổng <b>{_staffsTwo.length || 0}</b> nhân viên</span>
                                                      </div>
                                                    ),
                                              dataIndex: '',
                                              key: 'StaffName',
                                              render: (item) => {
                                                return <>{item.StaffCode} - {item.StaffName}<span className="pull-right">{item.DepartmentName}</span></>
                                              }
                                          }]}
                                          dataSource={_staffsTwo}
                                          size="small"
                                          locale={{emptyText: <Empty>Chưa có nhân viên PU.</Empty>}}
                                          rowSelection={{selectedRowKeys: props.selectStaffs, type: 'radio', onChange: props.onSelectStaffChange }}
                                          onRow={(record) => { return { onClick: () => props.onSelectStaffChange([record.StaffCode], [record])}}}
                                          pagination={false}
                                          scroll={{y: 'calc(100vh - 240px)'}}
                                        />
                                      </div>
                                      <div className="col-md-7 col-lg-8">
                                        <div className="row">
                                          <div className="col-md-12">
                                            <div className="tree-region" id="scroll">
                                              { treesing
                                                ? <div className="text-center" style={{padding: '50px'}}><Spin /></div>
                                                : (trees && trees.length > 0
                                                  ? <Tree checkable defaultExpandAll onCheck={onCheck} checkedKeys={checkedKeys} onSelect={onSelect} selectedKeys={selectedKeys} treeData={_trees} />
                                                  : <Empty description={<span>Bưu cục chưa có vùng phát</span>}/>
                                                )
                                              }
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </TabPane>
                                  <TabPane tab={ <span className="bold"><PlusOutlined />Thêm vùng phát</span>} key="3">
                                    <div className="row">
                                      <div className="col-md-3">
                                          <div className="row panel-headers">
                                              <div className="col-xs-12 search">
                                                  <span className="title bold">Khu vực</span>
                                                  <Select
                                                    value={region || ''}
                                                    options={_regions}
                                                    onChange={(value) => fetchProvinces(value)}
                                                    showSearch
                                                    filterOption={(input, option) =>
                                                      option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    className="w-100"
                                                  />
                                              </div>
                                          </div>
                                      </div>
                                      <div className="col-md-3">
                                            <div className="row panel-headers">
                                                <div className="col-xs-12 search">
                                                    <span className="title bold">Tỉnh Thành</span>
                                                    <Select
                                                      value={province || ''}
                                                      options={_provinces}
                                                      onChange={(value) => fetchDistricts(value)}
                                                      showSearch
                                                      filterOption={(input, option) =>
                                                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                      }
                                                      className="w-100"
                                                    />
                                                </div>
                                            </div>
                                      </div>
                                      
                                      <div className="col-md-3">
                                          <div className="row panel-headers">
                                              <div className="col-xs-12 search">
                                                  <span className="title bold">Quận Huyện</span>
                                                  <Select
                                                    value={district || ''}
                                                    options={_districts}
                                                    onChange={(value) => fetchWards(value)}
                                                    showSearch
                                                    filterOption={(input, option) =>
                                                      option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    className="w-100"
                                                  />
                                              </div>
                                          </div>
                                      </div>
                                      <div className="col-md-3 text-right">
                                        <Button type="primary" icon={<SaveFilled />} onClick={onSavePartition} disabled={!(selectRows.length > 0)}>Lưu lại vùng phát&ensp;<small>({selectRows.length} được chọn)</small></Button>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-md-12 mr-t-10">
                                        <Table
                                          loading={wardsing}
                                          className="table-wards"
                                          rowKey="RegionCode"
                                          columns={createColums(props)}
                                          dataSource={wards}
                                          size="small"
                                          locale={{emptyText: <Empty description={<div>Chưa có dữ liệu</div>}>Vui lòng chọn Khu vực/Tỉnh thành/Quận huyện.</Empty>}}
                                          rowSelection={{selectedRowKeys: props.selectKeys, onChange: props.onSelectChange }}
                                          pagination={false}
                                          scroll={{x: 1400}}
                                        />
                                      </div>
                                    </div>
                                  </TabPane>
                                </Tabs>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Content>
      </RestrictPage>
    )
}
export default PartitionV;

const actionMenu = ({selectKeys, togglePartition, deletePartition}) => {
  return (
    <Menu>
      <Menu.Item key="1" icon={<CheckCircleOutlined />} onClick={() => togglePartition(selectKeys, true)}>
        Kích hoạt vùng phát
      </Menu.Item>
      <Menu.Item key="2" icon={<CloseCircleOutlined />} onClick={() => togglePartition(selectKeys, false)}>
        Vô hiệu vùng phát
      </Menu.Item>
      <Menu.Item key="3" icon={<DeleteOutlined />} onClick={() => deletePartition()}>
        Loại bỏ vùng phát
      </Menu.Item>
    </Menu>
  );
}

const createColums = () => {
  const colums = [
      { title: 'ID', dataIndex: 'WardId', key: 'WardId', width: 100 },
      { title: 'Xã phường', dataIndex: '', key: 'Name', width: 250, render: (item) => {
          return `${item.Prefix} ${item.Name}`
      } },
      { title: 'Bưu cục', dataIndex: '', key: 'StationCode', render: (item) => {
        if(item.StationCode && item.StationCode.length) {
          return item.StationCode.map((item, key) => <Tag key={key} color="#108ee9" style={{borderRadius: '15px'}}>{item}</Tag>)
        }
        return <Tag color="volcano" style={{borderRadius: '15px'}}>Chưa được phân vùng</Tag>;
      } },
      { title: 'Thuộc vùng', dataIndex: '', key: 'CreatedDate', width: 250,  render: (item) => {
        return <>
                <Checkbox checked={item.IsInnerCity} className="mr-0 w-100">Nội thành</Checkbox>
                <Checkbox checked={item.IsOutlyingArea} className="mr-0 w-100">Vùng sâu, vùng xa</Checkbox>
              </>
      } }
  ]
  return colums;
}

const createColPartition = ({togglePartition}) => {
  const colums = [
      { title: 'Khu vực', dataIndex: '', key: 'RegionName', width: 120, render: (item) => {
          return item.RegionName
      } },
      { title: 'Bưu cục', dataIndex: '', key: 'StationCode', width: 90, render: ({StationCode, StationName}) => {
        return <Tag color="#108ee9" style={{borderRadius: '15px'}}>{StationCode} - {StationName}</Tag>;
      } },
      { title: 'Tỉnh thành', dataIndex: '', key: 'PrefixProvinceName', render: (item) => {
          return `${item.PrefixProvinceName} ${item.ProvinceName}`
      } },
      { title: 'Quận huyện', dataIndex: '', key: 'PrefixDistrictName', render: (item) => {
          return `${item.PrefixDistrictName} ${item.DistrictName}`
      } },
      { title: 'Xã phường', dataIndex: '', key: 'PrefixWardName', render: (item) => {
          return `${item.PrefixWardName} ${item.WardName}`
      } },
      { title: 'Thuộc vùng', dataIndex: '', key: 'CreatedDate', width: 170,  render: (item) => {
        return <>
                <Checkbox checked={item.IsInnerCity} className="mr-0 w-100">Nội thành</Checkbox>
                <Checkbox checked={item.IsOutlyingArea} className="mr-0 w-100">Vùng sâu, vùng xa</Checkbox>
                <Checkbox checked={item.IsIndustrialArea} className="mr-0 w-100">Khu công nghiệp</Checkbox>
              </>
      } },
      { title: 'Nhân viên phát', dataIndex: '', key: 'CreatedDate', width: 240,  render: (item) => {
        if(item.DeliveryStaffs && item.DeliveryStaffs.length) {
          return item.DeliveryStaffs.map((staff) => {
            return <div style={{marginBottom: '3px'}}><Avatar icon={<UserOutlined />} size="small"/>&ensp;{staff.StaffCode} - {staff.StaffName}</div>
          })
        }
        return <Tag color="volcano" style={{borderRadius: '15px'}}>Chưa có nhân viên phát</Tag>
      } },
      { title: 'Trạng thái', dataIndex: '', key: 'IsActive', className: "text-center", width: 100,  render: (item) => {
        return <Switch checkedChildren="Tắt" unCheckedChildren="Bật" defaultChecked checked={item.IsActive} onChange={(value) => togglePartition([item.RegionCode], value)} onClick={(item, e) => e.stopPropagation()} />
      } }
  ]
  return colums;
}


const treeData = (partitions, props) => {
  if(partitions && partitions.length) {
    let result = _.chain(partitions)
    .groupBy((item) => item.ProvinceId )
    .map(function(items, ProvinceId) {
      let province = items[0];
      
      return {
        title: `${province.PrefixProvinceName} ${province.ProvinceName}`,
        key: ProvinceId+'-'+province.DistrictId,
        className:"ls-province",
        checkable: false,
        children: treeChildren(items, props)
      };
    }).value();
    return result;
  } else {
    return []
  }
};

const treeChildren = (partitions, { deletePartitionStaff }) => {
  let num = 0
  let result = _.chain(partitions).groupBy((item) => item.DistrictId )
  .map(function(items, DistrictId) {
    let district = items[0];
    let className = num ? 'ls-district' : 'ls-district first'
    num++
    return {
      title: `${district.PrefixDistrictName} ${district.DistrictName}`,
      key: 'district-'+DistrictId+'-'+district.WardId,
      className,
      children: items.map((item, key) => {
        let staffs = item.DeliveryStaffs && item.DeliveryStaffs.length > 0;
        return {
          title: (<div className="ward-name">
                    <label>{item.PrefixWardName} {item.WardName}</label>
                    { staffs
                    ? item.DeliveryStaffs.map((staff, key1) => {
                      return (
                        <label key={key1}>
                          <Avatar icon={<UserOutlined />} size="small"/>&ensp;{staff.StaffCode} - {staff.StaffName}&ensp;
                          <Popconfirm placement="topRight" title={'Xóa nhân viên khỏ vùng phát này?'} onConfirm={() => deletePartitionStaff(staff, [item.Id])} okText="Xóa" cancelText="Thôi">
                            <DeleteOutlined className="i-delete" />
                          </Popconfirm>&ensp;
                        </label>
                      )
                    })
                    : <Tag color="volcano" style={{borderRadius: '15px'}}>Chưa có nhân viên</Tag>
                    }
                </div>),
          key: item.Id,
          className: key ? 'ls-ward' : 'ls-ward first',
          data: item,
        }
      })
    };
  }).value();
  return result;
};
