
import React from "react";
import { Upload, Button } from 'antd';
import { CloudUploadOutlined } from '@ant-design/icons';
import { Form } from "antd";

const FormItem = Form.Item;

class File extends React.Component {
  state = {
    fileList: []
  };
  // onUpload = (file) => {
  //   // console.log('==========', file);
  //   this.props.input.onChange(file);
  //   if (this.props.onFileChange) this.props.onFileChange(file);
  // }
  onChangeFile = ({file}) => {
    const { input: { onChange } } = this.props;
    if(file && file.response && file.response.url) {
      onChange(file.response.url);
    }
  };
  
  render() {
    const { fileList } = this.state;
    const { action } = this.props;
    const propsFile = {
      action: action,
      listType: 'picture',
      defaultFileList: [...fileList],
      className: 'upload-list-inline',
    };
    const { meta, hasFeedback, label, config } = this.props;
    const hasError = meta.touched && meta.invalid;
    // const itemLayout = {...formItemLayout, ...config};
    return (
      <FormItem
        {...config}
          label={label}
          validateStatus={hasError ? "error" : "success"}
          hasFeedback={hasFeedback && hasError}
          help={hasError && meta.error}
        >
        <Upload {...propsFile} onChange={this.onChangeFile}>
          <Button>
            <CloudUploadOutlined /> Chọn ảnh tải lên
          </Button>
        </Upload>
      </FormItem>
    );
  }
}

export default File;
