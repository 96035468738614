import { lifecycle, compose as recompose, withState, withHandlers} from 'recompose';
import { connect } from 'react-redux';
import { message, notification } from 'antd';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';
import { getRegionsRequest, getProvincesRequest, getProvincesSuccess, getDistrictsRequest, getDistrictsSuccess, getWardsRequest, getWardsSuccess, saveLocalRegionRequest } from '../../reduxs/region-redux/reducer';
import { selectRegions, selectProvinces, selectDistricts, selectWards  } from '../../reduxs/region-redux/selectors';
import { downloadLocationListRequest } from '../../reduxs/region-redux/reducer';

import PlaceV from './PlaceV';

import Auth from '../../storage/Auth';

export function mapDispatchToProps(dispatch) {
  return {
      getRegions: (params) => dispatch(getRegionsRequest(params)),
      getProvinces: (params) => dispatch(getProvincesRequest(params)),
      setProvinces: (params) => dispatch(getProvincesSuccess(params)),
      getDistricts: (params) => dispatch(getDistrictsRequest(params)),
      setDistricts: (params) => dispatch(getDistrictsSuccess(params)),
      getWards: (params) => dispatch(getWardsRequest(params)),
      setWards: (params) => dispatch(getWardsSuccess(params)),
      saveLocalRegion: (params) => dispatch(saveLocalRegionRequest(params)),
      downloadLocationList: (params) => dispatch(downloadLocationListRequest(params))
  };
}

const mapStateToProps = createStructuredSelector({
  regions: selectRegions(),
  provinces: selectProvinces(),
  districts: selectDistricts(),
  wards: selectWards(),
  downloading: (store) => store.region && store.region.downloading
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default recompose(
  withConnect,
  withState('monthFilter', 'setMonthFilter', moment()),
  withState('region', 'setRegion', null),
  withState('province', 'setProvince', null),
  withState('district', 'setDistrict', null),
  withState('openRegion', 'setOpenRegion', false),
  withState('openProvince', 'setOpenProvince', false),
  withState('openPlace', 'setOpenPlace', false),
  withState('checkedWards', 'setCheckedWards', []),
  withState('provinceOptions', 'setProvinceOptions', {}),
  withState('districtOptions', 'setDistrictOptions', {}),
  withState('wardOptions', 'setWardOptions', {}),
  withState('searchProvince', 'setSearchProvince', null),
  withState('searchDistrict', 'setSearchDistrict', null),
  withState('searchWard', 'setSearchWard', null),
  
  withHandlers({
    fetchRegions: ({ getRegions, setRegion, setProvinces, setDistricts, setWards }) => () => {
      getRegions();
      setRegion(null);
      setProvinces({provinces: []});
      setDistricts({districts: []});
      setWards({wards: []});
    },
    fetchProvinces: ({ setRegion, setProvince, setDistrict, getProvinces, setDistricts, setWards }) => (id) => {
      setRegion(id);
      setProvince(null);
      setDistrict(null);
      getProvinces({RegionId: id, StationCode: Auth.getStation() });
      setDistricts({districts: []});
      setWards({wards: []});
    },
    fetchDistricts: ({ setProvince, setDistrict, getDistricts, setWards, region }) => (id) => {
      setProvince(id);
      setDistrict(null);
      getDistricts({ProvinceId: id, City: id, RegionId: region, StationCode: Auth.getStation() });
      setWards({wards: []});
    },
    fetchWards: ({ setDistrict, getWards, region }) => (id) => {
      setDistrict(id);
      getWards({DistrictId: id, RegionId: region, StationCode: Auth.getStation() });
    },
    downloadLocation: ({downloadLocationList}) => () => {
      const params = {
        cb: (type) => {
          if(type === 'success') {
            message.success('Tải xuống thành công!')
          } else {
            message.error('Tải xuống không thành công!')
          }
        }
      }
      downloadLocationList(params);
    },
  }),
  withHandlers({
    onSelectWard: ({setCheckedWards}) => (items) => {
      setCheckedWards(items)
    },
    onProvinceOptions: ({provinceOptions, setProvinceOptions}) => (id, options) => {
      const data = {...provinceOptions};
      data[id] = options[0] || [];
      setProvinceOptions(data);
    },
    onDistrictOptions: ({districtOptions, setDistrictOptions}) => (id, options) => {
      const data = {...districtOptions};
      data[id] =  options[0] || [];
      setDistrictOptions(data);
    },
    onOptionsWards: ({wardOptions, setWardOptions}) => (id, options) => {
      const data = {...wardOptions};
      data[id] = options[0] || [];
      setWardOptions(data);
    },

    onSavePlace: ({ region, province, district, checkedWards, provinceOptions, districtOptions, wardOptions, saveLocalRegion }) => () => {
      if(checkedWards && checkedWards.length) {
        const wards = checkedWards.map((ward) => {
          return {
            Id: ward,
            IsInnerCity: wardOptions[ward] ? wardOptions[ward].includes('IsInnerCity') : false,
            IsOutlyingArea: wardOptions[ward] ? wardOptions[ward].includes('IsOutlyingArea') : false,
          }
        });

        const data = {
          RegionId: region,
          Province: [
            {
              Id: province,
              IsInnerCity: provinceOptions[province] ? provinceOptions[province].includes('IsInnerCity') : false,
              IsOutlyingArea: provinceOptions[province] ? provinceOptions[province].includes('IsOutlyingArea') : false,
            }
          ],
          District: [
            {
              ParentId: province,
              Childs: [
                {
                  Id: district,
                  IsInnerCity: districtOptions[district] ? districtOptions[district].includes('IsInnerCity') : false,
                  IsOutlyingArea: districtOptions[district] ? districtOptions[district].includes('IsOutlyingArea') : false,
                }
              ]
            }
          ],
          Ward: [
            {
              ParentId: district,
              Childs: wards
            }
          ]
        }

        const callback = (type) => {
          if(type === 'error') {
            notification[type]({message: 'Lưu thất bại!'});
          } else {
            notification[type]({message: 'Lưu thành công!'});
          }
        }

        const params ={
          data: data,
          cb: callback
        }

        saveLocalRegion(params)
      } else {
        message.warning('Xã phường chưa được chọn');
      }
    }
  }),
  lifecycle({
    componentDidMount() {
      const { fetchRegions } = this.props;
      fetchRegions();
    },
    componentDidUpdate(props) {
      
    }
  }),
)(PlaceV);