import axios from '../utils/axiosInstance';

export const fetchRequestHistories = async (params) => {
  try {
    const { data } = await axios.get('/api/request-history', { params: params });
    return data
  } catch (error) {
    throw error;
  }
};

export const fetchRequestHistoryGroupByPath = async (params) => {
  try {
    const { data } = await axios.get('/api/request-history/group-by-path', { params: params });
    return data
  } catch (error) {
    throw error;
  }
};