import React from "react";
import { Form, TreeSelect } from "antd";

const FormItem = Form.Item;


const makeField = () => ({ input, meta, children, hasFeedback, options, mode, label, ...rest }) => {
  const hasError = meta.touched && meta.invalid;
  if(mode && !input.value) input.value = [];
  return (
    <FormItem
      label={label}
      validateStatus={hasError ? "error" : "success"}
      hasFeedback={hasFeedback && hasError}
      help={hasError && meta.error}
    >
      <TreeSelect
         value={input.value}
         onChange={(value, label, extra) => {
          input.onChange && input.onChange(value)
         }}
         onFocus={input.onFocus}
        {...rest}
        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
        treeData={options}
      />
    </FormItem>
  );
};


export default makeField();