import { lifecycle, pure, compose as recompose, withHandlers, withState, withPropsOnChange } from 'recompose';
import { message, Modal, notification } from 'antd';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { scrollMoveTable } from '../../helpers/common';

import { selectStations } from '../../reduxs/station-redux/selectors';
import { selectCustomers, selectCustomersing, selectPagination, selectSteps, selectTypes } from '../../reduxs/account-redux/selectors';
import { getCustomersRequest, stepAccountRequest, getStepsRequest, getAccountTypeRequest, createUserByIdRequest, getAllowedVerifyStepsRequest, downloadAccountWaitConfirmRequest, resetPasswordToDefaultRequest, createAccountNumberRequest,addCustomerToNewStationRequest } from '../../reduxs/account-redux/reducer';
import { findStaffByStation } from '../../reduxs/common-redux/reducer';
import Auth from '../../storage/Auth';
import AccountsV from './AccountsV';
import moment from 'moment';
import { ACC_HELPER } from './Helper';

export function mapDispatchToProps(dispatch) {
  return {
    getCustomers: (params) => dispatch(getCustomersRequest(params)),
    confirmSteps: (params) => dispatch(stepAccountRequest(params)),
    getSteps: (params) => dispatch(getStepsRequest(params)),
    getTypes: (params) => dispatch(getAccountTypeRequest(params)),
    createUser: (params) => dispatch(createUserByIdRequest(params)),
    getAllowedSteps: () => dispatch(getAllowedVerifyStepsRequest()),
    downloadAccounts: (params) => dispatch(downloadAccountWaitConfirmRequest(params)),
    resetPassword: (params) => dispatch(resetPasswordToDefaultRequest(params)),
    findStaff: (params = {}) => dispatch(findStaffByStation(params)),
    createAccountNo: (params = {}) => dispatch(createAccountNumberRequest(params)),
    addCustomerToNewStation: (params = {}) => dispatch(addCustomerToNewStationRequest(params)),
  };
}

const mapStateToProps = createStructuredSelector({
  user: (state) => state.oidc && state.oidc.user && state.oidc.user.profile,
  customers: selectCustomers(),
  customersing: selectCustomersing(),
  pagination: selectPagination(),
  stations: selectStations(),
  steps: selectSteps(),
  types: selectTypes(),
  createUserSuccessed: (store) => store.account && store.account.createUserSuccessed,
  creatingUser: (store) => store.account && store.account.creatingUser,
  allowedVerifySteps: (store) => store.account && store.account.allowedVerifySteps,
  downloadingAWC: store => store.account && store.account.downloadingAWC,
  resetPwding: store => store.account && store.account.resetPwding,
  features: store => store.feature && store.feature.features
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

var accountMgmt_searchStaffTimeout = null

export default recompose(
  withConnect,
  withState('open', 'setOpen', false),
  withState('show', 'setShow', false),
  withState('modal', 'setModal', false),
  withState('openCode', 'setOpenCode', false),
  withState('openStation', 'setOpenStation', false),
  withState('openEmail', 'setOpenEmail', false),
  withState('showProfile', 'setShowProfile', false),
  withState('showDoc', 'setShowDoc', false),
  withState('params', 'setParams', { Offset: 1, Size: 50, StationCode: Auth.getStation() }),
  withState('account', 'setAccount', null),
  withState('tabs', 'setTabs', '2'),
  withState('rowKeys', 'setRowKeys', []),
  withState('rowRows', 'setSelectRows', []),
  withState('showConfirm', 'setShowConfirm', false),
  withState('createAccIfNotExists', 'setCreateAccIfNotExists', true),
  withState('selected', 'setSelected', null),
  withState('showZoneHistory', 'setShowZoneHistory', false),
  withState('newPassword', 'setNewPassword', null),
  withState('staffSource', 'setStaffSource', []),
  withState('verifySteps', 'setVerifySteps', []),
  withState('filterMode', 'setFilterMode', 'basic'),
  withState('hiddenColumns', 'setHiddenColumns', ['ReferrerCode', 'HasUserName', 'ProfileComplete', 'Source']),
  withState('allColumns', 'setAllColumns', []),
  withState('showOtherSetting', 'setShowOtherSetting', false),
  withPropsOnChange(['steps'], ({ steps, setVerifySteps }) => {
    setVerifySteps([{ value: -1, label: 'Chưa duyệt' }, ...steps.map(x => ({ value: x.Id, label: x.Name }))])
  }),
  withHandlers({
    scrollTable: () => () => {
      setTimeout(() => {
        scrollMoveTable('.ant-table-content');
      }, 1000);
    }
  }),
  withHandlers({
    onSearchStaff: ({ findStaff, setStaffSource }) => (q) => {
      clearTimeout(accountMgmt_searchStaffTimeout)
      accountMgmt_searchStaffTimeout = setTimeout(() => findStaff({
        params: {
          Query: q
        },
        cb: (isOk, data) => {
          if (isOk) {
            setStaffSource(data)
          } else {
            setStaffSource([])
          }
        }
      }), 800)
    }
  }),
  withHandlers({
    fetchAccounts: ({ params, setParams, setRowKeys, setSelectRows, getCustomers }) => (param) => {
      const _params = { ...params, Offset: 1, ...param }
      setParams(_params, () => {
        let request = { ..._params }
        if (request.CreatedDate && request.CreatedDate.length) {
          request['CreatedDate[0]'] = moment(request.CreatedDate[0]).format('YYYY-MM-DD')
          request['CreatedDate[1]'] = moment(request.CreatedDate[1]).format('YYYY-MM-DD')
          delete request.CreatedDate
        }
        getCustomers(request);
      });

      setRowKeys([]);
      setSelectRows([]);
    },
    onSelectChange: ({ setRowKeys, setSelectRows }) => (keys, rows) => {
      setRowKeys(keys);
      setSelectRows(rows);
    },
    downloadWAC: ({ tabs, params, downloadAccounts }) => (param = {}) => {
      const _params = { ...params, Offset: 1, ...param }
      if (tabs === '2') {
        _params.IsFinishAllSteps = false;
      } else if (tabs === '3') {
        _params.IsFinishAllSteps = false;
        _params.IsConfirmStep = true;
      }
      downloadAccounts({
        data: _params, cb: (_msg) => {
          if (_msg === 'success') {
            message.success('Tải xuống thành công');
          } else {
            message.error('Tải xuống không thành công');
          }
        }
      })
    },
    onResetPassword: ({ resetPassword, setNewPassword, newPassword }) => (AccountNo) => {
      resetPassword({
        data: {
          AccountNo: AccountNo,
          NewPassword: newPassword
        },
        cb: (_type) => {
          setNewPassword(null);
          if (_type === 'success') {
            message.success('Đặt lại mật khẩu thành công');
          } else {
            message.error('Đặt lại mật khẩu không thành công');
          }
        }
      });
    }
  }),
  withHandlers({
    confirmSteps: ({ fetchAccounts, confirmSteps, selected, setSelected, setShowConfirm, createAccIfNotExists }) => () => {
      const { CustomerId, StepId } = selected;
      if (CustomerId && StepId) {
        const data = {
          CustomerId,
          StepId,
          CreateUserNameIfNotExists: createAccIfNotExists
        }
        const callback = (type, message) => {
          if (type === 'error') {
            notification[type]({ message: message || 'Phê duyệt thất bại!' });
          } else {
            notification[type]({ message: 'Phê duyệt thành công!' });
          }

          setShowConfirm(false, () => { setSelected(null); fetchAccounts(); });
        }
        const _params = {
          data: data,
          cb: callback
        }
        confirmSteps(_params)
      }
    }
  }),
  withHandlers({
    onOpenZone: ({ setAccount, setOpen, setShow }) => (account) => {
      setShow(false)
      if (account) {
        setAccount(account, () => setOpen(true))
      } else {
        setAccount(null, () => setOpen(false))
      }
    },
    onOpenType: ({ setAccount, setOpen, setShow }) => (account) => {
      setOpen(false)
      if (account) {
        setAccount(account, () => setShow(true))
      } else {
        setAccount(null, () => setShow(false))
      }
    },

    onOpenSche: ({ setAccount, setModal }) => (account) => {
      if (account) {
        setAccount(account, () => setModal(true))
      } else {
        setAccount(null, () => setModal(false))
      }
    },

    onOpenStation: ({ setAccount, setOpenStation }) => (account) => {
      if (account) {
        setAccount(account, () => setOpenStation(true))
      } else {
        setAccount(null, () => setOpenStation(false))
      }
    },
    onOpenCode: ({ setAccount, setOpenCode }) => (account) => {
      if (account) {
        setAccount(account, () => setOpenCode(true))
      } else {
        setAccount(null, () => setOpenCode(false))
      }
    },
    onChangeCreateAcc: ({ setCreateAccIfNotExists }) => (e) => {
      setCreateAccIfNotExists(e.target.checked);
    },

    onOpenZoneHistory: ({ setShowZoneHistory, setAccount }) => (account) => {
      setShowZoneHistory(false)
      if (account) {
        setAccount(account, () => setShowZoneHistory(true))
      } else {
        setAccount(null, () => setShowZoneHistory(false))
      }
    },
    onOpenEmail: ({ setOpenEmail, setAccount }) => (account) => {
      if (account) {
        setAccount(account, () => setOpenEmail(true))
      } else {
        setAccount(null, () => setOpenEmail(false))
      }
    },
    onOpenProfile: ({ setShowProfile, setAccount }) => (account) => {
      if (account) {
        setAccount(account, () => setShowProfile(true))
      } else {
        setAccount(null, () => setShowProfile(false))
      }
    },

    onOpenDoc: ({ setShowDoc, setAccount }) => (account) => {
      if (account) {
        setAccount(account, () => setShowDoc(true))
      } else {
        setAccount(null, () => setShowDoc(false))
      }
    },

    onShowOtherSetting: ({ setAccount, setShowOtherSetting }) => (account) => {
      setAccount(account, () => {
        setShowOtherSetting(account ? true : false)
      })
    },

    onCreateAccounNumber: ({ createAccountNo, fetchAccounts }) => (account) => {
      createAccountNo({
        params: {
          CustomerId: account.Id
        },
        cb: (isOk, accountNo) => {
          if (isOk) {
            message.success('Mã khách hàng mới được tạo thành công là: ' + accountNo)
            fetchAccounts()
          } else {
            message.error('Đã xảy ra lỗi, vui lòng thử lại')
          }
        }
      })
    }

  }),
  withHandlers({
    onWarningStep: ({ setAccount, setOpen }) => (account) => {
      if (account) {
        Modal.warning({
          title: 'Chưa duyệt được tài khoản!',
          content: 'Vui lòng cập nhật vùng trước khi duyệt tài khoản',
          okText: 'Thêm',
          okCancel: true,
          cancelText: 'Đóng',
          onOk: () => {
            setTimeout(() => {
              setAccount(account, () => setOpen(true))
            }, 300);
          }
        });
      }
    }
  }),
  withHandlers({
    onSearch: ({ fetchAccounts }) => (cb) => {
      cb && cb()
      fetchAccounts()
    },
    onEnter: ({ fetchAccounts }) => (e) => {
      if (e.key === 'Enter') {
        fetchAccounts()
      }
    },
    onAddCustomerToNewStation: ({ addCustomerToNewStation }) => (AccountNo) => {
      if (AccountNo) {
        addCustomerToNewStation({
          params: {
            AccountNo: AccountNo
          },
          cb: (Result) => {
            if (Result) {
              message.success('Thêm thành công')
            } else {
              message.error('Thêm thất bại')
            }
          }
        });
      }
    }
  }),
  lifecycle({
    componentDidMount() {
      const { steps, getSteps, allowedVerifySteps, getAllowedSteps, location: { state }, types, getTypes, fetchAccounts, setAllColumns } = this.props;
      if (!(steps && steps.length)) {
        getSteps();
      }
      if (!(types && types.length)) {
        getTypes();
      }
      if (!(allowedVerifySteps && allowedVerifySteps.length)) {
        getAllowedSteps();
      }
      if (state && state.tab === 1) {
        fetchAccounts()
      } else if (state && state.login) {
        fetchAccounts({ HasUserName: 'false' })
      } else {
        fetchAccounts({ CurrentVerifyStep: -1 });
      }

      setAllColumns(ACC_HELPER.createColumnTable(this.props)
        .filter(x => x.toggleShow)
        .map(x => ({ value: x.key, label: x.title })))
    },
    componentWillUpdate(nextProps) {
      if ((this.props.creatingUser !== nextProps.creatingUser) && this.props.createUserSuccessed !== nextProps.createUserSuccessed && nextProps.createUserSuccessed) {
        this.props.fetchAccounts();
      }
    }
  }),
  pure,
)(AccountsV);