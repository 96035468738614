import { lifecycle, compose as recompose, withHandlers, withState} from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pickBy, identity } from 'lodash';
import WebhookV from './WebhookV';
import { getWebhookHistoriesRequest }  from './../../reduxs/webhook-redux/reducer'

export function mapDispatchToProps(dispatch) {
  return {
      getWebhookHistories: (params) => dispatch(getWebhookHistoriesRequest(params))
  };
}

const mapStateToProps = createStructuredSelector({
    fetching: store => store.webhook && store.webhook.fetching,
    histories: store => store.webhook && store.webhook.histories,
    pagination: store => store.webhook && store.webhook.pagination
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default recompose(
  withConnect,
  withState('params', 'setParams', { Offset: 1, Size: 25 }),
  withHandlers({
      onFetchData: ({ params,  setParams, getWebhookHistories }) => (param) => {
        let _params = { ...params, Offset: 1, ...param };

        if (_params.Offset === undefined) _params.Offset = 1;
        if (_params.Size === undefined) _params.Size = 25;
  
        _params = pickBy(_params, identity);
        setParams(_params);
        getWebhookHistories({ ..._params});
      }
  }),
  lifecycle({
    componentDidMount() {
        this.props.onFetchData();
    },
    componentDidUpdate(props) {
      
    }
  }),
)(WebhookV);