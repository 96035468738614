import React from 'react';
import { Button, Modal, Form, Spin, notification } from 'antd';
import { SaveOutlined, UserOutlined } from '@ant-design/icons';
import { compose as recompose, lifecycle, withHandlers, withProps} from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Field, reduxForm } from 'redux-form';
import { required  } from '../../../helpers/validate';
import { FieldSelect } from '../../../components/Fields';


import { USER_FORM_KEY, UserFormAction } from '../../../reduxs/role-redux/action';
import { getAllStationRequest } from '../../../reduxs/common-redux/reducer';
import { selectStationAlls } from '../../../reduxs/common-redux/selectors';

import { staffAllRequest } from '../../../reduxs/common-redux/reducer';
import { selectStaffAlls, selectStationAllsing } from '../../../reduxs/common-redux/selectors';
import { selectStation } from '../../../reduxs/station-redux/selectors';
// import { employeesListRequest } from '../../../reduxs/manageStation-redux/reducer';
// import { selectEmployee } from '../../../reduxs/manageStation-redux/selectors';

const RoleForm = (props) => {
  const { open, close, reset, stations, staffs, staffsing, getStaffs, handleSubmit, onChangeStation, submitting, pristine } = props;
  const submit = handleSubmit(UserFormAction);

  let _stations = [];
  if (stations && stations.length) {
    _stations = stations.map((item, key) => {
      return {
        value: item.STATION_CODE,
        title: item.STATION_CODE + " - " + item.NAME,
        text: item.STATION_CODE + " - " + item.NAME,
      }
    });
  }

  let _staffs = [];
  if (staffs && staffs.length) {
    _staffs = staffs.map((item, key) => {
      return {
        value: item.StaffCode,
        title: `${item.StaffCode} - ${item.FullName}`,
        text: <div className="w-100"><UserOutlined />&ensp;{item.StaffCode} - {item.FullName}</div>
      }
    });
  }

  return (
    <Modal
      title='Thêm người dùng mới'
      visible={open}
      style={{ top: 50 }}
      width={'460px'}
      footer={null}
      maskClosable={false}
      onOk={() => { reset(); close(false) }}
      onCancel={() => { reset(); close(false) }}
      className="modal-form-menu"
    >
      <Form layout="vertical" onFinish={submit}>
        <div className="row">
          <div className="col-md-12">
            <Field
              component={FieldSelect}
              label="Bưu cục"
              name="StationCode"
              onChange={(value) => onChangeStation(value)}
              options={_stations}
              showSearch
              filterOption={(input, option) =>option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              placeholder="Chọn bưu cục"
              className="required"
              validate={[required]}
            />
          </div>
          <div className="col-md-12">
            <Field
              component={FieldSelect}
              mode="multiple"
              label="Nhân viên dùng"
              name="UserCode"
              options={_staffs}
              showSearch
              onSearch={(value) => getStaffs({Limit: 25, Query: value})}
              notFoundContent={staffsing ? <Spin size="small" /> : null}
              placeholder="Tìm và chọn nhân viên"
              className="required"
              validate={[required]}
            />
          </div>
        </div>
        <br/>
        <Form.Item className="text-center">
            <Button loading={submitting} disabled={pristine} type="primary" icon={<SaveOutlined />} htmlType="submit">Lưu</Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    getStations: (params) => dispatch(getAllStationRequest(params)),
    getStaffs: (params) => dispatch(staffAllRequest(params)),
  };
}

const mapStateToProps = createStructuredSelector({
  station: selectStation(),
  stations: selectStationAlls(),
  staffs: selectStaffAlls(),
  staffsing: selectStationAllsing(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default recompose(
  withConnect,
  withProps(({RoleId, ClientId, station}) => {
      return { initialValues: { RoleId, ClientId, StationCode: station.STATION_CODE || '' }};
    }
  ),
  reduxForm({
    form: USER_FORM_KEY,
    enableReinitialize: true,
    onSubmitSuccess: (result, dispatch, props) => {
        notification.success({
            message: 'Lưu thành công!'
        });
        props.initialValues = null;
        props.reset();
        props.close(false);
        props.fetchRoleUsers();
    }
  }),
  withHandlers({
    onChangeStation: ({change, getStaffs}) => (station) => {
      change('UserCode', '');
      getStaffs({station: station});
    }
  }),
  lifecycle({
    componentDidMount() {
      const { stations, station, getStations, getStaffs} = this.props;
      if(!(stations && stations.length)) {
        getStations();
      }
      if(station && station.STATION_CODE) {
        getStaffs({Limit: 25});
      }
    }
  }),
)(RoleForm);
