import '../style.scss';
import React from 'react';
import { Empty, Table } from 'antd';
const WareHouse = (props) => {
  const { wareHouses } = props;
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="box-group">
          <div className="box-header">
            <label className="nav-title bold">Thông tin về nơi nhận hàng hóa gửi</label>
          </div>
          <div className="box-body pd-0">
            {
              wareHouses && wareHouses.length
              ?
              <Table
                className="table-complain table-warehouse"
                columns={createColums(props)}
                rowKey='Id'
                size="small"
                showHeader={false}
                dataSource={wareHouses || []}
                pagination={false}
                bordered={true}
              />
              : <Empty description="Chưa có thông tin về nơi nhận hàng hóa gửi"/>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

const createColums = () => {
  const colums = [
      { title: 'STT', dataIndex: '', key: 'STT', width: 30, className: "text-center", render: (text, item, key) => { return key + 1 } },
      {
          title: 'Người liên hệ', dataIndex: '', key: 'CardOwner', width: '40%', render: (item) => {
              return <div>
                  <label className="name-owner w-100 bold">{item.ContactPerson}</label>
                  <span><i class="fa fa-phone-square" />&ensp;{item.ContactPhoneNr}</span>&emsp;
                  {item.Email && <span><i className="fa fa-envelope" />&ensp;{item.Email}</span>}
              </div>
          }
      },
      {
          title: 'Thông tin địa chỉ', dataIndex: '', key: 'Address', width: '60%', render: (item) => {
              return <div>
                  <label className="bank-name w-100">{item.Address || ''}, {item.WardName || ''}, {item.DistrictName || ''}, {item.CityName || ''}</label>
              </div>
          }
      }
  ]
  return colums;
}

export default WareHouse
