
import axios from '../utils/axiosInstance';

export const getMyPrices = async (params) => {
  try {
    const { data } = await axios.get(`/api/price-list/by-service-code`, {
      params: params
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getDetailPrice = async params => {
  try {
    const { data } = await axios.get('/api/price-list/by-id', {params})
    return data;
  } catch (error) {
    throw error;
  }
};
