import { lifecycle, compose as recompose, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { updateServiceCacheRequest, updateIdentityServiceCacheRequest, updateServiceCacheOPRequest, updateServiceCacheCRMRequest, updateService2CacheRequest } from '../../reduxs/cache-redux/reducer';
import CacheV from './CacheV';

export function mapDispatchToProps(dispatch) {
    return {
        updateServiceCacheRequest: () => dispatch(updateServiceCacheRequest()),
        updateIdentityServiceCacheRequest: () => dispatch(updateIdentityServiceCacheRequest()),
        updateServiceCacheOPRequest: () => dispatch(updateServiceCacheOPRequest()),
        updateServiceCacheCRMRequest: () => dispatch(updateServiceCacheCRMRequest()),
        updateApiv2DevCacheRequest: () => dispatch(updateService2CacheRequest()),
    };
}

const mapStateToProps = createStructuredSelector({
    loading: (store) => store.cache && store.cache.fetching,
    clearingIdentityServerCache: (store) => store.cache && store.cache.clearingIdentityServerCache,
    clearingOpCache: (store) => store.cache && store.cache.clearingOpCache,
    clearingCrmCache: (store) => store.cache && store.cache.clearingCrmCache,
    clearingCache2: (store) => store.cache && store.cache.fetching2,
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default recompose(
    withConnect,
    withHandlers({
        updateServiceCache: ({ updateServiceCacheRequest }) => () => updateServiceCacheRequest(),
        updateIdentityServiceCache: ({ updateIdentityServiceCacheRequest }) => () => updateIdentityServiceCacheRequest(),
        updateOpCache: ({ updateServiceCacheOPRequest }) => () => updateServiceCacheOPRequest(),
        updateCrmCache: ({ updateServiceCacheCRMRequest }) => () => updateServiceCacheCRMRequest(),
        updateApiv2DevCache: ({ updateApiv2DevCacheRequest }) => () => updateApiv2DevCacheRequest()
    }),
    lifecycle({
        componentDidMount() {
        }
    })
)(CacheV);