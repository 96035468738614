import { lifecycle, compose as recompose, withState, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import _ from 'lodash';
import StaffsV from './StaffsV';
import { fetchStaffsRequest, unlockStaffRequest, lockStaffRequest } from '../../reduxs/staff-redux/reducer';
import { selectStaffs, selectStaffsing, selectPagination } from '../../reduxs/staff-redux/selectors';
import { message } from 'antd';

const columns = [
    { name: 'Mã nhân viên', id: 'StaffCode', type: 'string', show: true, width: 120 },
    { name: 'Tên đăng nhập', id: 'UserName', type: 'string', show: true, width: 120 },
    { name: 'Tên nhân viên', id: 'FullName', type: 'string', show: true, width: 150 },
    { name: 'Giới tính', id: 'Sex', type: 'gender', show: true, width: 80 },
    { name: 'Ngày sinh', id: 'Birthday', type: 'date', show: true, width: 150 },
    { name: 'Số điện thoại', id: 'Tel', type: 'string', show: true, width: 120 },
    { name: 'Email', id: 'Email', type: 'string', show: true, width: 150 },
    { name: 'Thời gian tạo hồ sơ', id: 'AccountCreatedTime', date: 'AccountCreatedTime', by: 'CreatedBy', type: 'time_exec', show: true, width: 150 },
    { name: 'Cập nhật gần nhất', id: 'UpdatedDate', date: 'UpdatedDate', by: 'UpdatedBy', type: 'time_exec', show: true, width: 150 },
    { name: 'Trạng thái', id: 'IsAccountActive', type: 'status', show: true, width: 100 },
];

export function mapDispatchToProps(dispatch) {
    return {
        searchStaffs: (params) => dispatch(fetchStaffsRequest(params)),
        lockStaff: (params) => dispatch(lockStaffRequest(params)),
        unlockStaff: (params) => dispatch(unlockStaffRequest(params))
    };
}

const mapStateToProps = createStructuredSelector({
    staffs: selectStaffs(),
    loading: selectStaffsing(),
    pagination: selectPagination(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default recompose(
    withConnect,
    withState('staff', 'setStaff', null),
    withState('openPass', 'setOpenPass', false),
    withState('openStaff', 'setOpenStaff', false),
    withState('onlyView', 'setOnlyView', false),
    withState('params', 'setParams', { Offset: 1, Size: 20 }),
    withState('columns', 'setColumns', columns),
    withState('rowKeys', 'setRowKeys', []),
    withState('rowRows', 'setSelectRows', []),
    withState('tabs', 'setTabs', '1'),
    withHandlers({
        fetchStaffs: ({ params, setParams, searchStaffs }) => (param) => {
            let _params = { ...params, Offset: 1, ...param };

            if (_params.Offset === undefined) _params.Offset = 1;
            if (_params.Size === undefined) _params.Size = 20;

            _params = _.pickBy(_params, _.identity);
            setParams(_params);
            searchStaffs({ ..._params });
        },
        setNewGenCode: ({ setIsGenCode }) => (isGen) => {
            setIsGenCode(isGen)
        },
        stateColumn: ({ columns, setColumns }) => (data) => {
            const cols = columns.map((col) => {
                if (col.id === data.id) col.show = !data.show
                return col;
            });
            setColumns(cols);
        }
    }),
    withHandlers({
        onViewStaff: ({ setStaff, setOpenStaff }) => (staff) => {
            setStaff(staff);
            setOpenStaff(true);
        },
        onPassStaff: ({ setStaff, setOpenPass }) => (staff) => {
            setStaff(staff, () => setOpenPass(true))
        },
        onInsertStaff: ({ setStaff, setOpenStaff }) => (staff) => {
            setStaff(null, () => setOpenStaff(true))
        },
        onUpdateStaff: ({ setStaff, setOpenStaff }) => (staff) => {
            setStaff(staff, () => setOpenStaff(true))
        },
        onLockStaff: ({ lockStaff, fetchStaffs }) => (staff) => {
            if (staff) {
                lockStaff({
                    data: staff,
                    cb: (type) => {
                        if (type) {
                            message.success('Khóa tài khoản nhân viên thành công!');
                            fetchStaffs();
                        } else {
                            message.error('Không thể khóa tài khoản nhân viên!');
                        }
                    }
                })
            }
        },
        onUnlockStaff: ({ unlockStaff, fetchStaffs }) => (staff) => {
            if (staff) {
                unlockStaff({
                    data: staff,
                    cb: (type) => {
                        if (type) {
                            message.success('Mở khóa tài khoản nhân viên thành công!');
                            fetchStaffs();
                        } else {
                            message.error('Không thể mở khóa tài khoản nhân viên!');
                        }
                    }
                })
            }
        },
    }),
    lifecycle({
        componentDidMount() {
            this.props.fetchStaffs();
        }
    })
)(StaffsV);