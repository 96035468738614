import { call, put, takeLatest } from 'redux-saga/effects';
import * as AssetSettingServices from '../../services/asset-setting-service';
import * as assetServices from '../../services/asset-service';

import {
  fetchAssetsSuccess,
  fetchGroupsSuccess,
  fetchTypesSuccess,
  fetchVendorsSuccess,
  fetchLocationsSuccess,
  fetchLeadersSuccess,
} from './reducer';


export function* fetchAssetChooses({ payload }) {
  try {
    const { Result } = yield call(assetServices.fetchAssets, payload);
    if(Result && Result.Data) {
      const { Data, Pagination } = Result
      yield put(fetchAssetsSuccess({ assets: Data, pagination: Pagination }));
    } else {
      yield put(fetchAssetsSuccess({ assets: [], pagination: { Offset: 1, Size: 50, Total: 0 } }));
    }
  } catch (error) {
    yield put(fetchAssetsSuccess({ assets: [], pagination: { Offset: 1, Size: 50, Total: 0 } }));
  }
}

export function* fetchAssetGroups({ payload }) {
  try {
    const { Result } = yield call(AssetSettingServices.fetchAssetGroups, payload);
    if(Result && Result.Data) {
      const { Data } = Result
      yield put(fetchGroupsSuccess({ groups: Data }));
    } else {
      yield put(fetchGroupsSuccess({ groups: [] }));
    }
  } catch (error) {
    yield put(fetchGroupsSuccess({ groups: [] }));
  }
}

export function* fetchAssetTypes({ payload }) {
  try {
    const { Result } = yield call(AssetSettingServices.fetchAssetTypes, payload);
    if(Result && Result.Data) {
      const { Data } = Result
      yield put(fetchTypesSuccess({ types: Data }));
    } else {
      yield put(fetchTypesSuccess({ types: [] }));
    }
  } catch (error) {
    yield put(fetchTypesSuccess({ types: [] }));
  }
}

export function* fetchAssetVendors({ payload }) {
  try {
    const { Result } = yield call(AssetSettingServices.fetchAssetVendors, payload);
    if(Result && Result.Data) {
      const { Data } = Result
      yield put(fetchVendorsSuccess({ vendors: Data }));
    } else {
      yield put(fetchVendorsSuccess({ vendors: [] }));
    }
  } catch (error) {
    yield put(fetchVendorsSuccess({ vendors: [] }));
  }
}

export function* fetchAssetLocations({ payload }) {
  try {
    const { Result } = yield call(AssetSettingServices.fetchAssetLocations, payload);
    if(Result && Result.Data) {
      const { Data } = Result
      yield put(fetchLocationsSuccess({ locations: Data }));
    } else {
      yield put(fetchLocationsSuccess({ locations: [] }));
    }
  } catch (error) {
    yield put(fetchLocationsSuccess({ locations: [] }));
  }
}

export function* fetchAssetLeaders({ payload }) {
  try {
    const { Result } = yield call(AssetSettingServices.fetchAssetLeaders, payload);
    if(Result) {
      yield put(fetchLeadersSuccess({ leaders: Result }));
    } else {
      yield put(fetchLeadersSuccess({ leaders: [] }));
    }
  } catch (error) {
    yield put(fetchLeadersSuccess({ leaders: [] }));
  }
}

export default [
  takeLatest('FETCH_ASSET_CHOOSE_REQUEST', fetchAssetChooses),
  takeLatest('FETCH_ASSET_GROUP_REQUEST', fetchAssetGroups),
  takeLatest('FETCH_ASSET_TYPE_REQUEST', fetchAssetTypes),
  takeLatest('FETCH_ASSET_VENDOR_REQUEST', fetchAssetVendors),
  takeLatest('FETCH_ASSET_LOCATION_REQUEST', fetchAssetLocations),
  takeLatest('FETCH_ASSET_LEADERS_REQUEST', fetchAssetLeaders),
];
