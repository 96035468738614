import axios from '../utils/axiosInstance';

export const getMyNotifications = async (params) => {
    try {
        const { data } = await axios.get('/api/notification/mine', {
            params: params
        });
        return data;
    } catch (error) {
        throw error;
    }
};

export const markAsRead = async (id) => {
    try {
        const { data } = await axios.put('/api/notification/mark-as-read', {
            id: id
        });
        return data;
    } catch (error) {
        throw error;
    }
};

export const markAllAsRead = async () => {
    try {
        const { data } = await axios.put('/api/notification/mark-all-as-read');
        return data;
    } catch (error) {
        throw error;
    }
};

export const markAsUnread = async (id) => {
    try {
        const { data } = await axios.put('/api/notification/mark-as-unread', {
            id: id
        });
        return data;
    } catch (error) {
        throw error;
    }
};

export const deleteNotification = async (params) => {
    try {
        const { data } = await axios.delete('/api/notification', {
            params: params
        });
        return data;
    } catch (error) {
        throw error;
    }
};

export const collectNewNotifications = async () => {
    try {
        const { data } = await axios.get(`/api/notification/collect-new-notifications`);
        return data;
    } catch (error) {
        throw error;
    }
}