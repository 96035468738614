
import './style.scss';
import React from 'react';
import DocumentPage from './DocumentVM';
import { Modal } from 'antd';

const TermV = (props) => {
  const { account, open, close } = props;

  return (
      <Modal
        title={<label className="bold">Thông tin tài liệu</label>}
        visible={open}
        style={{ top: 20 }}
        width={'1160px'}
        footer={null}
        maskClosable={false}
        onOk={() => close()}
        onCancel={() => close()}
        className="modal-form-zone"
      >
      <div className="w-100">
        <DocumentPage customer={account}/>
      </div>
    </Modal>
  )
}
export default TermV
