import { createAction, handleActions } from 'redux-actions';

// - Actions
export const getMyMenuRequest = createAction('FETCH_MY_MENU_REQUEST');
export const getMyMenuSuccess = createAction('FETCH_MY_MENU_SUCCESS');

// - Initial State
export const initialState = {
    myMenu: [],
    fetching: true,
    fetchedServer: false
};

// - Reducers
const reducer = handleActions({
    [getMyMenuRequest]: (state) => {
        return {
            ...state,
            myMenu: [],
            fetching: true,
            fetchedServer: false
        };
    },
    [getMyMenuSuccess]: (state, { payload: { menu } }) => {
        return {
            ...state,
            myMenu: menu,
            fetching: false,
            fetchedServer: true
        };
    }
}, initialState);

export default reducer;