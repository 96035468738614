import { lifecycle, compose as recompose, withHandlers, withState, pure} from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import _ from 'lodash';

import { partnerListRequest, confirmPartnerRequest, getCommitmentsRequest } from '../../reduxs/partner-redux/reducer';
import { selectPartners, selectLoading, selectPagination, selectCommitments } from '../../reduxs/partner-redux/selectors';

import PartnersV from './PartnersV';

const columns = [
  { name: 'Họ và Tên', id: 'FullName', show: true, width: 150},
  { name: 'Số ĐT', id: 'PhoneNumber', show: true, width: 130 },
  { name: 'Email', id: 'Email', show: true, width: 130 },
  { name: 'Giới tính', id: 'Gender', show: true, width: 60, type: 'gender', class: 'text-center' },
  { name: 'Ngày sinh', id: 'DateOfBirth', show: true, width: 100, type: 'date'},
  { name: 'Địa điểm', id: 'ProvinceId', show: true , width: 120, type: "address" },
  { name: 'CMT/CC/HC', id: 'IdNumber', show: true, width: 80, class: 'text-center' },
  { name: 'Phương tiện', id: 'VehicleTypeName', show: true, width: 80, class: 'text-center' },
  { name: 'Bằng lái xe', id: 'DriversLicenseNumber', show: true, width: 100, class: 'text-center' },
  { name: 'Loại bằng', id: 'DriversLicenseClass', show: true, width: 80, class: 'text-center' },
  { name: 'Biển số xe', id: 'VehicleNumberPlate', show: true, width: 100, class: 'text-right', type: 'price'},
  { name: 'Dòng xe', id: 'VehicleBrand', show: true, width: 100, class: 'text-right', type: 'price'},
]


export function mapDispatchToProps(dispatch) {
  return {
    searchPartners: (params) => dispatch(partnerListRequest(params)),
    confirmPartner: (params) => dispatch(confirmPartnerRequest(params)),
    getCommitments: (params) => dispatch(getCommitmentsRequest(params))
  };
}

const mapStateToProps = createStructuredSelector({
  partners: selectPartners(),
  loading: selectLoading(),
  pagination: selectPagination(),
  commitments: selectCommitments(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default recompose(
  withConnect,
  withState('open', 'close', false),
  withState('approve', 'setApprove', false),
  withState('exporting', 'setExporting', false),
  withState('confirm', 'setConfirm', false),
  withState('view', 'setView', false),
  withState('partner', 'setPartner', null),
  withState('params', 'setParams', {  Offset: 1, Size: 10, tabs: 1, ProfileStatusId: '' }),
  withState('columns', 'setColumns', columns),
  withState('rowKeys', 'setRowKeys', []),
  withState('rowRows', 'setSelectRows', []),
  withState('view', 'setView', false),
  withState('printing', 'setPrinting', false),
  withHandlers({
    fetchPartners: ({ params, setParams, searchPartners, setRowKeys, setSelectRows }) => (param) => {
      let objParams = { ...params, Offset: 1, ...param };

      objParams.ProfileStatusId = '';
      if(!objParams.tabs) objParams.ProfileStatusId = 2;
      if(objParams.tabs === 1) objParams.ProfileStatusId = 3;
      if(objParams.tabs === 2) objParams.ProfileStatusId = 4;
      if(objParams.tabs === 3) objParams.ProfileStatusId = 5;

      if(objParams.Offset === undefined) objParams.Offset = 1;
      if(objParams.Size === undefined) objParams.Size = 20;
      
      objParams = _.pickBy(objParams, _.identity);
      setParams(objParams);

      searchPartners({ ...objParams });

      setRowKeys([]);
      setSelectRows([]);
    },
    onSelectChange: ({setRowKeys, setSelectRows}) => (keys, rows) => {
      setRowKeys(keys);
      setSelectRows(rows);
    },
    
    stateColumn: ({ columns, setColumns }) => (data) => {
      const cols = columns.map((col) => {
        if(col.id === data.id) col.show = !data.show
        return col;
      });
      setColumns(cols);
    }
  }),
  withHandlers({
    onViewBill: ({close, setView, setPartner}) => (partner) => {
      if(partner) {
        setView(true, setPartner(partner, () => close(true)))
      } else {
        setPartner(null, () => close(false))
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.fetchPartners();
    }
  }),
  pure,
)(PartnersV);