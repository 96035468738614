import React from 'react';

const localStorageKey = 'sys-app-version';
const reloadRemaining = 'reload-remaining';

// version from response - first param, local version second param
const semverGreaterThan = (versionA, versionB) => {
  return versionA !== versionB
};

class CacheBuster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isLatestVersion: false,
      modalConfirmShown: false
    };
  }

  refreshCacheAndReload = (_version) => {
    console.log('Clearing cache and hard reloading...')
    localStorage.setItem(localStorageKey, _version);
    try {
      if (caches && caches instanceof CacheStorage) {
        // Service worker cache should be cleared with caches.delete()
        caches.keys().then(function (names) {
          for (let name of names) caches.delete(name);
        });
      }
    } catch (error) {
      console.error(error)      
    }
    // delete browser cache and hard reload
    localStorage.setItem(reloadRemaining, 2);
    window.location.reload();
  }

  setModalConfirm = (shown) => { this.setState({ modalConfirmShown: shown }) }

  componentDidMount() {
    fetch('/meta.json', { cache: "no-cache" })
      .then((response) => response.json())
      .then((meta) => {
        if (localStorage.getItem(reloadRemaining) && JSON.parse(localStorage.getItem(reloadRemaining)) > 0) {
          if (localStorage.getItem(reloadRemaining) - 1 <= 0) {
            localStorage.removeItem(reloadRemaining);
          } else {
            localStorage.setItem(reloadRemaining, JSON.parse(localStorage.getItem(reloadRemaining)) - 1);
          }
          window.location.reload();
          return;
        }
        const latestVersion = meta.version;
        const currentVersion = localStorage.getItem(localStorageKey);
        const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);
        if (shouldForceRefresh) {
          console.log(`We have a new version - ${latestVersion}. Should force refresh`);
          this.setState({
            loading: false,
            isLatestVersion: false,
            version: latestVersion,
            modalConfirmShown: false
          });
        } else {
          console.log(`You already have the latest version - ${latestVersion}. No cache refresh needed.`);
          this.setState({
            loading: false,
            isLatestVersion: true,
            version: latestVersion,
            modalConfirmShown: false
          });
        }
      });
  }
  
  render() {
    const {
      loading,
      isLatestVersion,
      version,
      modalConfirmShown
    } = this.state;

    const { refreshCacheAndReload, setModalConfirm } = this;

    return this.props.children({
      loading,
      isLatestVersion,
      refreshCacheAndReload,
      version,
      modalConfirmShown,
      setModalConfirm
    });
  }
}

export default CacheBuster;