import { call, put, takeLatest } from 'redux-saga/effects';
import * as EmailService from '../../services/email-management-service';


import {
    fetchEmailSuccess,
    resendEmailSuccess,
    resendEmailFailure,
    failure
} from './reducer';

export function* fetchEmails({ payload = {} }) {
    try {
        const { Result } = yield call(EmailService.getEmails, payload);
        if(Result) {
            yield put(fetchEmailSuccess({ emails: Result }));
        } else {
            yield put(fetchEmailSuccess({ emails: [] }));
        }
    } catch (error) {
        yield put(fetchEmailSuccess({ emails: [] }));
        yield put(failure(error));
    }
}

export function* resendEmail({ payload = {} }) {
    try {
        const { callback } = payload;
        const { Result } = yield call(EmailService.reSendEmail, payload);
        if (Result) {
            if (callback) {
                callback('ok')
            }
            yield put(resendEmailSuccess({ resendSuccess: Result }));
        } else {
            if (callback) {
                callback('error')
            }
            yield put(resendEmailSuccess({ resendSuccess: false }));
        }
    } catch (error) {
        yield put(resendEmailFailure({ resendSuccess: false }));
        yield put(failure(error));
    }
}

export function* downloadAllAttachments({ payload }) {
    const { data, cb } = payload;
    try {
    const Result = yield call(EmailService.downloadAllAttachments, data);
    if(Result) {
        cb('success')
    } else {
        cb('error')
    }
    } catch (error) {
    cb('error')
    }
}

export default [
    takeLatest('FETCH_EMAIL_REQUEST', fetchEmails),
    takeLatest('EXPORT_ALL_ATTACHMENTS_REQUEST', downloadAllAttachments),
    takeLatest('RESEND_EMAIL_REQUEST', resendEmail)
];
