
import axios from '../utils/axiosInstance';

export const searchStaffs = async (params ={}) => {
  try {
    const { data } = await axios.get('/crm/staff', { params });
    return data;
  } catch (error) {
    throw error;
  }
};

export const searchMyStaffs = async (params ={}) => {
  try {
    const { data } = await axios.get('/crm/staff/my-staff', { params });
    return data;
  } catch (error) {
    throw error;
  }
};


export const getStaffsByCode = async (params ={}) => {
  try {
    const { data } = await axios.get('/crm/staff/by-code', { params });
    return data;
  } catch (error) {
    throw error;
  }
};
