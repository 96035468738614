import { lifecycle, compose as recompose, withHandlers, pure } from 'recompose';
import { connect } from 'react-redux';
import { message } from 'antd';
import FileComponentV from './FileComponentV';
import { exportAllAttachmentsRequest } from '../../../reduxs/email-redux/reducer';

export function mapDispatchToProps(dispatch) {
  return {
    downloadAllAttachments: (params) => dispatch(exportAllAttachmentsRequest(params))
  };
}

const mapStateToProps = () => {
    return {}
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default recompose(
  withConnect,
  withHandlers({
    downloadAllAttachments: ({downloadAllAttachments}) => (emailId) => {
      const params = {
        data: {
          EmailId: emailId
        },
        cb: (type) => {
          if(type === 'success') {
            message.success('Tải xuống tất cả tệp đính kèm thành công!')
          } else {
            message.error('Tải xuống tất cả tệp đính kèm không thành công!')
          }
        }
      }
      downloadAllAttachments(params);
    },
    getIcon: () => (attachment) => {
      const ext = attachment.FileName.split('.')[attachment.FileName.split('.').length - 1];
      let icon = 'file.svg';
      switch (ext) {
        case 'xls':
        case 'xlsx': {
          icon = 'xls.svg';
          break;
        }
        case 'doc':
        case 'docx': {
          icon = 'doc.svg';
          break;
        }
        case 'pdf': {
          icon = 'pdf.svg';
          break;
        }
        case 'zi': {
          icon = 'zip.svg';
          break;
        }
        default: {
          break;
        }
      }
      return `/images/icons/file-types/${icon}`
    }
  }),
  lifecycle({
    componentDidMount() {
    }
  }),
  pure,
)(FileComponentV);