import { lifecycle, compose as recompose, withState, withHandlers} from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import _ from 'lodash';

import { fetchAutosRequest } from '../../reduxs/auto-redux/reducer';
import { selectAutos, selectAutosing, selectPagination } from '../../reduxs/auto-redux/selectors';

import AutomaticV from './AutomaticV';

export function mapDispatchToProps(dispatch) {
  return {
    getAutos: (params) => dispatch(fetchAutosRequest(params)),
  };
}

const mapStateToProps = createStructuredSelector({
  autos: selectAutos(),
  loading: selectAutosing(),
  pagination: selectPagination(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default recompose(
  withConnect,
  withState('open', 'close', false),
  withState('auto', 'setAuto', null),
  withState('params', 'setParams', { Offset: 1, Size: 20 }),
  withHandlers({
    fetchAutos: ({getAutos, setParams, params}) => (values) => {
      let _params = { ...params, ...values }
      _params = _.pickBy(_params, _.identity);
      setParams(_params);
      getAutos(_params)
    },
    // fetchAutos: ({ getAutos }) => () => {
    //   getAutos()
    // },
    onEditAuto: ({close, setAuto}) => (auto) => {
      setAuto(auto);
      close(true)
    }
  }),
  lifecycle({
    componentDidMount() {
      this.props.fetchAutos();
    }
  }),
)(AutomaticV);