import './style.scss';
import React, { useEffect, useState } from 'react';
import { Layout, Button, List } from 'antd';
import LoadingBar from 'react-top-loading-bar';
import RestrictPage from './../../components/RestrictComponents/RestrictPage';
import { ReloadOutlined } from '@ant-design/icons';

const { Content } = Layout;

const ItemCounter = (props) => {
    const { icon, count, description, loading, onclickReresh, baseUrl } = props;
    return (
        <div className="counter-container">
            <Button className="counter-refresh" onClick={() => onclickReresh({ baseUrl: `https://${baseUrl}` })} loading={loading} icon={<ReloadOutlined />}></Button>
            <div className="title">
                {icon}
            </div>
            <div className="count">
                <span className="count-number">{(loading ? <i className="fa fa-spinner fa-spin" aria-hidden="true"></i> : count)}</span>
            </div>
            <div className="description">
                <span>{description}</span>
            </div>
        </div>
    )
}

const TaskQueueV = (props) => {
    const { isMobile, fetching, taskInQueue, fetchingOp, taskInQueueOp, fetchingCrm, taskInQueueCrm, countTaskInQueue, countTaskInQueueOp, countTaskInQueueCrm, details, detailsOp, detailsCrm, fetchingDetails, fetchingDetailsOp, fetchingDetailsCrm } = props;
    const { onGetDetails, onGetDetailsOP, onGetDetailsCRM } = props;
    const [progress, setProgress] = useState(0);

    console.log(details, detailsCrm, detailsOp)

    useEffect(() => {
        setTimeout(() => {
            const nextP = progress + 1;
            if (nextP <= 10) {
                setProgress(nextP);
            } else {
                setProgress(0);
                countTaskInQueue();
                countTaskInQueueOp();
                countTaskInQueueCrm();
                
                onGetDetails()
                onGetDetailsOP()
                onGetDetailsCRM()
            }
        }, 1000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [progress])

    return (
        <RestrictPage>
            <Content className="page-task-queue">
                <div className="panel-header">
                    <div className="header-title">
                        <i className="fa fa-home icon" aria-hidden="true"></i>
                        <span>Hệ thống</span>
                        <span className="icon-next"><i className="fa fa-angle-right"></i></span>
                        <span className="active">Task queue</span>
                    </div>
                </div>
                <div className={isMobile ? "col-md-12 pd-0" : "col-md-12"}>
                    <div className="panel-body">
                        <div>
                            <LoadingBar color={'#f11946'} progress={progress * 10} onLoaderFinished={() => setProgress(0)} />
                        </div>
                        <div className="task-queue-list">
                            <ItemCounter
                                key="apiv2.pcs.vn"
                                baseUrl="apiv2.pcs.vn"
                                onclickReresh={countTaskInQueue}
                                loading={fetching}
                                icon={<i className="fa fa-cogs item-counter-icon" aria-hidden="true" ></i>}
                                count={taskInQueue}
                                description={<span>Số task đang có trong queue<br /><span className="bold">apiv2.pcs.vn</span></span>}
                            />

                            <ItemCounter
                                key="api-op.pcs.vn"
                                onclickReresh={countTaskInQueueOp}
                                loading={fetchingOp}
                                icon={<i className="fa fa-cogs item-counter-icon" aria-hidden="true" ></i>}
                                count={taskInQueueOp}
                                description={<span>Số task đang có trong queue<br /><span className="bold">api-op.pcs.vn</span></span>}
                            />

                            <ItemCounter
                                key="api-crm.pcs.vn"
                                onclickReresh={countTaskInQueueCrm}
                                loading={fetchingCrm}
                                icon={<i className="fa fa-cogs item-counter-icon" aria-hidden="true" ></i>}
                                count={taskInQueueCrm}
                                description={<span>Số task đang có trong queue<br /><span className="bold">api-crm.pcs.vn</span></span>}
                            />
                        </div>
                        <div className='task-queue-details'>
                            <div className='row'>
                                <div className='col-lg-4'>
                                    <div><span className='bold'>apiv2.pcs.vn</span></div>
                                    <List
                                        bordered
                                        size='small'
                                        style={{ height: 400, overflow: 'auto' }}
                                        loading={fetchingDetails}
                                        dataSource={details}
                                        renderItem={(item) => (
                                            <List.Item key={item.Source}>
                                                <div>{item.Source}</div>
                                                <div><span className='count-task'>{item.Count}</span></div>
                                            </List.Item>
                                        )}
                                    />
                                </div>

                                <div className='col-lg-4'>
                                <div><span className='bold'>api-op.pcs.vn</span></div>
                                    <List
                                        bordered
                                        size='small'
                                        style={{ height: 400, overflow: 'auto' }}
                                        loading={fetchingDetailsOp}
                                        dataSource={detailsOp}
                                        renderItem={(item) => (
                                            <List.Item key={item.Source}>
                                                <div>{item.Source}</div>
                                                <div><span className='count-task'>{item.Count}</span></div>
                                            </List.Item>
                                        )}
                                    />
                                </div>

                                <div className='col-lg-4'>
                                <div><span className='bold'>api-crm.pcs.vn</span></div>
                                    <List
                                        bordered
                                        size='small'
                                        style={{ height: 400, overflow: 'auto' }}
                                        loading={fetchingDetailsCrm}
                                        dataSource={detailsCrm}
                                        renderItem={(item) => (
                                            <List.Item key={item.Source}>
                                                <div>{item.Source}</div>
                                                <div><span className='count-task'>{item.Count}</span></div>
                                            </List.Item>
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Content>
        </RestrictPage>
    );
};

export default TaskQueueV