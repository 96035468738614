import { createAction, handleActions } from 'redux-actions';
// - Actions
export const getCustomerInfoSheetRequest = createAction('GET_CUSTOMER_INFO_SHEET_REQUEST');
export const getCustomerInfoSheetSuccess = createAction('GET_CUSTOMER_INFO_SHEET_SUCCESS');

export const confirmCustomerInfoSheetRequest = createAction('CONFIRM_CUSTOMER_INFO_SHEET_REQUEST');
export const deleteCustomerInfoSheetRequest = createAction('DELETE_CUSTOMER_INFO_SHEET_REQUEST');
export const deleteCustomerInfoSheetSuccess = createAction('DELETE_CUSTOMER_INFO_SHEET_SUCCESS');
export const InfoSheetDownloadRequest = createAction('INFO_SHEET_DOWNLOAD_REQUEST');
export const failure = createAction('INBILL_ACTION_FAILURE');
// - Initial State
export const initialState = {
  error: null,
  dataSource: [],
  pagination: {},
  fetchingData: false
};

// - Reducers
const reducer = handleActions({
  [getCustomerInfoSheetRequest]: state => ({ ...state, fetchingData: true }),
  [getCustomerInfoSheetSuccess]: (state, { payload: { data, pagination } }) => ({ ...state, fetchingData: false, dataSource: data, pagination: pagination }),
  [deleteCustomerInfoSheetRequest]: state => ({ ...state }),
  [deleteCustomerInfoSheetSuccess]: state => ({ ...state }),
  [failure]: (state, action) => ({
    ...state,
    error: action.error,
    loading: false,
  }),
}, initialState);

export default reducer;