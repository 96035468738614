
import axios from '../utils/axiosInstance';

export const getRevenues= async (params) => {
  try {
    const { data } = await axios.get(`/api/revenue/compare-data`, {
      params: params
    });
    return data;
  } catch (error) {
    throw error;
  }
};

