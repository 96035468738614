import axios from '../utils/axiosInstance';
import FileSaver from 'file-saver';

// Danh sách phòng ban
export const getListDepartments = async (params = {}) => {
    try {
        const { data } = await axios.get('/department/list-department', { params });
        return data;
    } catch (error) {
        throw error;
    }
};

// Chi tiết phòng ban
export const getDetailDepartment = async (params) => {
    try {
        const { data } = await axios.get(`/department/all/${params.Id}`);
        return data;
    } catch (error) {
        throw error;
    }
};

// Thêm phòng ban
export const addDepartment = async (department) => {
    try {
        const { data } = await axios.post('/department/add-department', department);
        return data;
    } catch (error) {
        throw error;
    }
};

// Sửa phòng ban
export const updateDepartment = async (department) => {
    try {
        const { data } = await axios.post(`/department/add-department`, department);
        return data;
    } catch (error) {
        throw error;
    }
};

// Xóa phòng ban
export const deleteDepartment = async (department) => {
    try {
        const { data } = await axios.delete(`/department/delete-department`,{ data: department });
        return data;
    } catch (error) {
        throw error;
    }
};

// Xuất dữ liệu excel phòng ban
export const exportExcelDepartment = async (params) => {
    try {
      const response = await axios.get('/department/export-department', { params,  responseType: 'blob' });
      // return data;
      // const response = await axios.post('/api/quote/export/excel', body, {
      //   responseType: 'blob'
      // });
      const { data } = response;
      const namefile = response.headers['pcs-filename-suggestion'];
      const fileType = response.headers['content-type'];
      var blob = new Blob([data], {type: fileType});
      FileSaver.saveAs(blob, namefile);
      return true;
    } catch (error) {
      throw error;
    }
  };
