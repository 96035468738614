export const apiEndpoint = ((_env) => {
  switch (_env) {
    case 'production':
      return 'https://apiv2.pcs.vn'

    case 'uat':
      return 'https://uat-apiv2.pcs.vn'

    default:
      return 'http://localhost:5003'
  }
})(process.env.REACT_APP_ENV)

export const apiEndpointHR = process.env.REACT_APP_ENV === 'production' ? 'https://my-pcs.pcs.vn' : 'http://localhost:5003';
export const apiEndpointPN = process.env.REACT_APP_ENV === 'production' ? 'https://api-ctv.pcstech.co' : 'http://localhost:5003';

export const title = 'PCS SYSTEM';
export const ApiUploadImage = `${apiEndpoint}/api/upload/file`;
export const getAvatar = (staffCode) => `${apiEndpoint}/api/user-info/${staffCode}`;
export const auth = {
  appFacebookId: '637698013405871',
  clientIdGoogle: '408369692449-l7jqfvaqe5h0t0qfd8edbfha5ua2hg03.apps.googleusercontent.com'
};

const maxBirthday = () => {
  let maxDate = new Date()
  maxDate.setFullYear(maxDate.getFullYear() - 16)
  return maxDate
}

const minBirthday = () => {
  let maxDate = new Date()
  maxDate.setFullYear(maxDate.getFullYear() - 120)
  return maxDate
}

export const APP_CONFIG = {

  URL_LOGIN: process.env.REACT_APP_ENV === 'production' ? 'https://id.pcs.vn' : 'https://id.pcs.vn',

  BIRTHDAY: {
    MIN_BIRTHDAY: minBirthday(),
    MAX_BIRTHDAY: maxBirthday()
  },

  NOTIF_API_HOST: process.env.REACT_APP_ENV === 'production' ? 'https://api-chat.pcs.vn' : 'https://api-chat.pcs.vn'
}
