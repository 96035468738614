const icons = {
  common: {
    logo: require('./icons/logo.svg'),
    hello: require('./icons/hello.jpg'),
    report_full: require('./icons/report_full.png'),
    banner_login: require('./icons/banner-login.png'),
    loading: require('./svg/loading.svg'),
    home: require('./svg/home.svg'),
    filter: require('./svg/filter-results.svg'),
    list_task: require('./svg/list.svg'),
    excel: require('./svg/excel.svg'),
    more: require('./svg/more.svg'),
    account: require('./svg/account.svg'),
    report: require('./svg/report.svg'),
    cog: require('./svg/cog.svg'),
    email: require('./svg/email.svg'),
    role: require('./svg/role.svg'),
    service: require('./svg/service.svg'),
    menu: require('./svg/menu.svg'),
    location: require('./svg/location.svg'),
    postType: require('./svg/post-office.svg'),
    postInformation: require('./svg/share-post.svg'),
    postAddress: require('./svg/address.svg'),
    postContact: require('./svg/phone.svg'),
    mailSend: require('./svg/mail-send.svg'),
    department: require('./svg/department.svg'),
    colorPicker: require('./svg/palette.svg'),
    mobile_balance: require('./svg/mobile-balance.svg')
  },
  dashboard: {
    icon_no_acc: require('./svg/account/icon_chua_co_tk.svg'),
    icon_wt_acc: require('./svg/account/icon_ds_tk.svg'),
    icon_ls_acc: require('./svg/account/icon_taikhoan_choduyet.svg'),

    icon_ls_region: require('./svg/region/icon_ds_vungmien.svg'),
    icon_ls_location: require('./svg/region/icon_quanlydiadanh2.svg'),
    icon_region_delivery: require('./svg/region/icon_vungphat.svg'),

    icon_sys_service: require('./svg/system/icon_chayngam.svg'),
    icon_sys_policy: require('./svg/system/icon_hethong_capphep.svg'),
    icon_sys_role: require('./svg/system/icon_vaitro_nguoidun.svg'),

    icon_station: require('./svg/system_one/icon_quanly_buucuc.svg'),
    icon_email: require('./svg/system_one/icon_quanly_email.svg'),
    icon_department: require('./svg/system_one/icon_quanly_phongban.svg'),

    icon_rp_revenue: require('./svg/report/icon_bc_doanhthu.svg'),
  },
  sys: require('./icons/pcs-sys-logo.png'),
}

const images = {
  common: {
    noAvatar: require('./images/no-avatar.png'),
    noImage: require('./images/no-image-big.jpg')
  },
  home: {

  },
  aboutUs: {

  }
}

export {
  icons,
  images
}
