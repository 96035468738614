import { SET_ACCESS_TOKEN, SET_ENVIRONMENT, SET_HANDLE_OPEN_ELEMENT } from '../constants';

export function setAccessToken(access_token) {
    return {
        type: SET_ACCESS_TOKEN,
        payload: access_token
    }
}

export function setEnvironment(access_token) {
    return {
        type: SET_ENVIRONMENT,
        payload: access_token
    }
}

export function setOpenHandleElement(element) {
    return {
        type: SET_HANDLE_OPEN_ELEMENT,
        payload: element
    }
}
