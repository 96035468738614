import './styles.scss';
import React from 'react'
import { Radio, Modal, Checkbox, Spin } from 'antd';

const optionsWeek = [
    { value: 1, label: 'Thứ hai' },
    { value: 2, label: 'Thứ ba' },
    { value: 3, label: 'Thứ tư' },
    { value: 4, label: 'Thứ năm' },
    { value: 5, label: 'Thứ sáu' },
    { value: 6, label: 'Thứ bảy' },
    { value: '0', label: 'Chủ nhật' },
];

const optionsMoth = () => {
    let options = [];
    for (let index = 1; index <= 31; index++) {
        options.push({ value: index, label: 'Ngày '+index })
    }
    return options;
}

const ScheduleV = (props) => {
    const {open, close, type, types, schedulesing, valueWeeks, valueMonths, onScheduleType, onScheduleValue } = props;

    let _week = types.find(k => k.TypeId === 'WEEK');
    let _month = types.find(k => k.TypeId === 'MONTH');
    let _types = [];
    if(types && types.length > 0) {
        _types = types.map((item) => {
            return {
                value: item.TypeId,
                label: item.Name,
            }
        })
    }

    return (
        <Modal
            title={<label className="bold"><i className="fa fa-calendar-o"/>&ensp;Lịch trình chốt cước cho khách hàng</label>}
            visible={open}
            style={{ top: 20 }}
            width={'860px'}
            footer={null}
            maskClosable={false}
            onOk={() => close()}
            onCancel={() => close()}
            className="modal-form-zone"
        >
            <div className="row">
                <div className="col-md-12">
                    <Spin spinning={schedulesing} delay={100}>
                        <div className="group-fee">
                            <div className="group-header">
                                <Radio.Group
                                    options={_types}
                                    onChange={(e) => onScheduleType(e.target.value)}
                                    value={type}
                                    optionType="button"
                                    buttonStyle="solid"
                                />
                            </div>
                            <div className="group-body">
                                {type && _types && _types.length > 0 &&
                                    (type === 'WEEK'
                                    ?
                                    <>
                                        <p className="max-time">Bạn được phép chọn tối đa {_week.MaxTime} ngày</p>
                                        <Checkbox.Group options={optionsWeek} value={valueWeeks.map((i) => i.Value === 0 ? '0': i.Value)} onChange={(values) => onScheduleValue(values, 'week')} className="group-schedule" />
                                    </>
                                    :
                                    <>
                                        <p className="max-time">Bạn được phép chọn tối đa {_month.MaxTime} ngày</p>
                                        <Checkbox.Group options={optionsMoth()} value={valueMonths.map((i) => i.Value)} onChange={(values) => onScheduleValue(values, 'month')} className="group-schedule" />
                                    </>
                                    )
                                }
                            </div>
                        </div>
                    </Spin>
                </div>
            </div>
        </Modal>
    )
}
export default ScheduleV
