import React from "react";
import { Form, DatePicker } from "antd";
import moment from 'moment';

const FormItem = Form.Item;

const makeField = Component => ({ input, meta, children, hasFeedback, formatDate, label, ...rest }) => {
  const hasError = meta.touched && meta.invalid;
  let date = null;
  if(input.value) {
    date = moment(input.value, formatDate || 'DD-MM-YYYY')
    date = date.isValid() ? date : null;
  }

  input = {
    ...input,
    value: date,
  }

  return (
    <FormItem
      label={label}
      validateStatus={hasError ? "error" : "success"}
      hasFeedback={hasFeedback && hasError}
      help={hasError && meta.error}
    >
      <Component {...input} {...rest} children={children} format={formatDate || "DD-MM-YYYY"}/>
    </FormItem>
  );
};

export default makeField(DatePicker);