import React from "react";
import { connect } from "react-redux";
import { CallbackComponent } from "redux-oidc";
import { push } from "connected-react-router";
import userManager from "../../utils/userManager";
import { Icons } from '../../assets'

class CallbackPage extends React.Component {
  render() {
    return (
      <CallbackComponent
        userManager={userManager}
        successCallback={() => this.props.dispatch(push("/"))}
        errorCallback={() => this.props.dispatch(push("/"))}
        >
        <div>
          <div className="text-center">
            <img src={Icons.common.loading} alt="loading" width="100" style={{paddingTop: '45vh'}} />
            <p>Đang chuyển hướng...</p>
          </div>
        </div>
      </CallbackComponent>
    );
  }
}

export default connect()(CallbackPage);
