import { call, put, takeLatest } from 'redux-saga/effects';
import * as AssetHandoverServices from '../../services/asset-handover-service';
import moment from 'moment';
import { AssetHandoverFormAction } from "./action";
import { SubmissionError } from "redux-form";

import {
  assetHandoversSuccess,
  getAssetHandoverSuccess
} from './reducer';


export function* fetchAssetHandovers({ payload }) {
  try {
    const { Result } = yield call(AssetHandoverServices.fetchAssetHandovers, payload);
    if(Result.Data) {
      const { Data, Pagination } = Result
      yield put(assetHandoversSuccess({ handovers: Data, pagination: Pagination }));
    } else {
      yield put(assetHandoversSuccess({ handovers: [], pagination: { Page: 1,Size: 50, Total: 0} }));
    }
  } catch (error) {
    yield put(assetHandoversSuccess({ handovers: [], pagination: { Page: 1,Size: 50, Total: 0} }));
  }
}

export function* getAssetHandover({ payload }) {
  try {
    const { Result } = yield call(AssetHandoverServices.getAssetHandover, payload);
    if(Result) {
      yield put(getAssetHandoverSuccess({ handover: Result }));
    } else {
      yield put(getAssetHandoverSuccess({ handover: null }));
    }
  } catch (error) {
    yield put(getAssetHandoverSuccess({ handover: null }));
  }
}

export function* deleteAssetHandover({ payload: { data, cb } }) {
  try {
    const { Result } = yield call(AssetHandoverServices.deleteAssetHandover, data);
    if(Result) {
      cb(true)
    } else {
      cb(false)
    }
  } catch (error) {
    cb(false)
  }
}

export function* saveAssetHandover({ payload }) {
  try {
    if(payload.RELATES_CODE && payload.RELATES_CODE.length > 0) {
      payload.RELATES_CODE = payload.RELATES_CODE.join(',');
    } else {
      payload.RELATES_CODE = ""
    }
    if(payload.CREATED_DATE) payload.CREATED_DATE = moment(payload.CREATED_DATE).format('YYYY-MM-DD')
    if(payload.SIGN_DATE) payload.SIGN_DATE = moment(payload.SIGN_DATE).format('YYYY-MM-DD')

    const { Result } = yield call(AssetHandoverServices.saveAssetHandover, payload);
    if (Result) {
      yield put(AssetHandoverFormAction.success(Result));
    } else {
      const formError = new SubmissionError({
        _error: 'Tạo phiếu bàn giao thất bại!'
      });
      yield put(AssetHandoverFormAction.failure(formError));
    }
  } catch (error) {
    let _message = "Tạo phiếu bàn giao thất bại!";
    if(error && typeof error.Description === 'string') {
      _message = error.Description;
    }
    const formError = new SubmissionError({_error: _message});
    yield put(AssetHandoverFormAction.failure(formError));
  }
}

export function* confirmAssetHandover({ payload: { data, cb } }) {
  try {
    const Result  = yield call(AssetHandoverServices.confirmAssetHandover, data);
    if(Result) {
      cb(true)
    } else {
      cb(false)
    }
  } catch (error) {
    cb(false)
  }
}

export function* printAssetHandover({ payload: { data, cb } }) {
  try {
    const { Result }  = yield call(AssetHandoverServices.printAssetHandover, data);
    if(Result) {
      cb(true)
    } else {
      cb(false)
    }
  } catch (error) {
    cb(false)
  }
}

export default [
  takeLatest('ASSET_HANDOVER_LIST_REQUEST', fetchAssetHandovers),
  takeLatest('ASSET_HANDOVER_REQUEST', getAssetHandover),
  takeLatest('ASSET_DEL_HANDOVER_REQUEST', deleteAssetHandover),
  takeLatest('ASSET_HANDOVER_CONFIRM_REQUEST', confirmAssetHandover),
  takeLatest('ASSET_HANDOVER_PRINT_REQUEST', printAssetHandover),
  takeLatest(AssetHandoverFormAction.REQUEST, saveAssetHandover),
];
