import { lifecycle, compose as recompose, withState, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { fetchStaffsCommissionRequest, getStaffCommissionRequest, deleteCommissionRequest, activeCommissionRequest  } from '../../reduxs/staff-redux/reducer';
import { selectCommissions, selectCommissionsing, selectCommissionPagin } from '../../reduxs/staff-redux/selectors';
import _ from 'lodash';
import CommissionV from './CommissionV';

const columns = [
    { name: 'Mã nhân viên', id: 'Code', type: 'string', show: true, width: 160 },
    { name: 'Tên nhân viên', id: 'Name', type: 'string', show: true },
];

export function mapDispatchToProps(dispatch) {
    return {
        fetchCommissions: (params) => dispatch(fetchStaffsCommissionRequest(params)),
        getCommission: (params) => dispatch(getStaffCommissionRequest(params)),
        getActiveCommission: (params) => dispatch(activeCommissionRequest(params)),
        deleteCommission: (params) => dispatch(deleteCommissionRequest(params)),
    };
}

const mapStateToProps = createStructuredSelector({
    commissions: selectCommissions(),
    loading: selectCommissionsing(),
    pagination: selectCommissionPagin(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default recompose(
    withConnect,
    withState('staff', 'setStaff', null),
    withState('openStaff', 'setOpenStaff', false),
    withState('openCommission', 'setOpenCommission', false),
    withState('params', 'setParams', { Offset: 1, Size: 20 }),
    withState('columns', 'setColumns', columns),
    withHandlers({
        fetchCommissions: ({ params, setParams, fetchCommissions }) => (param) => {
            let _params = { ...params, Offset: 1, ...param };

            if (_params.Offset === undefined) _params.Offset = 1;
            if (_params.Size === undefined) _params.Size = 20;

            _params = _.pickBy(_params, _.identity);
            setParams(_params);
            fetchCommissions({ ..._params });
        },
        stateColumn: ({ columns, setColumns }) => (data) => {
            const cols = columns.map((col) => {
                if (col.id === data.id) col.show = !data.show
                return col;
            });
            setColumns(cols);
        }
    }),
    withHandlers({
        onViewCommission: ({ setStaff, setOpenStaff, getCommission }) => (staff) => {
            getCommission({ StaffCode: staff.Code })
            setStaff(staff, () => setOpenStaff(true));
        },
        onCommissionStaff: ({ setStaff, setOpenCommission }) => (staff) => {
            setStaff(staff, () => setOpenCommission(true))
        },
    }),
    lifecycle({
        componentDidMount() {
            this.props.fetchCommissions();
        }
    })
)(CommissionV);