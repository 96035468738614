
import axios from '../utils/axiosInstance';

export const getListExportFuelSurcharges = async (params) => {
  try {
    const { data } = await axios.get(`/web-export-fuel-surcharges/list-web-export-fuel-surcharges`, {
      params: params
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const saveExportFuelSurcharge = async (params) => {
  try {
    const { data } = await axios.post(`/web-export-fuel-surcharges/add-web-export-fuel-surcharges`, params);
    return data;
  } catch (error) {
    throw error;
  }
};
export const removeExportFuelSurcharge = async (obj) => {
  try {
    const { data } = await axios.delete(`/web-export-fuel-surcharges/delete-web-export-fuel-surcharges`, {
      data: obj
    });
    return data;
  } catch (error) {
    throw error;
  }
};



