import { call, put, takeLatest } from 'redux-saga/effects';
import * as ServiceService from '../../services/service-service';

import {
  getMyPricesSuccess,
} from './reducer';

//Commission

export function* getMyPrices({ payload = {} }) {
  try {
    const { Result } = yield call(ServiceService.getMyPrices, payload);
    if (Result) {
      yield put(getMyPricesSuccess({ prices: Result }));
    } else {
      yield put(getMyPricesSuccess({ prices: null }));
    }
  } catch (error) {
    yield put(getMyPricesSuccess({ prices: null }));
  }
}

export function* getDetailPice({ payload }) {
  const { data, cb } = payload;
  try {
    const { Result } = yield call(ServiceService.getDetailPrice, data);
    if (Result) {
      cb(true, Result)
    } else {
      cb(false)
    }
  } catch (error) {
    cb(false)
  }
}



export default [
  takeLatest('GET_DETAIL_PRICE_REQUEST', getDetailPice),
  takeLatest('GET_MY_PRICES_REQUEST', getMyPrices),
];
