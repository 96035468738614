import { createSelector } from 'reselect';
import { initialState } from './reducer';

const autoState = (state) => state.auto || initialState;

const selectAutos = () => createSelector(
  autoState,
  ({ autos }) => autos
);
const selectAutosing = () => createSelector(
  autoState,
  ({ autosing }) => autosing
);
const selectPagination = () => createSelector(
  autoState,
  ({ pagination }) => pagination
);

export {
  autoState,
  selectAutos,
  selectAutosing,
  selectPagination,
}
