import React from 'react';
import { compose as recompose, withHandlers, withState } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { markAllAsReadRequest, setAlwayShow } from '../../reducer/reducer';
import { Menu, Dropdown } from 'antd';
// eslint-disable-next-line
import { CheckOutlined, PicRightOutlined, LoadingOutlined } from '@ant-design/icons';

import './styles.scss';


const MenuContent = (props) => {
    // eslint-disable-next-line
    const { onClickContextMenu, forceOpen, markingAllAsRead } = props;
    return (
        <Menu className="notification-item-menu-list" onClick={(e) => onClickContextMenu(e)}>
            <Menu.Item key="MARK_ALL_AS_READ" icon={markingAllAsRead ? <LoadingOutlined /> : <CheckOutlined />}> Đánh dấu tất cả là đã xem</Menu.Item>
            {/* <Menu.Item className={(forceOpen ? 'active' : '')} key="ALWAY_SHOW" icon={<PicRightOutlined />}>Luôn mở thông báo</Menu.Item> */}
        </Menu>
    )
}

const NotificationSettings = (props) => {
    const { showMenu, setShowMenu } = props;
    return (
        <div onMouseLeave={() => setShowMenu(false)}>
            <div className="notitication-list_header">
                <div className="notitication-list_header_container">
                    <div className="notitication-list_header__title">
                        <span>THÔNG BÁO</span>
                    </div>
                    <Dropdown overlayClassName='notification-setting-menu-overlay' visible={showMenu} placement="bottomLeft" style={{ padding: 0 }} overlay={<MenuContent {...props} />}>
                        <div className="notitication-list_header__action">
                            <span onClick={() => setShowMenu(!showMenu)} className="notitication-list_header__action--button">
                                <img style={{ width: 12 }} src={require('../../assets/ellipsis.svg')} alt="more" />
                            </span>
                        </div>
                    </Dropdown>
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        markAllAsRead: params => dispatch(markAllAsReadRequest(params)),
        onSetAlwayShow: (show) => dispatch(setAlwayShow({ show }))
    };
}

const mapStateToProps = createStructuredSelector({
    forceOpen: store => store.notification && store.notification.forceOpen,
    markingAllAsRead: store => store.notification && store.notification.markingAllAsRead
});

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default recompose(
    withConnect,
    withState('showMenu', 'setShowMenu', false),
    withHandlers({
        onClickContextMenu: ({ markAllAsRead, onSetAlwayShow, forceOpen }) => ({ key }) => {
            switch (key) {
                case 'MARK_ALL_AS_READ':{
                    markAllAsRead();
                    break;
                }
                default: {
                    onSetAlwayShow(!forceOpen);
                    break;
                }
            }
        }
    })
)(NotificationSettings)