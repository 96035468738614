import { call, put, takeLatest } from 'redux-saga/effects';
import * as AutoService from '../../services/auto-service';

import { AutoFormAction } from "./action";
import { SubmissionError } from "redux-form";

import {
  fetchAutosSuccess,
  failure
} from './reducer';

export function* fetchAutos({ payload = {} }) {
  try {
    const { Result } = yield call(AutoService.getAutos, payload);
    if(Result) {
      yield put(fetchAutosSuccess({ autos: Result }));
    } else {
      yield put(fetchAutosSuccess({ autos: [] }));
    }
  } catch (error) {
    yield put(fetchAutosSuccess({ autos: [] }));
    yield put(failure(error));
  }
}

export function* saveAuto({ payload }) {
  try {
    let result = yield call(AutoService.updateAuto, payload);
    if (result) {
      yield put(AutoFormAction.success("Lưu thành công!"));
    } else {
      const { message } = "Lưu thất bại!";
      const formError = new SubmissionError({
        _error: message
      });
      yield put(AutoFormAction.failure(formError));
    }
  } catch (error) {
    const formError = new SubmissionError({
      _error: error
    });
    yield put(AutoFormAction.failure(formError));
  }
}

export default [
  takeLatest('FETCH_AUTOS_REQUEST', fetchAutos),
  takeLatest(AutoFormAction.REQUEST, saveAuto),
];
