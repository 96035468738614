import React, { useEffect } from 'react'
import $ from 'jquery';
import ReactSummernote from 'react-summernote';
import 'react-summernote/dist/react-summernote.css';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/tooltip';
import './style.scss'

const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

export default ({ disabled, value, onChange, height, codeview }) => {
    useEffect(() => {
        window.jQuery = window.$ = $;
        ReactSummernote.insertText(value || '')
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (!value) {
            ReactSummernote.reset()
        }
    }, [value])

    const onImageUpload = (files) => {
        Array.from(files).forEach(async (file) => {
            if (file.type.startsWith('image/')) {
                ReactSummernote.insertImage(await toBase64(file));
            }
        });
    }

    return (
        <ReactSummernote
            value={value}
            disabled={disabled}
            onImageUpload={onImageUpload}
            onChange={onChange}
            codeview={codeview}
            options={{
                lang: 'vi-VN',
                height: height || 350,
                dialogsInBody: true,
                fontNames: ['Roboto-Regular', 'Roboto-Medium', 'Roboto-Light', 'Open Sans', 'FuturaStd Book', 'FuturaStd Heavy', 'FuturaStd Light', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New'],
                image: [
                    ['image', ['resizeFull', 'resizeHalf', 'resizeQuarter', 'resizeNone']],
                    ['float', ['floatLeft', 'floatRight', 'floatNone']],
                    ['remove', ['removeMedia']]
                ],
                link: [
                    ['link', ['linkDialogShow', 'unlink']]
                ],
                table: [
                    ['add', ['addRowDown', 'addRowUp', 'addColLeft', 'addColRight']],
                    ['delete', ['deleteRow', 'deleteCol', 'deleteTable']],
                ],
                toolbar: [
                    ['style', ['style']],
                    ['font', ['bold', 'italic', 'underline', 'clear']],
                    ['fontname', ['fontname']],
                    ['fontsize', ['fontsize']],
                    ['color', ['color']],
                    ['para', ['ul', 'ol', 'paragraph']],
                    ['table', ['table']],
                    ['insert', ['link', 'picture', 'video']],
                    ['view', ['fullscreen', 'codeview']]
                ]
            }}
        />
    )
}