import React from 'react';
import moment from 'moment';
import { Button, Form, Modal, Spin, notification, Tooltip } from 'antd';
import { SaveOutlined, ExclamationCircleOutlined, UserOutlined } from '@ant-design/icons';
import { compose as recompose, lifecycle, withProps, withPropsOnChange, withState } from 'recompose';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { required } from '../../../helpers/validate';
import { FieldCheckBox, FieldDatePicker, FieldNumber, FieldSelect } from '../../../components/Fields';
import { getTypeServicesRequest } from '../../../reduxs/common-redux/reducer';
import { selectTypeServices } from '../../../reduxs/common-redux/selectors';
import { FORM_COMMISSION_FORM, CommissionFormAction } from '../../../reduxs/staff-redux/action';
import { staffAllRequest } from '../../../reduxs/common-redux/reducer';
import { selectStaffAlls, selectStationAllsing } from '../../../reduxs/common-redux/selectors';
import { getStaffCommissionRequest, getStaffCommissionCallback  } from '../../../reduxs/staff-redux/reducer';
import { selectCommission } from '../../../reduxs/staff-redux/selectors';

const { confirm } = Modal;

const disabledDate = (current) => {
  return current && current < moment();
}
const disabledExprid = (start) => (current) => {
  let _start = start && moment(start, 'DD-MM-YYYY').isValid() ? moment(start, 'DD-MM-YYYY') : moment();
  return current && current < moment(_start).add(1, 'day');
}

const types = [
  {value: 'PERCENT', text: "(%) / đơn hàng"},
  {value: 'CURRENCY', text: "(đ) / đơn hàng"}
]

const FormPass = (props) => {
  const { open, close, staffs, StaffCode, commissions, services, staffsing, getStaffs, AppliedDate, details, handleSubmit, error, valid, pristine, submitting } = props;
  const onSubmit = handleSubmit(CommissionFormAction);

  function showConfirm() {
    confirm({
      title: 'Bạn có chắc muốn cập nhập chiết khấu?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Có',
      cancelText: 'Không',
      onOk() {
        onSubmit();
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  let _staffs = [];
  if (staffs && staffs.length) {
    _staffs = staffs.map((item, key) => {
      return {
        value: item.StaffCode,
        text: <div className="w-100"><UserOutlined />&ensp;{item.StaffCode} - {item.FullName}</div>
      }
    });
  }

  let _commissions = []
  if(commissions && commissions.length) {
    _commissions = commissions.map(i => i.ServiceCode)
  }

  let _services = [];
  if(services && services.length) {
    _services = services.map((i) => ({
      value: i.Code,
      text: (<label className="mr-0 w-100">
              {i.Code}-{i.Description}
              { (commissions ? _commissions.includes(i.Code) : true)
                ? ''
                : <small className="pull-right">Chưa áp dụng với người duyệt</small>
              }
            </label>),
      disabled: (commissions ? !_commissions.includes(i.Code) : false)
    }))
  }

  return (
    <Modal
      title={<span>Bảng giá chiết khấu</span>}
      visible={open}
      style={{ top: 20 }}
      width={'1060px'}
      footer={null}
      maskClosable={false}
      onOk={() => { close(false) }}
      onCancel={() => { close(false) }}
      className="modal-form"
    >
      <Form layout="vertical" onFinish={() => valid ? showConfirm() : onSubmit()}>
        <div className="row">
          <div className="col-md-6">
            <label className="w-100">Nhân viên</label>
            <Field
              allowClear
              showSearch
              component={FieldSelect}
              name='StaffCode'
              options={_staffs}
              placeholder="Tìm và chọn nhân viên"
              filterOption={false}
              onSearch={(value) => getStaffs({Limit: 25, Query: value})}
              notFoundContent={staffsing ? <Spin size="small" /> : null}
              className="w-100 required"
              validate={[required]}
            />
          </div>
          <div className="col-md-6">
            <label className="w-100">Người duyệt chiết khấu</label>
            <Field
              allowClear
              showSearch
              component={FieldSelect}
              name={`OverApprover`}
              options={_staffs.filter(i => i.value !== StaffCode)}
              placeholder="Tìm và chọn người được duyệt"
              filterOption={false}
              onSearch={(value) => getStaffs({Limit: 25, Query: value})}
              notFoundContent={staffsing ? <Spin size="small" /> : null}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <label className="w-100">Dịch vụ</label>
            <Field
              allowClear
              disabled={!StaffCode}
              component={FieldSelect}
              name={`Services`}
              options={_services}
              mode="multiple"
              placeholder="Tìm và chọn dịch vụ"
              filterOption={false}
              className="w-100 required"
              validate={[required]}
            />
          </div>
          <div className="col-md-3">
            <label className="w-100">Ngày áp dụng</label>
            <Field
              showTime
              formatDate="DD-MM-YYYY HH:mm"
              component={FieldDatePicker}
              name={`AppliedDate`}
              placeholder="Chọn ngày áp dụng"
              className="w-100 required"
              disabledDate={disabledDate}
              validate={[required]}
            />
          </div>
          <div className="col-md-3">
            <label className="w-100">Ngày hết hiệu lực</label>
            <Field
              showTime
              formatDate="DD-MM-YYYY HH:mm"
              component={FieldDatePicker}
              name={`ExpiredDate`}
              placeholder="Chọn ngày hết hạn"
              disabledDate={disabledExprid(AppliedDate)}
              className="w-100"
              formatTime
            />
          </div>
        </div>
        <br/>
        <table className="table table-bordered">
          <tbody>
            {details && details.map((service, key) => {
              let place = 'Nhập số chiết khấu'
              let option = {};
              if(service && service.max) {
                place = `Bạn được duyệt đối đa ${service.max} ${service.CommissionType === 'PERCENT' ? '%' : 'VNĐ'}`
                option = { max: service.max }
              }
              return (
                <tr key={key}>
                    <td>
                      <label className="bold mr-t-5">{service.ServiceCode} - {service.Description}</label>
                    </td>
                    <td style={{width: '200px'}}>
                      <Field
                        {...option}
                        component={FieldNumber}
                        name={`Details[${key}].Commission`}
                        placeholder={place}
                        min={0}
                        mode="comma"
                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        parser={value => `${value}`.replace(/(,*)/g, '')}
                        className="w-100 required"
                        validate={[required]}
                      />
                    </td>
                    <td style={{width: '200px'}}>
                      <Field
                        {...option}
                        component={FieldNumber}
                        name={`Details[${key}].SalaryBonus`}
                        placeholder={'Mức tính lương'}
                        mode="comma"
                        min={0}
                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        parser={value => `${value}`.replace(/(,*)/g, '')}
                      />
                    </td>
                    <td style={{width: '200px'}}>
                      <Field
                        disabled={!!service.CommissionType}
                        component={FieldSelect}
                        options={types}
                        name={`Details[${key}].CommissionType`}
                        placeholder="Chọn loại chiết khấu"
                        className="w-100 required"
                        validate={[required]}
                      />
                    </td>
                    <td style={{width: '40px'}}>
                      <div style={{height: '32px', marginTop: '6px'}}>
                        <Tooltip title="Chiết khấu áp dụng tạm thời">
                          <Field component={FieldCheckBox} name={`Details[${key}].IsTemporary`} />
                        </Tooltip>
                      </div>
                    </td>
                </tr>
              )
            })}
          </tbody>
        </table>
        <div className="row mr-t-15">
          <div className="col-md-12">
            <p>Ghi chú:&ensp;<span className="note-red">Trường không được để trống</span></p>
          </div>
          <div className="col-md-12">
            <Form.Item className="text-center">
              <Button type="primary" icon={<SaveOutlined />} disabled={pristine} loading={submitting} htmlType="submit" style={{ marginLeft: 8 }}>Lưu lại</Button>
              <p className="text-danger">{error}</p>
            </Form.Item>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export function mapDispatchToProps(dispatch) {
  return {
    getCommission: (params) => dispatch(getStaffCommissionCallback(params)),
    getStaffCommission: (params) => dispatch(getStaffCommissionRequest(params)),
    getStaffs: (params) => dispatch(staffAllRequest(params)),
    getServices: (params) => dispatch(getTypeServicesRequest(params)),
  };
}
const selector = formValueSelector(FORM_COMMISSION_FORM);
const mapStateToProps = createStructuredSelector({
  commission: selectCommission(),
  staffs: selectStaffAlls(),
  staffsing: selectStationAllsing(),
  services: selectTypeServices(),
  details: (state) => selector(state, 'Details'),
  codes: (state) => selector(state, 'Services'),
  AppliedDate: (state) => selector(state, 'AppliedDate'),
  StaffCode: (state) => selector(state, 'StaffCode'),
  OverApprover: (state) => selector(state, 'OverApprover'),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const datetime = moment();
export default recompose(
  withConnect,
  withProps(
    ({ staff }) => {
      if (staff) {
        return { initialValues: { StaffCode: staff.StaffCode,  AppliedDate: datetime } };
      }
      return { initialValues: { AppliedDate: datetime }}
    }
  ),
  withState('commissions', 'setCommissions', null),
  reduxForm({
    form: FORM_COMMISSION_FORM,
    enableReinitialize: true,
    onSubmitSuccess: (result, dispatch, props) => {
      notification.success({
        message: 'Lưu thành công!'
      });
      props.initialValues = null;
      props.reset();
      props.fetchCommissions();
      props.close(false);
    }
  }),
  withPropsOnChange(['codes'], ({ codes, commissions, details, services, change }) => {
    if(codes && codes.length) {
      let _details = [];
      codes.forEach((code, key) => {
        let item = services.find(i => i.Code === code);
        let detail = [...(details || [])].find(i => i.ServiceCode === code)
        let commission = commissions && commissions.length > 0 ? commissions.find(i => i.ServiceCode === code) : null;

        if(item) {
          if(detail) {
            _details.push(detail)
          } else if(commission) {
            _details.push({
              ServiceCode: item.Code,
              Description: item.Description,
              IsTemporary: false,
              max: commission.Commission,
              bonus: commission.SalaryBonus,
              CommissionType: commission.CommissionType
            })
          } else {
            _details.push({ServiceCode: item.Code, Description: item.Description, IsTemporary: false})
          }
        }
      });
      change('Details', _details)
    } else {
      change('Details', [])
    }
  }),
  withPropsOnChange(['OverApprover'], ({ change, OverApprover, getCommission, setCommissions }) => {
    setCommissions(null);
    change('Services', []);
    if(OverApprover) {
      getCommission({
        data: { StaffCode: OverApprover },
        cb: (result) => {
          if(result && result.Activated) setCommissions(result.Activated);
        }
      })
    }
  }),
  withPropsOnChange(['StaffCode'], ({ StaffCode, change, getStaffCommission }) => {
    change('Services', [])
    if(StaffCode) {
      getStaffCommission({ StaffCode: StaffCode })
    }
  }),
  lifecycle({
    componentDidMount() {
      const { services, staff, getServices, getStaffs } = this.props;
      if(!(services && services.length)) {
        getServices({Type: 'DOMESTIC', IsAll: true });
      }
      if(staff && staff.StaffCode) {
        getStaffs({Limit: 25, Query: staff.StaffCode})
      }
    }
})
)(FormPass);
