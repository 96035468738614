import { call, put, takeLatest } from 'redux-saga/effects';
import * as partnerService from '../../services/partner-service';
import {
  partnerListSuccess,
  getCommitmentsSuccess,
  failure,
} from './reducer';

export function* fetchPartners({ payload }) {
  try {
    const { Result } = yield call(partnerService.fetchPartners, payload);
    if(Result.Data) {
      const { Data, Pagination } = Result
      yield put(partnerListSuccess({ partners: Data, pagination: Pagination }));
    } else {
      yield put(failure('Search quotes failed'));
    }
  } catch (error) {
    yield put(failure(error));
  }
}

export function* confirmBillPartner({ payload = {} }) {
  const { data, cb } = payload;
  try {
    const { Result } = yield call(partnerService.confirmBillPartner, data);
    if(Result) {
      cb('success');
    } else {
      cb('error')
    }
  } catch (error) {
    if (cb) cb('error')
  }
}

export function* getCommitments({ payload = {} }) {
  try {
    const { Result } = yield call(partnerService.getCommitments, payload);
    if(Result) {
      yield put(getCommitmentsSuccess({ commitments: Result }));
    }
  } catch (error) {
    yield put(failure(error));
  }
}



export default [
  takeLatest('PARTNER_LIST_REQUEST', fetchPartners),
  takeLatest('CONFIRM_PARTNER_BILL_REQUEST', confirmBillPartner),
  takeLatest('GET_COMMITMENTS_REQUEST', getCommitments),
];
