import { createSelector } from 'reselect';
import { initialState } from './reducer';

const roleState = (state) => state.role || initialState;

const selectRoles = () => createSelector(
  roleState,
  ({ roles }) => roles
);
const selectRolesing = () => createSelector(
  roleState,
  ({ rolesing }) => rolesing
);

const selectRoleUsers = () => createSelector(
  roleState,
  ({ roleusers }) => roleusers
);
const selectPagination = () => createSelector(
  roleState,
  ({ pagination }) => pagination
);
const selectLoading = () => createSelector(
  roleState,
  ({ roleusersing }) => roleusersing
);

export {
  roleState,
  selectRoles,
  selectRolesing,
  selectRoleUsers,
  selectPagination,
  selectLoading
}
