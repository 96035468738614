
import './style.scss';
import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Layout, Avatar, Tooltip, Button, Table, Steps, Popover, Checkbox, Select, Pagination, Input } from 'antd';
import { ReloadOutlined, SettingOutlined, StopOutlined, FileSearchOutlined, EyeOutlined, UserOutlined, RightCircleOutlined, CheckOutlined } from '@ant-design/icons';
import { Icons } from '../../assets'
import ViewDetail from './components/ViewProfile';
import ConfirmPartner from './components/ConfirmProfile';

const { Content } = Layout;
const { Step } = Steps;
// const { RangePicker } = DatePicker;

const PartnersV = (props) => {
    const { pagination, params, setParams, partners, partner, loading } = props;
    const { confirmPartner, view, setView, fetchPartners } = props;
    const { approve, confirm, setConfirm } = props;

    const size = params.Size && Number(params.Size) > 0 ? Number(params.Size) : 20;
    const offset = params.Offset && Number(params.Offset) > 0 ? Number(params.Offset) : 1;
    const total = pagination && pagination.Total && Number(pagination.Total) > 0 ? Number(pagination.Total) : 1;
    const n_start = total > 0 ? (((offset - 1) * size) + 1) : 0;
    const n_end = total > 0 ? ((offset * size) > total ? total : offset * size) : 0;

    return (
        <Content className="page-bill" id="body">
            <div className="panel-header">
                <div className="header-title">
                    <i className="fa fa-home icon" aria-hidden="true"></i>
                    <Link to="/"><span>Dashboard</span></Link>
                    <span className="icon-next"><i className="fa fa-angle-right"/></span>
                    <span className="active">Quản lý hồ sơ cộng tác viên</span>
                </div>
            </div>
            <div className="col-md-12">
                <div className="panel-body">
                  <div className="row mr-b-15">
                    <div className="col-md-12 pd-0">
                      <Steps
                        size="small"
                        type="navigation"
                        current={params.tabs || 0}
                        onChange={(value) => fetchPartners({ tabs: value })}
                        className="site-navigation-steps step-partner"
                      >
                        <Step status={1} title="HỒ SƠ MỚI TẠO" />
                        <Step status={2} title="HỒ SƠ CHỜ DUYỆT" />
                        <Step status={3} title="DUYỆT CT VIÊN"/>
                        <Step status={4} title="HỒ SƠ ĐÃ DUYỆT" className="last-grp" />
                        <Step status={0} title="TẤT CẢ HỒ SƠ" icon={<FileSearchOutlined />} className="list-all" />
                      </Steps>
                    </div>
                  </div>
                    <div className="row mr-b-10">
                      <div className="col-md-6">
                        <label className="total-filter">
                          <img src={Icons.common.filter} alt="Số hồ sơ" />|&ensp;<span>{n_start} - {n_end}</span> trong tổng <span>{total}</span> hồ sơ
                        </label>
                      </div>
                      <div className="col-md-6 text-right">
                          <span>
                            <label className="bold mr-0">Tìm kiếm:</label>&ensp;
                            <Input.Search
                              value={params.Query}
                              onChange={(e) => setParams({...params, Query: e.target.value})} 
                              onSearch={(value) => fetchPartners({Query: value})}
                              placeholder="Tìm kiếm..."
                              style={{width: 350}}
                            />
                          </span>
                          &emsp;|&emsp;
                          <Button onClick={() => fetchPartners({Offset: 1, Size: 20, StartTime: '', EndTime: '',  Keyword: ''})} icon={<ReloadOutlined />}>Làm mới</Button>&ensp;
                          <Popover placement="bottomRight" content={content(props)} trigger="click">
                              <Button icon={<SettingOutlined />}/>
                          </Popover>
                      </div>
                    </div>
                    <Table
                        bordered={false}
                        loading={loading}
                        className="table-partner"
                        columns={createColums(props)}
                        rowKey='HawbNo'
                        size="small"
                        dataSource={ partners || []}
                        scroll={{x: 1200}}
                        pagination={false}
                    />
                    <div className={params.CustomerId ? 'hidden' : "row mr-t-15 "}>
                      <div className="col-md-6">
                        <span>Hiển thị &ensp;
                          <Select value={ size || 20 } style={{ width: 120 }} onChange={(size) => fetchPartners({ Size: size, Offset: 1 }) }>
                            <Select.Option value="20">20</Select.Option>
                            <Select.Option value="50">50</Select.Option>
                            <Select.Option value="100">100</Select.Option>
                            <Select.Option value="200">200</Select.Option>
                          </Select>
                          &nbsp; hồ sơ / trang
                        </span>
                      </div>
                      <div className="col-md-6 text-right">
                        <Pagination current={offset || 1} pageSize={ size || 20 } total={total || 0} onChange={(offset) => fetchPartners({ Offset: offset })} showSizeChanger={false}/>
                      </div>
                    </div>
                </div>
            </div>

            {view && <ViewDetail open={view} close={setView} partner={partner} confirmPartner={confirmPartner} fetchPartners={fetchPartners} />}
            {confirm && <ConfirmPartner open={confirm} close={setConfirm} partner={partner} confirmPartner={confirmPartner} approve={approve} fetchPartners={fetchPartners} />}
        </Content>
    )
}

export default PartnersV

const createColums = ({ columns = [], params, setConfirm, setApprove, setPartner, setView }) => {
    const _columns = [];
    if(columns && columns.length > 0) {
      _columns.push({ title: 'THÔNG TIN CÁ NHÂN', key: 'user', width: '60%', className: '', render: (item) => {
        return (<div className="group-info-partner">
          <div className="img-avatar">
            <Avatar shape="square" icon={<UserOutlined />} size={80} src={item.Avatar} />
          </div>
          <div className="row">
            <div className="col-xs-6">
              <div className="partner-item"><label><i className="fa fa-user-circle-o"/>Họ và Tên:</label><span>{item.FullName}</span></div>
              <div className="partner-item"><label><i className="fa fa-phone"/>Số ĐT:</label><span>{item.PhoneNumber}</span></div>
              <div className="partner-item"><label><i className="fa fa-envelope-o"/>Email:</label><span>{item.Email}</span></div>
              <div className="partner-item"><label><i className="fa fa-venus-mars"/>Giới tính:</label><span>{item.Gender ? "Nam" : "Nữ"}</span></div>
            </div>
            <div className="col-xs-6">
              <div className="partner-item"><label><i className="fa fa-birthday-cake"/>Ngày sinh:</label><span>{item?.DateOfBirth ? moment(item?.DateOfBirth).format('DD-MM-YYYY'): 'N/A'}</span></div>
              <div className="partner-item"><label><i className="fa fa-id-card-o"/>CMT/CC:</label><span>{item.IdNumber}</span></div>
              <div className="partner-item"><label><i className="fa fa-map-marker"/>Địa điểm:</label><span>{item.ProvinceName} - {item.DistrictName}</span></div>
            </div>
          </div>
        </div>)
      }})

      _columns.push({ title: 'THÔNG TIN PHƯƠNG TIỆN', key: 'user', width: '40%', className: '', render: (item) => {
        return (<div className="group-info-vehicle">
          <div className="row">
            <div className="col-xs-6">
              <div className="partner-item"><label><i className="fa fa-car"/>Phương tiện:</label><span>{item.VehicleTypeName}</span></div>
              <div className="partner-item"><label><i className="fa fa-graduation-cap"/>Bằng lái xe:</label><span>{item.DriversLicenseNumber}</span></div>
              <div className="partner-item"><label><i className="fa fa-cube"/>Loại bằng:</label><span>{item.DriversLicenseClass}</span></div>
            </div>
            <div className="col-xs-6">
              <div className="partner-item"><label><i className="fa fa-gamepad "/>Biển số xe:</label><span>{item.VehicleNumberPlate}</span></div>
              <div className="partner-item"><label><i className="fa fa-adjust"/>Dòng xe:</label><span>{item.VehicleBrand}</span></div>
              <div className="partner-item"><label><i className="fa fa-calendar-o"/>Năm SX:</label><span>{item.VehicleManufacturingYear}</span></div>
            </div>
          </div>
        </div>)
      }})

      _columns.push({ title: 'Action', dataIndex: '', fixed: 'right', width: 120, key: 'IsSendLater', render: (item) => {
            return (
                <div>
                  {item.ProfileStatusId > 2 ?
                    <Tooltip title="Xem chi tiết">
                      <Button size="small" icon={<EyeOutlined />} onClick={() => setPartner(item, () => setView(true))}>Chi tiết</Button>
                    </Tooltip>
                    : <label className="bold text-warning">Đang hoàn thiện hồ sơ</label>
                  }
                  {item.ProfileStatusId === 3 &&
                    <>
                      <Tooltip title="Từ chối hồ sơ chưa đủ điều kiện">
                        <Button size="small" icon={<StopOutlined />} onClick={() => setApprove(false, () => setPartner(item, () => setConfirm(true)))} type="primary" danger>Từ chối</Button>
                      </Tooltip>
                      <Tooltip title="Xác nhận hồ sơ đã đủ điều kiện">
                        <Button size="small" icon={<RightCircleOutlined />} onClick={() => setApprove(true, () => setPartner(item, () => setConfirm(true)))} type="primary">Xác nhận</Button>
                      </Tooltip>
                    </>
                  }
                  {item.ProfileStatusId === 4 &&
                    <>
                      <Tooltip title="Từ chối hồ sơ công tác viện">
                        <Button size="small" icon={<StopOutlined />} onClick={() => setApprove(false, () => setPartner(item, () => setConfirm(true)))} type="primary" danger>Từ chối</Button>
                      </Tooltip>
                      <Tooltip title="Duyệt hồ sơ cộng tác viện">
                        <Button size="small" icon={<CheckOutlined />} onClick={() => setApprove(true, () => setPartner(item, () => setConfirm(true)))} type="primary">Duyệt CTV</Button>
                      </Tooltip>
                    </>
                  }
                </div>
            )
        }
      })
      
    }
    return _columns;
}

const content = ({columns, stateColumn}) => {
    return <div className="row" style={{maxWidth: '400px'}}>
      { columns && columns.map((col, key) => 
        <div className="col-md-6" key={key} style={{padding: '5px 15px'}}><Checkbox checked={col.show} onChange={() => stateColumn(col)} ><span className="bold">{col.name}</span></Checkbox></div>)
      }
    </div>
  }