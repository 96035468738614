
import './style.scss';
import React from 'react';
import { Layout, Table, Tabs, Checkbox, Button, Pagination, Switch } from 'antd';
import { AppstoreAddOutlined, CaretDownOutlined, CaretRightOutlined, UndoOutlined, PlusOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import moment from 'moment';
import { Icons } from '../../assets'
import RoleForm from './components/RoleForm';
import UserForm from './components/UserForm';
import RestrictPage from './../../components/RestrictComponents/RestrictPage';
import RestrictFeature from './../../components/RestrictComponents/RestrictFeature';


const { Content} = Layout;
const { TabPane } = Tabs;

const RoleV = (props) => {
    const { pagination, tabs, setTabs, roles, role, clients, params, loading, roleusersing, roleusers, fetchRoles, fetchRoleUsers } = props;
    const { openRole, closeRole,  openUser, closeUser, roleId, setRoleId } = props;
    const size = params.Size && Number(params.Size) > 0 ? Number(params.Size) : 20;
    const offset = params.Offset && Number(params.Offset) > 0 ? Number(params.Offset) : 0;
    const total = pagination && pagination.Total && Number(pagination.Total) > 0 ? Number(pagination.Total) : 0;
    const n_start = total > 0 ? (((offset - 1) * size) + 1) : 0;
    const n_end = total > 0 ? ((offset * size) > total ? total : offset * size) : 0;

    return (
        <RestrictPage>
            <Content className="page-roles">
                <div className="panel-header">
                    <div className="header-title">
                        <i className="fa fa-home icon" aria-hidden="true"></i>
                        <span>Hệ thống</span>
                        <span className="icon-next"><i className="fa fa-angle-right"/></span>
                        <span className="active">Quản lý vai trò</span>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="panel-body">
                        <div className="import-table">
                            <div className="row">
                                <div className="col-md-12">
                                    <Tabs activeKey={tabs || '1'} onChange={(key) => setTabs(key)}
                                    tabBarExtraContent={
                                        <div>
                                            <Button onClick={()=> fetchRoles()}  size={'small'} icon={<UndoOutlined />}>Làm mới</Button>&emsp;
                                            <RestrictFeature key="add-role" feature="ADD_ROLE">
                                                { ({allowed}) => <Button disabled={!allowed} onClick={() => closeRole(true)} type="primary" size={'small'} icon={<AppstoreAddOutlined />}>Thêm role</Button>}
                                            </RestrictFeature>
                                        </div>
                                    }>
                                        <TabPane tab={<><MenuUnfoldOutlined />DANH SÁCH CÁC VAI TRÒ (ROLES)</>} key="1">
                                            <Table
                                                loading={loading}
                                                bordered
                                                className="table-main table-role"
                                                columns={reactColums(props)}
                                                rowKey='Id'
                                                dataSource={ roles || []}
                                                onRow={(record) => {
                                                    return {
                                                        onClick: () => {
                                                            setRoleId(record.Id !== roleId ? record.Id : '')
                                                            if(record.Id !== roleId && clients && clients.length) {
                                                                fetchRoleUsers({ ClientId: clients[0].Id, RoleId: record.Id })
                                                            }
                                                        }
                                                    }
                                                }}
                                                expandable={{
                                                    expandedRowRender: (record) => {
                                                        if(clients && clients.length) {
                                                            return(
                                                                <div className="table-view-role">
                                                                    <Tabs activeKey={params.ClientId || '1'} onChange={(key) => fetchRoleUsers({ClientId: key})}>
                                                                        { clients && clients.map((item, key) => <TabPane tab={item.Name} key={item.Id}/>) }
                                                                    </Tabs>
                                                                    <div className="row mr-b-15">
                                                                        <div className="col-md-6">
                                                                            <label className="total-filter">
                                                                                <img src={Icons.common.filter} alt="Tài khoản" />|&ensp;<span>{n_start} - {n_end}</span> trong tổng <span>{total}</span> tài khoản
                                                                            </label>
                                                                        </div>
                                                                        <div className="col-md-6 text-right">
                                                                            <RestrictFeature key="add-user-role" feature="ADD_USER_ROLE">
                                                                                {
                                                                                    ({ allowed }) => <Button type="primary" disabled={!allowed} onClick={() => {closeUser(true, )}} icon={<PlusOutlined/>}>Thêm người dùng</Button>
                                                                                }
                                                                            </RestrictFeature>
                                                                        </div>
                                                                    </div>
                                                                    <Table
                                                                        loading={roleusersing}
                                                                        className="table-chidren"
                                                                        rowKey="HAWB_NO"
                                                                        columns={createColums(props)}
                                                                        dataSource={roleusers} size="small"
                                                                        pagination={false}
                                                                    />
                                                                    <div className={"row mr-t-15 "}>
                                                                        <div className="col-md-12 text-right">
                                                                            <Pagination current={offset || 1} pageSize={ size || 20 } total={total || 0} onChange={(offset) => fetchRoleUsers({ Offset: offset })} onShowSizeChange={(i, size) => fetchRoleUsers({Size: size})} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                        return null
                                                    },
                                                    expandRowByClick: true,
                                                    expandedRowKeys: [roleId],
                                                    expandIcon: ({ expanded, onExpand, record }) =>
                                                    expanded ? (
                                                        <CaretDownOutlined onClick={e => onExpand(record, e)} />
                                                    ) : (
                                                        <CaretRightOutlined onClick={e => onExpand(record, e)} />
                                                    )
                                                }}
                                                pagination={false}
                                                scroll={{x: true}}
                                                locale={{emptyText: 'Chưa có menu'}}
                                                rowClassName={(record) => record.Id === roleId
                                                    ? 'selected'
                                                    : ''
                                                }
                                            />
                                        </TabPane>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {openRole && <RoleForm open={openRole} close={closeRole} role={role} fetchRoles={fetchRoles}/>}
                {openUser && params.ClientId && <UserForm open={openUser} close={closeUser} RoleId={roleId} ClientId={params.ClientId} fetchRoleUsers={fetchRoleUsers}/>}
            </Content>
        </RestrictPage>
    )
}
export default RoleV

const reactColums = ({togleRoles, toggle}) => {
    const colums = [
        { title: 'Code', dataIndex: 'Code', key: 'Code', width: 150 },
        { title: 'Tên role', dataIndex: 'Name', key: 'Name'},
        { title: 'Miêu tả', dataIndex: 'Description', key: 'Description',},
        { title: 'Thời gian tạo', dataIndex: '', key: 'CreatedDate', className: "text-center", width: 150,  render: ({CreatedDate}) => {
            return CreatedDate ? moment(CreatedDate).format('DD-MM-YYYY') : 'N/A';
        } },
        { title: 'Trạng thái', dataIndex: '', key: 'IsActive', className: "text-center", width: 150,  render: (item) => {
            return <RestrictFeature key={item.Code} feature="TOGGLE_ACTIVE_ROLE">{({ allowed }) => <Switch disabled={!allowed} checkedChildren="Tắt" unCheckedChildren="Bật" defaultChecked checked={item.IsActive} loading={toggle === item.Id} onChange={(value) => togleRoles(item, value)} onClick={(item, e) => e.stopPropagation()} />}</RestrictFeature>
        } },
    ]
    return colums;
}

const createColums = ({togleRoles, toggle}) => {
    const colums = [
        { title: 'Mã tài khoản', dataIndex: 'UserCode', key: 'UserCode', width: 180, className: "text-center",},
        { title: 'Tên tài khoản', dataIndex: 'UserName', key: 'UserName', width: 200,},
        { title: 'Tên người dùng', dataIndex: 'FullName', key: 'FullName', width: 250},
        { title: 'Miêu tả', dataIndex: 'Description', key: 'Description',width: 350,},
        { title: 'Bưu cục', dataIndex: 'StationCode', key: 'StationCode', width: 150,},
        { title: 'Trạng thái', dataIndex: '', key: 'IsActive', className: "text-center", width: 150,  render: (item) => {
            return <Checkbox checked={item.IsActive}/>
        } },
    ]
    return colums;
}

