import React from 'react';
import _ from 'lodash';
import { Empty, Spin } from 'antd';
import { NumberCommas } from '../../../../../helpers/common';

const mapGroup = (prices) => {
  return _.chain(prices)
        .groupBy((item) => item.ToZone)
        .map((items, ToZone) => ({ ToZone: ToZone, Prices: _.orderBy(items, ['To'], 'asc') }))
        .value();
}

const zones = ['E1', 'E2', 'E3', 'E4', 'E5', 'E6', 'E7']

const ViewCommission = (props) => {
  const { loading, prices } = props;

  let _prices = [];
  let _priceList = [];
  if(prices && prices.length) {
    _prices = prices[0].Details.filter(i => zones.includes(i.FromZone)).map((price) => {
      let prices = mapGroup(price.Prices)
      return { ...price, Prices: prices, Titles: prices[0]?.Prices || [], }
    });
    prices[0].Details.filter(i => !zones.includes(i.FromZone)).forEach((price) => {
      _priceList.push(...price.Prices);
    })
  }
  _priceList = _.orderBy(_priceList, ['RouteName'], 'asc');

  return (
          <div>
            {_priceList && _priceList.length > 0 &&
              <table className="table table-bordered table-price">
                <thead>
                  <tr>
                    <th rowSpan={2} style={{width: '40%'}}>Tuyến</th>
                    <th rowSpan={2} style={{width: '30%'}}>Cước phí</th>
                    <th colSpan={7} style={{width: '30%'}}>Mức tính</th>
                  </tr>
                </thead>
                <tbody>
                  {_priceList.map((price) => {
                    return (
                      <tr>
                        <td style={{textAlign: 'left'}}><span className="bold">{price.RouteName}</span></td>
                        <td><span className="bold">{price.WeightStep} kg</span> đầu cước <span className="bold">{NumberCommas(price.Price, 0)}đ</span></td>
                        <td>Mỗi <span className="bold">1 kg</span> tiếp theo + <span className="bold">{NumberCommas(price.PriceOnStep, 0)}đ</span></td>
                      </tr>
                    )
                  })
                  }
                </tbody>
              </table>
            }
            <table className="table table-bordered table-price">
              <thead>
                <tr>
                  <th rowSpan={2} style={{width: '30px'}}>Vùng đi</th>
                  <th rowSpan={2} style={{width: '200px'}}>Trong lượng (kg)</th>
                  <th colSpan={7} style={{width: '800px'}}>Vùng tới</th>
                </tr>
                <tr>
                  <th style={{width: '12%'}}>E1</th>
                  <th style={{width: '12%'}}>E2</th>
                  <th style={{width: '12%'}}>E3</th>
                  <th style={{width: '12%'}}>E4</th>
                  <th style={{width: '12%'}}>E5</th>
                  <th style={{width: '12%'}}>E6</th>
                  <th style={{width: '12%'}}>E7</th>
                </tr>
              </thead>
              <tbody>
                {_prices && _prices.length > 0
                ?_prices.map((price) => {
                  let from = 0;
                  return (
                    <tr>
                      <td><span className="bold">{price.FromZone}</span></td>
                      <td className="pd-0">
                        { price.Titles.map((item) => {
                          let title = (item.To
                                      ? <label className="title-weight" style={{width: '145px'}}><span>Từ</span><span className="bold">{from} ~ {item.To}</span></label>
                                      : <label className="title-weight" style={{width: '145px'}}><span>Trên</span><span className="bold">{from}</span></label>)
                          from = item.To;
                          return (
                            <div className="group-zone-header">
                              {title}
                            </div>
                          )
                        })}
                      </td>
                      {zones.map((i) => {
                        return(
                          <td className="pd-0">
                            { price.Prices.find(j => j.ToZone === i)?.Prices.map((item, key) => {
                              return (
                                <div className="group-zone-price text-right" key={key}>
                                  {key > 0
                                  ?<label>{item.WeightStep} kg:&ensp;+{item.PriceOnStep && NumberCommas(item.PriceOnStep, 0)}đ</label>
                                  :<label className="bold">{item.WeightStep} kg đầu:&ensp;{item.PriceOnStep && NumberCommas(item.PriceOnStep, 0)}đ</label>
                                  }
                                </div>
                              )
                            })}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })
                : <tr><td colSpan={9}>{(loading ? <Spin spinning/> : <Empty description="Chưa có dữ liệu"/>)}</td></tr>
                }
              </tbody>
            </table>
          </div>
  );
};

export default ViewCommission;
