import React from 'react';
import { connect } from 'react-redux';
import { compose as recompose, withState, withPropsOnChange } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { Tooltip } from 'antd';
import { find } from 'lodash';

const RestrictFeature = ({ allowed, title, children }) => {
    let prop = {}
    if (!allowed) {
        prop.disabled = true;
    }
    const child = children({ allowed });
    if (!child) {
        return child
    }
    return (
        <Tooltip title={!allowed ? "Bạn không có quyền sử dụng chức năng này" : (title)}>
            {React.cloneElement(child, prop)}
        </Tooltip>
    )
}
export function mapDispatchToProps() {
    return {
    };
}

const mapStateToProps = createStructuredSelector({
    features: (store) => store.feature && store.feature.features
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default recompose(
    withConnect,
    withState('allowed', 'setAllowed', ({ feature, features }) => (find(features, { Code: feature }) && find(features, { Code: feature }).IsActive) || false),
    withPropsOnChange(['features'], ({ features, feature, setAllowed }) => {
        if (features && features.length && feature) {
            let allowed = (find(features, { Code: feature }) && find(features, { Code: feature }).IsActive) || false;
            setAllowed(allowed);
        }
    }),
)(RestrictFeature);