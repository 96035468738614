import React from 'react';
import { Button, Modal, Form, Spin, Tree, Select, notification } from 'antd';
import { SaveOutlined, UserOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { lifecycle, compose as recompose, withHandlers, withProps, withState, withPropsOnChange} from 'recompose';
import { reduxForm, Field } from 'redux-form';
import { FieldSelect } from '../../../components/Fields';
import { required  } from '../../../helpers/validate';

import { unflattenToTreeViewDataObject } from '../../../helpers/common';
import { USER_MENU_FORM_KEY, UserMenuFormAction } from '../../../reduxs/menu-redux/action';

import { fetchMenuGroupsRequest, fetchUserMenusRequest } from '../../../reduxs/menu-redux/reducer';
import { selectUserMenus, selectMenuGroups, selectUserMenusing} from '../../../reduxs/menu-redux/selectors';

import { staffAllRequest } from '../../../reduxs/common-redux/reducer';
import { selectStaffAlls, selectStationAllsing } from '../../../reduxs/common-redux/selectors';


const UserMenuForm = (props) => {
  const { open, close, group, groups, menus, staffs, getStaffs, staffsing, onExpand, expandKeys, setSelectedKeys, selectedKeys, checkedKeys, onChangeGroup, onSelecteMenus, reset, handleSubmit } = props;
  const submit = handleSubmit(UserMenuFormAction);

  let _menus = [];
  if(menus && menus.length) {
    _menus = unflattenToTreeViewDataObject(menus);
  }

  let _groups = [];
  if(groups && groups.length) {
    _groups = groups.map((i) => ({value: i.Id, label: i.Name}))
  }
  _groups.unshift({value: '', label: 'Tất cả menu'});

  let _staffs = [];
  if (staffs && staffs.length) {
    _staffs = staffs.map((item, key) => {
      return {
        value: item.StaffCode,
        title: `${item.StaffCode} - ${item.FullName}`,
        text: <div className="w-100"><UserOutlined />&ensp;{item.StaffCode} - {item.FullName}</div>
      }
    });
  }

  return (
    <Modal
      title={`Cập nhật menu cho người dùng`}
      visible={open}
      style={{ top: 20 }}
      width={'960px'}
      footer={null}
      maskClosable={false}
      onOk={() => { reset(); close(false) }}
      onCancel={() => { reset(); close(false) }}
      className="modal-form-menu"
    >
      <Form layout="vertical" onFinish={submit}>
        <div className="row">
          <div className="col-md-12">
            <Field
              component={FieldSelect}
              mode="multiple"
              label="Nhân viên"
              name="ToUserCode"
              options={_staffs}
              showSearch
              onSearch={(value) => getStaffs({Limit: 25, Query: value})}
              notFoundContent={staffsing ? <Spin size="small" /> : null}
              placeholder="Tìm và chọn nhân viên"
              className="required"
              validate={[required]}
            />
          </div>
        </div>
        <br/>
        <div className="row">
          <div className="col-md-12">
            <div className="box-menus">
              <div className="menu-header">
                <label className=" bold menu-label">Danh sách menu</label>
                <label className="pull-right">
                  <span>Chọn theo nhóm menu:&ensp;</span>
                  <span>
                    <Select
                      allowClear
                      showSearch
                      style={{ width: '280px' }}
                      placeholder="Mã tài khoản nhân viên"
                      options={_groups}
                      filterOption={(input, option) => {
                        if(option.label) {
                            return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      }}
                      onChange={(value) => onChangeGroup(value)}
                    />
                  </span>
                </label>
              </div>
              <Tree
                checkable
                expandedKeys={expandKeys}
                onExpand={(keys) => onExpand(keys)}
                checkedKeys={checkedKeys}
                onCheck={(keys) => onSelecteMenus(keys)}
                onSelect={(keys) => setSelectedKeys(keys)}
                selectedKeys={selectedKeys}
                treeData={_menus}
              />
            </div>
          </div>
        </div>
        <br/>
        <Form.Item className="text-center">
            <Button type="primary" icon={<SaveOutlined />} htmlType="submit">{group ? "Cập nhật" : "Lưu lại"}</Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export function mapDispatchToProps(dispatch) {
  return {
    getUserMenus: (params) => dispatch(fetchUserMenusRequest(params)),
    getMenuGroups: (params) => dispatch(fetchMenuGroupsRequest(params)),
    getStaffs: (params) => dispatch(staffAllRequest(params)),
  };
}

const mapStateToProps = createStructuredSelector({
  userMenus: selectUserMenus(),
  menusGroup: selectMenuGroups(),
  loading: selectUserMenusing(),
  staffs: selectStaffAlls(),
  staffsing: selectStationAllsing()
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default recompose(
  withConnect,
  withState('selectedKeys', 'setSelectedKeys', []),
  withState('expandKeys', 'onExpand', []),
  withState('autoExpandParent', 'setAutoExpandParent', true),
  withState('checkedKeys', 'setCheckedKeys', []),
  withProps(({ group }) => {
      if(group) {
        return { initialValues: { GroupId: [ group.Id ] }};
      }
    }
  ),
  // withState('menus', 'setMenus', mockData),
  reduxForm({
    form: USER_MENU_FORM_KEY,
    enableReinitialize: true,
    onSubmitSuccess: (result, dispatch, props) => {
        notification.success({
            message: 'Lưu thành công!'
        });
        props.initialValues = null;
        props.reset();
        props.close(false);
        props.fetchMenus();
    }
  }),
  withState('selectMenus', 'setSelectMenus', []),
  withState('action', 'setAction', false),
  withHandlers({
    onSelecteMenus: ({setCheckedKeys, change}) => (items) => {
      change('MenuId', items);
      setCheckedKeys(items)
    },
    onChangeGroup: ({ change, onExpand, setCheckedKeys, getMenuGroups }) => (value) => {
      if(value) {
        getMenuGroups({ GroupId: value });
      } else {
        setCheckedKeys([]);
        onExpand([])
        change('MenuId', []);
      }
    }
  }),
  withPropsOnChange(['menusGroup'], ({ change, onExpand, menusGroup, menus, setCheckedKeys }) => {
    if(menusGroup && menusGroup.length) {
      const keys = []
      menusGroup.forEach(menu => {
        if(menusGroup.filter(i => i.ParentId === menu.Id).length === menus.filter(i => i.ParentId === menu.Id).length) {
          keys.push(menu.Id);
        }
      });
      setCheckedKeys(keys);
      onExpand(keys)
      change('MenuId', keys);
    }
  }),
  lifecycle({
    componentDidMount() {
      const { group, user } = this.props;
      if(group) {
        this.props.getUserMenus({ClientId: group.Id, UserCode: user.staffCode});
      }
    },
  }),
)(UserMenuForm);
