
import './style.scss'
import _ from 'lodash';
import React from 'react';
import moment from 'moment';
import { Button, Select, Radio, Table, Modal, Tooltip } from 'antd';
import { UnorderedListOutlined, AppstoreOutlined, CloudUploadOutlined, FolderOutlined } from '@ant-design/icons';

const getIcon = (name) => {
  let ext = name.split('.')[name.split('.').length - 1];
      ext = ext.toLowerCase()
  let icon = 'file.svg';
  switch (ext) {
    case 'xls':
    case 'xlsx': {
      icon = 'xls.svg';
      break;
    }
    case 'doc':
    case 'docx': {
      icon = 'doc.svg';
      break;
    }
    case 'png':
    case 'jpeg':
    case 'jpg': {
      icon = 'png.svg';
      break;
    }
    case 'pdf': {
      icon = 'pdf.svg';
      break;
    }
    case 'zi': {
      icon = 'zip.svg';
      break;
    }
    default: {
      break;
    }
  }
  return `/images/icons/file-types/${icon}`
}


const CustomerDoc = (props) => {
    const { documents, categories, category, setCategory, open, setOpen, sort, setSort, view, setView, type, setType, uploading, onUpFile } = props;

    let _categories = [];
    if(categories && categories.length) {
      _categories = categories.map((item) => ({value: item.Id, label: item.Name}))
    }

    let _documents = _.orderBy([...documents],  [sort], 'desc')
    if(_documents && _documents.length) {
      _documents = _documents.filter(i => type ? i.CategoryId === type : true)
    }

    // if(loading) return <Loading width='100px' />
    return (
            <div className="box-document">
                <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-6">
                          <Button onClick={() => setOpen(true)} icon={<CloudUploadOutlined />}>Tải tài liệu lên</Button>
                          &ensp;
                          <Button icon={<FolderOutlined />} disabled>Tạo folder</Button>
                        </div>
                        <div className="col-md-6 text-right">
                          <label className="text-left">
                            <span>Hiện thị:&ensp;</span>
                            <Select
                              value={type}
                              options={[{value: '', label: 'Tất cả'}, ..._categories]}
                              style={{width: '200px'}}
                              onChange={(value) => setType(value)}
                            />
                          </label>
                        </div>
                      </div>
                      <hr/>
                      <div className="row mr-b-10">
                        <div className="col-md-6">
                          <h5 className="bold">TÀI LIỆU CỦA KHÁCH HÀNG</h5>
                        </div>
                        <div className="col-md-6 text-right">
                          <label className="text-left">
                            <span>Sắp xếp:&ensp;</span>
                            <Select
                              value={sort}
                              options={[
                                {value: 'Name', label: 'Theo tên'},
                                {value: 'Size', label: 'Theo size'},
                                {value: 'CategoryName', label: 'Theo loại'},
                                {value: 'CreatedDate', label: 'Theo ngày'},
                              ]}
                              onChange={(value) => setSort(value)}
                              style={{width: '200px'}}
                            />
                          </label>
                          &ensp;
                          <Radio.Group value={view} onChange={(e) =>setView(e.target.value)}>
                            <Radio.Button value="list"><UnorderedListOutlined /></Radio.Button>
                            <Radio.Button value="grid"><AppstoreOutlined /></Radio.Button>
                          </Radio.Group>
                        </div>
                      </div>
                      {view === 'list'
                      ?
                      <Table
                        className="table-complain table-main"
                        columns={createColums(props)}
                        rowKey='Id'
                        size="small"
                        dataSource={_documents || []}
                        pagination={false}
                        bordered={true}
                      />
                      :
                      <div className="row">
                        {_documents.map((item) => {
                          return (<div className="col-xs-6 col-md-3 col-lg-2">
                                      <Tooltip
                                        title={
                                        <div>
                                          <label className="mr-0 w-100">Tên file: {item.Name}</label>
                                          <label className="mr-0 w-100">Loại: {item.CategoryName}</label>
                                          <label className="mr-0 w-100">Kích thước: {item.SizeAsText}</label>
                                          <label className="mr-0 w-100">Ngày tạo: {moment(item.CreatedDate).format('DD-MM-YYYY | HH:mm')}</label>
                                        </div>}
                                        >
                                        <div className="item-doc">
                                          <div className="img-doc">
                                            <div className="icon-doc">
                                              <a href={item.Url} target="_blank" rel="noopener noreferrer">
                                                <img src={getIcon(item.Name)} alt="" />
                                              </a>
                                            </div>
                                          </div>
                                          <a href={item.Url} target="_blank" rel="noopener noreferrer">
                                            <label className="name-doc bold">{item.Name}</label>
                                          </a>
                                        </div>
                                      </Tooltip>
                                  </div>)
                        })}
                      </div>
                      }

                    </div>
                </div>
                <Modal
                  // title="Tải tài liệu cho khách hàng"
                  centered
                  visible={open}
                  width={'460px'}
                  footer={null}
                  mask={false}
                  maskClosable={false}
                  className="modal-main"
                  onCancel={() => setOpen(false)}
                >
                  <div className="box-upload">
                      <div className="w-100">
                        <label className="bold">Chọn loại tài liệu tải lên</label>
                        <Select
                          value={category}
                          options={_categories}
                          onChange={(value) => setCategory(value)}
                          className="w-100"
                        />
                      </div>
                      <br/>
                      <div className="w-100">
                        <Button loading={uploading} className="btn-upload upload-lg" disabled={!category}>
                          <input accept="*" className="hidden" id="fileUpload" onChange={onUpFile} type="file"/>
                          <label htmlFor="fileUpload" className="label-upload"><CloudUploadOutlined/><p>Chọn tài liệu tải lên</p></label>
                        </Button>
                      </div>
                  </div>
                </Modal>
            </div>
    )
}


const createColums = () => {
  const colums = [
    { title: 'STT', dataIndex: '', key: 'STT', width: 80, className: "text-center", render: (text, item, key) => { return key + 1 } },
    {
      title: 'Tên tài liệu', dataIndex: '', key: 'Name', render: (item) => {
        return <div className="item-image-doc">
          <a href={item.Url} target="_blank" rel="noopener noreferrer">
            <Tooltip title="Click để tải xuống">
              <img src={getIcon(item.Name)} alt="" />
              <label className="name-doc w-100 bold">{item.Name}</label>
            </Tooltip>
          </a>
        </div>
      }
    },
    { title: 'Loại', dataIndex: 'CategoryName', key: 'CategoryName'},
    {
      title: 'Ngày tải lên', dataIndex: '', key: 'CreatedDate', render: (item) => {
        return moment(item.CreatedDate).format('DD-MM-YYYY | HH:mm')
      }
    },
    { title: 'Kích thước', dataIndex: 'SizeAsText', key: 'SizeAsText'},
    // {
    //   title: 'Trạng thái', dataIndex: '', key: 'Status', className: "text-center", width: 150, render: ({ IsActive }) => {
    //     return IsActive ? <label className='bank-status'>Hoạt động</label> : <label className='bank-status stop'>Ngừng hoạt động</label>;
    //   }
    // }
  ]
  return colums;
}

export default CustomerDoc;
