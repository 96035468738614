import './style.scss';
import React from 'react';
import moment from 'moment';
import { Select, Popover, Checkbox, Pagination, Table, Button, Input } from 'antd';
import { UndoOutlined, AppstoreOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons';
import { Icons } from '../../assets';
import ViewCommission from './components/ViewCommission';
import FormCommission from './components/FormCommission';

const CommissionV = (props) => {
    const { params, commissions, loading, pagination, staff, setStaff, fetchCommissions, isMobile, openStaff, setOpenStaff } = props;
    const { openCommission, setOpenCommission } = props;
    
    const size = params.Size && Number(params.Size) > 0 ? Number(params.Size) : 20;
    const offset = params.Offset && Number(params.Offset) > 0 ? Number(params.Offset) : 1;
    const total = pagination && pagination.Total && Number(pagination.Total) > 0 ? Number(pagination.Total) : 0;
    const n_start = total > 0 ? (((offset - 1) * size) + 1) : 0;
    const n_end = total > 0 ? ((offset * size) > total ? total : offset * size) : 0;

    return (
            <div className={isMobile ? "col-md-12 pd-0" : "col-md-12"}>
                <div>
                    <div className="row">
                        <div className="col-md-4">
                            <label className="total-filter">
                                <img src={Icons.common.filter} alt="Số nhân viên" />|&ensp;<span>{n_start} - {n_end}</span> trong tổng <span>{total}</span> nhân viên
                            </label>
                        </div>
                        <div className="col-md-8 text-right">
                            <Button type="primary" onClick={() => setOpenCommission(true)} icon={<PlusOutlined />}>Thêm chiết khấu</Button>
                            &emsp;
                            <span>
                                <Input.Search defaultValue={params.Query || ''} placeholder="Tìm kiếm..." onSearch={(value) => fetchCommissions({ Query: value })} style={{ maxWidth: isMobile ? '210px' : '280px' }} />
                            </span>
                            &emsp;
                            <Button onClick={() => fetchCommissions()} title="Làm mới" icon={<UndoOutlined />}>{!isMobile && 'Làm mới'}</Button>&ensp;
                            <Popover placement="bottomRight" content={content(props)} trigger="click">
                                <Button title="Ẩn/Hiển thị cột" icon={<AppstoreOutlined />} />
                            </Popover>
                        </div>
                    </div>
                    <div className="row mr-t-10">
                        <div className="col-md-12">
                            <Table
                                bordered
                                loading={loading}
                                size="small"
                                className="table-main"
                                columns={createColums(props)}
                                rowKey={(row, key) => key}
                                dataSource={commissions || []}
                                pagination={false}
                                scroll={{ x: 800 }}
                            />
                        </div>
                    </div>
                    <div className="row mr-t-10">
                        <div className="col-md-6">
                            <span>Hiển thị &ensp;
                                <Select value={size || 20} style={{ width: 120 }} onChange={(size) => fetchCommissions({ Size: size, Offset: 1 })}>
                                    <Select.Option value="20">20</Select.Option>
                                    <Select.Option value="50">50</Select.Option>
                                    <Select.Option value="100">100</Select.Option>
                                    <Select.Option value="200">200</Select.Option>
                                </Select>
                                &nbsp; nhân viên / trang
                            </span>
                        </div>
                        <div className="col-md-6 text-right">
                            <Pagination current={offset || 1} pageSize={size || 20} total={total || 0} showSizeChanger={false} onChange={(offset) => fetchCommissions({ Offset: offset })} />
                        </div>
                    </div>
                </div>
                {openStaff && <ViewCommission open={openStaff} fetchCommissions={fetchCommissions} close={() => setStaff(null, () => setOpenStaff(false))} staff={staff} />}
                {openCommission && <FormCommission open={openCommission} fetchCommissions={fetchCommissions} close={() => setStaff(null, () => setOpenCommission(false))} staff={staff} />}
            </div>
        );
};

export default CommissionV

const createColums = ({ columns = [], onViewCommission, onCommissionStaff }) => {
    const _columns = [];
    if (columns && columns.length > 0) {
        columns.forEach((col) => {
            if (col.show) {
                if (col.type === "date") {
                    _columns.push({ title: col.name, key: col.id, width: col.width, className: col.class, render: (item) => item[col.id] ? <span><i className="fa fa-calendar-o" />&ensp;{moment(item[col.id]).format("DD-MM-YYYY")}</span> : 'N/A' })
                } else {
                    _columns.push({ title: col.name, dataIndex: col.id, key: col.id, width: col.width, className: col.class })
                }
            }
        });
        var functionColumn = {
            title: 'Chức năng', dataIndex: '', key: 'action', fixed: "right", className: "text-center", align: 'center', width: 140, render: (item) => {
                    return <div>
                        <Button icon={<PlusOutlined />} size="small" onClick={() => onCommissionStaff({StaffCode: item.Code, FullName: item.Name})}/>&ensp;
                        <Button icon={<EyeOutlined />}  size="small" onClick={() => onViewCommission(item)}>Chi tiết</Button>
                    </div>
            }
        };
        _columns.push(functionColumn);
    }
    return _columns;
}

const content = ({ columns, stateColumn }) => {
    return <div className="row" style={{ maxWidth: '400px' }}>
        {columns && columns.map((col, key) =>
            <div className="col-md-6" key={key} style={{ padding: '5px 15px' }}><Checkbox checked={col.show} onChange={() => stateColumn(col)} >{col.name}</Checkbox></div>)
        }
    </div>
}