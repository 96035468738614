import { createSelector } from 'reselect';
import { initialState } from './reducer';

const exchargeState = (state) => state.excharge || initialState;

const selectExcharges = () => createSelector(
  exchargeState,
  ({ excharges }) => excharges
);
const selectLoading = () => createSelector(
  exchargeState,
  ({ loading }) => loading
);
const selectPagination = () => createSelector(
  exchargeState,
  ({ pagination }) => pagination
);

export {
  exchargeState,
  selectExcharges,
  selectLoading,
  selectPagination,
}
