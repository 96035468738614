import { call, takeLatest } from 'redux-saga/effects';
import * as zonePuService from '../../services/zone-pickup-service';

export function* getAllPickupZone({ payload }) {
    const { params, cb } = payload;
    try {
        const { Result } = yield call(zonePuService.getAllPickupZone, params);
        cb && cb(true, Result);
    } catch (error) {
        cb && cb(false, [])
    }
}

export function* updatePickupZone({ payload }) {
    const { params, cb } = payload;
    try {
        const { Result } = yield call(zonePuService.updateAllPickupZone, params);
        cb && cb(Result);
    } catch (error) {
        cb && cb(false)
    }
}


export default [
    takeLatest('GET_ALL_PICKUP_ZONE_REQUEST', getAllPickupZone),
    takeLatest('UPDATE_PICKUP_ZONE_REQUEST', updatePickupZone)
];
