import { call, takeLatest } from 'redux-saga/effects';
import * as emailScriptionMgmtService from '../../services/email-subscription-manager-service';

export function* getSubscriptionTypes({ payload: { params, cb } }) {
    try {
        const { Result } = yield call(emailScriptionMgmtService.getSubscriptionTypes, params);
        cb && cb(true, Result)
    } catch (error) {
        cb && cb(false)
    }
}

export function* addSubscriptionTypes({ payload: { params, cb } }) {
    try {
        const { Result } = yield call(emailScriptionMgmtService.addNewType, params);
        cb && cb(Result)
    } catch (error) {
        cb && cb(false)
    }
}

export function* updateSubscriptionTypes({ payload: { params, cb } }) {
    try {
        const { Result } = yield call(emailScriptionMgmtService.updateType, params);
        cb && cb(Result)
    } catch (error) {
        cb && cb(false)
    }
}

export function* removeSubscriptionTypes({ payload: { params, cb } }) {
    try {
        const { Result } = yield call(emailScriptionMgmtService.deleteType, params);
        cb && cb(Result)
    } catch (error) {
        cb && cb(false)
    }
}

export function* getEmailSubscriptionByType({ payload: { params, cb } }) {
    try {
        const { Result } = yield call(emailScriptionMgmtService.getEmailSubscriptionByType, params);
        cb && cb(true, Result)
    } catch (error) {
        cb && cb(false)
    }
}

export function* toggleEmailSubscription({ payload: { params, cb } }) {
    try {
        const { Result } = yield call(emailScriptionMgmtService.toggleActiveEmail, params);
        cb && cb(Result)
    } catch (error) {
        cb && cb(false)
    }
}

export function* removeEmailSubscription({ payload: { params, cb } }) {
    try {
        const { Result } = yield call(emailScriptionMgmtService.removeEmailSubscription, params);
        cb && cb(Result)
    } catch (error) {
        cb && cb(false)
    }
}

export function* addEmailSubscription({ payload: { params, cb } }) {
    try {
        const { Result } = yield call(emailScriptionMgmtService.addEmailSubscription, params);
        cb && cb(Result)
    } catch (error) {
        cb && cb(false)
    }
}

export function* getUserSubscriptionByType({ payload: { params, cb } }) {
    try {
        const { Result } = yield call(emailScriptionMgmtService.getUserSubscriptionByType, params);
        cb && cb(true, Result)
    } catch (error) {
        cb && cb(false)
    }
}

export function* toggleUserSubscription({ payload: { params, cb } }) {
    try {
        const { Result } = yield call(emailScriptionMgmtService.toggleActiveUser, params);
        cb && cb(Result)
    } catch (error) {
        cb && cb(false)
    }
}

export function* removeUserSubscription({ payload: { params, cb } }) {
    try {
        const { Result } = yield call(emailScriptionMgmtService.removeUserSubscription, params);
        cb && cb(Result)
    } catch (error) {
        cb && cb(false)
    }
}

export function* addUserSubscription({ payload: { params, cb } }) {
    try {
        const { Result } = yield call(emailScriptionMgmtService.addUserSubscription, params);
        cb && cb(Result)
    } catch (error) {
        cb && cb(false)
    }
}

export default [
    takeLatest('FETCH_SUBSCRIPTION_TYPE_REQUEST', getSubscriptionTypes),
    takeLatest('ADD_SUBSCRIPTION_TYPE_REQUEST', addSubscriptionTypes),
    takeLatest('UPDATE_SUBSCRIPTION_TYPE_REQUEST', updateSubscriptionTypes),
    takeLatest('DELETE_SUBSCRIPTION_TYPE_REQUEST', removeSubscriptionTypes),
    takeLatest('GET_EMAIL_SUBSCRIPTION_BY_TYPE_REQUEST', getEmailSubscriptionByType),
    takeLatest('TOGGLE_ACTIVE_EMAIL_SUBSCRIPTION_REQUEST', toggleEmailSubscription),
    takeLatest('REMOVE_EMAIL_SUBSCRIPTION_REQUEST', removeEmailSubscription),
    takeLatest('ADD_EMAIL_SUBSCRIPTION_REQUEST', addEmailSubscription),
    takeLatest('GET_USER_SUBSCRIPTION_BY_TYPE_REQUEST', getUserSubscriptionByType),
    takeLatest('TOGGLE_ACTIVE_USER_SUBSCRIPTION_REQUEST', toggleUserSubscription),
    takeLatest('REMOVE_USER_SUBSCRIPTION_REQUEST', removeUserSubscription),
    takeLatest('ADD_USER_SUBSCRIPTION_REQUEST', addUserSubscription)
];