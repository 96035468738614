
import React from 'react';
import { Button, Table, Radio, Input, DatePicker, Select, Tooltip } from 'antd';
import { ArrowDownOutlined, RetweetOutlined, ArrowUpOutlined, MinusOutlined, CaretDownOutlined, CaretRightOutlined, CloudDownloadOutlined } from '@ant-design/icons';
import _ from 'lodash';
import moment from 'moment';
import {NumberCommas, MakeRound} from '../../../helpers/common';
import './style.scss';
import RestrictPage from './../../../components/RestrictComponents/RestrictPage';

// function disabledDate(current) {
//     return current && current >= moment().endOf('day');
// }

const onRow = (record, props, expanded) => {
    var rows = document.querySelector('[data-row-key="'+ record.SalesStaffCode +'"]');
    if (expanded) {
        if (rows)
            rows.classList.add('expand-parent')
    } else {
        if (rows)
            rows.classList.remove('expand-parent')
    }
}

const RevenueV = (props) => {
    const { params, setParams, loading, onReportRevenue, revenues, onClickDownload, downloadingRevenue } = props;
    let _revenues = []
    if(revenues && revenues.length) {
        _revenues = [ ...revenues];
        let TotalRevenue1 = 0;
        let TotalShipment1 = 0;
        let TotalRevenue2 = 0;
        let TotalShipment2 = 0;
        revenues.forEach((item) => {
            if(item && item.Revenues && item.Revenues.length) {
                TotalRevenue1  = TotalRevenue1  + (item.Revenues[0] ? (item.Revenues[0].TotalRevenue  || 0) : 0);
                TotalShipment1 = TotalShipment1 + (item.Revenues[0] ? (item.Revenues[0].TotalShipment || 0) : 0);
                TotalRevenue2  = TotalRevenue2  + (item.Revenues[1] ? (item.Revenues[1].TotalRevenue  || 0) : 0);
                TotalShipment2 = TotalShipment2 + (item.Revenues[1] ? (item.Revenues[1].TotalShipment || 0) : 0);
            }
        });
        _revenues.unshift({
            AccountNr: '',
            CustomerName: 'Tổng doanh thu',
            Revenues: [
                {
                    TotalRevenue: MakeRound(TotalRevenue1),
                    TotalShipment: TotalShipment1
                },
                {
                    TotalRevenue: MakeRound(TotalRevenue2),
                    TotalShipment: TotalShipment2
                }
            ],
        })
    }
    let attrTable = {
        loading: loading,
        columns: creatColums(props),
        dataSource: _revenues || [],
        rowKey: 'AccountNr',
        bordered: true,
        size: "small",
        rowClassName: (record) => record.AccountNr? '': 'td-total bold',
        pagination: {
            defaultPageSize: 50
        }
    }


    let Revenue1 = 0;
    let Shipment1 = 0;
    let Revenue2 = 0;
    let Shipment2 = 0;

    if(!loading && params.GroupBy === 'SALES') {
        let revenueStaffs = _.chain(revenues)
        .groupBy((item) => item.SalesStaffCode )
        .map(function(items, SalesStaffCode) {
            let TotalRevenue1 = 0;
            let TotalShipment1 = 0;
            let TotalRevenue2 = 0;
            let TotalShipment2 = 0;
            items.forEach((item) => {
                if(item && item.Revenues && item.Revenues.length) {
                    TotalRevenue1  = TotalRevenue1  + (item.Revenues[0] ? (item.Revenues[0].TotalRevenue  || 0) : 0);
                    TotalShipment1 = TotalShipment1 + (item.Revenues[0] ? (item.Revenues[0].TotalShipment || 0) : 0);
                    TotalRevenue2  = TotalRevenue2  + (item.Revenues[1] ? (item.Revenues[1].TotalRevenue  || 0) : 0);
                    TotalShipment2 = TotalShipment2 + (item.Revenues[1] ? (item.Revenues[1].TotalShipment || 0) : 0);
                }
            });
            Revenue1 += TotalRevenue1;
            Shipment1 += TotalShipment1;
            Revenue2 += TotalRevenue2;
            Shipment2 += TotalShipment2;
            return {
                SalesStaffCode: SalesStaffCode,
                SalesStaffName: items[0].SalesStaffName,
                Revenues: [
                    {
                        TotalRevenue: MakeRound(TotalRevenue1),
                        TotalShipment: TotalShipment1
                    },
                    {
                        TotalRevenue: MakeRound(TotalRevenue2),
                        TotalShipment: TotalShipment2
                    }
                ],
                revenues: items,
              }
        })
        .value();

        revenueStaffs.unshift({
            SalesStaffCode: '',
            SalesStaffName: 'Tổng doanh thu',
            Revenues: [
                {
                    TotalRevenue: MakeRound(Revenue1),
                    TotalShipment: Shipment1
                },
                {
                    TotalRevenue: MakeRound(Revenue2),
                    TotalShipment: Shipment2
                }
            ],
            revenues: [],
        })

        attrTable = {
            loading: loading,
            columns: creatColumsStaffs(props),
            dataSource: revenueStaffs || [],
            bordered: true,
            rowKey: 'SalesStaffCode',
            className:"tabel-revenue-staffs",
            size: "small",
            rowClassName: (record) => record.SalesStaffCode? '': 'td-total bold',
            pagination: {
                defaultPageSize: 50
            },
            expandable:{
                rowExpandable: record => !!record.SalesStaffCode,
                expandedRowRender: (record, index, indent, expanded) => {
                    return(
                    <div className="table-view-children">
                        <Table
                            showHeader={false}
                            columns={creatColums(props)}
                            dataSource={record.revenues || []}
                            bordered
                            rowKey={'AccountNr'}
                            pagination={false}
                            size="small"
                            onRow = {onRow(record, props, expanded)}
                        />
                    </div>
                    )
                },
                expandRowByClick: true,
                expandIcon: ({ expanded, onExpand, record }) => {
                    if(!record.SalesStaffCode) return '';
                    return expanded ? <CaretDownOutlined onClick={e => onExpand(record, e)} /> : <CaretRightOutlined onClick={e => onExpand(record, e)} />
                }

            }
        }
    }
  

    return (
        <RestrictPage>
            <section className='content-main'>
                <div className="panel-header">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="header-title">
                                <i className="fa fa-home icon" aria-hidden="true"></i>
                                <span>Báo cáo</span>
                                <span className="icon-next"><i className="fa fa-angle-right"/></span>
                                <span className="active">Báo cáo doanh thu</span>
                            </div>
                        </div>
                        <div className="col-md-8 text-right">
                            <label className="mr-0">
                                <span className="bold">Xem theo</span>&ensp;
                                <Radio.Group value={params.GroupBy} buttonStyle="solid" onChange={(e) => onReportRevenue({GroupBy: e.target.value})}>
                                    <Radio.Button value="SALES">Nhân viên</Radio.Button>
                                    <Radio.Button value="CUSTOMER">Khách hàng</Radio.Button>
                                </Radio.Group>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="panel-body">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="group-select-week">
                                    <Input.Search allowClear value={params.AccountNr || ''} placeholder="Mã khách hàng" onChange={(e) => setParams({...params, AccountNr: e.target.value})} onSearch={(value) => onReportRevenue({AccountNr : value})} style={{maxWidth: '180px'}} />&emsp;
                                    {params.GroupBy === 'SALES' &&
                                    <React.Fragment>
                                        <Input.Search allowClear value={params.SalesStaffCode || ''} placeholder="Mã nhân viên" onChange={(e) => setParams({...params, SalesStaffCode: e.target.value})} onSearch={(value) => onReportRevenue({SalesStaffCode : value})} style={{maxWidth: '180px'}} />
                                        <Select dropdownMatchSelectWidth={false} defaultValue={params.Owner} onChange={(value) => { setParams({...params, Owner: value }); onReportRevenue({ Owner: value }); }} >
                                            <Select.Option value="0">Nhóm KH theo NV bán hàng hiện tại</Select.Option>
                                            <Select.Option value="1">Nhóm KH theo NV bởi thời gian phân vùng</Select.Option>
                                        </Select>
                                    </React.Fragment>
                                    }
                                    &ensp;
                                    Tháng&ensp;
                                    <DatePicker value={params.SecondMonth}  onChange={(value) => onReportRevenue({SecondMonth: value ? value : moment()})} format="MM - YYYY" picker="month" style={{width: '120px'}} />
                                    &ensp; so với tháng&ensp;
                                    <DatePicker value={params.FirstMonth} onChange={(value) => onReportRevenue({FirstMonth: value ? value : moment().subtract(1, 'month')})} format="MM - YYYY" picker="month" style={{width: '120px'}} />
                                    &ensp;|&ensp;<Tooltip placement="leftTop" title="Làm mới"><Button onClick={() => onReportRevenue()} icon={<RetweetOutlined />}/></Tooltip>
                                    &ensp;<Tooltip placement="leftTop" title="Tải báo cáo dưới dạng excel"><Button loading={downloadingRevenue} onClick={() => {onClickDownload()}} icon={<CloudDownloadOutlined/>} /></Tooltip>
                                </div>
                            </div>
                        </div>
                        <br/>
                        <Table {...attrTable} scroll={{x: 1400, y: 'calc(100vh - 300px)'}}/>
                    </div>
                </div>
            </section>
        </RestrictPage>
    )
}
const creatColums = ({params}) => {
    const first = params.FirstMonth ? params.FirstMonth : moment().subtract('month', 1);
    let first_start = first.startOf('month').format('DD/MM/YYYY');
    let first_end = first.endOf('month') < moment() ? first.endOf('month').format('DD/MM/YYYY'): moment().format('DD/MM/YYYY');
    const secound = params.SecondMonth ? params.SecondMonth : moment();
    let secound_start = secound.startOf('month').format('DD/MM/YYYY');
    let secound_end = secound.endOf('month') < moment() ? secound.endOf('month').format('DD/MM/YYYY'): moment().format('DD/MM/YYYY');
    const columns = [
        { title: 'STT', dataIndex: 'key', key: 'key', width: 47, className:"text-center", render: (record, index, key) => params.GroupBy === 'SALES' ? (key || 0) + 1 : (key === 0 ? '' : key) },
        { title: 'Mã KH', dataIndex: 'AccountNr', key: 'AccountNr', width: 120, },
        { title: 'Tên khách hàng', dataIndex: 'CustomerName', key: 'CustomerName' },
        { title: `Từ ngày: ${first_start} đến ${first_end}`, children: [
            { title: 'Tổng vận đơn', dataIndex: '', key: 'TotalShipment', className:"text-right", width: 120, render: ({Revenues}) => {
                let shipments = Revenues && Revenues[0] && Revenues[0].TotalShipment;
                return  NumberCommas(shipments);
            }},
            { title: 'Tổng doanh thu', dataIndex: '', key: 'TotalRevenue', className:"text-right", width: 140, render: ({Revenues}) =>{
                let revenue = Revenues && Revenues[0] && Revenues[0].TotalRevenue;
                return  NumberCommas(revenue);
            }},
        ]},
        { title: `Từ ngày: ${secound_start} đến ${secound_end}`, children: [
            { title: 'Tổng vận đơn', dataIndex: '', key: 'TotalShipment', className:"text-right", width: 120, render: ({Revenues}) => {
                let shipments = Revenues && Revenues[1] && Revenues[1].TotalShipment;
                return  NumberCommas(shipments);
            }},
            { title: 'Tổng doanh thu', dataIndex: '', key: 'TotalRevenue', className:"text-right", width: 140, render: ({Revenues}) =>{
                let revenue = Revenues && Revenues[1] && Revenues[1].TotalRevenue;
                return  NumberCommas(revenue);
            }},
        ]},
        { title: 'So sánh', children: [
            { title: 'Tổng vận đơn', dataIndex: '', key: 'TotalShipment', className:"text-right", width: 120, render: ({Revenues}) => {
                let one = Revenues && Revenues[0] && Revenues[0].TotalShipment;
                let two = Revenues && Revenues[1] && Revenues[1].TotalShipment;
                let total = two - one;
                return <label>{NumberCommas(total)}&ensp;{total > 0 ? <ArrowUpOutlined style={{ color: '#3f8600' }}/> : (total === 0 ? <MinusOutlined style={{ color: '#FF9800' }}/> : <ArrowDownOutlined style={{ color: '#cf1322' }}/>)}</label>;
            }},
            { title: 'Tổng doanh thu', dataIndex: '', key: 'TotalRevenue', className:"text-right", width: 150, render: ({Revenues}) =>{
                let one = Revenues && Revenues[0] && Revenues[0].TotalRevenue;
                let two = Revenues && Revenues[1] && Revenues[1].TotalRevenue;
                let total = two - one;
                return <label>{NumberCommas(total)}&ensp;{total > 0 ? <ArrowUpOutlined style={{ color: '#3f8600' }}/> : (total === 0 ? <MinusOutlined style={{ color: '#FF9800' }}/> : <ArrowDownOutlined style={{ color: '#cf1322' }}/>)}</label>;
            }},
        ]},
    ]
    return columns;
}

const creatColumsStaffs = ({params}) => {
    const first = params.FirstMonth ? params.FirstMonth : moment().subtract('month', 1);
    let first_start = first.startOf('month').format('DD/MM/YYYY');
    let first_end = first.endOf('month') < moment() ? first.endOf('month').format('DD/MM/YYYY'): moment().format('DD/MM/YYYY');
    const secound = params.SecondMonth ? params.SecondMonth : moment();
    let secound_start = secound.startOf('month').format('DD/MM/YYYY');
    let secound_end = secound.endOf('month') < moment() ? secound.endOf('month').format('DD/MM/YYYY'): moment().format('DD/MM/YYYY');
    const columns = [
        { title: 'Mã Nhân viên', dataIndex: 'SalesStaffCode', key: 'SalesStaffCode', width: 120, },
        { title: 'Tên Nhân viên', dataIndex: 'SalesStaffName', key: 'SalesStaffName' },
        { title: `Từ ngày: ${first_start} đến ${first_end}`, children: [
            { title: 'Tổng vận đơn', dataIndex: '', key: 'TotalShipment', className:"text-right", width: 120, render: ({Revenues}) => {
                let shipments = Revenues && Revenues[0] && Revenues[0].TotalShipment;
                return  NumberCommas(shipments);
            }},
            { title: 'Tổng doanh thu', dataIndex: '', key: 'TotalRevenue', className:"text-right", width: 140, render: ({Revenues}) =>{
                let revenue = Revenues && Revenues[0] && Revenues[0].TotalRevenue;
                return  NumberCommas(revenue);
            }},
        ]},
        { title: `Từ ngày: ${secound_start} đến ${secound_end}`, children: [
            { title: 'Tổng vận đơn', dataIndex: '', key: 'TotalShipment', className:"text-right", width: 120, render: ({Revenues}) => {
                let shipments = Revenues && Revenues[1] && Revenues[1].TotalShipment;
                return  NumberCommas(shipments);
            }},
            { title: 'Tổng doanh thu', dataIndex: '', key: 'TotalRevenue', className:"text-right", width: 140, render: ({Revenues}) =>{
                let revenue = Revenues && Revenues[1] && Revenues[1].TotalRevenue;
                return  NumberCommas(revenue);
            }},
        ]},
        { title: 'So sánh', children: [
            { title: 'Tổng vận đơn', dataIndex: '', key: 'TotalShipment', className:"text-right", width: 120, render: ({Revenues}) => {
                let one = Revenues && Revenues[0] && Revenues[0].TotalShipment;
                let two = Revenues && Revenues[1] && Revenues[1].TotalShipment;
                let total = two - one;
                return <label>{NumberCommas(total)}&ensp;{total > 0 ? <ArrowUpOutlined style={{ color: '#3f8600' }}/> : (total === 0 ? <MinusOutlined style={{ color: '#FF9800' }}/> : <ArrowDownOutlined style={{ color: '#cf1322' }}/>)}</label>;
            }},
            { title: 'Tổng doanh thu', dataIndex: '', key: 'TotalRevenue', className:"text-right", width: 151, render: ({Revenues}) =>{
                let one = Revenues && Revenues[0] && Revenues[0].TotalRevenue;
                let two = Revenues && Revenues[1] && Revenues[1].TotalRevenue;
                let total = MakeRound(two - one);
                return <label>{NumberCommas(total)}&ensp;{total > 0 ? <ArrowUpOutlined style={{ color: '#3f8600' }}/> : (total === 0 ? <MinusOutlined style={{ color: '#FF9800' }}/> : <ArrowDownOutlined style={{ color: '#cf1322' }}/>)}</label>;
            }},
        ]},
    ]
    return columns;
}

export default RevenueV
