import axios from 'axios';
import { API_ENDPOINT } from '../constants';
import store from '../private-store/store';

const axiosInstance = axios.create({
});

axiosInstance.interceptors.request.use(
    (config) => {
        const env = store.getState()['private'] && store.getState()['private']['env'];
        const baseUrl = `${API_ENDPOINT[env]}`;
        config.baseURL = baseUrl;
        
        const token = store.getState() && store.getState()['private'] && store.getState()['private']['access_token'];
        config.headers.authorization = `Bearer ${token}`;
        return config;
    },
    (error) => Promise.reject(error)
);

export default axiosInstance;