import { call, put, takeLatest } from 'redux-saga/effects';
import { authenService } from '../../services';
// import Auth from '../../storage/Auth'

import {
  registerSuccess,
  registerFailure,

  loginSuccess,
  loginFailure,

  forgotSuccess,
  forgotFailure,
} from './reducer';


export function* submitPreRegister({payload}) {
  try {
    const { email } = payload;
    const { data } = yield call(authenService().preRegister, { email: email });
    if (data && data.code === 1) {
      yield put(registerSuccess());
    } else {
      let message = data ? data.message : '';
      yield put(registerFailure({message}));
    }
  } catch (error) {
    const message = error.message || 'Đăng ký user mới không thành công';
    yield put(registerFailure({message}));
  }
}

export function* submitLogin({payload}) {
  try {
    const { data } = yield call(authenService().login, payload);
    if (data && data.code === 1) {
      // Auth.authenticateUser(data.data.token);
      // Auth.setUserInfo(JSON.stringify(data.data.user));
      yield put(loginSuccess());
    } else {
      let message = data ? data.message : '';
      yield put(loginFailure({message}));
    }
  } catch (error) {
    const message = error.message || 'Email hoặc password không chính xác';
    yield put(loginFailure({message}));
  }
}

export function* submitForgot({payload}) {
  try {
    const { email } = payload;
    const { data } = yield call(authenService().forgot, { email: email });
    if (data && data.code === 1) {
      yield put(forgotSuccess());
    } else {
      let message = data ? data.message : '';
      yield put(forgotFailure({message}));
    }
  } catch (error) {
    const message = error.message || 'Gửi email không thành công';
    yield put(forgotFailure({message}));
  }
}



export default [
  takeLatest('REGISTER_REQUEST', submitPreRegister),
  takeLatest('LOGIN_REQUEST', submitLogin),
  takeLatest('FORGOT_REQUEST', submitForgot),
];
