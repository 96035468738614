import "../style.scss";
import React from "react";
import { Button, Form, notification, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import {
    compose as recompose,
    lifecycle,
    pure,
    withProps,
    withState,
} from "recompose";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Field, reduxForm } from "redux-form";
import Modal from "antd/lib/modal/Modal";
import {
    FieldInput,
    FieldSelect
} from "../../../components/Fields";
import { required } from '../../../helpers/validate';

import { selectStaffs, selectStaffsing } from "../../../reduxs/common-redux/selectors";
import { getCurrenciesRequest, staffAllRequest } from "../../../reduxs/common-redux/reducer";
import { ExchargeFormAction, EXCHARGE_FORM_KEY } from "../../../reduxs/excharge-redux/action";
import { selectCurrency, selectCurrencing } from "../../../reduxs/common-redux/selectors";
import moment from "moment";
import Auth from "../../../storage/Auth";
const StationCode = Auth.getStation();

const ExchargeForm = (props) => {
    const {
        open,
        close,
        loading,
        currencies,
        currencing,
        submitting,
        handleSubmit,
        excharge,
        pristine } = props;
    const submit = handleSubmit(ExchargeFormAction);

    let _currencies = [];
    if (currencies && currencies.length) {
        _currencies = currencies.map((item) => {
            return { value: item.Id, text: item.Code };
        });
    }

    return (
        <Modal
            title={excharge ? "Cập nhật tỷ giá " + excharge.FromCurency + " -> " + excharge.ToCurency : "Thêm mới tỷ giá"}
            closable={true}
            visible={open}
            maskClosable={false}
            width={"650px"}
            footer={null}
            className="excharge-modal"
            onOk={() => {
                close(false);
            }}
            onCancel={() => {
                close(false);
            }}
        >
            <Spin
                spinning={loading || currencing || false}
                indicator={<LoadingOutlined spin />}
                tip="Xin chờ..."
            >
                <Form layout="vertical" onFinish={submit}>
                    <div>
                        <div className="panel-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <Field
                                        component={FieldSelect}
                                        label="Từ"
                                        name="FromCurency"
                                        options={_currencies}
                                        className="w-100 required"
                                        validate={[required]}
                                        disabled={excharge}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        component={FieldSelect}
                                        label="Sang"
                                        name="ToCurency"
                                        options={_currencies}
                                        className="w-100 required"
                                        validate={[required]}
                                        disabled={excharge}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        component={FieldInput}
                                        type="number"
                                        className="w-100 required"
                                        label="Tháng áp dụng"
                                        name="AppliedMonth"
                                        placeholder="Tháng áp dụng"
                                        disabled={excharge}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        component={FieldInput}
                                        className="w-100 required"
                                        label="Năm áp dụng"
                                        name="AppliedYear"
                                        placeholder="Năm áp dụng"
                                        disabled={excharge}
                                    />
                                </div>
                                <div className="col-md-12">
                                    <Field
                                        component={FieldInput}
                                        type="number"
                                        label="Tỷ giá"
                                        name="Rate"
                                        placeholder="Tỷ giá chuyển đổi"
                                        mode='comma'
                                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                        parser={value => `${value}`.replace(/(,*)/g, '')}
                                        className="w-100 required"
                                        validate={[required]}
                                    />
                                </div>
                                <div className="col-md-12 text-right">
                                    <Button
                                        loading={submitting}
                                        disabled={pristine}
                                        type="primary"
                                        htmlType="submit"
                                        icon={<i className="fa fa-floppy-o" />}
                                    >
                                        &ensp;Lưu lại
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            </Spin>
        </Modal >
    );
};
export function mapDispatchToProps(dispatch) {
    return {
        getStaffs: (params) => dispatch(staffAllRequest(params)),
        getCurrencies: () => dispatch(getCurrenciesRequest()),
    };
}

const mapStateToProps = createStructuredSelector({
    staffs: selectStaffs(),
    isCustomerSearching: selectStaffsing(),
    currencies: selectCurrency(),
    currencing: selectCurrencing()
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default recompose(
    withConnect,
    withState('search', 'setSearchStaff', false),
    withProps(({ excharge }) => {
        if (excharge) {
            return {
                initialValues:
                    {
                        ...excharge,
                    } || null,
            };
        } else {
            return {
                initialValues: {
                    FromCurency: 'USD',
                    ToCurency: 'VND',
                    AppliedMonth: moment().format('M'),
                    AppliedYear: moment().format('Y'),
                    StationCode: StationCode,
                },
            };
        }
    }),
    reduxForm({
        form: EXCHARGE_FORM_KEY,
        enableReinitialize: true,
        onSubmitSuccess: (result, dispatch, props) => {
            notification.success({
                message: "Cập nhật dữ liệu thành công!",
            });
            props.initialValues = null;
            props.reset();
            props.fetchExcharges();
            props.close(false);
        },
    }),
    lifecycle({
        componentDidMount() {
            const { currencies, getCurrencies } = this.props;
            if (!(currencies && currencies.length)) {
                getCurrencies();
            }
        },
    }),
    pure
)(ExchargeForm);
