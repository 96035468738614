
import axios from '../utils/axiosInstance';

export const getListExcharges = async (params) => {
  try {
    const { data } = await axios.get(`/post-common-price/list-web-curency-exchange`, {
      params: params
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const saveExcharge = async (params) => {
  try {
    const { data } = await axios.post(`/post-common-price/add-web-curency-exchange`, params);
    return data;
  } catch (error) {
    throw error;
  }
};
export const removeExcharge = async (obj) => {
  try {
    const { data } = await axios.delete(`/post-common-price/delete-web-curency-exchange`, {
      data: obj
    });
    return data;
  } catch (error) {
    throw error;
  }
};





