
import axios from '../utils/axiosInstance';

export const getRoles = async (params) => {
  try {
    const { data } = await axios.get(`/admin/role`, {
      params: params
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getRoleUsers = async (params) => {
  try {
    const { data } = await axios.get(`/admin/role/user`, {
      params: params
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const addRole = async (role) => {
  try {
    const { data } = await axios.post(`/admin/role`, role);
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateRole = async (role) => {
  try {
    const { data } = await axios.put(`/admin/role`, role);
    return data;
  } catch (error) {
    throw error;
  }
};

export const addRoleUser = async (role) => {
  try {
    const { data } = await axios.post(`/admin/role/user`, role);
    return data;
  } catch (error) {
    throw error;
  }
};



export const activeRole = async (role) => {
  try {
    const { data } = await axios.put(`/admin/role`, role);
    return data;
  } catch (error) {
    throw error;
  }
};



