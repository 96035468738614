import { createAction, handleActions } from 'redux-actions';

// - Actions
export const countTaskInQueueRequest = createAction('COUNT_TASK_IN_QUEUE_REQUEST');
export const countTaskInQueueSuccess = createAction('COUNT_TASK_IN_QUEUE_SUCCESS');

export const countTaskInQueueOpRequest = createAction('COUNT_TASK_IN_QUEUE_OP_REQUEST');
export const countTaskInQueueOpSuccess = createAction('COUNT_TASK_IN_QUEUE_OP_SUCCESS');

export const countTaskInQueueCrmRequest = createAction('COUNT_TASK_IN_QUEUE_CRM_REQUEST');
export const countTaskInQueueCrmSuccess = createAction('COUNT_TASK_IN_QUEUE_CRM_SUCCESS');

export const getTaskInQueueDetailsCrmRequest = createAction('COUNT_TASK_IN_QUEUE_DETAILS_CRM_REQUEST');
export const getTaskInQueueDetailsCrmSuccess = createAction('COUNT_TASK_IN_QUEUE_DETAILS_CRM_SUCCESS');

export const getTaskInQueueDetailsOpRequest = createAction('COUNT_TASK_IN_QUEUE_DETAILS_OP_REQUEST');
export const getTaskInQueueDetailsOpSuccess = createAction('COUNT_TASK_IN_QUEUE_DETAILS_OP_SUCCESS');

export const getTaskInQueueDetailsRequest = createAction('COUNT_TASK_IN_QUEUE_DETAILS_REQUEST');
export const getTaskInQueueDetailsSuccess = createAction('COUNT_TASK_IN_QUEUE_DETAILS_SUCCESS');

// - Initial State
export const initialState = {
    fetching: true,
    taskInQueue: 0,
    taskQueueDetails: {},
    taskQueueCrmDetails: {},
    taskQueueOpDetails: {}
};

// - Reducers
const reducer = handleActions({
    [countTaskInQueueRequest]: (state) => {
        return {
            ...state,
            fetching: true
        };
    },
    [countTaskInQueueSuccess]: (state, { payload: { taskInQueue } }) => {
        return {
            ...state,
            fetching: false,
            taskInQueue: taskInQueue
        };
    },
    [countTaskInQueueOpRequest]: (state) => {
        return {
            ...state,
            fetchingOp: true
        };
    },
    [countTaskInQueueOpSuccess]: (state, { payload: { taskInQueue } }) => {
        return {
            ...state,
            fetchingOp: false,
            taskInQueueOp: taskInQueue
        };
    },
    [countTaskInQueueCrmRequest]: (state) => {
        return {
            ...state,
            fetchingCrm: true
        };
    },
    [countTaskInQueueCrmSuccess]: (state, { payload: { taskInQueue } }) => {
        return {
            ...state,
            fetchingCrm: false,
            taskInQueueCrm: taskInQueue
        };
    },
    [getTaskInQueueDetailsCrmRequest]: state => ({ ...state }),
    [getTaskInQueueDetailsCrmSuccess]: (state, { payload }) => ({ ...state, taskQueueCrmDetails: payload }),
    [getTaskInQueueDetailsOpRequest]: state => ({ ...state }),
    [getTaskInQueueDetailsOpSuccess]: (state, { payload }) => ({ ...state, taskQueueOpDetails: payload }),
    [getTaskInQueueDetailsRequest]: state => ({ ...state }),
    [getTaskInQueueDetailsSuccess]: (state, { payload }) => ({ ...state, taskQueueDetails: payload })
}, initialState);

export default reducer;