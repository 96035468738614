
import axios from '../utils/axiosInstance';

export const getMenus = async (params) => {
  try {
    const { data } = await axios.get(`/admin/menu/all`, {
      params: params
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getClients = async (params) => {
  try {
    const { data } = await axios.get(`/admin/clients`, {
      params: params
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getGroups = async (params) => {
  try {
    const { data } = await axios.get(`/admin/menu/group/all`, {
      params: params
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getMenuGroups = async (params) => {
  try {
    const { data } = await axios.get(`/admin/menu/group`, {
      params: params
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getUserMenus = async (params) => {
  try {
    const { data } = await axios.get(`/admin/menu/user`, {
      params: params
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const addMenu = async (menu) => {
  try {
    const { data } = await axios.post(`/admin/menu`, menu);
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateMenu = async (menu) => {
  try {
    const { data } = await axios.put(`/admin/menu`, menu);
    return data;
  } catch (error) {
    throw error;
  }
};

export const addGroup = async (group) => {
  try {
    const { data } = await axios.post(`/admin/menu/group`, group);
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateGroup = async (group) => {
  try {
    const { data } = await axios.put(`/admin/menu/group`, group);
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateGroupMenu = async (group) => {
  try {
    if(group.DelMenu && group.DelMenu.length) {
      await axios.delete(`/admin/menu/group/remove-menu`, {data:{ GroupId: group.GroupId, MenuId: group.DelMenu }});
    }
    const { data } = await axios.post(`/admin/menu/group/add-menu`, { GroupId: group.GroupId, MenuId: group.MenuId });
    return data;
  } catch (error) {
    throw error;
  }
};

export const saveGroupMenus = async (group) => {
  try {
    const { data } = await axios.post(`/admin/menu/group/add-menu`, { GroupId: group.GroupId, MenuId: group.MenuId });
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateUserMenu = async (group) => {
  try {
    const { data } = await axios.post(`/admin/user/replace-menu`, { ToUserCode: group.ToUserCode, MenuId: group.MenuId });
    return data;
  } catch (error) {
    throw error;
  }
};

export const removeMenus = async ({Id}) => {
  try {
    var strId = '';
    if(Id && Id.length) {
      Id.forEach((index, key) => {
        if(strId) {
          strId += `&Id[${key}]=${index}`
        } else {
          strId = `?Id[${key}]=${index}`
        }
      });
    }
    const { data } = await axios.delete(`/admin/menu${strId}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const removeUserMenu = async (usermenus) => {
  try {
    const { data } = await axios.delete(`/admin/user/remove-menu`, { data: usermenus});
    return data;
  } catch (error) {
    throw error;
  }
};

export const removeGroupMenu = async (group) => {
  try {
    const { data } = await axios.put(`/admin/menu/group/remove-menu`, group);
    return data;
  } catch (error) {
    throw error;
  }
};

