import axios from '../utils/axiosInstance';

export const getSubscriptionTypes = async (params = {}) => {
    try {
        const { data } = await axios.get('/admin/email-subscription-manager/type', { params });
        return data;
    } catch (error) {
        throw error;
    }
};

export const addNewType = async (params = {}) => {
    try {
        const { data } = await axios.post('/admin/email-subscription-manager/type', params);
        return data;
    } catch (error) {
        throw error;
    }
};

export const updateType = async (params = {}) => {
    try {
        const { data } = await axios.put('/admin/email-subscription-manager/type', params);
        return data;
    } catch (error) {
        throw error;
    }
};

export const deleteType = async (params = {}) => {
    try {
        const { data } = await axios.delete('/admin/email-subscription-manager/type', { params });
        return data;
    } catch (error) {
        throw error;
    }
};

export const getEmailSubscriptionByType = async (params = {}) => {
    try {
        const { data } = await axios.get('/admin/email-subscription-manager/email', { params });
        return data;
    } catch (error) {
        throw error;
    }
}

export const toggleActiveEmail = async (params = {}) => {
    try {
        const { data } = await axios.put('/admin/email-subscription-manager/email', params);
        return data;
    } catch (error) {
        throw error;
    }
}

export const removeEmailSubscription = async (params = {}) => {
    try {
        const { data } = await axios.delete('/admin/email-subscription-manager/email', { params });
        return data;
    } catch (error) {
        throw error;
    }
}

export const addEmailSubscription = async (params = {}) => {
    try {
        const { data } = await axios.post('/admin/email-subscription-manager/email', params);
        return data;
    } catch (error) {
        throw error;
    }
}

export const getUserSubscriptionByType = async (params = {}) => {
    try {
        const { data } = await axios.get('/admin/email-subscription-manager/user', { params });
        return data;
    } catch (error) {
        throw error;
    }
}

export const toggleActiveUser = async (params = {}) => {
    try {
        const { data } = await axios.put('/admin/email-subscription-manager/user', params);
        return data;
    } catch (error) {
        throw error;
    }
}

export const removeUserSubscription = async (params = {}) => {
    try {
        const { data } = await axios.delete('/admin/email-subscription-manager/user', { params });
        return data;
    } catch (error) {
        throw error;
    }
}

export const addUserSubscription = async (params = {}) => {
    try {
        const { data } = await axios.post('/admin/email-subscription-manager/user', params);
        return data;
    } catch (error) {
        throw error;
    }
}