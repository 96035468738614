import { createAction, handleActions } from 'redux-actions';
// - Actions

export const getMyPricesRequest = createAction('GET_MY_PRICES_REQUEST');
export const getMyPricesSuccess = createAction('GET_MY_PRICES_SUCCESS');

export const getPricesCallbackRequest = createAction('GET_PRICES_CALLBACK_REQUEST');
export const getDetailPriceRequest = createAction('GET_DETAIL_PRICE_REQUEST');

export const failure = createAction('CUSTOMER_ACTION_FAILURE');

// - Initial State
export const initialState = {
  prices: [],
  pricesing: false,
};

// - Reducers
const reducer = handleActions({

  [getMyPricesRequest]: (state) => {
    return { ...state, prices: [], pricesing: true };
  },
  [getMyPricesSuccess]: (state, { payload: { prices } }) => {
    return { ...state, prices: prices, pricesing: false };
  },

  [failure]: (state, action) => ({
    ...state,
    error: action.error,
    loading: false,
  }),
}, initialState);

export default reducer;
