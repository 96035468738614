import React from 'react';
import { Button, Modal, Form, notification } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { compose as recompose, withHandlers, withProps} from 'recompose';
import { Field, reduxForm } from 'redux-form';
import { required  } from '../../../helpers/validate';
import { FieldInput, FieldTextArea } from '../../../components/Fields';


import { ROLE_FORM_KEY, RoleFormAction } from '../../../reduxs/role-redux/action';

const RoleForm = (props) => {
  const { open, close, reset, handleSubmit, submitting, pristine } = props;
  const submit = handleSubmit(RoleFormAction);

  return (
    <Modal
      title='Thêm mới'
      visible={open}
      style={{ top: 50 }}
      width={'560px'}
      footer={null}
      maskClosable={false}
      onOk={() => { reset(); close(false) }}
      onCancel={() => { reset(); close(false) }}
      className="modal-form-menu"
    >
      <Form layout="vertical" onFinish={submit}>
        <div className="row">
          <div className="col-md-6">
            <Field
              component={FieldInput}
              label="Mã Code"
              name="Code"
              placeholder="Mã role"
              className="required"
              validate={[required]}
            />
          </div>
          <div className="col-md-6">
            <Field
              component={FieldInput}
              label="Tên role"
              name="Name"
              placeholder="Nhập tên role"
              className="required"
              validate={[required]}
            />
          </div>
          <div className="col-md-12">
            <Field
              component={FieldTextArea}
              label="Miêu tả"
              name="Description"
              placeholder="Miêu tả"
              rows={2}
            />
          </div>
        </div>
        <Form.Item className="text-center">
            <Button loading={submitting} disabled={pristine} type="primary" icon={<SaveOutlined />} htmlType="submit">Lưu</Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default recompose(
    withProps(({role}) => {
        if(role) {
          return { initialValues: { ...role }};
        } else {
          return { initialValues: null};
        }
      }
    ),
    withHandlers({
    }),
    reduxForm({
      form: ROLE_FORM_KEY,
      enableReinitialize: true,
      onSubmitSuccess: (result, dispatch, props) => {
          notification.success({
              message: 'Lưu thành công!'
          });
          props.initialValues = null;
          props.reset();
          props.close(false);
          props.fetchRoles();
      }
    })
)(RoleForm);
