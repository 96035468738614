
import React from 'react';
import {  Modal, Radio, Button, Select, notification, Checkbox, Tooltip } from 'antd';
import { compose as recompose, withHandlers, lifecycle, withState, withPropsOnChange } from 'recompose';
import { ReloadOutlined, SaveOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getValueModesRequest } from '../../../reduxs/common-redux/reducer';
import { selectValueModes } from '../../../reduxs/common-redux/selectors';
import { changeAccounTypeRequest, changeValueModeRequest } from '../../../reduxs/account-redux/reducer';
import { selectTypes } from '../../../reduxs/account-redux/selectors';

const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
};
const ZoneForm = (props) => {
    const { open, setValue, value, types, modes, valueModes, submitting, onHide, onAccountType, changeModes } = props;
    const { onSaveMode } = props;

    let type = null;
    if(types) type = types.find(i => i.Id === value);

    return (
        <Modal
            title="Thay đổi loại khách hàng"
            visible={open}
            style={{ top: 20 }}
            width={'1080px'}
            footer={null}
            maskClosable={false}
            onOk={() => onHide()}
            onCancel={() => onHide()}
            className="modal-form-zone"
            >
                <div className="row">
                    <div className="col-md-8">
                        <div className="box-form-type">
                            <h4 className="bold">Nhóm dịch vụ</h4>
                            <hr/>
                            <Radio.Group onChange={(e) => setValue(e.target.value)} value={value}>
                                {types && types.length > 0 && types.map((type, key) => <Radio key={key} style={radioStyle} value={type.Id}>{type.Name}<span style={{color: '#bbb'}}> | ({type.ServiceCode.join(' + ')})</span></Radio>)}
                            </Radio.Group>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <h4 className="bold">Mức tính cồng kềnh</h4>
                        <hr/>
                        {type && type.ServiceCode && type.ServiceCode.map((code, key) => {
                            const mode = valueModes[code];
                            const options = (modes?.AllAvailableMode || []).map(x => {
                                let _label = `${x.Name} | ${x.Description}`;
                                if (mode && mode.VolModeId === x.Id) {
                                    _label += ' (Mặc định hệ thống)';
                                }

                                if (mode && mode.CurrentModeId === x.Id) {
                                    _label += ' (Áp dụng)';
                                }
                                return {
                                    value: x.Id,
                                    label: _label
                                }
                            })
                            if(mode) {
                                return (
                                    <div className="list-mode" key={key}>
                                        <label style={{width: '50px'}} className="bold">{code}:</label>
                                        <div>
                                            <Select
                                                disabled={mode?.IgnoreVolWeight}
                                                value={mode?.CurrentModeId || ''}
                                                options={options}
                                                onChange={(value) => changeModes({ ...mode, CurrentModeId: value })}
                                                style={{width: '100px'}}
                                                dropdownMatchSelectWidth={false}
                                            />
                                            &emsp;
                                            <Tooltip title="Không tính cồng kềnh">
                                                <Checkbox checked={mode?.IgnoreVolWeight} onChange={(e) => changeModes({...mode, CurrentModeId: e.target.checked?'':mode.CurrentModeId, IgnoreVolWeight: e.target.checked})}/>
                                            </Tooltip>
                                        </div>
                                        <div>
                                            <Tooltip title="Lưu mức tính cồng kềnh">
                                                <Button onClick={() => onSaveMode(mode)} type="primary" disabled={!(mode?.CurrentModeId || mode?.IgnoreVolWeight)} icon={<SaveOutlined />}/>
                                            </Tooltip>
                                            &ensp;
                                            <Tooltip title="Đặt lại theo mặc định">
                                                <Button onClick={() => onSaveMode(mode, true)} icon={<ReloadOutlined />}/>
                                            </Tooltip>
                                        </div>
                                    </div>
                                )
                            }
                            return '';
                        })}
                    </div>
                </div>
                <hr/>
                <div className="row">
                    <div className="col-md-12">
                        <div className="w-100 mr-t-15 text-center">
                            <Button type="primary" onClick={() =>onAccountType()} loading={submitting} disabled={!value} icon={<SaveOutlined />}>Thay đổi</Button>
                        </div>
                    </div>

                </div>
        </Modal>
    )
}

function mapDispatchToProps(dispatch) {
    return {
        changeAccountType: (params) => dispatch(changeAccounTypeRequest(params)),
        changeValueMode: (params) => dispatch(changeValueModeRequest(params)),
        getValueModes: (params) => dispatch(getValueModesRequest(params)),
    };
}
const mapStateToProps = createStructuredSelector({
    types: selectTypes(),
    modes: selectValueModes(),
  });
  
const withConnect = connect(mapStateToProps, mapDispatchToProps);
  
export default recompose(
    withConnect,
    withState('value', 'setValue', ''),
    withState('submitting', 'setSubmitting', false),
    withState('valueModes', 'setValueModes', {}),
    withHandlers({
        onAccountType: ({ value, account, setValue, close, setSubmitting, fetchAccounts, changeAccountType }) => () => {
            if(value && account && account.Id) {
                setSubmitting(true);
                changeAccountType({
                    data: {
                        AccountTypeId: value,
                        CustomerId: account.Id,
                    },
                    cb: (type, message) => {
                        setSubmitting(false);
                        if(type === 'error'){
                            notification.error({message: message || 'Thay đổi loại tài khoản thất bại!'});
                        } else {
                            notification.success({message: 'Thay đổi loại tài khoản thành công!'});
                            fetchAccounts();
                            setValue(null, () => close(false))
                        }
                    }
                })
            }
        },
        onSaveMode: ({ account, setSubmitting, getValueModes, changeValueMode }) => (mode, reset) => {
            if(mode && account && account.AccountNr) {
                setSubmitting(true);
                let data = {
                    "AccountNo": account.AccountNr,
                    "IgnoreVolWeight": mode.IgnoreVolWeight,
                    "ServiceCode": mode.ServiceCode,
                    "VolModeId": mode.CurrentModeId
                }
                if(reset) data['Reset'] = true;

                changeValueMode({
                    data,
                    cb: (type, message) => {
                        setSubmitting(false);
                        if(type === 'error'){
                            notification.error({message: message || 'Thay đổi thất bại!'});
                        } else {
                            notification.success({message: 'Thay đổi khoản thành công!'});
                            getValueModes({AccountNo: account.AccountNr});
                        }
                    }
                })
            }
        },
        onHide: ({setValue, close}) => () => {
            setValue(null, () => close(false))
        },
        changeModes: ({ valueModes, setValueModes }) => (option) => {
            let _valueModes = { ...valueModes }
                _valueModes[option.ServiceCode] = option;

            setValueModes(_valueModes);
        }
    }),
    withPropsOnChange(['modes'], ({ modes, setValueModes }) => {
        const valueModes = {};
        if(modes && modes.Details.length) {
            modes.Details.forEach(item => {
                valueModes[item.ServiceCode] = item;
            });
        }
        setValueModes(valueModes);

    }),
    lifecycle({
        componentDidMount() {
            const { account, setValue, getValueModes } = this.props;
            getValueModes({AccountNo: account.AccountNr});
            if(account && account.AccountTypeId) setValue(account.AccountTypeId)
        },
    }),
)(ZoneForm);
