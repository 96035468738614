import React from 'react';
import { Icons } from '../../assets';
import { Layout, Select, Popover, Checkbox, Pagination, Table, Button, Popconfirm } from 'antd';
import { AppstoreAddOutlined, UndoOutlined, AppstoreOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import DepartmentForm from './components/DepartmentForm';
import RestrictPage from './../../components/RestrictComponents/RestrictPage';
const { Content } = Layout;

const DepartmentV = (props) => {
    const { pagination, params, departments, department, isMobile, loading } = props;
    const { fetchDepartments, editDepartment, exportExcelDepartment } = props;
    const { open, close } = props;
    const size = params.Size && Number(params.Size) > 0 ? Number(params.Size) : 20;
    const offset = params.Offset && Number(params.Offset) > 0 ? Number(params.Offset) : 1;
    const total = pagination && pagination.Total && Number(pagination.Total) > 0 ? Number(pagination.Total) : 0;
    const n_start = total > 0 ? (((offset - 1) * size) + 1) : 0;
    const n_end = total > 0 ? ((offset * size) > total ? total : offset * size) : 0;
    return (
        <RestrictPage>
            <Content className="page-menus">
                <div className="panel-header">
                    <div className="header-title">
                        <i className="fa fa-home icon" aria-hidden="true"></i>
                        <span>Hệ thống</span>
                        <span className="icon-next"><i className="fa fa-angle-right"></i></span>
                        <span className="active">Quản lý phòng ban</span>
                    </div>
                </div>
                <div className={isMobile ? "col-md-12 pd-0" : "col-md-12"}>
                    <div className="panel-body">
                        <div className="row">
                            <div className="col-md-6">
                                <label className="total-filter">
                                    <img src={Icons.common.filter} alt="Số phòng ban" />|&ensp;<span>{n_start} - {n_end}</span> trong tổng <span>{total}</span> phòng ban
                                </label>
                            </div>
                            <div className="col-md-6 text-right">
                                <Button onClick={() => editDepartment(null)} type="primary" title="Thêm phòng ban" size={'small'} icon={<AppstoreAddOutlined />}>{!isMobile &&  'Thêm phòng ban'}</Button>&ensp;
                                <Button icon={<img src={Icons.common.excel} alt="" />} size={'small'} title="Xuất excel danh sách phòng ban" onClick={exportExcelDepartment} type="primary">{!isMobile &&  'Xuất excel'}</Button>&emsp;
                                <Button onClick={() => fetchDepartments()} title="Làm mới" size={'small'} icon={<UndoOutlined />}>{!isMobile &&  'Làm mới'}</Button>&ensp;
                                <Popover placement="bottomRight" content={content(props)} trigger="click">
                                        <Button size={'small'} title="Ẩn/Hiển thị cột" icon={<AppstoreOutlined />} />
                                </Popover>
                            </div>
                        </div>
                        <div className="row mr-t-10">
                            <div className="col-md-12">
                                <Table
                                    loading={loading}
                                    className="table-main"
                                    columns={createColums(props)}
                                    rowKey="Id"
                                    size="small"
                                    dataSource={departments || []}
                                    pagination={false}
                                    bordered={true}
                                    scroll={{x: 1200}}
                                />
                            </div>
                        </div>
                        <div className="row mr-t-10">
                            <div className="col-md-6">
                                <span>Hiển thị &ensp;
                                    <Select value={size || 20} style={{ width: 120 }} onChange={(size) => fetchDepartments({ Size: size, Offset: 1 })}>
                                        <Select.Option value="20">20</Select.Option>
                                        <Select.Option value="50">50</Select.Option>
                                        <Select.Option value="100">100</Select.Option>
                                        <Select.Option value="200">200</Select.Option>
                                    </Select>
                                    &nbsp; phòng ban / trang
                                </span>
                            </div>
                            <div className="col-md-6 text-right">
                                <Pagination current={offset || 1} pageSize={size || 20} total={total || 0} onChange={(offset) => fetchDepartments({ Offset: offset })} showSizeChanger={false} />
                            </div>
                        </div>
                    </div>
                </div>
                {open && <DepartmentForm open={open} close={close} department={department} fetchDepartments={fetchDepartments} />}
            </Content>
        </RestrictPage>
    );
};

export default DepartmentV

// Tạo ra danh sách cột trên bảng
const createColums = ({ columns = [], editDepartment, deleteDepartment }) => {
    const _columns = [];
    if (columns && columns.length > 0) {
        columns.forEach((col) => {
            if (col.show) {
                if (col.id !== 'IsActive') {
                    _columns.push({ title: col.name, dataIndex: col.id, key: col.id, width: col.width, className: col.class });
                }
                else {
                    _columns.push(
                        {
                            title: 'Trạng thái', dataIndex: '', key: 'IsActive', className: "text-center", align: 'center', width: 50, render: (item) => {
                                return <Checkbox checked={item.IsActive} />
                            }
                        })
                }
            }
        });
        var functionColumn = {
            title: 'Chức năng', dataIndex: '', key: 'action', className: "text-center", align: 'center', width: 50, render: (department) => {
                return <div>
                    <Button icon={<EditOutlined />} size="small" title="Chi tiết phòng ban" type="primary" onClick={() => editDepartment(department)}></Button>&ensp;
                    <Popconfirm title="Bạn có chắc muốn xóa?" onConfirm={() => deleteDepartment(department)} placement="topRight" okText="Xóa" cancelText="Không">
                        <Button icon={<DeleteOutlined />} title="Xóa phòng ban" size="small" type="danger" />
                    </Popconfirm>
                </div>
            }
        };
        _columns.push(functionColumn);
    }
    return _columns;
}

const content = ({ columns, stateColumn }) => {
    return <div className="row" style={{ maxWidth: '400px' }}>
        {columns && columns.map((col, key) =>
            <div className="col-md-6" key={key} style={{ padding: '5px 15px' }}><Checkbox checked={col.show} onChange={() => stateColumn(col)} >{col.name}</Checkbox></div>)
        }
    </div>
}