import { call, put, takeLatest } from 'redux-saga/effects';
import * as AssetAdjustmentServices from '../../services/asset-adjustment-service';
import moment from 'moment';
import { AssetAdjustmentFormAction } from "./action";
import { SubmissionError } from "redux-form";

import {
  assetAdjustmentsSuccess,
  getAssetAdjustmentSuccess
} from './reducer';


export function* fetchAssetAdjustments({ payload }) {
  try {
    const { Result } = yield call(AssetAdjustmentServices.fetchAssetAdjustments, payload);
    if(Result.Data) {
      const { Data, Pagination } = Result
      yield put(assetAdjustmentsSuccess({ adjustments: Data, pagination: Pagination }));
    } else {
      yield put(assetAdjustmentsSuccess({ adjustments: [], pagination: { Page: 1,Size: 50, Total: 0} }));
    }
  } catch (error) {
    yield put(assetAdjustmentsSuccess({ adjustments: [], pagination: { Page: 1,Size: 50, Total: 0} }));
  }
}

export function* getAssetAdjustment({ payload }) {
  try {
    const { Result } = yield call(AssetAdjustmentServices.getAssetAdjustment, payload);
    if(Result) {
      yield put(getAssetAdjustmentSuccess({ adjustment: Result }));
    } else {
      yield put(getAssetAdjustmentSuccess({ adjustment: null }));
    }
  } catch (error) {
    yield put(getAssetAdjustmentSuccess({ adjustment: null }));
  }
}

export function* deleteAssetAdjustment({ payload: { data, cb } }) {
  try {
    const { Result } = yield call(AssetAdjustmentServices.deleteAssetAdjustment, data);
    if(Result) {
      cb(true)
    } else {
      cb(false)
    }
  } catch (error) {
    cb(false)
  }
}

export function* saveAssetAdjustment({ payload }) {
  try {
    if(payload.CREATED_DATE) payload.CREATED_DATE = moment(payload.CREATED_DATE).format('YYYY-MM-DD')
    if(payload.ADJUSTED_DATE) payload.ADJUSTED_DATE = moment(payload.ADJUSTED_DATE).format('YYYY-MM-DD')

    const { Result } = yield call(AssetAdjustmentServices.saveAssetAdjustment, payload);
    if (Result) {
      yield put(AssetAdjustmentFormAction.success(Result));
    } else {
      const formError = new SubmissionError({
        _error: 'Tạo phiếu bàn giao thất bại!'
      });
      yield put(AssetAdjustmentFormAction.failure(formError));
    }
  } catch (error) {
    let _message = "Tạo phiếu bàn giao thất bại!";
    if(error && typeof error.Description === 'string') {
      _message = error.Description;
    }
    const formError = new SubmissionError({_error: _message});
    yield put(AssetAdjustmentFormAction.failure(formError));
  }
}

export function* confirmAssetAdjustment({ payload: { data, cb } }) {
  try {
    const Result  = yield call(AssetAdjustmentServices.confirmAssetAdjustment, data);
    if(Result) {
      cb(true)
    } else {
      cb(false)
    }
  } catch (error) {
    cb(false)
  }
}

export function* printAssetAdjustment({ payload: { data, cb } }) {
  try {
    const { Result }  = yield call(AssetAdjustmentServices.printAssetAdjustment, data);
    if(Result) {
      cb(true)
    } else {
      cb(false)
    }
  } catch (error) {
    cb(false)
  }
}

export default [
  takeLatest('ASSET_ADJUSTMENT_LIST_REQUEST', fetchAssetAdjustments),
  takeLatest('ASSET_ADJUSTMENT_REQUEST', getAssetAdjustment),
  takeLatest('ASSET_DEL_ADJUSTMENT_REQUEST', deleteAssetAdjustment),
  takeLatest('ASSET_ADJUSTMENT_CONFIRM_REQUEST', confirmAssetAdjustment),
  takeLatest('ASSET_ADJUSTMENT_PRINT_REQUEST', printAssetAdjustment),
  takeLatest(AssetAdjustmentFormAction.REQUEST, saveAssetAdjustment),
];
