import './style.scss';
import React from 'react';
import { Layout, Menu } from 'antd';
import { lifecycle, compose as recompose, withHandlers, withState} from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getMyMenuRequest } from './../../reduxs/user-menu-redux/reducer'
import { RenderMenu } from './slider-helper';
import { Icons } from './../../assets';
import { Link } from 'react-router-dom';
import useSize from '../SizeScreen'

const { Sider } = Layout;

const style={
    overflow: 'auto',
    height: '100vh',
    position: 'fixed',
    left: 0,
    top: '0px',
    zIndex: 99,
  }

const Siders = (props) => {
    const { toggle, location: { pathname }, myMenu } = props;

    let keys = ['1']
    if(pathname.includes('/plan'))  {
        keys = ['2']
    } else if(pathname.includes('/statistics'))  {
        keys = ['3']
    } else if(pathname.includes('/mail'))  {
        keys = ['4']
    } else if(pathname.includes('/articles'))  {
        keys = ['5']
    } else if(pathname.includes('/profile'))  {
        keys = ['6']
    }
    const size = useSize();

    return (
        <Sider style={style} className={toggle ? "sider-admin open" : "sider-admin"}>
            <Menu mode={size && size.width < 768 ? "inline" : "vertical"} className="menu-siders" key={keys}>
                <div className="sys-logo text-center">
                    <Link to="/">
                        <img alt="" src={Icons.sys} />
                    </Link>
                </div>
                {
                    RenderMenu(myMenu)
                }
            </Menu>
        </Sider>
    );
}
export function mapDispatchToProps(dispatch) {
    return {
        fetchMyMenu: () => dispatch(getMyMenuRequest())
    };
  }
  
  const mapStateToProps = createStructuredSelector({
    user: (state) => state.oidc && state.oidc.user,
    myMenu: (state) => state.userMenu && state.userMenu.myMenu
  });
  
  const withConnect = connect(mapStateToProps, mapDispatchToProps);
  
  export default recompose(
    withConnect,
    withState('action', 'setAction', false),
    withHandlers({
        goBack: ({ history }) => () => {
            history.goBack();
        },
        touchScreen: ({ action, setAction }) => () => {
            if(action) {
                setAction(false)
            }
        },
    }),
    lifecycle({
      componentDidMount() {
          this.props.fetchMyMenu()
      },
    }),
  )(Siders);