import { createAction, handleActions } from 'redux-actions';
// - Actions
export const reportRevenueRequest = createAction('REPORT_REVENUE_REQUEST');
export const reportRevenueSuccess = createAction('REPORT_REVENUE_SUCCESS');


export const failure = createAction('REPORT_ACTION_FAILURE');


// - Initial State
export const initialState = {
  revenues: [],
  revenuesing: false,
  error: null,
};

// - Reducers
const reducer = handleActions({
  [reportRevenueRequest]: (state) => {
    return { ...state, revenues: [], revenuesing: true };
  },
  [reportRevenueSuccess]: (state, {payload: { revenues }}) => {
    return { ...state, revenues, revenuesing: false  };
  },

  [failure]: (state, action) => ({
    ...state,
    error: action.error,
    revenuesing: false,
  }),
}, initialState);

export default reducer;
