import './style.scss';
import React from 'react';
import CustomerInfoSheetForm from './CustomerInfoSheetForm';
import { Card, Table, Modal, Select, Pagination, Button, Input, Checkbox } from 'antd';
import { EditOutlined, ReloadOutlined, DownloadOutlined } from '@ant-design/icons';
import { Icons } from '../../assets';

const CustomerInfoSheetV = (props) => {
    const { dataSource, sheet, params, setParams, onFetchData, pagination, fetching, setSheet, open, setOpen } = props;
    const size = params.Size && Number(params.Size) > 0 ? Number(params.Size) : 25;
    const offset = params.Offset && Number(params.Offset) > 0 ? Number(params.Offset) : 1;
    const total = pagination && pagination.Total && Number(pagination.Total) > 0 ? Number(pagination.Total) : 0;
    const n_start = pagination && pagination.Total && pagination.Total > 0 ? (((offset - 1) * size) + 1) : 0;
    const n_end = pagination && pagination.Total && pagination.Total > 0 ? ((offset * size) > total ? total : offset * size) : 0;

    return (
        <section className='content-main'>
            <header className='panel-header'>
                <div className='title-main'>
                    <div className="col-md-6">
                        <div className='header-title'>
                            <i className="fa fa-home icon" />
                            <span>Tiện ích</span>
                            <span className="icon-next"><i className="fa fa-angle-right" /></span>
                            <span className="active">Phiếu thông tin khách hàng</span>
                        </div>
                    </div>
                    {/* <div className="col-md-6 text-right">
                      <div className='btn-group-action'>
                        <Button type="primary" onClick={() => setSheet(null, () => setOpen(true))}><i className="fa fa-plus" />&ensp;Thêm mới</Button>
                      </div>
                    </div> */}
                </div>
            </header>
            <div className="col-md-12 box-content customer-info-sheet">
                <Card>
                    <div className="row mr-b-10">
                        <div className="col-md-5">
                            <label className="total-filter">
                                <img src={Icons.common.filter} alt="Phiếu khách hàng" />|&ensp;<span>{n_start} - {n_end}</span> trong tổng <span>{total}</span> phiếu
                            </label>
                        </div>
                        <div className="col-md-7 group-primary text-right">
                            <span>
                                <Checkbox checked={params.IncompleteOnly || false} onChange={(e) => onFetchData({ IncompleteOnly: e.target.checked })}>Chỉ hiển thị phiếu chưa duyệt xong</Checkbox>
                                &emsp;
                                <Input.Search
                                    value={params.Query || ''}
                                    placeholder="Tìm kiếm..."
                                    onChange={(e) => setParams({ Query: e.target.value })}
                                    onSearch={(value) => onFetchData({ Query: value })}
                                    style={{ width: '250px' }}
                                />
                            </span>&emsp;|&emsp;
                            <Button onClick={() => onFetchData()} icon={<ReloadOutlined />}>Làm mới</Button>&ensp;
                        </div>
                    </div>
                    <Table
                        bordered
                        rowKey="Id"
                        size="small"
                        className="table-main"
                        key="customer-info-sheet"
                        dataSource={dataSource}
                        columns={createColumnList(props)}
                        pagination={false}
                        loading={fetching}
                    />
                    <div className="row mr-t-15">
                        <div className="col-md-6">
                            <span>Hiển thị &ensp;
                                <Select value={size || 25} style={{ width: 120 }} onChange={(size) => onFetchData({ Size: size })}>
                                    <Select.Option value="20">20</Select.Option>
                                    <Select.Option value="50">50</Select.Option>
                                    <Select.Option value="100">100</Select.Option>
                                    <Select.Option value="200">200</Select.Option>
                                </Select>
                                &nbsp; phiếu / trang
                            </span>
                        </div>
                        <div className="col-md-6 text-right">
                            <Pagination current={offset} pageSize={size || 25} total={total} onChange={(offset) => onFetchData({ Offset: offset })} showSizeChanger={false} />
                        </div>
                    </div>
                </Card>
            </div>
            <Modal
                visible={open}
                style={{ top: 20 }}
                width={'210mm'}
                footer={null}
                maskClosable={false}
                onOk={() => setSheet(null, () => setOpen(false))}
                onCancel={() => setSheet(null, () => setOpen(false))}
                className="modal-sheet"
            >
                {open && sheet && <CustomerInfoSheetForm sheet={sheet} refresh={() => onFetchData()} close={() => setSheet(null, () => setOpen(false))} />}
            </Modal>
        </section>
    )
}

const createColumnList = ({ setSheet, setOpen, isDownloading, onInfomationDownload, onConfirmDelete }) => {
    return [
        {
            title: 'STT', key: "stt", dataIndex: '', width: 40, render: (value, rowData, index) => {
                return `${(index + 1)}`
            }
        },
        { title: 'Mã KH', key: "AccountNo", dataIndex: 'AccountNo', className: "bold", width: '120px' },
        { title: 'Tên khách hàng', key: "CustomerName", dataIndex: 'CustomerName', className: "bold text-uppercase" },
        {
            title: 'Số điện thoại', key: "PhoneNumber", dataIndex: 'PhoneNumber', render: (data) => {
                return (data || []).join('; ');
            }
        },
        {
            title: 'Địa chỉ lấy hàng', key: "WarehouseAddress", dataIndex: 'WarehouseAddress', render: (data) => {
                return (data || []).join('; ');
            }
        },
        { title: 'Đơn vị', key: "StationCode", dataIndex: 'StationCode', width: '120px' },
        { title: 'Loại khách hàng', key: 'CustomerType', dataIndex: 'CustomerType' },
        {
            title: 'Trạng thái xác nhận', key: 'Approval', width: 180, render: ({ Approval }) => {
                const signature = {}
                if (Approval && Approval.length) {
                    Approval.forEach((item) => {
                        signature[item.Type] = item;
                    })
                }
                return (<div className="status-signature">
                    <small><label>Giám đốc:</label>{signature.GD ? <span className="text-success">Đã xác nhận</span> : <span className="text-danger">Chưa xác nhận</span>}</small>
                    <small><label>P. TCKT:</label>{signature.KT ? <span className="text-success">Đã xác nhận</span> : <span className="text-danger">Chưa xác nhận</span>}</small>
                    <small><label>TP KD:</label>{signature.KD ? <span className="text-success">Đã xác nhận</span> : <span className="text-danger">Chưa xác nhận</span>}</small>
                </div>)
            }
        },
        {
            title: 'Thao tác', width: 120, key: "action", render: (data) => {
                return (
                    <div style={{ display: 'block', 'justifyContent': 'space-around' }} className="customer-info-sheet-actions">
                        <Button block type="primary" className="btn-signature" icon={<EditOutlined />} onClick={() => setSheet(data, () => setOpen(true))}>Xem và xác nhận</Button>
                        {/* <Button size="small" type="" icon={<DeleteOutlined />} danger onClick={() => onConfirmDelete(data.Id)} /> */}
                        <Button block type="primary" className="btn-signature" icon={<DownloadOutlined />} onClick={() => onInfomationDownload({ Id: data.Id })} >Tải phiếu thông tin</Button>
                    </div>
                )
            }
        }
    ]
}

export default CustomerInfoSheetV;
