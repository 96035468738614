import React from 'react';
import moment from 'moment';
import { Button, Table, Modal, Collapse, Popconfirm, message, Empty, Spin } from 'antd';
import { CaretRightOutlined, DeleteOutlined, StopOutlined } from '@ant-design/icons';
import { compose as recompose, withHandlers, withState } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getStaffCommissionRequest, deleteCommissionRequest, activeCommissionRequest  } from '../../../reduxs/staff-redux/reducer';
import { selectCommission, selectCommissioning, } from '../../../reduxs/staff-redux/selectors';
import FormCommission from './EditCommission';
import { NumberCommas } from '../../../helpers/common';

const { Panel } = Collapse;

const createColums = ({ onAcitve, onDelete}, act, del) => {
  return [
    { title: 'Dịch vụ', key: 'ServiceCode', width: 100, className: '', render: (item) => item.ServiceCode },
    { title: 'Chiết khấu', key: 'Commission', width: 90, className: 'text-right', render: (item) => item.Commission > 999 ? NumberCommas(item.Commission) : item.Commission },
    { title: 'Đơn vị CK', key: 'CommissionType', width: 80, className: '', render: (item) => item.CommissionType === 'PERCENT' ? '%' : 'VNĐ' },
    { title: 'TG áp dụng', key: 'AppliedDate', width: 100, className: '', render: (item) => item.AppliedDate ? moment(item.AppliedDate).format('DD-MM-YYYY'): '' },
    { title: 'TG hết hạn', key: 'ExpiredDate', width: 100, className: '', render: (item) => item.ExpiredDate ? moment(item.ExpiredDate).format('DD-MM-YYYY'): '' },
    { title: 'Người duyệt', key: 'OverApprover', width: 140, className: '', render: (item) => item.OverApprover },
    { title: 'Tạo bởi', key: 'CreatedBy', width: 140, className: '', render: (item) => item.CreatedBy },
    { title: 'Thời gian tạo', key: 'CreatedDate', width: 120, className: '', render: (item) => item.CreatedDate ? moment(item.CreatedDate).format('DD-MM-YYYY HH:mm'): '' },
    { title: '', dataIndex: '', key: 'action', fixed: "right", className: "text-center", align: 'center', width: 80, render: (item) => {
      return (
        <div>
          <Popconfirm title="Bạn có chắc muốn ngừng sử dụng?" disabled={!act} onConfirm={() => onAcitve(item)} placement="topRight" okText="Có" cancelText="Không">
            <Button disabled={!act} icon={<StopOutlined />} size="small"></Button>
          </Popconfirm>
          &ensp;
          <Popconfirm title="Bạn có chắc muốn xóa?" disabled={!del}onConfirm={() => onDelete(item)} placement="topRight" okText="Có" cancelText="Không">
            <Button disabled={!del} icon={<DeleteOutlined />} size="small" danger></Button>
          </Popconfirm>
        </div>
      )
    }}
  ]
}

const ViewCommission = (props) => {
  const { open, close, show, setShow, record, setRecord, commission, loading, staff, getCommission } = props;
  let acitveKey = [];
  if(commission) {
    if(commission.Activated && commission.Activated.length) acitveKey.push('1');
    if(commission.Deactivated && commission.Deactivated.length) acitveKey.push('2');
    if(commission.Expired && commission.Expired.length) acitveKey.push('3');
    if(commission.Deleted && commission.Deleted.length) acitveKey.push('4');
  }

  return (
    <Modal
      title={<span>Chi tiết bảng chiết khấu:&ensp;<span className="bold">{staff?.Code} - {staff?.Name}</span></span>}
      visible={open}
      style={{ top: 20 }}
      width={'1160px'}
      footer={null}
      maskClosable={false}
      onOk={() =>  close(false) }
      onCancel={() => close(false) }
      className="modal-form"
    >
      <div className="row">
        {commission
        ?
        <Collapse
          bordered={false}
          defaultActiveKey={acitveKey}
          expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
          className="site-collapse-custom-collapse"
        >
          <Panel header="ĐANG KÍCH HOẠT" key="1" className="site-collapse-custom-panel">
            <Table
              bordered
              loading={loading}
              size="small"
              className="table-main"
              columns={createColums(props, true, true)}
              rowKey={(row, key) => key}
              dataSource={commission?.Activated || []}
              pagination={false}
              scroll={{ x: 800 }}
            />
          </Panel>
          <Panel header="NGỪNG KÍCH HOẠT" key="2" className="site-collapse-custom-panel">
            <Table
              bordered
              loading={loading}
              size="small"
              className="table-main"
              columns={createColums(props, false, true)}
              rowKey={(row, key) => key}
              dataSource={commission?.Deactivated || []}
              pagination={false}
              scroll={{ x: 800 }}
            />
          </Panel>
          <Panel header="ĐÃ HẾT HẠN" key="3" className="site-collapse-custom-panel">
            <Table
              bordered
              loading={loading}
              size="small"
              className="table-main"
              columns={createColums(props, false, true)}
              rowKey={(row, key) => key}
              dataSource={commission?.Expired || []}
              pagination={false}
              scroll={{ x: 800 }}
            />
          </Panel>
          <Panel header="ĐÃ XÓA" key="4" className="site-collapse-custom-panel">
            <Table
              bordered
              loading={loading}
              size="small"
              className="table-main"
              columns={createColums(props, false, false)}
              rowKey={(row, key) => key}
              dataSource={commission?.Deleted || []}
              pagination={false}
              scroll={{ x: 800 }}
            />
          </Panel>
        </Collapse>
        : (loading ? <Spin spinning/> : <Empty description="Chưa có dữ liệu"/>)
        }
        {show && record && <FormCommission open={show} getCommission={() => getCommission({ StaffCode: staff.Code })} close={() => setRecord(null, () => setShow(false))} record={record} />}
      </div>
    </Modal>
  );
};

export function mapDispatchToProps(dispatch) {
  return {
    getCommission: (params) => dispatch(getStaffCommissionRequest(params)),
    getActiveCommission: (params) => dispatch(activeCommissionRequest(params)),
    deleteCommission: (params) => dispatch(deleteCommissionRequest(params)),
  };
}

const mapStateToProps = createStructuredSelector({
  commission: selectCommission(),
  loading: selectCommissioning(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default recompose(
  withConnect,
  withState('show', 'setShow', false),
  withState('record', 'setRecord', null),
  withHandlers({
    onAcitve: ({ staff, getCommission, getActiveCommission }) => (record) => {
      if (record && record.Id) {
        getActiveCommission({
            data: {Id: record.Id},
            cb: (type) => {
                if (type) {
                    message.success('Thay đổi thành công!');
                    getCommission({ StaffCode: staff.Code });
                } else {
                    message.error('Thay đổi thất bại!');
                }
            }
        })
      }
    },
    onDelete: ({ staff, getCommission, deleteCommission }) => (record) => {
      if (record && record.Id) {
        deleteCommission({
            data: { Id: record.Id },
            cb: (type) => {
                if (type) {
                    message.success('Xóa thành công!');
                    getCommission({ StaffCode: staff.Code });
                } else {
                    message.error('Xóa thất bại!');
                }
            }
        })
      }
    }
  }),
)(ViewCommission);
