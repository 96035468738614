import React from 'react';
import _ from 'lodash';
import { Button, Form, notification } from 'antd';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { SaveOutlined } from '@ant-design/icons';
import { lifecycle, compose as recompose, withHandlers, withProps, withState } from 'recompose';
import { Field, reduxForm } from 'redux-form';
import { required } from '../../../helpers/validate';
import { FieldSelect } from '../../../components/Fields';
import { ADD_STAFF_STATION_FORM, StaffStationForm } from '../../../reduxs/manageStation-redux/action';
import { selectEmployee } from '../../../reduxs/manageStation-redux/selectors';
import { employeesListRequest, employeesInStationListRequest } from '../../../reduxs/manageStation-redux/reducer';
import { departmentListRequest } from '../../../reduxs/department-redux/reducer';
import { selectDepartment } from '../../../reduxs/department-redux/selectors';

const StaffForm = (props) => {
  const { isPu, handleSubmit, submitting, pristine, error, employees, departments, onChangeDepartment } = props;
  const submit = handleSubmit(StaffStationForm);

  // Danh sách nhân viên
  let _employees = [];
  if (employees && employees.length) {
    _employees = _.uniqBy(employees, 'STAFF_CODE').map((employees, key) => {
      return {
        value: employees.STAFF_CODE,
        text: employees.STAFF_CODE + " - " + employees.NAME
      }
    });
  }

  // Danh sách phòng ban
  let _departments = [];
  if (departments && departments.length) {
    _departments = departments.map((departments, key) => {
      return {
        value: departments.Code,
        text: departments.Name
      }
    });
  }

  return (
    <Form layout="vertical" onFinish={submit}>
      <div className="box-body">
        <div className="row">
          <div className={isPu ? "col-md-5" : "col-md-5"}>
            <Field
              component={FieldSelect}
              label="Nhân viên"
              name="Staffcode"
              placeholder="Nhân viên"
              options={_employees}
              className="required"
              validate={[required]}
              showSearch
              filterOption={(input, option) =>
                {
                  const childrens = option.children.filter(x => !!x);
                  return childrens.filter(child => child.toLowerCase().indexOf(input.toLowerCase()) >= 0).length !== 0;
                }
              }
            />
          </div>
          <div className={isPu ? "col-md-3" : "col-md-5"}>
            <Field
              component={FieldSelect}
              label="Phòng ban"
              name="DepartmentCode"
              placeholder="Phòng ban"
              options={_departments}
              className="required"
              validate={[required]}
              onChange={(value) =>onChangeDepartment(value)}
              showSearch
              filterOption={(input, option) =>
                {
                  const childrens = option.children.filter(x => !!x);
                  return childrens.filter(child => child.toLowerCase().indexOf(input.toLowerCase()) >= 0).length !== 0;
                }
              }
            />
          </div>
          { isPu &&
            <div className="col-md-2">
              <Field
                component={FieldSelect}
                label="Phương tiện"
                name="Vehicle"
                placeholder="Chọn phương tiện"
                options={[{value: 'Xe máy', text: 'Xe máy'}, {value: 'Ô tô', text: 'Ô tô'}]}
                className="required"
                validate={[required]}
              />
            </div>
          }
          <div className="col-md-2">
            <Button loading={submitting} disabled={pristine} type="primary" size={'big'} className="btnAddEmployeStation" htmlType="submit" title="Thêm" icon={<SaveOutlined />}>Thêm</Button>&ensp;
          </div>
        </div>
        <p className="text-danger text-center mr-0">{error}</p>
      </div>
    </Form>
  );
};

export function mapDispatchToProps(dispatch) {
  return {
   // Danh sách nhân viên trong bưu cục
   searchEmployeesInStation: (params) => dispatch(employeesInStationListRequest(params)),
    // Nhân viên
    getEmployees: (params) => dispatch(employeesListRequest(params)),
    // Phòng ban
    getDepartments: (params) => dispatch(departmentListRequest(params))
  };
}

const mapStateToProps = createStructuredSelector({
  employees: selectEmployee(),
  departments: selectDepartment(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default recompose(
  withConnect,
  withProps(({ station, StationCode }) => {
    if (station) {
      return { initialValues: {StationCode: station.STATION_CODE} };
    } else if(StationCode) {
      return { initialValues: {StationCode: StationCode} };
    }
  }),
  withState('isPu', 'setIsPu', false),
  reduxForm({
    form: ADD_STAFF_STATION_FORM,
    enableReinitialize: true,
    onSubmitSuccess: (result, dispatch, props) => {
      notification.success({
        message: 'Lưu thành công!'
      });
      props.initialValues = null;
      props.reset();
      props.setIsPu(false);
      props.fetchEmployees();
    }
  }),
  withHandlers({
    onChangeDepartment: ({setIsPu}) => (value) => {
      if(value === 'PU') {
        setIsPu(true)
      } else {
        setIsPu(false)
      }
    }
  }),
  lifecycle({
    componentDidMount() {
      const { employees, departments } = this.props;
      if(!(employees && employees.length)) {
        this.props.getEmployees();
      }
      if(!(departments && departments.length)) {
        this.props.getDepartments({ Size: 100, Offset: 1 });
      }
    },
  }),
)(StaffForm);