import { call, put, takeLatest } from 'redux-saga/effects';
import * as AccountService from '../../services/account-service';
import * as CustomerService from '../../services/customer-service';
import { ZoneFormAction, CodeFormAction } from './action';
import { SubmissionError } from 'redux-form';
import moment from 'moment';

import {
  getAccountsSuccess,
  getCustomersSuccess,
  getStepsSuccess,
  getAccountTypeSuccess,
  getZonesSuccess,
  getAccountInfoSuccess,
  getInvoicesSuccess,
  getWarehousesSuccess,
  getBanksSuccess,
  createUserByIdSuccess,
  getSchedulesSuccess,
  typeScheduleSuccess,
  getAllowedVerifyStepsSuccess,
  getCodesSuccess,
  getEmailSuccess,
  getCodeDetailSuccess,
  downloadAccountWaitConfirmSuccess,
  getZoningHistorySuccess,
  resetPasswordToDefaultSuccess,
  failure
} from './reducer';


export function* getAccounts({ payload = {} }) {
  try {
    const { Result } = yield call(AccountService.getAccounts, payload);
    if (Result && Result.Data) {
      const { Data, Pagination } = Result;
      yield put(getAccountsSuccess({ accounts: Data, pagination: Pagination }));
    } else {
      yield put(getAccountsSuccess({ accounts: [], pagination: { Page: 1, Size: 50, Total: 0 } }));
    }
  } catch (error) {
    yield put(getAccountsSuccess({ accounts: [], pagination: { Page: 1, Size: 50, Total: 0 } }));
  }
}

export function* getCustomers({ payload = {} }) {
  try {
    const { Result } = yield call(AccountService.getCustomers, payload);
    if (Result && Result.Data) {
      const { Data, Pagination } = Result;
      yield put(getCustomersSuccess({ customers: Data, pagination: Pagination }));
    } else {
      yield put(getCustomersSuccess({ customers: [], pagination: { Page: 1, Size: 50, Total: 0 } }));
    }
  } catch (error) {
    yield put(getCustomersSuccess({ customers: [], pagination: { Page: 1, Size: 50, Total: 0 } }));
  }
}

export function* getCodes({ payload = {} }) {
  try {
    const { Result } = yield call(AccountService.getCodes, payload);
    if (Result && Result.Data) {
      const { Data, Pagination } = Result;
      yield put(getCodesSuccess({ codes: Data, pagination: Pagination }));
    } else {
      yield put(getCodesSuccess({ codes: [], pagination: { Page: 1, Size: 50, Total: 0 } }));
    }
  } catch (error) {
    yield put(getCodesSuccess({ codes: [], pagination: { Page: 1, Size: 50, Total: 0 } }));
  }
}

export function* getEmails({ payload = {} }) {
  try {
    const { Result } = yield call(AccountService.getEmails, payload);
    if (Result && Result.length) {
      yield put(getEmailSuccess({ emails: Result }));
    } else {
      yield put(getEmailSuccess({ emails: [] }));
    }
  } catch (error) {
    yield put(getEmailSuccess({ emails: [] }));
  }
}

export function* getCodeDetails({ payload = {} }) {
  try {
    const { Result } = yield call(AccountService.getCodeDetais, payload);
    if (Result && Result.Data) {
      const { Data, Pagination } = Result;
      yield put(getCodeDetailSuccess({ codedetails: Data, pagination: Pagination }));
    } else {
      yield put(getCodeDetailSuccess({ codedetails: [], pagination: { Page: 1, Size: 50, Total: 0 } }));
    }
  } catch (error) {
    yield put(getCodeDetailSuccess({ codedetails: [], pagination: { Page: 1, Size: 50, Total: 0 } }));
  }
}

export function* getSteps({ payload = {} }) {
  try {
    const { Result } = yield call(AccountService.getSteps, payload);
    if (Result && Result.length) {
      yield put(getStepsSuccess({ steps: Result }));
    } else {
      yield put(getStepsSuccess({ steps: [] }));
    }
  } catch (error) {
    yield put(getStepsSuccess({ steps: [] }));
  }
}

export function* confirmAccounts({ payload }) {
  const { data, cb } = payload;
  try {
    const { Result } = yield call(AccountService.confirmAccounts, data);
    if (Result) {
      cb('success')
    } else {
      cb('error')
    }
  } catch (error) {
    cb('error')
  }
}

export function* stepAccounts({ payload }) {
  const { data, cb } = payload;
  try {
    const { Result, Message } = yield call(AccountService.stepAccounts, data);
    if (Result) {
      cb('success')
    } else {
      cb('error', Message)
    }
  } catch (error) {
    let message = error && error.Description;
    cb('error', message)
  }
}

export function* getAccountType({ payload = {} }) {
  try {
    const { Result } = yield call(AccountService.getAccountType, payload);
    if (Result && Result.length) {
      yield put(getAccountTypeSuccess({ types: Result }));
    } else {
      yield put(getAccountTypeSuccess({ types: [] }));
    }
  } catch (error) {
    yield put(getAccountTypeSuccess({ types: [] }));
  }
}

export function* getAllowedVerifySteps({ payload = {} }) {
  try {
    const { Result } = yield call(AccountService.getAllowedVerifySteps, payload);
    if (Result && Result.length) {
      yield put(getAllowedVerifyStepsSuccess({ steps: Result }));
    } else {
      yield put(getAllowedVerifyStepsSuccess({ steps: [] }));
    }
  } catch (error) {
    yield put(getAllowedVerifyStepsSuccess({ steps: [] }));
  }
}

export function* changeAccountType({ payload }) {
  const { data, cb } = payload;
  try {
    const { Result } = yield call(AccountService.changeAccountType, data);
    if (Result) {
      cb('success')
    } else {
      cb('error')
    }
  } catch (error) {
    let message = error && error.Message;
    cb('error', message)
  }
}

/**Zone account */
export function* getZoneAccount({ payload }) {
  const { data, cb } = payload;
  try {
    const { Result } = yield call(AccountService.getZoneByAccount, data);
    cb('success', Result || null)
  } catch (error) {
    let message = error && error.Description;
    cb('error', message)
  }
}

export function* saveCustomerZone({ payload }) {
  try {
    let result;
    const body = { ...payload }
    if (body.EffectiveDate) body.EffectiveDate = moment(body.EffectiveDate, 'DD-MM-YYYY').format('YYYY-MM-DD HH:mm:ss');
    if (!body.IsActive) body.IsActive = 0;
    if (!body.IsInEffect) body.IsInEffect = 0;

    if (body.ZoningInfoId) {
      result = yield call(AccountService.updateZone, { zone: body, ZoningInfoId: body.ZoningInfoId });
    } else {
      result = yield call(AccountService.addZone, body);
    }

    if (result) {
      yield put(ZoneFormAction.success('Lưu thành công'))
    } else {
      const { message } = 'Lưu không thành công!'
      const formError = new SubmissionError({
        _error: message
      })
      yield put(ZoneFormAction.failure(formError))
    }
  } catch (error) {
    const formError = new SubmissionError({
      _error: error.Message || 'Lưu không thành công!'
    })
    yield put(ZoneFormAction.failure(formError))
  }
}

export function* searchZones({ payload = {} }) {
  try {
    const { Result } = yield call(AccountService.getZones, payload);
    if (Result) {
      yield put(getZonesSuccess({ zones: Result }));
    }
  } catch (error) {
    yield put(failure(error));
  }
}

export function* getAccountInfo({ payload = {} }) {
  try {
    const { Result } = yield call(AccountService.getAccountInfo, payload);
    if (Result) {
      yield put(getAccountInfoSuccess({ user: Result }));
    } else {
      yield put(getAccountInfoSuccess({ user: null }));
    }
  } catch (error) {
    yield put(getAccountInfoSuccess({ user: null }));
  }
}

export function* searchInvoices({ payload = {} }) {
  try {
    const { Result } = yield call(AccountService.getInvoices, payload);
    if (Result) {
      yield put(getInvoicesSuccess({ invoices: Result }));
    } else {
      yield put(getInvoicesSuccess({ invoices: [] }));
    }
  } catch (error) {
    yield put(getInvoicesSuccess({ invoices: [] }));
  }
}

export function* searchWarehouses({ payload }) {
  try {
    const { Result } = yield call(AccountService.getWarehouseUser, payload);
    if (Result) {
      yield put(getWarehousesSuccess({ warehouses: Result }));
    } else {
      yield put(getWarehousesSuccess({ warehouses: [] }));
    }
  } catch (error) {
    yield put(getWarehousesSuccess({ warehouses: [] }));
  }
}

export function* searchBanks({ payload }) {
  try {
    const { Result } = yield call(AccountService.getBanks, payload);
    if (Result) {
      yield put(getBanksSuccess({ banks: Result }));
    } else {
      yield put(getBanksSuccess({ banks: [] }));
    }
  } catch (error) {
    yield put(getBanksSuccess({ banks: [] }));
  }
}

export function* createUserById({ payload = {} }) {
  try {
    const { Result } = yield call(AccountService.createUserByCustomerId, payload);
    if (Result) {
      yield put(createUserByIdSuccess({ success: Result }));
    }
  } catch (error) {
    yield put(failure(error));
  }
}

export function* getSchedules({ payload = {} }) {
  try {
    const { Result } = yield call(AccountService.getSchedulesByCode, payload);
    yield put(getSchedulesSuccess({ schedules: Result }));
  } catch (error) {
    yield put(getSchedulesSuccess({ schedules: null }));
  }
}

export function* getScheduleTypes({ payload = {} }) {
  try {
    const { Result } = yield call(AccountService.getScheduleTypes, payload);
    yield put(typeScheduleSuccess({ scheduletypes: Result }));
  } catch (error) {
    yield put(typeScheduleSuccess({ scheduletypes: [] }));
  }
}

export function* saveSchedule({ payload }) {
  const { data, cb } = payload;
  try {
    const { Result, Message } = yield call(AccountService.saveSchedule, data);
    if (Result) {
      if (cb) cb("success");
    } else {
      if (cb) cb("error", Message);
    }
  } catch (error) {
    if (cb) cb("error");
  }
}

export function* saveValueMode({ payload }) {
  const { data, cb } = payload;
  try {
    const { Result, Message } = yield call(AccountService.saveValueMode, data);
    if (Result) {
      if (cb) cb("success");
    } else {
      if (cb) cb("error", Message);
    }
  } catch (error) {
    if (cb) cb("error");
  }
}

export function* saveCustomerCode({ payload }) {
  try {
    const { Result } = yield call(AccountService.saveCode, payload);

    if (Result) {
      yield put(CodeFormAction.success('Lưu thành công'))
    } else {
      const { message } = 'Lưu không thành công!'
      const formError = new SubmissionError({
        _error: message
      })
      yield put(CodeFormAction.failure(formError))
    }
  } catch (error) {
    const formError = new SubmissionError({
      _error: error.Message || 'Lưu không thành công!'
    })
    yield put(CodeFormAction.failure(formError))
  }
}

export function* changeAccountCode({ payload }) {
  const { data, cb } = payload;
  try {
    const { Result } = yield call(AccountService.changeAccountCode, data);
    if (Result) {
      cb('success')
    } else {
      cb('error')
    }
  } catch (error) {
    let message = error && error.Message;
    cb('error', message)
  }
}

export function* removeParentCode({ payload }) {
  const { data, cb } = payload;
  try {
    const { Result } = yield call(AccountService.removeParentCode, data);
    if (Result) {
      cb('success')
    } else {
      cb('error')
    }
  } catch (error) {
    cb('error')
  }
}

export function* removeAccountCode({ payload }) {
  const { data, cb } = payload;
  try {
    const { Result } = yield call(AccountService.removeAccountCode, data);
    if (Result) {
      cb('success')
    } else {
      cb('error')
    }
  } catch (error) {
    cb('error')
  }
}

export function* downloadWAC({ payload }) {
  const { data, cb } = payload;
  try {
    const Result = yield call(AccountService.downloadAccounts, data);
    if (Result) {
      cb('success')
    } else {
      cb('error')
    }
    yield put(downloadAccountWaitConfirmSuccess())
  } catch (error) {
    cb('error')
  }
}

export function* getZoningHistory({ payload = {} }) {
  try {
    const { Result } = yield call(CustomerService.getZoningHistory, payload);
    yield put(getZoningHistorySuccess({ histories: Result }));
  } catch (error) {
    yield put(getZoningHistorySuccess({ histories: [] }));
  }
}

export function* resetPasswordToDefault({ payload }) {
  const { data, cb } = payload;
  try {
    const { Result } = yield call(AccountService.resetPasswordToDefault, data);
    if (Result && !Result.Code) {
      cb('success')
    } else {
      cb('error')
    }
    yield put(resetPasswordToDefaultSuccess())
  } catch (error) {
    cb('error')
  }
}

export function* saveCustomerStation({ payload }) {
  const { data, cb } = payload;
  try {
    const { Result } = yield call(AccountService.changestationAccounts, data);
    if (Result) {
      cb('success')
    } else {
      cb('error')
    }
  } catch (error) {
    let message = error && error.Message;
    cb('error', message)
  }
}

export function* saveEmail({ payload }) {
  const { data, cb } = payload;
  try {
    const { Result } = yield call(AccountService.saveEmail, data);
    if (Result) {
      cb('success')
    } else {
      cb('error')
    }
  } catch (error) {
    let message = error && error.Message;
    cb('error', message)
  }
}

export function* deleteEmail({ payload }) {
  const { data, cb } = payload;
  try {
    const { Result } = yield call(AccountService.deleteEmail, data);
    if (Result) {
      cb('success')
    } else {
      cb('error')
    }
  } catch (error) {
    let message = error && error.Message;
    cb('error', message)
  }
}

export function* changeProfileStatus({ payload }) {
  const { data, cb } = payload;
  try {
    const { Result } = yield call(AccountService.changeProfileStatus, data);
    if (Result) {
      cb('success')
    } else {
      cb('error')
    }
  } catch (error) {
    cb('error')
  }
}

export function* updateCustomerByProps({ payload }) {
  const { params, cb } = payload;
  try {
    const { Result } = yield call(CustomerService.updateByProps, params);
    cb && cb(Result)
  } catch (error) {
    cb && cb (false)
  }
}

export function* createAccountNumber({ payload }) {
  const { params, cb } = payload;
  try {
    const { Result } = yield call(CustomerService.createAccountNumber, params);
    cb && cb(true, Result)
  } catch (error) {
    cb && cb (false)
  }
}
export function* addCustomerToNewStation({ payload }) {
  const { params, cb } = payload;
  try {
    const { Result } = yield call(CustomerService.addCustomerToNewStation, params);
    cb && cb(Result)
  } catch (error) {
    cb && cb (false)
  }
}

export default [
  takeLatest('GET_ACCOUNTS_REQUEST', getAccounts),
  takeLatest('GET_CUSTOMERS_REQUEST', getCustomers),
  takeLatest('GET_CODES_REQUEST', getCodes),
  takeLatest('GET_EMAIL_REQUEST', getEmails),
  takeLatest('SAVE_EMAIL_REQUEST', saveEmail),
  takeLatest('DEL_EMAIL_REQUEST', deleteEmail),
  takeLatest('GET_CODE_DETAIL_REQUEST', getCodeDetails),
  takeLatest('GET_STEPS_REQUEST', getSteps),
  takeLatest('GET_ACCOUNT_TYPE_REQUEST', getAccountType),
  takeLatest('CONFIRM_ACCOUNT_REQUEST', confirmAccounts),
  takeLatest('STEP_ACCOUNT_REQUEST', stepAccounts),
  takeLatest('CHANGE_ACCOUNT_TYPE_REQUEST', changeAccountType),
  takeLatest('GET_ZONE_ACCOUNT_REQUEST', getZoneAccount),
  takeLatest('GET_ZONES_REQUEST', searchZones),
  takeLatest('GET_USER_ACCOUNT_REQUEST', getAccountInfo),
  takeLatest('GET_INVOICES_REQUEST', searchInvoices),
  takeLatest('GET_WAREHOUSES_REQUEST', searchWarehouses),
  takeLatest('GET_BANKS_REQUEST', searchBanks),
  takeLatest(ZoneFormAction.REQUEST, saveCustomerZone),
  takeLatest('CREATE_USER_BY_ID_REQUEST', createUserById),

  takeLatest('GET_SCHEDULES_REQUEST', getSchedules),
  takeLatest('TYPE_SCHEDULE_REQUEST', getScheduleTypes),
  takeLatest('SAVE_SCHEDULE_REQUEST', saveSchedule),
  takeLatest('CHANGE_VALUE_MODE_REQUEST', saveValueMode),

  takeLatest('GET_ALLOWED_VERIFY_STEPS_REQUEST', getAllowedVerifySteps),

  takeLatest(CodeFormAction.REQUEST, saveCustomerCode),
  takeLatest('CHANGE_ACCOUNT_CODE_REQUEST', changeAccountCode),
  takeLatest('REMOVE_PARENT_CODE_REQUEST', removeParentCode),
  takeLatest('REMOVE_ACCOUNT_CODE_REQUEST', removeAccountCode),

  takeLatest('DOWNLOAD_ACCOUNT_WAIT_TO_CONFIRM_REQUEST', downloadWAC),
  takeLatest('GET_ZONING_HISTORY_REQUEST', getZoningHistory),
  takeLatest('RESET_PASSWORD_TO_DEFAULT_REQUEST', resetPasswordToDefault),
  takeLatest('CHANGE_PROFILE_STATUS_REQUEST', changeProfileStatus),
  takeLatest('CHANGE_ACCOUNT_STATION_REQUEST', saveCustomerStation),

  takeLatest('UPDATE_CUSTOMER_BY_PROP_REQUEST', updateCustomerByProps),
  takeLatest('CREATE_ACCOUNT_NUMBER_REQUEST', createAccountNumber),
  takeLatest('ADD_CUSTOMER_TO_NEW_STATION_REQUEST', addCustomerToNewStation),
];
