import { call, put, takeLatest } from 'redux-saga/effects';
import * as AssetTransferServices from '../../services/asset-transfer-service';
import moment from 'moment';
import { AssetTransferFormAction } from "./action";
import { SubmissionError } from "redux-form";

import {
  assetTransfersSuccess,
  getAssetTransferSuccess
} from './reducer';


export function* fetchAssetTransfers({ payload }) {
  try {
    const { Result } = yield call(AssetTransferServices.fetchAssetTransfers, payload);
    if(Result.Data) {
      const { Data, Pagination } = Result
      yield put(assetTransfersSuccess({ transfers: Data, pagination: Pagination }));
    } else {
      yield put(assetTransfersSuccess({ transfers: [], pagination: { Page: 1,Size: 50, Total: 0} }));
    }
  } catch (error) {
    yield put(assetTransfersSuccess({ transfers: [], pagination: { Page: 1,Size: 50, Total: 0} }));
  }
}

export function* getAssetTransfer({ payload }) {
  try {
    const { Result } = yield call(AssetTransferServices.getAssetTransfer, payload);
    if(Result) {
      yield put(getAssetTransferSuccess({ transfer: Result }));
    } else {
      yield put(getAssetTransferSuccess({ transfer: null }));
    }
  } catch (error) {
    yield put(getAssetTransferSuccess({ transfer: null }));
  }
}

export function* deleteAssetTransfer({ payload: { data, cb } }) {
  try {
    const { Result } = yield call(AssetTransferServices.deleteAssetTransfer, data);
    if(Result) {
      cb(true)
    } else {
      cb(false)
    }
  } catch (error) {
    cb(false)
  }
}

export function* saveAssetTransfer({ payload }) {
  try {
    if(payload.RELATES_CODE && payload.RELATES_CODE.length > 0) {
      payload.RELATES_CODE = payload.RELATES_CODE.join(',');
    } else {
      payload.RELATES_CODE = ""
    }
    if(payload.CREATED_DATE) payload.CREATED_DATE = moment(payload.CREATED_DATE).format('YYYY-MM-DD')
    if(payload.TRANSFER_DATE) payload.TRANSFER_DATE = moment(payload.TRANSFER_DATE).format('YYYY-MM-DD')

    const { Result } = yield call(AssetTransferServices.saveAssetTransfer, payload);
    if (Result) {
      yield put(AssetTransferFormAction.success(Result));
    } else {
      const formError = new SubmissionError({
        _error: 'Tạo phiếu bàn giao thất bại!'
      });
      yield put(AssetTransferFormAction.failure(formError));
    }
  } catch (error) {
    let _message = "Tạo phiếu bàn giao thất bại!";
    if(error && typeof error.Description === 'string') {
      _message = error.Description;
    }
    const formError = new SubmissionError({_error: _message});
    yield put(AssetTransferFormAction.failure(formError));
  }
}

export function* confirmAssetTransfer({ payload: { data, cb } }) {
  try {
    const Result  = yield call(AssetTransferServices.confirmAssetTransfer, data);
    if(Result) {
      cb(true)
    } else {
      cb(false)
    }
  } catch (error) {
    cb(false)
  }
}

export function* printAssetTransfer({ payload: { data, cb } }) {
  try {
    const { Result }  = yield call(AssetTransferServices.printAssetTransfer, data);
    if(Result) {
      cb(true)
    } else {
      cb(false)
    }
  } catch (error) {
    cb(false)
  }
}

export default [
  takeLatest('ASSET_TRANSFER_LIST_REQUEST', fetchAssetTransfers),
  takeLatest('ASSET_TRANSFER_REQUEST', getAssetTransfer),
  takeLatest('ASSET_DEL_TRANSFER_REQUEST', deleteAssetTransfer),
  takeLatest('ASSET_TRANSFER_CONFIRM_REQUEST', confirmAssetTransfer),
  takeLatest('ASSET_TRANSFER_PRINT_REQUEST', printAssetTransfer),
  takeLatest(AssetTransferFormAction.REQUEST, saveAssetTransfer),
];
