import React from 'react';
import { Empty, Spin } from 'antd';
import { NumberCommas } from '../../../../../helpers/common';

const zones = [
  {value: 'E1', text: 'E1'},
  {value: 'E2', text: 'E2'},
  {value: 'E3', text: 'E3'},
  {value: 'E4', text: 'E4'},
  {value: 'E5', text: 'E5'},
  {value: 'E6', text: 'E6'},
  {value: 'E7', text: 'E7'},
  {value: 'INNER-CITY', text: 'N.Thành'},
  {value: 'HA_NOI', text: 'Hà Nội'},
  {value: 'DA_NANG', text: 'Đã Nẵng'},
  {value: 'HO_CHI_MINH', text: 'HCM'}
]

const ViewCommission = (props) => {
  const { loading, price } = props;

  let _prices = [];
  if(price && price.Details) {
    _prices = price.Details;
  }

  return (
          <div>
            <h4 className="bold">Cước {price?.PriceList?.XFirstWeight} kg đầu {NumberCommas(price?.PriceList?.PriceForXFirstWeight, 0)}đ</h4>
            <p>Bảng giá mỗi {price?.PriceList?.WeightStep} kg tiếp theo</p>
            <table className="table table-bordered table-price">
              <thead>
                <tr>
                  <th rowSpan={2} style={{width: '30px'}}>Vùng đi</th>
                  <th colSpan={11} style={{width: '800px'}}>Vùng tới</th>
                </tr>
                <tr>
                  <th style={{width: '80px'}}>E1</th>
                  <th style={{width: '80px'}}>E2</th>
                  <th style={{width: '80px'}}>E3</th>
                  <th style={{width: '80px'}}>E4</th>
                  <th style={{width: '80px'}}>E5</th>
                  <th style={{width: '80px'}}>E6</th>
                  <th style={{width: '80px'}}>E7</th>
                  <th style={{width: '80px'}}>N.Thành</th>
                  <th style={{width: '80px'}}>Hà Nội</th>
                  <th style={{width: '80px'}}>Đà Nẵng</th>
                  <th style={{width: '80px'}}>HCM</th>
                </tr>
              </thead>
              <tbody>
                {_prices && _prices.length > 0
                ? zones.map((zone) => {
                  let price = _prices.find(i => i.FromZone === zone.value)
                  return (
                    <tr>
                      <td><span className="bold">{zone.text}</span></td>
                      {zones.map((z) => {
                        let item = price && price.Prices.find(i => i.ToZone === z.value);
                        return(
                          <td className="text-center">
                            {item
                            ? <label className="w-100 text-right">+{item.PriceOnStep && NumberCommas(item.PriceOnStep, 0)}đ</label>
                            : '-'}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })
                : <tr><td colSpan={9}>{(loading ? <Spin spinning/> : <Empty description="Chưa có dữ liệu"/>)}</td></tr>
                }
              </tbody>
            </table>
          </div>
  );
};

export default ViewCommission;
