import { createSelector } from 'reselect';
import { initialState } from './reducer';

const utilityState = (state) => state.utility || initialState;

const selectCountries = () => createSelector(
  utilityState,
  ({ countries }) => countries
);

const selectProvinces = () => createSelector(
  utilityState,
  ({ provinces }) => provinces
);

const selectDistricts = () => createSelector(
  utilityState,
  ({ districts }) => districts
);

const selectWards = () => createSelector(
  utilityState,
  ({ wards }) => wards
);

const selectShipProvinces = () => createSelector(
  utilityState,
  ({ shipProvinces }) => shipProvinces
);

const selectShipDistricts = () => createSelector(
  utilityState,
  ({ shipDistricts }) => shipDistricts
);

const selectShipWards = () => createSelector(
  utilityState,
  ({ shipWards }) => shipWards
);

const makeSelectComplated = () => createSelector(
  utilityState,
  ({ completed }) => completed
);

const selectLocations = () => createSelector(
  utilityState,
  ({ locations }) => locations
);

const selectLocationsing = () => createSelector(
  utilityState,
  ({ locationsing }) => locationsing
);

const selectCustomers = () => createSelector(utilityState, ({ customers }) => customers);
const selectCustomersing = () => createSelector(utilityState, ({ customersing }) => customersing);

export {
  utilityState,
  selectCountries,
  selectProvinces,
  selectDistricts,
  selectWards,
  selectShipProvinces,
  selectShipDistricts,
  selectShipWards,
  makeSelectComplated,
  selectLocations,
  selectLocationsing,
  selectCustomers,
  selectCustomersing
};
