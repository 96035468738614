import { call, put, takeLatest } from 'redux-saga/effects';
import * as areaService from '../../services/area-service';

import { AreaFormAction } from "./action";
import { SubmissionError } from "redux-form";

import {
    areaListSuccess,
} from './reducer';

export function* fetchAreas({ payload }) {
    try {
        const { Result } = yield call(areaService.getListAreas, payload);
        if(Result) {
          const { Data, Pagination } = Result;
           yield put(areaListSuccess({ areas: Data, pagination: Pagination }));
        } else {
          yield put(areaListSuccess({ areas: [], pagination: {Total: 0, Size: 0, Offset: 0 } }));
        }
    } catch (error) {
      yield put(areaListSuccess({ areas: [], pagination: {Total: 0, Size: 0, Offset: 0 } }));
    }
}

export function* saveArea({ payload }) {
    try {
      let result = null;
      if(!(payload.Items && payload.Items.length)) {
        const message = "Chưa chọn xã phường nhận";
        const formError = new SubmissionError({
          _error: message
        });
        yield put(AreaFormAction.failure(formError));
      }
      if(payload.Id) {
        result= yield call(areaService.updateArea, payload);
      } else {
        result= yield call(areaService.addArea, payload);
      }
      if (result && result.Result) {
        yield put(AreaFormAction.success("Lưu thành công!"));
      } else {
        const message = "Lưu thất bại!";
        const formError = new SubmissionError({
          _error: message
        });
        yield put(AreaFormAction.failure(formError));
      }
    } catch (error) {
      const message = "Lưu thất bại!";
      const formError = new SubmissionError({
        _error: message
      });
      yield put(AreaFormAction.failure(formError));
    }
  }

  
export function* deleteArea({ payload }) {
  const { data, cb } = payload;
  try {
    const { Result } = yield call(areaService.deleteArea, data);
    if(Result) {
      cb('success')
    } else {
      cb('error')
    }
  } catch (error) {
    cb('error')
  }
}

// Xuất dữ liệu exel bưu cục
export function* exportExcelArea({ payload }) {
  const { data, cb } = payload;
  try {
    const Result = yield call(areaService.exportExcelArea, data);
    if(Result) {
      cb('success')
    } else {
      cb('error')
    }
  } catch (error) {
    cb('error')
  }
}

export function* districtsArea({ payload }) {
  const { data, cb } = payload;
  try {
    const { Result } = yield call(areaService.getDistricts, data);
    if(Result) {
      cb(Result);
    } else {
      cb([])
    }
  } catch (error) {
    cb([])
  }
}

export function* wardsArea({ payload }) {
  const { data, cb } = payload;
  try {
    const { Result } = yield call(areaService.getWards, data);
    if(Result) {
      cb(Result);
    } else {
      cb([])
    }
  } catch (error) {
    cb([])
  }
}

export default [
  takeLatest(AreaFormAction.REQUEST, saveArea),
  takeLatest('AREA_LIST_REQUEST', fetchAreas),
  takeLatest('DELETE_AREA_REQUEST', deleteArea),
  takeLatest('EXCEL_AREA_REQUEST', exportExcelArea),
  takeLatest('DISTRICTS_AREA_REQUEST', districtsArea),
  takeLatest('WARDS_AREA_REQUEST', wardsArea),
];
