
import './style.scss';
import React from 'react';
import Info from './components/info';
import Invoices from './components/invoices';
import WareHouse from './components/warehouse';
// import Services from './components/services';
import Bank from './components/banks';
import Contact from './components/contacts';

import PriceList from './services/PriceListVM';
import DocumentPage from '../document/DocumentVM';
import { Spin, Tabs } from 'antd';
import CommissionPage from '../commission/CommissionVM';

const { TabPane } = Tabs;

const TermV = (props) => {
  const { loading, user, invoices, banks, wareHouses, getUser, account, fetchAccounts } = props;

  let complete = user && user.FullName && user.Addresses && user.PassportNumber;
  complete = complete && user?.Contacts.length > 0;
  complete = complete && wareHouses?.length > 0;
  complete = complete && invoices?.length > 0;
  complete = complete && user.IsVerified;

  return (
    <Spin spinning={loading} tip="Đang tải...">
      <div className="page-term">
        <Tabs defaultActiveKey="1">
          <TabPane tab={<span className="bold"><i className="fa fa-user" />&ensp;HỒ SƠ</span>} key="1">
            <div className="row">
              {user &&
                <div className="col-md-12">
                  <Info user={user} complete={complete} fetchAccounts={fetchAccounts} fetchUser={() => getUser({ AccountNr: account.AccountNr })} />
                  <Contact user={user} />
                  <Invoices invoices={invoices} />
                  <Bank banks={banks} />
                  <WareHouse wareHouses={wareHouses} />
                  {/* <Services types={types} onchangService={onchangService} serviceId={serviceId} disabled={user.IsVerified} openForm={open} setOpenForm={setOpen} /> */}
                </div>
              }
            </div>
          </TabPane>
          <TabPane tab={<span className="bold"><i className="fa fa-money" />&ensp;BẢNG GIÁ</span>} key="2">
            {user && <PriceList user={user} />}
          </TabPane>
          <TabPane tab={<span className="bold"><i className="fa fa-file-text-o" />&ensp;TÀI LIỆU</span>} key="3">
            {user && <DocumentPage customer={user} />}
          </TabPane>
          <TabPane tab={<span className="bold"><i className="fa fa-file-text-o" />&ensp;CHIẾT KHẤU</span>} key="4">
            {user && <CommissionPage customer={user} />}
          </TabPane>
        </Tabs>
      </div>
    </Spin>
  )
}
export default TermV
