import API from '../utils/axiosApiHR';
// import { printFrame } from '../helpers/common';
import FileSaver from 'file-saver';

/**Sale */
export const fetchAssetSales = async (params) => {
  try {
    const { data } = await API.get('/assets/sellings', { params });
    return data;
  } catch (error) {
    throw error;
  }
};
export const saveAssetSale = async (body) => {
    try {
      if(body && body.CODE) {
        const { data } = await API.put(`/assets/selling/${body.CODE}`, body);
        return data;
      } else {
        const { data } = await API.post('/assets/selling', body);
        return data;
      }
    } catch (error) {
      throw error;
    }
};
export const getAssetSale = async ({Code}) => {
  try {
    const { data } = await API.get(`/assets/selling/${Code}`);
    return data;
  } catch (error) {
    throw error;
  }
};
export const deleteAssetSale = async ({Code}) => {
  try {
    const { data } = await API.delete(`/assets/selling/${Code}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const confirmAssetSale = async (body) => {
  try {
    const { data } = await API.post(`/assets/selling/confirm`, body);
    return data;
  } catch (error) {
    throw error;
  }
};

export const printAssetSale = async ({Code}) => {
  try {
    const response = await API.get(`/assets/selling/print/${Code}`, {
      responseType: 'blob'
    });
    const { data } = response;
    const namefile = response.headers['pcs-filename-suggestion'];
    let blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
    FileSaver.saveAs(blob, namefile);
    return true;
  } catch (error) {
    throw error;
  }
};
