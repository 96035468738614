import { call, put, takeLatest } from 'redux-saga/effects';
import * as CommonService from '../../services/common-service';
import Auth from '../../storage/Auth';

import {
  autoCompleteCustomerSuccess,
  getTypeExtraServicesSuccess,
  getTypeServicesSuccess,
  getCurrenciesSuccess,
  getAllStationSuccess,
  getMyStationSuccess,
  calculaterSuccess,
  calculaterExtraSuccess,
  getBatchCodeSuccess,
  staffStationSuccess,
  staffAllSuccess,
  getStaffDepartmentSuccess,
  getValueModesSuccess,
  exportRevenueComparisonSuccess,
  getDepartmentsSuccess,
  failure,
} from './reducer';


export function* autoCompleteCustomer({ payload = {} }) {
  try {
    const { Result } = yield call(CommonService.autoCompleteCustomer, payload);
    if (Result) {
      yield put(autoCompleteCustomerSuccess({ customers: Result }));
    }
  } catch (error) {
    yield put(failure(error));
  }
}

export function* getTypeServices({ payload = {} }) {
  try {
    const { Result } = yield call(CommonService.getServiceType, payload);
    if (Result) {
      yield put(getTypeServicesSuccess({ services: Result }));
    } else {
      yield put(getTypeServicesSuccess({ services: [] }));
    }
  } catch (error) {
    yield put(failure(error));
  }
}

export function* getExtraTypeServices() {
  try {
    const { Result } = yield call(CommonService.getExtraServiceType);
    if (Result) {
      yield put(getTypeExtraServicesSuccess({ services: Result }));
    } else {
      yield put(getTypeExtraServicesSuccess({ services: [] }));
    }
  } catch (error) {
    yield put(failure(error));
  }
}

export function* getCurrency({ payload = {} }) {
  try {
    const { Result } = yield call(CommonService.getCurrency, payload);
    if (Result) {
      yield put(getCurrenciesSuccess({ currencies: Result }));
    } else {
      yield put(getCurrenciesSuccess({ currencies: [] }));
    }
  } catch (error) {
    yield put(failure(error));
  }
}

export function* fetchAllStations({ payload = {} }) {
  try {
    const { Result } = yield call(CommonService.fetchAllStations, payload);
    if (Result) {
      yield put(getAllStationSuccess({ stations: Result }));
    } else {
      yield put(getAllStationSuccess({ stations: [] }));
    }
  } catch (error) {
    yield put(failure(error));
  }
}

export function* fetchMyStations({ payload = {} }) {
  try {
    const { Result } = yield call(CommonService.fetchMyStations, payload);
    if (Result) {
      yield put(getMyStationSuccess({ stations: Result }));
    } else {
      yield put(getMyStationSuccess({ stations: [] }));
    }
  } catch (error) {
    yield put(failure(error));
  }
}

export function* fetchCalculater({ payload = {} }) {
  try {
    const { Result } = yield call(CommonService.fetchCalculater, payload);
    if (Result) {
      yield put(calculaterSuccess({ calculater: Result }));
    } else {
      yield put(calculaterSuccess({ calculater: null }));
    }
  } catch (error) {
    yield put(failure(error));
  }
}

export function* fetchCalculaterExtra({ payload = {} }) {
  try {
    const { Result } = yield call(CommonService.fetchCalculaterExtra, payload);
    if (Result && Number(Result) > 0) {
      yield put(calculaterExtraSuccess({ extra: Number(Result) }));
    } else {
      yield put(calculaterExtraSuccess({ extra: 0 }));
    }
  } catch (error) {
    yield put(failure(error));
  }
}

export function* fetchBatchCode({ payload: { cb } }) {
  try {
    const { Result } = yield call(CommonService.fetchBatch);
    if (Result && Result.Code) {
      yield put(getBatchCodeSuccess({ batch: Result.Code }));
      if (cb) {
        cb(Result.Code)
      }
    } else {
      yield put(getBatchCodeSuccess({ batch: null }));
    }
  } catch (error) {
    yield put(getBatchCodeSuccess({ batch: null }));
    yield put(failure(error));
  }
}

export function* fetchBillCode({ payload }) {
  const { data, cb } = payload;
  try {
    const { Result } = yield call(CommonService.fetchBillCheckin, data);
    cb(Result)
  } catch (error) {
    cb(null)
  }
}

export function* fetchStaffStation({ payload = {} }) {
  try {
    const { Result } = yield call(CommonService.fetchMyStaffs, payload);
    if (Result) {
      yield put(staffStationSuccess({ staffs: Result }));
    } else {
      yield put(staffStationSuccess({ staffs: [] }));
    }
  } catch (error) {
    yield put(staffStationSuccess({ staffs: [] }));
    yield put(failure(error));
  }
}

export function* getStaffDepartment({ payload = {} }) {
  try {
    const station = Auth.getStation();
    if (!payload.StationCode) payload.StationCode = station;
    const { Result } = yield call(CommonService.getStationDepartment, payload);
    if (Result) {
      yield put(getStaffDepartmentSuccess({ staffs: Result }));
    } else {
      yield put(getStaffDepartmentSuccess({ staffs: [] }));
    }
  } catch (error) {
    yield put(getStaffDepartmentSuccess({ staffs: [] }));
  }
}

export function* fetchStaffAlls({ payload = {} }) {
  try {
    const { Result } = yield call(CommonService.fetchStaffAlls, payload);
    if (Result) {
      yield put(staffAllSuccess({ staffs: Result }));
    } else {
      yield put(staffAllSuccess({ staffs: [] }));
    }
  } catch (error) {
    yield put(staffAllSuccess({ staffs: [] }));
  }
}

export function* getValueModes({ payload = {} }) {
  try {
    const { Result } = yield call(CommonService.getValueModes, payload);
    if (Result) {
      yield put(getValueModesSuccess({ modes: Result }));
    } else {
      yield put(getValueModesSuccess({ modes: null }));
    }
  } catch (error) {
    yield put(getValueModesSuccess({ modes: null }));
  }
}

export function* downloadRevenueComparison({ payload }) {
  const { cb, params } = payload;
  try {
    const Result = yield call(CommonService.downloadRevenueComparison, params);
    if (Result) {
      yield put(exportRevenueComparisonSuccess({ success: true }))
      cb('success')
    } else {
      yield put(exportRevenueComparisonSuccess({ success: false }))
      cb('error')
    }
  } catch (error) {
    yield put(exportRevenueComparisonSuccess({ success: false }))
    cb('error')
  }
}

export function* getDepartments({ payload = {} }) {
  try {
    payload.iDisplayStart = 0
    payload.iDisplayLength = 100
    const { Result } = yield call(CommonService.getDepartments, payload);
    if (Result && Result.data) {
      yield put(getDepartmentsSuccess({ departments: Result.data }));
    } else {
      yield put(getDepartmentsSuccess({ departments: [] }));
    }
  } catch (error) {
    yield put(getDepartmentsSuccess({ departments: [] }));
  }
}

export function* findUsers({ payload }) {
  const { cb, params } = payload;
  try {
    const { Result } = yield call(CommonService.findUsers, params);
    if (Result) {
      cb && cb(true, Result)
    }
  } catch (error) {
    cb && cb(false)
  }
}

export function* findStaffByStation({ payload = {} }) {
  const { cb, params } = payload;
  try {
    const { Result } = yield call(CommonService.findStaffByStation, params);
    if (Result) {
      cb && cb(true, Result)
    }
  } catch (error) {
    cb && cb(false, [])
  }
}

export default [
  takeLatest('AUTO_COMPLETE_CUSTOMER_REQUEST', autoCompleteCustomer),
  takeLatest('GET_TYPE_SERVICE_REQUEST', getTypeServices),
  takeLatest('GET_CURRENCY_REQUEST', getCurrency),
  takeLatest('GET_ALL_STATION_REQUEST', fetchAllStations),
  takeLatest('GET_MY_STATION_REQUEST', fetchMyStations),
  takeLatest('CALCULATER_REQUEST', fetchCalculater),
  takeLatest('CALCULATER_EXTRA_REQUEST', fetchCalculaterExtra),
  takeLatest('GET_TYPE_EXTRA_SERVICE_REQUEST', getExtraTypeServices),
  takeLatest('GET_BATCH_CODE_REQUEST', fetchBatchCode),
  takeLatest('GET_BILL_CODE_REQUEST', fetchBillCode),
  takeLatest('STAFF_STATION_REQUEST', fetchStaffStation),
  takeLatest('STAFF_ALL_REQUEST', fetchStaffAlls),
  takeLatest('GET_STAFF_DEPARTMENT_REQUEST', getStaffDepartment),
  takeLatest('GET_VALUE_MODE_REQUEST', getValueModes),
  takeLatest('DOWNLOAD_REVENUE_COMPARISON_REQUEST', downloadRevenueComparison),
  takeLatest('GET_DEPARTMENTS_REQUEST', getDepartments),
  takeLatest('FIND_USER_REQUEST', findUsers),
  takeLatest('FIND_STAFF_BY_STATION_REQUEST', findStaffByStation)
];
