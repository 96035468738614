import './style.scss'
import React from 'react'
import { lifecycle, compose as recompose, withHandlers, withState} from 'recompose';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Layout } from 'antd';

import { Header, Station, Sider } from '../components';
import useSize from '../components/SizeScreen'
import RouteSubRoutes from '../app/RouteSubRoutes';

import { getStationRequest } from '../reduxs/station-redux/reducer';
import { fetchMyFeatureRequest } from '../reduxs/feature-redux/reducer';

const { Content } = Layout;

const MainPage = (props) => {
  const { routes, user, setToggle } = props;
  const size = useSize();
  if(user) {
    return (
      <Layout >
          <Sider {...props}/>
          <Layout className="container-main">
            <Header {...props} />
            <Content className="box-main content-main" onTouchEnd={()=> setToggle(false)}>
              {routes.map((route, i) => <RouteSubRoutes key={i} {...route} width={size.width} isMobile={(size && size.width <= 678)} />)}
            </Content>
            <Station />
          </Layout>
      </Layout>
    )
  } else {
    return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
  }
}

export function mapDispatchToProps(dispatch) {
  return {
    getStations: () => dispatch(getStationRequest()),
    getFeatures: () => dispatch(fetchMyFeatureRequest())
  };
}

function mapStateToProps(state) {
  return {
    user: state.oidc && state.oidc.user
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default recompose(
  withConnect,
  withState('modal', 'setModal', false),
  withState('toggle', 'setToggle', false),
  withHandlers({

  }),
  lifecycle({
    componentDidMount() {
      this.props.getStations();
      this.props.getFeatures();
    },
    componentDidUpdate(props) {
      if(this.props.location.pathname !== props.location.pathname) {
          this.props.setToggle(false)
      }
    }
  }),
)(MainPage);