import React from 'react';
import { Button, Modal, Form, notification } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { compose as recompose, withProps} from 'recompose';
import { Field, reduxForm } from 'redux-form';
import { required  } from '../../../helpers/validate';
import { FieldInput, FieldNumber } from '../../../components/Fields';


import { PICKUP_ROUTE_FORM_KEY, PickupFormAction } from '../../../reduxs/pickup-route-redux/action';

const PickupRouteForm = (props) => {
  const { open, close, group, reset, handleSubmit } = props;
  const submit = handleSubmit(PickupFormAction);

  return (
    <Modal
      title={group ? 'Cập nhật tuyến hàng' : 'Thêm tuyến hàng' }
      visible={open}
      style={{ top: 20 }}
      width={'760px'}
      footer={null}
      maskClosable={false}
      onOk={() => { reset(); close(false) }}
      onCancel={() => { reset(); close(false) }}
      className="modal-form-menu"
    >
      <Form layout="vertical" onFinish={submit}>
        <br/>
        <div className="row">
          <div className="col-md-6">
            <Field
              component={FieldInput}
              label="Tên tuyến"
              name="Name"
              placeholder="Nhập tên tuyến"
              className="required"
              validate={[required]}
            />
          </div>
          <div className="col-md-6">
            <Field
              component={FieldNumber}
              label="Giá cước"
              name="Price"
              mode="comma"
              placeholder="Nhập giá cước"
              formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              parser={value => `${value}`.replace(/(,*)/g, '')}
              className="required"
              validate={[required]}
            />
          </div>
        </div>
        <p>Ghi chú:&ensp;<span className="note-red">Trường không được để trống</span></p>
        <Form.Item className="text-center">
            <Button type="primary" icon={<SaveOutlined />} htmlType="submit">{group ? "Cập nhật" : "Lưu lại"}</Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default recompose(
    withProps(({ route }) => {
        if(route) {
          return { initialValues: { Id: route.Id, Name: route.Name, Price: route.CommonPrice, IsActive: route.IsActive || true }};
        } else {
          return { initialValues:  null };
        }
      }
    ),
    reduxForm({
      form: PICKUP_ROUTE_FORM_KEY,
      enableReinitialize: true,
      onSubmitSuccess: (result, dispatch, props) => {
          notification.success({
              message: 'Lưu thành công!'
          });
          props.initialValues = null;
          props.close(false);
          props.refresh();
      }
    })
)(PickupRouteForm);
