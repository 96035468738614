import { call, put, takeLatest } from 'redux-saga/effects';
import * as MenuService from '../../services/menu-service';

import { MenuFormAction, GroupFormAction, GroupMenuFormAction, UserMenuFormAction } from "./action";
import { SubmissionError } from "redux-form";

import {
  fetchMenusSuccess,
  fetchClientsSuccess,
  fetchGroupsSuccess,
  fetchMenuGroupsSuccess,
  fetchUserMenusSuccess,
  failure
} from './reducer';

export function* fetchMenus({ payload = {} }) {
  try {
    const { Result } = yield call(MenuService.getMenus, payload);
    if(Result) {
      yield put(fetchMenusSuccess({ menus: Result }));
    } else {
      yield put(fetchMenusSuccess({ menus: [] }));
    }
  } catch (error) {
    yield put(fetchMenusSuccess({ menus: [] }));
    yield put(failure(error));
  }
}

export function* fetchClients({ payload = {} }) {
  try {
    const { Result } = yield call(MenuService.getClients, payload);
    if(Result) {
      yield put(fetchClientsSuccess({ clients: Result }));
    } else {
      yield put(fetchClientsSuccess({ clients: [] }));
    }
  } catch (error) {
    yield put(fetchClientsSuccess({ clients: [] }));
    yield put(failure(error));
  }
}

export function* fetchGroups({ payload = {} }) {
  try {
    const { Result } = yield call(MenuService.getGroups, payload);
    if(Result) {
      yield put(fetchGroupsSuccess({ groups: Result }));
    } else {
      yield put(fetchGroupsSuccess({ groups: [] }));
    }
  } catch (error) {
    yield put(fetchGroupsSuccess({ groups: [] }));
    yield put(failure(error));
  }
}

export function* fetchMenuGroups({ payload = {} }) {
  try {
    const { Result } = yield call(MenuService.getMenuGroups, payload);
    if(Result) {
      yield put(fetchMenuGroupsSuccess({ menugroups: Result }));
    } else {
      yield put(fetchMenuGroupsSuccess({ menugroups: [] }));
    }
  } catch (error) {
    yield put(fetchMenuGroupsSuccess({ menugroups: [] }));
    yield put(failure(error));
  }
}

export function* fetchUserMenus({ payload = {} }) {
  try {
    const { Result } = yield call(MenuService.getUserMenus, payload);
    if(Result && Result.length) {
      yield put(fetchUserMenusSuccess({ usermenus: Result }));
    } else {
      yield put(fetchUserMenusSuccess({ usermenus: [] }));
    }
  } catch (error) {
    yield put(fetchUserMenusSuccess({ usermenus: [] }));
  }
}

export function* saveMenu({ payload }) {
  try {
    let result = null;
    if(payload.Id) {
      result= yield call(MenuService.updateMenu, payload);
    } else {
      result= yield call(MenuService.addMenu, payload);
    }
    if (result) {
      yield put(MenuFormAction.success("Lưu thành công!"));
    } else {
      const { message } = "Lưu thất bại!";
      const formError = new SubmissionError({
        _error: message
      });
      yield put(MenuFormAction.failure(formError));
    }
  } catch (error) {
    const formError = new SubmissionError({
      _error: error
    });
    yield put(MenuFormAction.failure(formError));
  }
}

export function* saveGroup({ payload }) {
  try {
    let result = null;
    if(payload.Id) {
      result= yield call(MenuService.updateGroup, payload);
    } else {
      result= yield call(MenuService.addGroup, payload);
    }
    if (result) {
      yield put(GroupFormAction.success("Lưu thành công!"));
    } else {
      const { message } = "Lưu thất bại!";
      const formError = new SubmissionError({
        _error: message
      });
      yield put(GroupFormAction.failure(formError));
    }
  } catch (error) {
    const formError = new SubmissionError({
      _error: error
    });
    yield put(GroupFormAction.failure(formError));
  }
}

export function* saveGroupMenu({ payload }) {
  try {
    let result = yield call(MenuService.updateGroupMenu, payload);
    if (result) {
      yield put(GroupMenuFormAction.success("Lưu thành công!"));
    } else {
      const { message } = "Lưu thất bại!";
      const formError = new SubmissionError({
        _error: message
      });
      yield put(GroupMenuFormAction.failure(formError));
    }
  } catch (error) {
    const formError = new SubmissionError({
      _error: error
    });
    yield put(GroupMenuFormAction.failure(formError));
  }
}

export function* saveUserMenus({ payload }) {
  try {
    let result = yield call(MenuService.updateUserMenu, payload);
    if (result) {
      yield put(UserMenuFormAction.success("Lưu thành công!"));
    } else {
      const { message } = "Lưu thất bại!";
      const formError = new SubmissionError({
        _error: message
      });
      yield put(UserMenuFormAction.failure(formError));
    }
  } catch (error) {
    const formError = new SubmissionError({
      _error: error
    });
    yield put(UserMenuFormAction.failure(formError));
  }
}

export function* saveGroupMenus({ payload }) {
  const { data, cb } = payload;
  try {
    const { Result } = yield call(MenuService.saveGroupMenus, data);
    if(Result) {
      cb('success')
    } else {
      cb('error')
    }
  } catch (error) {
    cb('error')
  }
}

export function* removeMenus({ payload }) {
  const { data, cb } = payload;
  try {
    const { Result } = yield call(MenuService.removeMenus, data);
    if(Result) {
      cb('success')
    } else {
      cb('error')
    }
  } catch (error) {
    cb('error')
  }
}

export function* removeUserUser({ payload }) {
  const { data, cb } = payload;
  try {
    const { Result } = yield call(MenuService.removeUserMenu, data);
    if(Result) {
      cb('success')
    } else {
      cb('error')
    }
  } catch (error) {
    cb('error')
  }
}

export default [
  takeLatest('FETCH_MENUS_REQUEST', fetchMenus),
  takeLatest('FETCH_CLIENTS_REQUEST', fetchClients),
  takeLatest('FETCH_GROUPS_REQUEST', fetchGroups),
  takeLatest('FETCH_MENU_GROUPS_REQUEST', fetchMenuGroups),
  takeLatest('FETCH_USER_MENUS_REQUEST', fetchUserMenus),
  takeLatest('REMOVE_MENUS_REQUEST', removeMenus),
  takeLatest('REMOVE_USER_MENUS_REQUEST', removeUserUser),
  takeLatest('SAVE_GROUP_MENUS_REQUEST', saveGroupMenus),
  
  takeLatest(MenuFormAction.REQUEST, saveMenu),
  takeLatest(GroupFormAction.REQUEST, saveGroup),
  takeLatest(GroupMenuFormAction.REQUEST, saveGroupMenu),
  takeLatest(UserMenuFormAction.REQUEST, saveUserMenus),
];
