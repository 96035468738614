import { call, put, takeLatest } from 'redux-saga/effects';
import * as exchargeService from '../../services/excharge-service';

import { ExchargeFormAction } from "./action";
import { SubmissionError } from "redux-form";

import {
  getExchargesSuccess,
} from './reducer';

export function* getListExcharges({ payload = {} }) {
  try {
    const { Result } = yield call(exchargeService.getListExcharges, payload);
    if (Result) {
      const { Data, Pagination } = Result
      yield put(getExchargesSuccess({ excharges: Data, pagination: Pagination }));
    } else {
      yield put(getExchargesSuccess({ excharges: [], pagination: { Page: 1, Size: 50, Total: 0 } }));
    }
  } catch (error) {
    yield put(getExchargesSuccess({ excharges: [], pagination: { Page: 1, Size: 50, Total: 0 } }));
  }
}

export function* saveExcharge({ payload }) {
  try {
    let result = yield call(exchargeService.saveExcharge, payload);
    if (result) {
      yield put(ExchargeFormAction.success("Lưu thành công!"));
    } else {
      const { message } = "Lưu thất bại!";
      const formError = new SubmissionError({
        _error: message
      });
      yield put(ExchargeFormAction.failure(formError));
    }
  } catch (error) {
    const formError = new SubmissionError({
      _error: error
    });
    yield put(ExchargeFormAction.failure(formError));
  }
}

export function* removeExcharge({ payload }) {
  const { data, cb } = payload;
  try {
    const { Result } = yield call(exchargeService.removeExcharge, data);
    if (Result) {
      cb('success')
    } else {
      cb('error')
    }
  } catch (error) {
    cb('error')
  }
}

export default [
  takeLatest('GET_LIST_EXCHARGES_REQUEST', getListExcharges),
  takeLatest(ExchargeFormAction.REQUEST, saveExcharge),
  takeLatest('REMOVE_EXCHARGE_REQUEST', removeExcharge),
];
