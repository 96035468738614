
import React from 'react';
import {  Modal, Select, Button, notification } from 'antd';
import { compose as recompose, withHandlers, lifecycle, withState } from 'recompose';
import { SaveOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { changeAccountStationRequest } from '../../../reduxs/account-redux/reducer';
import { getCodesRequest } from '../../../reduxs/account-redux/reducer';
import { selectCodes, selectCodesing } from '../../../reduxs/account-redux/selectors';
import { selectStations } from '../../../reduxs/station-redux/selectors';
const StationForm = (props) => {
    const { open, setValue, value, stations, submitting, onStationHide, onAccountStation } = props;
    let _stations = [];
    if(stations && stations.length) {
        _stations = stations.map((station, key) => {
        return {
          key: key,
          value: station.STATION_CODE,
          label: station.STATION_CODE + ' - ' + station.NAME
        }
      });
    }
    return (
        <Modal
            title="Thay đổi bưu cục"
            visible={open}
            style={{ top: 20 }}
            width={'460px'}
            footer={null}
            maskClosable={false}
            onOk={() => onStationHide()}
            onCancel={() => onStationHide()}
            className="modal-form-zone"
            >
                <div className="box-form-type">
                    <Select
                        value={value}
                        options={_stations}
                        onChange={(value) => setValue(value)}
                        className="w-100"
                        showSearch
                        filterOption={(input, option) =>
                            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    />
                    <div className="w-100 mr-t-15 text-center">
                        <Button type="primary" onClick={() =>onAccountStation()} loading={submitting} disabled={!value} icon={<SaveOutlined />}>Cập nhật</Button>
                    </div>
                </div>
        </Modal>
    )
}

function mapDispatchToProps(dispatch) {
    return {
        changeAccountStation: (params) => dispatch(changeAccountStationRequest(params)),
        getCodes: (params) => dispatch(getCodesRequest(params))
    };
}
const mapStateToProps = createStructuredSelector({
    codes: selectCodes(),
    loading: selectCodesing(),
    stations: selectStations(),
  });
  
const withConnect = connect(mapStateToProps, mapDispatchToProps);
  
export default recompose(
    withConnect,
    withState('value', 'setValue', ''),
    withState('submitting', 'setSubmitting', false),
    withHandlers({
        fetchCodes: ({ getCodes }) => (param) => {
            let _params = { Size: 50, Offset: 1, ...param };
            getCodes({ ..._params});
        },
        onAccountStation: ({ value, account, setValue, close, setSubmitting, fetchAccounts, changeAccountStation }) => () => {
            if(value && account && account.Id) {
                setSubmitting(true);
                changeAccountStation({
                    data: {
                        StationCode: value,
                        CustomerId: account.Id,
                    },
                    cb: (type, message) => {
                        setSubmitting(false);
                        if(type === 'error'){
                            notification.error({message: message || 'Cập nhật mã cha thất bại!'});
                        } else {
                            notification.success({message: 'Cập nhật mã cha thành công!'});
                            fetchAccounts();
                            setValue(null, () => close(false))
                        }
                    }
                })
            }
        },
        onStationHide: ({setValue, close}) => () => {
            setValue(null, () => close(false))
        }
    }),
    lifecycle({
        componentDidMount() {
            this.props.fetchCodes();
            // const {account, setValue} = this.props;
            // if(account && account.AccountTypeId) setValue(account.AccountTypeId)
        },
    }),
)(StationForm);
