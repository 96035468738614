import axios from '../utils/axiosInstance';
import FileSaver from 'file-saver';

// Danh sách bưu cục
export const getListStations = async (params = {}) => {
    try {
        const { data } = await axios.get('/ops-station/list-station-ops-pagination', { params });
        return data;
    } catch (error) {
        throw error;
    }
};

// Thêm bưu cục
export const addStation = async (station) => {
    try {
        const { data } = await axios.post('/admin/station', station);
        return data;
    } catch (error) {
        throw error;
    }
};

// Sửa bưu cục
export const updateStation = async (station) => {
    try {
        const { data } = await axios.put(`/admin/station`, station);
        return data;
    } catch (error) {
        throw error;
    }
};

// Xóa bưu cục
export const deleteStation = async (station) => {
    try {
        const { data } = await axios.delete(`/ops-station/delete-station`, { data: station });
        return data;
    } catch (error) {
        throw error;
    }
};

// Xuất excel danh sách bưu cục
export const exportExcel = async (params) => {
    try {
        const response = await axios.get('/ops-station/export-station', { params, responseType: 'blob' });
        const { data } = response;
        const namefile = response.headers['pcs-filename-suggestion'];
        const fileType = response.headers['content-type'];
        var blob = new Blob([data], { type: fileType });
        FileSaver.saveAs(blob, namefile);
        return true;
    } catch (error) {
        throw error;
    }
};

// Danh sách tất cả bưu cục
export const getListStationAll = async (params = {}) => {
    try {
        const { data } = await axios.get('/ops-station/list-station-ops', { params });
        return data;
    } catch (error) {
        throw error;
    }
};
// Danh sách loại bưu cục
export const getListStationType = async (params = {}) => {
    try {
        const { data } = await axios.get('/admin/station/type', { params });
        return data;
    } catch (error) {
        throw error;
    }
};

// Danh sách nhân viên
export const getListEmployees = async (params = {}) => {
    try {
        const { data } = await axios.get('/ops-staff/list-staff', { params });
        return data;
    } catch (error) {
        throw error;
    }
};

// Thêm nhân viên vào trong bưu cục
export const addEmployeeInStation = async (station) => {
    try {
        const { data } = await axios.post('/ops-staff/add-staff-department', station);
        return data;
    } catch (error) {
        throw error;
    }
};

// Danh sách nhân viên trong bưu cục
export const getListEmployeesInStation = async (params = {}) => {
    try {
        const { data } = await axios.get('/ops-staff/list-staff-by', { params });
        return data;
    } catch (error) {
        throw error;
    }
};