export const API_ENDPOINT = {
    prod: 'https://api-chat.pcs.vn',
    uat: 'https://api-chat.pcs.vn',
    dev: 'https://api-chat.pcs.vn'
};

export const HUBS = {
    NOTIFICATION: `/notification`
}

export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';
export const SET_ENVIRONMENT = 'SET_ENVIRONMENT';
export const SET_HANDLE_OPEN_ELEMENT = 'SET_HANDLE_OPEN_ELEMENT';