import React from 'react';
import { connect } from 'react-redux';
import { compose as recompose } from 'recompose';
import { Modal, Button } from 'antd';
import parse from 'html-react-parser';
import MDEditor from '@uiw/react-md-editor';
import { parseMarkdownMessage } from '../../helpers'

const ViewNotification = (props) => {
    const { data: { Body }, handleCancel, handleOk } = props;
    return (
        <Modal title={Body.Text}
            wrapClassName="pcs-notification-modal-viewer"
            visible={true}
            onOk={handleOk}
            onCancel={handleCancel}
            style={{ zIndex: 1000 }}
            footer={[
                <Button key="back" onClick={handleCancel}>
                    Đóng
            </Button>,
                <Button key="submit" type="primary" onClick={handleOk}>
                    Ok
            </Button>,
            ]}
        >
            {
                Body.NotifType.endsWith(':markdown') ? <MDEditor.Markdown source={parseMarkdownMessage(Body.More)} /> : parse(Body.More)
            }
        </Modal>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {

    };
}

const mapStateToProps = (store) => ({

})

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default recompose(
    withConnect,
)(ViewNotification)