import { createAction, handleActions } from 'redux-actions';

// - Actions
export const autoCompleteCustomerRequest = createAction('AUTO_COMPLETE_CUSTOMER_REQUEST');
export const autoCompleteCustomerSuccess = createAction('AUTO_COMPLETE_CUSTOMER_SUCCESS');

export const getAllStationRequest = createAction('GET_ALL_STATION_REQUEST');
export const getAllStationSuccess = createAction('GET_ALL_STATION_SUCCESS');

export const getMyStationRequest = createAction('GET_MY_STATION_REQUEST');
export const getMyStationSuccess = createAction('GET_MY_STATION_SUCCESS');

export const getTypeServicesRequest = createAction('GET_TYPE_SERVICE_REQUEST');
export const getTypeServicesSuccess = createAction('GET_TYPE_SERVICE_SUCCESS');

export const getTypeExtraServicesRequest = createAction('GET_TYPE_EXTRA_SERVICE_REQUEST');
export const getTypeExtraServicesSuccess = createAction('GET_TYPE_EXTRA_SERVICE_SUCCESS');

export const getCurrenciesRequest = createAction('GET_CURRENCY_REQUEST');
export const getCurrenciesSuccess = createAction('GET_CURRENCY_SUCCESS');

export const getBatchCodeRequest = createAction('GET_BATCH_CODE_REQUEST');
export const getBatchCodeSuccess = createAction('GET_BATCH_CODE_SUCCESS');

export const getBillCodeRequest = createAction('GET_BILL_CODE_REQUEST');

export const calculaterRequest = createAction('CALCULATER_REQUEST');
export const calculaterSuccess = createAction('CALCULATER_SUCCESS');

export const calculaterExtraRequest = createAction('CALCULATER_EXTRA_REQUEST');
export const calculaterExtraSuccess = createAction('CALCULATER_EXTRA_SUCCESS');

export const staffStationRequest = createAction('STAFF_STATION_REQUEST');
export const staffStationSuccess = createAction('STAFF_STATION_SUCCESS');

export const staffAllRequest = createAction('STAFF_ALL_REQUEST');
export const staffAllSuccess = createAction('STAFF_ALL_SUCCESS');

export const getStaffDepartmentRequest = createAction("GET_STAFF_DEPARTMENT_REQUEST");
export const getStaffDepartmentSuccess = createAction("GET_STAFF_DEPARTMENT_SUCCESS");

export const getValueModesRequest = createAction('GET_VALUE_MODE_REQUEST');
export const getValueModesSuccess = createAction('GET_VALUE_MODE_SUCCESS');

export const getDepartmentsRequest = createAction('GET_DEPARTMENTS_REQUEST');
export const getDepartmentsSuccess = createAction('GET_DEPARTMENTS_SUCCESS');

export const exportRevenueComparisonRequest = createAction('DOWNLOAD_REVENUE_COMPARISON_REQUEST');
export const exportRevenueComparisonSuccess = createAction('DOWNLOAD_REVENUE_COMPARISON_SUCCESS');

export const findUserRequest = createAction('FIND_USER_REQUEST');
export const findStaffByStation = createAction('FIND_STAFF_BY_STATION_REQUEST')

export const failure = createAction('ACTION_COMMON_FAILURE');


// - Initial State
export const initialState = {
  customers: [],
  customering: false,
  typeservices: [],

  extraServiceTypes: [],
  typeservicing: false,
  extraServiceTyping: false,

  stations: [],
  stationsing: false,

  currencies: [],
  currencing: false,

  calculater: null,
  extra: 0,
  calculating: false,

  batch: null,
  batching: false,

  staffs: [],
  staffsing: false,

  stationAlls: [],
  stationAllsing: false,

  staffAlls: [],
  staffAllsing: false,

  staffDepartments: [],
  staffDepartmentsing: false,

  valueModes: null,
  valueModesing: false,

  downloadingRevenue: false,

  departments: [],
  departmentsing: false,

  error: null,
};

// - Reducers
const reducer = handleActions({
  [autoCompleteCustomerRequest]: (state) => {
    return {
      ...state,
      customering: true,
    };
  },
  [autoCompleteCustomerSuccess]: (state, { payload: { customers } }) => {
    return {
      ...state,
      customers: customers || [],
      customering: false,
    };
  },

  [getTypeServicesRequest]: (state) => {
    return {
      ...state,
      typeservicing: true,
    };
  },

  [getTypeServicesSuccess]: (state, { payload: { services } }) => {
    return {
      ...state,
      typeservices: services || [],
      typeservicing: false,
    };
  },

  [getAllStationRequest]: (state) => {
    return {...state, stationAllsing: true };
  },
  [getAllStationSuccess]: (state, { payload: { stations } }) => {
    return {...state, stationAllsing: false, stationAlls: stations};
  },

  [getMyStationRequest]: (state) => {
    return {...state, stationsing: true };
  },
  [getMyStationSuccess]: (state, { payload: { stations } }) => {
    return {...state, stationsing: false, stations: stations,};
  },

  [getTypeExtraServicesRequest]: (state) => {
    return { ...state, extraServiceTyping: true };
  },
  [getTypeExtraServicesSuccess]: (state, { payload: { services } }) => {
    return { ...state, extraServiceTypes: services || [], extraServiceTyping: false};
  },

  [getCurrenciesRequest]: (state) => {
    return { ...state,  currencing: true };
  },
  [getCurrenciesSuccess]: (state, { payload: { currencies } }) => {
    return { ...state, currencies: currencies || [], currencing: false };
  },

  [calculaterRequest]: (state) => {
    return { ...state, calculater: null,  calculating: true };
  },
  [calculaterSuccess]: (state, { payload: { calculater } }) => {
    return { ...state, calculater: calculater, calculating: false };
  },

  [calculaterExtraRequest]: (state) => {
    return { ...state, extra: 0,  calculating: true };
  },
  [calculaterExtraSuccess]: (state, { payload: { extra } }) => {
    return { ...state, extra: extra, calculating: false };
  },

  [getBatchCodeRequest]: (state) => {
    return { ...state, batch: null,  batching: true };
  },
  [getBatchCodeSuccess]: (state, { payload: { batch } }) => {
    return { ...state, batch: batch, batching: false };
  },

  [staffStationRequest]: (state) => {
    return {...state, staffsing: true };
  },
  [staffStationSuccess]: (state, { payload: { staffs } }) => {
    return {...state, staffsing: false, staffs: staffs,};
  },

  [getStaffDepartmentRequest]: (state) => {
    return { ...state, staffDepartmentsing: true };
  },
  [getStaffDepartmentSuccess]: (state, { payload: { staffs } }) => {
    return {  ...state, staffDepartmentsing: false, staffDepartments: staffs };
  },

  [staffAllRequest]: (state) => {
    return {...state, staffAllsing: true };
  },
  [staffAllSuccess]: (state, { payload: { staffs } }) => {
    return {...state, staffAllsing: false, staffAlls: staffs,};
  },

  [getValueModesRequest]: (state) => {
    return { ...state, valueModesing: true };
  },
  [getValueModesSuccess]: (state, { payload: { modes } }) => {
    return { ...state, valueModes: modes, valueModesing: false };
  },

  [exportRevenueComparisonRequest]: (state) => {
    return {...state, downloadingRevenue: true };
  },
  [exportRevenueComparisonSuccess]: (state, { payload: { success } }) => {
    return {...state, downloadingRevenue: false };
  },

  [getDepartmentsRequest]: (state) => {
    return { ...state, departmentsing: true };
  },
  [getDepartmentsSuccess]: (state, {payload: { departments }}) => {
    return { ...state, departmentsing: false, departments  };
  },

  [failure]: (state, action) => ({
    ...state,
    error: action.error,
    customering: false,
  }),
}, initialState);

export default reducer;
