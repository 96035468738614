import React, { useEffect, useState } from 'react'
import { Layout, Table, message, Select, Modal } from 'antd'
import { useDispatch } from 'react-redux';
import { getAllPickupZoneRequest, updatePickupZoneRequest } from '../../reduxs/zone-pickup-redux/reducer';
import RestrictFeature from '../../components/RestrictComponents/RestrictFeature';
import './style.scss'

const { Content } = Layout;

const STATIONS = [{
    value: '89301',
    label: 'Hà Nội'
}, {
    value: '89355',
    label: 'Đà Nẵng'
}, {
    value: '89302',
    label: 'Hồ Chí Minh'
}]

export default () => {
    const dispatch = useDispatch()
    const [dataSource, setDataSource] = useState([])
    const [fetching, setFetching] = useState(false)

    const loadData = () => {
        setFetching(true)
        dispatch(getAllPickupZoneRequest({
            cb: (isOk, data) => {
                setFetching(false)
                if (!isOk) {
                    message.error('Đã xảy ra lỗi')
                }
                setDataSource(data)
            }
        }))
    }

    const onChange = (provinceId, stationCode) => {

        Modal.confirm({
            title: "Thay đổi bưu cục lấy hàng?",
            content: <>Cập nhật bưu cục lấy hàng cho <span className='bold'>{dataSource.find(x => x.ProvinceId === provinceId).ProvinceName}</span> là <span className='bold'>{STATIONS.find(x => x.value === stationCode).label}</span></>,
            onOk: () => {
                setFetching(true)
                dispatch(updatePickupZoneRequest({
                    params: {
                        ProvinceId: provinceId,
                        StationCode: stationCode
                    },
                    cb: (isOk) => {
                        setFetching(false)
                        if (!isOk) {
                            message.error('Đã xảy ra lỗi')
                        } else {
                            message.success('Cập nhật thành công')
                            loadData()
                        }
                    }
                }))
            }
        })
    }

    useEffect(() => {
        loadData()
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <Content className="page-menus">
                <div className="panel-header">
                    <div className="header-title">
                        <i className="fa fa-home icon" aria-hidden="true"></i>
                        <span>Quản lý bưu cục lấy hàng - PCS POST</span>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="panel-body">
                        <div className="row mr-t-10">
                            <div className="col-md-12">
                                <Table
                                    key="tab1"
                                    loading={fetching}
                                    className="zone-pickup"
                                    columns={(() => {
                                        return [{
                                            title: 'Tên tỉnh/thành',
                                            render: item => item.ProvinceName,
                                            width: 250,
                                            sorter: (a, b) => a.ProvinceName.localeCompare(b.ProvinceName)
                                        }, {
                                            title: 'Bưu cục lấy hàng',
                                            render: item => {
                                                return (
                                                    <>
                                                        <RestrictFeature feature="UPDATE_PICKUP_ZONE">
                                                            {
                                                                (_props) => (
                                                                    <Select
                                                                        style={{ minWidth: 250 }}
                                                                        dropdownMatchSelectWidth={false}
                                                                        placeholder="Chọn bưu cục lấy hàng"
                                                                        value={item.StationCode}
                                                                        onChange={value => onChange(item.ProvinceId, value)}
                                                                        options={STATIONS}
                                                                    />
                                                                )
                                                            }
                                                        </RestrictFeature>
                                                    </>
                                                )
                                            },
                                            sorter: (a, b) => a.StationName.localeCompare(b.StationName)
                                        }]
                                    })()}
                                    rowKey="Id"
                                    size="small"
                                    dataSource={dataSource}
                                    pagination={false}
                                    bordered={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Content>
        </>
    )
}