import axios from '../utils/axiosInstance';
import FileSaver from 'file-saver';

// Danh sách phòng ban
export const getListAreas = async (params = {}) => {
    try {
        const { data } = await axios.get('/fin-price-pt12h/get-list-service-restriction', { params });
        return data;
    } catch (error) {
        throw error;
    }
};

// Chi tiết phòng ban
export const getDetailArea = async (params) => {
    try {
        const { data } = await axios.get(`/department/all/${params.Id}`);
        return data;
    } catch (error) {
        throw error;
    }
};

// Thêm phòng ban
export const addArea = async (area) => {
    try {
        area.ToWardId = area.Items.filter(k => k.WardId).map((i) => i.WardId);
        const { data } = await axios.post('/fin-price-pt12h/add-service-restriction', area);
        return data;
    } catch (error) {
        throw error;
    }
};

// Sửa phòng ban
export const updateArea = async (area) => {
    try {
        const { data } = await axios.post(`/fin-price-pt12h/edit-service-restriction`, area);
        return data;
    } catch (error) {
        throw error;
    }
};

// Xóa phòng ban
export const deleteArea = async (area) => {
    try {
        const { data } = await axios.delete(`/fin-price-pt12h/del-service-restriction`,{ data: area });
        return data;
    } catch (error) {
        throw error;
    }
};

// Xuất dữ liệu excel phòng ban
export const exportExcelArea = async (params) => {
    try {
      const response = await axios.get('/department/export-department', { params,  responseType: 'blob' });
      // return data;
      // const response = await axios.post('/api/quote/export/excel', body, {
      //   responseType: 'blob'
      // });
      const { data } = response;
      const namefile = response.headers['pcs-filename-suggestion'];
      const fileType = response.headers['content-type'];
      var blob = new Blob([data], {type: fileType});
      FileSaver.saveAs(blob, namefile);
      return true;
    } catch (error) {
      throw error;
    }
};

export const getDistricts = async ({ CityId  }) => {
    try {
      const { data } = await axios.get(`/common/local/country/234/city/${CityId}/district`);
      return data || [];
    } catch (error) {
      throw error;
    }
  };
  
  export const getWards = async ({ CityId, DistrictId }) => {
    try {
       const { data } = await axios.get(`/common/local/country/234/city/${CityId}/district/${DistrictId}/ward`);
       return data || [];
    } catch (error) {
      throw error;
    }
  };
