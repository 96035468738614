import React from 'react';
import { Button, Modal, Form, notification } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { compose as recompose, withHandlers, withProps} from 'recompose';
import { Field, reduxForm } from 'redux-form';
import { required  } from '../../../helpers/validate';
import { FieldInput, FieldTextArea, FieldNumber, FieldSelect, FieldCheckBox } from '../../../components/Fields';


import { AUTO_FORM_KEY, AutoFormAction } from '../../../reduxs/auto-redux/action';

const AutoForm = (props) => {
  const { open, close, reset, handleSubmit, submitting, pristine } = props;
  const submit = handleSubmit(AutoFormAction);

  return (
    <Modal
      title='Cập nhật thời gian chạy tự động'
      visible={open}
      style={{ top: 50 }}
      width={'560px'}
      footer={null}
      maskClosable={false}
      onOk={() => { reset(); close(false) }}
      onCancel={() => { reset(); close(false) }}
      className="modal-form"
    >
      <Form layout="vertical" onFinish={submit}>
        <div className="row">
          <div className="col-md-6">
            <Field
              component={FieldInput}
              label="Thời gian chạy"
              name="Expression"
              placeholder=""
              className="required"
              validate={[required]}
            />
          </div>
          <div className="col-md-6">
            <Field
              component={FieldNumber}
              label="Đỗ trễ"
              name="Delay"
              placeholder=""
              className="required"
              validate={[required]}
            />
          </div>
          <div className="col-md-6">
            <Field
              component={FieldSelect}
              label="Môi trường"
              name="EnvironmentName"
              placeholder=""
              options={[
                {value: 'Production', text: 'Production'},
                {value: 'Staging', text: 'Staging'},
                {value: 'Development', text: 'Development'},
              ]}
              className="required"
              validate={[required]}
            />
          </div>
          <div className="col-md-6">
            <Field
              component={FieldCheckBox}
              label="&ensp;"
              text="Kích hoạt"
              name="IsActive"
            />
          </div>
          <div className="col-md-12">
            <Field
              component={FieldTextArea}
              label="Miêu tả"
              name="Description"
              placeholder="Miêu tả"
              rows={2}
            />
          </div>
        </div>
        <Form.Item className="text-center">
            <Button loading={submitting} disabled={pristine} type="primary" icon={<SaveOutlined />} htmlType="submit">Lưu</Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default recompose(
    withProps(({auto}) => {
        if(auto) {
          return { initialValues: { ...auto }};
        } else {
          return { initialValues: null};
        }
      }
    ),
    withHandlers({
    }),
    reduxForm({
      form: AUTO_FORM_KEY,
      enableReinitialize: true,
      onSubmitSuccess: (result, dispatch, props) => {
          notification.success({
              message: 'Lưu thành công!'
          });
          props.initialValues = null;
          props.reset();
          props.close(false);
          props.fetchAutos();
      }
    })
)(AutoForm);
