import axios from '../utils/axiosInstance';
import FileSaver from 'file-saver';
export const createCustomerInfoSheet = async (params) => {
    try {
      let _params = { ...params };
      const keys = {
        CSKH: "Nhân viên CSKH",
        DVKH: "Nhân viên DVKH",
        GiaoDich: "Nhân viên giao dịch",
        KD: "Nhân viên KD",
        KeToan: "Nhân viên kế toán",
        TPKD: "TP. Kinh doanh"
      }
      if (_params.Staffs) {
        let staffs = [];

        Object.keys(_params.Staffs).forEach(key => {
          staffs.push({
            Name: _params.Staffs[key],
            Type: keys[key]
          })
        })

        _params.Staffs = staffs;
      }
      if(params.Id) {
        const { data } = await axios.put(`/api/customer-info-sheet`, _params);
        return data;
      } else {
        const { data } = await axios.post(`/api/customer-info-sheet`, _params);
        return data;
      }
    } catch (error) {
      throw error;
    }
};

export const confirmCustomerInfoSheet = async (body) => {
  try {
    const { data } = await axios.put(`/api/customer-info-sheet/approve`, body);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getCustomerInfoSheet = async (params) => {
  try {
    const { data } = await axios.get(`/api/customer-info-sheet/manager`, { params });
    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteCustomerInfoSheet = async (params) => {
  try {
    const { data } = await axios.delete(`/api/customer-info-sheet`, { params } );
    return data;
  } catch (error) {
    throw error;
  }
};

export const InfoSheetDownload = async (params) => {
  try {
    const response = await axios.get(`/api/customer-info-sheet/download-customer-info-sheet-by-account-no`, {
      params: params,
      responseType: 'blob'
    });

    const { data } = response;
    const namefile = decodeURI(response.headers['pcs-filename-suggestion']);
    const fileType = response.headers['pcs-filetype'];

    var blob = new Blob([data], { type: fileType });
    FileSaver.saveAs(blob, namefile);
    return true;
  } catch (error) {
    throw error;
  }
};

