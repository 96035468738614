
import axios from '../utils/axiosInstance';
// Lấy danh sách nhân viên
export const fetchStaffs = async (params) => {
  try {
    const { data } = await axios.get(`/api/system-user`, {
      params: params
    });
    return data;
  } catch (error) {
    throw error;
  }
};
// Thêm mới nhân viên
export const saveStaff = async (staff) => {
  try {
    if(staff.UserId) {
      const { data } = await axios.put('/sysstaff/update-user-staff', staff);
      return data;
    } else {
      const { data } = await axios.post('/sysstaff/add-user-staff', staff);
      return data;
    }
  } catch (error) {
    return error;
  }
};
// Đổi mật khẩu
export const changePassword = async staff => {
  try {
    const { data } = await axios.put('/sysstaff/reset-password-staff', staff);
    return data;
  } catch (error) {
    throw error;
  }
};
// Khóa nhân viên
export const lockStaff = async (staff) => {
  try {
    const { data } = await axios.put('/sysstaff/lock-staff', staff);
    return data;
  } catch (error) {
    throw error;
  }
};
// Mở khóa nhân viên
export const unLockStaff = async (staff) => {
  try {
    const { data } = await axios.put('/sysstaff/un-lock-staff', staff);
    return data;
  } catch (error) {
    throw error;
  }
};


export const fetchStaffsCommission = async (params) => {
  try {
    const { data } = await axios.get(`/api/staff-commission`, {
      params: params
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getStaffCommission = async (params) => {
  try {
    const { data } = await axios.get(`/api/staff-commission/details`, {
      params: params
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const saveCommission = async body => {
  try {
    const { data } = await axios.post('/api/staff-commission/details', body);
    return data;
  } catch (error) {
    throw error;
  }
};

export const activeCommission = async body => {
  try {
    const { data } = await axios.put('/api/staff-commission/details/deactive', body);
    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteCommission = async (body) => {
  try {
    const { data } = await axios.delete(`/api/staff-commission/details?Id=${body.Id}`);
    return data;
  } catch (error) {
    throw error;
  }
};
