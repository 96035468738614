
import axios from '../utils/axiosInstance';

export const getAutos = async (params) => {
  try {
    const { data } = await axios.get(`/crontab`, {
      params: params
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateAuto = async (role) => {
  try {
    const { data } = await axios.put(`/crontab`, role);
    return data;
  } catch (error) {
    throw error;
  }
};




