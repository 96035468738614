
import '../style.scss';
import React from 'react';
import { Empty, Table } from 'antd';

const Bank = (props) => {
  const { banks } = props;
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="box-group">
          <div className="box-header">
            <label className="nav-title bold">Thông tin thẻ ngân hàng</label>
          </div>
          <div className="box-body pd-0">
            {banks && banks.length > 0
            ?
            <Table
              className="table-complain table-main"
              columns={createColums(props)}
              rowKey='Id'
              size="small"
              dataSource={banks || []}
              pagination={false}
              showHeader={false}
              bordered={true}
            />
            :
            <Empty description="Chưa có thông tin thẻ ngân hàng"/>}
          </div>
        </div>
      </div>
    </div>
  )
}

const createColums = () => {
  const colums = [
    { title: 'STT', dataIndex: '', key: 'STT', width: 80, className: "text-center", render: (text, item, key) => { return key + 1 } },
    {
      title: 'Thông tin', dataIndex: '', key: 'CardOwner', render: (item) => {
        return <div>
          <label className="name-owner w-100 bold">{item.CardOwner}</label>
          <label className="card-number w-100"><i className="fa fa-credit-card-alt" />&ensp;{item.CardNo}</label>
        </div>
      }
    },
    {
      title: 'Ngân hàng', dataIndex: '', key: 'BankName', render: (item) => {
        return <div>
          <label className="bank-name w-100"><span className="bold">Ngân hàng:</span> {item.BankName}</label>
          <label className="bank-branch w-100"><span className="bold">Chi nhánh:</span> {item.BankBranchName} {item.ProvinceName}</label>
        </div>
      }
    },
    {
      title: 'Trạng thái', dataIndex: '', key: 'Status', className: "text-center", width: 150, render: ({ IsActive }) => {
        return IsActive ? <label className='bank-status'>Hoạt động</label> : <label className='bank-status stop'>Ngừng hoạt động</label>;
      }
    }
  ]
  return colums;
}

export default Bank
