import React from 'react';
import { connect } from 'react-redux';
import { compose as recompose, withHandlers, lifecycle } from 'recompose';
import { createStructuredSelector } from 'reselect';
import parse from 'html-react-parser';
import { Card, Avatar } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import Moment from 'react-moment';
import { markAsReadRequest, deteleNotificationRequest } from '../../reducer/reducer';
import { processText } from '../../helpers'

const FlashNotificationItem = (props) => {
    const { Body: { Text, More, CreationTime, NotifImage, Ranges }, NotifId } = props.notification;
    const { onDelete, onMarkAsRead } = props;
    return (
        <div style={{ width: '100%' }}>
            <Card
                title={
                    <div className="flash-mesage-title">
                        <div>
                            <Avatar src={NotifImage.Uri} />
                        </div>
                        <div className="flash-mesage-title_message">
                            {parse(processText(Text, Ranges))}
                        </div>
                    </div>
                }
                actions={[
                    <CloseOutlined key="DELETE" onClick={() => onDelete(NotifId)} />,
                    <CheckOutlined key="MARK_AS_READ" onClick={() => onMarkAsRead(NotifId)} />
                ]}
                extra={
                    <span>
                        <Moment locale="vi" fromNow>{CreationTime}</Moment>
                    </span>
                }
                style={{ width: '100%' }}>
                <div>
                    {parse(More)}
                </div>
            </Card>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        markAsRead: (params) => dispatch(markAsReadRequest(params)),
        deleteNotif: (params) => dispatch(deteleNotificationRequest(params)),
    };
}

const mapStateToProps = createStructuredSelector({
    notifications: store => store.notification && store.notification.notifications
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default recompose(
    withConnect,
    withHandlers({
        onMarkAsRead: ({ markAsRead }) => (_id) => markAsRead(_id),
        onDelete: ({ deleteNotif, notifications }) => (_id) => deleteNotif({ Id: _id, PageOffset: notifications.length, PageSize: 25 })
    }),
    lifecycle({
        componentDidMount() {

        }
    })
)(FlashNotificationItem)