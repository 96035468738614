import React, { Component } from 'react'
import { connect } from 'react-redux';
import { compose as recompose, withHandlers, withPropsOnChange } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { List, Spin, notification } from 'antd';
import InfiniteScroll from 'react-infinite-scroller';
import NotificationItem from '../../modules/notification/components/notification-item/notificationItem';
import NotificationSettings from '../../modules/notification/components/notification-setting/notificationSettings';
import * as signalR from "@microsoft/signalr";
import { default as privateStore } from '../notification/private-store/store';
import { setShow } from '../notification/reducer/reducer';

import { getMyNotificationRequest, collectingNewNotificationsRequest, clearCheckHasNewNotification } from '../notification/reducer/reducer';
import { HUBS, API_ENDPOINT } from '../notification/constants';
import FlashNotification from './components/flash-notification';
import OutsideWrapper from './OutsideWrapper'

import './styles.scss';

const openNotification = (_newNotification) => {
  notification.info({
    message: `Bạn có ${_newNotification} thông báo mới`,
    placement: 'bottomLeft',
    duration: 5
  });
};

class Notification extends Component {

  constructor(props) {
    super(props);
    this.state = {
      hubConnection: null,
      reconnect: 0,
      isInit: true
    };
  }

  createConnection = (_access_token) => {
    const options = {
      accessTokenFactory: () => {
        return _access_token;
      },
      transport: signalR.HttpTransportType.WebSockets
    };

    const env = privateStore.getState() && privateStore.getState().private && privateStore.getState().private.env;

    const url = `${API_ENDPOINT[env]}${HUBS.NOTIFICATION}`;

    const hub = new signalR.HubConnectionBuilder()
      .withUrl(url, options)
      .configureLogging(signalR.LogLevel.Information)
      .withAutomaticReconnect()
      .build();

      hub.serverTimeoutInMilliseconds = 1800000;

    this.setState({
      hubConnection: hub
    })

    return hub;
  }

  onMessageReceived = () => {
  }

  onTriggerGetNewNotifications = () => {
    console.log('Get new notifications');
    this.props.collectingNewNotifications(true);
  }

  startSignalRConnection = async (connection, access_token) => {
    const { reconnect, isInit } = this.state;
    await connection.start()
      .then(() => {
        console.log('Connection started!');
        this.listenEvents();
        if (reconnect === 0 || isInit === true) {
          this.props.collectingNewNotifications(true, true);
          this.setState({ isInit: false });
        }
      })
      .catch(err => {
        console.log('Error while establishing connection :(', err);
        const decoded = this.parseJwt(access_token);
        if (reconnect < 10 && new Date(decoded.exp * 1000) > new Date()) {
          this.setState({ reconnect: reconnect + 1 })
          setTimeout(() => { this.startSignalRConnection(this.createConnection(access_token), access_token) }, 5000);
        }
      });
  }

  listenEvents = () => {
    if (this.state.hubConnection) {
      this.state.hubConnection.on('messageReceived', this.onMessageReceived);

      this.state.hubConnection.on('triggerGetNewNotifications', this.onTriggerGetNewNotifications);

      this.state.hubConnection.onclose(async () => {
        console.log('trying reconnect...');
        if (this.state.hubConnection) {
          await this.startSignalRConnection(this.state.hubConnection, this.props.access_token);
        }
      })
    }
  }

  parseJwt = (token) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  };

  componentDidMount() {
    const { access_token } = this.props;
    const decoded = this.parseJwt(access_token);
    if (access_token && decoded && decoded.exp && new Date(decoded.exp * 1000) > new Date()) {
      const hubConnection = this.createConnection(access_token);
      this.startSignalRConnection(hubConnection, access_token);
    }
  }

  componentWillUnmount() {
    const { hubConnection } = this.state;

    if (hubConnection) {
      hubConnection.stop();
    }
  }

  render() {
    const { handleInfiniteOnLoad, fetching, hasMore, notifications, toggleShow } = this.props;
    const handleOpenElmt = privateStore.getState() && privateStore.getState().private && privateStore.getState().private.handleOpenElmt;

    return (
      <React.Fragment>
        <FlashNotification />
        <OutsideWrapper onClickOutside={(pRef, currentTarget) => {
          if (document.querySelector('.notification-setting-menu-overlay') && document.querySelector('.notification-setting-menu-overlay').contains(currentTarget)) {
            return
          }

          if (handleOpenElmt) {
            if (document.querySelector(handleOpenElmt) && document.querySelector(handleOpenElmt).contains(currentTarget)) {
              return;
            }

            if (!document.querySelector('.pcs-notification-modal-viewer') || !document.querySelector('.pcs-notification-modal-viewer').contains(currentTarget)) {
              toggleShow(false)
            }
          }
        }} >
          <div className="notification-infinite-container">
            <NotificationSettings />
            {notifications && notifications.length > 0 &&
              (<InfiniteScroll
                initialLoad={false}
                pageStart={0}
                loadMore={handleInfiniteOnLoad}
                hasMore={!fetching && hasMore}
                useWindow={false}
              >
                <List
                  dataSource={notifications}
                  renderItem={item => (
                    <List.Item key={item.id}>
                      <NotificationItem notification={item} />
                    </List.Item>
                  )}
                >
                  {fetching && hasMore && (
                    <div className="notification-loading-container">
                      <Spin />
                    </div>
                  )}
                  {!hasMore && (<div className="has-no-more-notification">- Bạn không còn thông báo cũ nào -</div>)}
                </List>
              </InfiniteScroll>)
            }
            {
              notifications && notifications.length === 0 && (
                <div className="">
                  <div style={{ width: '50%', marginLeft: 'auto', marginRight: 'auto' }}>
                    <img src={require('../notification/assets/null_states_notifications_gray_wash.svg')} alt="no notifications" />
                  </div>
                  <div style={{ fontFamily: 'Roboto-Medium', display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 45, fontSize: 16, color: '#a4a7ab', userSelect: 'none' }}>
                    Bạn không có thông báo nào
                  </div>
                </div>
              )
            }
          </div>
        </OutsideWrapper>
      </React.Fragment>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchNotifications: (params = {}) => dispatch(getMyNotificationRequest(params)),
    collectingNewNotifications: (getNotification, isFirst) => dispatch(collectingNewNotificationsRequest({ getNotification, isFirst })),
    clearCheckNewNotification: () => dispatch(clearCheckHasNewNotification()),
    toggleShow: (isShow) => dispatch(setShow(isShow))
  };
}

const mapStateToProps = createStructuredSelector({
  notifications: store => store.notification && store.notification.notifications,
  fetching: store => store.notification && store.notification.fetching,
  access_token: store => { return store.oidc && store.oidc.user && store.oidc.user.access_token },
  hasMore: store => { return store.notification && store.notification.hasMore },
  hasNewNotification: store => store.notification && store.notification.hasNewNotification,
  collectNewNotificationCount: store => store.notification && store.notification.collectNewNotificationCount,
  forceOpen: store => store.notification && store.notification.forceOpen,
});

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default recompose(
  withConnect,
  withPropsOnChange(['hasNewNotification', 'collectNewNotificationCount'], props => {
    const { hasNewNotification, clearCheckNewNotification, collectNewNotificationCount } = props;
    if (hasNewNotification) {
      // Tạo âm thanh thông báo và gọi api lấy thông báo mới về
      if (collectNewNotificationCount > 0) {
        openNotification(collectNewNotificationCount);
      }
      clearCheckNewNotification();
    }
  }),
  withHandlers({
    fetchData: ({ fetchNotifications }) => (_offset) => {
      fetchNotifications({ PageSize: 25, PageOffset: _offset || 0 });
    }
  }),
  withHandlers({
    handleInfiniteOnLoad: ({ fetchData, notifications }) => () => {
      fetchData(notifications.length)
    }
  })
)(Notification)