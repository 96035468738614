import { call, put, takeLatest } from 'redux-saga/effects';
import * as DatabaseChangeLogService from '../../services/database-changelog-service';


import {
    fetchDatabaseChangeLogSuccess,
    fetchPrimaryColumnsSuccess
} from './reducer';

export function* fetchErrorLogs({ payload = {} }) {
    try {
        const { Result: { Data, Pagination } } = yield call(DatabaseChangeLogService.getChangeLogs, payload);
        if(Data && Data.length) {
            yield put(fetchDatabaseChangeLogSuccess({ logs: Data, pagination: Pagination }));
        } else {
            yield put(fetchDatabaseChangeLogSuccess({ logs: [] }));
        }
    } catch (error) {
        yield put(fetchDatabaseChangeLogSuccess({ logs: [] }));
    }
}

export function* fetchPrimaryColumns({ payload = {} }) {
    try {
        const { Result: { DatabaseAndTables } } = yield call(DatabaseChangeLogService.getPrimaryColumns, payload);
        if(DatabaseAndTables && DatabaseAndTables.length) {
            yield put(fetchPrimaryColumnsSuccess({ columns: DatabaseAndTables }));
        } else {
            yield put(fetchPrimaryColumnsSuccess({ columns: [] }));
        }
    } catch (error) {
        yield put(fetchPrimaryColumnsSuccess({ columns: [] }));
    }
}

export default [
    takeLatest('FETCH_DATABASE_CHANGELOG_REQUEST', fetchErrorLogs),
    takeLatest('FETCH_PRIMARY_COLUMNS_REQUEST', fetchPrimaryColumns)
];
