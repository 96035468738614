import React from 'react';
import { Icons } from '../../assets';
import { Layout, Select, DatePicker, Pagination, Input, Table, Button, Popconfirm } from 'antd';
import { AppstoreAddOutlined, UndoOutlined, DeleteOutlined } from '@ant-design/icons';
import ProvinceRegion from './components/ProvinceRegion';
import moment from 'moment';
import RestrictPage from '../../components/RestrictComponents/RestrictPage';

const { RangePicker } = DatePicker;
const { Content } = Layout;

const AreasV = (props) => {
    const { pagination, params, services, provinces, setParams, areas, area, loading } = props;
    const { fetchAreas, isMobile, editArea } = props;
    const { open, close } = props;

    const size = params.Size && Number(params.Size) > 0 ? Number(params.Size) : 20;
    const offset = params.Offset && Number(params.Offset) > 0 ? Number(params.Offset) : 1;
    const total = pagination && pagination.Total && Number(pagination.Total) > 0 ? Number(pagination.Total) : 0;
    const n_start = total > 0 ? (((offset - 1) * size) + 1) : 0;
    const n_end = total > 0 ? ((offset * size) > total ? total : offset * size) : 0;

    let _services = [];
    if(services && services.length) {
        _services = services.map((i) => {
            return {value: i.Code, label: i.Description}
        })
    }
    _services.unshift({value: '', label: 'Tất cả'});

    let _provinces = []
    if(provinces && provinces.length) {
        _provinces = provinces.map((i) => {
            return {value: i.Id, label: i.Name}
        })
    }
    _provinces.unshift({value: '', label: 'Tất cả'});

    return (
        <RestrictPage>
            <Content className="page-menus">
                <div className="panel-header">
                    <div className="header-title">
                        <i className="fa fa-home icon" aria-hidden="true"></i>
                        <span>Hệ thống</span>
                        <span className="icon-next"><i className="fa fa-angle-right"></i></span>
                        <span className="active">Quản lý địa danh phát hàng</span>
                    </div>
                </div>
                <div className={isMobile ? "col-md-12 pd-0" : "col-md-12"}>
                    <div className="panel-body">
                        <div className="row mr-b-10">
                            <div className="col-md-2">
                                <label className="w-100">Thời gian</label>
                                <RangePicker
                                    className="w-100"
                                    format="DD-MM-YYYY"
                                    // disabledDate={disabledDate}
                                    value={[params.StartTime || moment().subtract(1, 'days'), params.EndTime || moment()]}
                                    onCalendarChange={(values) => fetchAreas({StartTime: values ? values[0] : moment().subtract(1, 'days'), EndTime: values ? values[1] : moment()})}
                                />
                            </div>
                            <div className="col-md-3">
                                <label className="w-100">Dịch vụ chuyển phát</label>
                                <Select value={params.TosCode || ''} options={_services} onChange={(value) => fetchAreas({TosCode: value})} className="w-100"/>
                            </div>
                            <div className="col-md-2">
                                <label className="w-100">Tỉnh thành gửi</label>
                                <Select value={params.FromProvinceID || ''} options={_provinces} onChange={(value) => fetchAreas({FromProvinceID: value})} className="w-100"/>
                            </div>
                            <div className="col-md-4">
                                <label className="w-100">Tìm kiếm</label>
                                <Input.Search
                                    value={params.Query}
                                    onChange={(e) => setParams({...params, FindKey: e.target.value})}
                                    onSearch={(value) => fetchAreas({ FindKey: value})}
                                    placeholder="Tìm kiếm theo mã xã phường"
                                    className="w-100"
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <label className="total-filter">
                                    <img src={Icons.common.filter} alt="Số địa danh" />|&ensp;<span>{n_start} - {n_end}</span> trong tổng <span>{total}</span> địa danh
                                </label>
                            </div>
                            <div className="col-md-6 text-right">
                                <Button onClick={() => editArea(null)} type="primary" title="Thêm địa danh" size={'small'} icon={<AppstoreAddOutlined />}>Thêm địa danh áp dụng mới</Button>&ensp;
                                {/* <Button icon={<img src={Icons.common.excel} alt="" />} size={'small'} title="Xuất excel danh sách địa danh" onClick={exportExcelArea} type="primary">&ensp;Xuất excel</Button>&emsp; */}
                                <Button onClick={() => fetchAreas()} title="Làm mới" size={'small'} icon={<UndoOutlined />}>Làm mới</Button>&ensp;
                            </div>
                        </div>
                        <div className="row mr-t-10">
                            <div className="col-md-12">
                                <Table
                                    loading={loading}
                                    className="table-main"
                                    columns={createColums(props)}
                                    rowKey="Id"
                                    size="small"
                                    dataSource={areas || []}
                                    pagination={false}
                                    bordered={true}
                                    scroll={{x: 1200}}
                                />
                            </div>
                        </div>
                        <div className="row mr-t-10">
                            <div className="col-md-6 mr-b-10">
                                <span>Hiển thị &ensp;
                                    <Select value={size || 20} style={{ width: 120 }} onChange={(size) => fetchAreas({ Size: size, Offset: 1 })}>
                                        <Select.Option value="20">20</Select.Option>
                                        <Select.Option value="50">50</Select.Option>
                                        <Select.Option value="100">100</Select.Option>
                                        <Select.Option value="200">200</Select.Option>
                                    </Select>
                                    &nbsp; địa danh / trang
                                </span>
                            </div>
                            <div className="col-md-6 text-right">
                                <Pagination current={offset || 1} pageSize={size || 20} total={total || 0} onChange={(offset) => fetchAreas({ Offset: offset })} showSizeChanger={false} />
                            </div>
                        </div>
                    </div>
                </div>
                { open && <ProvinceRegion open={open} close={close} fetchAreas={fetchAreas} provinces={provinces} services={services} area={area}/>}
                {/* {open && <AreaForm open={open} close={close} area={area} fetchAreas={fetchAreas} />} */}
            </Content>
        </RestrictPage>
    );
};

export default AreasV

// Tạo ra danh sách cột trên bảng
const createColums = ({ columns = [], editArea, deleteArea }) => {
    let _columns = [];
    if (columns && columns.length > 0) {
        _columns = [
            { title: 'Dịch vụ', dataIndex: 'TosCode', key: 'TosCode', show: true, width: 120, className:'text-left' },
            { title: 'Tỉnh thành gửi', dataIndex: 'FromProvinceName', key: 'FromProvinceName', show: true, width: 120, className:'text-left' },

            { title: 'Nơi Nhận', dataIndex: '', key: 'string', show: true, width: 120, className:'text-left', children: [
                { title: 'Tỉnh thành', dataIndex: 'ToProvinceName', key: 'ToProvinceName', show: true, width: 120, className:'text-left' },
                { title: 'Quận huyện', dataIndex: 'ToDistrictName', key: 'ToDistrictName', show: true, width: 120, className:'text-left' },
                { title: 'Xã phường', dataIndex: 'ToWardName', key: 'ToWardName', show: true, width: 120, className:'text-left' },
            ] },
            { title: 'Loại', dataIndex: 'Type', key: 'Type', show: false, width: 120, className:'text-left' },
            { title: 'Ngày tạo', dataIndex: '', key: 'datetime', show: false, width: 120, className:'text-left', render: ({CreatedDate}) => {
                return CreatedDate ? moment(CreatedDate).format("DD-MM-YYYY HH:mm") : 'N/A'
            } },
        ];
        var functionColumn = {
            title: 'Chức năng', dataIndex: '', key: 'action', className: "text-center", align: 'center', width: 50, render: (area) => {
                return <div>
                    {/* <Button icon={<EditOutlined />} size="small" title="Chi tiết địa danh" type="primary" onClick={() => editArea(area)}></Button>&ensp; */}
                    <Popconfirm title="Bạn có chắc muốn xóa?" onConfirm={() => deleteArea(area)} placement="topRight" okText="Xóa" cancelText="Không">
                        <Button icon={<DeleteOutlined />} title="Xóa địa danh" size="small" type="danger" />
                    </Popconfirm>
                </div>
            }
        };
        _columns.push(functionColumn);
    }
    return _columns;
}
