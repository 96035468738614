import { lifecycle, compose as recompose, withHandlers, withState } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pickBy, identity, sortBy } from 'lodash';
import DatabaseLogV from './DatabaseLogV';
import { fetchDatabaseChangeLogRequest, fetchPrimaryColumnsRequest } from './../../reduxs/db-change-redux/reducer'

export function mapDispatchToProps(dispatch) {
  return {
    getLogs: (params) => dispatch(fetchDatabaseChangeLogRequest(params)),
    getPrimaryColumns: () => dispatch(fetchPrimaryColumnsRequest())
  };
}

const mapStateToProps = createStructuredSelector({
  fetching: store => store.dbChangeLogs && store.dbChangeLogs.fetching,
  pagination: store => store.dbChangeLogs && store.dbChangeLogs.pagination,
  logs: store => store.dbChangeLogs && store.dbChangeLogs.logs,
  fetchingPrimaryColumn: store => store.dbChangeLogs && store.dbChangeLogs.fetchingPrimaryColumns,
  primaryColumns: store => store.dbChangeLogs && store.dbChangeLogs.primaryColumns
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default recompose(
  withConnect,
  withState('params', 'setParams', { Offset: 1, Size: 50 }),
  withState('tables', 'setTables', []),
  withState('columnKey', 'setColumnKey', []),
  withState('filterMode', 'setFilterMode', 'DB'),
  withHandlers({
    onFetchData: ({ params, setParams, getLogs, filterMode }) => (param) => {
      let _params = { ...params, ...param };

      if (_params.Offset === undefined) _params.Offset = 1;
      if (_params.Size === undefined) _params.Size = 50;

      _params = pickBy(_params, identity);
      setParams(_params);

      let data = {};

      Object.keys(_params).forEach(key => {
        if (_params[key] instanceof Array) {
          _params[key].forEach((elm, index) => {
            switch (key) {
              case 'TableName': {
                data[`${key}[${index}]`] = elm.split('.')[1];
                break;
              }

              case 'Column': {
                data[`${key}[${index}]`] = elm.split('.')[2];
                break;
              }

              default: {
                data[`${key}[${index}]`] = elm;
                break;
              }
            }
          });
        } else {
          data[key] = _params[key];
        }
      })

      if (filterMode === 'DB' && data.HawbNo) {
        delete data.HawbNo;
      }

      getLogs({ ...data });
    },
    onChangeParams: ({ setParams, params, setTables, primaryColumns, setColumnKey }) => (_params = {}) => {
      const _prs = { ...params, Offset: 1, ..._params };

      if (_params.Database) {
        const data = primaryColumns.filter(x => _params.Database.indexOf(x.Database) !== -1);
        let tables = [];
        if (data.length) {
          const temp = data.flatMap((x, i) => x.TableAndColumns.map(x => ({ FullPath: `${data[i].Database}.${x.TableName}`, Database: data[i].Database, Table: x.TableName })));
          temp.forEach(element => {
            if (tables.filter(x => x.FullPath === element.FullPath).length === 0) {
              tables.push(element);
            }
          });
        }
        setTables(tables);

        if (_prs.TableName && _prs.TableName.length && _prs.Database) {
          _prs.TableName = _prs.TableName.filter(x => {
            return _prs.Database.indexOf(x.split('.')[0]) !== -1;
          });
        }
      }

      if (_params.TableName) {
        const t1 = primaryColumns.filter(z => _params.TableName.map(x => x.split('.')[0]).indexOf(z.Database) !== -1);

        let cols = [];

        t1.forEach(element => {
          const _cols = element.TableAndColumns.filter(z => _params.TableName.indexOf(`${element.Database}.${z.TableName}`) !== -1)
            .map(x => ({ FullPath: `${element.Database}.${x.TableName}.${x.ColumnName}`, ColumnName: x.ColumnName, Type: x.KeyType }));
            _cols.forEach(cl => {
              if (cols.findIndex(z => z.FullPath === cl.FullPath) === -1) {
                cols.push(cl);
              }
            });
        });

        setColumnKey(sortBy(cols, 'FullPath'));

        if (_prs.Column && _prs.Column.length) {
          _prs.Column = _prs.Column.filter(x => {
            return (_prs.TableName || []).indexOf(`${x.split('.')[0]}.${x.split('.')[1]}`) !== -1;
          });
        }
      }

      if (!_prs.Database || _prs.Database.length === 0) {
        if (_prs.TableName && _prs.TableName.length !== 0) {
          _prs.TableName.length = 0;
        }

        if (_prs.Column && _prs.Column.length !== 0) {
          _prs.Column.length = 0;
        }
      }

      setParams(_prs);
    }
  }),
  lifecycle({
    componentDidMount() {
      this.props.onFetchData();
      this.props.getPrimaryColumns();
    },
    componentDidUpdate(props) {

    }
  }),
)(DatabaseLogV);