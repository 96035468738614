import { lifecycle, compose as recompose, withState, withHandlers } from 'recompose';
import { notification  } from 'antd';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import _ from 'lodash';
import StaffsV from './CodesV';
import { getCodesRequest, removeParentCodeRequest } from '../../../reduxs/account-redux/reducer';
import { selectCodes, selectCodesing, selectPagination } from '../../../reduxs/account-redux/selectors';

const columns = [
    { name: 'ID', id: 'Id', type: 'string', show: true, width: 120 },
    { name: 'Code', id: 'Code', type: 'string', show: true, width: 200 },
    { name: 'Tên', id: 'Name', type: 'string', show: true, width: 250 },
    { name: 'Mô tả', id: 'Description', type: 'string', show: true, width: 250 },
    { name: 'Người tạo', id: 'CreatedBy', type: 'string', show: true, width: 120 },
    { name: 'Thời gian tạo', id: 'CreatedDate', type: 'date', show: true, width: 120 }
];

export function mapDispatchToProps(dispatch) {
    return {
        getCodes: (params) => dispatch(getCodesRequest(params)),
        removeCode: (params) => dispatch(removeParentCodeRequest(params))
    };
}

const mapStateToProps = createStructuredSelector({
    codes: selectCodes(),
    loading: selectCodesing(),
    pagination: selectPagination(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default recompose(
    withConnect,
    withState('code', 'setCode', null),
    withState('edit', 'setEdit', false),
    withState('view', 'setView', false),
    withState('params', 'setParams', { Offset: 1, Size: 20 }),
    withState('columns', 'setColumns', columns),
    withState('rowKeys', 'setRowKeys', []),
    withState('rowRows', 'setSelectRows', []),
    withHandlers({
        fetchCodes: ({ params,  setParams, getCodes }) => (param) => {
            let _params = { ...params, Offset: 1, ...param };
      
            if (_params.Offset === undefined) _params.Offset = 1;
            if (_params.Size === undefined) _params.Size = 20;
      
            _params = _.pickBy(_params, _.identity);
            setParams(_params);
            getCodes({ ..._params});
        },
        stateColumn: ({ columns, setColumns }) => (data) => {
            const cols = columns.map((col) => {
              if (col.id === data.id) col.show = !data.show
              return col;
            });
            setColumns(cols);
        }
    }),
    withHandlers({
        onViewCode: ({ setCode, setView}) => (code) => {
            setCode(code, () => setView(true))
        },
        onEditCode: ({ setCode, setEdit }) => (code) => {
            setCode(code, () => setEdit(true))
        },
        onDeleteCode: ({fetchCodes, removeCode}) => (Id) => {
            if(Id) {
                const data = {
                    Id: Id
                }
                const callback = (type) => {
                    if(type === 'error') {
                    notification[type]({message: 'Xóa thất bại!'});
                    } else {
                    notification[type]({message: 'Xóa thành công!'});
                    }
                    fetchCodes();
                }
                const _params ={
                    data: data,
                    cb: callback
                }
                removeCode(_params);
            }
        }
    }),
    lifecycle({
        componentDidMount() {
            this.props.fetchCodes();
        }
    })
)(StaffsV);