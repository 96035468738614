
import 'react-image-lightbox/style.css';
import React from 'react'
import Lightbox from 'react-image-lightbox';
import { compose as recompose, lifecycle, withHandlers, withState } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useLocation } from 'react-router-dom';

function usePageViews() {
    let location = useLocation();
    React.useEffect(() => {
        if (location.pathname && !location.pathname.startsWith('/admin') && location.pathname.indexOf('guide') !== -1) {
            document.body.classList.add('pcs-allowed-image-popup');
        } else {
            document.body.classList.remove('pcs-allowed-image-popup');
        }
    }, [location]);
}
  

const ImageGallery = (props) => {
    usePageViews();
    const { image, showImage, setShowImage, setImage } = props;
    return showImage &&
        <Lightbox
            mainSrc={image}
            nextSrc={null}
            prevSrc={null}
            onCloseRequest={() => {setShowImage(false, () => setImage(null))}}
        />
}

const mapDispatchToProps = (dispatch) => {
  return {

  };
}

const mapStateToProps = createStructuredSelector({

});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default recompose(
    withConnect,
    withState('image', 'setImage', null),
    withState('showImage', 'setShowImage', false),
    withHandlers({
        onClick: ({ setImage, setShowImage }) => (evt) => {
            const target = evt.target;
            if (target && document.body.classList && document.body && document.querySelector('.modal-view-detail') && document.querySelector('.modal-view-detail').contains(target) && target.tagName === "IMG" && target.clientWidth < target.naturalWidth) {
                setImage(target.src, () => { setShowImage(true)});
            }
        },
        onHover: () => (evt) => {
            const target = evt.target;
            if (target && document.body.classList && document.body && document.querySelector('.modal-view-detail') && document.querySelector('.modal-view-detail').contains(target) && target.tagName === "IMG" && target.clientWidth < target.naturalWidth) {
                target.setAttribute('title', 'Click để xem ảnh với kích thước lớn hơn');
            }
        }
    }),
    lifecycle({
        componentDidMount() {
            document.addEventListener('click', this.props.onClick);
            document.addEventListener('mouseover', this.props.onHover);
        },
        componentWillUnmount() {
            document.removeEventListener('click', this.props.onClick);
            document.removeEventListener('mouseover', this.props.onHover);
        }
    })
)(ImageGallery)
