import { createAction, handleActions } from 'redux-actions';
// - Actions
export const searchStaffRequest = createAction('SEARCH_STAFF_REQUEST');
export const searchStaffSuccess = createAction('SEARCH_STAFF_SUCCESS');

export const searchMyStaffRequest = createAction('SEARCH_MY_STAFF_REQUEST');
export const searchMyStaffSuccess = createAction('SEARCH_MY_STAFF_SUCCESS');

export const getStaffByCodeRequest = createAction('GET_STAFF_BY_CODE_REQUEST');
export const getStaffByCodeSuccess = createAction('GET_STAFF_BY_CODE_SUCCESS');

export const deleteZonesRequest = createAction('DELETE_CUSTOMER_ZONE_REQUEST');


export const failure = createAction('ACTION_ZONE_FAILURE');


// - Initial State
export const initialState = {
  staffs: [],
  searching: false,

  myStaffs: [],
  mystaffing: false,

  staffscode: [],
  staffcoding: false,
  error: null,
};

// - Reducers
const reducer = handleActions({
  [searchStaffRequest]: (state) => {
    return { ...state, searching: true };
  },
  [searchStaffSuccess]: (state, { payload: { staffs } }) => {
    return { ...state, staffs: staffs || [], searching: false };
  },

  [searchMyStaffRequest]: (state) => {
    return { ...state, mystaffing: true };
  },
  [searchMyStaffSuccess]: (state, { payload: { staffs } }) => {
    return { ...state, myStaffs: staffs || [], mystaffing: false };
  },

  [getStaffByCodeRequest]: (state) => {
    return { ...state, staffcoding: true };
  },
  [getStaffByCodeSuccess]: (state, { payload: { staffs } }) => {
    return { ...state, staffscode: staffs || [], staffcoding: false };
  },

  [failure]: (state, action) => ({
    ...state,
    error: action.error,
    searching: false,
  }),
}, initialState);

export default reducer;
