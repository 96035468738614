
import React from "react";
import { Form } from "antd";
import { CloudUploadOutlined } from '@ant-design/icons';
import CropperImage from '../CropperImage/CropperImage'
import axios from 'axios';
import { ApiUploadImage } from '../../config';

const FormItem = Form.Item;

class FileDropper extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      image: '',
      open: false,
      blod: '',
      loading: false,
      file_name: '',
      scr: null,
      error: ''
    }
    this.onChangeFile = this.onChangeFile.bind(this);
    this.openDropModal = this.openDropModal.bind(this);
    this.closeDropModal = this.closeDropModal.bind(this);
    this.onCropImage = this.onCropImage.bind(this);
  }

  openDropModal () {
    this.setState({open: true});
  };

  closeDropModal() {
    this.setState({open: false});
  };

  onCropImage(canvas) {
    const seft = this;
    const { file_name } = this.state;
    const { action, input } = this.props;
    canvas.toBlob(function (blob) {
      seft.setState({ open: false, loading: true, error: '' });
      if(action) {
        submitUpdate(blob);
      } else {
        input.onChange(blob)
        seft.setState({ blod: canvas.toDataURL(), loading: false});
      }
    })
  
    const submitUpdate = (file) => {
      const config = {
        onUploadProgress: function(progressEvent) {
          var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          console.log(percentCompleted)
        }
      }
    
      const data = new FormData()
      data.append('file', file, file_name)
    
      axios.post(ApiUploadImage, data, config)
        .then(res => {
          if(res && res.data) {
            const { url } = res.data;
            input.onChange(url)
            seft.setState({ blod: url, loading: true});
          }
        })
        .catch(err => {
          seft.setState({ error: 'Upload failed', loading: true});
        })
    }
  };

  onChangeFile(e) {
    e.preventDefault()
    let files
    if (e.dataTransfer) {
      files = e.dataTransfer.files
    } else if (e.target) {
      files = e.target.files
    }
    if (files && files[0]) {
      this.setState({
        file_name: files[0].name
      })
      const reader = new FileReader();
      const seft = this;
      reader.onload = () => {
        seft.setState({
          scr: reader.result,
          open: true,
        })
      }
      reader.readAsDataURL(files[0])
    }
  };
  
  render() {
    const { scr, open, blod } = this.state;
    const { meta, hasFeedback, label, config, input } = this.props;
    const hasError = meta.touched && meta.invalid;
    return (
      <FormItem
        {...config}
          label={label}
          validateStatus={hasError ? "error" : "success"}
          hasFeedback={hasFeedback && hasError}
          help={hasError && meta.error}
        >
        <div className='uploader'>
          <div className='uploader__drop-area'>
            { blod
              ?
              <img className='image-upload' src={blod} alt="" />
              :
              <div className="w-100">
                {
                  input.value
                  ? <img className='image-upload' src={input.value} alt="" />
                  : <CloudUploadOutlined onClick={this.openDropModal}  className='upload-icon' />
                }
              </div>
            }
            <input accept='image/*' type='file' onChange={this.onChangeFile} />
          </div>
        </div>
        <CropperImage
          ratio={1}
          src={scr}
          open={open}
          close={this.closeDropModal}
          onCrop={this.onCropImage}
        />
      </FormItem>
    );
  }
}

export default FileDropper;
