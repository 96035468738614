import { createSelector } from 'reselect';
import { initialState } from './reducer';
const stateDepartment = (state) => state.department || initialState;

const selectDepartment = () => createSelector(
    stateDepartment,
    ({ departments }) => departments
);
const selectPagination = () => createSelector(
    stateDepartment,
    ({ pagination }) => pagination
);
const selectLoading = () => createSelector(
    stateDepartment,
    ({ loading }) => loading
);

export {
    stateDepartment,
    selectDepartment,
    selectPagination,
    selectLoading,
};
