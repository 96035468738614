import { createAction, handleActions } from 'redux-actions';
// - Actions

export const fetchEmailRequest = createAction('FETCH_EMAIL_REQUEST');
export const fetchEmailSuccess = createAction('FETCH_EMAIL_SUCCESS');

export const resendEmailRequest = createAction('RESEND_EMAIL_REQUEST');
export const resendEmailSuccess = createAction('RESEND_EMAIL_SUCCESS');
export const resendEmailFailure = createAction('RESEND_EMAIL_FAILURE');

export const exportAllAttachmentsRequest = createAction('EXPORT_ALL_ATTACHMENTS_REQUEST');
export const exportAllAttachmentsSuccess = createAction('EXPORT_ALL_ATTACHMENTS_SUCCESS');

export const failure = createAction('EMAIL_ACTION_FAILURE');

// - Initial State
export const initialState = {
    emails: [],
    fetching: false,
    exporting: false,
    resending: false,
    pagination: {
        Page: 1,
        Size: 50,
        Total: 0
    },
    error: null
};

// - Reducers
const reducer = handleActions({
    [fetchEmailRequest]: (state) => {
        return { ...state, fetching: true };
    },
    [fetchEmailSuccess]: (state, {payload: { emails }}) => {
        const data = { emails: emails.Data, pagination: emails.Pagination }
        return { ...state, ...data, fetching: false  };
    },
    [exportAllAttachmentsRequest]: (state) => {
        return {...state, exporting: true }
    },
    [exportAllAttachmentsSuccess]: (state) => {
        return {...state, exporting: false }
    },
    [resendEmailRequest]: (state) => {
        return {...state, resending: true }
    },
    [resendEmailSuccess]: (state) => {
        return {...state, resending: false }
    },
    [resendEmailFailure]: (state) => {
        return {...state, resending: false }
    },
    [failure]: (state, action) => ({
        ...state,
        error: action.error,
        fetching: false,
        exporting: false
    }),
}, initialState);

export default reducer;