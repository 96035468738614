import { call, put, takeLatest } from 'redux-saga/effects';
import * as UtilityService from '../../services/utility-service';

import {
  getCountriesSuccess,
  getProvincesSuccess,
  getDistrictsSuccess,
  getWardsSuccess,
  getShipProvincesSuccess,
  getShipDistrictsSuccess,
  getShipWardsSuccess,
  findLocationsSuccess,
  failure,
  searchCustomerSuccess
} from './reducer';


export function* fetchCountries() {
  try {
    const { Result } = yield call(UtilityService.getCountry);
    if (Result && Result.length) {
      yield put(getCountriesSuccess({ countries: Result }));
    } else {
      yield put(getCountriesSuccess([]));
    }

  } catch (error) {
    yield put(failure(error));
  }
}

export function* fetchProvinces({ payload }) {
  try {
    const { Result } = yield call(UtilityService.getProvinces, payload);
    if (Result && Result.length) {
      yield put(getProvincesSuccess(Result));
    } else {
      yield put(getProvincesSuccess([]));
    }

  } catch (error) {
    yield put(failure(error));
  }
}

export function* fetchDistricts({ payload }) {
  try {
    const { Result } = yield call(UtilityService.getDistricts, payload);
    if (Result && Result.length) {
      yield put(getDistrictsSuccess(Result));
    } else {
      yield put(getDistrictsSuccess([]));
    }
  } catch (error) {
    yield put(failure(error));
  }
}

export function* fetchWards({ payload }) {
  try {
    const { Result } = yield call(UtilityService.getWards, payload);
    if (Result && Result.length) {
      yield put(getWardsSuccess(Result));
    } else {
      yield put(getWardsSuccess([]));
    }
  } catch (error) {
    yield put(failure(error));
  }
}

export function* fetchShipProvinces({ payload }) {
  try {
    const { Result } = yield call(UtilityService.getProvinces, payload);
    if (Result && Result.length) {
      yield put(getShipProvincesSuccess(Result));
    } else {
      yield put(getShipProvincesSuccess([]));
    }

  } catch (error) {
    yield put(getShipProvincesSuccess([]));
    yield put(failure(error));
  }
}

export function* fetchShipDistricts({ payload }) {
  try {
    const { Result } = yield call(UtilityService.getDistricts, payload);
    if (Result && Result.length) {
      yield put(getShipDistrictsSuccess(Result));
    } else {
      yield put(getShipDistrictsSuccess([]));
    }
  } catch (error) {
    yield put(getShipDistrictsSuccess([]));
    yield put(failure(error));
  }
}

export function* fetchShipWards({ payload }) {
  try {
    const { Result } = yield call(UtilityService.getWards, payload);
    if (Result && Result.length) {
      yield put(getShipWardsSuccess(Result));
    } else {
      yield put(getShipWardsSuccess([]));
    }
  } catch (error) {
    yield put(getShipWardsSuccess([]));
    yield put(failure(error));
  }
}

export function* searchLocations({ payload }) {
  try {
    const { Result } = yield call(UtilityService.findLocation, payload);
    if (Result && Result.length) {
      yield put(findLocationsSuccess({ locations: Result }));
    } else {
      yield put(findLocationsSuccess([]));
    }
  } catch (error) {
    yield put(failure(error));
  }
}
export function* searchCustomers({ payload }) {
  try {
    const { Result } = yield call(UtilityService.searchCustomers, payload);
    if (Result && Result.length) {
      yield put(searchCustomerSuccess({ customers: Result }));
    } else {
      yield put(searchCustomerSuccess({ customers: [] }));
    }
  } catch (error) {
    yield put(searchCustomerSuccess({ customers: [] }));
    yield put(failure(error));
  }
}

export default [
  takeLatest('GET_COUNTRY_REQUEST', fetchCountries),
  takeLatest('GET_PROVINCES_REQUEST', fetchProvinces),
  takeLatest('GET_DISTRICTS_REQUEST', fetchDistricts),
  takeLatest('GET_WARDS_REQUEST', fetchWards),
  takeLatest("SEARCH_CUSTOMERS_REQUEST", searchCustomers),
  takeLatest('GET_SHIPPER_PROVINCES_REQUEST', fetchShipProvinces),
  takeLatest('GET_SHIPPER_DISTRICTS_REQUEST', fetchShipDistricts),
  takeLatest('GET_SHIPPER_WARDS_REQUEST', fetchShipWards),
  takeLatest('FIND_LOCATIONS_REQUEST', searchLocations),
];
