import { call, put, takeLatest } from 'redux-saga/effects';
import * as WebhookService from '../../services/webhook-service';

import {
  getWebhookHistoriesSuccess,
  failure
} from './reducer';


export function* fetchWebhookHistories({ payload }) {
  try {
    const { Result } = yield call(WebhookService.fetchWebhookHistories, payload);
    if(Result) {
        const { Data, Pagination } = Result;
        yield put(getWebhookHistoriesSuccess({ histories: Data, pagination: { Total: Pagination.Total, Page: Pagination.Offset, Size: Pagination.Size } }));
    }
  } catch (error) {
    yield put(failure(error));
  }
}

export default [
    takeLatest('GET_WEBHOOK_HISTORIES_REQUEST', fetchWebhookHistories)
];
  