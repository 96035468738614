import { sortBy } from 'lodash';

const TEMPLATE = {
    'username': '<strong class="bold">{text}</strong>',
    'optional_notification_type_prefix': '<strong class="bold">{text}</strong>',
    'bill_of_lading_number': '<strong class="bold">{text}</strong>',
    'origin_text': '{text}',
    'link': '<a href="{url}">{text}</a>'
}

const replace = (template, obj) => {
    if (!template) {
        template = TEMPLATE['origin_text']
    }
    let text = template;
    Object.keys(obj).forEach(key => {
        text = text.replace(`{${key}}`, obj[key]);
    })
    return text;
}

const processText = (_text, _ranges) => {
    if (_text && _ranges && _ranges.length) {
        let data = [];
        const sorted = sortBy(_ranges, ['Offset']);
        sorted.forEach((current, index) => {
            const before = sorted[index - 1];
            const after = sorted[index + 1];
            if (!before) {
                data.push({ type: 'origin_text', text: _text.substr(0, current.Offset), url: current.Url });
            }
            data.push({ type: current.Type, text: _text.substr(current.Offset, current.Length), url: current.Url });
            if (after) {
                data.push({ type: 'origin_text', text: _text.substr(current.Offset + current.Length, after.Offset - current.Offset - current.Length), url: current.Url });
            } else {
                data.push({ type: 'origin_text', text: _text.substr(current.Offset + current.Length, _text.Length), url: current.Url });
            }
        });

        const res = data.map(x => {
            return replace(TEMPLATE[x.type], x)
        }).join('');

        return res;
    }
    return _text;
}

const getMentions = (ip) => {
    if (!ip) {
        return []
    }
    
    // eslint-disable-next-line
    return ip.match(/@\[(\w|[^\x00-\x7F]|\s)+\(\w{4,}\)\]/g)
}

const getUserFromMentionToken = (tokens) => {
    if (!tokens || !tokens.length) {
        return []
    }

    // Đoạn code này không hỗ trợ trình duyệt di động, khi nào trình duyệt di động hỗ trợ thì dùng
    // Tạm thời dử dụng đoạn code phía dưới thay thế
    // return {
    //     Code: tokens.flatMap(x => x.match(/(?<=@\[((\w|[^\\x00-\\x7F])|\s+\S)+.\()(.*?)(?=\)\])/g))[0],
    //     Name: tokens.flatMap(x => x.match(/(?<=@\[())(.*?)(?=\(\w{1,}\)\])/))[0]
    // }

    return {
        Code: tokens.flatMap(x => x.substring(x.lastIndexOf("(") + 1, x.lastIndexOf(")")))[0],
        Name: tokens.flatMap(x => x.substring(x.indexOf("[") + 1, x.lastIndexOf("(")))[0]
    }
}

const parseMarkdownMessage = (message) => {
    const mentionsTokens = getMentions(message);
    let _msg = message;
    if (mentionsTokens) {
        mentionsTokens.forEach(element => {
            const user = getUserFromMentionToken([element]);
            let textReg = element.replaceAll('[', '\\[');
            textReg = textReg.replaceAll(']', '\\]');
            textReg = textReg.replaceAll('(', '\\(');
            textReg = textReg.replaceAll(')', '\\)');
            _msg = _msg.replaceAll(new RegExp(`${textReg}`, 'g'), `<span class="bold" data-usercode="${user.Code}" title="${user.Code}" style="color: #0079bf">@${user ? user.Name : user.Code}</span>`)
        });
    }
    return _msg
}

export {
    processText,
    parseMarkdownMessage
}