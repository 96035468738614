import { call, put, takeLatest } from 'redux-saga/effects';
import * as ReportService from '../../services/report-service';

import {
  reportRevenueSuccess,
  failure
} from './reducer';


export function* reportRevenues({ payload = {} }) {
  try {
    const { Result } = yield call(ReportService.getRevenues, payload);
    if(Result) {
      yield put(reportRevenueSuccess({ revenues: Result }));
    } else {
      yield put(reportRevenueSuccess({ revenues: [] }));
    }
  } catch (error) {
    yield put(reportRevenueSuccess({ revenues: [] }));
    yield put(failure(error));
  }
}


export default [
  takeLatest('REPORT_REVENUE_REQUEST', reportRevenues),
];
