import { call, put, takeLatest } from 'redux-saga/effects';
import * as AssetSettingServices from '../../services/asset-setting-service';

import { AssetVendorFormAction, AssetLocationFormAction, AssetGroupFormAction, AssetTypeFormAction } from "./action";
import { SubmissionError } from "redux-form";

import {
  assetVendorsSuccess,
  assetLocationsSuccess,
  assetGroupsSuccess,
  assetTypesSuccess
} from './reducer';


export function* fetchAssetVendors({ payload }) {
  try {
    const { Result } = yield call(AssetSettingServices.fetchAssetVendors, payload);
    if(Result.Data) {
      const { Data, Pagination } = Result
      yield put(assetVendorsSuccess({ vendors: Data, pagination: Pagination }));
    } else {
      yield put(assetVendorsSuccess({ vendors: [], pagination: { Page: 1,Size: 50, Total: 0} }));
    }
  } catch (error) {
    yield put(assetVendorsSuccess({ vendors: [], pagination: { Page: 1,Size: 50, Total: 0} }));
  }
}
export function* deleteAssetVendor({ payload: { data, cb } }) {
  try {
    const { Result } = yield call(AssetSettingServices.deleteAssetVendor, data);
    if(Result) {
      cb(true)
    } else {
      cb(false)
    }
  } catch (error) {
    cb(false)
  }
}
export function* saveAssetVendor({ payload }) {
  try {
    let { Result } = yield call(AssetSettingServices.saveAssetVendor, payload);
    if (Result) {
      yield put(AssetVendorFormAction.success(Result));
    } else {
      const formError = new SubmissionError({
        _error: 'Lưu lại loại tài sản thất bại!'
      });
      yield put(AssetVendorFormAction.failure(formError));
    }
  } catch (error) {
    let _message = "Lưu lại loại tài sản thất bại!";
    if(error && typeof error.Description === 'string') {
      _message = error.Description;
    }
    const formError = new SubmissionError({_error: _message});
    yield put(AssetVendorFormAction.failure(formError));
  }
}

/**Location */
export function* fetchAssetLocations({ payload }) {
  try {
    const { Result } = yield call(AssetSettingServices.fetchAssetLocations, payload);
    if(Result.Data) {
      const { Data, Pagination } = Result
      yield put(assetLocationsSuccess({ locations: Data, pagination: Pagination }));
    } else {
      yield put(assetLocationsSuccess({ locations: [], pagination: { Page: 1,Size: 50, Total: 0} }));
    }
  } catch (error) {
    yield put(assetLocationsSuccess({ locations: [], pagination: { Page: 1,Size: 50, Total: 0} }));
  }
}
export function* deleteAssetLocation({ payload: { data, cb } }) {
  try {
    const { Result } = yield call(AssetSettingServices.deleteAssetLocation, data);
    if(Result) {
      cb(true)
    } else {
      cb(false)
    }
  } catch (error) {
    cb(false)
  }
}
export function* saveAssetLocation({ payload }) {
  try {
    let { Result } = yield call(AssetSettingServices.saveAssetLocation, payload);
    if (Result) {
      yield put(AssetLocationFormAction.success(Result));
    } else {
      const formError = new SubmissionError({
        _error: 'Lưu lại vị trí tài sản thất bại!'
      });
      yield put(AssetLocationFormAction.failure(formError));
    }
  } catch (error) {
    let _message = "Lưu lại vị trí tài sản thất bại!";
    if(error && typeof error.Description === 'string') {
      _message = error.Description;
    }
    const formError = new SubmissionError({_error: _message});
    yield put(AssetLocationFormAction.failure(formError));
  }
}
/**Group */
export function* fetchAssetGroups({ payload }) {
  try {
    const { Result } = yield call(AssetSettingServices.fetchAssetGroups, payload);
    if(Result.Data) {
      const { Data, Pagination } = Result
      yield put(assetGroupsSuccess({ groups: Data, pagination: Pagination }));
    } else {
      yield put(assetGroupsSuccess({ groups: [], pagination: { Page: 1,Size: 50, Total: 0} }));
    }
  } catch (error) {
    yield put(assetGroupsSuccess({ groups: [], pagination: { Page: 1,Size: 50, Total: 0} }));
  }
}
export function* deleteAssetGroup({ payload: { data, cb } }) {
  try {
    const { Result } = yield call(AssetSettingServices.deleteAssetGroup, data);
    if(Result) {
      cb(true)
    } else {
      cb(false)
    }
  } catch (error) {
    cb(false)
  }
}
export function* saveAssetGroup({ payload }) {
  try {
    let { Result } = yield call(AssetSettingServices.saveAssetGroup, payload);
    if (Result) {
      yield put(AssetGroupFormAction.success(Result));
    } else {
      const formError = new SubmissionError({
        _error: 'Lưu lại loại tài sản thất bại!'
      });
      yield put(AssetGroupFormAction.failure(formError));
    }
  } catch (error) {
    let _message = "Lưu lại loại tài sản thất bại!";
    if(error && typeof error.Description === 'string') {
      _message = error.Description;
    }
    const formError = new SubmissionError({_error: _message});
    yield put(AssetGroupFormAction.failure(formError));
  }
}

/**Type */

export function* fetchAssetTypes({ payload }) {
  try {
    const { Result } = yield call(AssetSettingServices.fetchAssetTypes, payload);
    if(Result.Data) {
      const { Data, Pagination } = Result
      yield put(assetTypesSuccess({ types: Data, pagination: Pagination }));
    } else {
      yield put(assetTypesSuccess({ types: [], pagination: { Page: 1,Size: 50, Total: 0} }));
    }
  } catch (error) {
    yield put(assetTypesSuccess({ types: [], pagination: { Page: 1,Size: 50, Total: 0} }));
  }
}

export function* deleteAssetType({ payload: { data, cb } }) {
  try {
    const { Result } = yield call(AssetSettingServices.deleteAssetType, data);
    if(Result) {
      cb(true)
    } else {
      cb(false)
    }
  } catch (error) {
    cb(false)
  }
}

export function* saveAssetType({ payload }) {
  try {
    let { Result } = yield call(AssetSettingServices.saveAssetType, payload);
    if (Result) {
      yield put(AssetTypeFormAction.success(Result));
    } else {
      const formError = new SubmissionError({
        _error: 'Lưu lại loại tài sản thất bại!'
      });
      yield put(AssetTypeFormAction.failure(formError));
    }
  } catch (error) {
    let _message = "Lưu lại loại tài sản thất bại!";
    if(error && typeof error.Description === 'string') {
      _message = error.Description;
    }
    const formError = new SubmissionError({_error: _message});
    yield put(AssetTypeFormAction.failure(formError));
  }
}

export default [
  takeLatest('ASSET_VENDOR_LIST_REQUEST', fetchAssetVendors),
  takeLatest('ASSET_DEL_VENDOR_REQUEST', deleteAssetVendor),
  takeLatest(AssetVendorFormAction.REQUEST, saveAssetVendor),

  takeLatest('ASSET_LOCATION_LIST_REQUEST', fetchAssetLocations),
  takeLatest('ASSET_DEL_LOCATION_REQUEST', deleteAssetLocation),
  takeLatest(AssetLocationFormAction.REQUEST, saveAssetLocation),

  takeLatest('ASSET_GROUP_LIST_REQUEST', fetchAssetGroups),
  takeLatest('ASSET_DEL_GROUP_REQUEST', deleteAssetGroup),
  takeLatest(AssetGroupFormAction.REQUEST, saveAssetGroup),

  takeLatest('ASSET_TYPE_LIST_REQUEST', fetchAssetTypes),
  takeLatest('ASSET_DEL_TYPE_REQUEST', deleteAssetType),
  takeLatest(AssetTypeFormAction.REQUEST, saveAssetType),
];
