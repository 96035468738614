import { createAction, handleActions } from 'redux-actions';
// - Actions

export const createNotificationRequest = createAction('CREATE_NOTIFICATION_REQUEST');
export const createNotificationSuccess = createAction('CREATE_NOTIFICATION_SUCCESS');

export const failure = createAction('NOTIFICATION_ACTION_FAILURE');

// - Initial State
export const initialState = {
  creatingNotification: false,

  error: null,
};

// - Reducers
const reducer = handleActions({
  [createNotificationRequest]: (state) => {
    return { ...state, creatingNotification: true };
  },
  [createNotificationSuccess]: (state) => {
    return { ...state, creatingNotification: false  };
  },

  [failure]: (state, action) => ({
    ...state,
    error: action.error
  }),
}, initialState);

export default reducer;
