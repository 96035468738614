import React from 'react';
// import _ from 'lodash';
import { Button, Modal, Tree, Empty, message } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { lifecycle, compose as recompose, withHandlers, withProps, withState, withPropsOnChange} from 'recompose';
import { fetchMenuGroupsRequest, saveGroupMenusRequest } from '../../../reduxs/menu-redux/reducer';
import { selectMenuGroups, selectMenuGroupsing} from '../../../reduxs/menu-redux/selectors';


const renderMenus = (menu) => ({
    key: menu.Id,
    title: menu.Name,
    children: menu.children && menu.children.map((child) => renderMenus(child))
  })

const GroupMenuForm = (props) => {
  const { open, close, group, menuselects, treeMenus, onSaveMenus, setMenuSelects } = props;

  let dataMenus = []
  if(treeMenus && treeMenus.length) {
    dataMenus = treeMenus.map((menu) => renderMenus(menu))
  }

  return (
    <Modal
      title={<span>Cập nhật menu nhóm: <span className="bold">{group.Name}</span></span>}
      visible={open}
      style={{ top: 20 }}
      width={'580px'}
      footer={null}
      maskClosable={false}
      onOk={() => close(false) }
      onCancel={() => close(false) }
      className="modal-form-menu"
    >
      <div className="row">
          <div className="col-md-12">
            <div className="box-menus">
              <div className="menu-header">
                <label  className="w-100 bold">Danh sách menus</label>
              </div>
              {dataMenus && dataMenus.length
              ?
              <Tree
                checkable
                defaultExpandAll
                checkedKeys={menuselects}
                onCheck={(keys) => setMenuSelects(keys)}
                treeData={dataMenus}
              />
              :
                <Empty description="Chưa có menu để cập nhập"/>
              }
          </div>
        </div>
      </div>
      <br/>
      {dataMenus && dataMenus.length > 0 &&
        <div className="row">
          <div className="col-md-12 text-center">
            <Button onClick={() => onSaveMenus()} type="primary" icon={<SaveOutlined />}>{group ? "Cập nhật" : "Lưu"}</Button>
          </div>
        </div>
      }
    </Modal>
  );
};

export function mapDispatchToProps(dispatch) {
  return {
    getMenuGroups: (params) => dispatch(fetchMenuGroupsRequest(params)),
    saveMenuGroups: (params) => dispatch(saveGroupMenusRequest(params)),
  };
}

const mapStateToProps = createStructuredSelector({
  menusGroup: selectMenuGroups(),
  loading: selectMenuGroupsing(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default recompose(
  withConnect,
  withProps(({ group }) => {
      if(group) {
        return { initialValues: { GroupId: [ group.Id ] }};
      }
    }
  ),
  withState('menuselects', 'setMenuSelects', []),
  withState('action', 'setAction', false),
  withHandlers({
    onSaveMenus: ({ group, menuselects, getMenuGroups, saveMenuGroups }) => () => {
      const data = {
        GroupId: [group.Id],
        MenuId: menuselects
      }
      saveMenuGroups({
        data,
        cb: (type) => {
          if(type === 'error') {
            message.error('Cập nhập thất bại!');
          } else {
            getMenuGroups({GroupId: group.Id})
            message.success('Cập nhập thành công!')
          }
        }
      })
    },
    onSelecteMenu: ({menusGroup, menuselects, setMenuSelects, setAction, change}) => (items) => {
      let _menus = menuselects.map((item) => item.Id);
      let _hasMenus = menusGroup.filter((item) => _menus.includes(item.Id)).map((item) => item.Id);
      let _delMenus = _hasMenus.filter((item) => !items.includes(item));
      change('MenuId', items);
      change('DelMenu', _delMenus);
      setAction(true)
      setMenuSelects(items)
    }
  }),
  withPropsOnChange(['menusGroup'], ({ menus, menusGroup, setMenuSelects }) => {
    if(menusGroup && menusGroup.length) {
      const keys = []
      menusGroup.forEach(menu => {
        if(menusGroup.filter(i => i.ParentId === menu.Id).length === menus.filter(i => i.ParentId === menu.Id).length) {
          keys.push(menu.Id);
        }
      });
      setMenuSelects(keys);
    } else {
      setMenuSelects([])
    }
  }),
  lifecycle({
    componentDidMount() {
      const { group } = this.props;
      if(group) {
        this.props.getMenuGroups({GroupId: group.Id});
      }
    },
  }),
)(GroupMenuForm);
