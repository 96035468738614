import { call, takeLatest } from 'redux-saga/effects';
import * as NotificationTemplateService from '../../services/notification-template-service';

export function* getNotificationTemplate({ payload }) {
    const { params, cb } = payload;
    try {
        const { Result } = yield call(NotificationTemplateService.getNotificationTemplates, params);
        cb && cb(true, Result)
    } catch (error) {
        cb && cb(false);
    }
}

export function* addNotificationTemplate({ payload }) {
    const { params, cb } = payload;
    try {
        const { Result } = yield call(NotificationTemplateService.addTemplate, params);
        cb && cb(Result)
    } catch (error) {
        cb && cb(false);
    }
}

export function* updateNotificationTemplate({ payload }) {
    const { params, cb } = payload;
    try {
        const { Result } = yield call(NotificationTemplateService.updateTemplate, params);
        cb && cb(Result)
    } catch (error) {
        cb && cb(false);
    }
}

export function* removeNotificationTemplate({ payload }) {
    const { params, cb } = payload;
    try {
        const { Result } = yield call(NotificationTemplateService.removeTemplate, params);
        cb && cb(Result)
    } catch (error) {
        cb && cb(false);
    }
}

export default [
    takeLatest('GET_NOTIFICATION_TEMPLATES_REQUEST', getNotificationTemplate),
    takeLatest('ADD_NOTIFICATION_TEMPLATES_REQUEST', addNotificationTemplate),
    takeLatest('UPDATE_NOTIFICATION_TEMPLATES_REQUEST', updateNotificationTemplate),
    takeLatest('REMOVE_NOTIFICATION_TEMPLATES_REQUEST', removeNotificationTemplate)
];
