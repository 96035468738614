
import React from "react";
import { Upload, Button } from 'antd';
import { CloudUploadOutlined } from '@ant-design/icons';
import { Form } from "antd";

const FormItem = Form.Item;

class File extends React.Component {
  state = {
    fileList: []
  };
  onUpload = (file) => {
    // console.log('==========', file);
    this.props.input.onChange(file);
    if (this.props.onFileChange) this.props.onFileChange(file);
  }
  
  render() {
    const { fileList } = this.state;
    const { multiple } = this.props;
    const propsFile = {
      onRemove: file => {
        this.setState(state => {
          if(multiple) {
            const index = state.fileList.indexOf(file);
            const newFileList = state.fileList.slice();
            newFileList.splice(index, 1);
            this.onUpload(newFileList);
            return {
              fileList: newFileList,
            };
          } else {
            this.onUpload();
            return {
              fileList: [],
            };
          }
        });
      },
      beforeUpload: file => {
        if(multiple) {
          this.onUpload([...this.state.fileList, file]);
          this.setState(state => ({fileList: [...state.fileList, file]}));
        } else {
          this.onUpload(file);
          this.setState({ fileList: [file] });
        }
        return false;
      },
      fileList,
    };
    const { meta, hasFeedback, label, config } = this.props;
    const hasError = meta.touched && meta.invalid;
    return (
      <FormItem
        {...config}
        label={label}
        validateStatus={hasError ? "error" : "success"}
        hasFeedback={hasFeedback && hasError}
        help={hasError && meta.error}
        >
        <Upload {...propsFile} >
          <Button>
            <CloudUploadOutlined /> Chọn ảnh tải lên
          </Button>
        </Upload>
      </FormItem>
    );
  }
}

export default File;