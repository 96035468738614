import { createFormAction } from "redux-form-saga";
export const FORM_STAFF_KEY = "FORM_STAFF_KEY";
export const StaffFormAction = createFormAction(FORM_STAFF_KEY);

export const FORM_CHANGE_KEY = "FORM_CHANGE_PASS_FORM";
export const PassFormAction = createFormAction(FORM_CHANGE_KEY);

export const FORM_COMMISSION_FORM = "FORM_COMMISSION_FORM";
export const CommissionFormAction = createFormAction(FORM_COMMISSION_FORM);

