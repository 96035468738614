
import axios from '../utils/axiosInstance';

export const searchCustomers = async (params = {}) => {
  try {
    const { data } = await axios.get('/crm/customer', {
      params: params
    });
    return data;
  } catch (error) {
    throw error;
  }
};
export const getCustomer = async (params) => {
  try {
    const { data } = await axios.get(`/crm/customer/${params.Id}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const addCustomer = async (customer) => {
  try {
    const { data } = await axios.post('/crm/customer', customer);
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateCustomer = async ({ customer }) => {
  try {
    const { data } = await axios.put(`/crm/customer`, customer);
    return data;
  } catch (error) {
    throw error;
  }
};

//Images
export const importFileCustomer = async ({ file }) => {
  try {
    const body = new FormData()
    body.append('File', file)

    const { data } = await axios.post(`/crm/customer/import`, body);
    return data;
  } catch (error) {
    throw error;
  }
};

export const changeLevelCustomer = async ({ customerId, levelId }) => {
  try {
    const { data } = await axios.put(`/crm/customer/${customerId}/change-level`, { LevelId: levelId });
    return data;
  } catch (error) {
    throw error;
  }
};

export const changeTypeCustomer = async ({ customerId, TypeId }) => {
  try {
    const { data } = await axios.put(`/crm/customer/${customerId}/change-type`, { TypeId: TypeId });
    return data;
  } catch (error) {
    throw error;
  }
};

export const changeActiveCustomer = async ({ customerId, active }) => {
  try {
    const { data } = await axios.put(`/crm/customer/${customerId}/toggle-active`, { IsActive: active });
    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteCustomer = async (id) => {
  try {
    const { data } = await axios.delete(`/api/customer/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};


/**Contact */
export const addContact = async ({ contact, customerId }) => {
  try {
    const { data } = await axios.post(`/crm/customer/${customerId}/contact`, contact);
    return data;
  } catch (error) {
    throw error;
  }
};
export const updateContact = async ({ contact, customerId, contactId }) => {
  try {
    const { data } = await axios.put(`/crm/customer/${customerId}/contact/${contactId}`, contact);
    return data;
  } catch (error) {
    throw error;
  }
};
export const deleteContact = async ({ customerId, contactId }) => {
  try {
    const { data } = await axios.delete(`/crm/customer/${customerId}/contact/${contactId}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const deletePhoneContact = async ({ customerId, contactId, phoneId }) => {
  try {
    const { data } = await axios.delete(`/crm/customer/${customerId}/contact/${contactId}/phone/${phoneId}`);
    return data;
  } catch (error) {
    throw error;
  }
};
export const deleteEmailContact = async ({ customerId, contactId, emailId }) => {
  try {
    const { data } = await axios.delete(`/crm/customer/${customerId}/contact/${contactId}/email/${emailId}`);
    return data;
  } catch (error) {
    throw error;
  }
};
export const deleteAddressContact = async ({ customerId, contactId, addressId }) => {
  try {
    const { data } = await axios.delete(`/crm/customer/${customerId}/contact/${contactId}/address/${addressId}`);
    return data;
  } catch (error) {
    throw error;
  }
};

/**address */
export const addAddress = async ({ address, customerId }) => {
  try {
    const { data } = await axios.post(`/crm/customer/${customerId}/address`, address);
    return data;
  } catch (error) {
    throw error;
  }
};
export const updateAddress = async ({ address, customerId, addressId }) => {
  try {
    const { data } = await axios.put(`/crm/customer/${customerId}/address/${addressId}`, address);
    return data;
  } catch (error) {
    throw error;
  }
};
export const deleteAddress = async ({ customerId, addressId }) => {
  try {
    const { data } = await axios.delete(`/crm/customer/${customerId}/address/${addressId}`);
    return data;
  } catch (error) {
    throw error;
  }
};

/**phone */
export const addPhone = async ({ phone, customerId }) => {
  try {
    const { data } = await axios.post(`/crm/customer/${customerId}/phone`, phone);
    return data;
  } catch (error) {
    throw error;
  }
};
export const updatePhone = async ({ phone, customerId, phoneId }) => {
  try {
    const { data } = await axios.put(`/crm/customer/${customerId}/phone/${phoneId}`, phone);
    return data;
  } catch (error) {
    throw error;
  }
};
export const deletePhone = async ({ customerId, phoneId }) => {
  try {
    const { data } = await axios.delete(`/crm/customer/${customerId}/phone/${phoneId}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteEmail = async ({ customerId, emailId }) => {
  try {
    const { data } = await axios.delete(`/crm/customer/${customerId}/email/${emailId}`);
    return data;
  } catch (error) {
    throw error;
  }
};

/**Type customer */
export const getTypeCustomers = async (params) => {
  try {
    const { data } = await axios.get(`/crm/customer/type`, {
      params: { IsActive: true, ...params }
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getHistoryLevels = async ({ CustomerId }) => {
  try {
    const { data } = await axios.get(`/crm/customer/${CustomerId}/level-history`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getZoningHistory = async (params) => {
  try {
    const { data } = await axios.get(`/api/zoning-history`, { params });
    return data;
  } catch (error) {
    throw error;
  }
};


export const updateByProps = async (params) => {
  try {
    const { data } = await axios.put(`/api/customer/update-by-props`, params);
    return data;
  } catch (error) {
    throw error;
  }
};


export const createAccountNumber = async (params = {}) => {
  try {
    const { data } = await axios.post(`/admin/create-account-no`, params)
    return data;
  } catch (error) {
    throw error;
  }
};
export const addCustomerToNewStation = async (params) => {
  try {
    const { data } = await axios.get(`/crm/customer/add-customer-to-newstation`, { params });
    return data;
  } catch (error) {
    throw error;
  }
};