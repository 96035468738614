import axios from '../utils/axiosInstance';

export const getAllPickupZone = async (params = {}) => {
    try {
        const { data } = await axios.get('/api/pickup-zone-manager', { params });
        return data
    } catch (error) {
        throw error;
    }
};

export const updateAllPickupZone = async (params = {}) => {
    try {
        const { data } = await axios.put('/api/pickup-zone-manager', params);
        return data
    } catch (error) {
        throw error;
    }
};